import { useRef, useEffect } from 'react'

/**
 * Set the document title of the page. Base on
 * https://dev.to/luispa/how-to-add-a-dynamic-title-on-your-react-app-1l7k
 * 
 * @param {string} title 
 * @param {boolean} prevailOnUnmount 
 */
export function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef('Liquidity');

  useEffect(() => {
    document.title = `Liquidity | ${title}`;
  }, [title]);

  // Retain the current title if the component is unmounted
  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [prevailOnUnmount])
}