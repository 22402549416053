import {
    Box,
    Link
} from '@theme-ui/components';
import {
    KeyboardNavigation,
    Container,
    BackButton,
    Logo
} from '~/Common';
import { useHistory } from 'react-router-dom';

const containerSize = '684px';

export const AcceptanceLayout = ({ children, sx, ...props }) => {
    const history = useHistory();

    const goBack = () => history.push('/portfolio');

    return (
        <>
            <KeyboardNavigation>
                <Link href="#content">Skip to content</Link>
            </KeyboardNavigation>

            <Box sx={{
                backgroundImage: 'url(/img/acceptance-header.jpg)',
                backgroundSize: 'cover',
                py: '44px',
                position: 'relative'
            }}>
                <BackButton
                    onClick={goBack}
                    label="Back to Liquidity"
                    sx={{
                        position: ['relative', null, null, 'absolute'],
                        left: ['10px', null, null, '20px'],
                        top: ['120px', null, null, '50%'],
                        transform: ['none', null, null, 'translateY(-50%)']
                    }}
                />
                <Container size={containerSize} sx={{ display: 'flex' }}>
                    <Logo />
                </Container>
            </Box>
            <Container size={containerSize} sx={{ pt: '96px', fontSize: '18px' }}>
                {children}
            </Container>
            <Box sx={{
                bg: '#F7F8F9',
                mt: '106px',
                pt: '48px',
                pb: '80px',
                fontSize: '18px'
            }}>
                <Container size={containerSize}>
                    <b>General Risk & Disclaimer</b><br /><br/>
                    <b>Early stage capital funding is risky. Investment in these types of ventures is speculative and carries high risks.</b><br/><br/>
                    You may lose your entire investment, and you should be in a position to bear this risk without undue hardship.<br /><br />
                    Even if the company is successful, the value of your investment and any return on the investment could be reduced if the company issues more shares.<br/><br/>
                    Disclosure of Interest: Liquidity Technology Pty Ltd (“Liquidity”) receives commission from dealing in securities and its introducers of business, may directly share in this commission. Liquidity and its associates may hold shares in the Company and they may hold directorships in the Company.<br/><br/>
                    <b>Liquidity Technology Pty Ltd</b><br />
                    Corporate Authorised Representative: 001293556 of AFSL 338731<br /><br />
                    Suite 7, 29 The Avenue<br />Nedlands WA 6009 Australia<br /><br />
                    <Link href="https://www.liquidity.com.au" target="_blank">www.liquidity.com.au</Link>
                </Container>
            </Box>
        </>
    );
};
