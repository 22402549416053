import { useRef } from 'react';
import { SwitchProfile as SwitchProfileStyled, ProfileItem } from './styled';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Flex,
    Text
} from '@theme-ui/components';
import { setProfileByUUID } from 'features/auth/authSlice';
import { setPortfolioStatus } from 'features/portfolio/portfolioSlice';
import { Circle } from '~/Common/Circle';
import { Icon } from 'assets/Icon';
import { Tooltip } from '../Tooltip';

export const UserBox = ({ profile, skipActive = false, sx, onClick, ...props }) => {
    const activeProfile = useSelector((state) => state.user?.activeProfile);
    const dispatch = useDispatch();
    const isActive = activeProfile?.uuid === profile?.uuid;
    const isVerified = profile?.verified !== false;
    const canBid = profile?.can_bid !== false

    const getProfileIcon = (type) => {
        switch(type) {
            case 'company':
            case 'superannuation_company':
            case 'trust':
            case 'trust_company':
                return 'company';
            default:
                return 'user';
        }
    }

    return <>
        { !isActive || !skipActive ?
        <ProfileItem
            title={`Change to ${profile?.name}`}
            as={canBid ? Button : Box}
            variant="link"
            sx={{
                width: '100%',
                color: 'dark',
                transition: 'background 0.1s ease-in-out',
                borderRadius: '8px',
                marginBottom: '20px',
                padding: '13px',
                cursor: isVerified ? 'pointer' : 'default',
                '&.active': {
                    color: 'darker'
                },
                '&:hover': {
                    backgroundColor: isVerified ? 'secondaryLight' : undefined
                },
                ...sx
            }}
            onClick={() => {
                if (canBid) {
                    dispatch(setProfileByUUID(profile?.uuid));
                    dispatch(setPortfolioStatus('idle'));

                    if (typeof onClick === 'function') {
                        onClick(profile);
                    }
                }
            }}
            className={`${isActive ? 'active' : ''} ${isVerified ? 'verified' : ''}`}
            {...props}
        >
            <Flex sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                paddingRight: '20px'
            }}>
                <Box mr={2}>
                    <Circle type="box" size="30px" sx={{ background: 'transparent' }} className="profile__icon">
                        {<Icon icon={isActive ? 'tick' : getProfileIcon(profile?.type)} size="16" />}
                    </Circle>
                </Box>
                <Flex sx={{
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                }}>
                    <Text as="div" variant="h5" mb={0} sx={{
                        lineHeight: 1,
                        color: 'inherit',
                        display: 'flex',
                        gridGap: 2,
                        alignItems: 'center',
                    }}>
                        {profile?.name}
                        {!isVerified && (
                            <Tooltip text="Requires Verification" sx={{ flexShrink: 0 }}>
                                <Icon size="14" icon="pending" color="warning" />
                            </Tooltip>
                        )}
                    </Text>
                    <Text as="p" mb={0} sx={{ lineHeight: 1, fontSize: '12px' }}>{profile?.type_name}</Text>
                </Flex>
            </Flex>
        </ProfileItem> : null}
    </>;
};

export const SwitchProfile = ({ isOpen, className = '', sx, itemSx, onChange, children, ...props }) => {
    const menuRef = useRef(null);
    const user = useSelector((state) => state.user?.user);

    return (
        <>
        {Boolean(Array.isArray(user?.profiles) && user?.profiles.length > 0) && (
            <SwitchProfileStyled ref={menuRef} role="dialog" tabIndex="-1" className={`${className}${isOpen ? ` open` : ''}`} sx={{
                transform: 'translate3d(100%, 0, 0)',
                borderRadius: '16px',
                position: 'absolute',
                bg: 'white',
                p: '22px 16px',
                visibility: 'hidden',
                opacity: 0,
                transition: 'transform 0.3s ease-in-out 0.1s, opacity 0s linear 0.3s, visibility 0s linear 0.3s',
                ...sx
            }} {...props}>
                {Array.isArray(user?.profiles) && user?.profiles.length > 1 ?
                    user.profiles.map((profile, index) => (
                        <UserBox key={`profile${index}`} profile={profile} skipActive={false} sx={itemSx} onClick={onChange} />
                    )
                    ) : null}
                {children}
            </SwitchProfileStyled>
        )}
        </>
    );
};
