import { useState } from 'react'
import { Icon } from 'assets/Icon'
import { Alert as ThemeAlert, Box, Close } from '@theme-ui/components'

export const Alert = ({
    children,
    isCloseable = true,
    icon = 'info',
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(true)
    return (
        <>
            {isOpen && (
                <ThemeAlert {...props}>
                    <Box sx={{
                        border: '1px solid',
                        borderColor: 'utilityGray200',
                        borderRadius: '9999px',
                        width: '22px',
                        height: '22px',
                        padding: '4px',
                        display: 'flex',
                        justifyContent: 'center'
                    }} mr={3}><Icon icon={icon} size="12px" /></Box>
                    <Box mr={isCloseable ? 3 : 0}>{children}</Box>
                    {isCloseable && (
                        <Close
                            ml="auto"
                            mr={-2}
                            onClick={() => setIsOpen(false)}
                        />
                    )}
                </ThemeAlert>
            )}
        </>
    )
}
