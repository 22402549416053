import { useEffect, useState, useRef, useCallback } from 'react';
import {
    Grid,
    Flex,
    Heading,
    Text,
    Image,
    Box,
    Button,
    Link as ThemeLink
} from '@theme-ui/components';
import {
    Pagination,
    Table,
    Tooltip
} from '~/Common';
import { Link, NavLink } from 'react-router-dom';
import { Icon } from 'assets/Icon';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPortfolioListedDealsAsync,
    getPortfolioUnlistedDealsAsync,
    getPortfolioDeals,
    getPortfolioEquityHoldings,
    getPortfolioEquityHoldingsAsync
} from 'features/portfolio/portfolioSlice';
import {
    Circle,
    ImagePlaceholder
} from '~/Common'
import { StatusIcon } from '~/Investments'
import {
    dealOfferPriceFormatting,
    formatCurrency
} from 'utils/helpers';
import { useResponsiveValue } from '@theme-ui/match-media'
import { DealInvestmentStatus } from 'app/constants';
import { fetchUserOpentrader, setUser } from 'features/auth/authSlice';
import { updateAsxAnnouncementPreference } from 'features/notifications/notificationsAPI';
import { toast } from 'react-toastify';
import { grabFirstError } from 'utils/helpers';

const getAcceptanceDoc = investment => {
    switch (investment.status.id) {
        case DealInvestmentStatus.agreement_signed:
        case DealInvestmentStatus.money_received:
        case DealInvestmentStatus.fulfilled:
            return investment.signed_document ? {
                url: investment.signed_document,
                label: 'Signed'
            } : false;
        case DealInvestmentStatus.authorised:
            return investment.acceptance_document ? {
                url: investment.acceptance_document,
                label: 'Acceptance'
            } : false;
        default:
            return false;
    }
}

const getRowLink = (investment, props) => {
    const requiresAccept = investment?.status?.id === DealInvestmentStatus.authorised;
    const linkTo = requiresAccept ? {
        as: ThemeLink,
        href: investment.acceptance_url,
        className: 'btn'
    } : {
        as: Link,
        to: `/deals/${investment?.deal?.slug}`
    };

    return (
        <ThemeLink
            {...linkTo}
            className="btn"
            sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                zIndex: 60
            }}
            {...props}
        />
    )
}

export const List = ({ portfolio, type, ...props }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user?.user);
    const activeProfile = useSelector((state) => state.user?.activeProfile);
    const headingRef = useRef(null);
    const equityHeadingRef = useRef(null);
    const [isLoading, setLoading] = useState(true);
    const [isEquityHoldingsLoading, setEquityHoldingsLoading] = useState(true);
    const [iconColors, setIconColors] = useState(new Map());
    const smoothScroll = (ref) => {
        window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop });
    };
    const dealsToShow = 'listed' === type ? portfolio?.deals?.listed : portfolio?.deals?.unlisted;
    const activePage = 'listed' === type ? portfolio?.deals?.listed?.meta?.current_page : portfolio?.deals?.unlisted?.meta?.current_page;
    const pageCount = 'listed' === type ? portfolio?.deals?.listed?.meta?.last_page : portfolio?.deals?.unlisted?.meta?.last_page;
    const equityHoldingsPages = {
        activePage: portfolio?.equityholdings?.meta?.current_page,
        pageCount: portfolio?.equityholdings?.meta?.last_page
    };
    const isTablet = useResponsiveValue([true, true, true, false]);
    const isMobile = useResponsiveValue([true, false]);

    const handleOpentraderOnClick = (e) => {
        e.preventDefault();
        fetchUserOpentrader().then((response) => {
            if (response?.redirect_uri && response?.token) {
                setTimeout(() => {
                    window.open(`${response.redirect_uri}?token=${response.token}`, '_blank');
                });
            } else {
                toast.error(t('login.opentrader_error'), {
                    toastId: 'opentrader-error'
                })
            }
        }).catch(({ response }) => {
            toast.error(grabFirstError(response.data?.errors) ?? t('login.opentrader_error'), {
                toastId: 'opentrader-error'
            })
        });
    };

    useEffect(() => {
        if(portfolio?.deals?.listed?.status === 'idle'){
            setLoading(true);
            dispatch(getPortfolioListedDealsAsync());
            dispatch(getPortfolioUnlistedDealsAsync());
            dispatch(getPortfolioEquityHoldingsAsync());
        }
        if(portfolio?.deals?.listed?.status === 'attempted'){
            setLoading(false);
        }
        if (portfolio?.equityholdings?.status === 'attempted') {
            setEquityHoldingsLoading(false);
        }
    }, [portfolio, setLoading, setEquityHoldingsLoading, dispatch]);

    /**
     * Returns the color for the icon based on the user preferences
     * 
     * @param {string} type - The type of notification (email or phone)
     * @param {string} securityCode - The security code of the holding
     */
    const getIconColor = useCallback((type, securityCode) => {     
        if (user?.asx_announcement_preferences[securityCode]?.notify_by?.hasOwnProperty(type)) {
          return 'tealDark';
        }
        return 'dark';
      }, [user?.asx_announcement_preferences]);

    /**
     * Updates the icon colors based on the user preferences
     * 
     * @returns {void}
     */
    const updateIconColors = useCallback(() => {
        // Logic to fetch icon colors based on user preferences
        const newIconColors = new Map();

        // Iterate over holdings and set colors in the Map
        portfolio?.equityholdings?.results?.forEach((holding) => {
            const emailColor = getIconColor("email", holding.security_code);
            const phoneColor = getIconColor("phone", holding.security_code);
            newIconColors.set(`email_${holding.security_code}`, emailColor);
            newIconColors.set(`phone_${holding.security_code}`, phoneColor);
        });

        // Update state with the new icon colors
        setIconColors(newIconColors);
    }, [portfolio?.equityholdings?.results, getIconColor]);

    useEffect(() => {
        updateIconColors();
    }, [user?.asx_announcement_preferences, updateIconColors]);

    const handleUpdateAsxAnnouncementPreference = (type, securityCode) => {
        let formdata = new FormData();
    
        const updatedPreferences = { ...user?.asx_announcement_preferences };
        
        if (updatedPreferences.hasOwnProperty('all_securities')) {
            delete updatedPreferences.all_securities;
        }
    
        // If we already have this security in the preferences, update it
        if (updatedPreferences[securityCode]) {
            const currentPreference = { ...updatedPreferences[securityCode] };
    
            let updatedNotifyBy = { ...currentPreference.notify_by };
    
            // If we already have this type in the notify_by, remove it, otherwise add it
            if (updatedNotifyBy[type]) {
                delete updatedNotifyBy[type];
            } else {
                updatedNotifyBy[type] = type;
            }
    
            // If we have no notify_by left, remove the whole preference
            currentPreference.notify_by = updatedNotifyBy;
            updatedPreferences[securityCode] = currentPreference;
        } else {
            // If we don't have this security in the preferences, add it
            updatedPreferences[securityCode] = {
                security_code: securityCode,
                notify_by: { [type]: type }
            };
        }
    
        // Convert object back to array before sending in the formdata
        formdata.append('preferences', JSON.stringify(Object.values(updatedPreferences)));
    
        updateAsxAnnouncementPreference(formdata)
            .then((res) => {
                toast.success(t('notifications.preferences.success'), {
                    toastId: 'notifications-preferences-success'
                });
                dispatch(setUser(res));
            })
            .catch((err) => {
                toast.error(t('notifications.preferences.error'), {
                    toastId: 'notifications-preferences-error'
                });
            });
    };
    
    var columns = [
        {
            key: 'company',
            title: (
                <Flex
                    sx={{
                        pr: '4px',
                        flex: 1,
                        justifyContent: 'space-between'
                    }}
                >
                    <Box>{t('Company')}</Box>
                    <Box>{t('Status')}</Box>
                </Flex>
            ),
            width: !isTablet ? '325px' : '400px',
            fixed: !isMobile,
            sx: { zIndex: 65 },
            render: investment => {
                const acceptanceDoc = getAcceptanceDoc(investment);

                return (
                    <Grid sx={{
                        gridTemplate: 'auto / 40px auto 50px',
                        alignItems: 'center',
                        width: '100%',
                        pr: '17px'
                    }}>
                        <Circle size="40px" type="box" sx={{
                            boxShadow: 'none',
                            bg: 'transparent'
                        }}>
                            {investment?.deal?.company?.logo?.sizes?.tiny ? (
                                <Image src={investment?.deal?.company?.logo?.sizes?.tiny} sx={{ borderRadius: '50%' }} />
                            ) : (
                                <ImagePlaceholder sx={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%'
                                }} />
                            )}
                        </Circle>
                        <Box pb={0} sx={{
                            position: 'relative',
                            alignItems: 'center',
                            order: [2,2,1]
                        }}>
                            <Heading as="h3" variant="h5" sx={{ whiteSpace: 'normal' }}>
                                {t(investment?.deal.name)}
                            </Heading>
                            <Text as="p" variant="tag" color="dark" sx={{ fontSize: 0, fontWeight: 500 }}>{investment?.deal.asx ? `ASX:${investment?.deal.asx}` : investment.deal.listed_on_alternate_exchange ? `${investment.deal.alternate_exchange}:${investment.deal.alternate_security_code}` : 'Unlisted'}</Text>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'top',
                            justifyContent: 'right',
                            order: 3,
                            textAlign: 'right'
                        }}>
                            {acceptanceDoc && (
                                <Tooltip text={`Download ${acceptanceDoc.label} Document`} sx={{ mr: '12px', zIndex: 65, pt: '1px' }}>
                                    <ThemeLink
                                        as="a"
                                        variant="icon"
                                        href={acceptanceDoc.url}
                                    >
                                        <Icon icon="download" color="dark" />
                                    </ThemeLink>
                                </Tooltip>
                            )}
                            <StatusIcon size="15px" sx={{ textAlign: 'right', zIndex: 65 }} label={investment?.status?.name} status={investment?.status?.id} />
                        </Box>
                        {getRowLink(investment)}
                    </Grid>
                )
            }
        },
        {
            key: 'entity',
            title: 'Entity',
            width: 'unlisted' === type ? '170px' : '140px',
            sx: 'unlisted' === type ? {
                pl: '40px'
            } : {},
            render: investment => investment?.profile && (
                <Box mr={[3,3,4]}>
                    <Text as="p" color="darker" sx={{ fontSize: 1, fontWeight: 'bold', lineHeight: 1 }}>{investment.profile.name}</Text>
                    <Text as="p" color="darker" sx={{ fontSize: 0 }}>{investment.profile.type}</Text>
                </Box>
            )
        },
        {
            key: 'amount_invested',
            title: 'Amount invested',
            width: '144px',
            align: 'right',
            render: investment => investment?.capital && <Text as="p" variant="h5" color="darker" mb={1}>{formatCurrency(investment.capital)}*</Text>
        }
    ];

    if ('listed' === type) {
        columns = [
            ...columns,
            {
                key: 'avg_price',
                title: 'Avg. Price',
                align: 'right',
                render: investment => investment?.avg_price && <Text as="p" variant="h6" color="darker">{formatCurrency(investment.avg_price)}</Text>
            },
            {
                key: 'shares',
                title: 'Shares',
                align: 'right',
                render: investment => <Text as="p" variant="h6" color="darker">{investment?.deal_fulfilled ? (<>{investment?.shares}</>) : <>Not allocated</>}</Text>
            }
        ]

        if ('listed' === type){
            columns = [
                ...columns,
                {
                    key: 'current_price',
                    title: 'Current Price',
                    align: 'right',
                    render: investment => investment?.last_price && <Text as="p" variant="h6" color="darker">${investment?.last_price}</Text>
                },
                {
                    key: 'return_percentage',
                    title: 'Return %',
                    align: 'right',
                    render: investment => investment?.return_percentage && <Text as="p" variant="h6" color="darker">{(investment.return_percentage > 0) && '+'}{investment?.return_percentage}%</Text>
                },
                {
                    key: 'return',
                    title: 'Market Value',
                    align: 'right',
                    render: investment => investment?.return_price && <Text as="p" variant="h5" color={investment.return_price < 0 ? 'loss' : 'tealDark'}>{formatCurrency(investment.return_price)}</Text>
                },
            ]
        }
    } else {
        columns = [
            ...columns,
            {
                key: 'entry_price',
                title: 'Entry Price',
                align: 'right',
                width: '104px',
                render: investment => investment?.deal?.offer_price && <Text as="p" variant="h6" color="darker" mb={1}>${dealOfferPriceFormatting(investment?.deal.offer_price)}</Text>
            }
        ]
    }

    columns = [
        ...columns,
        {
            key: 'arrow',
            title: '',
            align: 'right',
            render: i => <Icon icon="arrow-long" className="table-row__arrow" />,
            sx: {
                flex: 1,
                justifyContent: 'right'
            }
        }
    ]

    return (
        <>
            {Boolean('listed' === type) && (
                <Box mb={4}>
                    <Flex sx={{
                        alignItems: 'center'
                    }}>
                        <Heading as="h3" variant="h3" sx={{ fontSize: '22px' }} mr={3} id="listedShareholdings" ref={equityHeadingRef}>{t('Listed Shareholdings')}</Heading>
                        {portfolio?.equityholdings?.results.length > 0 ? (
                            <>
                                <Icon color="tealDark" icon="tag" size="14px" mr={2} />
                                <Text as="p" color="tealDark" variant="h5">{portfolio?.equityholdings?.meta?.total} {portfolio?.equityholdings?.meta?.total === 1 ? 'holding' : 'holdings'}</Text>
                            </>
                        ) : null}
                    </Flex>

                    <Text as="p" color="text" variant="p" sx={{ fontStyle: 'italic' }} mt={3}>The share prices displayed below are from the end of day.{(JSON.parse(process.env.REACT_APP_TRADE) && user.can_see_trade_link) && <> To view live pricing please go to your <ThemeLink onClick={handleOpentraderOnClick}>trading screen.</ThemeLink></>}</Text>

                    <Table
                        data={portfolio.equityholdings.results}
                        columns={[
                            {
                                key: 'security_code',
                                title: 'Security',
                                align: 'left',
                                render: holding => holding?.security_code && (
                                    <Box sx={{
                                        position: 'relative',
                                        alignItems: 'center',
                                    }}>
                                        <Heading as="h3" variant="h5">
                                            {t(holding?.security_description)}
                                        </Heading>
                                        <Text as="p" variant="tag" color="dark" sx={{ fontSize: 0, fontWeight: 500 }}>{`ASX:${holding?.security_code}`}</Text>
                                    </Box>
                                )
                            },
                            {
                                key: 'asx_announcements',
                                title: 'Announcement Alerts',
                                align: 'center',
                                render: (holding) =>
                                    holding?.security_code && holding?.security_code.length === 3 ? (
                                        <Grid
                                            columns={[2, 'auto auto']}
                                            gap={3}
                                            sx={{
                                                position: 'relative',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Button
                                                variant="icon"
                                                onClick={() => {handleUpdateAsxAnnouncementPreference("email", holding?.security_code)}}
                                            >
                                                <Icon icon="mail" color={iconColors.get(`email_${holding.security_code}`)} />
                                            </Button>
                                            <Button
                                                variant="icon"
                                                onClick={() => {handleUpdateAsxAnnouncementPreference("phone", holding?.security_code)}}
                                            >
                                                <Icon mt={1} icon="mobile" color={iconColors.get(`phone_${holding.security_code}`)} />
                                            </Button>
                                        </Grid>
                                    ) : null
                            },
                            {
                                key: 'amount_invested',
                                title: 'Amount invested',
                                align: 'right',
                                render: holding => holding?.amount_invested && <Text as="p" variant="h6" color="darker">{formatCurrency(holding.amount_invested)}</Text>
                            },
                            {
                                key: 'avg_price',
                                title: 'Avg. Price',
                                align: 'right',
                                render: holding => holding?.avg_price && <Text as="p" variant="h6" color="darker">{formatCurrency(holding.avg_price)}</Text>
                            },
                            {
                                key: 'shares',
                                title: 'Shares',
                                align: 'right',
                                render: holding => holding?.shares && <Text as="p" variant="h6" color="darker">{holding?.shares}</Text>
                            },
                            {
                                key: 'current_price',
                                title: 'Current Price',
                                align: 'right',
                                render: holding => holding?.current_price && <Text as="p" variant="h6" color="darker">{formatCurrency(holding.current_price)}</Text>
                            },
                            {
                                key: 'return_percentage',
                                title: 'Return %',
                                align: 'right',
                                render: investment => investment?.return_percentage && <Text as="p" variant="h6" color={investment.return_percentage < 0 ? 'red' : 'green'}>{(investment.return_percentage > 0) && '+'}{investment?.return_percentage}%</Text>
                            },
                            {
                                key: 'return_price',
                                title: 'Market Value',
                                align: 'right',
                                render: holding => holding?.return_price && <Text as="p" variant="h6" color="darker">{formatCurrency(holding.return_price)}</Text>
                            }
                        ]}
                        prefix={`${type}_table_${activePage}`}
                        isLoading={isEquityHoldingsLoading}
                        rowProps={() => ({
                            sx: {
                                textDecoration: 'none',
                                color: 'darker',
                                '.table-row__arrow': {
                                    transform: 'translateX(-4px)',
                                    transition: 'transform 0.3s ease-in-out'
                                },
                                '&:hover .table-row__arrow': {
                                    transform: 'translateX(0)',
                                    color: 'tealDark'
                                }
                            }
                        })}
                        my={3}
                        minWidth={'listed' === type ? '1200px' : '900px'}
                        emptyTemplate={{
                            icon: 'portfolio',
                            heading: 'Start Building Your Portfolio',
                            content: <Grid sx={{ 
                                        gridTemplateColumns: activeProfile?.trade_account_complete ? '1fr' : ['1fr', '1fr 1fr'],
                                    }}>
                                        <Button
                                            as={NavLink}
                                            to={'/deals'}
                                            sx={{
                                                minWidth: '160px',
                                                mt: '56px'
                                            }}
                                        >
                                            Invest in a Deal
                                        </Button>
                                        { activeProfile?.trade_account_complete ? null : (
                                            <Button
                                                as={NavLink}
                                                to={activeProfile?.type === 'individual' ? '/settings/account' : `/settings/entities/${activeProfile?.uuid}`}
                                                sx={{
                                                    minWidth: '160px',
                                                    mt: ['28px', '56px']
                                                }}
                                            >
                                                Finalise Trade Account
                                            </Button>
                                        )}
                                    </Grid>
                        }}
                    />

                    <Pagination
                        sx={{ my: 3 }}
                        activePage={equityHoldingsPages.activePage ?? 0}
                        pageCount={equityHoldingsPages.pageCount ?? 0}
                        onPageChange={(event) => {
                            const payload = getPortfolioEquityHoldings({
                                params: {
                                    profile: localStorage.getItem('activeProfile') ?? null,
                                    page: (event.selected + 1)
                                }
                            });
                            smoothScroll(equityHeadingRef);
                            setEquityHoldingsLoading(true);

                            dispatch(getPortfolioEquityHoldingsAsync(payload))
                        }}
                    />
                </Box>
            )}

            <Flex sx={{
                alignItems: 'center'
            }}>
                <Heading as="h3" variant="h3" sx={{ fontSize: '22px' }} mr={3} id="listedDeals" ref={headingRef}>{'listed' === type ? 'Listed' : 'Unlisted'} {t('Deal History')}</Heading>
                {dealsToShow?.results.length > 0 ? (
                    <>
                        <Icon color="tealDark" icon="tag" size="14px" mr={2} />
                        <Text as="p" color="tealDark" variant="h5">{dealsToShow?.meta?.total} {dealsToShow?.meta?.total === 1 ? 'deal' : 'deals'}</Text>
                    </>
                ) : null}
            </Flex>

            {'listed' === type && <Text as="p" color="text" variant="p" sx={{ fontStyle: 'italic' }} mt={3}>The share prices displayed below are from the end of day.{(JSON.parse(process.env.REACT_APP_TRADE) && user.can_see_trade_link) && <> To view live pricing please go to your <ThemeLink onClick={handleOpentraderOnClick}>trading screen.</ThemeLink></>}</Text>}

            <Table
                data={dealsToShow.results}
                columns={columns}
                prefix={`${type}_table_${activePage}`}
                isLoading={isLoading}
                rowLink={row => getRowLink(row, { tabIndex: '-1' })}
                rowProps={() => ({
                    sx: {
                        textDecoration: 'none',
                        color: 'darker',
                        '.table-row__arrow': {
                            transform: 'translateX(-4px)',
                            transition: 'transform 0.3s ease-in-out'
                        },
                        '&:hover .table-row__arrow': {
                            transform: 'translateX(0)',
                            color: 'tealDark'
                        }
                    }
                })}
                my={3}
                minWidth={'listed' === type ? '1200px' : '900px'}
                emptyTemplate={{
                    icon: 'portfolio',
                    heading: 'Invest in a deal to start building your portfolio',
                    content: 'Start investing',
                    button: {
                        title: 'See deals',
                        to: '/deals'
                    }
                }}
            />

            <Pagination
                sx={{ my: 3 }}
                activePage={activePage ?? 0}
                pageCount={pageCount ?? 0}
                onPageChange={(event) => {
                    const payload = getPortfolioDeals({
                        params: {
                            profile: localStorage.getItem('activeProfile') ?? null,
                            listed: 'listed' === type ? 1 : 0,
                            page: (event.selected + 1)
                        }
                    });
                    smoothScroll(headingRef);
                    setLoading(true);
                    if ('listed' === type) {
                        dispatch(getPortfolioListedDealsAsync(payload))
                    } else {
                        dispatch(getPortfolioUnlistedDealsAsync(payload))
                    }
                }}
            />
        </>
    )
};
