import { useState, useEffect } from 'react'
import {
    Box,
    Grid,
    Flex
} from '@theme-ui/components';
import moment from 'moment'
import {
    Button,
    WatchlistButton,
    TimeRemaining,
    Tooltip,
    Skeleton
} from '~/Common'
import { Icon } from 'assets/Icon';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { APP_FEATURES, DealStageTypes } from 'app/constants'
import { ShareButton } from '~/Deals';

export const CardButtons = ({
    user,
    deal,
    size = 'tile',
    buttonHeights = ['32px', '100%'],
    link = true,
    ...props
}) => {
    const { t } = useTranslation();
    const [isClosed, setIsClosed] = useState(false);
    const isAdviser = user?.is_adviser === true;
    const buttonText = props.viewButtonText ? props.viewButtonText : deal?.has_offer && !isClosed && user.active && !isAdviser && !deal.preview_mode ? 'View Deal & Bid' : 'View Deal';

    useEffect(() => {
        const interval = setInterval(() => {
            const then = moment(deal?.available_to).utc();

            if(moment().isAfter(then)){
                setIsClosed(true);
            }
        }, 1000);
        return () => clearInterval(interval);

    }, [deal, setIsClosed])

    const buttonStyles = {
        width: '100%',
        height: 'list' === size ? '100%' : 'auto',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: '14px',
        lineHeight: 1
    };

    return (
        <Flex sx={{
            flexDirection: 'list' === size ? 'row' : 'column',
            height: 'list' === size ? '64px' : 'auto'
        }}>
            {deal ? (
                <>
                    <Box sx={{
                        order: 'list' === size ? 1 : 0,
                        ml: 'list' === size ? 3 : 0,
                        flex: 1
                    }}>
                        {!user?.can_see_dashboard ? (
                            <Tooltip text={t('deals.notFinishedOnboarding.tooltip')} sx={{ width: '100%' }}>
                                <Button variant="primary" sx={buttonStyles} mr={3}><Icon icon="closed-deals" width="15px" sx={{ top: '-1px', position: 'relative', mr: 2 }} /> <small>{t('deals.notFinishedOnboarding.label')}</small></Button>
                            </Tooltip>
                        ) : (
                            <Button variant="primary" sx={buttonStyles} mr={3} as={link ? Link : Box} to={link ? `/deals/${deal?.slug}` : null}>{t(buttonText)}</Button>
                        )}
                    </Box>

                    <Grid sx={{
                        width: '100%',
                        gridTemplate: 'list' === size || deal?.preview_mode || !APP_FEATURES.following || props.alternateInvestment ? 'auto / auto' : 'auto / 1fr 1fr',
                        flex: 1,
                        height: buttonHeights,
                        mt: 'list' === size ? 0 : 3
                    }}>
                        {!props.alternateInvestment ? (<Box>
                            <TimeRemaining
                                styled={true}
                                to={moment(deal?.available_to).utc().clone().local()}
                                from={moment(deal?.available_from).utc().clone().local()}
                                showClosed={true}
                                dealPreviewMode={deal?.preview_mode}
                                sx={{
                                    fontSize: '14px',
                                    height: '100%'
                                }}
                            />
                        </Box>) : null }
                        {(size !== 'list' && APP_FEATURES.following && deal?.company?.uuid) ? (
                            <WatchlistButton key={`watchlist-button-${deal?.company?.uuid}`} company={deal?.company?.uuid} withText={true} sx={{ width: '100%' }} fund={deal?.stage?.type === DealStageTypes.fund} />
                        ) : null}
                    </Grid>

                    {!isClosed ? <ShareButton deal={deal} user={user} size={size} buttonStyles={buttonStyles} /> : null}
                </>
            ) : (
                <>
                    <Grid sx={{
                        width: '100%',
                        gridTemplate: 'list' === size ? 'auto / 1fr 1fr' : 'auto / auto',
                        flex: 1,
                        height: buttonHeights,
                        mt: 'list' === size ? 0 : 3
                    }}>
                        <Skeleton variant="button" height="48px" />
                        <Skeleton variant="button" height="48px" />
                    </Grid>
                </>
            )}
        </Flex>
    )
};
