import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getArticle } from './articleAPI'

export const getCurrentArticleAsync = createAsyncThunk(
    'articles/currentArticle',
    async (slug) => {
        try{
            const response = await getArticle(slug);
            return response;
        }catch(e){
            // trigger event for livewire
            var notFound = new CustomEvent('notFound', { detail: { method: 'articles/currentArticle', slug: slug } });
            window.dispatchEvent(notFound);
            throw new Error(e);
        }
    }
);

const initialState = {
    current: {
        status: 'idle',
        data: null,
    },
};


const articles = createSlice({
    name: 'articles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCurrentArticleAsync.pending, (state) => {
                    state.current.status = 'loading';
                })
                .addCase(getCurrentArticleAsync.rejected, (state) => {
                    state.current.data = null;
                    state.current.status = 'attempted';
                })
                .addCase(getCurrentArticleAsync.fulfilled, (state, {payload}) => {
                    state.current.data = payload?.data;
                    state.current.status = 'attempted';
                });
    },
});

export { getArticle }
export default articles.reducer;
