import { useState } from 'react';
import { Box } from '@theme-ui/components'
import { Icon } from 'assets/Icon';

export const Accordion = ({ children, isOpen: open, title, emptyTitle = 'Untitled', ...props }) => {
    const [isOpen, setIsOpen] = useState(open);
    return (
        <Box {...props} sx={{
            bg: 'light',
            mb: 3,
            borderRadius: 4,
            transition: 'background-color 0.3s ease-in-out',
            '&:hover': {
                bg: !isOpen ? 'secondaryLight' : 'light',
            }
        }}>
            <Box
                onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                }}
                sx={{
                    cursor: 'pointer',
                    p: 3,
                    height: '56px',
                    fontWeight: isOpen ? 600 : 400,
                    display: 'flex',
                }}
            >
                <Box sx={{ flex: 1 }}>{title || emptyTitle}</Box>
                <Icon
                    sx={{
                        ml: 2,
                        transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.2s 0.1s ease-in-out'
                    }}
                    icon="dropdown"
                    size="10px"
                />
            </Box>
            <Box sx={{
                display: isOpen ? 'block' : 'none',
                px: 3,
                pb: isOpen ? 3 : 0,
            }}>
                {children}
            </Box>
        </Box>
    );
};
