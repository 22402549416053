import React, {
    useState,
    forwardRef,
    useRef,
    useEffect,
    useCallback
} from 'react';
import { useField, useFormikContext } from 'formik';
import { Box, Link, Flex } from 'theme-ui';
import { Icon } from 'assets/Icon';
import { Label } from '~/Forms/Label';
import { useDebounce } from 'hooks/useDebounce';
import _debounce from 'lodash.debounce';
import SignaturePad from 'signature_pad';
import { CanvasStyled } from './styled';

// forward ref so we can focus with parent components
export const SignatureField = forwardRef(
    ({
        label,
        name,
        apiName,
        type,
        className,
        placeholder,
        disabled,
        required,
        validIndicator,
        validationError,
        showLabel,
        onChange,
        onChangeOverride,
        validation,
        size,
        sx,
        ...props
    }, ref) => {
        /* We have to use FormikContext, as the useField helpers causes infinite renders within useEffect
            https://github.com/jaredpalmer/formik/issues/2268 */
        const { setFieldValue, setFieldTouched } = useFormikContext();
        const [field, meta] = useField(name);

        const [focused, setFocused] = useState(false);
        const [isHovered, setHovered] = useState(false);
        const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);
        const isEmpty = typeof meta.value !== 'string' || !meta.value;
        const fieldFocusedOrFilled = focused || !isEmpty || placeholder !== '';
        const signaturePad = useRef(null);
        const canvasRef = useRef(null);
        const isInvalid = (required && isEmpty) || meta.error !== undefined;

        const fieldProps = { ...field, ...props };

        const resizeCanvas = useCallback(() => {
            if (signaturePad.current) {
                const ratio = Math.max(window.devicePixelRatio || 1, 1);
                canvasRef.current.width = canvasRef.current.offsetWidth * ratio;
                canvasRef.current.height = canvasRef.current.offsetHeight * ratio;
                canvasRef.current.getContext("2d").scale(ratio, ratio);
                signaturePad.current.clear();
            }
        }, []);

        const clearCanvas = () => {
            if (signaturePad.current) {
                signaturePad.current.clear();
                setFieldValue(name, null);
            }
        }

        const onSignatureChange = useCallback((e) => {
            if (signaturePad.current) {
                const value = signaturePad.current.toDataURL();
                const empty = signaturePad.current.isEmpty();

                setFieldTouched(name, true);

                if (onChangeOverride) {
                    onChangeOverride(e);
                } else {
                    setFieldValue(name, empty ? null : value);

                    onChange &&
                        debouncedSave({
                            name: apiName,
                            value: value === null ? '' : value,
                        });
                }
            }
        }, [
            apiName,
            debouncedSave,
            onChange,
            onChangeOverride,
            setFieldValue,
            setFieldTouched,
            name
        ]);

        useEffect(() => {
            window.addEventListener('resize', resizeCanvas);

            return () => {
                window.removeEventListener('resize', resizeCanvas);
            }
        }, [resizeCanvas]);

        useEffect(() => {
            if (canvasRef.current) {
                signaturePad.current = new SignaturePad(canvasRef.current);
                signaturePad.current.addEventListener('afterUpdateStroke', _debounce(onSignatureChange, 500));
                resizeCanvas();
            }
        }, [resizeCanvas, onSignatureChange]);

        return (
            <Box sx={{
                position: 'relative',
                pointerEvents: disabled ? 'none' : 'all',
                ...sx
            }}>
                <Label
                    label={label}
                    showLabel={showLabel}
                    fieldFocusedOrFilled={fieldFocusedOrFilled}
                    field={field}
                    placeholder={placeholder}
                    meta={meta}
                    validationError={validationError}
                    focused={focused || isHovered}
                />

                <CanvasStyled
                    {...fieldProps}
                    ref={canvasRef}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                />
                <Flex sx={{ justifyContent: 'flex-end' }}>
                    <Link color="dark" onClick={clearCanvas}>Reset</Link>
                </Flex>

                {validIndicator === true && isInvalid && (
                    <Icon
                        icon="cross"
                        color="error"
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 80
                        }}
                    />
                )}
            </Box>
        );
    }
);

SignatureField.defaultProps = {
    validationError: true,
    validIndicator: false,
    required: true,
    size: 'default',
    disabled: false
};
