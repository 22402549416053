import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    getPortfolioEquityHoldings,
    getPortfolioDeals,
    getPortfolioStats
} from './portfolioAPI'

export const getPortfolioEquityHoldingsAsync = createAsyncThunk(
    'deals/getPortfolioEquityHoldings',
    async (payload) => {
        const params = {
            params: {
                profile: localStorage.getItem('activeProfile') ?? null
            }
        };
        const response = payload === undefined ? await getPortfolioEquityHoldings(params) : payload;
        return response;
    }
);

export const getPortfolioListedDealsAsync = createAsyncThunk(
    'deals/getPortfolioListedDeals',
    async (payload) => {
        const params = {
            params: {
                listed: 1,
                profile: localStorage.getItem('activeProfile') ?? null
            }
        };
        const response = payload === undefined ? await getPortfolioDeals(params) : payload;
        return response;
    }
);

export const getPortfolioUnlistedDealsAsync = createAsyncThunk(
    'deals/getPortfolioUnlistedDeals',
    async (payload) => {
        const params = {
            params: {
                profile: localStorage.getItem('activeProfile') ?? null
            }
        };
        const response = payload === undefined ? await getPortfolioDeals(params) : payload;
        return response;
    }
);

export const getPortfolioStatsAsync = createAsyncThunk(
    'deals/getPortfolioStats',
    async (payload) => {
        const params = {
            params: {
                profile: localStorage.getItem('activeProfile') ?? null
            }
        };
        const response = payload === undefined ? await getPortfolioStats(params) : payload;
        return response;
    }
);

const initialState = {
    deals: {
        listed: {
            status: 'idle',
            results: [],
            meta: []
        },
        unlisted: {
            status: 'idle',
            results: [],
            meta: []
        }
    },
    equityholdings: {
        status: 'idle',
        results: [],
        meta: []
    },
    stats: {
        status: 'idle',
        counts: {
            invested: 0,
            day_gain: 0,
            total_gain: 0,
            investments_by_theme: [],
            total_investments: 0
        }
    }
};


const portfolio = createSlice({
    name: 'portfolio',
    initialState,
    reducers: {
        setPortfolioStatus: (state, action) => {
            state.deals.listed.status = action.payload;
            state.deals.unlisted.status = action.payload;
            state.stats.status = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPortfolioEquityHoldingsAsync.pending, (state, { payload }) => {
                    state.equityholdings.status = 'loading';
                })
                .addCase(getPortfolioEquityHoldingsAsync.rejected, (state) => {
                    state.equityholdings.results = [];
                    state.equityholdings.meta = [];
                    state.equityholdings.status = 'attempted';
                })
                .addCase(getPortfolioEquityHoldingsAsync.fulfilled, (state, { payload }) => {
                    state.equityholdings.results = payload?.data;
                    state.equityholdings.meta = payload?.meta;
                    state.equityholdings.status = 'attempted';
                })
                .addCase(getPortfolioListedDealsAsync.pending, (state, {payload}) => {
                    state.deals.listed.status = 'loading';
                })
                .addCase(getPortfolioListedDealsAsync.rejected, (state) => {
                    state.deals.listed.results = [];
                    state.deals.listed.meta = [];
                    state.deals.listed.status = 'attempted';
                })
                .addCase(getPortfolioListedDealsAsync.fulfilled, (state, {payload}) => {
                    state.deals.listed.results = payload?.data;
                    state.deals.listed.meta = payload?.meta;
                    state.deals.listed.status = 'attempted';
                })
                .addCase(getPortfolioUnlistedDealsAsync.pending, (state, {payload}) => {
                    state.deals.unlisted.status = 'loading';
                })
                .addCase(getPortfolioUnlistedDealsAsync.rejected, (state) => {
                    state.deals.unlisted.results = [];
                    state.deals.unlisted.status = 'attempted';
                })
                .addCase(getPortfolioUnlistedDealsAsync.fulfilled, (state, {payload}) => {
                    state.deals.unlisted.results = payload?.data;
                    state.deals.unlisted.meta = payload?.meta;
                    state.deals.unlisted.status = 'attempted';
                })
                .addCase(getPortfolioStatsAsync.pending, (state, {payload}) => {
                    state.stats.status = 'loading';
                })
                .addCase(getPortfolioStatsAsync.rejected, (state) => {
                    state.stats.counts = {...initialState.stats.counts};
                    state.stats.status = 'attempted';
                })
                .addCase(getPortfolioStatsAsync.fulfilled, (state, {payload}) => {
                    state.stats.counts = payload?.data;
                    state.stats.status = 'attempted';
                });
    },
});

export {
    getPortfolioEquityHoldings,
    getPortfolioDeals,
    getPortfolioStats
}
export const { setPortfolioStatus } = portfolio.actions;
export default portfolio.reducer;
