import { useEffect } from 'react';
import { Container, AnimatedTexture } from '~/Common';
import { PageBanner as PageBannerStyled } from './styled';
import { useDispatch } from 'react-redux';
import { setTransparentHeader } from 'features/theme/themeSlice';

export const PageBanner = ({ children, variant, ...props }) => {
    const dispatch = useDispatch();

    // Set header to transparent on mount
    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY >= 40) {
                dispatch(setTransparentHeader(false));
            } else {
                dispatch(setTransparentHeader(true));
            }
        };

        document.addEventListener("scroll", onScroll, false);
        onScroll();

        return () => {
            document.removeEventListener("scroll", onScroll, false);
            dispatch(setTransparentHeader(false));
        }
    }, [dispatch]);

    return (
        <PageBannerStyled {...props}>
            <AnimatedTexture sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                zIndex: 1
            }} />
            <Container variant={variant ?? 'layouts.pageBanner'} sx={{ position: 'relative', zIndex: 10 }}>
                {children}
            </Container>
        </PageBannerStyled>
    );
};
