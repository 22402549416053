import React from 'react';
import * as Yup from 'yup';
import {
    Alert,
    Box,
    Flex,
    Close,
    Heading,
    Themed,
    Text
} from 'theme-ui';
import { Submit } from '~/Forms/Submit';
import { Form, Formik } from 'formik';
import { Field } from '~/Forms/Field';
import { Layout } from '~/Layouts';
import { Container } from '~/Common';
import { toast } from 'utils/toast';
import { passwordReset } from 'features/auth/authSlice';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from "react-router-dom";
import { useSearchParams } from 'hooks/useSearchParams';
import { useResponsiveValue } from '@theme-ui/match-media';
import { grabFirstError } from 'utils/helpers'
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required().test('len', 'must be at least 8 characters', val => val !== undefined && val.length >= 8),
    password_confirmation: Yup.string().required('required field').oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const PasswordReset = (props) => {
    const token = props.match.params.token;
    const { t } = useTranslation();
    useDocumentTitle(t('Reset Password'));
    const history = useHistory();
    let query = useSearchParams();
    const isMobile = useResponsiveValue([true, true, false]);

    const handleSubmit = (values, actions) => {
        passwordReset({
            email: values.email,
            token: token,
            password: values.password,
            password_confirmation: values.password_confirmation
        }).then((response) => {
            toast.success(response?.data?.message || t('passwordReset.success'), {
                toastId: 'passwordReset',
            });
            actions.setSubmitting(false);
            actions.resetForm({
                values: {
                    email: '',
                    password: '',
                    password_confirmation: ''
                }
            })
            history.push('/');
        }).catch(({ response }) => {
            localStorage.removeItem('authToken');
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(grabFirstError(response?.data?.errors) || t('passwordReset.error'), {
                toastId: 'passwordReset',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    };

    return (
        <Layout>
            <Container>
                <Formik
                    initialValues={{
                        email: query.get('email'),
                        password: '',
                        password_confirmation: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ status, setStatus }) => (
                        <Box pt={5} sx={{
                            borderTop: isMobile ? '1px solid' : null,
                            borderColor: isMobile ? 'light' : null,
                        }}>
                            { status === 'api_error' ?
                            <Alert role="alert" variant='danger' mb={2}>
                                {t('passwordReset.error')}
                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                            </Alert> : ''}

                            <Form mb={2}>
                                <Heading as="h1" variant="publicH1" mb={4}>
                                    {t('passwordReset.title', { site: process.env.REACT_APP_NAME })}
                                </Heading>

                                <Text as="p" mb={3}>
                                    {t('passwordReset.subtitle')}
                                </Text>

                                <Field
                                    label="Email"
                                    name="email"
                                    type="email"
                                />

                                <Field
                                    label="Password"
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                />

                                <Field
                                    label="Password Confirmation"
                                    name="password_confirmation"
                                    type="password"
                                    autoComplete="new-password"
                                />

                                <Flex sx={{ justifyContent: 'center' }}>
                                    <Submit variant="primary" text={t('buttons.submit')} />

                                    <Flex ml={'auto'} sx={{ justifyContent: 'center', mt: 2 }}>
                                        <Text as="span">
                                            <Themed.a as={Link} to="/"> {t('Back to login')}</Themed.a>
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Form>
                        </Box>
                    )}
                </Formik>
            </Container>
        </Layout>
    );
};

export default PasswordReset;
