import React from 'react';
import * as Yup from 'yup';
import {
    Alert,
    Box,
    Close,
    Flex,
    Heading,
    Themed,
    Text
} from 'theme-ui';
import { Submit } from '~/Forms/Submit';
import { Form, Formik } from 'formik';
import { Field } from '~/Forms/Field';
import { Layout } from '~/Layouts';
import { Container } from '~/Common';
import { toast } from 'utils/toast';
import { forgotPassword } from 'features/auth/authSlice';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { useResponsiveValue } from '@theme-ui/match-media';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required()
});

const ForgotPassword = (props) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Forgot Password'));
    const isMobile = useResponsiveValue([true, true, false]);

    const handleSubmit = (values, actions) => {
        forgotPassword({
            email: values.email
        }).then((response) => {
            toast.success(response?.data?.message || t('forgotPassword.success'), {
                toastId: 'forgotPassword',
            });
            actions.setSubmitting(false);
        }).catch(({ response }) => {
            localStorage.removeItem('authToken');
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(response?.data?.message || t('forgotPassword.error'), {
                toastId: 'forgotPassword',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    };

    return (
        <Layout>
            <Container>
                <Formik
                    initialValues={{
                        email: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ status, setStatus }) => (
                        <Box pt={5}  sx={{
                            borderTop: isMobile ? '1px solid' : null,
                            borderColor: isMobile ? 'light' : null,
                        }}>
                            { status === 'api_error' ?
                            <Alert role="alert" variant='danger' mb={2}>
                                {t('forgotPassword.error')}
                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                            </Alert> : ''}

                            <Form mb={2}>
                                <Heading as="h1" variant="publicH1" mb={4}>
                                    {t('forgotPassword.title', { site: process.env.REACT_APP_NAME })}
                                </Heading>

                                <Text as="p" mb={3}>
                                    {t('forgotPassword.subtitle')}
                                </Text>

                                <Field
                                    label="Email"
                                    name="email"
                                    type="email"
                                />

                                <Flex sx={{ justifyContent: 'center' }}>
                                    <Submit variant="primary" text={t('buttons.submit')} />

                                    <Flex ml={'auto'} sx={{ justifyContent: 'center', mt: 2 }}>
                                        <Text as="span">
                                            <Themed.a as={Link} to="/"> {t('Back to login')}</Themed.a>
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Form>
                        </Box>
                    )}
                </Formik>
            </Container>
        </Layout>
    );
};

export default ForgotPassword;
