import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Text, Link } from "theme-ui";
import { Field } from "..";
import { Icon } from 'assets/Icon';

const RegistrationDetailsUpload = ({ uploadProgress, setStatus, field, handleUpload, setShowUpload, showUpload, uploadName }) => {
    const { t } = useTranslation();

    return (
        showUpload ? (
            <>
                <Field
                    field="upload"
                    name={field.name}
                    label={field.label}
                    showLabel={false}
                    uploadProgress={uploadProgress}
                    onChange={(props) => handleUpload({ setStatus, ...props })}
                />
                <Box mb={4}>
                    <Link to="#cancel" onClick={(e) => {
                        e.preventDefault();
                    }}>{t('Cancel upload')}</Link>
                </Box>
            </>
        ) : (
            <>
                <Flex sx={{ alignItems: 'center', mb: 2, gridGap: 3, flexDirection: 'column' }}>
                    {uploadProgress === 100 &&
                        <Box variant="styles.captionWrapper" sx={{ py: '13px' }}>
                            <Flex sx={{ alignItems: 'center' }}>
                                <Icon icon="tick" color="tealDark" width="auto" height="9px" sx={{ mr: 2 }} />
                                <Text variant="caption">{t(`${uploadName} provided`)}</Text>
                            </Flex>
                        </Box>
                    }
                    <Button variant="light" onClick={() => setShowUpload(true)}>{t(uploadProgress === 100 ? `Update ${uploadName}` : `Supply ${uploadName}`)}</Button>
                </Flex>
            </>
        )
    );
}

export default RegistrationDetailsUpload;