import { Icon } from "assets/Icon";
import { Link } from "react-router-dom";
import { Box, Grid } from "theme-ui";

export const Steps = ({ items, sx, ...props }) => {
    // Find idx of first step in items that is not complete
    const firstIncompleteStep = items.findIndex((step) => !step.complete);
    
    return (
        <Grid sx={{
            borderLeft: '1px solid',
            borderLeftColor: 'medium',
            paddingLeft: '32px',
            gridGap: 2,
            ...sx,
        }} {...props}>
            {items.map((step, index) => (
                <Box key={index}>
                    {Boolean(step?.action && !step.complete) ? (
                        <Box as={Link} sx={{
                            padding: '21px',
                            bg: step.complete ? 'tealLight' : 'white',
                            fontSize: '20px',
                            borderRadius: 8,
                            border: firstIncompleteStep === index ? '1px solid #1de9d5' : 'none',
                            position: 'relative',
                            textDecoration: 'none',
                            '&:hover, &:focus': {
                                bg: 'tealLight'
                            }
                        }}
                        to={step.action}
                        >
                            <Icon
                                icon={step.complete ? 'tick-circle' : 'clock'}
                                color={step.complete ? 'green' : 'medium'}
                                size="17px"
                                strokeWidth="2"
                                sx={{
                                    position: 'absolute',
                                    left: '-32px',
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    bg: 'light',
                                }}
                            />
                            {step.label}
                        </Box>
                    ) : (
                        <Box sx={{
                            padding: '21px',
                            bg: step.complete ? 'tealLight' : 'white',
                            fontSize: '20px',
                            borderRadius: 8,
                            position: 'relative',
                            border: firstIncompleteStep === index ? '1px solid #1de9d5' : 'none',
                        }}>
                            <Icon
                                icon={step.complete ? 'tick-circle' : 'clock'}
                                color={step.complete ? 'green' : 'medium'}
                                size="24px"
                                strokeWidth="2"
                                sx={{
                                    position: 'absolute',
                                    left: '-32px',
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    bg: 'light',
                                }}
                            />
                            {step.label}
                        </Box>
                    )}
                </Box>
            ))}
        </Grid>
    );
}
