import {
    Box,
    Grid,
    Heading,
    Image
} from '@theme-ui/components'
import { Container, Markdown } from '~/Common'
import { useTranslation } from 'react-i18next'
import { useResponsiveValue } from '@theme-ui/match-media'

const renderImage = (data, isMobile, position) => {
    const isTopBottom = ['bottom', 'top'].includes(position)
    return (
        <Box sx={{
            position: 'relative',
            overflow: 'hidden',
            '&:focus': {
                outline: '3px solid',
                outlineColor: 'tealDark',
                outlineOffset: '3px'
            }
        }}>
            <Image
                src={isMobile || isTopBottom ? data?.image?.sizes?.medium : ( data?.image?.sizes?.text_image ? data.image.sizes.text_image : data?.image?.sizes?.medium )}
                alt={data?.image_alt}
                sx={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    borderRadius: '16px'
                }}
            />

            {data?.image_caption && (
                <Box
                    className="caption"
                    sx={{
                        mt: 2,
                        color: 'dark'
                    }}
                >
                    {data.image_caption}
                </Box>
            )}
        </Box>
    );
};

export const TextAndImage = ({ bg = 'white', data, container, ...props }) => {
    const { t } = useTranslation();
    const title = data?.title ?? null;
    const position = data?.image_position ?? 'left';
    const isTop = ['left', 'top'].includes(position);
    const isBottom = ['right', 'bottom'].includes(position);
    const isHorizontal = ['left', 'right'].includes(position);
    const isMobile = useResponsiveValue([true, false, false, false]);

    return (
        <Box bg={bg} {...props}>
            <Container variant="layouts.section" {...container}>
                { title && <Heading as="h2" variant="content_block_heading">{t(title)}</Heading>}

                <Grid sx={{
                    gridGap: 4,
                    gridTemplateColumns: ['1fr', isHorizontal ? 'repeat(2, 1fr)' : '1fr'],
                    width: '100%'
                }}>
                    {Boolean(data?.image && isTop) && (
                        <>{renderImage(data, isMobile, position)}</>
                    )}

                    { data?.content && (
                        <Markdown className="leftContent" showReadMore={!!data?.showReadMore}>{data?.content}</Markdown>
                    )}

                    {Boolean(data?.image && isBottom) && (
                        <>{renderImage(data, isMobile, position)}</>
                    )}
                </Grid>
            </Container>
        </Box>
    )
};
