import axios from 'axios'

export const getNotifications = (params) =>
    axios.get('notifications', params)
        .then(({ data }) => data)

export const markAllNotificationsAsRead = () =>
    axios.get('notifications/all/read')
        .then(({ data }) => data)

export const markNotificationAsRead = (id) =>
    axios.get(`notifications/${id}/read`)
        .then(({ data }) => data)

export const markNotificationAsUnread = (id) =>
    axios.get(`notifications/${id}/unread`)
        .then(({ data }) => data)

export const removeNotification = (id) =>
    axios.delete(`notifications/${id}`)
        .then(({ data }) => data)

export const storeNotificationPreferences = (params) =>
    axios.post('notifications/preferences', params)
        .then(({ data }) => data)

export const updateAsxAnnouncementPreference = (params) =>
    axios.post('/notifications/asx-announcements/preferences', params)
        .then(({ data }) => data);

export const toggleAsxAnnouncementPreferences = (params) =>
    axios.post('/notifications/asx-announcements/toggle', params)
        .then(({ data }) => data);
