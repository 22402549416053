import { useCallback, useEffect, useState } from 'react'
import {
    Box
} from '@theme-ui/components'
import { useTranslation } from 'react-i18next';

export const NewsAnchor = ({ article, ...props }) => {
    const { t } = useTranslation();
    const [showing, setShowing] = useState(false);
    const [articleProgress, setArticleProgress] = useState(0);

    const listenScroll = useCallback(() => {
        const offset = {
            top: 450,
            bottom: 600
        }

        if(window.pageYOffset > offset.top){
            setShowing(true);
        }else{
            setShowing(false);
        }

        const scrollTop = document.scrollingElement.scrollTop - offset.top;
        const articleHeight = document.body.clientHeight - window.innerHeight - offset.bottom;

        setArticleProgress((scrollTop / articleHeight) * 100);
    }, []);


    useEffect(() => {
        window.addEventListener('scroll', listenScroll)
        return () => window.removeEventListener("scroll", listenScroll)
    }, [ listenScroll ]);

    return (
        <Box sx={{
            top: t => t.styles.header.heightSmall,
            transform: showing ? 'translate3d(0,0,0)' : 'translate3d(0,-200%,0)',
            position: 'fixed',
            width: '100%',
            zIndex: 99,
            transition: 'transform 0.3s ease-in-out',
            bg: '#fff',
            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)',
            pb: '4px',
        }}>
            <Box sx={{
                maxWidth: '1440px',
                m: '0 auto',
                py: '12px',
            }}>
                {t('news.anchor.youAreReading')} <b>{article?.name}</b>
            </Box>
            <Box sx={{
                    position: 'absolute',
                    height: '4px',
                    left: 0,
                    bottom: 0,
                    right: 0,
                    bg: 'secondaryLight'
                }}>
                    <Box sx={{
                        position: 'absolute',
                        bg: 'teal',
                        height: '100%',
                        width: `${articleProgress}%`,
                        transition: 'width 0.05s ease-in-out',
                    }}>&nbsp;</Box>
                </Box>
        </Box>
    );
};
