import React from 'react';
import { DashboardLayout } from '~/Layouts';
import {
    Container,
    PageHeader
} from '~/Common';
import {
    Heading,
    Text,
    Link
} from '@theme-ui/components'
import { useTranslation } from 'react-i18next';
import { logoutAsync } from "features/auth/authSlice";
import { useDispatch, connect } from 'react-redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { user: state.user?.user }
}

const AdviserDealsView = ({ user }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Adviser Deal View'));
    const dispatch = useDispatch();

    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(logoutAsync());
    };

    return (
        <DashboardLayout p={0}>
            <PageHeader>
                <Container variant="layouts.default">
                    <Heading as="h1" mb={4}>{t('dashboard.welcome', { name: user.first_name })}</Heading>
                </Container>
            </PageHeader>
            <Container variant="layouts.default">
                <Text as="p">{t('Content goes here')}</Text>

                <Link as="a" href="#logout" onClick={handleLogout}>
                    {t('Logout')}
                </Link>
            </Container>
        </DashboardLayout>
    );
};

export default connect(mapStateToProps, null)(AdviserDealsView);
