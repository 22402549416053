import { createRef } from 'react';
import axios from 'axios'
import * as Yup from 'yup';
import { DashboardLayout } from '~/Layouts';
import {
    Alert,
    Container,
    PageHeader
} from '~/Common';
import { toast } from 'utils/toast';
import { Heading } from '@theme-ui/components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Field, Submit } from '~/Forms';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { user: state.user?.user }
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email().required('Email is required'),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required')
})

const Contact = ({ user }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Contact'));
    const recaptchaRef = createRef();
    const recaptchaEnabled = process.env.REACT_APP_GOOGLE_RECAPTCHA === 'true';

    const handleSubmit = (values, actions, setFieldError) => {
        (async () => {
            let formData = {
                name: values.name,
                email: values.email,
                subject: values.subject,
                message: values.message,
                device_name: 'App'
            };
            let token = null;

            actions.setStatus(null)

            if (recaptchaEnabled) {
                token = recaptchaRef.current.getValue();
                if(!token) {
                    token = await recaptchaRef.current.executeAsync();
                }
            }

            if (token) {
                formData['recaptcha'] = token;
            }

            // if reCaptcha validates then proceed to try and validate code
            if (token || !recaptchaEnabled) {
                axios.post('enquiry', formData)
                    .then((response) => {
                        actions.resetForm()
                        actions.setStatus('success')
                    })
                    .catch((e, data) => {
                        if ('object' === typeof e?.response?.data?.errors) {
                            actions.setErrors(e.response.data.errors)
                        } else {
                            actions.setStatus('error')
                        }
                    })
                    // .finally(() => actions.setSubmitting(false))
            } else {
                actions.setStatus('error')
                toast.error(t('recaptcha.error'), {
                    toastId: 'recaptcha-error',
                });
                setFieldError('recaptcha', t('recaptcha.error'));
            }
        })();
    }

    return (
        <DashboardLayout p={0}>
            <PageHeader>
                <Container size="500px" variant="layouts.default">
                    <Heading as="h1">{t('contact.heading')}</Heading>
                </Container>
            </PageHeader>
            <Container size="500px" variant="layouts.small">
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: `${user.first_name ?? ''} ${user.last_name ?? ''}`.trim(),
                        email: user.email,
                        subject: '',
                        message: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validateOnMount={true}
                >
                    {({ status }) => (
                        <>
                            {status === 'success' && (
                                <Alert role="alert" variant='success' mb={4}>
                                    {t('contact.messages.success')}
                                </Alert>
                            )}
                            {status === 'error' && (
                                <Alert role="alert" variant='danger' mb={4}>
                                    {t('contact.messages.error')}
                                </Alert>
                            )}
                            <Form>
                                <Field
                                    name="name"
                                    label={t("Your name")}
                                />
                                <Field
                                    name="email"
                                    label={t("Email address")}
                                    type="email"
                                />
                                <Field
                                    name="subject"
                                    label={t("Subject")}
                                />
                                <Field
                                    name="message"
                                    label={t("Your message")}
                                    field="textarea"
                                />
                                {recaptchaEnabled &&
                                    <Field
                                        field="recaptcha"
                                        name="recaptcha"
                                        inputRef={recaptchaRef}
                                    />
                                }
                                <Submit
                                    variant="primary"
                                    sx={{ width: '100%' }}
                                    text={t('contact.submit')}
                                />
                            </Form>
                        </>
                    )}
                </Formik>
            </Container>
        </DashboardLayout>
    );
};

export default connect(mapStateToProps, null)(Contact);
