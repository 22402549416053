import { forwardRef } from 'react';
import {
    Box,
    Button,
    Text
} from '@theme-ui/components';
import { Icon } from 'assets/Icon';
import { MenuDialog as MenuDialogStyled, MenuUl } from './styled';
import { useTranslation } from 'react-i18next';

export const MenuItem = ({ label, name, icon, ...props }) => {
    const { t } = useTranslation();

    return (
        <li>
            <Button variant="link" {...props}>
                {icon && <Icon sx={{ color: 'tealDark' }} icon={icon} size="14px" className="header__navIcon" mr={3} />}
                <Text className="header__navText">{t(label)}</Text>
            </Button>
        </li>
    );
};

export const MenuDialog = forwardRef(({ isOpen, items, ...props }, ref) => {
        return (
        <MenuDialogStyled ref={ref} role="dialog" tabIndex="-1" className={isOpen ? 'open' : ''} {...props}>
            <Box sx={{ overflowY: 'auto' }}>
                <MenuUl>
                    {Array.isArray(items) && items.map(item => (
                        <MenuItem
                            key={item.name}
                            {...item}
                        />
                    ))}
                </MenuUl>
            </Box>
        </MenuDialogStyled>
    );
});
