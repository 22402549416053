import { Fragment } from 'react';
import { Box, Link, Text } from '@theme-ui/components';
import { Link as RouterLink } from 'react-router-dom'
import { Icon } from 'assets/Icon';

export const Breadcrumbs = ({ items = [], title, sx, ...props }) => {



    return (
        <Box sx={{
            mb: 4,
            ...sx
        }}>
            {Array.isArray(items) && items.map((item, key) => (
                <Fragment key={key}>
                    <Link variant="breadcrumb" as={RouterLink} to={item.to}>{item.title}</Link>
                    <Icon icon="breadcrumb-seperator" mx={2} />
                </Fragment>
            ))}
            <Text sx={{
                fontSize: 1,
                color: 'dark'
            }}>{title}</Text>
        </Box>
    );
};
