import { useEffect, useState } from 'react'
import {
    Box,
    Heading
} from '@theme-ui/components'
import { Container, Markdown } from '~/Common'
import { useTranslation } from 'react-i18next'

export const Disclaimer = ({ bg = 'light', title = 'Disclaimer', container, ...props }) => {
    const { t } = useTranslation();
    const [disclaimer, setDisclaimer] = useState('');

    useEffect(() => {
        const getContent = async () => {
            const markdown = require('./content.md');
            const response = await fetch(markdown.default);
            const content = await response.text();
            setDisclaimer(content);
        };
        getContent();
    },[setDisclaimer]);

    return (
        <Box bg={bg} {...props}>
            <Container variant="layouts.section" {...container}>
                { title && <Heading as="h2" variant="h5" mb={3} sx={{ fontWeight: 'bold' }}>{t(title)}</Heading>}
                <Box className="markdown disclaimer">
                    <Markdown>{disclaimer}</Markdown>
                </Box>
            </Container>
        </Box>
    )
};
