import {
    Box,
    Grid,
    Flex,
    Heading,
    Text
} from '@theme-ui/components'
import { Container } from '~/Common'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { Icon } from 'assets/Icon';

export const OfferDetails = ({ bg = 'white', data, container, ...props }) => {
    const { t } = useTranslation();
    const title = data?.title ?? 'Offer Details';
    const deal = useSelector((state) => state.deals?.currentDeal);

    return (
        <Box bg={bg} {...props}>
            <Container variant="layouts.section" {...container}>
                { title && <Heading as="h2" variant="content_block_heading">{t(title)}</Heading>}

                <Grid sx={{
                    gridTemplate: ['auto/auto', 'auto / repeat(2, 1fr)'],
                    gridGap: '40px'
                }}>
                    {deal?.offer_price && (
                        <>
                            <Flex>
                                <Icon icon="dollar-thin" color="#D5D9DA" size="39px" mr={3} />
                                <Box>
                                    <Text as="p" variant="small" color="dark">Offer Price (Per Share)</Text>
                                    <Text as="p" variant="price">A${deal?.offer_price}</Text>
                                </Box>
                            </Flex>
                            {deal?.offer_size &&
                                <Flex>
                                    <Icon icon="chart" color="#D5D9DA" size="35px" mr={3} />
                                    <Box>
                                        <Text as="p" variant="small" color="dark">Offer Size</Text>
                                        <Text as="p" variant="price" sx={{ fontWeight: 'bold' }}>A${deal?.offer_size}</Text>
                                    </Box>
                                </Flex>
                            }
                        </>
                    )}
                    <Flex>
                        <Icon icon="stack" color="#D5D9DA" size="35px" mr={3} />
                        <Box>
                            <Text as="p" variant="small" color="dark">Shares Available</Text>
                            <Text as="p" variant="price" sx={{ fontWeight: 'bold' }}>{deal?.shares_available}</Text>
                        </Box>
                    </Flex>
                    {deal?.asx_discount &&
                        <Flex>
                            <Icon icon="stack" color="#D5D9DA" size="35px" mr={3} />
                            <Box>
                                <Text as="p" variant="small" color="dark">Discount</Text>
                                <Text as="p" variant="price" sx={{ fontWeight: 'bold' }}>{deal?.asx_discount}%</Text>
                            </Box>
                        </Flex>
                    }
                </Grid>
            </Container>
        </Box>
    )
};
