import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { redirectAuthenticated } from 'features/auth/authSlice';

const GuestRoute = ({component: Component, ...rest}) => {
    const user = useSelector(store => store.user);
    const authenticatedPath = redirectAuthenticated(user)

    if(authenticatedPath && authenticatedPath !== '/'){
        return <Redirect to={authenticatedPath} />
    }

    return (
        <Route
            {...rest}
            render={(props) => <Component {...props} />}
        />
    )
}

const TwoFactorRoute = ({component: Component, ...rest}) => {
    if(localStorage.getItem('authTokenScope') !== 'twoFactorRequired' || !localStorage.getItem('authToken')){
        return <Redirect to="/" />
    }

    return (
        <Route
            {...rest}
            render={(props) => <Component {...props} />}
        />
    )
}

const PrivateRoute = ({component: Component, ...rest}) => {
    const isAuthenticated = useSelector(store => !!store.user?.user);
    const canSeeDashboard = useSelector(store => store.user?.user?.can_see_dashboard);
    const seenIntro = useSelector(store => store.user?.user?.intro_shown);
    const isAdviser = useSelector(store => store.user?.user?.is_adviser);

    if (isAuthenticated){

        // Need to check if this user is an advisor and if they are currently viewing the app as an investor
        if (isAdviser) {
            if (rest.path !== '/adviser/dashboard') {
                return <Redirect to="/adviser/dashboard" />;
            }
        }

        if (!canSeeDashboard) {
            return <Redirect to="/onboarding" />
        } else if (!seenIntro && rest.path !== '/portfolio') {
            return <Redirect to="/portfolio" />
        }
    }

    return (
        <Route
            {...rest}
            render={(props) => isAuthenticated ? <Component {...props} /> : <Redirect to={{pathname: "/", state: {referrer: props.location}}}/>}
        />
    )
}

const SharedPrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = useSelector(store => !!store.user?.user);
    const canSeeDashboard = useSelector(store => store.user?.user?.can_see_dashboard);
    const seenIntro = useSelector(store => store.user?.user?.intro_shown);
    const isAdviser = useSelector(store => store.user?.user?.is_adviser);

    if (isAuthenticated) {

        // Need to check if this user is an advisor and if they are currently viewing the app as an investor
        if (isAdviser) {
            if (!canSeeDashboard && rest.path !== '/adviser/email-verify') {
                return <Redirect to="/adviser/email-verify" />;
            }

        // Investor routes
        }else{
            if (!canSeeDashboard) {
                return <Redirect to="/onboarding" />
            } else if (!seenIntro && rest.path !== '/portfolio') {
                return <Redirect to="/portfolio" />
            }
        }
    }

    return (
        <Route
            {...rest}
            render={(props) => isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: "/", state: { referrer: props.location } }} />}
        />
    )
}

const AdviserRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = useSelector(store => !!store.user?.user);
    const canSeeDashboard = useSelector(store => store.user?.user?.can_see_dashboard);
    const isAdviser = useSelector(store => store.user?.user?.is_adviser);

    if (isAuthenticated) {
        if (isAdviser){
            if (!canSeeDashboard && rest.path !== '/adviser/email-verify') {
                return <Redirect to="/adviser/email-verify" />;
            } else if (canSeeDashboard && rest.path !== '/adviser/dashboard') {
                return <Redirect to="/adviser/dashboard" />;
            }
        }else{
            return <Redirect to="/portfolio" />;
        }
    }

    return (
        <Route
            {...rest}
            render={(props) => isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: "/", state: { referrer: props.location } }} />}
        />
    )
}

const OnboardingRoute = ({component: Component, ...rest}) => {
    const isAuthenticated = useSelector(store => !!store.user?.user);
    const isOnboarding = useSelector(store => store.user?.user?.onboarding ?? false);
    const isLoading = useSelector(store => store.user?.status === 'loading');
    const isAdviser = useSelector(store => store.user?.user?.is_adviser);

    if (isAdviser && rest.path !== '/adviser/dashboard') {
        return <Redirect to="/adviser/dashboard" />;
    }

    return (
        <Route
            {...rest}
            render={(props) => (isAuthenticated && isOnboarding) || props.match.params.token || isLoading ? <Component {...props} /> : <Redirect to={{pathname: "/", state: {referrer: props.location}}}/>}
        />
    )
}

const AcceptanceLetterRoute = ({component: Component, ...rest}) => {
    const isAuthenticated = useSelector(store => !!store.user?.user);
    const isLoading = useSelector(store => store.user?.status === 'loading');
    const isAdviser = useSelector(store => store.user?.user?.is_adviser);

    if (isAdviser && rest.path !== '/adviser/dashboard') {
        return <Redirect to="/adviser/dashboard" />;
    }

    return (
        <Route
            {...rest}
            render={(props) => (isAuthenticated) || props.match.params.token || isLoading ? <Component {...props} /> : <Redirect to={{pathname: "/", state: {referrer: props.location}}}/>}
        />
    )
}

const TestingRoute = ({component: Component, ...rest}) => {
    const isNotLive = process.env.NODE_ENV !== 'production';
    return (
        <Route
            {...rest}
            render={(props) => isNotLive ? <Component {...props} /> : <Redirect to={{ pathname: "/" }}/>}
        />
    )
}

export { GuestRoute, TwoFactorRoute, OnboardingRoute, PrivateRoute, SharedPrivateRoute, TestingRoute, AdviserRoute, AcceptanceLetterRoute }
