import { FieldArray, useFormikContext } from 'formik';
import { Box, Grid, Heading, Text } from 'theme-ui';
import { Field } from '~/Forms';
import { useTranslation } from 'react-i18next';
import { accountManagementTypeOptions, chessEmailOptions } from 'app/constants';
import { Button } from '~/Common';
import { Icon } from 'assets/Icon';

export const TradingPreferences = ({ isLocked, ...props }) => {
    const { t } = useTranslation();
    const { values } = useFormikContext();
    const hasTradeEmails = Boolean(Array.isArray(values.additional_trade_confirmation_emails) && values.additional_trade_confirmation_emails.length > 0);
    const hasDirectDebitEmails = Boolean(Array.isArray(values.additional_direct_debit_fields) && values.additional_direct_debit_fields.length > 0);

    return (
        <>
            <Text mb={4} as="p">{t('fieldsRequired')}</Text>

            <Grid
                mb={1}
                sx={{
                    gridGap: [2, 3],
                    gridTemplateColumns: ['1fr'],
                    width: '100%',
                }}
            >
                <Heading variant='h5'>{t('Trading Account Type')}</Heading>
                <Text as="p">{t('Please select the type of account you would like to open')}</Text>
                <Field field="select" label="Select the option that applies *" placeholder="-- Please select --" name="account_management_type" options={accountManagementTypeOptions} />
                <Text variant="labelSmall" as="p" mb={2}>
                    {
                        parseInt(values?.account_management_type) === accountManagementTypeOptions.find(option => option.label === 'Full Service').value ? t('settings.profile.accountManagementTypeReasonFullService') :
                        parseInt(values?.account_management_type) === accountManagementTypeOptions.find(option => option.label === 'Self Managed').value ? t('settings.profile.accountManagementTypeReasonSelfManaged') :
                        null
                    }
                </Text>

                {parseInt(values?.account_management_type) === accountManagementTypeOptions.find(option => option.label === 'Full Service').value ? (
                    <>
                        <Heading variant='h5'>{t('Direct Debit Request')}</Heading>
                        <Text as="p">{t('To open your trading account, we will be sending you a Direct Debit Request Form that will need to be signed by all account holders of your nominated bank account. Please provide the name(s) and email address(es) of any other account holders (not including yourself).')}</Text>

                        <FieldArray name="additional_direct_debit_fields">
                            {({ remove, push }) => (
                                <>
                                    {hasDirectDebitEmails ? (
                                        <Box>
                                            {values.additional_direct_debit_fields.map((email, index) => (
                                                <Grid
                                                    key={`direct_debit_emails${index}`}
                                                    sx={{
                                                        gridGap: [1],
                                                        gridTemplateColumns: ['4fr 3fr 1fr'],
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Field label={`Email ${(index + 1)}`} name={`additional_direct_debit_fields.${index}.email`} updateOnBlur={true} />
                                                    <Field label="Name" name={`additional_direct_debit_fields.${index}.name`} updateOnBlur={true} />

                                                    {!isLocked ? (
                                                        <Button
                                                            onClick={() => remove(index)}
                                                            size="default"
                                                            variant="danger"
                                                            mt={2}
                                                            mb={3}
                                                        >
                                                            <Icon icon="trash" size="18px" color="#fff" />
                                                            <span className="sr-only">{t('Remove')}</span>
                                                        </Button>
                                                    ) : null}
                                                </Grid>
                                            ))}
                                        </Box>
                                    ) : null}

                                    {!isLocked ? (
                                        <Box>
                                            <Button
                                                sx={{
                                                    display: ['flex'],
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    mb: 2
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    push("");
                                                }}
                                            >
                                                <Icon icon="plus" size="12" mr={2} />
                                                {t(`Add Additional Account Holder`)}
                                            </Button>
                                        </Box>
                                    ) : null}
                                </>
                            )}
                        </FieldArray>
                    </>
                ): null}

                <Heading variant="h5">{t('Status of Applicant')}</Heading>
                <Text as="p">{t('Are any of the Applicants, Directors, Responsible Officials, Partners, Authorised Representatives, Trustees, Beneficial Owners or any other Beneficiaries of this Account: an entity licensed by any government body, affiliated with any other Participant of                    ASX Group; ASX Listed Company, a government official; government entity, or having dealings with a government official or any government related entity of any country?')}</Text>

                <Grid
                    mb={1}
                    sx={{
                        gridGap: [1],
                        gridTemplateColumns: ['1fr'],
                        width: '100%',
                    }}
                >
                    <Field field="checkbox" label="Tick if this does this apply" validIndicator={false} name="affiliated_with_other_asx_or_government" value={true} checked={values?.affiliated_with_other_asx_or_government}  />

                    {values?.affiliated_with_other_asx_or_government === true ? (
                        <Field label="Please supply details" name="affiliated_with_other_asx_or_government_details" />
                    ) : null}
                </Grid>

                <Heading variant="h5">{t('CHESS Preferences')}</Heading>
                <Text as="p">
                    {t('Would you like OpenMarkets to provide an email address to CHESS and the relevant share registries for the purpose of receiving any correspondence via email ? (Note not all share registries may accept this instruction).')}
                </Text>

                <Grid
                    mb={1}
                    sx={{
                        gridGap: [1],
                        gridTemplateColumns: ['1fr'],
                        width: '100%',
                    }}
                >
                    <Field field="select" label="Select the option that applies *" placeholder="-- Please select --" name="send_chess_email" options={chessEmailOptions} />

                    {values?.send_chess_email === 'YES_OTHER' ? (
                        <Field label="Please supply the CHESS email" name="chess_email" />
                    ) : null}
                </Grid>

                <Heading variant="h5">{t('Additional Copies of Trade Confirmation')}</Heading>
                <Text as="p">
                    {t('Please provide the email address(es) of any third parties to whom you wish to receive a copy of your trade confirmation notes:')}
                </Text>
                <FieldArray name="additional_trade_confirmation_emails">
                    {({ remove, push }) => (
                        <>
                            {hasTradeEmails ? (
                                <Box>
                                    {values.additional_trade_confirmation_emails.map((email, index) => (
                                        <Grid
                                            key={`trade_confirmation_emails${index}`}
                                            sx={{
                                                gridGap: [1],
                                                gridTemplateColumns: ['4fr 1fr'],
                                                width: '100%',
                                            }}
                                        >
                                            <Field label={`Email ${(index + 1)}`} name={`additional_trade_confirmation_emails.${index}`} updateOnBlur={true} />

                                            {!isLocked ? (
                                                <Button
                                                    onClick={() => remove(index)}
                                                    size="default"
                                                    variant="danger"
                                                    mt={2}
                                                    mb={3}
                                                >
                                                    <Icon icon="trash" size="18px" color="#fff" />
                                                    <span className="sr-only">{t('Remove')}</span>
                                                </Button>
                                            ) : null}
                                        </Grid>
                                    ))}
                                </Box>
                            ) : null}

                            {!isLocked ? (
                                <Box>
                                    <Button
                                        sx={{
                                            display: ['flex'],
                                            alignItems: 'center',
                                            width: '100%',
                                            mb: 2
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            push("");
                                        }}
                                    >
                                        <Icon icon="plus" size="12" mr={2} />
                                        {t(`Add Additional Email`)}
                                    </Button>
                                </Box>
                            ) : null}
                        </>
                    )}
                </FieldArray>
            </Grid>
        </>
    );
};
