import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Box, Heading } from '@theme-ui/components'
import _camelCase from 'lodash.camelcase';
import {
    Link
} from '@theme-ui/components'
import moment from 'moment-timezone';
import { Icon } from 'assets/Icon';

export const ContentBlockMenu = ({ contentBlocks, showAsx = false, showArticles = false, title, variant = 'sidemenu', prependMenu, sx }) => {
    const [ menuItems, setMenuItems ] = useState([]);
    const showingDealSummaryHeader = useSelector((state) => state.theme?.showingDealSummaryHeader);
    const user = useSelector((state) => state.user?.user);

    moment.tz.setDefault(user?.timezone);

    useEffect(() => {
        let menu = [];

        if (prependMenu){
            menu.push(prependMenu)
        }

        if (showAsx) {
            menu.push({
                title: 'Fund Performance',
                href: '#asx-performance'
            })

            menu.push({
                title: 'Market Info',
                href: '#asx-info'
            })
        }

        if (showArticles) {
            menu.push({
                title: 'Latest News',
                href: '#latest-news'
            })
        }

        if (Array.isArray(contentBlocks)) {
            contentBlocks.forEach((contentBlock, key) => {
                var title = contentBlock?.data?.menu_title ? contentBlock?.data?.menu_title : ( contentBlock?.data?.title ?? null );
                const id = contentBlock?.data?.menu_title ? `${_camelCase(contentBlock?.data.menu_title)}${key + 1}`
                : (contentBlock?.data?.title ? `${_camelCase(contentBlock?.data.title)}${key + 1}` : `section${key + 1}`);

                const liveAt = moment(contentBlock?.live_at).utc();

                // Fallbacks
                if (null == title) {
                    switch(contentBlock.type) {
                        case 'OfferDetails':
                            title = 'Offer Details';
                            break;
                        default:
                            break;
                    }
                }

                if (title && moment().isAfter(liveAt)){
                    menu.push({
                        title: title,
                        href: `#${id}`
                    })
                }
            });
        }

        setMenuItems(menu);

    }, [contentBlocks, setMenuItems, showAsx, showArticles, prependMenu]);

    if ( ! menuItems.length ) {
        return null;
    }

    const smoothScroll = (e, id) => {
        e.preventDefault();

        if (document.querySelector(id)) {
            const elem = document.querySelector(id).getBoundingClientRect();
            const body = document.body.getBoundingClientRect();
            window.scrollTo({ behavior: 'smooth', top: elem.top - body.top - 50});
            window.history.pushState({}, '', id);
        }
    };

    return (
        <Box className={`content-block-menu`} sx={{
            position: ['relative', 'relative', 'sticky'],
            top: [0, 0, 0, showingDealSummaryHeader ? '100px' : 0, showingDealSummaryHeader ? '164px' : 0],
            py: [0, 0, 0, 0, 5],
            maxWidth: ['none', 'none', 'none', 'none', '348px'],
            transition: 'top 1s ease-in-out',
            ...sx
        }}>
            {title && (
                <Heading
                    as="h4"
                    sx={{
                        fontSize: '20px',
                        fontWeight: 400
                    }}
                    mb={2}
                >
                    {title}
                </Heading>
            )}
            {menuItems.map((item, key) => (
                <Box key={key}>
                    <Link
                        onClick={(e) => smoothScroll(e, item.href)}
                        href={item.href}
                        variant={variant}
                    >
                        {variant === 'jumpTo' && <Icon icon="chevron-right" sx={{ height: '27px', minWidth: '24px' }} />}
                        <Box sx={{ flex: 1, pr: 2 }}>{item.title}</Box>
                        {variant === 'sidemenu' && <Icon icon="arrow-right" color="medium" sx={{ minWidth: '24px' }} />}
                    </Link>
                </Box>
            ))}
        </Box>
    );
};
