import {
    Box
} from '@theme-ui/components'
import ReactPaginate from 'react-paginate'
import './styles.css'

export const Pagination = ({
    onPageChange,
    pageCount,
    activePage,
    pageRangeDisplayed = 5,
    breakLabel = '...',
    nextLabel = 'Next',
    previousLabel = 'Prev',
    sx,
    ...props
}) => {
    return (
        <>
        { pageCount > 1 &&
            <Box my={3} sx={sx}>
                <ReactPaginate
                    containerClassName="pagination"
                    pageClassName="pagination__page"
                    previousClassName="pagination__previous"
                    nextClassName="pagination__next"
                    activeClassName="pagination__page--active"
                    breakLabel={breakLabel}
                    nextLabel={nextLabel}
                    onPageChange={onPageChange}
                    forcePage={(activePage - 1)}
                    pageRangeDisplayed={pageRangeDisplayed}
                    pageCount={pageCount}
                    previousLabel={previousLabel}
                    renderOnZeroPageCount={null}
                    {...props}
                />
            </Box>
        }
        </>
    )
}
