import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Label, Radio, Text } from 'theme-ui';
import { Error } from '~/Forms';
import { ProfileMainTypes } from 'app/constants';

const setupSteps = {
    company: ["Enter your company's details", 'Enter the registered address', 'Upload a copy of your s708 Certificate', 'Verify directors & beneficial owners', 'Set your trading preferences', 'Submit for approval'],
    superannuation: ['Enter your superannuation details', 'Upload a copy of the super fund deed', 'Enter the registered address', 'Upload a copy of your s708 Certificate', 'Verify beneficial owners', 'Set your trading preferences', 'Submit for approval'],
    trust: ["Enter your company's details", 'Upload a certified copy of trust', 'Enter the registered address', 'Upload a copy of your s708 Certificate', 'Verify beneficial owners', 'Set your trading preferences', 'Submit for approval'],
};

export const EntityType = ({ isLocked, ...props }) => {
    const { t } = useTranslation();
    const { errors, values, setFieldValue } = useFormikContext();

    return (
        <>
            {errors?.type && <Error text={errors?.type} isVisible={errors?.type} />}
            <Grid
                mb={4}
                sx={{
                    gridGap: 3,
                    gridTemplateColumns: ['1fr'],
                    width: '100%',
                    maxWidth: '760px',
                }}
            >
                {Object.keys(ProfileMainTypes).map((key) => (
                    // We don't want users to create a second individual profile
                    key !== 'individual' && (
                        <Box key={`profile${key}`}>
                            <Label
                                htmlFor={`type_${key}`}
                                sx={{
                                    width: '100%',
                                    maxWidth: '432px',
                                    textAlign: 'left',
                                    py: 3,
                                    px: 3,
                                    border: '1px solid',
                                    borderRadius: '8px',
                                    borderColor: 'primary',
                                    bg: 'white',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Radio
                                    name="type"
                                    type="radio"
                                    value={key}
                                    id={`type_${key}`}
                                    onChange={(event) => {
                                        if (!isLocked){
                                            const { value } = event.target;
                                            setFieldValue('type', value);
                                        }
                                    }}
                                    checked={key === values.type}
                                />
                                <Text pl={2}>{ProfileMainTypes[key]}</Text>
                            </Label>
                        </Box>
                    )
                ))}
            </Grid>
            {values.type && (
                <Box
                    sx={{
                        fontSize: '14px',
                        color: '#6A7175',
                    }}
                >
                    <Box mb={2}>{t(`To setup a ${values.type} account you will be taken through these steps:`)}</Box>
                    {setupSteps[values.type].map((step, index) => (
                        <Box
                            key={index}
                            sx={{
                                py: 2,
                                borderBottom: '1px solid #D5D9DA',
                                '&:last-child': {
                                    borderBottom: 'none',
                                    mb: 4,
                                },
                            }}
                        >
                            <Text
                                sx={{
                                    fontWeight: 700,
                                    color: '#D5D9DA',
                                    mr: 2,
                                }}
                            >
                                Step {index + 1}
                            </Text>
                            {step}
                        </Box>
                    ))}
                </Box>
            )}
        </>
    );
};
