import styled from '@emotion/styled';
import { theme } from 'app/theme';

export const MenuUl = styled('ul')`
    margin: 0;
    padding: 0;

    & li {
        margin: 0 0 6px;
        padding: 0;
        list-style: none;
    }

    & li ul {
        margin-left: 15px;
    }
    & a{
        position: relative;
        display: inline-flex;
    }
    & a:focus{
        outline: none;
    }

    & a.active{
        color: ${theme.colors.darker};
    }
`;
