import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { useField } from 'formik';
import { Box } from 'theme-ui';
import { Icon } from 'assets/Icon';
import { InputStyled } from './styled';
import { Label } from '~/Forms/Label';
import { useDebounce } from 'hooks/useDebounce';
import { theme } from 'app/theme';

// forward ref so we can focus with parent components
export const TextField = forwardRef(
    (
        {
            label,
            name,
            apiName,
            type,
            className,
            placeholder,
            disabled,
            required = false,
            buttonText,
            onButtonClick,
            controlledInput,
            icon,
            validIndicator,
            validationError,
            valueRegex,
            showLabel,
            onChangeOverride,
            onChange,
            customValueChange,
            textType,
            textOverflow,
            validation,
            variant,
            size = 'default',
            rows,
            sx,
            updateOnBlur,
            ...props
        },
        ref
    ) => {
        const inputRef = useRef(ref?.current);
        const [autoFilled, setAutoFilled] = useState(false);
        const [focused, setFocused] = useState(false);
        const [isHovered, setHovered] = useState(false);
        const [field, meta, { setValue, setTouched }] = useField(name);
        const [internalValue, setInternalValue] = useState(field?.value ?? '');
        const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);
        const fieldFocusedOrFilled = autoFilled || focused || meta.value !== '' || placeholder !== '';
        const isTextarea = rows !== undefined && rows > 1;

        const isInvalid = (required && meta.value === '') || meta.error !== undefined;

        const fieldProps = {
            ...field,
            ...props,
            value: updateOnBlur ? internalValue : field.value,
        };
        const sizes = theme?.forms?.input?.sizes ?? [];

        useEffect(() => {
            const checkAutofill = () => {
                try {
                    setAutoFilled(inputRef.current.matches(':-internal-autofill-selected'))
                } catch (error) {
                    // Browser probably not supported
                }
            };
            document.addEventListener('animationstart', checkAutofill, true);
            return () => document.removeEventListener('animationstart', checkAutofill, true);
        }, [])

        useEffect(() => {
            if (updateOnBlur && field.value !== internalValue && !focused) {
                setInternalValue(field.value);
            }
        }, [field.value, internalValue, updateOnBlur, focused])

        return (
            <Box sx={{ position: 'relative' }}>
                <Label
                    label={label}
                    showLabel={showLabel}
                    fieldFocusedOrFilled={fieldFocusedOrFilled}
                    field={field}
                    placeholder={placeholder}
                    meta={meta}
                    validationError={validationError}
                    focused={focused || isHovered}
                    centered={!isTextarea}
                />

                <InputStyled
                    {...fieldProps}
                    {...props}
                    as={isTextarea ? 'textarea' : 'input'}
                    rows={rows}
                    type={isTextarea ? undefined : (textType || type)}
                    placeholder={placeholder}
                    disabled={disabled}
                    icon={icon}
                    buttonText={buttonText}
                    ref={(r) => {
                        ref && (ref.current = r);
                        inputRef && (inputRef.current = r);
                    }}
                    variant={variant}
                    textOverflow={textOverflow}
                    customValueChange={customValueChange}
                    invalid={meta.touched && meta.error}
                    showLabel={showLabel}
                    onChange={(e) => {
                        const value = valueRegex ? e.target.value.replace(valueRegex,'') : e.target.value;

                        if (onChangeOverride) {
                            onChangeOverride(e);
                        } else {
                            if (updateOnBlur) {
                                setInternalValue(value);
                            } else {
                                setValue(value);
                            }

                            onChange &&
                                debouncedSave({
                                    name: apiName,
                                    value: value === null ? '' : value,
                                });
                        }
                    }}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                        setTouched(field.name, true);
                        setFocused(false);

                        if (updateOnBlur) {
                            setValue(internalValue);
                        }
                    }}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    sx={{
                        ...sizes[size],
                        ...sx
                    }}
                />

                {validIndicator === true && isInvalid && meta.touched && (
                    <Icon
                        icon="cross"
                        color="error"
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 80
                        }}
                    />
                )}
            </Box>
        );
    }
);

TextField.defaultProps = {
    validationError: true,
    placeholder: '',
    showLabel: true,
    controlledInput: true,
    type: 'text',
    validIndicator: true,
    required: false,
    disabled: false
};
