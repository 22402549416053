import { Box, Flex, Link as ThemeLink, Text } from '@theme-ui/components';
import { Logo } from '~/Common'
import { Link } from 'react-router-dom';
import {
    KeyboardNavigation,
    AnimatedTexture
} from '~/Common';
import { useResponsiveValue } from '@theme-ui/match-media';

export const Layout = ({ children, sx, banner = "/img/layout-banner-default.jpg", ...props }) => {
    const isMobile = useResponsiveValue([true, true, false]);

    return (
        <>
            <KeyboardNavigation>
                <ThemeLink href="#content">Skip to content</ThemeLink>
            </KeyboardNavigation>

            <Flex sx={{ flexDirection: 'row', minHeight: [0, 0, '100vh'] }}>
                <Box sx={{
                    width: [0, 0, '40%'],
                    backgroundSize: 'cover',
                    backgroundPosition: 'left center',
                    backgroundRepeat: 'no-repeat',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative'
                }}
                >
                    <Box sx={{
                        position: 'absolute',
                        background: 'linear-gradient(45deg, #7fab9f 0%,#4c8278 45%,#4c8278 45%,#0e2427 100%)',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom:0,
                        zIndex: '-2'
                    }}>
                    </Box>
                    <AnimatedTexture sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom:0,
                        zIndex: '-1'
                    }} />
                    <Box as={Link} to="/">
                        <Logo p={5} px="40px" width="180" color="white" />
                    </Box>

                    <Box p={5} px="40px" mt={'auto'}>
                        <Text as="p" sx={{
                          fontSize: '43px',
                          letterSpacing: '-0.02em',
                          lineHeight: 1
                        }} color="white">{`Invest in world\nchanging ideas.`}</Text>
                    </Box>
                </Box>

                <Flex sx={{
                    width: ['100%', '100%', '60%'],
                    backgroundColor: 'white',
                    justifyContent: 'center',
                    alignContent: 'flex-start',
                    overflowY: 'auto',
                }}>
                    <Box sx={{
                        maxHeight: '100vh',
                        position: 'relative'
                    }}>
                        <Box
                            sx={{
                                py: 5,
                                px: [2,5],
                                width: '568px',
                                maxWidth: '100%',
                                ...sx
                            }}
                            {...props}
                            id="content"
                        >
                            {isMobile && (
                                <Box sx={{ width: '100%', textAlign: 'center' }}>
                                    <Box as={Link} to="/" px={3} sx={{ mx: 'auto' }}>
                                        <Logo width="180" color="black" center={true} mb={4} />
                                    </Box>
                                </Box>
                            )}
                            {children}
                        </Box>
                    </Box>
                </Flex>
            </Flex>
        </>
    );
};
