import { Box, Image } from '@theme-ui/components'

export const ImagePlaceholder = ({ children, sx, imageSx, ...props }) => {
    return (
        <Box
            sx={{
                bg: 'light',
                position: 'relative',
                width: '100%',
                height: '150px',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                zIndex: 1,
                ...sx
            }}
            {...props}
        >
            {children ? children : <Image src="/img/image-placeholder.jpg" alt="" sx={{
                width: '100%',
                objectFit: 'cover',
                ...imageSx
            }} />}
        </Box>
    );
};
