import styled from '@emotion/styled';
import { theme } from 'app/theme';

export const KeyboardNavigation = styled('nav')`
    & a {
        position: absolute;
        left: 0;
        top: -10em;
        padding: 15px 30px;
        white-space: nowrap;
        color: #fff;
        font-size: 18px;
        background-color: ${theme.colors.tealDark};
        text-decoration: none;
    }
    & a:focus{
        top: 0;
        z-index: 20000;
        outline: 0;
    }
`;
