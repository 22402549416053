import * as Yup from 'yup';

Yup.addMethod(Yup.string, "taxNumber", function (errorMessage) {
    return this.test(`test-tax-number`, errorMessage, function (value) {
        const { path, createError } = this;
        const error = errorMessage ?? 'A valid tax file number is required';

        if(value === undefined || value === null){
            return true;
        }
        const modulo = 11; // modulo 11 arithmetic
        let sum = 0;

        // Strip any non digit characters
        const tfn = value.replace(/[^\d]/g, '');
        const digits = tfn.split("");
        let weight = null;

        // Set the weighting based on TFN length
        if (digits.length === 9) {
            weight = [1, 4, 3, 7, 5, 8, 6, 9, 10];
        } else if (digits.length === 8) {
            weight = [10, 7, 8, 4, 6, 3, 5, 1, 0];
        } else {
            return createError({ path, message: error });
        }

        digits.forEach((digit, key) => {
            sum += digit * weight[key];
        });

        return (
            ! (sum % modulo) ||
            createError({ path, message: error })
        );
    });
});

Yup.addMethod(Yup.string, "acnNumber", function (errorMessage) {
    return this.test(`test-acn-number`, errorMessage, function (value) {
        const { path, createError } = this;
        const error = errorMessage ?? 'A valid ACN is required';

        if (value === undefined || value === null) {
            return true;
        }
        let sum = 0;

        // Strip any non digit characters
        const acn = value.replace(/[^\d]/g, '');
        const digits = acn.split("");

        if (digits.length !== 9) {
            return createError({ path, message: error });
        }

        const weight = [8, 7, 6, 5, 4, 3, 2, 1, 0];
        digits.forEach((digit, key) => {
            sum += parseInt(digit) * weight[key];
        });

        let remainder = sum % 10;
        let complement = (10 - remainder);

        if(complement === 10){
            complement = 0;
        }

        return (
            (parseInt(digits[8]) === complement) ||
            createError({ path, message: error })
        );
    });
});

Yup.addMethod(Yup.string, "abnNumber", function (errorMessage) {
    return this.test(`test-abn-number`, errorMessage, function (value) {
        const { path, createError } = this;
        const error = errorMessage ?? 'A valid ABN is required';

        if (value === undefined || value === null) {
            return true;
        }
        const modulo = 89; // modulo 11 arithmetic
        let sum = 0;

        // Strip any non digit characters
        const abn = value.replace(/[^\d]/g, '');
        let digits = abn.split("");

        if (digits.length !== 11) {
            return createError({ path, message: error });
        }

        digits[0] = (parseInt(digits[0]) - 1);
        const weight = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
        digits.forEach((digit, key) => {
            sum += parseInt(digit) * weight[key];
        });

        return (
            (sum % modulo) === 0 ||
            createError({ path, message: error })
        );
    });
});

export const addressValidationNullable = Yup.object().shape({
    unit_number: Yup.string().nullable(),
    street_number: Yup.string().nullable(),
    street_name: Yup.string().nullable(),
    street_type: Yup.string().nullable(),
    address2: Yup.string().nullable(),
    state: Yup.string().nullable(),
    suburb: Yup.string().nullable(),
    postcode: Yup.string().nullable(),
});

export {
    Yup
};
