import { useState, useEffect, useRef } from 'react'
import { DashboardLayout } from '~/Layouts';
import {
    Container,
    PageHeader,
    Pagination
} from '~/Common';
import {
    Card,
    Related
} from '~/Deals';
import {
    Box,
    Flex,
    Grid,
    Heading,
    Spinner,
    Text
} from '@theme-ui/components'
import { connect, useDispatch } from 'react-redux';
import { useQuery } from 'utils/helpers';
import { useTranslation, Trans } from 'react-i18next';
import { getDeals } from 'features/deal/dealSlice';
import { setRunSearch } from 'features/theme/themeSlice';
import { Icon } from 'assets/Icon';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { theme: state.theme }
}

const Search = ({ theme }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Search'));
    const searchDeals = useRef(null);
    const query = useQuery();
    const keywords = query.get('s');
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(theme.runSearch);
    const [results, setResults] = useState([]);
    const dispatch = useDispatch();
    const smoothScroll = () => {
        window.scrollTo({ behavior: 'smooth', top: searchDeals.current.offsetTop });
    };

    useEffect(() => {
        // This will load results on first load
        const shouldQuery = theme.runSearch === null && keywords.length;

        if(shouldQuery || theme.runSearch){
            setLoading(true);
            setResults([]);
            setCount(0);

            getDeals({
                params: {
                    search: keywords
                }
            }).then((response) => {
                setResults(response);
                setCount(response.meta?.total ?? 0)
                setLoading(false)
                dispatch(setRunSearch(false));
            }).catch(() => {
                setResults([]);
                setCount(0);
                setLoading(false)
                dispatch(setRunSearch(false));
            })
        }
    }, [theme, dispatch, keywords]);

    const hasResults = results?.data && results?.data.length > 0;

    return (
        <DashboardLayout p={0}>
            {!loading && count > 0 && (
                <PageHeader>
                    <Container variant="layouts.default">
                            <Heading as="h1" sx={{ fontSize: '36px' }}>{t('Search')}</Heading>
                            <Text as="p" variant="subtext">
                                <Trans>{t(
                                    loading ? "Searching for <strong>'{{keywords}}'</strong>" : "Your search for <strong>'{{keywords}}'</strong> produced <strong>{{count}}</strong> {{results}}",
                                    {
                                        keywords: keywords,
                                        count: count,
                                        results: count !== 1 ? 'results' : 'result'
                                    }
                                )}</Trans>
                            </Text>
                    </Container>
                </PageHeader>
            )}
            <Container variant="layouts.default">
                {loading ? <Spinner variant="styles.spinner" sx={{ mx: 'auto', display: 'block', my: 5 }} /> : (
                    hasResults ? (
                        <>
                            <Heading as="h2" variant="h3" mb={3} id="searchDeals" ref={searchDeals}>{t('Deals')}</Heading>

                            <Flex mb={[4,5,6]} sx={{
                                flexDirection: 'column',
                                position: 'relative',
                            }}>
                                <Grid sx={{
                                    gridGap: 4,
                                    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
                                    width: '100%'
                                }}>
                                {results.data.map((deal, key) => (
                                    <Box key={key}>
                                        <Card deal={deal} />
                                    </Box>
                                ))}
                                </Grid>

                                <Pagination
                                    activePage={results.meta?.current_page ?? 0}
                                    pageCount={results.meta?.last_page ?? 0}
                                    onPageChange={(event) => {
                                        setLoading(true);
                                        getDeals({
                                            params: {
                                                search: keywords,
                                                page: (event.selected + 1)
                                            }
                                        }).then((response) => {
                                            setResults(response);
                                            setCount(response.meta?.total ?? 0)
                                            setLoading(false)
                                            smoothScroll();
                                        }).catch(() => {
                                            setResults([]);
                                            setCount(0);
                                            setLoading(false)
                                            smoothScroll();
                                        })
                                    }}
                                />
                            </Flex>
                        </>
                    ) : (
                        <Flex sx={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            textAlign: 'center',
                            p: [0, '130px'],
                        }}>
                            <Icon icon="search-circle" color="tealDark" />
                            <Heading variant="h2" sx={{ mt: '18px', fontSize: '36px' }}>No matches found</Heading>
                            <Text as="p" mt={1} variant="subtext">Please try another search.</Text>
                        </Flex>
                    )
                )}
            </Container>

            <Box my={4}>
                <Container variant="layouts.default">
                    <Related
                        heading="Deals you might be interested in"
                        showDiscoverMore={true}
                        to="/deals"
                        status={'open'}
                    />
                </Container>
            </Box>
        </DashboardLayout>
    );
};

export default connect(mapStateToProps, null)(Search);
