import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    utilStateByCountry,
    utilTimezoneByCountry,
    utilGetFilters,
    utilGetSettings,
    utilGetCountries,
    utilGetProfileOptions,
    utilGetMarketData
} from './themeAPI';

const initialState = {
    app_mode: 'beta',
    showProfileMenu: false,
    showMenu: false,
    showSearch: false,
    showPlaceBid: false,
    showFullService: false,
    showingDealSummaryHeader: false,
    transparentHeader: false,
    blurDashboard: false,
    showAnimation: false,
    showVerifySteps: true,
    runSearch: null,
    itemLayout: localStorage.getItem('themeItemLayout') ? localStorage.getItem('themeItemLayout') : 'grid',
    stages: [],
    categories: [],
    industries: [],
    countries: {
        status: 'loading',
        options: []
    },
    settings: {
        status: 'loading',
        terms_of_use: null,
        privacy_policy: null,
        trust_and_safety: null,
        identity_terms_conditions: null,
    },
    profile: {
        status: 'loading',
        options: {
            titles: [],
            occupationTypes: [],
            macquarieOccupationTypes: [],
            macquarieOccupations: [],
            sourceOfWealth: [],
            companyTypes: [],
            trustTypes: [],
            industryCodes: [],
            roleTypesCompany: [],
            roleTypesTrust: [],
            roleTypesSuper: [],
        }
    },
    onboarding: {
        type: 'individual',
        subtype: '',
    },
    marketData: {
        status: 'loading',
        data: []
    },
    status: 'idle',
};

export const getFiltersAsync = createAsyncThunk(
    'theme/getFilters',
    async () => {
        const response = await utilGetFilters();
        return response;
    }
);

export const getSettingsAsync = createAsyncThunk(
    'theme/getSettings',
    async () => {
        const response = await utilGetSettings();
        return response;
    }
);

export const getProfileOptionsAsync = createAsyncThunk(
    'theme/getProfileOptions',
    async () => {
        const response = await utilGetProfileOptions();
        return response;
    }
);

export const getCountriesAsync = createAsyncThunk(
    'theme/getCountries',
    async () => {
        const response = await utilGetCountries();
        return response;
    }
);

export const getMarketDataAsync = createAsyncThunk(
    'theme/getMarketData',
    async () => {
        const response = await utilGetMarketData();
        return response;
    }
);

const theme = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setShowMenu: (state, action) => {
            state.showMenu = action.payload;
        },
        setShowProfileMenu: (state, action) => {
            state.showProfileMenu = action.payload;
        },
        setShowSearch: (state, action) => {
            state.showSearch = action.payload;
        },
        setShowFullService: (state, action) => {
            state.showFullService = action.payload;
        },
        setShowPlaceBid: (state, action) => {
            state.showPlaceBid = action.payload;
        },
        setItemLayout: (state, action) => {
            state.itemLayout = action.payload;
            localStorage.setItem('themeItemLayout', action.payload);
        },
        setRunSearch: (state, action) => {
            state.runSearch = action.payload;
        },
        setShowingDealSummaryHeader: (state, action) => {
            state.showingDealSummaryHeader = action.payload;
        },
        setTransparentHeader: (state, action) => {
            state.transparentHeader = action.payload;
        },
        setBlurDashboard: (state, action) => {
            state.blurDashboard = action.payload;
        },
        setShowAnimation: (state, action) => {
            state.showAnimation = action.payload;
        },
        setShowVerifySteps: (state, action) => {
            state.showVerifySteps = action.payload;
        },
        setOnboardingType: (state, action) => {
            state.onboarding.type = action.payload;
            if (action.payload === 'individual' || action.payload === 'company') {
                state.onboarding.subtype = '';
            }
        },
        setOnboardingSubtype: (state, action) => {
            state.onboarding.subtype = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFiltersAsync.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(getFiltersAsync.rejected, (state) => {
                    state.stages = {...initialState.stages};
                    state.categories = {...initialState.categories};
                    state.industries = {...initialState.industries};
                    state.status = 'attempted';
                })
                .addCase(getFiltersAsync.fulfilled, (state, {payload}) => {
                    state.stages = payload?.data?.stages;
                    state.categories = payload?.data?.categories;
                    state.industries = payload?.data?.industries;
                    state.status = 'attempted';
                })
                .addCase(getSettingsAsync.pending, (state) => {
                    state.settings.status = 'loading';
                })
                .addCase(getSettingsAsync.rejected, (state) => {
                    state.settings = {...initialState.settings};
                    state.settings.status = 'attempted';
                })
                .addCase(getSettingsAsync.fulfilled, (state, {payload}) => {
                    state.settings = payload?.data;
                    state.settings.status = 'attempted';
                })
                .addCase(getProfileOptionsAsync.pending, (state) => {
                    state.profile.status = 'loading';
                })
                .addCase(getProfileOptionsAsync.rejected, (state) => {
                    state.profile = {...initialState.profile};
                    state.profile.status = 'attempted';
                })
                .addCase(getProfileOptionsAsync.fulfilled, (state, {payload}) => {
                    state.profile.options = payload?.data;
                    state.profile.status = 'attempted';
                })
                .addCase(getCountriesAsync.pending, (state) => {
                    state.countries.status = 'loading';
                })
                .addCase(getCountriesAsync.rejected, (state) => {
                    state.countries = {...initialState.profile};
                    state.countries.status = 'attempted';
                })
                .addCase(getCountriesAsync.fulfilled, (state, {payload}) => {
                    state.countries.options = payload?.data;
                    state.countries.status = 'attempted';
                })
                .addCase(getMarketDataAsync.pending, (state) => {
                    state.marketData.status = 'loading';
                })
                .addCase(getMarketDataAsync.rejected, (state) => {
                    state.marketData = {...initialState.marketData};
                    state.marketData.status = 'attempted';
                })
                .addCase(getMarketDataAsync.fulfilled, (state, {payload}) => {
                    state.marketData.data = payload?.data;
                    state.marketData.status = 'attempted';
                });
    },
});

export {
    utilStateByCountry,
    utilTimezoneByCountry,
    utilGetFilters,
    utilGetSettings,
    utilGetProfileOptions,
    utilGetMarketData
};

export const { setShowMenu, setShowProfileMenu, setShowSearch, setShowPlaceBid, setItemLayout, setRunSearch, setShowingDealSummaryHeader, setTransparentHeader, setBlurDashboard, setShowAnimation, setShowVerifySteps, setOnboardingType, setOnboardingSubtype, setShowFullService } = theme.actions;

export default theme.reducer;
