import React from 'react'
import { Label as ThemeLabel } from 'theme-ui'
import { Error } from '~/Forms/Error';

export const Label = ({
    label,
    showLabel = true,
    fieldFocusedOrFilled,
    field,
    focused,
    placeholder,
    required,
    meta,
    validationError,
    centered = true
}) => {
    return (
    <>
        {label !== false && (
        <>
            <ThemeLabel
                className={ showLabel ? 'label' : 'sr-only'}
                sx={{
                    position: 'absolute',
                    left: 15,
                    fontWeight: 300,
                    fontSize: fieldFocusedOrFilled ? '12px' : 2,
                    top: fieldFocusedOrFilled ? '-12px' : (centered ? '50%' : '1em'),
                    transform: fieldFocusedOrFilled ? '' : (centered ? 'translateY(-50%)' : ''),
                    pointerEvents: 'none',
                    padding: fieldFocusedOrFilled ? '0 4px' : '',
                    margin: fieldFocusedOrFilled ? '0 -4px' : '',
                    display: 'inline-block',
                    width: 'auto',
                    transition: 'all 0.3s ease-in-out',
                    zIndex: 50,
                    color: (theme) => focused ?
                        theme.colors.tealDark :
                        (!fieldFocusedOrFilled && meta?.touched && meta?.error ? theme.colors.error : theme.colors.dark),
                    '&::after': {
                        content: '""',
                        display: 'block',
                        background: (theme) => fieldFocusedOrFilled ? theme.colors.white : 'transparent',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '6px',
                        zIndex: -1,
                        transition: 'all 0.3s ease-in-out',
                    }
                }}
                htmlFor={field?.name} mb={1}>
                {
                    label
                        ? `${label}${required ? '*' : ''}`
                        : placeholder
                }
            </ThemeLabel>

            {validationError && !focused && (
                <Error
                    text={meta?.error}
                    isVisible={meta?.touched && meta?.error}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: '-15px',
                        zIndex: 80
                    }}
                />
            )}
        </>
        )}
    </>
    );
};
