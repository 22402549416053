import { useEffect, useState, useRef } from 'react';
import { SearchBar as SearchBarStyled } from './styled';
import { useQuery } from 'utils/helpers';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setShowSearch, setRunSearch } from 'features/theme/themeSlice';
import { useTranslation } from 'react-i18next';
import {
    Container
} from '~/Common';
import { Icon } from 'assets/Icon';
import {
    Box,
    Button,
    Close,
    Flex,
    Input,
    Text
} from 'theme-ui';
import { useMenuClose } from 'hooks/useMenuClose';

export const SearchBar = ({ isOpen, buttonRef, ...props }) => {
    const query = useQuery();
    const [keywords, setKeywords] = useState(query.get('s') ?? '');
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const searchBarRef = useRef(null);
    const textInput = useRef(null);

    // Close menu if esc is pressed, or if click outside of reference
    useMenuClose([searchBarRef, buttonRef], () => isOpen && dispatch(setShowSearch(false)));

    useEffect(() => {
        setTimeout(() => {
            if(isOpen && textInput.current !== null){
                textInput.current.focus();
            }
        }, 750);
    }, [isOpen, textInput]);

    const handleSubmit = (e) => {
        e.preventDefault();
        history.push(`/search?s=${keywords}`);
        dispatch(setRunSearch(true));
        dispatch(setShowSearch(false));
        return false;
    };

    return (
        <SearchBarStyled role="dialog" tabIndex="-1" className={isOpen ? 'open' : ''} {...props} id="search">
            <Container sx={{ position: 'relative' }}>
                <Box ref={searchBarRef} as="form" onSubmit={handleSubmit} method="GET">
                    <Flex sx={{ position: 'relative' }}>
                        <Input ref={textInput} name="s" title="Search by deals, sectors, deal stages and more..." onChange={(e) => setKeywords(e.target.value)} value={keywords} placeholder={t("Search by deals, sectors, deal stages and more...")} sx={{
                            border: 0,
                            ':hover': {
                                border: 0
                            },
                            ':focus': {
                                outline: 0
                            }
                        }} />

                        <Flex sx={{ alignItems: 'center', position: 'absolute', right: '27px', top: 0, height: '100%' }} >
                            <Close type="button" variant="clear" onClick={() => keywords.length ? setKeywords('') : dispatch(setShowSearch(false))} sx={{ width: '50px', height: '50px' }}>
                                <Text className="sr-only">{t('Close Search')}</Text>
                            </Close>
                            <Button type="submit" variant="clear" sx={{ height: '50px', display: 'inline-flex', alignItems: 'center' }}>
                                <Icon icon="search" color="dark" size="24px" />
                                <Text className="sr-only">{t('Search')}</Text>
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
            </Container>
        </SearchBarStyled>
    );
};
