import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'app/routes';
import { getCsrfCookie } from 'utils/api';
import 'utils/helpers';
import { theme } from 'app/theme';
import { ThemeProvider } from 'theme-ui';
import Modal from 'react-modal'
import { getUserAsync, getOnboardingStatusAsync, setUserStatus, setAdviserStatus, getAdviserAsync } from 'features/auth/authSlice';
import { getNotificationsAsync } from 'features/notifications/notificationsSlice';
import { getSettingsAsync } from 'features/theme/themeSlice';
import { getWatchlistAsync, getWatchlistClosedDealsAsync, getWatchlistCompaniesAsync, getWatchlistDealsAsync, getWatchlistStatsAsync } from 'features/watchlist/watchlistSlice';
import { ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingScreen, AnimatedTexturePortal } from '~/Common';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'App.css'
import "@lang/i18n"
import { createHtmlPortalNode } from 'react-reverse-portal';
import { PortalProvider } from 'app/PortalContext';
import Echo from 'laravel-echo';
import axios from 'axios';
import LogRocket from 'logrocket';
import { isBroadcastingEnabled } from 'utils/helpers';
import { getInvitedByAsync } from 'features/invites/invitesSlice';

if (isBroadcastingEnabled()) {
    window.Pusher = require('pusher-js');

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.REACT_APP_PUSHER_APP_KEY,
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        encrypted: false,
        authorizer: (channel, options) => {
            return {
                authorize: (socketId, callback) => {
                    axios.post('broadcasting/auth', {
                        socket_id: socketId,
                        channel_name: channel.name
                    })
                    .then(response => {
                        callback(false, response.data);
                    })
                    .catch(error => {
                        callback(true, error);
                    });
                }
            };
        },
        forceTLS: JSON.parse(process.env.REACT_APP_PUSHER_FORCE_TLS)
    });
}

if (JSON.parse(process.env.REACT_APP_USERBACK)) {
    window.Userback = window.Userback || {};
    window.Userback.access_token = '9019|64593|A8eXlEWWW0kN6VIvRwBVZlOgTRZCAv3YNTYD8e0aU5KzSfsRHc';
    (function(d) {
        var s = d.createElement('script');s.async = true;
        s.src = 'https://static.userback.io/widget/v1.js';
        (d.head || d.body).appendChild(s);
    })(document);
}

if (JSON.parse(process.env.REACT_APP_LOGROCKET)){
    LogRocket.init('bjprsq/liquidity');
}

if (JSON.parse(process.env.REACT_APP_HS_CHAT)) {
    (function(d) {
        var s = d.createElement('script');
        s.async = true;
        s.defer = true;
        s.id = 'hs-script-loader';
        s.src = '//js-na1.hs-scripts.com/22234511.js';
        (d.head || d.body).appendChild(s);
    })(document);
}

Modal.setAppElement('#root');

const App = () => {
    const dispatch = useDispatch();
    const user = useSelector(store => store.user);
    const watchlist = useSelector(store => store.watchlist);
    const settings = useSelector(store => store.theme?.settings);
    const isBlurred = useSelector(state => state.theme.blurDashboard);
    const canvasPortal = React.useMemo(() => createHtmlPortalNode(), []);

    useEffect(() => {
        document.getElementById('root').classList.toggle('page__blur', isBlurred);
    }, [isBlurred]);

    useEffect(() => {
        getCsrfCookie();

        const isLogout = window.location === '/logout';
        const isOnboardingToken = window.location.pathname.startsWith('/onboarding/');

        if (!isLogout && !isOnboardingToken && localStorage.getItem('authToken')) {
            if(localStorage.getItem('authTokenScope') === 'onboarding'){
                dispatch(getOnboardingStatusAsync());
            }else{
                dispatch(getUserAsync());
                dispatch(getNotificationsAsync());
                dispatch(getInvitedByAsync());
                dispatch(getWatchlistAsync());
            }
        }else{
            dispatch(setUserStatus('idle'));
        }

        if (localStorage.getItem('adviserToken')){
            dispatch(getAdviserAsync());
        } else {
            dispatch(setAdviserStatus('idle'));
        }

        if(settings?.status === 'loading'){
            dispatch(getSettingsAsync());
        }
    }, [dispatch, settings]);

    useEffect(() => {
        if (user?.user !== null && user?.user.login_status === 'success' && (watchlist.status === 'resync' || watchlist.status === 'idle')) {
            dispatch(getWatchlistAsync());
            dispatch(getWatchlistStatsAsync());
            dispatch(getWatchlistDealsAsync());
            dispatch(getWatchlistCompaniesAsync());
            dispatch(getWatchlistClosedDealsAsync());
        }
    }, [watchlist, user, dispatch]);

    const isLoading = window.location?.pathname !== '/onboarding' && (user?.status === 'loading' || settings?.status === 'loading');

    return (
        <>
            <AnimatedTexturePortal node={canvasPortal} />
            <PortalProvider value={{ canvas: canvasPortal }}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                            {isLoading ? <LoadingScreen hasToken={localStorage.getItem('authToken')} /> : (
                                <>
                                    <Routes />
                                    <ToastContainer icon={false} />
                                </>
                            )}
                    </ThemeProvider>
                </BrowserRouter>
            </PortalProvider>
        </>
    );
}

export default App;
