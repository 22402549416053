import moment from 'moment';
import { Link } from 'react-router-dom';
import { Flex, Heading, Image, Text } from 'theme-ui';
import { DATE_FORMAT } from 'app/constants';

export const ArticleTile = ({ article, sx, ...props }) => {
    return (
        <Flex
            as={Link}
            to={article.is_external ? {pathname: article.external_url} : `/articles/${article.slug}`}
            sx={{
                flexDirection: 'column',
                gridGap: '16px',
                textDecoration: 'none',
                '&::after': {
                    display: 'none',
                }
            }}
            target={article.is_external ? '_blank' : null}
            {...props}
        >
            <Image src={article?.image?.sizes?.thumbnail ?? '/img/image-placeholder.jpg'} sx={{ objectFit: article?.is_external ? 'contain' : '', height: ['40vw', null, null, '164px'], borderRadius: '16px' }} />
            <Heading as="h2" variant="h4" sx={{ color: 'darker', fontSize: '20px' }}>{article?.name}</Heading>
            <Text sx={{ fontSize: '13px', color: 'dark' }}>
                {moment(article.date).format(DATE_FORMAT.date_format_friendly)}
                {article.reading_time_human && ` | ${article.reading_time_human} read time`}
            </Text>
        </Flex>
    )
}
