import { useEffect, useRef, useState } from 'react'
import { SettingsLayout } from '~/Layouts';
import {
    Circle,
    Button,
    Pagination,
    Tabs,
    MenuDialog
} from '~/Common'
import {
    Box,
    Close,
    Divider,
    Heading,
    Label,
    Checkbox,
    Text,
    Grid,
    Link as ThemeLink,
    Image
} from '@theme-ui/components'
import { Link } from 'react-router-dom'
import { Icon } from 'assets/Icon';
import { Form, Formik } from 'formik';
import { Submit } from '~/Forms/Submit';
import { toast } from 'utils/toast'
import { confirmAlert } from 'react-confirm-alert'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMenuClose } from 'hooks/useMenuClose';
import {
    getNotificationsAsync,
    getNotifications,
    markAllNotificationsAsReadAsync,
    markNotificationAsReadAsync,
    markNotificationAsUnreadAsync,
    removeNotificationAsync,
    storeNotificationPreferences,
    updateAsxAnnouncementPreference,
    toggleAsxAnnouncementPreferences
} from 'features/notifications/notificationsSlice'
import { setAsxAnnouncementPreferences, setUser } from 'features/auth/authSlice'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { ThemeProvider } from 'theme-ui'
import { CSSTransition } from 'react-transition-group';
import { theme } from 'app/theme'
import { useResponsiveValue } from '@theme-ui/match-media'
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { getPortfolioEquityHoldingsAsync, getPortfolioEquityHoldings } from 'features/portfolio/portfolioSlice';
import { smoothScroll } from 'utils/helpers';

const mapStateToProps = (state) => {
    return { user: state.user?.user, notifications: state.notifications, portfolio: state.portfolio, profile: state.user?.activeProfile }
}

const SettingsNotifications = ({ user, notifications, portfolio, profile }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Notifications'));
    const nodeRef = useRef(null);
    const menuRef = useRef(null);
    const alertsHeadingRef = useRef(null);
    const dispatch = useDispatch();
    const unreadNotifications = notifications.notifications.filter(n => !n.read_at);
    const readNotifications = notifications.notifications.filter(n => !!n.read_at);
    const [ showOptions, setShowOptions ] = useState(false);
    const [ selectedTab, setSelectedTab ] = useState('all');
    const notificationOptions = user?.is_adviser !== true ? [
        { key: 'email', label: 'Email' },
        { key: 'phone', label: 'Phone' }
    ] : [
        { key: 'email', label: 'Email' }
    ];
    const isDesktop = useResponsiveValue([ false, false, false, true ]);
    const equityHoldingsPages = {
        activePage: portfolio?.equityholdings?.meta?.current_page,
        pageCount: portfolio?.equityholdings?.meta?.last_page
    };

    const tabs = [
        {
            key: 'all',
            name: 'All',
            onClick: () => setSelectedTab('all')
        },
        {
            key: 'unread',
            name: 'Unread',
            onClick: () => setSelectedTab('unread')
        }
    ]

    useMenuClose(menuRef, () => {
        if (showOptions) {
            setShowOptions(false);
        }
    });

    useEffect(() => {
        if (portfolio?.equityholdings?.status === 'idle') {
            dispatch(getPortfolioEquityHoldingsAsync());
        }
    }, [portfolio, dispatch]);

    const handleSubmit = (values, actions) => {
        let formdata = new FormData();
        formdata.append('notify_by', JSON.stringify(values?.notify_by));

        storeNotificationPreferences(formdata).then((response) => {
            toast.success(t('notifications.preferences.success'), {
                toastId: 'notifications-preferences-success',
            });

            dispatch(setUser(response));
            actions.setSubmitting(false);
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(t('notifications.preferences.error'), {
                toastId: 'notifications-preferences-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    };

    const handleAsxPreferencesSubmit = (values, actions) => {
        let formdata = new FormData();
        formdata.append('preferences', JSON.stringify(user?.asx_announcement_preferences));

        updateAsxAnnouncementPreference(formdata).then((response) => {
            toast.success(t('notifications.preferences.success'), {
                toastId: 'notifications-preferences-success',
            });

            dispatch(setUser(response));
            actions.setSubmitting(false);
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(t('notifications.preferences.error'), {
                toastId: 'notifications-preferences-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    }

    const handleToggleAsxPreferences = (key) => {
        if (user?.asx_announcement_preferences.all_securities?.notify_by?.[key] !== undefined) {
            toggleAsxAnnouncementPreferences({ key: key, value: false, profile: profile?.uuid}).then((response) => {   
                dispatch(setUser(response));
            }).catch(({ response }) => {
                console.error(response)
            });
        } else {
            toggleAsxAnnouncementPreferences({ key: key, value: true, profile: profile?.uuid}).then((response) => {
                dispatch(setUser(response));
            }).catch(({ response }) => {
                console.error(response)
            });
        }
    }

    const markAllAsRead = (e) => {
        e.preventDefault();
        dispatch(markAllNotificationsAsReadAsync());
    };

    const markAsRead = (e, id) => {
        e.preventDefault();
        dispatch(markNotificationAsReadAsync(id));
    };

    const markAsUnread = (e, id) => {
        e.preventDefault();
        dispatch(markNotificationAsUnreadAsync(id));
    };


    var totalShowing = 0;
    const renderNotification = (notification, key) => {
        totalShowing++;

        const extraProps = notification.data?.frontend_url ? {
            as: p => <ThemeLink as={Link} variant="menuitem" sx={{ fontWeight: 400 }} {...p} />,
            to: notification.data?.frontend_url,
            sx: {
                textDecoration: 'none',
                color: 'text'
            }
        } : {};

        return (
            <Grid py={3} key={`notifications-table-${key}`} sx={{
                width: '100%',
                borderBottom: '1px solid',
                borderBottomColor: 'light',
                justifyContent: 'stretch',
                alignItems: 'center',
                gridTemplate: [null, 'auto / auto 172px']
            }}>
                <Grid {...extraProps} sx={{
                    ...extraProps.sx,
                    gridTemplate: ['auto / 48px auto 10fr'],
                    display: 'grid !important',
                    py: 3,
                    px: [2,3]
                }}>
                    <Circle size="48px" bg="#f4f4f4" type="box" sx={{ flexShrink: 0, overflow: 'hidden' }}>
                        {notification.data.avatar_url && (
                            <Image
                                alt="Notification image"
                                src={notification.data.avatar_url}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                }}
                            />
                        )}
                    </Circle>
                    <Box sx={{ flex: 1 }}>
                        <Text as="p" variant="small">
                            {notification.data?.content}
                        </Text>
                        <Text as="p" variant="small" title={moment(notification.created_at).format('Do MMM Y')} sx={{
                            color: 'tealDark'
                        }}>
                            {notification.created_at && moment(notification.created_at).fromNow()}
                        </Text>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        {!notification.read_at && <Circle size="10px" bg="tealLight" type="box" />}
                    </Box>
                </Grid>
                <Box sx={{ textAlign: ['left','right'], paddingLeft: ['70px', 0] }}>
                    {!notification.read_at ? (
                        <Button onClick={(e) => markAsRead(e, notification.id)} mr={2} size="small">
                            Mark as read
                        </Button>
                    ) : (
                        <>
                            <Button onClick={(e) => markAsUnread(e, notification.id)} mr={2} size="small">
                                Mark as unread
                            </Button>

                            <Circle variant="secondary" size="35px" onClick={(e) => removeNotification(e, notification.id)} p="3" mr={2}>
                                <Icon color="darker" icon="trash" size="11" />
                            </Circle>
                        </>
                    )}
                </Box>
            </Grid>
        );
    }

    const removeNotification = (e, id) => {
        e.preventDefault();

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ThemeProvider theme={theme}>
                        <Box bg="white" px={5} py={4} sx={{
                            maxWidth: '600px',
                            width: '100%',
                            textAlign: 'center',
                            boxShadow: '0 20px 75px rgb(0 0 0 / 23%)',
                            position: 'relative'
                        }}>
                            <Close onClick={onClose} sx={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px'
                            }}></Close>

                            <Heading as="h2" variant="h2" mb={3}>Are you sure?</Heading>
                            <Text as="p" mb={3}>{t('Are you sure to want to remove this notification?')}</Text>
                            <Text as="p" mb={3}>{t('This can\'t be undone')}</Text>
                            <Button variant="primary" mr={3} onClick={() => {
                                dispatch(removeNotificationAsync(id))
                                onClose();
                            }}
                            >
                            Yes, please delete it!
                            </Button>
                            <Button variant="link" onClick={onClose}>Cancel</Button>
                        </Box>
                    </ThemeProvider>
                );
            }
        });
    };

    const allOptions = [
        {
            name: 'markAllAsRead',
            label: 'Mark all as read',
            onClick: (e) => {
                markAllAsRead(e);
                setShowOptions(false);
            },
            icon: 'tick'
        }
    ];

    return (
        <SettingsLayout>
            <CSSTransition
                nodeRef={nodeRef}
                in={true}
                timeout={500}
                classNames="fade"
                unmountOnExit
                appear
            >
                <Box ref={nodeRef}>
                    <Heading as="h1" variant="h2" mb={2}>{t('Notifications')}</Heading>
                    <Heading as="h2" variant="h4" mb={-3} sx={{
                        fontSize: '16px'
                    }}>{t('notifications.settings.subheading')}</Heading>

                    <Formik
                        initialValues={{
                            notify_by: user?.notify_by
                        }}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <Grid sx={{
                                    gridTemplate: notificationOptions.length === 2 ? 'auto / 60fr 20fr 20fr' : 'auto / 60fr 20fr',
                                    textAlign: 'center'
                                }} mb={3}>
                                    <Box></Box>
                                    {isDesktop && notificationOptions.map(({ label, key }) => (
                                        <Text key={`notify-label-${key}`}>
                                            {label}
                                        </Text>
                                    ))}
                                </Grid>
                                <Grid sx={{
                                    gridTemplate: ['auto / 1fr', null, null, notificationOptions.length === 2 ? 'auto / 60fr 20fr 20fr' : 'auto / 60fr 20fr']
                                }}>
                                    <Box mb={[0, null, null, 3]} py="24px">
                                        <Heading variant="h5" sx={{
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            mb: 2
                                        }}>{t('How would you like to be notified?')}</Heading>
                                        <Heading variant="h5" sx={{
                                            color: 'dark',
                                            fontSize: '14px'
                                        }}>{t('Notifications about the deals you follow, have backed or launched a project')}</Heading>
                                    </Box>
                                    {notificationOptions.map(({ label, key }) => { return (
                                        <Box key={`notify-by-${key}`} py={[0, null, null, '24px']}>
                                            <Label sx={{
                                                alignItems: 'center',
                                                justifyContent: ['left', null, null, 'center']
                                            }}>
                                                <Checkbox name={`notify_by[${key}]`} value={key} checked={values.notify_by[key] !== undefined} onChange={(e) => {
                                                    let checks = {...values.notify_by};
                                                    if(e.target.checked && !checks.hasOwnProperty(key)){
                                                        checks[key] = key;
                                                    }else if(!e.target.checked && checks.hasOwnProperty(key)){
                                                        delete checks[key];
                                                    }
                                                    setFieldValue('notify_by', checks);
                                                }} />
                                                <Text>{!isDesktop && label}</Text>
                                            </Label>
                                        </Box>
                                    ) })}
                                </Grid>
                                <Submit variant="primary" text={t('Update Preference')} sx={{ width: '256px' }} mt={[4, null, null, 0]} mb={3} />
                            </Form>
                        )}
                    </Formik>

                    <Divider color="light" my={4} />

                    <Heading as="h2" variant="h2" mb={2} ref={alertsHeadingRef}>{t('Announcement Alerts')}</Heading>
                    <Heading as="h4" variant="h4" mb={4} sx={{
                        fontSize: '16px'
                    }}>{t('notifications.announcements.subheading')}</Heading>

                    <Formik
                        initialValues={{
                            asx_announcement_preferences: user?.asx_announcement_preferences
                        }}
                        onSubmit={handleAsxPreferencesSubmit}
                    >
                        {() => (
                            <Form>
                                <Grid sx={{
                                    gridTemplate: notificationOptions.length === 2 ? 'auto / 60fr 20fr 20fr' : 'auto / 60fr 20fr',
                                    textAlign: 'center',
                                }}>
                                    <Box></Box>
                                    {isDesktop && notificationOptions.map(({ label, key }) => (
                                        <Text key={`notify-label-${key}`}>
                                            {label}
                                        </Text>
                                    ))}
                                </Grid>
                                <Grid sx={{
                                        gridTemplate: ['auto / 3fr 2fr 2fr', null, null, notificationOptions.length === 2 ? 'auto / 60fr 20fr 20fr' : 'auto / 60fr 20fr'],
                                        justifyItems: 'center'
                                    }} my={2}>
                                        <Box mb={[1, null, null, 3]}>
                                            <Heading variant="h5" sx={{
                                                fontSize: '18px',
                                                fontWeight: 700,
                                                paddingLeft: '8px'}}></Heading>
                                        </Box>
                                        {notificationOptions.map(({ key }) => { return (
                                            <Box key={`all-securities-announcement-notify-by-${key}`} py={[0, null, null, '12px']} sx={{width: 'max-content', alignItems: 'center'}}>
                                                <Label sx={{
                                                    alignItems: 'center',
                                                    justifyContent: ['left', null, null, 'center']
                                                }}>
                                                    <Button
                                                        variant="secondary" 
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleToggleAsxPreferences(key);
                                                        }}>
                                                            {user?.asx_announcement_preferences?.all_securities?.notify_by?.[key] !== undefined ? 'Unselect All' : 'Select All'}
                                                    </Button>
                                                </Label>
                                            </Box>
                                        ) })}
                                    </Grid>
                                {/* All "normal" ASX codes have 3 digits, the rest are options etc. and should be ignored */}
                                {portfolio?.equityholdings?.results?.map((holding, securityKey) => holding?.security_code?.length !== 3 ? null : (
                                    <Grid key={`security-${securityKey}`} sx={{
                                        gridTemplate: ['auto / 3fr 2fr 2fr', null, null, notificationOptions.length === 2 ? 'auto / 60fr 20fr 20fr' : 'auto / 60fr 20fr']
                                    }} mb={!isDesktop ? 3 : 0}>
                                        <Box mb={[0, null, null, 3]} py="12px">
                                            <Heading variant="h5" sx={{
                                                fontSize: '18px',
                                                fontWeight: 700,
                                                paddingLeft: '8px'}}>{isDesktop ? `ASX:${holding?.security_code} - ${holding?.security_description}` : `ASX:${holding.security_code}`}</Heading>
                                        </Box>
                                        {notificationOptions.map(({ label, key }) => { return (
                                            <Box key={`${securityKey}-announcement-notify-by-${key}`} py={[0, null, null, '12px']}>
                                                <Label sx={{
                                                    alignItems: 'center',
                                                    justifyContent: ['left', null, null, 'center']
                                                }}>
                                                    <Checkbox 
                                                        name={`asx_announcement_preferences['${holding?.security_code}']['notify_by'][${key}]`}
                                                        value={key} checked={user?.asx_announcement_preferences ? user?.asx_announcement_preferences[holding?.security_code]?.notify_by?.[key] !== undefined : false}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked ? key : undefined;
                                                            const preferences = user?.asx_announcement_preferences[holding?.security_code] || {};
                                                            const updatedPreferences = JSON.parse(JSON.stringify(user?.asx_announcement_preferences || {}));

                                                            updatedPreferences[holding.security_code] = {
                                                                ...preferences,
                                                                notify_by: {
                                                                    ...preferences.notify_by,
                                                                    [key]: isChecked
                                                                },
                                                                security_code: holding.security_code
                                                            };

                                                            if (!isChecked) {
                                                                updatedPreferences.all_securities.notify_by[key] = undefined;
                                                            } else {
                                                                // if all other securities are checked, check all_securities
                                                                const allOtherSecuritiesChecked = Object.keys(updatedPreferences).every((k) => {
                                                                    return k === 'all_securities' || updatedPreferences[k].notify_by[key] !== undefined;
                                                                });
                                                                if (allOtherSecuritiesChecked) {
                                                                    updatedPreferences.all_securities.notify_by[key] = key;
                                                                }
                                                            }

                                                            dispatch(setAsxAnnouncementPreferences(updatedPreferences));
                                                        }}
                                                    />
                                                    <Text>{!isDesktop && label}</Text>
                                                </Label>
                                            </Box>
                                        ) })}
                                    </Grid>
                                ))}
                                <Pagination
                                    sx={{ my: 3 }}
                                    activePage={equityHoldingsPages.activePage ?? 0}
                                    pageCount={equityHoldingsPages.pageCount ?? 0}
                                    onPageChange={(event) => {
                                        const payload = getPortfolioEquityHoldings({
                                            params: {
                                                profile: localStorage.getItem('activeProfile') ?? null,
                                                page: (event.selected + 1)
                                            }
                                        });
                                        smoothScroll(alertsHeadingRef);
                                        dispatch(getPortfolioEquityHoldingsAsync(payload))
                                    }}
                                />
                                <Submit variant="primary" text={t('Update Preference')} sx={{ width: '256px' }} mt={[4, null, null, 0]} mb={3} />
                            </Form>
                        )}
                    </Formik>

                    <Heading as="h4" variant="h4" my={4} sx={{
                        fontSize: '16px'
                    }}>{t('The list above is based on your equity holdings on your Liquidity HIN for this profile. You may also be receiving notifications from your other profiles or ')} <ThemeLink as="a" href="/following">{t('companies you are following.')}</ThemeLink></Heading>

                    <Divider color="light" my={4} />

                    {notifications?.notifications && (
                        <Box>
                            <Grid sx={{
                                gridTemplate: 'auto / 75fr 25fr',
                                position: 'relative'
                            }}>
                                <Tabs tabs={tabs} selected={selectedTab} />

                                <Box sx={{ textAlign: 'right' }}>
                                    <Circle variant="secondary" size="35px" onClick={(e) => setShowOptions(!showOptions)} p="3" ml={3}>
                                        <Icon color="darker" icon="dots" size="11" />
                                    </Circle>
                                    <MenuDialog ref={menuRef} items={allOptions} isOpen={showOptions} sx={{
                                        top: 32,
                                        right: 0
                                    }} />
                                </Box>
                            </Grid>

                            {selectedTab === 'all' && unreadNotifications.length !== 0 && <Heading as="h5" variant="h5" mb={2}>{t('New')}</Heading>}
                            {unreadNotifications.map((n, key) => renderNotification(n, key))}
                            {selectedTab === 'all' && readNotifications.length !== 0 && unreadNotifications.length !== 0 && <Heading as="h5" variant="h5" mt={4} mb={2}>{t('Earlier')}</Heading>}
                            {selectedTab === 'all' && readNotifications.map((n, key) => renderNotification(n, key))}

                            {!totalShowing && (
                                <Text as="p">{t('notifications.list.none')}</Text>
                            )}

                            {selectedTab === 'all' && totalShowing > 0 && (
                                <Pagination
                                    activePage={notifications?.meta?.current_page ?? 0}
                                    pageCount={notifications?.meta?.last_page ?? 0}
                                    onPageChange={(event) => {
                                        const payload = getNotifications({ params: {
                                            page: (event.selected + 1)
                                        }});
                                        dispatch(getNotificationsAsync(payload))
                                    }}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </CSSTransition>
        </SettingsLayout>
    );
};

export default connect(mapStateToProps, null)(SettingsNotifications);
