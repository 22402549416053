export const breakpoints = ['576px', '768px', '992px', '1330px', '1600px', '1920px'];

export const theme = {
    breakpoints: breakpoints,
    colors: {
        text: '#00000A',
        white: '#fff',
        black: '#000',
        background: '#fff',
        darker: '#00000A', // Super Dark
        darkerHover: '#28282D',
        dark: '#6A7175',
        darkOpacity: 'rgba(69, 86, 90, 0.2)',
        medium: '#D5D9DA',
        light: '#F7F8F9',
        lighter: '#f8f7f5', // Shell White
        primary: '#00000A',
        secondary: '#FAFAFA',
        secondaryLight: '#ECFAF9',
        placeholder: '#CBD2D4',
        disabled: '#6A7175',
        disabledBackground: 'rgba(69, 86, 90, 0.025)',

        tealLighter: '#D2FBF7',
        tealLight: '#1DE9D5',
        teal: '#1AD3C1',
        tealDark: '#19C5B4',

        slate: '#373A47',
        error: '#ED1E43',
        warning: '#ED9A1E',
        success: '#05934a', // Forrest
        info: '#006991', // Indian Ocean
        muted: '#f4f4f5',
        loss: 'red',

        utilityGray50: '#F9FAFB',
        utilityGray200: '#EAECF0',
        utilityBrand700: '#058F96',
        utilityBrand200: '#7ED9DD',
        utilityBrand50: '#DFF6F7'
    },
    fonts: {
        body: '"Favorit", sans-serif',
        heading: '"Favorit", sans-serif',
        serif: '"Favorit"',
        monospace: 'Menlo, monospace',
    },
    fontSizes: [
        12, 14, 16, 22, 26, 32, 36, 48, 64
    ],
    fontWeights: {
        serif: 600,
        thin: 300,
        body: 400,
        heading: 600,
        bold: 600,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.125,
    },
    letterSpacings: {
        body: 'normal',
        caps: '0.2em',
    },
    alerts: {
        primary: {
            color: 'darker',
            bg: 'light',
            fontWeight: 'body',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        danger: {
            color: 'white',
            bg: 'error',
            fontWeight: 'body'
        },
        success: {
            color: 'white',
            bg: 'success',
            fontWeight: 'body'
        },
        info: {
            color: 'white',
            bg: 'info',
            fontWeight: 'body'
        },
        bid: {
            color: 'primary',
            border: '1px solid',
            borderColor: 'utilityGray200',
            bg: 'utilityGray50',
            borderRadius: '9999px',
            fontSize: 0,
            lineHeight: '18px',
            padding: '4px 10px 4px 4px',
            alignSelf: 'center'
        },
        warning: {
            color: 'white',
            bg: 'warning',
            fontWeight: 'body'
        },
        muted: {
            color: 'text',
            bg: 'muted',
            fontWeight: 'body'
        },
    },
    badges:{
        primary: {
            backgroundColor: 'primary',
            color: 'white',
        },
        new: {
            fontSize: 0,
            lineHeight: '18px',
            color: 'utilityBrand700',
            border: '1px solid',
            borderColor: 'utilityBrand200',
            bg: 'utilityBrand50',
            borderRadius: '9999px',
            padding: '2px 8px',
            textTransform: 'uppercase'
        },
        light: {
            padding: '3px 8px',
            backgroundColor: 'light',
            color: 'dark',
            display: 'inline-block',
            borderRadius: '2px'
        },
        coins: {
            display: 'inline-flex',
            alignItems: 'center',
            borderRadius: '20px',
            padding: '6px 7px',
            backgroundColor: 'transparent',
            fontWeight: 'normal',
            color: t => t.colors.darker,
            border: t => `1px solid ${t.colors.darker}`,
            transition: 'color 0.5s, border-color 0.3s ease-in-out',
            '&:hover': {
              border: t => `1px solid ${t.colors.tealDark}`,
              color: t => t.colors.tealDark
            },
            '& svg path': {
              transition: 'fill 0.5s ease-in-out',
            }
        },
        profile: {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            color: 'tealDark',
            bg: '#ECFCFA',
            borderRadius: '9999px',
            padding: '2px 8px',
        }
    },
    buttons: {
        sizes: {
            default: {
                px: [2,3,3,3],
                py: '6px',
                fontSize: 2,
                outlineOffset: '4px'
            },
            small: {
                px: 3,
                py: 2,
                fontSize: 0,
                outlineOffset: '4px'
            },
            large: {
                px: 5,
                py: 3,
                outlineOffset: '4px'
            },
            tag: {
                px: '24px',
                py: 2,
                fontSize: 0,
                borderRadius: '16px',
                lineHeight: 1
            },
        },
        close: {
            color: 'dark',
            cursor: 'pointer',
            bg: 'light',
            borderRadius: '50%',
            fontSize: '14px',
            transition: 'background 0.5s ease-in-out',
            p: 0,
            width: '20px',
            height: '20px',

            '& svg': {
                width: '14px',
                height: '14px',
            },
            '&:hover': {
                color: 'darker',
                bg: '#EAEDEF',
            },
            '&:focus': {
                bg: '#EAEDEF',
                outlineColor: 'white'
            }
        },
        closeWhite: {
            color: 'white',
            cursor: 'pointer',

            '&:hover': {
                color: 'light',
                bg: 'rgba(255,255,255,0.1)',
            },
            '&:focus': {
                color: 'light',
                bg: 'rgba(255,255,255,0.1)',
                outlineColor: 'white'
            }
        },
        primary: {
            cursor: 'pointer',
            borderRadius: '8px',
            border: '2px solid transparent',
            bg: 'darker',
            fontWeight: 500,

            '&:hover': {
                bg: 'dark',
            },
            '&:focus': {
                bg: 'dark',
                outlineColor: '#3DC9C1'
            },
            '&:disabled': {
                bg: '#D5D9DA'
            }
        },
        danger: {
            cursor: 'pointer',
            borderRadius: '6px',
            border: '2px solid transparent',
            bg: 'error',
            fontWeight: 'bold',

            '&:hover': {
                bg: 'darker',
            },
            '&:focus': {
                bg: 'darker',
                outlineColor: '#3DC9C1'
            }
        },
        secondary: {
            cursor: 'pointer',
            borderRadius: '6px',
            border: '2px solid transparent',
            bg: 'secondary',
            color: 'text',

            '&:hover': {
                bg: '#EAEDEF',
            },
            '&:focus': {
                bg: 'light',
                outlineColor: '#3DC9C1'
            },
            '&:active': {
                bg: '#D2FBF7'
            }
        },
        tealLight: {
            backgroundColor: 'tealLight',
            color: t => t.colors.black,
            borderRadius: '8px',
            fontWeight: 700,
            cursor: 'pointer',
            transition: 'background 0.3s ease-in-out',

            '&:hover': {
                backgroundColor: '#1AD3C1'
            },
            '&:focus': {
                outlineColor: '#00F4C8'
            }
        },
        light: {
            cursor: 'pointer',
            borderRadius: '6px',
            border: '2px solid transparent',
            bg: 'light',
            color: 'text',
            fontWeight: 500,

            '&:hover': {
                bg: 'darker',
                color: 'light',
            },
            '&:focus': {
                bg: 'darker',
                color: 'light',
                outlineColor: '#3DC9C1'
            }
        },
        link: {
            fontFamily: 'Favorit, sans-serif',
            cursor: 'pointer',
            bg: 'transparent',
            color: 'primary',
            position: 'relative',
            border: '2px solid transparent',
            '&:focus': {
                outline: 0,
            },
            '&:hover': {
                color: 'tealDark'
            },
        },
        clear: {
            cursor: 'pointer',
            bg: 'transparent',
            color: 'primary',
            border: '2px solid transparent',
            '&:focus': {
                outline: 0,
                borderColor: 'light'
            },
            '&:hover': {
                borderColor: 'light'
            },
        },
        slickNav: {
            bg: 'transparent',
            color: 'white',
            position: 'absolute',
            top: '50%',
            zIndex: 50,
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            transition: 'none',
            p: 1,
            lineHeight: 0,
            mx: 2,
            '&:hover': {
                color: 'tealDark'
            },
            '&:focus': {
                outlineStyle: 'solid',
                outlineWidth: '2px',
                outlineColor: 'tealDark'
            }
        },
        bordered: {
            cursor: 'pointer',
            border: t => `2px solid ${t.colors.primary}`,
            bg: 'transparent',
            color: 'primary',
            borderRadius: '6px',
            '&:hover': {
                bg: 'primary',
                color: 'white'
            },
            '&:focus': {
                bg: 'primary',
                color: 'white',
                outlineColor: '#3DC9C1'
            }
        },
        borderedActive: {
            cursor: 'pointer',
            border: t => `2px solid ${t.colors.darker}`,
            bg: 'transparent',
            color: 'primary',
            '&:hover': {
                borderColor: 'darker'
            },
            '&:focus': {
                borderColor: 'darker'
            }
        },
        borderedWhite: {
            cursor: 'pointer',
            border: t => `2px solid ${t.colors.white}`,
            bg: 'transparent',
            color: 'white',
            borderRadius: '6px',
            '&:hover': {
                bg: 'darker',
                borderColor: 'darker',
                color: 'white'
            },
            '&:focus': {
                bg: 'darker',
                borderColor: 'darker',
                color: 'white',
                outlineColor: '#3DC9C1'
            }
        },
        borderedMedium: {
            cursor: 'pointer',
            border: t => `2px solid ${t.colors.medium}`,
            bg: 'transparent',
            color: 'primary',
            borderRadius: '6px',
            '&:hover': {
                bg: 'primary',
                color: 'white',
                borderColor: 'primary'
            },
            '&:focus': {
                bg: 'primary',
                color: 'white',
                outlineColor: '#3DC9C1',
                borderColor: 'primary'
            }
        },
        switchProfile: {
            position: 'relative',
            display: 'flex',
            padding: 2,
            borderRadius: '213px',
            background: t => t.colors.darker,
            color: t => t.colors.white,
            alignItems: 'center',
            border: '1px solid white'
        },
        watchlist: {
            display: 'inline-flex',
            cursor: 'pointer',
            border: t => `1px solid ${t.colors.darker}`,
            bg: 'white',
            color: 'primary',
            padding: '10px',
            fontSize: '14px',
            transition: 'all 0.3s ease-in-out',

            '&:hover': {
                bg: 'light'
            },
            '&:focus': {
                bg: 'light',
                outlineColor: '#3DC9C1'
            },
            '&.active': {
                color: 'dark',
                borderColor: 'dark'
            }
        },
        increment: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            border: '1px solid',
            borderColor: ['white', null, null, 'darker'],
            borderRadius: '8px',
            bg: 'transparent',
            color: ['white', null, null, 'primary'],
            width: '103px',
            height: '48px',
            py: 3,
            px: 4,
            '&:hover': {
                bg: ['white', null, null, 'primary'],
                borderColor: ['white', null, null, 'primary'],
                color: ['primary', null, null, 'white']
            },
            '&:focus': {
                bg: ['white', null, null, 'primary'],
                borderColor: ['white', null, null, 'primary'],
                color: ['primary', null, null, 'white']
            },
            '& svg path': {
                transition: 'none'
            }
        },
        tab: {
            borderRadius: '20px',
            color: 'darker',
            fontWeight: 500,
            fontSize: 2,
            backgroundColor: 'light',
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            '&.active': {
                backgroundColor: '#D2FBF7'
            },
            '&:focus': {
                outlineColor: '#3DC9C1'
            },
            '&:not(.active):hover': {
                backgroundColor: '#EAEDEF'
            }
        },
        tabUnderline: {
            borderRadius: 0,
            color: 'dark',
            fontWeight: 500,
            fontSize: 2,
            backgroundColor: 'transparent',
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            borderBottom: '1px solid transparent',
            py: 1,
            px: 0,
            '&.active': {
                color: 'darker',
                borderBottomColor: 'darker',
            },
            '&.invalid': {
                color: 'error',
                '&.active': {
                    borderBottomColor: 'error',
                }
            },
            '&:focus': {
                outlineColor: '#3DC9C1'
            },
            '&:not(.active):hover': {
                backgroundColor: 'transparent',
                color: 'darker',
                borderBottomColor: 'darker',
            }
        },
        icon: {
            bg: 'transparent',
            p: 1,
            color: 'medium',
            lineHeight: 0,
            cursor: 'pointer',
            '&:hover': {
                bg: 'light'
            },
            '&.active': {
                color: 'tealDark'
            }
        }
    },
    cards: {
        primary: {
            border: '0.5px solid',
            borderColor: 'light',
            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)',
            borderRadius: '8px'
        },
        deal: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)',
            borderRadius: '8px',
            overflow: 'hidden',
            textDecoration: 'none',
            transition: 'box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out',
            '&:hover': {
                boxShadow: '0px 16px 26px rgba(25, 197, 180, 0.16)',
                transform: 'translateY(-5px)'
            }
        },
        company: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)',
            borderRadius: '8px',
            overflow: 'hidden',
            textDecoration: 'none',
            transition: 'box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out',
            '&:hover': {
                boxShadow: '0px 16px 26px rgba(25, 197, 180, 0.16)',
                transform: 'translateY(-5px)'
            }
        },
        compact: {
            padding: 0,
            overflow: 'hidden',
            border: '0',
            borderRadius: '16px',
            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)',
            textDecoration: 'none',
            transition: 'box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out',
            '&:hover': {
                boxShadow: '0px 16px 26px rgba(25, 197, 180, 0.16)',
                transform: 'translateY(-5px)'
            }
        },
        list: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)',
            borderColor: 'light',
            borderRadius: '8px',
            textDecoration: 'none',
            transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': {
                boxShadow: '0px 16px 26px rgba(25, 197, 180, 0.16)'
            }
        },
        featured: {
            color: 'medium',
            border: '0.5px solid',
            borderColor: 'light',
            bg: 'white',
            borderRadius: '16px',
            overflow: 'hidden',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.06)',
            position: 'relative',
            display: 'grid',
            gridTemplate: [null, null, null, 'auto/500px auto', 'auto/664px auto'],
            width: '100%',
            textDecoration: 'none',
            transition: 'box-shadow 0.3s ease-in-out, transform 0.5s ease-in-out',
            '&:hover': {
                boxShadow: '0px 16px 26px rgba(25, 197, 180, 0.16)',
                transform: 'translateY(-5px)'
            },
        },
        featured_compact: {
            border: '0.5px solid',
            borderColor: 'light',
            bg: 'white',
            textDecoration: 'none',
            position: 'relative',
            maxWidth: '392px',
            display: 'flex',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.06)',
            flexDirection: 'column',
            transition: 'box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out',
            '&:hover': {
                boxShadow: '0px 8px 20px rgba(25, 197, 180, 0.1)',
                transform: 'translateY(-5px)'
            }
        },
        shadow: {
            boxShadow: '3px 3px 20px rgba(29, 43, 43, 0.1)',
            bg: 'white',
            borderRadius: '16px'
        },
        link: {
            boxShadow: '3px 3px 20px rgba(29, 43, 43, 0.1)',
            bg: 'white',
            borderRadius: '16px',
            transition: 'box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out',
            '&:hover': {
                boxShadow: '0px 16px 26px rgba(25, 197, 180, 0.16)',
                transform: 'translateY(-5px)'
            },
            '&:hover::after': {
                display: 'none',
            }
        }
    },
    forms: {
        label: {
            fontSize: 1,
            fontWeight: 'normal'
        },
        checkbox: {
            width: '32px',
            height: '32px',
            'input:checked ~ &': {
                borderRadius: '4px',
                color: 'tealDark',
            },

            small: {
                width: '22px',
                height: '22px',

                'input:checked ~ &': {
                    borderRadius: '4px',
                    color: 'tealDark',
                },
            }
        },
        switch: {
            width: ['40px'],
            backgroundColor: 'medium',
            'input:checked ~ &': {
                backgroundColor: 'tealLight',
            },
            containerType: 'inline-size',

            dark: {
                width: ['40px'],

                'input:checked ~ &': {
                    backgroundColor: 'darker',
                },
            },
            tealLight: {
                width: ['40px'],

                'input:checked ~ &': {
                    backgroundColor: 'tealLight',
                },
                '~ span': {
                    color: 'light',
                },
                '~ span a': {
                    color: 'tealLight'
                }
            }
        },
        input: {
            border: (t) => `1px solid ${t.colors.light}`,
            borderRadius: '8px',
            backgroundColor: 'white',
            fontSize: '16px',
            color: t => t.colors.dark,
            fontFamily: t => t.fonts.body,
            '&:hover': {
                border: t => `1px solid ${t.colors.primary}`,
            },
            width: '100%',

            sizes: {
                default: {
                    padding: '4px 14px'
                },

                small: {
                    padding: '6px 12px'
                },

                large: {
                    padding: '28px 18px'
                },
            },

            investment: {
                textAlign: 'center',
                fontFamily: 'serif',
                fontSize: '48px',
                padding: '',
                width: 'auto',
                fontWeight: 400
            }
        }
    },
    sizes: {
        sm: {
            px: 3,
            py: 2,
        },
        lg: {
            px: 5,
            py: 3,
        }
    },
    spinner: {
        color: 'dark',
    },
    text: {
        heading: {
            color: 'darker',
            fontWeight: 'heading',
            fontSize: 6,
            lineHeight: 'heading'
        },
        subtext: {
            color: 'dark',
            fontSize: '18px'
        },
        display: {
            color: 'darker',
            fontWeight: 'heading',
            fontSize: [5, 5, 5, 7],
            lineHeight: 'heading'
        },
        h1: {
            color: 'darker',
            fontWeight: 'normal',
            fontSize: [4, 6],
            lineHeight: 'heading'
        },
        publicH1: {
            fontSize: 4,
        },
        h2: {
            color: 'darker',
            fontWeight: 500,
            fontSize: [4, 5],
            lineHeight: 'heading'
        },
        h3: {
            color: 'darker',
            fontWeight: 'normal',
            fontSize: [3,4],
            lineHeight: 'heading'
        },
        h4: {
            color: 'dark',
            fontWeight: 'normal',
            fontSize: 3,
            lineHeight: 'heading'
        },
        h5: {
            color: 'darker',
            fontWeight: 500,
            fontSize: 2,
            lineHeight: 'heading'
        },
        h6: {
            color: 'darker',
            fontWeight: 'normal',
            fontSize: 1,
            lineHeight: 'heading'
        },
        content_block_heading: {
            color: 'darker',
            fontWeight: 'normal',
            fontSize: '26px',
            lineHeight: 'heading',
            py: 3,
            mb: 4,
            borderBottom: '1px solid',
            borderBottomColor: 'medium',
        },
        page_title: {
            fontSize: '64px',
            lineHeight: '110%',
            color: t => t.colors.white,
            fontWeight: 'normal'
        },
        price: {
            color: 'darker',
            fontSize: 4,
            fontWeight: 500
        },
        form_section: {
            color: 'darker',
            fontWeight: 'normal',
            fontSize: 2
        },
        profile_section: {
            color: 'dark',
            fontWeight: 'normal',
            fontSize: 0,
            lineHeight: 1.3
        },
        section_heading: {
            color: 'darker',
            fontWeight: 700,
            fontSize: '18px'
        },
        menu_heading: {
            fontSize: '20px',
            fontWeight: 'normal'
        },
        deal_heading_compact: {
            color: 'darker',
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: 'heading'
        },
        deal_heading_list: {
            color: 'darker',
            fontWeight: 'normal',
            fontSize: '18px',
            lineHeight: 'heading'
        },
        filter_label: {
            fontSize: ['14px','20px'],
            display: ['none', 'none', 'none','none','block'],
            fontWeight: 'normal',
            mt: ['4px', 0]
        },
        footer_heading: {
            color: 'light',
            fontWeight: 'normal',
            fontSize: 0
        },
        settings_heading: {
            color: 'slate',
            fontSize: '18px',
            fontWeight: 500,
            mb: 4
        },
        body_large: {
            fontWeight: 400,
            color: 'dark'
        },
        portfolio_item: {
            fontSize: '26px',
            fontWeight: 700,
            color: 'darker'
        },
        gain: {
            fontSize: '14px',
            color: 'tealDark',
        },
        tag: {
            color: 'darker',
            fontWeight: 500,
            fontSize: 0
        },
        info: {
            fontSize: '24px',
            fontWeight: 500
        },
        captionTitle: {
            fontSize: '14px',
            fontWeight: 'bold'
        },
        caption: {
            fontSize: '13px',
            fontWeight: 'thin'
        },
        labelCompact: {
            fontSize: '14px',
            lineHeight: 1,
            color: 'dark',
            mb: 1
        },
        labelSmall: {
            display: 'block',
            fontSize: '12px',
            lineHeight: '1.5',
            color: 'dark',
            mt: -2,
            mb: 4,
            fontWeight: 300,
        },
        small: {
            fontSize: ['15px', '16px']
        },
        smaller: {
            fontSize: '13px'
        },
        smallLight: {
            fontSize: '14px',
            color: 'dark'
        },
        success: {
            color: 'success'
        },
        error: {
            color: 'error'
        }
    },
    links: {
        icon: {
            cursor: 'pointer',
            display: 'flex',
            color: 'medium',
            transition: 'none',
            '&:hover': {
                color: 'tealDark'
            },
            '&:focus': {
                outlineColor: 'tealDark'
            }
        },
        nav: {
            px: '12px',
            py: [3, 2],
            display: 'inline-flex',
            alignItems: 'center',
            fontWeight: 'normal',
            color: 'darker',
            textAlign: 'center',
            justifyContent: 'center',

            '&:focus': {
                outline: '0',
                color: t => t.colors.tealDark
            },
            '&:hover': {
                color: t => t.colors.tealDark
            },
        },
        jumpTo: {
            display: 'flex',
            textDecoration: 'none',
            color: 'dark',
            whiteSpace: 'normal',
            fontSize: '18px',
            border: 'solid transparent',
            borderWidth: '2px 0 2px 0',
            alignItems: 'flex-start',
            py: 2,
            borderRadius: '8px',
            transition: 'box-shadow 0.3s ease-in-out, color 0.3s ease-in-out',
            '& svg': {
                color: 'tealDark',
                transition: 'margin-right 0.3s ease-in-out',
                mr: '8px',
                '& path': {
                    transition: 'stroke 0.3s ease-in-out'
                }
            },
            '&:hover, &:focus, &.active': {
                color: 'darker',
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)',
                '& svg': {
                    mr: '0px',
                    '& path': {
                        stroke: 'tealLight'
                    }
                }
            },
            '&:focus, &.active': {
                outline: 'none',
                bg: 'light',
                '& svg path': {
                    stroke: 'darker'
                }
            }
        },
        sidemenu: {
            display: 'flex',
            textDecoration: 'none',
            color: 'dark',
            whiteSpace: 'normal',
            fontSize: '18px',
            border: 'solid transparent',
            borderWidth: '2px 0 2px 0',
            px: 3,
            py: 2,
            borderRadius: '8px',
            transition: 'box-shadow 0.3s ease-in-out, color 0.3s ease-in-out',
            '& svg': {
                transition: 'margin-right 0.3s ease-in-out',
                mr: '8px',
                '& path': {
                    transition: 'stroke 0.3s ease-in-out'
                }
            },
            '&:hover, &:focus, &.active': {
                color: 'darker',
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)',
                '& svg': {
                    mr: '0px',
                    '& path': {
                        stroke: 'tealLight'
                    }
                }
            },
            '&:focus, &.active': {
                outline: 'none',
                bg: 'light',
                '& svg path': {
                    stroke: 'darker'
                }
            }
        },
        breadcrumb: {
            bg: 'transparent',
            color: 'dark',
            position: 'relative',
            textDecoration: 'none',
            fontSize: 1,
            borderRadius: '6px',
            '&:focus': {
                outline: t => `2px solid ${t.colors.tealDark}`,
                outlineOffset: '3px'
            },
            '&:hover': {
                color: 'dark',
                '&::after': {
                    width: '100%'
                }
            },
            '&::after': {
                display: 'inline-block',
                top: '100%',
                height: '2px',
                width: '0',
                left: '0',
                position: 'absolute',
                backgroundColor: 'tealDark',
                transition: '0.2s width ease-in-out'
            }
        },
        link: {
            color: 'darker',
            textDecoration: 'none',
            fontWeight: 500
        },
        menuitem: {
            display: 'flex !important',
            alignItems: 'center',
            fontWeight: 500,
            padding: '13px',
            marginTop: '8px',
            borderRadius: '8px',
            cursor: 'pointer',
            '&:first-of-type': {
                marginTop: 0
            },
            '&:hover': {
                background: t => t.colors.secondaryLight
            },
            '&:focus': {
                background: t => t.colors.secondaryLight
            },
            '&.active': {
                background: t => t.colors.secondaryLight
            }
        },
        utility: {
            px: "13px",
            py: 2,
            display: 'inline-flex',
            alignItems: 'center',
            fontWeight: 'normal',
            color: 'darker',

            '.header__transparent:not(.menu-open) &:not(:hover)': {
                color: '#fff'
            },
            '&:focus': {
                outline: '0',
                color: t => t.colors.tealDark
            },
            '&:hover': {
                color: t => t.colors.tealDark
            },
        },
        footer: {
            color: 'light',
            fontWeight: '300',
            fontSize: 3,
            py: 1,
            position: 'relative',
            borderRadius: '4px',

            '&:focus': {
                color: 'light',
                outlineStyle: 'solid',
                outlineColor: 'tealDark',

                '&:after':{
                    width: '100%'
                }
            },
            '&:hover': {
                color: 'tealDark',
            }
        },
        light: {
            fontSize: '13px',
            position: 'relative',
            color: 'dark',
            textDecoration: 'none',
            '&:hover::after': {
                width: '100%'
            },
            '&:after': {
                display: 'inline-block',
                top: '100%',
                height: '2px',
                width: '0',
                left: '0',
                position: 'absolute',
                backgroundColor: 'tealDark',
                transition: '0.2s width ease-in-out'
            }
        },
        footer_legal: {
            color: 'light',
            fontWeight: '300',
            fontSize: '11px',
            py: 1,
            position: 'relative',
            textDecoration: 'none',

            '&:focus': {
                color: 'light',
                outline: '0',

                '&:after':{
                    width: '100%'
                }
            },
            '&:hover': {
                color: 'light',

                '&:after':{
                    width: '100%'
                }
            },
            '&:after': {
                display: 'inline-block',
                top: '100%',
                height: '1px',
                width: '0',
                left: '0',
                position: 'absolute',
                backgroundColor: 'tealDark',
                transition: '0.2s width ease-in-out'
            }
        },
        policies: {
            display: 'flex',
            textDecoration: 'none',
            color: 'darker',
            whiteSpace: 'normal',
            fontSize: '18px',
            border: 'solid transparent',
            borderWidth: '2px 0 2px 0',
            px: 3,
            py: 2,
            borderRadius: '8px',
            transition: 'background 0.3s ease-in-out, color 0.3s ease-in-out',
            '&:hover, &:focus, &.active': {
                color: 'darker',
                outline: 'none',
                bg: 'secondaryLight'
            }
        }
    },
    layouts: {
        pageBanner: {
            minHeight: '170px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            px: [0,0]
        },
        small: {
            py: [3,4]
        },
        default: {
            py: [4,4]
        },
        portfolio: {
            pt: [4, 4],
            pb: [4, 3]
        },
        section: {
            py: [3,4]
        },
        large: {
            py: [4,5,6]
        },
        disclaimer: {
            py: '80px'
        },
    },
    styles: {
        whiteSpace: 'pre-line',
        header: {
            height: '88px',
            heightSmall: '60px',
            whiteSpace: 'normal',
        },
        spinner: {
            display: 'block',
            mx: 'auto',
            strokeWidth: '2px',
            color: 'tealLight',
            whiteSpace: 'normal',
        },
        root: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
            a: {
                color: 'darker'
            }
        },
        progress: {
            color: 'tealLight',
            bg: 'light',
            height: '6px',
            borderRadius: '20px',
            whiteSpace: 'normal',
        },
        h1: {
            variant: 'text.h1',
            mt: 0,
            mb: 3,
            whiteSpace: 'normal',
        },
        h2: {
            variant: 'text.h2',
            mt: 0,
            mb: 3,
            whiteSpace: 'normal',
        },
        h3: {
            variant: 'text.h3',
            mt: 0,
            mb: 3,
            whiteSpace: 'normal',
        },
        h4: {
            variant: 'text.h4',
            mt: 0,
            mb: 3,
            whiteSpace: 'normal',
        },
        h5: {
            variant: 'text.h5',
            mt: 0,
            mb: 3,
            whiteSpace: 'normal',
        },
        h6: {
            variant: 'text.h6',
            mt: 0,
            mb: 3,
            whiteSpace: 'normal',
        },
        p: {
            lineHeight: 'body',
            marginBottom: 3
        },
        a: {
            color: 'primary',
            textDecoration: 'none',
            position: 'relative',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            cursor: 'pointer',
            ':not(.btn)': {
                '&:focus': {
                    outline: t => `2px solid ${t.colors.tealDark}`,
                    outlineOffset: '3px',
                    borderRadius: '6px'
                },
                '&:hover::after': {
                    width: '100%'
                },
                '&:after': {
                    content: '""',
                    display: 'inline-block',
                    top: '100%',
                    height: '2px',
                    width: '0',
                    left: '0',
                    position: 'absolute',
                    backgroundColor: 'tealDark',
                    transition: '0.2s width ease-in-out'
                }
            }
        },
        ol: {
            mt: 0,
            listStyle: 'lower-alpha',
            whiteSpace: 'normal',
        },
        ul: {
            mt: 0,
            whiteSpace: 'normal',
        },
        menuitem: {
            display: 'flex !important',
            alignItems: 'center',
            fontWeight: 500,
            padding: '13px',
            marginTop: '8px',
            borderRadius: '8px',
            cursor: 'pointer',
            '&:first-of-type': {
                marginTop: 0
            },
        },
        captionWrapper: {
            display: 'inline-flex',
            alignItems: 'center',
            bg: 'white',
            p: 3,
            borderRadius: 8,
            color: 'dark',
            border: '1px solid #D5D9DA',
            lineHeight: 1,
        },
        market_data: {
            color: 'darker',
            textTransform: 'uppercase',
            fontSize: 1,
            fontWeight: 500
        },
        market_data_value: {
            color: 'darker',
            fontSize: 1
        }
    },

    // These don't inherit theme styles; but we use them to style consistently
    modals: {
        default: {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(69, 86, 90, 0.8)',
                zIndex: 1000
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                textAlign: 'center',
                marginRight: '-50%',
                width: '100%',
                maxWidth: '606px',
                overflow: 'visible',
                transform: 'translate(-50%, -50%)',
                padding: '4em',
                borderRadius: '16px',
                maxHeight: '100vh',
                overflowY: 'auto',
                overflowX: 'hidden',
            },
        },
        terms: {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(69, 86, 90, 0.8)',
                zIndex: 2000
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                textAlign: 'left',
                marginRight: '-50%',
                width: '100%',
                maxWidth: '1000px',
                overflow: 'hidden',
                maxHeight: '85vh',
                transform: 'translate(-50%, -50%)',
                padding: '3em',
                borderRadius: '16px',
            }
        },
        intro: {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0,0,0,0.6)',
                zIndex: 1000,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            content: {
                position: 'relative',
                inset: 'unset',
                textAlign: 'center',
                maxHeight: '100vh',
                maxWidth: '824px',
                padding: 0,
                paddingBottom: '4em',
                borderRadius: '16px',
                border: 'none',
                overflowX: 'hidden',
                overflowY: 'auto',
                width: '90%',
            },
        },
        transactions: {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(69, 86, 90, 0.8)',
                zIndex: 1000
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                textAlign: 'center',
                marginRight: '-50%',
                width: '100%',
                maxWidth: '1200px',
                transform: 'translate(-50%, -50%)',
                padding: 0,
                border: 0,
                borderRadius: '16px',
                overflow: 'hidden',
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)'
            },
        },
        deal_bid: {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(69, 86, 90, 0.8)',
                zIndex: 1000
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                textAlign: 'center',
                marginRight: '-50%',
                width: '100%',
                maxWidth: '1060px',
                transform: 'translate(-50%, -50%)',
                padding: 0,
                border: 0,
                borderRadius: '16px',
                overflow: 'hidden',
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)',
            },
        },
        verify: {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(69, 86, 90, 0.8)',
                zIndex: 1000
            },
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                textAlign: 'left',
                marginRight: '-50%',
                width: '100%',
                maxWidth: '497px',
                transform: 'translate(-50%, -50%)',
                padding: 0,
                border: 0,
                borderRadius: '16px',
                overflow: 'hidden',
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)'
            },
        }
    }
};
