import React, { useState, forwardRef } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useField } from 'formik';
import { Label } from '~/Forms/Label';
import { useDebounce } from 'hooks/useDebounce';
import { theme } from 'app/theme';
import { VerifyStyled } from './styled';

// forward ref so we can focus with parent components
export const VerifyField = forwardRef(
    (
        {
            label,
            name,
            apiName,
            type,
            className,
            placeholder,
            disabled,
            controlledInput,
            validationError,
            showLabel,
            onChangeOverride,
            onChange,
            customValueChange,
            validateField,
            fields,
            autoFocus = true,
            sx,
            ...props
        },
        ref
    ) => {
        const [focused, setFocused] = useState(false);
        const [field, meta, { setValue, setTouched }] = useField(name);
        const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);
        const fieldFocusedOrFilled = focused || meta.value !== '' || placeholder !== '';

        const fieldProps = { ...field, ...props };

        return (
            <VerifyStyled sx={sx}>
                <Label
                    label={label}
                    showLabel={false}
                    fieldFocusedOrFilled={fieldFocusedOrFilled}
                    field={field}
                    placeholder={placeholder}
                    meta={meta}
                    validationError={validationError}
                />

                <ReactInputVerificationCode
                    {...fieldProps}
                    {...props}
                    type={type}
                    length={fields}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    value={meta.value}
                    ref={ref}
                    invalid={meta.touched && meta.error}
                    onChange={(value) => {
                        if (onChangeOverride) {
                            onChangeOverride(value);
                        } else {
                            setValue(value, true);
                            if(validateField !== undefined){
                                validateField(field.name);
                            }

                            onChange &&
                                debouncedSave({
                                    name: apiName,
                                    value: value === null ? '' : value,
                                });
                        }
                    }}
                    touch={() => setFocused(true)}
                    untouch={() => {
                        setTouched(field.name, true);
                        setFocused(false);
                    }}
                />
            </VerifyStyled>
        );
    }
);

VerifyField.defaultProps = {
    validationError: false,
    placeholder: '',
    showLabel: true,
    controlledInput: true,
    type: 'text',
    required: false,
    disabled: false,
    fields: 6,
    autoComplete: 'true',
    inputStyle: {
        fontFamily: theme.fonts.body,
        borderRadius: '2px',
        fontSize: '16px',
        height: '50px',
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        color: theme.colors.text,
    },
    inputStyleInvalid: {
        border: `1px solid ${theme.colors.error}`
    }
};
