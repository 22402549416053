import { useRef } from 'react';
import { FullServiceTradeOverlay as FullServiceTradeOverlayStyled } from './styled';
import { useDispatch } from 'react-redux';
import { setShowFullService } from 'features/theme/themeSlice';
import {
    Container
} from '~/Common';
import {
    Box,
    Flex,
    Link,
    Text
} from 'theme-ui';
import { useMenuClose } from 'hooks/useMenuClose';
import { useTranslation } from 'react-i18next';
import { Icon } from 'assets/Icon';
import { useResponsiveValue } from '@theme-ui/match-media';

export const FullServiceTradeOverlay = ({ isOpen, ...props }) => {
    const dispatch = useDispatch();
    const overlayRef = useRef(null);
    const { t } = useTranslation();
    const isMobile = useResponsiveValue([true, true, true, false, false]);
    const isVerticalMobile = useResponsiveValue([true, false, false, false]);

    // Close menu if esc is pressed, or if click outside of reference
    useMenuClose(overlayRef, () => {
        if(isOpen) dispatch(setShowFullService(false))
    });

    return (
        <FullServiceTradeOverlayStyled role="dialog" tabIndex="-1" className={isOpen ? 'open' : ''} {...props} id="full-service">
            <Container sx={{ position: 'relative' }}>
                <Box>
                    <Flex sx={{ position: 'relative' }}>
                        <Box sx={{ position: 'relative', width: '100%', justifyContent: 'center' }} ref={overlayRef} id="full-service-trade-details">
                            <Text as={ isVerticalMobile ? "h4" : "h2" } variant="h2" mb={2} sx={{ textAlign: 'center' }}>{t('Contact us')}</Text>
                            <Flex mb={2} sx={{ gap: 3 }}>
                                <Icon icon="phone" color="tealLight" size={isVerticalMobile ? 24:  32}></Icon>
                                {isMobile ? (
                                    <Link href="tel:(08) 9386 9577" variant="body_large" sx={{ fontSize: '16px' }}>(08) 9386 9577</Link>
                                ) : (
                                    <Text as="p" variant="body_large">{t('(08) 9386 9577')}</Text>
                                )}
                            </Flex>
                            <Flex mb={2} sx={{ gap: 3 }}>
                                <Icon icon="mobile" color="tealLight" size={isVerticalMobile ? 24:  32}></Icon>
                                {isMobile ? (
                                    <Link href="tel:(+61) 411 222 927" variant="body_large" sx={{ fontSize: '16px' }}>(+61) 411 222 927</Link>
                                ) : (
                                    <Text as="p" variant="body_large">{t('(+61) 411 222 927')}</Text>
                                )}
                            </Flex>
                            <Flex sx={{ gap: 3 }}>
                                <Icon icon="mail" color="tealLight" size={isVerticalMobile ? 24:  32} mr={isVerticalMobile ? 0 :"4px"}></Icon>
                                {isMobile ? (
                                    <Link href="mailto:members@liquidity.com.au" variant="body_large" sx={{ fontSize: '16px' }}>{t('members@liquidity.com.au')}</Link>
                                ) : (
                                    <Text as="p" variant="body_large">{t('members@liquidity.com.au')}</Text>
                                )}
                                
                                
                            </Flex>  
                        </Box>                        
                    </Flex>
                </Box>
            </Container>
        </FullServiceTradeOverlayStyled>
    );
};
