import { Box } from 'theme-ui';

export const Container = ({ children, sx, variant = 'default', size = '1392px', ...props }) => (
    <Box
        px={3}
        mx="auto"
        variant={variant}
        sx={{ width: '100%', maxWidth: ['100%', '', '', size], ...sx }}
        {...props}
    >
        {children}
    </Box>
);
