import { Error } from '~/Forms/Error';
import { useField } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import {
    Text,
    Link
} from '@theme-ui/components';
import './styles.css';

export const RecaptchaField = ({ name, inputRef, ...props }) => {
    // eslint-disable-next-line
    const [field, meta, { setValue }] = useField(name);

    const handleOnChange = (response) => {
        setValue(response);
    };

    return (
        <>
            <ReCAPTCHA
                ref={inputRef}
                onChange={handleOnChange}
                size="invisible"
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE}
            />
            <Text as="p" variant="caption">
                This site is protected by reCAPTCHA and the Google <Link href="https://policies.google.com/privacy" target="_blank">Privacy Policy</Link> and <Link href="https://policies.google.com/terms" target="_blank">Terms of Service</Link> apply.
            </Text>
            {meta?.error &&
                <Error
                    text={meta?.error}
                    isVisible={meta?.error}
                    sx={{
                        mb: 4
                    }}
                />
            }
        </>
    );
};

RecaptchaField.defaultProps = {
    name: 'recaptcha'
};
