import { theme } from 'app/theme';
import { Button as ThemeButton } from '@theme-ui/components';

export const Button = ({ type, children, size = 'default', sx, ...props }) => {
    const sizes = theme?.buttons?.sizes ?? [];

    return (
        <ThemeButton
            type={type || 'button'}
            {...props}
            sx={{ ...sizes[size], ...sx }}
        >
            {children}
        </ThemeButton>
    );
};
