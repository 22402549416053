import { useState } from 'react'
import {
    Button,
} from '~/Common'
import {
    Alert,
    Box,
    Close,
    Grid
} from '@theme-ui/components';
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { Form, Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast'
import * as Yup from 'yup'
import {
    storeInvite,
    getInvitesAsync
} from 'features/invites/invitesSlice'
import { APP_FEATURES } from 'app/constants'

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email().required('Email is required'),
});

export const PeerInviteForm = ({ user }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState(false);
    const canAddMore = useSelector((s) => s.invites?.invites && s.invites?.invites.length < s.theme?.settings?.peer_invites_per_user_limit);

    const handleSubmit = (values, actions) => {
        storeInvite({
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email
        }).then((response) => {
            toast.success(t('settings.invite.success'), {
                toastId: 'invite-success',
            });
            dispatch(getInvitesAsync());
            setShowForm(false);
            actions.setStatus('success');
            actions.setSubmitting(false);
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(response?.data?.message || t('settings.invite.error'), {
                toastId: 'invite-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    };

    return (
        <>
            {APP_FEATURES.peer_invites &&
                <>
                {showForm ? <>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            email: '',
                            first_name: '',
                            last_name: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ status, setStatus, setFieldValue }) => (
                            <Box>
                                { status === 'api_error' ?
                                <Alert role="alert" variant='danger' mb={2}>
                                    {t('settings.invite.error')}
                                    <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                                </Alert> : ''}

                                <Form>
                                    <Grid mb={3} sx={{
                                        gridGap: 2,
                                        gridTemplateColumns: ['1fr'],
                                        width: '100%',
                                        maxWidth: '430px',
                                        margin: '0 auto'
                                    }}>
                                        <Field
                                            label="First Name"
                                            name="first_name"
                                        />
                                        <Field
                                            label="Last Name"
                                            name="last_name"
                                        />
                                        <Field
                                            label="Email"
                                            name="email"
                                        />
                                    </Grid>

                                    <Submit variant="primary" text={t('buttons.submit')} mr={2} />

                                    <Button variant="light" onClick={() => setShowForm(false)}>
                                        Cancel
                                    </Button>
                                </Form>
                            </Box>
                        )}
                    </Formik>
                </> : <>
                    {canAddMore &&
                        <Button variant="primary" onClick={() => setShowForm(true)}>
                            {t('buttons.invite')}
                        </Button>
                    }
                </>
                }
            </>
            }
        </>
    );
};
