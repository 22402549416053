import { DashboardLayout } from '~/Layouts';
import {
    Container,
    PageHeader
} from '~/Common';
import {
    Box,
    Heading,
    Text
} from '@theme-ui/components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown'
import moment from 'moment-timezone'
import { DATE_FORMAT } from 'app/constants'
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { user: state.user?.user, settings: state.theme?.settings }
}

const Trust = ({ user, settings  }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Trust and Safety'));

    moment.tz.setDefault(user.timezone);

    return (
        <DashboardLayout p={0}>
            <PageHeader>
                <Container variant="layouts.default">
                    <Heading as="h1" mb={4}>{t('Trust & Safety')}</Heading>
                </Container>
            </PageHeader>
            <Container variant="layouts.default">
                <Box sx={{ variant: 'styles' }}>
                    <ReactMarkdown>{settings?.trust_and_safety}</ReactMarkdown>
                </Box>
                {settings?.updated_at && <Text as="p" mt={4} variant="caption">Last updated: {moment(settings?.updated_at).format(DATE_FORMAT.last_updated)}</Text>}
            </Container>
        </DashboardLayout>
    );
};

export default connect(mapStateToProps, null)(Trust);
