import {
    Box,
    Card,
    Close,
    Flex,
    Grid,
    Heading,
    Image,
    Spinner,
    Text
} from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { toast } from 'utils/toast'
import { loginAsInvestor, getUserAsync, getAdviserAsync, setAdviserUsers } from "features/auth/authSlice"
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
    Button,
    Circle,
    ImagePlaceholder
} from '~/Common'
import { confirmAlert } from 'react-confirm-alert'
import { ThemeProvider } from 'theme-ui'
import { theme } from 'app/theme'
import { unlinkUser } from 'features/advisers/advisersAPI'
import { CSSTransition } from 'react-transition-group'
import { useState, useRef } from 'react'
import { Icon } from 'assets/Icon'

export const Users = ({ adviserUsers, showEmptyNotice = true }) => {
    const { t } = useTranslation();
    const nodeRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(adviserUsers ?? undefined);

    const handleLoginAsClick = (e, uuid) => {
        e.preventDefault();

        loginAsInvestor({
            uuid: uuid,
            device_name: 'App'
        }).then((response) => {
            const returnTo = localStorage.getItem('returnTo') ?? '/portfolio';
            dispatch(getUserAsync());
            dispatch(getAdviserAsync());

            localStorage.removeItem('returnTo');
            history.push(returnTo);
        }).catch(({ response }) => {
            toast.error(response?.data?.message ?? t('login.error'), {
                toastId: 'login-error',
            });
        });
    };

    const handleUnlinkClick = (e, uuid) => {
        e.preventDefault();

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ThemeProvider theme={theme}>
                        <Box
                            bg="white"
                            px={5}
                            py={4}
                            sx={{
                                maxWidth: '600px',
                                width: '100%',
                                textAlign: 'center',
                                boxShadow: '0 20px 75px rgb(0 0 0 / 23%)',
                                position: 'relative',
                            }}
                        >
                            <Close
                                onClick={onClose}
                                sx={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                }}
                            ></Close>

                            <Heading as="h2" variant="h2" mb={3}>
                                {t('Are you sure?')}
                            </Heading>
                            <Text as="p" mb={3}>
                                {t('Are you sure to want to unlink this user?')}
                            </Text>
                            <Text as="p" mb={3}>
                                {t("If you require access again, they will need to re-invite you.")}
                            </Text>
                            <Button
                                id="confirmUnlink"
                                variant="primary"
                                mr={3}
                                onClick={() => {
                                    confirmUnlink(uuid)
                                    onClose();
                                }}
                            >
                                {t('Yes')}
                            </Button>
                            <Button variant="link" onClick={onClose}>
                                {t('Cancel')}
                            </Button>
                        </Box>
                    </ThemeProvider>
                );
            },
        });
    };

    const confirmUnlink = (uuid) => {
        setLoading(true);
        unlinkUser(uuid).then((response) => {
            toast.success(response?.message ?? t('advisers.unlink.success'), {
                toastId: 'unlink-success',
            });

            dispatch(setAdviserUsers(response));
            setUsers(response?.data);

        }).catch(({ response }) => {
            toast.error(response?.data?.message ?? t('advisers.unlink.error'), {
                toastId: 'unlink-error',
            });
        }).finally(() => setLoading(false))
    };

    return <>
        {loading ? <Spinner variant="styles.spinner" m={'auto'} /> : (
            <CSSTransition
                nodeRef={nodeRef}
                in={true}
                timeout={500}
                classNames="fade"
                unmountOnExit
                appear
            >
                <Box ref={nodeRef} id="currentAdviserUsers">
                    {users !== undefined && users.length ? (
                        <>
                            {users.map((user, key) => (
                                <Card
                                    key={`adviserUser${key}`}
                                    variant="list"
                                    as={Box}
                                    mb={3}
                                >
                                    <Grid px={3} py={3} sx={{
                                        position: 'relative',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gridTemplate: ['1fr', '1fr', 'auto / 50px auto 200px 170px'],
                                        gridGap: '24px',
                                    }}>
                                        <Flex sx={{
                                            width: '80px',
                                            alignItems: 'center',
                                        }}>
                                            <Circle size="39px" bg="#f4f4f4" type="box" sx={{ flexShrink: 0, overflow: 'hidden', mr: 2 }}>
                                                {user?.profile_photo_url ? (
                                                    <Image src={user?.profile_photo_url} sx={{ borderRadius: '50%' }} />
                                                ) : (
                                                    <ImagePlaceholder imageSx={{ borderRadius: '50%' }} sx={{
                                                        width: '70px',
                                                        height: '70px',
                                                        borderRadius: '50%'
                                                    }} />
                                                )}
                                            </Circle>
                                        </Flex>

                                        <Box pb={[3,3,0]} sx={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: ['calc(100% - 80px)', 'auto'],
                                            maxWidth: '300px',
                                        }}>
                                            <Heading as="h3" variant="deal_heading_list" mb={0}>
                                                {t(`${user.first_name} ${user.last_name}`)}
                                            </Heading>
                                            <Text as="p" variant="tag" color="dark">{user?.email}</Text>
                                        </Box>

                                        <Box pb={[3, 3, 0]} sx={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            maxWidth: '300px',
                                        }}>
                                            <Flex sx={{
                                                justifyContent: "space-between"
                                            }}>
                                                <Text as="p" variant="tag" color="dark">
                                                    {t('Finished personal details?')}
                                                </Text>
                                                <Text as="p" variant="tag" color="dark">
                                                    {t(user?.finished_onboarding_details ? 'Yes' : 'No')}
                                                </Text>
                                            </Flex>
                                            <Flex sx={{
                                                justifyContent: "space-between"
                                            }}>
                                                <Text as="p" variant="tag" color="dark">
                                                    {t('Can see dashboard?')}
                                                </Text>
                                                <Text as="p" variant="tag" color="dark">
                                                    {t(user?.can_see_dashboard ? 'Yes' : 'No')}
                                                </Text>
                                            </Flex>
                                            <Flex sx={{
                                                justifyContent: "space-between"
                                            }}>
                                                <Text as="p" variant="tag" color="dark">
                                                    {t('Can place bids?')}
                                                </Text>
                                                <Text as="p" variant="tag" color="dark">
                                                    {t(user?.can_place_bids ? 'Yes' : 'No')}
                                                </Text>
                                            </Flex>
                                        </Box>

                                        <Flex p={[null, null, 2]} sx={{
                                            width: '100%',
                                            marginLeft: 'auto',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                        }}>
                                            <Button variant="primary" className="switchUser" onClick={(e) => handleLoginAsClick(e, user.uuid)} mb={2}>{t(`Switch account`)}</Button>
                                            <Button variant="bordered" className="unlinkUser" onClick={(e) => handleUnlinkClick(e, user.uuid)}>{t(`Unlink account`)}</Button>
                                        </Flex>
                                    </Grid>
                                </Card>
                            ))}
                        </>
                    ) : (
                        showEmptyNotice ?
                            <Box py={[4, 5]} px={[3, 4, 6]} mb={4} sx={{
                                bg: 'light',
                                borderRadius: '16px',
                                textAlign: 'center'
                            }}>
                                <Icon sx={{ color: 'tealDark' }} icon="users" size="48px" mb={3} />
                                <Heading as="h2" variant="h2" mb={4}>{t('No users currently being managed')}</Heading>
                                <Text as="p" mb={4}>{t('If you require access to help an investor, please ask them to request via their account.')}</Text>
                            </Box> : null
                    )}
                </Box>
            </CSSTransition>
        )}
    </>;
};
