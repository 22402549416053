import { useState, useEffect } from 'react';
import {
    AspectRatio,
    Embed,
    Image
} from '@theme-ui/components'
import { Icon } from 'assets/Icon';
import { getVideoEmbed } from 'utils/helpers'

export const Media = ({ ratio, sx, video, image, ...props }) => {
    const [showVideo, setShowVideo] = useState(false);
    const [embed, setEmbed] = useState(null);
    const showPlay = image && video;
    const styles = {
        position: 'absolute',
        objectFit: 'cover',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        borderRadius: '32px',
        overflow: 'hidden'
    };

    const onClick = e => setShowVideo(!!video);

    useEffect(() => {
        if(video){
            getVideoEmbed(video).then((src) => {
                setEmbed(src);
                if(src && !image){
                    setShowVideo(true)
                }
            });
        }
    }, [video, image]);

    return (
        <AspectRatio
            ratio={ratio ?? 16 / 9}
            sx={{
                userSelect: 'none',
                cursor: showPlay ? 'pointer' : 'default',
                borderRadius: '32px',
                overflow: 'hidden',
                '&::before': {
                    content: '""',
                    bg: 'rgba(106, 113, 117, 0.4)',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 54,
                    opacity: 0,
                    transition: 'opacity 0.3s ease-in-out',
                    pointerEvents: 'none'
                },
                '& img': {
                    transition: 'transform 0.3s ease-in-out'
                },
                '&:hover': video && {
                    '&::before': {
                        opacity: showVideo ? 0 : 1
                    },
                    '& img': {
                        transform: 'scale(1.025)'
                    },
                    '& .playBtn': {
                        color: '#1DE9D5',
                        '& svg': {
                            width: '75px',
                            '& path': {
                                strokeWidth: '3px'
                            }
                        }
                    }
                },
                ...sx
            }}
            onClick={onClick}
        >
            {embed && showVideo &&
                <Embed src={`${embed}?autoplay=1`} allow="autoplay" sx={{
                    ...styles,
                    zIndex: 51,
                    opacity: image && !showVideo ? 0 : 1
                }} {...props} />
            }
            {image &&
                <Image src={image} sx={{
                    ...styles,
                    zIndex: 50,
                    opacity: showVideo ? 0 : 1,
                    transition: 'opacity 1s ease-in-out',
                }} {...props} />
            }
            {showPlay && (
                <Icon
                    icon="play"
                    color="white"
                    className="playBtn"
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 55,
                        opacity: showVideo ? 0 : 1,
                        transition: 'opacity 0.5s ease-in-out',
                        pointerEvents: 'none',
                        '& svg': {
                            width: '84px',
                            transition: 'width 0.3s ease-in-out',
                            '& path': {
                                transition: 'color 0.3s ease-in-out, stroke-width 0.3s ease-in-out'
                            }
                        }
                    }}
                />
            )}
        </AspectRatio>
    )
}
