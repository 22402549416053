import { useState, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import { useDropzone } from 'react-dropzone';
import { Box, Flex, Link, Text, Paragraph, Spinner } from '@theme-ui/components';
import { Button } from '~/Common';
import { useTranslation } from 'react-i18next';
import { Icon } from 'assets/Icon';
import { Label } from '~/Forms/Label';
import { toast } from 'utils/toast';

export const UploadField = ({
    name,
    apiName,
    label,
    showLabel,
    required,
    sx,
    onChange,
    validationError,
    reloadUpload,
    uploadProgress,
    allowMimes,
    maxFiles = 1,
    ...props
}) => {
    const isMultiple = Boolean(maxFiles > 1);
    // eslint-disable-next-line
    const [field, meta, { setValue, setTouched, setError }] = useField(name, {
        type: isMultiple ? 'array' : 'file',
    });
    const [uploading, setUploading] = useState(false);
    const { setErrors } = useFormikContext();
    const { t } = useTranslation();
    const hasValue = Boolean((isMultiple && field.value.length > 0) || (!isMultiple && field.value));

    useEffect(() => {
        if(reloadUpload){
            setUploading(false);
        }
    }, [reloadUpload]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        open
    } = useDropzone({
        maxFiles: maxFiles,
        multiple: isMultiple,
        accept: Array.isArray(allowMimes) ? allowMimes.join(',') : allowMimes ?? 'image/*, application/pdf',
        onDropRejected: async (fileRejections) => {
            await setTouched(true);

            toast.error('Incorrect file format. Must be PDF, PNG or JPG.', {
                toastId: 'file-upload-error'
            })

            setError('Incorrect file format. Must be PDF, PNG or JPG.');
        },
        onDropAccepted: async (acceptedFiles) => {
            if (onChange) {
                setUploading(true);
                onChange({
                    name: apiName ?? name,
                    value: isMultiple ? acceptedFiles : acceptedFiles[0],
                    type: isMultiple ? 'array' : 'file',
                    // set the value here with the returned S3 URL instead of the local file blob
                    callback: (val) => setValue(val),
                    uploadSettled: () => setUploading(false),
                    onError: (err) => {
                        setTouched(true);

                        if (err) {
                            // some errors returned from API are structured differently with files (e.g. file size too large)
                            if (typeof err === 'string') {
                                setErrors({ [name]: err });
                            } else if (typeof err === 'object') {
                                let errMessage = Object.values(err);
                                Array.isArray(errMessage) &&
                                    setErrors({ [name]: errMessage[0] });
                            }
                        }
                        setUploading(false);
                    },
                });
            }
            return;
        },
    });

    const renderFiles = () => {
        if (isMultiple){
            return (
                <>
                {field.value.map((value, key) => (
                    <Box key={`file${key}`}>
                        {renderFileName(value)}
                    </Box>
                ))}
                </>
            );
        }

        return renderFileName(field.value);
    };

    const renderFileName = (value) => {
        if (uploading) {
            return 'Uploading...';
        }

        if (value) {
            return (
                <>
                    <Icon
                        as="button"
                        icon="cross"
                        type="button"
                        size="20px"
                        sx={{
                            mr: 1,
                            border: 'none',
                            bg: 'transparent',
                            p: 0,
                            cursor: 'pointer',
                            transition: 'easeDefault',
                            color: 'grayDark',
                            '&:hover': {
                                color: 'secondary',
                                transform: 'scale(1.15)',
                            },
                        }}
                        onClick={(e) => {
                            onChange &&
                                onChange({
                                    name: apiName,
                                    value: '',
                                });
                            setValue('');
                            e.stopPropagation();
                        }}
                    />
                    <Text
                        as="p"
                        variant="label"
                        sx={{
                            position: 'relative',
                            zIndex: 5,
                            cursor: 'initial',
                            mb: 0,
                            color: 'grayDark',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Link
                            href={value}
                            target="_blank"
                            sx={{
                                transition: 'easeDefault',
                                textDecoration: 'none',
                                color: 'grayDark',
                                '&:hover': {
                                    color: 'primary',
                                },
                                lineHeight: '20px'
                            }}
                        >
                            View uploaded file
                        </Link>
                    </Text>
                </>
            );
        }
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Box
                sx={{
                    cursor: uploading ? 'progress' : 'pointer',
                    pointerEvents: 'auto',
                    position: 'relative',
                    zIndex: 10,
                }}
            >
                <Flex
                    sx={{
                        bg: 'white',
                        borderRadius: '16px',
                        px: [3, 4],
                        py: ['40px', '27px'],
                        mb: 3,
                        justifyContent: 'center',
                        alignItems: 'center',
                        transition: 'all 0.3s ease-in-out',
                        backgroundColor: isDragReject ? '#fff5f5' : (isDragActive || isDragAccept ? 'secondaryLight' : 'secondary'),
                        color: isDragReject ? '#ff5722' : (isDragActive || isDragAccept ? 'tealDark' : 'primary'),
                        border: `2px dashed ${isDragReject ? '#ff5722' : (isDragActive || isDragAccept ? '#19C5B4' : '#CACCD3')}`,
                        pointerEvents: uploading ? 'none' : 'auto',
                        '&:hover': {
                            color: 'tealDark',
                            borderColor: '#19C5B4',
                            backgroundColor: 'secondaryLight',
                            '& button': {
                                backgroundColor: '#1AD3C1'
                            }
                        },
                    }}
                    {...getRootProps()}
                    onClick={e => e.stopPropagation()}
                >
                    <Box tabIndex="-1">
                        <Label
                            label={label}
                            tabIndex="-1"
                            showLabel={showLabel}
                            fieldFocusedOrFilled={true}
                            field={field}
                            meta={meta}
                            validationError={validationError}
                        />

                        <input {...getInputProps()} />

                        {hasValue && (
                            <Box sx={{ mt: 1 }}>
                                <Flex sx={{ position: 'relative', zIndex: 5 }}>
                                    {!uploading && (
                                        <>
                                            {renderFiles()}
                                        </>
                                    )}
                                </Flex>
                            </Box>
                        )}
                    </Box>
                    {uploading ? (
                        isNaN(uploadProgress) ? (
                            <Spinner variant="styles.spinner" />
                        ) : (
                            <Box sx={{
                                width: '100%',
                                textAlign: 'center'
                            }}>
                                <Box sx={{
                                    position: 'relative',
                                    backgroundColor: 'medium',
                                    height: '5px',
                                    width: '100%',
                                    mb: 3
                                }}>
                                    <Box sx={{
                                        position: 'absolute',
                                        backgroundColor: 'dark',
                                        height: '100%',
                                        width: `${uploadProgress}%`,
                                        left: 0,
                                        top: 0
                                    }} />
                                </Box>
                                <Paragraph sx={{ mt: 2 }}>
                                    {`${Math.round(uploadProgress)}${t('% complete')}`}
                                </Paragraph>
                            </Box>
                        )
                    ) : (
                        <Box sx={{
                            textAlign: 'center'
                        }}>
                            <Paragraph sx={{
                                position: 'relative',
                                mb: 3,
                                transition: 'top 0.3s ease-in-out',
                                top: isDragActive || isDragAccept ? '28px' : 0
                            }}>
                                {t('Drop file here to upload...')}
                            </Paragraph>

                            <Button type="button" onClick={open} variant="tealLight" sx={{
                                opacity: isDragActive || isDragAccept ? 0 : 1,
                                transition: 'opacity 0.3s ease-in-out, background 0.3s ease-in-out'
                            }}>
                                <Icon icon="add-file" size="12" mr={2} />
                                {t(isMultiple ? 'Browse files' : 'Browse file')}
                            </Button>

                            <Icon
                                color="secondary"
                                icon={field.value ? 'tick-circle-white' : ''}
                                sx={{
                                    width: [13, '16px'],
                                    height: [13, '16px'],
                                    svg: {
                                        width: '100%',
                                        height: '100%',
                                    },
                                }}
                            />
                        </Box>
                    )}
                </Flex>
            </Box>
        </Box>
    );
};
