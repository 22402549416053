import React from 'react';
import {
    Heading,
    Themed,
    Text
} from 'theme-ui';
import { Container } from '~/Common/Container';
import { Layout } from '~/Layouts';
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const NotFound = () => {
    const { t } = useTranslation();
    useDocumentTitle(t('Not Found'));
    const isMobile = useResponsiveValue([true, true, false]);

    return (
        <Layout>
            <Container
                pt={5}
                sx={{
                    borderTop: isMobile ? '1px solid' : null,
                    borderColor: isMobile ? 'light' : null,
                }}
            >
                <Heading as="h1" variant="publicH1" mb={4}>
                    {t('notFound.title')}
                </Heading>

                <Text as="p" mb={3}>{t('notFound.description')}</Text>

                <Text as="span">
                    <Themed.a as={Link} to="/"> {t('Back to home')}</Themed.a>
                </Text>
            </Container>
        </Layout>
    );
};

export default NotFound;
