import { useState } from 'react';
import { Form, Formik } from 'formik';
import { Layout } from '~/Layouts';
import { Alert, Button, Container } from '~/Common';
import { Badge, Box, Checkbox, Grid, Label, Text, Close, Heading, Divider } from '@theme-ui/components';
import { useTranslation } from 'react-i18next';
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { Icon } from 'assets/Icon';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { theme } from 'app/theme';
import * as Yup from 'yup';
import { Card } from '~/Deals';

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    small: Yup.string().required(),
    favouriteFood: Yup.string().required(),
    favouriteColour: Yup.string().required(),
    verify: Yup.string().required().test('len', 'Must be exactly 6 characters', val => val !== undefined && val.length === 6),
});

const mapStateToProps = (state) => {
    return { appTheme: state.theme }
}

const Demo = ({ appTheme }) => {
    const { t } = useTranslation();
    const [modelOpen, setModalOpen] = useState(false);
    const favouriteColours = [
        { value: 'red', label: 'Red' },
        { value: 'yellow', label: 'Yellow' },
        { value: 'green', label: 'Green' },
        { value: 'blue', label: 'Blue' },
    ];
    const favouriteFoods = [
        { value: 'pizza', label: 'Pizza' },
        { value: 'burgers', label: 'Burgers' },
        { value: 'pasta', label: 'Pasta' },
        { value: 'buritos', label: 'Buritos' },
        { value: 'salad', label: 'Salad' },
    ];
    const openModel = (e) => {
        e.preventDefault();
        setModalOpen(true);
    };
    const closeModal = (e) => {
        e.preventDefault();
        setModalOpen(false);
    };

    return (
        <Layout>
            <Container>
                <Alert role="alert" variant='primary' mb={2}>
                    This is a primary alert
                </Alert>

                <Alert role="alert" variant='danger' mb={2}>
                    This is an alert for errors
                </Alert>

                <Alert role="alert" variant='info' mb={2}>
                    This is an alert for information
                </Alert>

                <Alert role="alert" variant='warning' mb={2}>
                    This is an alert for warnings
                </Alert>

                <Alert role="alert" variant='success' mb={2}>
                    This is an alert for success
                </Alert>

                <Divider my={4} />

                <Heading as="h1" variant="h1" mb={3}>Heading 1</Heading>
                <Heading as="h2" variant="h2" mb={3}>Heading 2</Heading>
                <Heading as="h3" variant="h3" mb={3}>Heading 3</Heading>
                <Heading as="h4" variant="h4" mb={3}>Heading 4</Heading>
                <Heading as="h5" variant="h5" mb={3}>Heading 5</Heading>
                <Heading as="h6" variant="h6" mb={3}>Heading 6</Heading>

                <Text as="p" mb={3}>
                    {t('lorem.description')}
                </Text>

                <Text as="p" variant="body_large" mb={3}>
                    Body Large {t('lorem.description')}
                </Text>

                <Text as="p" variant="tag" color="primary" mb={3}>
                    Tag {t('lorem.title')}
                </Text>

                <Text as="p" variant="caption" mb={3}>
                    Caption {t('lorem.title')}
                </Text>

                <Divider my={4} />

                <Button variant="primary" mb={2} mr={2}>
                    Primary
                </Button>

                <Button variant="secondary" mb={2} mr={2}>
                    Secondary
                </Button>

                <Button variant="bordered" mb={4} mr={2}>
                    Outlined Primary
                </Button>

                <Divider my={4} />

                <Heading as="h2" mb={2}>Sample Modal</Heading>
                <Text as="p" mb={2}>Click the button below to trigger a sample modal</Text>
                <Button variant="primary" onClick={openModel} sx={{ width: '100%' }} mb={4}>
                    Open Modal
                </Button>

                <Divider my={4} />

                <Modal
                    isOpen={modelOpen}
                    onRequestClose={closeModal}
                    style={theme.modals.default}
                    shouldCloseOnOverlayClick={false}
                    closeTimeoutMS={300}
                    contentLabel={t('onboarding.landing.invite.title')}
                    aria={{
                        labelledby: "onboardingTitle"
                    }}
                >
                    <>
                        <Box sx={{
                            position: 'absolute',
                            bottom: '103%',
                            left: 0,
                            right: 0,
                            width: '100%',
                            textAlign: 'center'
                        }}>
                            <Icon icon="logo" className="light" sx={{
                                position: 'relative',
                                maxWidth: '170px'
                            }} />
                        </Box>

                        <Box sx={{ overflow: 'auto' }}>
                            {appTheme.app_mode &&
                                <Badge variant="light" mb={3}>{appTheme.app_mode}</Badge>
                            }
                            <Heading as="h2" mb={3} id="onboardingTitle">{t('onboarding.landing.invite.title')}</Heading>
                            <Heading as="h3" variant="h4" mb={3}>{t('onboarding.landing.invite.subtitle')}</Heading>
                            <Text as="p" mb={4}>{t('onboarding.landing.invite.description')}</Text>

                            <Button variant="dark" sx={{
                                width: '100%',
                                maxWidth: '280px'
                            }} type="button" onClick={closeModal}>{t('onboarding.landing.invite.accept')}</Button>
                        </Box>
                    </>
                </Modal>
                <br />

                <Box mb={4}>
                    <Card />
                </Box>

                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        large: '',
                        small: '',
                        favouriteColour: '',
                        favouriteFood: '',
                        remember: false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(function(){
                            alert(JSON.stringify(values, null, 2));
                            actions.setStatus('api_error');
                            actions.setSubmitting(false);
                        }, 500);
                    }}
                >
                    {({ status, setStatus }) => (
                        <>
                            { status === 'api_error' ?
                            <Alert role="alert" variant='danger' mb={2}>
                                {t('login.error')}
                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                            </Alert> : ''}
                            <Form>
                                <Field
                                    name="email"
                                    id="email"
                                    label="Email"
                                />
                                <Field
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                />

                                <Field
                                    name="small"
                                    id="small"
                                    label="Small input"
                                    size="small"
                                    placeholder="Showing a small input"
                                />

                                <Field
                                    name="large"
                                    id="large"
                                    label="Large input"
                                    size="large"
                                    placeholder="Showing a large input"
                                />

                                <Field
                                    rows={5}
                                    name="textarea"
                                    id="textarea"
                                    label="Textarea"
                                    placeholder="Showing a textarea"
                                />

                                <Field
                                    field="select"
                                    name="favouriteColour"
                                    id="favouriteColour"
                                    label="Favourite Colour"
                                    options={favouriteColours}
                                />

                                <Field
                                    field="searchable-select"
                                    name="favouriteFood"
                                    id="favouriteFood"
                                    label="Favourite Food"
                                    options={favouriteFoods}
                                />

                                <Field
                                    field="verify"
                                    name="verify"
                                    id="verify"
                                    label="Verify code"
                                    type='number'
                                    fields={6}
                                />

                                <Field
                                    field="coin"
                                    name="coin"
                                    id="coin"
                                    label="Coins"
                                />

                                <Field
                                    field="currency"
                                    name="currency"
                                    id="currency"
                                    label="Currency"
                                />

                                <Field
                                    field="checkbox"
                                    name="toggle"
                                    id="toggle"
                                    label="Toggle"
                                />

                                <Grid columns={[1, 2]} gap={2} mb={3}>
                                    <Box>
                                        <Label>
                                            <Checkbox name="remember" />
                                            Remember me
                                        </Label>
                                    </Box>
                                </Grid>

                                <Submit text={t('buttons.submit')} />
                            </Form>
                        </>
                    )}
                </Formik>
            </Container>
        </Layout>
    );
};

export default connect(mapStateToProps, null)(Demo);
