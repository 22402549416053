import { Box, Heading, Button } from '@theme-ui/components';
import { useTranslation } from 'react-i18next';
import { Container } from '~/Common';
import { NavLink } from 'react-router-dom';

export const CTABanner = ({ ...props }) => {
    const { t } = useTranslation();


    return (
        <Box sx={{
            background: 'url(/img/cta-banner.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            py: '100px',
            textAlign: 'center'
        }}>
            <Container>
                <Heading as="h3" variant="h1" sx={{
                    mb: '50px',
                    fontSize: '64px',
                    lineHeight: '104.5%',
                    letterSpacing: '-0.025em'
                }}>{t('footer.cta.heading')}</Heading>
                <Button
                    variant="primary"
                    as={NavLink}
                    to="/deals"
                >
                    {t('footer.cta.button')}
                </Button>
            </Container>
        </Box>
    );
};
