import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = ({children, ...props}) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }, [location]);

    return <>{children}</>;
}

export default ScrollToTop;
