import { useRef, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Button } from '~/Common';
import {
    Box,
    Close,
    Flex,
    Heading,
    Link,
    Text,
    Spinner
} from '@theme-ui/components'
import { useResponsiveValue } from '@theme-ui/match-media'
import { Form, Formik } from 'formik';
import { Field } from '~/Forms/Field';
import { Icon } from 'assets/Icon'
import Modal from 'react-modal'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { sendVerification, unsophisticatedSendVerification } from 'features/deal/dealSlice';
import { theme as appTheme } from 'app/theme'
import { toast } from 'utils/toast';
import { useMenuClose } from 'hooks/useMenuClose';
import { acceptanceLetterVerify, unsophisticatedAcceptanceLetterVerify } from 'features/deal/dealAPI';

const validationVerifySchema = Yup.object().shape({
    code: Yup.string().required().test('len', 'must be exactly 6 characters', val => val !== undefined && val.length === 6)
});

export const VerifyModal = ({
    isOpen,
    closeModal,
    onSuccess,
    userUuid,
    theme,
    hideCloseOnSuccess,
    successTemplate,
    forceSuccess,
    unsophisticated,
    ...props
}) => {
    const modalRef = useRef(null);
    const isMobile = useResponsiveValue([true, true, true, false]);
    const [step, setStep] = useState('loading');
    const { t } = useTranslation();

    const handleCloseModal = () => {
        if (hideCloseOnSuccess && step === 'success') {
            return;
        }

        closeModal && closeModal();
    }

    useEffect(() => {
        if (forceSuccess) {
            setStep('success');
        } else {
            setStep('verify');
        }
    }, [forceSuccess]);

    useMenuClose(modalRef, handleCloseModal);

    const resendVerification = e => {
        e.preventDefault();

        if(!unsophisticated){
            sendVerification()
                .then((response) => {
                    toast.success(t(response.message), {
                        toastId: 'twofa-sent',
                    });
                })
                .catch((response) => {
                    toast.error(t(response.message), {
                        toastId: 'twofa-not-sent',
                    });
                });
        }else{
            unsophisticatedSendVerification(userUuid)
                .then((response) => {
                    toast.success(t(response.message), {
                        toastId: 'twofa-sent',
                    });
                })
                .catch((response) => {
                    toast.error(t(response.message), {
                        toastId: 'twofa-not-sent',
                    });
                });
        }
    }

    const handleVerifySubmit = (values, actions) => {
        let formdata = new FormData();
        formdata.append('code', values.code);
        formdata.append('user', userUuid)

        if(!unsophisticated){
            acceptanceLetterVerify(formdata)
            .then((response) => {
                toast.success(t(response.message), {
                    toastId: 'identity-verified',
                });

                onSuccess && onSuccess();
                setStep('success');
            })
            .catch((response) => {
                actions.resetForm();
                toast.error(t(response.data?.errors?.code ?? 'verification.error'), {
                    toastId: 'identity-not-verified',
                });
            })
            .finally(() => actions.setSubmitting(false));
        }else{
            unsophisticatedAcceptanceLetterVerify(formdata)
            .then((response) => {
                toast.success(t(response.message), {
                    toastId: 'identity-verified',
                });

                onSuccess && onSuccess();
                setStep('success');
            })
            .catch((response) => {
                actions.resetForm();
                toast.error(t(response.data?.errors?.code ?? 'verification.error'), {
                    toastId: 'identity-not-verified',
                });
            })
            .finally(() => actions.setSubmitting(false));
        }        
    }

    const content = () => {
        switch(step) {
            case 'loading':
                return (
                    <Flex sx={{
                        flexDirection: 'column',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Spinner variant="styles.spinner" mb={2} />
                        <Heading variant="h2" sx={{ fontSize: '36px', lineHeight: '43px' }}>Please wait while<br />we verify your account</Heading>
                        <Text as="p" mt={'27px'}>It may take a few seconds</Text>
                    </Flex>
                )
            case 'verify':
                return (
                    <Formik
                        initialValues={{
                            code: ''
                        }}
                        validationSchema={validationVerifySchema}
                        onSubmit={handleVerifySubmit}
                    >
                        {({ validateField, isSubmitting, submitForm }) => (
                            <Form>
                                <Box mb={4}>
                                    <Heading as="h2" variant="h2" color="primary" mb={3}>{t('Identity verification')}</Heading>
                                    <Text id="verifyInfo" sx={{ fontSize: 3, lineHeight: '24px', color: '#6A7175' }}>{t('Please confirm your identity with the verification code sent to your registered mobile number:')}</Text>
                                </Box>

                                {isSubmitting ? (
                                    <Box my={2}>
                                        <Spinner variant="styles.spinner" />
                                    </Box>
                                ) : (
                                    <>
                                        <Field
                                            field="verify"
                                            label={t('Please supply your code')}
                                            showLabel={true}
                                            name="code"
                                            validateField={validateField}
                                            onChange={(field) => {
                                                if (field.value.length >= 6) {
                                                    submitForm();
                                                }
                                            }}
                                        />
                                        <Box sx={{ color: 'dark' }}>
                                            {t('Didn\'t receive your Verification Code? ')}
                                            <Link as="a" onClick={resendVerification} color="medium">
                                                {t('Resend')}
                                            </Link>
                                        </Box>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                )
            case 'success':
                return (
                    <Flex sx={{
                        flexDirection: 'column',
                        height: '100%',
                        flex: 1,
                        textAlign: 'center'
                    }}>
                        <Box mb={4}>
                            <Icon icon="done" mb={3} size="50" color="tealDark" />
                            <Heading as="h2" variant="h2" id="confirmBidTitle" sx={{ color: ['white', null, null, 'primary'], fontSize: '36px', letterSpacing: '-0.03em' }}>
                                {successTemplate?.heading ?? t('verification.success')}
                            </Heading>
                        </Box>

                        <Text as="p" variant="h5" mb={4} sx={{ color: ['medium', null, null, 'darker'] }}>{t(successTemplate?.content)}</Text>

                        <Box mt={'auto'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            {successTemplate?.button && (
                                <Button
                                    variant="primary"
                                    as={unsophisticated ? Link : RouterLink}
                                    to={successTemplate?.button?.to ?? "/portfolio"}
                                    href={unsophisticated ? successTemplate?.button?.to ?? 'https://www.liquidity.com.au' : null}
                                    sx={{ width: '100%', height: '60px', lineHeight: '44px' }}
                                    mb={3}
                                    onClick={successTemplate?.button?.onClick ?? handleCloseModal}
                                >
                                    {t(successTemplate?.button?.label ?? 'Done')}
                                </Button>
                            )}
                        </Box>
                    </Flex>
                )
            default:
                return <></>
        }
    }

    return (
        <Modal
            contentRef={node => (modalRef.current = node)}
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            style={{
                overlay: appTheme.modals.verify.overlay,
                content: {
                    ...appTheme.modals.verify.content,
                    borderRadius: isMobile ? '0' : '16px'
                }
            }}
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={300}
            contentLabel="Identity verification"
            aria={{
                labelledby: "verifyHeading"
            }}
        >
            <>
                <Flex sx={{
                    flexDirection: ['column', null, null, 'row'],
                    overflow: 'auto',
                    width: '100%',
                    height: ['100vh', null, null, 'auto']
                }}>
                    <Box
                        sx={{
                            bg: ['black', null, null, 'white'],
                            width: '100%',
                            position: 'relative',
                            px: 4,
                            pb: [3, null, null, 4],
                            pt: ['100px', null, null, 5],
                            order: [1, null, null, 2],
                            display: ['flex', null, null, 'block'],
                            alignItems: 'center',
                            flex: 1
                        }}
                    >
                        {closeModal && !(hideCloseOnSuccess && step === 'success') && (
                            <Close onClick={closeModal} sx={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                zIndex: 10,
                                color: 'tealDark',
                                '&:hover': {
                                    color: 'tealLight'
                                }
                            }} />
                        )}

                        <Flex sx={{
                            width: '100%',
                            height: ['auto', null, null, '100%'],
                            justifyContent: 'center',
                            position: 'relative'
                        }}>
                            {content()}
                        </Flex>
                    </Box>
                </Flex>
            </>
        </Modal>
    );
};
