import { useEffect, useCallback, useState } from 'react';
import { Text, NavLink as ThemedNavLink, Button } from '@theme-ui/components';
import { Icon } from 'assets/Icon';
import { NavLink } from 'react-router-dom';
import { Menu as MenuStyled, MenuUl } from './styled';
import { Tooltip } from '../Tooltip';
import { useResponsiveValue } from '@theme-ui/match-media'

export const MenuItem = ({ label, name, path, icon, items, onClick, disabled, tooltip, depthStep = 10, depth = 0, groupKey, ...props }) => {
    const [open, setOpen] = useState(false);
    const escFunction = useCallback((event) => {
        if(event.keyCode === 27) {
            setOpen(false);
        }
    }, []);

    const isMobile = useResponsiveValue([true, true, true, false]);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    return (
        <li className={open ? 'focused' : ''}>
            <ThemedNavLink
                as={NavLink}
                id={`${groupKey}_${name}`}
                to={path}
                onClick={onClick}
            >
                {icon && <Icon icon={icon} size="16" className="header__navIcon" color="tealDark" mr={2} />}
                <Text className="header__navText">
                    {disabled && !isMobile ? (
                        <Tooltip text={tooltip ?? 'Link unavailable at this time'} position="bottom" sx={{ flexShrink: 0 }} innerSx={{ mt: 2 }}>
                            <Icon icon="lock-thin" size="16" mr={1} sx={{ transform: 'translate3d(0, 2px, 0)', 'svg path': { transition: 'none' } }} /> {label}
                        </Tooltip>
                    ) : <>{label}</>}
                </Text>
                {Array.isArray(items) ? (
                    <Button variant="link" className="header__dropdownIcon" ml={2} onClick={() => setOpen(!open)}><Icon icon="chevron-down" size="24" /></Button>
                ) : null}
            </ThemedNavLink>
            {Array.isArray(items) ? (
                <MenuUl>
                    {items.map((subItem) => (
                    <MenuItem
                        key={subItem.name}
                        depth={depth + 1}
                        depthStep={depthStep}
                        {...subItem}
                        />
                    ))}
                </MenuUl>
            ) : null}
        </li>
    );
};

export const Menu = ({ className, items, depthStep, depth, onItemClick, children, groupKey = 'Menu', ...props }) => {
    return (
        <MenuStyled className={className} {...props}>
            {children}
            <MenuUl>
            {items.map(( menuItem, index ) => menuItem.hidden === true ? null : (
                <MenuItem
                    key={`${menuItem.name}${index}`}
                    groupKey={groupKey}
                    depthStep={depthStep}
                    depth={depth}
                    disabled={menuItem?.disabled ?? false}
                    tooltip={menuItem?.tooltip}
                    onClick={onItemClick || menuItem.onClick}
                    {...menuItem}
                />
            ))}
            </MenuUl>
        </MenuStyled>
    );
};
