import { useResponsiveValue } from "@theme-ui/match-media";
import { getCurrentArticleAsync } from "features/article/articleSlice";
import moment from "moment";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Box, Flex, Grid, Heading, Image, Spinner, Text } from "theme-ui";
import { DATE_FORMAT } from "app/constants";
import { Breadcrumbs, Container } from "~/Common";
import { ContentBlock, ContentBlockMenu } from "~/ContentBlocks";
import { DashboardLayout } from "~/Layouts";
import { NewsAnchor, RelatedArticles } from "~/News";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "hooks/useDocumentTitle";

const ArticleView = ({ article, match, getCurrentArticleAsync, status }) => {
    const articleSlug = match.params.slug;
    const isLoading = status === 'loading' || !article;
    const isMobile = useResponsiveValue([ true, true, true, false ]);
    const { t } = useTranslation();
    useDocumentTitle(t(`Article - ${article?.name || ''}`));


    useEffect(() => {
        const hasChanged = article && articleSlug !== article.slug;

        if (hasChanged || status === 'idle') {
            getCurrentArticleAsync(articleSlug)
        }
    }, [
        articleSlug,
        status,
        getCurrentArticleAsync,
        article,
    ]);

    return (
        <DashboardLayout p={0}>
            {isLoading ? (
                <Flex sx={{
                    flexDirection: 'column'
                }}>
                    <Spinner variant="styles.spinner" mx={'auto'} my={6} />
                </Flex>
            ) : (
                <>
                    <NewsAnchor article={article} />
                    <Container variant="layouts.default">
                        {!isMobile && (
                            <Breadcrumbs title={article?.name} items={[
                                {
                                    title: 'Deals',
                                    to: '/deals'
                                },
                                {
                                    title: article?.deal?.name,
                                    to: `/deals/${article?.deal?.slug}`
                                }
                            ]} />
                        )}
                        <Box sx={{ maxWidth: '896px' }}>
                            <Heading as="h1" variant="display" mb={2}>{article?.name}</Heading>
                            <Text as="p" my={3}>{article?.summary}</Text>
                            <Text as="p" sx={{ fontSize: '16px', color: '#6A7175' }}>
                                {moment(article.date).format(DATE_FORMAT.date_format_friendly)}
                                {article.reading_time_human && ` | ${article.reading_time_human} read time`}
                            </Text>
                        </Box>

                        {article?.image?.original && (
                            <Image
                                src={article?.image?.original}
                                my="40px"
                                sx={{
                                    borderRadius: '16px',
                                    width: '100%',
                                    maxHeight: '600px',
                                    objectFit: 'cover'
                                }}
                            />
                        )}

                        <Grid sx={{
                            position: 'relative',
                            gridGap: [4, 4, 4, 5],
                            gridTemplateColumns: ['1fr', null, null, '3fr 2fr', 'auto 316px'],
                            width: '100%'
                        }}>
                            <ContentBlockMenu title="Jump to" contentBlocks={article?.content_blocks} variant="jumpTo" sx={{ order: 1 }} />
                            <Box sx={{
                                order: [2, null, null, 0],
                            }}>
                                {Array.isArray(article?.content_blocks) && article.content_blocks.map((block, key) => (
                                    <ContentBlock
                                        type={block?.type}
                                        data={block?.data}
                                        container={{
                                            px: 0,
                                            ...block?.settings?.container,
                                        }}
                                        live_at={block?.live_at}
                                        bg={block?.settings?.bg}
                                        blockKey={key + 1}
                                        key={`block${key}`}
                                    />
                                ))}
                            </Box>
                        </Grid>
                        <RelatedArticles article={article} my="50px" />
                    </Container>
                </>
            )}
        </DashboardLayout>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        theme: state.theme,
        article: state.articles.current?.data,
        status: state.articles.current?.status
    }
}

export default connect(mapStateToProps, { getCurrentArticleAsync })(ArticleView);
