import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    fetchInvites,
    storeInvite,
    fetchInvitedBy,
    respondToInvitedBy,
} from './invitesAPI';

const initialState = {
    invites: null,
    invited_by: null,
    invited_by_status: 'idle',
    status: 'idle',
};

export const getInvitesAsync = createAsyncThunk(
    'invites/getInvites',
    async (payload) => {
        const response = payload === undefined ? await fetchInvites() : payload;
        return response;
    }
);

export const getInvitedByAsync = createAsyncThunk(
    'invites/getInvitedBy',
    async (payload) => {
        const response = payload === undefined ? await fetchInvitedBy() : payload;
        return response;
    }
);

const invites = createSlice({
    name: 'invites',
    initialState,
    reducers: {
        setInvites: (state, { payload }) => {
            state.invites = payload?.data;
        },
        setInvitedBy: (state, { payload }) => {
            state.invited_by = payload?.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getInvitesAsync.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(getInvitesAsync.rejected, (state) => {
                    state.status = 'attempted';
                })
                .addCase(getInvitesAsync.fulfilled, (state, {payload}) => {
                    state.status = 'attempted';
                    state.invites = payload?.data;
                })
                .addCase(getInvitedByAsync.pending, (state) => {
                    state.invited_by_status = 'loading';
                })
                .addCase(getInvitedByAsync.rejected, (state) => {
                    state.invited_by_status = 'attempted';
                })
                .addCase(getInvitedByAsync.fulfilled, (state, {payload}) => {
                    state.invited_by_status = 'attempted';
                    state.invited_by = payload?.data;
                });
    },
});

export const { setInvites, setInvitedBy } = invites.actions;
export {
    fetchInvites,
    storeInvite,
    respondToInvitedBy
};

export default invites.reducer;
