import styled from '@emotion/styled';
import { theme } from 'app/theme';

export const NotificationMenu = styled('div')`
    position: fixed;
    top: ${theme.styles.header.height};
    right: 50px;
    margin-top: 20px;
    background: #fff;
    width: 80%;
    max-width: 700px;
    border-radius: 16px;
    height: auto;
    padding: 20px 22px 0 22px;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.3s ease-in-out 0.1s,
                opacity 0s linear 0.3s,
                visibility 0s linear 0.3s;
    z-index: 200;
    visibility: hidden;
    opacity: 0;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    &.open{
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
    }

    & .notifications__date {
      color: ${theme.colors.tealDark};
      font-size: 13px;
      line-height: 140%;
    }

    @media(max-width: 991px) {
        right: 0;
        left: 0;
        width: 100%;
        max-width: unset;
        top: ${theme.styles.header.height};
        border-radius: 0;
        box-shadow: none;
        margin: 0;
        height: calc(100vh - ${theme.styles.header.height});
    }
`;

export const NotificationMenuItem = styled('div')`
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.justifyRight ? 'flex-end' : 'flex-start'};
    border-radius: 16px;

    &:last-child{
        border-bottom: 0;
    }

    &.has-link:hover {
      background: ${theme.colors.secondaryLight};
    }
`;

export const NotificationMenuScroll = styled('div')`
    overflow-y: auto;
    height: auto;
    padding-bottom: 20px;
    max-height: 100%;

    @media(min-width: 992px) {
        max-height: 460px;
    }
`;
