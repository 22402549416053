import { useEffect, useState, useRef } from 'react'
import {
    Badge,
    Box,
    Flex,
    Image,
    NavLink as ThemedNavLink,
    Text
} from '@theme-ui/components'
import {
    Circle,
    Menu,
    NotificationMenu,
    ProfileMenu,
    SearchBar,
    Logo,
    PageOverlay,
    Skeleton,
    FullServiceTradeOverlay
} from '~/Common'
import { Form, Formik } from 'formik'
import { Field } from '~/Forms'
import { Header as HeaderStyled } from './styled'
import { Icon } from 'assets/Icon'
import { useSelector, useDispatch } from 'react-redux'
import { setShowMenu, setShowProfileMenu, setShowSearch, setRunSearch, setShowFullService } from 'features/theme/themeSlice'
import { setShowNotificationMenu, getNotificationsAsync } from 'features/notifications/notificationsSlice'
import { getUserCoinsAsync, fetchUserOpentrader, getUserTransactionsAsync } from 'features/auth/authSlice'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useResponsiveValue } from '@theme-ui/match-media'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useInterval } from 'hooks/useInterval'
import { APP_FEATURES, POLLING } from 'app/constants'
import { getFallbackProfileImage, grabFirstError, isBroadcastingEnabled } from 'utils/helpers'
import { Navigation as AdviserNavigation } from '~/Advisers'
import { getInvitedByAsync } from 'features/invites/invitesSlice'

export const Header = ({ children, className, ...props }) => {
    const profileMenuRef = useRef(null);
    const notificationButton = useRef(null);
    const invitedByButton = useRef(null);
    const searchButton = useRef(null);
    const phoneButton = useRef(null);
    const user = useSelector((state) => state.user?.user);
    const adviser = useSelector((state) => state.user?.adviser);
    const isAdviser = useSelector((state) => state.user?.user?.is_adviser);
    const activeProfile = useSelector((state) => state.user?.activeProfile);
    const theme = useSelector((state) => state.theme);
    const notifications = useSelector((state) => state.notifications);
    const invites = useSelector((state) => state.invites);
    const isTransparent = useSelector(state => state.theme.transparentHeader);
    const [profileImage, setProfileImage] = useState(null);
    const [isSmall, setSmall] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMobile = useResponsiveValue([ true, true, true, false ]);
    const history = useHistory();

    const toggleMenu = (type, e) => {
        if ('object' === typeof e) {
          e.preventDefault();
        }

        const showing = {
            menu: type === 'menu' && !theme.showMenu,
            notifications: type === 'notifications' && !notifications.showNotificationMenu,
            profile: type === 'profile' && !theme.showProfileMenu,
            search: type === 'search' && !theme.showSearch,
            fullService: (type === 'full-service' || type === 'phone') && !theme.showFullService
        };
        dispatch(setShowMenu(showing.menu));
        dispatch(setShowNotificationMenu(showing.notifications));
        if(showing.notifications){
            dispatch(getNotificationsAsync());
            dispatch(getInvitedByAsync());
        }
        dispatch(setShowProfileMenu(showing.profile));
        dispatch(setShowSearch(showing.search));
        dispatch(setShowFullService(showing.fullService));
    };

    useEffect(() => {
        if(invites.invited_by_status === 'idle'){
            dispatch(getInvitedByAsync());
        }
    }, [dispatch, invites]);

    useEffect(() => {
        if (isBroadcastingEnabled()) {
            window.Echo.private(`coins.${user?.uuid}`).listenToAll(() => {
                dispatch(getUserCoinsAsync());
                dispatch(getUserTransactionsAsync());
            });
        }

        if (user?.profile_photo_url && profileImage === null) {
            setProfileImage(user?.profile_photo_url ?? getFallbackProfileImage(user));
        }
    }, [dispatch, user, profileImage]);

    // Still keeping polling as a backup
    useInterval(async () => {
        dispatch(getUserCoinsAsync());
    }, POLLING.COINS);

    const handleSearch = ({ s }) => {
        history.push(`/search?s=${s}`);
        dispatch(setRunSearch(true));
        dispatch(setShowMenu(false))
    }

    const handleOpentraderOnClick = (e) => {
        e.preventDefault();

        if(!user?.can_see_trade_link){
            if(!user?.finished_onboarding_details){
                toast.info(t('login.opentrader_not_ready_finish_onboarding'), {
                    toastId: 'opentrader-warning'
                })
                history.push('/onboarding');
            }
            if (activeProfile?.account_management_type === 'Full Service') {
                toast.info(t('login.opentrader_not_ready_full_service'), {
                    toastId: 'opentrader-warning'
                })
                return; 
            }
            toast.info(t('login.opentrader_not_ready'), {
                toastId: 'opentrader-warning'
            })
            return;
        }

        if (activeProfile?.account_management_type === 'Full Service') {
            toggleMenu('full-service', e);
            return;
        }

        fetchUserOpentrader().then((response) => {
            if (response?.redirect_uri && response?.token){
                setTimeout(() => {
                    window.open(`${response.redirect_uri}?token=${response.token}`, '_blank');
                });
            }else{
                toast.error(t('login.opentrader_error'), {
                    toastId: 'opentrader-error'
                })
            }
        }).catch(({ response }) => {
            toast.error((grabFirstError(response.data?.errors) || response.data?.message) ?? t('login.opentrader_error'), {
                toastId: 'opentrader-error'
            })
        });
    };

    useEffect(() => {
        const onScroll = (e) => {
            setSmall(window.pageYOffset >= 40);
        }

        const onResize = (e) => {
            dispatch(setShowMenu(false));
            dispatch(setShowProfileMenu(false));
            dispatch(setShowSearch(false));
        }

        document.addEventListener('scroll', onScroll);
        window.addEventListener('resize', onResize);

        return () => {
            document.removeEventListener('scroll', onScroll);
            window.removeEventListener('resize', onResize);
        }
    }, [dispatch]);

    let menuItems = [
        { name: 'dashboard', path: '/adviser/dashboard', label: 'My Dashboard', icon: 'portfolio-thick', hidden: !isAdviser },
        { name: 'portfolio', path: '/portfolio', label: 'Portfolio', icon: 'portfolio-thick', hidden: isAdviser },
        // Show the trade link for apps that have it enabled as well as only for user's that are fully verified
        { name: 'deals', path: '/deals', label: 'Invest', icon: 'live-deals' },
        { name: 'trade', path: '/trade', label: 'Trade', icon: 'trending-up', hidden: Boolean(adviser || isAdviser || !JSON.parse(process.env.REACT_APP_TRADE)), onClick: handleOpentraderOnClick, disabled: !user?.can_see_trade_link, tooltip: t('login.opentrader_not_ready') },
        { name: 'companies', path: '/companies', label: 'Insights', icon: 'office-building' },
        { name: 'watchlist', path: '/following', label: 'Following', icon: 'watchlist-thick', hidden: !APP_FEATURES.following },
        { name: 'news', path: '/news', label: 'News', icon: 'passport', hidden: !APP_FEATURES.news },
    ];

    const renderNotificationIcon = () => (
        <ThemedNavLink ref={notificationButton} className="header__utility" href="#notifications" title="View Notifications" onClick={e => toggleMenu('notifications', e)} variant="utility" sx={{ transition: 'none' }}>
            <Box sx={{ position: 'relative', width: '24px', display: 'inline-flex' }}>
                {notifications.unread_count > 0 && (
                    <Circle type="box" p={1} sx={{
                        position: 'absolute',
                        top: '-7px',
                        right: '-7px',
                        zIndex: 2,
                        backgroundColor: 'tealLight',
                        color: 'darker',
                        fontWeight: 500,
                        fontSize: 11
                    }}>{notifications.unread_count}</Circle>
                )}
                <Icon icon="bell" width="18px" height="auto" />
            </Box>
            <Text className="sr-only">Notifications</Text>
        </ThemedNavLink>
    );

    const renderInvitedByIcon = () => (
        <>
            {APP_FEATURES.adviser_invites && invites?.invited_by && invites?.invited_by.length > 0 ? (
                <ThemedNavLink ref={invitedByButton} className="header__utility" href="#notifications" title="You've been invited" onClick={e => toggleMenu('notifications', e)} variant="utility" sx={{ transition: 'none' }}>
                    <Box sx={{ position: 'relative', width: '24px', display: 'inline-flex' }}>
                        <Circle type="box" p={1} sx={{
                            position: 'absolute',
                            top: '-7px',
                            right: '-7px',
                            zIndex: 2,
                            backgroundColor: 'tealLight',
                            color: 'darker',
                            fontWeight: 500,
                            fontSize: 11
                        }}>{invites?.invited_by.length}</Circle>

                        <Icon icon="user-thin" width="18px" height="auto" />
                    </Box>
                    <Text className="sr-only">Invited By</Text>
                </ThemedNavLink>
            ): null}
        </>
    );

    const isMenuOpen = theme.showMenu || notifications.showNotificationMenu || theme.showProfileMenu;

    const renderLogo = () => (
        <ThemedNavLink to="/portfolio" as={NavLink} className="header__logoWrap">
            <Logo width="auto" height="28" color={isTransparent && !isMenuOpen ? 'white' : 'black'} className="header__logo" onClick={() => dispatch(setShowMenu(false))} />
            <Text className="sr-only">Liquidity</Text>
        </ThemedNavLink>
    );

    return (
        <>
            <HeaderStyled
                className={`${className ?? ''} ${isTransparent ? 'header__transparent' : ''} ${isSmall ? 'header__small' : ''} ${isMenuOpen ? 'menu-open' : ''}`}
                {...props}
            >
                <AdviserNavigation />

                <Flex
                    mx="auto"
                    sx={{
                       width: '100%'
                    }}
                >
                    <Box mr={'auto'}>
                        <Flex as="nav" className="header__left" id="main-nav">
                            <ThemedNavLink href="#menu" variant="utility" className="header__utility header__menuButton" onClick={() => toggleMenu('menu')} sx={{ maxWidth: '80px', color: t => t.colors.darker }}>
                                <Box sx={{ fontSize: '0px' }}>
                                    <Icon icon="menu" width="20" />
                                </Box>
                                <Text className="sr-only">Show Menu</Text>
                            </ThemedNavLink>
                            {isMobile ? (
                                <>
                                    {renderInvitedByIcon()}
                                    {renderNotificationIcon()}
                                </>
                            ) : (
                                <>
                                    {renderLogo()}
                                </>
                            )}
                        </Flex>
                    </Box>
                    {isMobile && renderLogo()}
                    <Box ml={'auto'}>
                        <Flex as="nav" className="header__right" id="user-actions" role="navigation" aria-label="Main Navigation">
                            <Menu
                                groupKey="MainMenu"
                                items={menuItems}
                                className={`header__mainMenu ${theme.showMenu ? 'header__mainMenu--open' : 'header__mainMenu--closed'}`}
                                onItemClick={() => {
                                    dispatch(setShowMenu(false));
                                }}
                            >
                                <Box className="header__mobileSearch">
                                    <Formik
                                        enableReinitialize
                                        initialValues={{
                                            s: ''
                                        }}
                                        onSubmit={handleSearch}
                                    >
                                        {() => (
                                            <Form>
                                                <Field
                                                    name="s"
                                                    label={t("Search")}
                                                    groupMb={0}
                                                />
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>
                            </Menu>
                            {children}

                            {!isAdviser ? <ThemedNavLink className="header__coins" id="MainMenu_coins" to="/coins" variant="utility" as={NavLink}>
                                <Badge variant="coins" className="header__coinsBadge">
                                    <Icon icon="coin" size="25" />
                                    <Text sx={{
                                      minWidth: '40px',
                                      textAlign: 'center',
                                      fontSize: 2,
                                      letterSpacing: '-0.01em',
                                      lineHeight: '25px',
                                      px: 2
                                    }}>{user?.coins ?? 0 }</Text>
                                    <Text className="sr-only">{t('liquidityDollars.title')}</Text>
                                </Badge>
                            </ThemedNavLink> : null}
                            <ThemedNavLink ref={searchButton} className="header__search header__utility" id="MainMenu_search" href="#search" onClick={e => toggleMenu('search', e)} variant="utility" sx={{ transition: 'none' }}>
                                <Icon icon="search" size="18" />
                                <Text className="sr-only">Search</Text>
                            </ThemedNavLink>
                            {!isMobile ? (
                                <>
                                    {renderInvitedByIcon()}
                                    {renderNotificationIcon()}
                                    <ThemedNavLink ref={phoneButton} className="header__utility" id="MainMenu_phone" href="#phone" onClick={e => toggleMenu('phone', e)} variant="utility" sx={{ transition: 'none' }}>
                                        <Icon icon="phone" width="22px" height="auto" strokeWidth={1} />
                                        <Text className="sr-only">Search</Text>
                                    </ThemedNavLink>
                                </>
                            ) : null}
                            <ThemedNavLink ref={profileMenuRef} className="header__utility" id="MainMenu_usermenu" href="#usermenu" onClick={e => toggleMenu('profile', e)} variant="utility" sx={{ pr: ['19px', '19px', '19px', '40px'] }}>
                                <Box className="profileButton" sx={{
                                  display: 'flex',
                                  borderRadius: '213px',
                                  background: t => t.colors.darker,
                                  color: t => t.colors.white,
                                  alignItems: 'center',
                                  transition: 'background 0.3s ease-in-out, padding 0.5s ease-in-out',
                                  lineHeight: '31px',
                                  '&:hover': {
                                      background: t => t.colors.darkerHover
                                  }
                                }}>
                                  {profileImage ? (
                                        <Circle size="39px" bg="#f4f4f4" type="box" sx={{ flexShrink: 0, overflow: 'hidden', mr: 2 }}>
                                        <Image
                                            alt=""
                                            src={profileImage}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            onError={() => setProfileImage(getFallbackProfileImage(user))}
                                        />
                                    </Circle>
                                  ) : (
                                    <Icon icon="user-circle" className="header__userimage" mr={2} />
                                  )}
                                  <Text className="header__navText header__profileName" pr={2} sx={{
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden'
                                  }}>
                                    {user ? (
                                        <>
                                            <Box sx={{
                                                maxWidth: '150px',
                                                display: 'inline-block',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                verticalAlign: 'top',
                                                fontWeight: 700
                                            }}>{activeProfile?.name ?? `${user?.first_name} ${user?.last_name}`}</Box> / {activeProfile?.type_name ?? 'Individual'}
                                        </>
                                    ) : <Skeleton height="24px" width="150px" />}
                                  </Text>
                                  <Icon icon="chevron-down" size="12" sx={{ ml: 1, mr: 2 }} />
                                </Box>
                            </ThemedNavLink>
                        </Flex>
                    </Box>
                </Flex>
            </HeaderStyled>
            <NotificationMenu isOpen={notifications.showNotificationMenu} buttonRef={notificationButton} inviteRef={invitedByButton} />
            <ProfileMenu isOpen={theme.showProfileMenu} buttonRef={profileMenuRef} />
            <SearchBar isOpen={theme.showSearch} buttonRef={searchButton} />
            <FullServiceTradeOverlay isOpen={theme.showFullService} />
            <PageOverlay show={theme.showSearch || theme.showProfileMenu || notifications.showNotificationMenu || theme.showFullService} />
        </>
    );
};
