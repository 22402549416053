import moment from 'moment';
import { Link } from 'react-router-dom';
import { Box, Flex, Grid } from 'theme-ui';
import { DATE_FORMAT } from 'app/constants';
import { Markdown } from '../Markdown';
import { ReadMore } from '../ReadMore';

export const AnnouncementRow = ({ announcement, sx, ...props }) => (
    <Grid
        sx={{
            gridTemplateColumns: 'auto 24px',
            gridGap: '12px',
            py: 3,
            borderBottom: '1px solid #D5D9DA',
            color: '#00000A',
            textDecoration: 'none',
            '&::after': { display: 'none' },
            ...sx
        }}
        {...props}
    >
        <Flex sx={{ flexDirection: 'column', gridGap: '5px' }}>
            <Flex sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box
                    as={Link}
                    to={{pathname: announcement.announcement}}
                    target={'_blank'}
                    sx={{
                        textDecoration: 'none',
                    }}
                >
                    <Box className="link-title">ASX: {announcement.label}</Box>
                </Box>

                <Box sx={{ fontSize: '14px', color: '#6A7175', my: 'auto' }}>
                    {moment.utc(announcement.releaseDate).format(DATE_FORMAT.datetime_format_friendly)}
                </Box>
            </Flex>
            {announcement?.summary ? (
                <ReadMore sx={{mt: '12px'}} label={'Show Summary'}>
                    <Box sx={{ fontSize: '14px', color: '#6A7175' }}>
                        {announcement?.summary?.map((sum, key) => (
                            <Markdown key={`summary${key}`}>
                                {`- ${sum}`}
                            </Markdown>
                        ))} 
                    </Box>
                </ReadMore>
            ) : null}
        </Flex>
    </Grid>
)
