export const TRANSLATIONS_EN = {
    login: {
        connect_error: 'Unable to connect to Liquidity.',
        error: 'Your email and password are incorrect.',
        two_factor_error: 'The supplied code was invalid',
        title: 'Login',
        two_factor_title: 'Login Challenge',
        opentrader_error: 'Sorry, we are unable to connect to the trade platform at this time',
        opentrader_not_ready: 'Available once bank account setup is finalised',
        opentrader_not_ready_finish_onboarding: 'Available once your documents are supplied and bank account setup is finalised',
        opentrader_not_ready_full_service: 'Available once trading account is finalised'
    },
    loginOneTime: {
        title: 'Link check',
        subtitle: 'Please provide your email that received this link to complete this step',
        success: 'Thank you for confirming!',
        error: 'Unable to match the details',
    },
    header: {
        switchProfile: 'Switch entity',
        addProfile: 'Add entity',
        lqd: 'LQD$',
    },
    verification: {
        success: 'Your account has been verified.',
        error: 'Something went wrong while verifying your account.',
    },
    recaptcha: {
        error: 'We could not verify the captcha request.',
    },
    verifySteps: {
        canBid: {
            label: 'Express Interest in Deals',
            heading: 'Place a Bid',
            description: 'You are now able to bid on a company\'s share placement. Please note, you will need to finalise your account before the bid is accepted.',
        },
        finaliseEntity: {
            label: 'Secure Priority Allocations',
            heading: 'Get Priority Allocations by Finalising Your Bidding Account',
            description: 'By finalising your Liquidity bidding account you will be able to take advantage of your status as a sophisticated investor by participating in discounted capital raises. Clients who <finalise> their account receive priority allocation in deals.',
        },
        openTrading: {
            label: 'Trading Account Open',
            heading_submitted: 'Opening Your Trade Account',
            heading: 'Want to Buy / Sell?',
            description: "Liquidity clients can manage their own trades with lower fees, or enjoy the benefits of our experienced full service account manager.\n\n<call_to_action>",
            description_submitted: 'We are in the process of verifying your account.',
            description_account_management_type_selected: "Complete your details"
        },
        transferHoldings: {
            label: 'Transfer Existing Holdings',
            heading: 'Transfer your existing holdings',
            description: "Complete the <transferForm> to transfer your existing holdings into Liquidity to enjoy having everything in one place. Once completed, please send the form to <email> and we'll do the rest.",
        },
    },
    passwordReset: {
        title: 'Reset your password',
        subtitle: 'Provide your email and confirm your new password to complete this step',
        success: 'Success! Your password has been reset. Please try and sign in.',
        error: 'Unable to reset your password',
    },
    forgotPassword: {
        title: 'Set a new password?',
        subtitle: 'Enter your email address and you will be sent a link to set a new password.',
        success: 'Success! Password reset link has been sent.',
        error: 'The provided email was invalid',
    },
    cashBalance: {
        title: 'Entity Cash Balance',
    },
    liquidityDollars: {
        title: 'Liquidity Dollars',
        add: 'Add Liquidity dollars',
        description: 'Earn Liquidity Dollars every time you bid on a deal that can be redeemed for cash or used as part of future bids.',
        tooltip: 'Liquidity dollars (LQD) can be used to offset your trades or accumulated for experiences in the Liquidity Lounge',
    },
    liquidityLounge: {
        title: 'Liquidity Lounge',
        description: 'Liquidity dollars (LQD) can be used to offset your trades or accumulated for future  opportunities in the Liquidity Lounge',
        failedPurchase: 'Something went wrong while purchasing the item.',
        tradeModal: {
            description: "We know our investors like to trade and that's why we offer trade credit as part of being a Liquidity member. You will receive free standard trades on us with each trade being rebated within 24 hours once complete.",
        },
        cashModal: {
            description: 'How many Liquidity Points (LQD) do you want to redeem?',
        },
        trades: {
            noResults: 'There are currently no free trades available.',
        },
        experiences: {
            noResults: 'There are currently no experiences available.',
        },
    },
    companiesDashboard: {
        title: 'Company Insights',
        description: '',
        notFoundHeading: 'No company insights',
        notFound: 'Sorry, there are no company insights at this time',
        filteringNotFound: 'Sorry, there are no company insights matching your filters. Try reset the search or use different filters.',
    },
    dashboard: {
        welcome: 'Welcome {{name}},',
    },
    portfolio: {
        title: 'My {{type}} Portfolio',
        disclaimerNoOpenmarkets: 'All information displayed is for your {{type}} profile. To view a different entity, switch via the top menu.',
        disclaimer: 'All values and shareholdings are part of your {{type}} portfolio with the exception of "Your Liquidity Dollars", which are accumulated to your user account. To view a different entity, switch via the top menu.',
    },
    notFound: {
        title: 'Page not found',
        description: 'Sorry, we were unable to find a page for that address.',
    },
    notAuthorised: {
        alert: 'Sorry, you are unauthorised for that action. Your account has not been approved yet',
    },
    lorem: {
        title: 'Lorem ipsum dolor sit amet consectetur adipiscing elit',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi justo sem, tempor at ultrices ut, semper in nibh. Aenean facilisis augue ac enim sagittis, vel porttitor libero ultrices. Praesent ac augue hendrerit libero aliquet pretium quis nec nisl. Mauris aliquet volutpat odio, ac gravida nisi gravida facilisis. Donec lacinia nibh accumsan nibh volutpat porta. Aliquam mi mi, tristique nec porttitor in, luctus vel libero. Vestibulum auctor, est id venenatis semper, arcu turpis ultrices turpis, cursus sagittis odio enim in elit. Aliquam nec sapien aliquam, fringilla diam eget, placerat justo.',
    },
    buttons: {
        upload: 'Upload',
        save: 'Save changes',
        submit: 'Submit',
        submitErrorTooltip: 'Fix the following errors to continue:',
        login: 'Login',
        discard: 'Discard changes',
        invite: 'Invite an investor',
        inviteAdviser: 'Invite an adviser',
        shareDeal: 'Share deal'
    },
    validations: {
        required: 'Please provide a value for this field',
    },
    footer: {
        copyright: 'Copyright \u00A9 {{year}} Liquidity. All rights reserved. ACN: 650 875 595. CAR: 001293556  of AFSL: 338731',
        description: 'Built for Sophisticated Investors.\nOne platform to access exclusive deals and trade.',

        cta: {
            heading: "What's trending\nin curated investing?",
            button: 'Discover deals',
        },
    },
    profileMenu: {
        balances: {
            title: 'Balances',
        },
    },
    news: {
        related: {
            title: 'More from {{deal}}',
        },
        anchor: {
            youAreReading: 'You are reading:',
        },
    },
    onboarding: {
        landing: {
            invite: {
                title: "You've been invited!",
                subtitle: "To be a part of Australia's most exclusive investment platform.",
                description: "Sophisticated investing as it should be.",
                accept: 'Accept Invite',
            },
            mobile: {
                title: 'Sign up',
                subtitle: 'To set up your account we need to verify your phone number. We will never display this number publicly.',
                submit: 'Send code',
                submitVerify: 'Verify',
                error: 'Unable to send code',
            },
            mobileVerify: {
                title: 'Account verification',
                subtitle: 'A two-factor confirmation code has been sent via text to {{mobile}}.',
                label: 'Please enter the 6-digit security code below to here:',
                submit: 'Verify',
                error: 'Unable to verify',
            },
            personal: {
                title: 'Tell us about yourself',
                subtitle: 'Enter a few details to set up your Liquidity account as an individual. You can add other entities to your account once your in.',
                legal_name: 'Please ensure you enter your full legal name.',
                submit: 'Next',
                success: 'Your details have been saved',
                error: 'Unable to add details to your account',
                taxFileNumberReason: 'Provision of a TFN is not compulsory, however, if you do not quote your TFN or claim an exemption, tax may be deducted from the interest paid to your Cash Management Account at the highest marginal tax rate plus Medicare Levy. Declining to quote a TFN is not an offence.',
                occupationReason: 'Australian law requires all financial institutions to collect information about your job category and type. This will not be used for marketing purposes.',
                businessOwnerReason: 'We need a few more details about the business you own. This is for occupation purposes only, not to establish a company account.',
            },
            address: {
                title: 'Tell us about yourself',
                subtitle: 'Your residential address',
                submit: 'Next',
                success: 'Your address has been saved',
                error: 'Unable to add address details to your account',
            },
            entity: {
                title: 'Tell us about your entity',
                subtitle: 'We need the registration details of your entity so that you can begin bidding on deals within the platform',
                submit: 'Next',
                success: 'Your entity details have been saved',
                error: 'Unable to add entity details to your account',
            },
            identity: {
                slides: {
                    'identity-card': {
                        title: 'Verifying your identity',
                        description: 'Before we give your access. We need to take two simple steps to protect you and us against financial crime. It should only take a minute or two.',
                        next: 'Next',
                    },
                    'passport': {
                        title: 'Provide your ID',
                        description: 'We may require two forms of ID to verfiy your account information. First your driver’s licence and if we require further information for identity verification, your passport.',
                        next: 'Next',
                    },
                    'certificate': {
                        title: 's708 certificate',
                        description:
                            'In order to participate, you will be required to prove you are a sophisticated investor by providing a signed s708 certificate form. If accepted, your account will be approved. Please include all Companies/Trusts you control on the certificate.',
                        next: "Let's go",
                    },
                },
            },
            passport: {
                title: 'Your passport',
                subtitle: 'Please enter your passport details below. We only support Australian passports at this time.',
                legal_name: 'Please ensure you enter your full legal name.',
                submit: 'Verify',
                success: 'Your passport has been submitted',
                error: 'Unable to verify your passport with your provided details',
            },
            drivers_licence: {
                title: "Your Driver's Licence",
                subtitle: "Please enter your Australian driver's licence details below. We only support Australian driver's licences at this time.",
                legal_name: 'Please ensure you enter your full legal name.',
                submit: 'Verify',
                success: "Your driver's licence has been submitted",
                error: "Unable to verify your driver's licence with your provided details",
            },
            certificate: {
                title: '708 Investor Certificate',
                description: '708 certificates are issued by a qualified accountant as verification you are a sophisticated investor.',
                submit: 'Upload',
                success: 'Your 708 Investor Certificate was uploaded successfully.',
                error: 'Unable to upload your certificate',
            },
            finished: {
                pretitle: 'Your documents were uploaded with success.',
                title: 'You will be notified once your documents are <verified>.',
                description: 'This might take up to 48 hours.',
                link: 'Go to dashboard',
            },
        },
    },
    acceptance: {
        heading: 'Acceptance of Offer',
        content: 'We are pleased to offer you an allocation in the abovementioned Company on the terms outlined below and in the attached offer letter.',
        urgentAttention: 'Your urgent attention is required as your Electronic Acceptance is due by no later than {{acceptanceDate}}.',
        readTermSheet: 'By electronically accepting this offer below, you warrant that you have received the term sheet / Company Presentation in relation to the offer and read it in its entirety.',
        clickAccept: 'Please click on the electronic acceptance below. Once accepted an email will be sent to you with bank details.',
        drawSignature: 'To accept the offer please draw your signature below or upload a digital signature',
        signatureLabel: 'Draw your signature below',

        agreeLabel: 'I’ve read and agree to the {{companyName}} offer terms.',
        downloadLetter: 'Download offer letter.',

        contact: 'If you have any queries, please contact', //{{email}}.
        thankyou: 'Thank you for your support.',

        success: {
            heading: 'Congratulations, your offer has been completed',
            content: 'You will be notified shortly regarding payment details.',
            button: 'Go to Portfolio',
            unsophisticatedButton: 'Go to Liquidity website',
        },

        error: {
            heading: 'Sorry, we were unable to process your acceptance document',
            content: 'Please contact Liquidity at members@liquidity.com.au',
        }

    },
    companies: {
        notFound: {
            title: 'Company not found',
            description: 'Sorry, we were unable to find a company for that address.',
        },
    },
    deals: {
        stats: {
            liveBidding: '{{count}} live {{singularPlural}} available for bidding',
            bidsLast7Days: '{{count}} {{singularPlural}} recently received',
            dealsClosedLast3Days: '{{count}} {{singularPlural}} recently closed for bidding',
        },
        notFinishedOnboarding: {
            tooltip: 'We need your identity documents before you can gain full access to deals',
            label: 'Finish account setup',
        },
        bid: {
            error: 'Unable to place bid',
            success: 'Bid successfully placed',
            agreeTermsError: 'You must accept the terms and conditions to place your bid',
        },
        notFound: {
            title: 'Deal not found',
            description: 'Sorry, we were unable to find a deal for that address.',
        },
        live: {
            notFoundHeading: 'No live deals',
            notFound: 'Sorry, there are no live deals at this time',
            filteringNotFound: 'Sorry, there are no live deals matching your filters. Try reset the search or use different filters.',
        },
        organisation: {
            notFoundHeading: 'No active managed deals',
            notFound: 'There are no active managed deals at this time',
            filteringNotFound: 'Sorry, there are no active deals matching your filters. Try reset the search or use different filters.',
        },
        modal: {
            bidBefore: 'Please note additional bids on a deal will be processed separately.',
            acceptTerms: 'I have read and confirm acceptance of the',
            termsLink: 'terms and conditions',
            acceptTermsAfter: 'of this offer.',
            followCompany: 'Follow {{company}} to keep up to date with their news and announcements'
        },
    },
    asx: {
        labels: {
            open: 'Open',
            marketCap: 'Market Cap',
            previousClose: 'Previous Close',
            bid: 'Bid',
            peRatio: 'P/E Ratio',
            yearRange: '52 Weeks Range',
            vwap: 'Average (VWAP)',
            volume: 'Volume',
            dividendYield: 'Dividend Yield',
            ask: 'Ask',
        },
    },
    intro: {
        modal: {
            step1: {
                title: 'Hello, {{name}}!',
                subtitle: 'You’re about to begin your curated investing experience.',
                description: 'Access exclusive deals not found anywhere else.',
                continue: 'Start experience',
            },
            step2: {
                title: 'Choose investment interests',
                description: 'Choosing a deal segment allows us to recommend the right deals to you before anyone else. You can always change your preferences within your account area',
                continue: 'Next',
            },
            step3: {
                title: 'Select deal stage preferences',
                description: 'Choosing a deal stage preference allows us to recommend the right deals to you before anyone else. You can always change your preferences within your account area.',
                continue: 'Done',
            },
            step4: {
                title: 'Want to setup another Trading entity?',
                subtitle: 'You can also provide a Company, Trust or Super Fund.',
                skipForNow: 'Skip for now',
                toEntities: 'Yes, lets do it',
                required: {
                    title: 'Setup your Trading entity?',
                    subtitle: 'You will need to provide a Company, Trust or Super Fund.',
                },
                finaliseEntity: {
                    title: 'Finalise your Trading entity',
                    subtitle: 'At this stage, you are ready to bid on deals. However, to access all of Liquidity\'s features and receive priority for allocations, you will need to finalise your entity.',
                    toEntity: 'Finalise entity',
                }
            },
            step5: {
                title: 'You’re done!',
                subtitle: 'One moment while we curate your investments.',
                toEntities: {
                    title: 'Setup your Trading entities',
                    subtitle: 'One moment whilst we take you to our entity setup.',
                },
                toEoiDeal: {
                    title: 'Confirm your bid',
                    subtitle: 'One moment while we take you to confirm your bid'
                }
            },
        },
    },
    notifications: {
        preferences: {
            success: 'Notification preferences saved successfully',
            error: 'Unable to save notification preferences',
        },
        list: {
            none: 'You currently have no notifications',
        },
        settings: {
            subheading: 'Update how you would like to hear from us',
        },
        announcements: {
            subheading: 'Update your ASX Announcement notification settings to receive a notification when a price sensitive announcement is released by the company.',
        },
        invites: {
            notification: '{{invited_by}} from {{organisation}} has requested to become adviser on your account. Please respond to the request.',
            lead: '{{invited_by}} from {{organisation}} has requested to become adviser on your account. Please respond to the approve or deny the request.\n\nBy adding {{invited_by}} as an adviser, you\'ll have exclusive access to listed and unlisted deals from {{organisation}}.',
            success: '{{answer}} has been processed successfully',
            error: 'Unable to process the request',
        }
    },
    contact: {
        heading: 'Contact us',
        messages: {
            success: 'Your message has been sent successfully.',
            error: 'Something went wrong, please try again.',
        },
        submit: 'Submit enquiry',
    },
    settings: {
        account: {
            success: 'Account details saved successfully',
            error: 'Unable to save your details',
            businessOwnerReason: 'We need a few more details about the business you own. This is for occupation purposes only, not to establish a company account.',
            chessReason: 'Please provide the address to supply for CHESS registration',
            certificateExpired: 'Your certificate expired on {{date}}. \n\nQualified accountant certificates expire two years from date of issue. Please upload a new certificate below.',
            certificateExpiryWarning: 'Qualified accountant certificates expire two years from date of issue. Your certificate will expire on {{date}}.',
            locked: 'Your account details are currently locked from further changes at this time. You may still update your avatar, change password, two factor authentication and update your certificate during this time. If you wish to update any further details, please contact us.',
        },
        certificate: {
            success: 'Certificate uploaded successfully, we will review this soon.',
            error: 'Unable to upload your certificate',
        },
        supporting_documents: {
            success: 'Supporting documents uploaded successfully, we will review this soon.',
            error: 'Unable to upload your supporting documents',
            delete_success: 'Supporting document removed successfully',
            delete_error: 'Unable to find or remove supporting document',
        },
        deed: {
            success: 'Document uploaded successfully, we will review this soon.',
            error: 'Unable to upload your document',
        },
        profiles: {
            heading: 'My Entities',
            subheading: 'Select the entity you want to manage',
        },
        profile: {
            create: {
                success: 'New entity added successfully',
                error: 'Unable to create new entity',
                step_error: 'Unable to save the entity, please review the errors on the form',
                details: {
                    title: '{{type}} Details',
                    description: 'Please provide the name of the {{type}}:',
                    companyDescription: 'Trade as a company director or secretary on behalf of a company (e.g. ABC Pty Ltd).',
                    asicLink: "Don't have your ACN? Click here to look it up with ASIC",
                    superannuationDescription: 'The Self Managed Super Fund must be established in Australia.',
                    trustDescription: "If you'd like to trade on behalf of a Trust, select this option. The Trust must be established in Australia (eg. Minor Trust, Unit Trust, Family Trust, Testamentary Trust, Estate of, etc.)\n\nPlease provide the name of the Trust:",
                },
                deedDescription: 'Once you\'ve provided the name and selected the document, click the "Create new entity" button below.',
                deedExtraDescription: 'The certification must include: The certifier\'s full name, signature, date & title (e.g. independent financial adviser, judge, magistrate, justice of the peace, chartered accountant or police officer).',
                cancel: {
                    success: 'Cancelled creating entity',
                    error: 'Unable to cancel creating entity, navigating back to your entities',
                },
                accountantDetailsReason: "By providing your accountant's details we are able to liaise with them for any further requirements. You will be included in all communication.",
            },
            update: {
                success: 'Entity updated successfully',
                error: 'Unable to update entity',
            },
            delete: {
                success: 'Entity deleted successfully.',
                error: 'Unable to delete entity, or entity not found.',
            },
            submitted: {
                success: 'Entity successfully submitted. We will start the process shortly.',
                error: 'Unable to submit entity, please review the errors.',
                progress: "We've saved the progress of your entity",
            },
            accountManagementTypeReasonSelfManaged: 'Self Managed accounts are provided with a new Macquarie CMA, linking to a live trading screen and the ability to trade on the ASX with reduced fees. This account is for individuals who wish to manage their own trades.',
            accountManagementTypeReasonFullService: 'Full Service accounts come with a dedicated account manager who will manage your trades on your behalf. This account is for individuals who wish to have their trades managed by a professional. Full Service accounts will incur higher brokerage fees.',
            entityIntroduction: 'Complete the online onboarding below. If you are unable to complete all fields, simply click "Save Submission" and our support team will complete the rest.',
            taxFileNumberReason: 'Provision of a TFN is not compulsory, however, if you do not quote your TFN or claim an exemption, tax may be deducted from the interest paid to your Macquarie Cash Management Account at the highest marginal tax rate plus Medicare Levy. Declining to quote a TFN is not an offence.',
            entityIntroductionConcierge: 'Our support team will complete your submission.',
            entityIntroductionConciergeVerified: 'Your entity is now verified. You may use this entity to place bids on deals.',
            industryReason: 'Australian law requires all financial institutions to collect information about your industry category and type. This will not be used for marketing purposes.',
            phoneEmailReason: 'Phone and email are used for correspondence during the bank application.',
            locked: 'Entity is currently locked from further changes at this time. If you wish to update any details, please contact us.',
            applicantsReasonCompany: 'Please provide details of the individual(s) who directly or indirectly:\n\n(a) own 25% or more of the company;\n(b) controls the company (i.e. has ability to exercise control over the decisions or policies of the company).\n\nIf there are no such individuals, please provide the details of those who hold the position of senior managing officials (or equivalent), for example Managing Director or Directors.\n\nDo not fill out your details again, as they will already be included in the application.',
            applicantsReasonTrust: 'Please provide details of the individual(s) who directly or indirectly own 25% or more of the Trust.\n\nDo not fill out your details again, as they will already be included in the application.',
            applicantsReasonSuper: 'Please provide details of the individual(s) who directly or indirectly own 25% or more of the Super Fund.\n\nDo not fill out your details again, as they will already be included in the application.',
            applicantsLegalName: 'Please ensure you enter full legal names.',
            designationReason: 'Please provide an abbreviation of the name of the Trust for CHESS registration purposes (in 25 characters including spaces). Do not enter the words "Trust", "Fund" or "ATF" in this designation as it will not be accepted by CHESS.',
            settlorReason: 'The Settlor of the Trust is the individual(s) or entity who settled the initial sum to create the Trust.',
            tooltips: {
                submit: 'Once you have confirmed all the details, use this button to submit the application.',
                save: 'Save the progress of your submission to come back later and submit.',
            },
            accountManagementType: {
                success: 'Successfully updated your account type.',
                error: 'Unable to update your account type.',
            }
        },
        profile_hins: {
            title: '{{type}} / {{name}}',
            subtitle: 'Manage your connected Holder Identification Numbers (HINs)',
            success: 'Connected HINs saved successfully',
            error: 'Unable to update connected HINs',
            sections: {
                liquidity: {
                    title: 'Your Liquidity HIN',
                    skip_individual_trade_account: 'During onboarding elected to skip the Liquidity trade account for an individual.',
                    hin_in_progress: 'HIN application in progress.',
                    onboarding_required: 'Complete your entity to get a Liquidity HIN.'
                },
                alternate: {
                    title: 'Alternate HINs',
                    subtitle: 'If you possess alternate HINs for this entity, they may be utilised. These will be presented during the bidding process to validate the selected HIN.'
                }
            },
            notFound: {
                title: 'Unable to find entity',
                description: 'Sorry, unable to find the entity provided to update the connected HINs',
            }
        },
        profile_certificate: {
            title: '{{type}} / {{name}}',
            subtitle: 'Manage your entity s708 certificate',
            success: 'Certificate saved successfully',
            error: 'Unable to update certificate',
            notFound: {
                title: 'Unable to find entity',
                description: 'Sorry, unable to find the entity provided to update the certificate',
            }
        },
        invite: {
            success: 'Invite sent successfully',
            error: 'Unable to send invite',
        },
        two_factor: {
            heading: 'Two Factor Authentication',
            subheading: 'Add additional security to your account using two factor authentication. This will be used during your login experience only.',
            instructions: {
                qrCode: "Scan the QR code using your phone's Google Authenticator app.",
                recoveryCodes: 'Store these recovery codes in a secure password manager. They are single use and each can be used once to recover access to your account if your two factor authentication device is lost.',
            },
            modal: {
                heading: 'Two Factor Authentication',
                subheading: "We need to confirm you've got your Google Authenticator setup correctly. Please supply the token once you're ready.",
            },
            pending: {
                heading: 'Two factor authentication is pending',
                subheading: "When two factor authentication is enabled, you will be prompted for a secure, random token during login. You may retrieve this token from your phone's Google Authenticator application.",
                buttonText: 'Confirm setup',
                success: 'Please confirm your two factor authentication is enabled and supply the code',
                error: 'Unable to enable two factor authentication',
            },
            enabled: {
                heading: 'Two factor authentication is now enabled.',
                subheading: "Now that two factor authentication is enabled, you will be prompted for a secure, random token during login. You may retrieve this token from your phone's Google Authenticator application. Remember that recovery codes can only be used once. It's recommended you regenerate and store new recovery codes if you use one.",
                buttonText: 'Disable',
                regenerateButtonText: 'Regenerate recovery codes',
                success: 'Two factor authentication enabled successfully',
                error: 'Unable to enable two factor authentication',
            },
            disabled: {
                heading: 'You have not enabled two factor authentication.',
                subheading: "When two factor authentication is enabled, you will be prompted for a secure, random token during login. You may retrieve this token from your phone's Google Authenticator application.",
                buttonText: 'Enable',
                success: 'Two factor authentication disabled successfully',
                error: 'Unable to disable two factor authentication',
            },
            recoveryCodes: {
                success: 'New recovery codes have been generated',
                error: 'Unable to regenerate recovery codes',
            },
        },
        preferences: {
            update_category: {
                success: 'Successfully updated your preferences.',
            },
            update_stage: {
                success: 'Successfully updated your preferences.',
            },
        },
    },
    remaining: 'remaining',
    fieldsRequired: 'Fields marked with * will be required',
    advisers: {
        unlink: {
            success: 'Successfully unlinked the user from your account',
            error: 'Unable to unlink the user'
        },
        invite: {
            success: 'Successfully invited the adviser',
            error: 'Unable to invite adviser, please review the errors'
        },
        inviteUser: {
            success: 'Successfully invited the investor',
            error: 'Unable to invite investor, please review the errors'
        },
        resendInviteUser: {
            success: 'Successfully resent invite to the investor',
            error: 'Unable to resend invite investor, please review the errors'
        }
    },
    registrationDetails: {
        form: {
            editing: {
                title: 'Tell us about your bidding entity',
                subtitle: 'Enter a few details about the entity you will be bidding with.',
                legal_name: 'Please ensure you enter your full legal name.',
                submit: 'Submit',
                success: 'Your details have been saved',
                error: 'Unable to add details to your account',
                uploadChess: 'Upload a <holdingStatement> to supply your account designation, or fill out the form below.',
                certificate: 'Participation in the offer requires that you are a Sophisticated Investor (Corporations Act 2001). Please upload your signed certificate below.',
                certificateIpo: 'This is not a requirement for IPOs if have read and understood the prospectus. \n\nIf you are a Sophisticated Investor (Corporations Act 2001), please upload your signed certificate below.',
            },
            complete: {
                title: 'Thank you for submitting your details',
                nextSteps: 'Once your bid has been approved, you will receive an offer letter to sign electronically along with details for the transfer of funds. \n\nIf you have any questions, please contact us at members@liquidity.com.au, or (08) 9386 9577',
                subtitle: 'You may now close this page. We will be in touch soon.',
                submit: 'Edit details',
            }
        }
    },
    asxAnnouncements: {
        subheading: {
            hasTrade: 'To view the full list of announcements and their content, please go to your live ',
            noTrade: 'To view the full list of announcements and their content, please set up your live trading screen or contact us at ',
        }
    },
};
