import {
    Box,
    Grid,
    Heading,
    Text
} from '@theme-ui/components'
import { Container, Markdown } from '~/Common'
import { useTranslation } from 'react-i18next'

export const LabelAndValues = ({ bg = 'white', data, sx, container, ...props }) => {
    const { t } = useTranslation();
    const title = data?.title ?? null;
    const description = data?.description ?? null;

    return (
        <Box bg={bg} {...props} sx={{
            position: 'relative',
            ...sx
        }}>
            <Container variant="layouts.section" {...container}>
                { title && <Heading as="h2" variant="content_block_heading">{t(title)}</Heading>}
                { description && (
                    <Box className="markdown" mb={[4,4,5]} sx={{ fontWeight: 500, fontSize: '19px' }}>
                        <Markdown>{description}</Markdown>
                    </Box>
                )}

                {data?.cards && (
                    <Grid sx={{
                        gridGap: '40px',
                        gridTemplateColumns: ['repeat(3, 1fr)'],
                        width: '100%',
                        mb: 0,
                        pr: '100px'
                    }}>
                        {data.cards.map((card, key) => (
                            <Box key={key}>
                                <Box mr={4} sx={{ width: '180px' }}>
                                    <Text as="p" variant="small" color="dark" mb={1}>{card?.label}</Text>
                                    <Text as="p" variant="h3" color="darker" sx={{ fontWeight: 500, fontSize: 3 }}>{card?.value}</Text>
                                </Box>
                            </Box>
                        ))}
                    </Grid>
                )}
            </Container>
        </Box>
    )
};
