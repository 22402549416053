import { Box, Link as ThemeLink } from '@theme-ui/components'
import { Link } from 'react-router-dom'

export const DiscoverMore = ({ to, label = 'View All', sx, ...props }) => {
    return (
        <Box>
            <ThemeLink as={Link} to={to} sx={{
                display: 'inline-flex',
                fontSize: 1,
                ...sx
            }}{...props}>
                {label}
            </ThemeLink>
        </Box>
    );
}
