import { useState, useEffect, useRef } from 'react';
import { DashboardLayout } from '~/Layouts';
import {
    Alert,
    ArticleRow,
    Button,
    Breadcrumbs,
    Container,
    PageHeader,
    TimeRemaining,
    WatchlistButton,
    Markdown,
    Media,
    Pagination
} from '~/Common';
import { ContentBlock, ContentBlockMenu } from '~/ContentBlocks'
import {
    Box,
    Flex,
    Grid,
    Heading,
    Image,
    Text
} from '@theme-ui/components'
import {
    DealModal,
    PerformanceGraph,
    Related,
    StatusTag,
    SummaryHeader
} from '~/Deals'
import { useHistory, Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment-timezone'
import { getCurrentDealAsync, getCurrentDealNewsAsync } from 'features/deal/dealSlice';
import { setShowPlaceBid } from 'features/theme/themeSlice';
import { dealOfferPriceFormatting, formatCurrency, formatNumber } from 'utils/helpers'
import { toast } from 'utils/toast';
import {DATE_FORMAT, DealStatus, APP_FEATURES, DealInvestmentStatus, DealStageTypes} from 'app/constants'
import { useResponsiveValue } from '@theme-ui/match-media'
import {rejectEoi} from "features/auth/authAPI";
import { Icon } from 'assets/Icon';
import { DealsViewSkeleton } from './skeleton';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useDealTypeDetail } from 'hooks/useDealTypeDetail';
import { useDisplayName } from 'hooks/useDisplayName';

const DealsView = ({ user, theme, deals, match }) => {
    const { t } = useTranslation();
    useDocumentTitle(t(`Deals - ${deals?.currentDeal?.name || ''}`));
    const showSummaryHeader = useResponsiveValue([false, false, false, false, true]);
    const dealDetail = useDealTypeDetail(deals?.currentDeal);
    const deal = deals?.currentDeal;
    const dealDisplayName = useDisplayName(deal);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isClosed, setIsClosed] = useState(false);
    const nodeRef = useRef(null);
    const [notFound, setNotFound] = useState(false);
    const [dealSlug, setDealSlug] = useState('');
    const [showBidButton, setShowBidButton] = useState(false);
    const [showBiddingClosed, setShowBiddingClosed] = useState(false);
    const isMobile = useResponsiveValue([ true, true, true, false ]);
    const isAdviser = user?.user?.is_adviser === true;
    const now = moment().utc();

    const isLoading = deals.currentDealStatus === 'loading';


    const articles = deals?.currentDealNews;
    const showArticles = Array.isArray(articles) && articles.length > 0 && deal?.status?.id !== DealStatus.draft && deal !== null;

    useEffect(() => {
        // Don't allow unauthorised users to view
        if (!user.user?.can_see_dashboard){
            toast.error(t('notAuthorised.alert'), {
                toastId: 'not-authorised-error',
            });

            history.push('/deals');
        }

        // Get the current deal (if the slug is empty or has changed)
        if(deals.currentDealStatus === 'idle' || (match?.params?.slug !== dealSlug)){
            const newSlug = match?.params?.slug;

            setDealSlug(newSlug);
            dispatch(getCurrentDealAsync(newSlug));
            dispatch(getCurrentDealNewsAsync({slug: newSlug, page: 1 }))

            window.addEventListener('notFound', event => {
                setNotFound(true);
            });
        }

        const interval = setInterval(() => {
            if (deal?.available_to) {
                const then = moment(deal?.available_to).utc();

                if(moment().isAfter(then)){
                    setIsClosed(true);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval)
        };
    }, [deal, deals, dealSlug, match, dispatch, setIsClosed, history, t, user, isClosed]);

    useEffect(() => {
        setShowBidButton( !isAdviser && user.user?.can_place_bids && deal?.status.id === DealStatus.active && !isClosed && deal?.has_offer)
    }, [ isClosed, deal, user, isAdviser ]);

    const openModal = (e) => {
        e.preventDefault();
        dispatch(setShowPlaceBid(true));
    };

    const openExternalOnboardingLink = (e) => {
        e.preventDefault();
        if (deal?.external_onboarding_link) {
            window.open(deal.external_onboarding_link, '_blank');
        } else {
            dispatch(setShowPlaceBid(true));
        }
    }

    useEffect(() => {
        const expired = !now.isBetween(moment(deal?.available_from).utc(), moment(deal?.available_to).utc());
        const userHasEoiForDeal = user.user?.eoi && deal?.uuid === user.user?.eoi?.deal_info?.uuid && user.user?.eoi?.deal_investment_status_id === DealInvestmentStatus.pending;

        if((userHasEoiForDeal && !deal?.has_external_onboarding_link) && (!deal?.has_offer || !(deal?.status?.id === DealStatus.active) || expired)){
            setShowBiddingClosed(true);
        }

        if (deal && userHasEoiForDeal) {
            if (!deal?.has_offer || !(deal?.status?.id === DealStatus.active) || expired) {
                setTimeout(() => {
                    rejectEoi(deal.slug);
                }, 5000);
            } else {
                dispatch(setShowPlaceBid(true));
            }
        }
    }, [deal, user, dispatch, now]);

    moment.tz.setDefault(user?.user?.timezone);

    return (
        <DashboardLayout p={0}>
            <CSSTransition
                nodeRef={nodeRef}
                in={true}
                timeout={500}
                classNames="fade"
                unmountOnExit
                appear
            >
                <div ref={nodeRef}>
                    {isLoading ?
                        <DealsViewSkeleton />
                    : <>
                        {showBiddingClosed ? <Alert role="alert" variant='warning' mb={4}>
                            Unfortunately bidding has now closed for this deal.
                        </Alert> : null}
                        {showSummaryHeader && showBidButton && <SummaryHeader deal={deal} bidButtonAction={openModal} />}
                        <DealModal user={user} theme={theme} deal={deal} onBidPlaced={p => setShowBidButton(!p)} />
                        <PageHeader sx={{ position: 'relative' }}>
                            <Container variant="layouts.default" sx={{
                                maxWidth: ['812px', '812px', '812px', '1392px']
                            }}>
                                <Grid sx={{
                                    position: 'relative',
                                    gridGap: [4, 4, 4, 5],
                                    gridTemplateColumns: ['1fr', null, null, '2fr 3fr', 'auto 780px'],
                                    width: '100%'
                                }}>
                                    <Box sx={{ position: 'relative', order: [2, 2, 2, 0] }}>
                                        {!isMobile && (
                                            <Breadcrumbs title={deal?.name} items={[
                                                {
                                                    title: 'Deals',
                                                    to: '/deals'
                                                }
                                            ]} />
                                        )}

                                        {deal?.status?.id === DealStatus.draft &&
                                            <Alert role="alert" variant='warning' mb={4}>
                                                This deal is currently in <strong>draft</strong> status
                                            </Alert>
                                        }

                                        {notFound ? (
                                            <Box mb={4}>
                                                <Heading as="h1" variant="display" mb={2}>{t('deals.notFound.title')}</Heading>
                                                <Text as="p" color="medium">{t('deals.notFound.description')}</Text>
                                            </Box>
                                        ) : (
                                            <>
                                                <StatusTag mb={4} label={deal?.stage?.name} status={deal?.status?.id} />

                                                <Box mb={4}>
                                                    <Heading as="h1" variant="display" sx={{ lineHeight: '110%', fontWeight: 500}}>{t(dealDisplayName)}</Heading>
                                                    <Text as="p" variant="small" color="dark" mb={2}>{deal?.code}</Text>
                                                </Box>

                                                <Box mb={3}>
                                                    <Text as="p" variant="tag" color="dark" mb={2} sx={{ fontSize: '14px' }}>{t(dealDetail.managerText)}</Text>
                                                    {deal?.organisation ? (
                                                        <>
                                                            {deal?.organisation?.logo?.sizes?.medium && <Image src={deal?.organisation?.logo?.sizes?.medium} />}
                                                            {deal?.organisation?.logo?.sizes?.medium === undefined && <Text as="p" variant="tag" color="dark" mb={2} sx={{ fontSize: '14px' }}>{deal?.organisation?.name}</Text>}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Icon icon="lqd" height="36" />
                                                        </>
                                                    )}
                                                </Box>

                                                {!deal?.preview_mode && (
                                                    <>
                                                        {(deal?.offer_price && deal.alternateInvestment) ? (
                                                            <Flex
                                                                my={2}
                                                                sx={{
                                                                    flexDirection: ['row'],
                                                                    flexGrow: 0
                                                                }}
                                                            >
                                                                <Box mr={4} sx={{ width: '120px', flex: 1 }}>
                                                                    <Text as="p" variant="small" color="dark" mb={2} sx={{ fontWeight: 'bold' }}>{t('Offer price')}</Text>
                                                                    <Text as="p" variant="price" color="darker" sx={{ fontSize: [3, 3, 3, 3, 6], lineHeight: 1 }}>A${dealOfferPriceFormatting(deal?.offer_price)}</Text>
                                                                    <Text as="p" color="dark">{deal?.stage?.name === 'Fund' ? 'per unit' : 'per share'}</Text>
                                                                </Box>
                                                                <Box>
                                                                    <Text as="p" variant="small" color="dark" mb={2} sx={{ fontWeight: 'bold' }}>{t('Offer size')}</Text>
                                                                    <Text as="p" variant="price" color="darker" sx={{ fontSize: [3, 3, 3, 3, 6], lineHeight: 1 }}>A${deal?.offer_size}</Text>
                                                                </Box>
                                                            </Flex>
                                                        ) : (
                                                            <Flex
                                                                my={2}
                                                                sx={{
                                                                    flexDirection: ['row'],
                                                                    flexGrow: 0
                                                                }}
                                                            >
                                                                <Box mr={4} sx={{ width: '120px', flex: 1 }}>
                                                                    <Text as="p" variant="small" color="dark" mb={2} sx={{ fontWeight: 'bold' }}>{t('Minimum Investment')}</Text>
                                                                    <Text as="p" variant="price" color="darker" sx={{ fontSize: [3, 3, 3, 3, 6], lineHeight: 1 }}>A${dealOfferPriceFormatting(deal?.min_bid_price)}</Text>
                                                                </Box>
                                                            </Flex>
                                                        )}
                                                    </>
                                                )}

                                                <Box mt={4}>
                                                    {showBidButton && (
                                                        <Button variant="primary" sx={{ height: '60px', width: '100%' }} mb={3} onClick={((e) => {
                                                            deal.has_external_onboarding_link ?  openExternalOnboardingLink(e) : openModal(e)
                                                        })}>{t(dealDetail.bidButtonText)}</Button>
                                                    )}
                                                    <Grid sx={{
                                                        gridTemplate: APP_FEATURES.following ? 'auto / repeat(2, 1fr)' : 'auto'
                                                    }}>
                                                        <TimeRemaining
                                                            to={moment(deal?.available_to).utc().clone().local()}
                                                            from={moment(deal?.available_from).utc().clone().local()}
                                                            dealPreviewMode={deal?.preview_mode}
                                                            alternateInvestment={dealDetail.alternateInvestment}
                                                        />
                                                        {deal?.company ? <WatchlistButton sx={{ width: 'auto' }} company={deal?.company?.uuid} withText={true} fund={deals?.currentDeal?.stage?.type === DealStageTypes.fund} /> : null}
                                                    </Grid>
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                    <Box sx={{
                                        maxWidth: ['none', 'none', 'none', '820px'],
                                        position: 'relative',
                                        order: [0, 0, 0, 2]
                                    }}>
                                        <Media
                                            video={deal?.video}
                                            image={deal?.banner?.sizes?.content ? deal.banner.sizes.content : deal?.banner?.sizes?.large}
                                        />
                                    </Box>
                                </Grid>
                            </Container>
                        </PageHeader>

                        <Container variant="layouts.default" sx={{
                            maxWidth: ['812px', '812px', '812px', '1392px']
                        }}>
                            <Grid sx={{
                                position: 'relative',
                                gridGap: [1, 1, 1, 5],
                                gridTemplateColumns: ['1fr', '1fr', '1fr', '300px auto', 'auto 780px'],
                                width: '100%'
                            }}>
                                <Box sx={{ position: 'relative' }}>
                                    <ContentBlockMenu
                                        contentBlocks={deal?.content_blocks}
                                        showAsx={!!deal?.security}
                                        showArticles={showArticles}
                                        prependMenu={{
                                            title: deal?.company?.name,
                                            href: '#company-profile'
                                        }}
                                    />
                                </Box>
                                <Box>
                                    {deal && (
                                        <Box id="company-profile">
                                            <Container variant="layouts.section">
                                                <Heading as="h2" variant="content_block_heading">{t(`${deal?.company?.name} Overview`)}</Heading>

                                                <Markdown className="leftContent">{deal?.company?.summary}</Markdown>
                                                <Button as={Link} to={`/companies/${deal?.company?.slug}`} variant="bordered">{t('View company profile')}</Button>
                                            </Container>
                                        </Box>
                                    )}
        
                                    {deal?.security && (
                                        <>
                                            <Box id="asx-performance">
                                                <Container variant="layouts.section">
                                                    <Heading as="h2" variant="content_block_heading">{t('Fund Performance')}</Heading>

                                                    <Grid sx={{
                                                        gridGap: 4,
                                                        gridTemplateColumns: ['1fr', '1fr'],
                                                        width: '100%'
                                                    }}>
                                                        <PerformanceGraph deal={deal} />
                                                        {deal?.security?.updated_at &&
                                                            <Text as="p" sx={{ fontSize: '10px' }} color="gray" mt={2}>Last updated: {moment(deal?.security?.updated_at).format(DATE_FORMAT.last_updated)}</Text>
                                                        }
                                                    </Grid>
                                                </Container>
                                            </Box>
                                            <Box id="asx-info">
                                                <Container variant="layouts.section">
                                                    <Heading as="h2" variant="content_block_heading">{t('Market Info')}</Heading>

                                                    <Grid sx={{
                                                        gridGap: 3,
                                                        gridTemplateColumns: ['1fr', '1fr', '1fr', 'repeat(3, 1fr)'],
                                                        width: '100%'
                                                    }}>
                                                        <Box pb={3}>
                                                            <Text as="p" variant="small" color="dark">{t('asx.labels.open')}</Text>
                                                            <Text as="p" variant="info" color="darker">{deal?.security?.current?.open_price && <>{formatCurrency(deal?.security?.current?.open_price)}</>}</Text>
                                                        </Box>
                                                        <Box pb={3}>
                                                            <Text as="p" variant="small" color="dark">{t('asx.labels.marketCap')}</Text>
                                                            <Text as="p" variant="info" color="darker">{deal?.security?.current?.market_capitalisation_readable && <>${deal?.security?.current?.market_capitalisation_readable}</>}</Text>
                                                        </Box>
                                                        <Box pb={3}>
                                                            <Text as="p" variant="small" color="dark">{t('asx.labels.previousClose')}</Text>
                                                            <Text as="p" variant="info" color="darker">{deal?.security?.current?.previous_close_price && <>{formatCurrency(deal?.security?.current?.previous_close_price)}</>}</Text>
                                                        </Box>

                                                        <Box pb={3}>
                                                            <Text as="p" variant="small" color="dark">{t('asx.labels.bid')}</Text>
                                                            <Text as="p" variant="info" color="darker">{deal?.security?.current?.sellers_price && <>{formatCurrency(deal?.security?.current?.sellers_price)}</>}</Text>
                                                        </Box>
                                                        <Box pb={3}>
                                                            <Text as="p" variant="small" color="dark">{t('asx.labels.peRatio')}</Text>
                                                            <Text as="p" variant="info" color="darker">{deal?.security?.current?.pe_ratio && <>{formatNumber(deal?.security?.current?.pe_ratio)}</>}</Text>
                                                        </Box>
                                                        <Box pb={3}>
                                                            <Text as="p" variant="small" color="dark">{t('asx.labels.yearRange')}</Text>
                                                            <Text as="p" variant="info" color="darker">{(deal?.security?.trade_history?.low_year_price && deal?.security?.trade_history?.high_year_price) ?
                                                                <>
                                                                    {formatCurrency(deal?.security?.trade_history?.low_year_price)} - {formatCurrency(deal?.security?.trade_history?.high_year_price)}
                                                                </> : 'N/A'}</Text>
                                                        </Box>

                                                        <Box pb={3}>
                                                            <Text as="p" variant="small" color="dark">{t('asx.labels.vwap')}</Text>
                                                            <Text as="p" variant="info" color="darker">{deal?.security?.trade_history?.market_vwap ? <>{formatCurrency(deal?.security?.trade_history?.market_vwap)}</> : 'N/A'}</Text>
                                                        </Box>
                                                        <Box pb={3}>
                                                            <Text as="p" variant="small" color="dark">{t('asx.labels.volume')}</Text>
                                                            <Text as="p" variant="info" color="darker">{deal?.security?.current?.volume && <>{formatNumber(deal?.security?.current?.volume)}</>}</Text>
                                                        </Box>
                                                        <Box pb={3}>
                                                            <Text as="p" variant="small" color="dark">{t('asx.labels.dividendYield')}</Text>
                                                            <Text as="p" variant="info" color="darker">{deal?.security?.current?.dividend_yield && <>{formatNumber(deal?.security?.current?.dividend_yield)}</>}</Text>
                                                        </Box>

                                                        <Box>
                                                            <Text as="p" variant="small" color="dark">{t('asx.labels.ask')}</Text>
                                                            <Text as="p" variant="info" color="darker">{deal?.security?.current?.buyers_price && <>{formatCurrency(deal?.security?.current?.buyers_price)}</>}</Text>
                                                        </Box>
                                                    </Grid>
                                                </Container>
                                            </Box>
                                        </>
                                    )}
                                    {showArticles && (
                                        <Box id="latest-news">
                                            <Container variant="layouts.section">
                                                <Heading as="h2" variant="content_block_heading" mb={0}>{t('Latest News')}</Heading>
                                                <Box>
                                                    {articles.map((article, index) => (
                                                        <ArticleRow key={index} article={article} />
                                                    ))}
                                                </Box>
                                                {deals?.currentDealNewsMeta ? (
                                                    <Pagination
                                                        activePage={deals.currentDealNewsMeta?.current_page ?? 0}
                                                        pageCount={deals.currentDealNewsMeta?.last_page ?? 0}
                                                        onPageChange={(event) => {
                                                            dispatch(getCurrentDealNewsAsync({slug: dealSlug, page: event.selected + 1 }))
                                                        }}
                                                    />
                                                ) : null}
                                            </Container>
                                        </Box>
                                    )}
                                    {deal?.content_blocks && deal?.content_blocks.map((block, key) => (
                                        <ContentBlock
                                            type={block?.type}
                                            data={block?.data}
                                            container={block?.settings?.container}
                                            bg={block?.settings?.bg}
                                            blockKey={key + 1}
                                            live_at={block?.live_at}
                                            key={`block${key}`}
                                        />
                                    ))}
                                </Box>
                            </Grid>
                        </Container>

                        <Box my={4}>
                            <Container variant="layouts.default">
                                <Related heading="Deals you might be interested in" showDiscoverMore={true} to="/deals" deal={deal} />
                            </Container>
                        </Box>

                        {deal?.disclaimer && (
                            <Box sx={{ borderTop: '1px solid', borderTopColor: 'medium' }}>
                                <Container variant="layouts.disclaimer">
                                    <Heading as="h2" variant="h5" mb={3} sx={{ fontSize: '18px', fontWeight: 'bold' }}>{t('Disclaimer')}</Heading>
                                    <Markdown className="markdown disclaimer" sx={{ fontSize: '12px', lineHeight: '1.5' }}>
                                        {deal?.disclaimer}
                                    </Markdown>
                                </Container>
                            </Box>
                        )}
                    </>}
                </div>
            </CSSTransition>
        </DashboardLayout>
    );
};

const mapStateToProps = (state) => {
    return { user: state.user, theme: state.theme, deals: state.deals }
}

export default connect(mapStateToProps, null)(DealsView);
