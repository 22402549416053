import { useState, useEffect, useCallback, useRef } from 'react';
import { Form, Formik } from 'formik';
import { LoadingScreen, VerifyModal } from '~/Common';
import { AcceptanceLayout } from '~/Layouts';
import { Field, Submit } from '~/Forms';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom'
import { formatCurrency, numberWithCommas } from 'utils/helpers'
import {
    Heading,
    Text,
    Link,
    Flex,
    Box,
    Grid
} from '@theme-ui/components';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { getDealAcceptance, signInvestment } from 'features/deal/dealSlice'
import { setPortfolioStatus } from 'features/portfolio/portfolioSlice';
import { toast } from 'utils/toast'
import moment from 'moment-timezone';
import { DATE_FORMAT } from 'app/constants';
import { acceptanceLetterLogin } from 'features/auth/authAPI';
import { getCsrfCookie } from 'utils/api';
import { setUser } from 'features/auth/authSlice';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { user: state.user?.user }
}

const validationSchema = Yup.object().shape({
    signature: Yup.string().nullable().required('Please sign within the box'),
    termsAgree: Yup.bool().oneOf([true], 'You must agree to the terms')
});

const DealAcceptance = ({ user, match }) => {
    const token = match.params?.token;
    const { t } = useTranslation();
    useDocumentTitle(t('Deal Acceptance'));
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const [data, setData] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [loginAttempted, setLoginAttempted] = useState(false);

    moment.tz.setDefault(user?.user?.timezone);

    const fetchAcceptanceData = useCallback((token) => {
        getDealAcceptance(token)
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch(({ response }) => {
                if (response?.status === 403) {
                    setShowVerifyModal(true);
                }else{
                    toast.error(response.data?.errors?.token?.[0] ?? t('Error loading investment data'), {
                        toastId: 'deal-acceptance-signature-error',
                    });
                    history.push('/');
                }
                setLoading(false);
            });
    }, [t, history]);

    useEffect(() => {
        // If we have a logged in user, we don't need to login via token
        if(!loginAttempted && token){
            setLoading(true);

            // Get login via CSRF and token, have to wait for csrf to run first
            getCsrfCookie().then(() => {
                acceptanceLetterLogin({
                    acceptance_token: token,
                    device_name: 'App'
                }).then((response) => {
                    dispatch(setUser(response));
                    fetchAcceptanceData(token);
                    setLoading(false);
                }).catch(({ response }) => {
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('authTokenScope');
                    toast.error(response?.data?.message || t('login.error'), {
                        toastId: 'login-error',
                    });
                    history.push('/');
                });
                setLoginAttempted(true);
            })
        } else {
            setLoading(false);
        }
    }, [token, dispatch, t, history, loginAttempted, fetchAcceptanceData]);

    const onVerifySuccess = () => {
        setShowVerifyModal(false);
        fetchAcceptanceData(token);
    }

    const handleSubmit = (values, actions) => {
        if (data?.deal) {
            signInvestment(data.uuid, {
                signature: values.signature
            })
                .then(() => {
                    setSuccess(true);
                    setShowVerifyModal(true);
                    dispatch(setPortfolioStatus('idle'));
                })
                .catch(({ response }) => {
                    if (response?.status === 403) {
                        setShowVerifyModal(true);
                    } else {
                        toast.error(response.data?.message ?? t('Unable to submit signature'), {
                            toastId: 'deal-acceptance-signature-error',
                        });
                    }
                    setLoading(false);
                })
                .finally(() => actions.setSubmitting(false))
        }
    }

    if (isLoading) {
        return <LoadingScreen />
    }

    const acceptanceDate = moment(data.acceptance_by).format(DATE_FORMAT.date_format_friendly);

    const profileAddress = data.profile?.address?.street_name ? [
        data.profile?.address?.unit_number,
        data.profile?.address?.street_number,
        data.profile?.address?.street_name,
        data.profile?.address?.street_type
    ].filter(i => !!i).join(' ') : (data.profile?.address?.address ?? false);

    const profileLocation = [
        data.profile?.address?.suburb,
        data.profile?.address?.state,
        data.profile?.address?.postcode
    ].filter(i => !!i).join(' ');

    return (
        <>
            <VerifyModal
                isOpen={showVerifyModal}
                onSuccess={onVerifySuccess}
                closeModal={() => setShowVerifyModal(false)}
                hideCloseOnSuccess={true}
                successTemplate={{
                    heading: t('acceptance.success.heading'),
                    content: t('acceptance.success.content'),
                    button: {
                        label: t('acceptance.success.button'),
                        to: '/portfolio'
                    }
                }}
                forceSuccess={isSuccess}
                userUuid={user?.uuid}
            />
            {data && (
                <AcceptanceLayout>
                    <Heading mb={5}>{t('acceptance.heading')}</Heading>
                    <Heading variant="h3" sx={{ fontWeight: 500 }}>{t(`${data.deal?.company?.name} (or the "Company")`)}</Heading>
                    <Text as="p" variant="small" color="dark" mb={4} sx={{ fontSize: ['14px', '14px'] }}>{t('Deal:')} {data.deal?.code}</Text>

                    <Text as="p">{data.profile?.letterhead_name ?? data.profile?.name}</Text>
                    {data.profile?.designation && <Text as="p">{`<${data.profile?.designation} A/C>`}</Text>}
                    {profileAddress && <Text as="p">{profileAddress}</Text>}
                    {data.profile?.address?.address2 && <Text as="p">{data.profile?.address?.address2}</Text>}
                    {profileLocation && <Text as="p">{profileLocation}</Text>}
                    <br />
                    <Text as="p">{t('By Email:')} {data.user?.email}</Text>
                    <br />
                    <Text as="p" mb={4}>
                        {t('Dear')} {data.user?.first_name},<br />
                        <br />
                        {t('acceptance.content')}
                    </Text>

                    <Grid sx={{
                        gridTemplate: Boolean(data.has_attaching_options) ? ['auto', 'auto / repeat(4, 1fr)'] : ['auto', 'auto / repeat(3, 1fr)'],
                        gridGap: '20px',
                        py: [3, '40px'],
                        px: '20px',
                        border: '1px solid #00000A',
                        borderRadius: '16px',
                        mb: 4,
                        textAlign: 'center'
                    }}>
                        <Box>
                            <Text variant="smallLight" sx={{ fontSize: '13px' }}>{t('Offer Shares')}</Text>
                            <Text sx={{ display: 'block', fontSize: '16px' }}>{numberWithCommas(data.expected_shares)}</Text>
                        </Box>
                        <Box>
                            <Text variant="smallLight" sx={{ fontSize: '13px' }}>{t('Subscription Price')}</Text>
                            <Text sx={{ display: 'block', fontSize: '16px' }}>AUD{formatCurrency(data.avg_price, 3)}</Text>
                        </Box>
                        <Box>
                            <Text variant="smallLight" sx={{ fontSize: '13px' }}>{t('Total Amount')}</Text>
                            <Text sx={{ display: 'block', fontSize: '16px' }}>AUD{formatCurrency(data.capital)}</Text>
                        </Box>

                        {Boolean(data.has_attaching_options) && (
                            <Box>
                                <Text variant="smallLight" sx={{ fontSize: '13px' }}>{t('Attaching Options')}</Text>
                                <Text sx={{ display: 'block', fontSize: '16px' }}>{data.attaching_options}</Text>
                            </Box>
                        )}
                    </Grid>

                    <Text as="p" mb={5}>
                        {t('acceptance.urgentAttention', { acceptanceDate })}<br /><br />
                        {t('acceptance.readTermSheet')}<br /><br />
                        {t('acceptance.clickAccept')}
                    </Text>


                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            signature: null,
                            termsAgree: false
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ status, setStatus, setFieldValue, values, validateField, submitForm, isSubmitting }) => (
                            <Form>
                                <Heading variant="h3" mb={4} sx={{ fontWeight: 500 }}>{t('acceptance.drawSignature')}</Heading>
                                <Text size="20px">{t('acceptance.signatureLabel')}</Text>
                                <Field field="signature" name="signature" disabled={isSubmitting} sx={{ mt: 3 }} />
                                <Flex sx={{ mb: 3 }}>
                                    <Field
                                        field="checkbox"
                                        name="termsAgree"
                                        disabled={isSubmitting}
                                        label={t('acceptance.agreeLabel', { companyName: data.deal?.company?.name })}
                                        validIndicator={false}
                                        pb={0}
                                        groupSx={{ pt: 0 }}
                                        groupMb={0}
                                    />
                                    <Link
                                        href={data.acceptance_document}
                                        target="_blank"
                                        sx={{
                                            fontSize: '14px',
                                            color: 'tealDark',
                                            ml: 1
                                        }}>{t('acceptance.downloadLetter')}</Link>
                                </Flex>
                                <Submit text="Accept Offer" disabled={!values.termsAgree || !values.signature || isSubmitting} sx={{ width: '100%' }} />
                            </Form>
                        )}
                    </Formik>

                    <Text as="p" mt={5}>
                        {t('acceptance.contact')} <Link href="mailto:members@liquidity.com.au">members@liquidity.com.au</Link>.<br /><br />
                        {t('acceptance.thankyou')}
                    </Text>

                </AcceptanceLayout>
            )}
            
        </>
    );
};

export default connect(mapStateToProps, null)(DealAcceptance);
