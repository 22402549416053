import { Layout } from '~/Layouts';
import { Container } from '~/Common';
import { useCallback, useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Close,
    Heading,
    Grid,
    Spinner,
    Text,
    Link,
    Paragraph,
} from 'theme-ui';
import { Alert as StyledAlert } from '~/Common';
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { getEoiUser, storeEoiCertificate, storeEoiContractNote, storeEoiRegistrationDetails } from 'features/eoi/eoiAPI';
import { 
    getProfileOptionsAsync, 
    getCountriesAsync, 
} from 'features/theme/themeSlice'
import { toast } from 'utils/toast';
import { connect, useDispatch } from 'react-redux';
import { Yup } from 'utils/validationRules';
import { Button } from '~/Common';
import { Icon } from 'assets/Icon';
import { ProfileMainTypes, ProfileSubTypes } from 'app/constants';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import AddressFields from '~/Forms/AddressFields/AddressFields';
import RegistrationDetailsCard from '~/Common/RegistrationDetailsCard/RegistrationDetailsCard';
import { CheckboxField } from '~/Forms/CheckboxField';
import RegistrationDetailsUpload from '~/Forms/RegistrationDetailsUpload/RegistrationDetailsUpload';

const mapStateToProps = (state) => {
    return { theme: state.theme }
}

const validationSchema = Yup.object().shape({
    title: Yup.string().nullable().when('has_contract_note', {
        is: false,
        then: Yup.string().required('required field'),
    }),
    first_name: Yup.string().required('required field'),
    middle_names: Yup.string().nullable(),
    last_name: Yup.string().required('required field'),
    mobile: Yup.string().required('required field'),
    trustee_1_title: Yup.string().nullable().when(['subtype', 'has_contract_note'], {
        is: (subtype, has_contract_note) => !has_contract_note && (subtype === 'individual' || subtype === 'joint'),
        then: Yup.string().required('Trustee 1\'s title is required'),
    }),
    trustee_1_first_name: Yup.string().nullable().when(['subtype', 'has_contract_note'], {
        is: (subtype, has_contract_note) => !has_contract_note && (subtype === 'individual' || subtype === 'joint'),
        then: Yup.string().required('Trustee 1\'s first name is required'),
    }),
    trustee_1_last_name: Yup.string().nullable().when(['subtype', 'has_contract_note'], {
        is: (subtype, has_contract_note) => !has_contract_note && (subtype === 'individual' || subtype === 'joint'),
        then: Yup.string().required('Trustee 1\'s last name is required'),
    }),
    trustee_2_title: Yup.string().nullable().when(['subtype', 'has_contract_note'], {
        is: (subtype, has_contract_note) => !has_contract_note && subtype === 'joint',
        then: Yup.string().required('Trustee 2\'s title is required'),
    }),
    trustee_2_first_name: Yup.string().nullable().when(['subtype', 'has_contract_note'], {
        is: (subtype, has_contract_note) => !has_contract_note && subtype === 'joint',
        then: Yup.string().required('Trustee 2\'s first name is required'),
    }),
    trustee_2_last_name: Yup.string().nullable().when(['subtype', 'has_contract_note'], {
        is: (subtype, has_contract_note) => !has_contract_note && subtype === 'joint',
        then: Yup.string().required('Trustee 2\'s last name is required'),
    }),
    name: Yup.string().nullable().when(['subtype', 'type', 'has_contract_note'], {
        is: (subtype, type, has_contract_note) => !has_contract_note && (subtype === 'company' || type === 'company'),
        then: Yup.string().required(),
    }),
    designation: Yup.string().nullable().when(['type', 'has_contract_note'], {
        is: (type, has_contract_note) => !has_contract_note && (type === 'trust' || type === 'superannuation'),
        then: Yup.string().required(),
    }),
    unit_number: Yup.string().nullable(),
    street_number: Yup.string().nullable(),
    street_name: Yup.string().nullable(),
    street_type: Yup.string().nullable(),
    address2: Yup.string().nullable(),
    state: Yup.string().nullable(),
    suburb: Yup.string().nullable(),
    postcode: Yup.string().nullable(),
    country: Yup.string().nullable(),
    hin_number: Yup.string().nullable(),
    has_contract_note: Yup.boolean().nullable(),
});

const RegistrationDetailsForm = ({theme, match}) => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useDocumentTitle(t('Registration Details'));
    const [profileOptions, setProfileOptions] = useState(theme.profile.options);
    const [selected, setSelected] = useState('individual');
    const [subtype, setSubtype] = useState('');
    const [eoiUser, setEoiUser] = useState(null);
    const [contractNoteUploadProgress, setContractNoteUploadProgress] = useState(0);
    const [certificateUploadProgress, setCertificateUploadProgress] = useState(0);
    const [showUploadContractNote, setShowUploadContractNote] = useState(true);
    const [showCertificateUpload, setShowCertificateUpload] = useState(true);
    const [formState, setFormState] = useState('editing');
    const formText = {
        editing: {
            title: 'registrationDetails.form.editing.title',
            subtitle: 'registrationDetails.form.editing.subtitle',
            submit: 'registrationDetails.form.editing.submit'
        },
        complete: {
            title: 'registrationDetails.form.complete.title',
            nextSteps: 'registrationDetails.form.complete.nextSteps',
            subtitle: 'registrationDetails.form.complete.subtitle',
            submit: 'registrationDetails.form.complete.submit'
        }
    }

    useEffect(() => {
        getEoiUser(match.params.uuid)
            .then((response) => {
                setEoiUser(response?.data);
            })
            .catch(({ response }) => {
                // Handle error
            });
    }, [match.params.uuid]);

    const getOptions = useCallback(() => {
        if (theme.profile.status === 'loading') {
            dispatch(getProfileOptionsAsync());
        }
        if (theme.countries.status === 'loading') {
            dispatch(getCountriesAsync());
        }
    }, [theme, dispatch]);

    useEffect(() => {
        getOptions();
        if (theme.profile.status === 'attempted' && theme.countries.status === 'attempted') {
            setProfileOptions(theme.profile.options);
            setLoading(false);
        }
    }, [theme, getOptions]);

    const onUploadProgress = (p) => {
        setContractNoteUploadProgress((p.loaded / p.total) * 100);
    };

    const handleUpload = ({ name, value, setStatus }) => {
        let formdata = new FormData();
        formdata.append(name, value);

        if (name === 'contract_note') {
            setContractNoteUploadProgress(0);

            storeEoiContractNote(formdata, match.params.uuid, onUploadProgress).then((response) => {
                toast.success(t('registrationDetails.form.editing.success'), {
                    toastId: 'contract-note-success',
                });
                setStatus('success');
                setContractNoteUploadProgress(100);
                setShowUploadContractNote(false);
            }).catch(({ response }) => {
                toast.error(response?.data?.message || t('registrationDetails.form.editing.error'), {
                    toastId: 'contract-note-error',
                });
                setStatus('api_error');
                setContractNoteUploadProgress(0);
                setTimeout(() => setShowUploadContractNote(false), 750);
            });
        } else if (name === 'certificate') {
            setCertificateUploadProgress(0);

            storeEoiCertificate(formdata, match.params.uuid, onUploadProgress).then((response) => {
                toast.success(t('registrationDetails.form.editing.success'), {
                    toastId: 'certificate-success',
                });
                setStatus('success');
                setCertificateUploadProgress(100);
                setShowCertificateUpload(false);
            }).catch(({ response }) => {
                toast.error(response?.data?.message || t('registrationDetails.form.editing.error'), {
                    toastId: 'certificate-error',
                });
                setStatus('api_error');
                setCertificateUploadProgress(0);

                setTimeout(() => setShowCertificateUpload(false), 750);
            });
        }
    };

    const handleSubmit = (values, actions) => {
        if (formState === 'complete') {
            setLoading(false);
            actions.setSubmitting(false);
            setFormState('editing');
        }else{
            const requiresTrustee1 = subtype === 'individual' || subtype === 'joint';
            setLoading(true);
            storeEoiRegistrationDetails({
                title: values.title,
                first_name: values.first_name,
                middle_names: values.middle_names,
                last_name: values.last_name,
                email: values.email,
                mobile: values.mobile,
                address: {
                    unit_number: values.unit_number,
                    street_number: values.street_number,
                    street_name: values.street_name,
                    street_type: values.street_type,
                    address2: values.address2,
                    suburb: values.suburb,
                    state: values.state,
                    postcode: values.postcode,
                    country: 'AU',
                },
                company_name: values.name,
                designation: values.designation,
                type: selected,
                subtype: subtype,
                trustee_1_title: requiresTrustee1 ? values.title : '',
                trustee_1_first_name: requiresTrustee1 ? values.first_name : '',
                trustee_1_middle_names: requiresTrustee1 ? values.middle_names : '',
                trustee_1_last_name: requiresTrustee1 ? values.last_name : '',
                trustee_2_title: values.trustee_2_title,
                trustee_2_first_name: values.trustee_2_first_name,
                trustee_2_middle_names: values.trustee_2_middle_names,
                trustee_2_last_name: values.trustee_2_last_name,
                hin_number: values.hin_number,
                has_contract_note: values.has_contract_note ? 1 : 0,
            }, match.params.uuid).then((response) => {
                toast.success(t('registrationDetails.form.editing.success'), {
                    toastId: 'personal-success',
                });
                setLoading(false);
                actions.setSubmitting(false);
                actions.setStatus('success');
                setFormState('complete');
            }).catch(({ response }) => {
                response?.data?.errors && actions.setErrors(response.data.errors);
                toast.error(response?.data?.message || t('registrationDetails.form.editing.error'), {
                    toastId: 'personal-error',
                });
                actions.setStatus('api_error');
                actions.setSubmitting(false);
                setLoading(false);
            });
        }
    };

    return (
        <Layout>
            <Container>
                {loading && <Spinner variant="styles.spinner" m={'auto'} />}

                <Box sx={{
                    display: loading ? 'none' : 'block'
                }}>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            title: '',
                            first_name: eoiUser?.first_name ?? '',
                            middle_names: '',
                            last_name: eoiUser?.last_name ?? '',
                            email: eoiUser?.email ?? '',
                            mobile: eoiUser?.mobile ?? '',
                            unit_number: '',
                            street_number: '',
                            street_name: '',
                            street_type: '',
                            address2: '',
                            suburb: '',
                            state: '',
                            postcode: '',
                            country: '',
                            name: '',
                            designation: '',
                            type: 'individual',
                            subtype: '',
                            trustee_1_title: '',
                            trustee_1_first_name: '',
                            trustee_1_middle_names: '',
                            trustee_1_last_name: '',
                            trustee_2_title: '',
                            trustee_2_first_name: '',
                            trustee_2_middle_names: '',
                            trustee_2_last_name: '',
                            hin_number: '',
                            has_contract_note: false,
                            is_sophisticated: eoiUser?.is_sophisticated ?? false,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ status, setStatus, values, setFieldValue }) => (
                            <Box>
                                { status === 'api_error' ?
                                <Alert role="alert" variant='danger' mb={2}>
                                    {t('registrationDetails.form.editing.error')}
                                    <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                                </Alert> : ''}

                                <Form>
                                    <Heading as="h1" variant="h3" sx={{ fontWeight: 500 }} mb={4}>
                                        {t(formText[formState].title)}
                                    </Heading>

                                    {formState === 'complete' && (
                                        <Text as="p" mb={3} sx={{
                                            fontSize: 2,
                                            color: 'dark'
                                        }}>
                                            {t(formText[formState].nextSteps)}
                                        </Text>
                                    )}

                                    <Text as="p" mb={3} sx={{
                                        fontSize: 2,
                                        color: 'dark'
                                    }}>
                                        {t(formText[formState].subtitle)}
                                    </Text>

                                    {formState === 'editing' && (
                                        <>
                                            <Heading as="h2" variant="form_section" sx={{
                                                fontWeight: 600,
                                                letterSpacing: '-0.01em',
                                                mb: 3,
                                            }}>
                                                {t('Your details')}
                                            </Heading>

                                            <Text as="p" variant="small" mb={3} sx={{
                                                fontSize: '13px',
                                                color: 'dark',
                                                '& a': {
                                                    color: 'tealDark'
                                                }
                                            }}>
                                                {t('registrationDetails.form.editing.uploadChess', {
                                                    holdingStatement: <Link as="a" target="_blank" href="https://www.asxonline.com/content/dam/asxonline/public/documents/manuals/chess-asx-settlement-procedure-guidelines/asx015440.pdf">CHESS holding statement</Link>
                                                })}
                                            </Text>

                                            <CheckboxField name="has_contract_note" validIndicator={false} label="Upload a CHESS holding statement" sx={{ marginBottom: 4 }} />

                                            {values.has_contract_note ? (
                                                <RegistrationDetailsUpload
                                                    uploadProgress={contractNoteUploadProgress}
                                                    setStatus={setStatus}
                                                    showUpload={showUploadContractNote}
                                                    field={{
                                                        name: 'contract_note',
                                                        label: 'CHESS holding statement',
                                                    }}
                                                    handleUpload={handleUpload}
                                                    setShowUpload={setShowUploadContractNote}
                                                    uploadName="CHESS holding statement"
                                                />
                                            ) : 
                                            (
                                                <>
                                                    <StyledAlert mb={3} isCloseable={false}>{t('registrationDetails.form.editing.legal_name')}</StyledAlert>
                                                    {profileOptions.titles.length > 0 &&
                                                        <Field
                                                            label="Title"
                                                            placeholder="Select title"
                                                            name="title"
                                                            field="searchable-select"
                                                            options={profileOptions.titles}
                                                            required={!values.has_contract_note}
                                                        />
                                                    }
                                                    <Field
                                                        label="First name"
                                                        name="first_name"
                                                        required={!values.has_contract_note}
                                                    />
                                                    <Field
                                                        label="Middle names"
                                                        name="middle_names"
                                                    />
                                                    <Field
                                                        label="Last name"
                                                        name="last_name"
                                                        required={!values.has_contract_note}
                                                    />
                                                    <Field
                                                        label="Email"
                                                        name="email"
                                                        readOnly="readonly"
                                                    />

                                                    <Field
                                                        label="Mobile"
                                                        name="mobile"
                                                        required
                                                    />

                                                    <Heading
                                                        as="h2"
                                                        variant="form_section"
                                                        sx={{
                                                            fontWeight: 600,
                                                            letterSpacing: '-0.01em',
                                                            mt: 4,
                                                            mb: 3,
                                                        }}
                                                    >
                                                        {t('Choose the type of account you would like to bid with')}
                                                    </Heading>

                                                    <Box mb={3}>
                                                        {Object.keys(ProfileMainTypes).map((key) => {
                                                            return (
                                                                <Box key={`profile${key}`} mb={1}>
                                                                    <Button
                                                                        // id should be 'profile' + capitalized key
                                                                        id={`profile${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                                                        variant="bordered"
                                                                        onClick={() => {
                                                                            setSelected(key);
                                                                            if (key === 'individual' || key === 'company') {
                                                                                setSubtype('');
                                                                            }
                                                                        }}
                                                                        sx={{
                                                                            width: '100%',
                                                                            height: '48px',
                                                                            maxWidth: '432px',
                                                                            textAlign: 'left',
                                                                            py: '12px',
                                                                            px: 3,
                                                                            borderRadius: '8px',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            borderWidth: '1px',
                                                                            fontWeight: 'bold',
                                                                            fontSize: '14px',
                                                                            gridGap: 3,
                                                                            ...(key === selected
                                                                                ? {
                                                                                    backgroundColor: (t) => t.colors.darker,
                                                                                    color: (t) => t.colors.white,
                                                                                }
                                                                                : {}),
                                                                        }}
                                                                    >
                                                                        <Icon color={key === selected ? 'tealLight' : 'medium'} icon="ellipse" size="18px" />
                                                                        <Box sx={{ flex: 1 }}>
                                                                            <Text as="p">{ProfileMainTypes[key]}</Text>
                                                                        </Box>
                                                                    </Button>
                                                                </Box>
                                                            );
                                                        })}
                                                    </Box>
                                                    
                                                    {(selected.toLowerCase().includes('super') || selected.toLowerCase().includes('trust')) && (
                                                        <>
                                                            <Heading
                                                                as="h2"
                                                                variant="form_section"
                                                                sx={{
                                                                    fontWeight: 600,
                                                                    letterSpacing: '-0.01em',
                                                                    mb: 3,
                                                                    mt: 3,
                                                                }}
                                                            >
                                                                {t('Choose the type of trustee for the account')}
                                                            </Heading>
                                                            <Grid columns={[1, '1fr 1fr 1fr']} gap={2} mb={2}>
                                                                {Object.keys(ProfileSubTypes).map((key) => {
                                                                    return (
                                                                        <Box key={`profile${key}`} mb={1}>
                                                                            <Button
                                                                                id={`subProfile${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                                                                variant="bordered"
                                                                                onClick={() => {
                                                                                    setSubtype(key);
                                                                                }}
                                                                                sx={{
                                                                                    width: '100%',
                                                                                    height: '48px',
                                                                                    maxWidth: '432px',
                                                                                    textAlign: 'left',
                                                                                    py: '12px',
                                                                                    px: 3,
                                                                                    borderRadius: '8px',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    borderWidth: '1px',
                                                                                    fontWeight: 'bold',
                                                                                    fontSize: '14px',
                                                                                    gridGap: 3,
                                                                                    ...(key === subtype
                                                                                        ? {
                                                                                            backgroundColor: (t) => t.colors.darker,
                                                                                            color: (t) => t.colors.white,
                                                                                        }
                                                                                        : {}),
                                                                                }}
                                                                            >
                                                                                <Icon color={key === subtype ? 'tealLight' : 'medium'} icon="ellipse" size="18px" />
                                                                                <Box sx={{ flex: 1 }}>
                                                                                    <Text as="p">{ProfileSubTypes[key]}</Text>
                                                                                </Box>
                                                                            </Button>
                                                                        </Box>
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </>
                                                    )}

                                                    <Field
                                                        label="HIN"
                                                        name="hin_number"
                                                        mt={3}
                                                    />
                                                    <Text as="p" variant="small" mb={4} sx={{
                                                        fontSize: '13px',
                                                        color: 'dark',
                                                        '& a': {
                                                            color: 'tealDark'
                                                        }
                                                    }}>
                                                        {t('You may nominate an external HIN if you already have one available for this entity.')}
                                                    </Text>

                                                    { selected.includes('company') && (
                                                        <Field
                                                            label="Company Name"
                                                            name="name"
                                                            required={!values.has_contract_note}
                                                        />
                                                    )}

                                                    { (selected.includes('trust') || selected.includes('superannuation')) && (
                                                        <>
                                                            <Box sx={{ position: 'relative' }}>
                                                                <Field
                                                                    label={`Name of ${selected === 'trust' ? 'Trust' : 'Fund'}`}
                                                                    name="designation"
                                                                    description="eg. Petersen Family A/C"
                                                                />
                                                                <Box sx={{
                                                                    position: 'absolute',
                                                                    height: '70px',
                                                                    top: 0,
                                                                    right: 3,
                                                                    pointerEvents: 'none',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <Text>A/C</Text>
                                                                </Box>
                                                            </Box>
                                                        </>
                                                    )}

                                                    {subtype.includes('company') && (
                                                        <Field
                                                            label="Trustee (Company Name)"
                                                            name="name"
                                                            required={!values.has_contract_note}
                                                        />
                                                    )}

                                                    {subtype.includes('joint') && (
                                                        <>
                                                            <Heading as="h2" variant="form_section" mb={3} sx={{
                                                                fontWeight: 700,
                                                                fontSize: 2
                                                            }}>
                                                                {t('Additional Trustee (not yourself)')}
                                                            </Heading>
                                                            <Grid
                                                                sx={{
                                                                    gridGap: [0, 2],
                                                                    gridTemplateColumns: ['1fr', '1fr 2fr'],
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                {profileOptions.titles.length > 0 && <Field label="Title" id="trustee_2_title" classNamePrefix='list' placeholder="Select title" name="trustee_2_title" field="searchable-select" options={profileOptions.titles} />}
                                                                <Field label="Trustee 2 First Name" name="trustee_2_first_name" />
                                                            </Grid>
                                                            <Field label="Trustee 2 Middle Names" name="trustee_2_middle_names" />
                                                            <Field label="Trustee 2 Last Name" name="trustee_2_last_name" />
                                                        </>

                                                    )}

                                                    <Heading
                                                        as="h2"
                                                        variant="form_section"
                                                        mb={3}
                                                        mt={4}
                                                        sx={{
                                                            fontWeight: 700,
                                                            fontSize: 2,
                                                        }}
                                                    >
                                                        {t('Entity Address')}
                                                    </Heading>
                                                    
                                                    <AddressFields setFieldValue={setFieldValue} />
                                                    
                                                    <RegistrationDetailsCard type={selected} subtype={subtype} values={values} />
                                                </>
                                            )}

                                            <Box bg='light' p={3} my={4} sx={{ borderRadius: '12px' }}>
                                                <Heading as="h2" variant="form_section" sx={{
                                                    fontWeight: 600,
                                                    letterSpacing: '-0.01em',
                                                    mb: 3,
                                                }}>
                                                    {t('s708 Certificate')}
                                                </Heading>

                                                <Paragraph mb={3} sx={{
                                                    fontSize: 2,
                                                    color: 'dark'
                                                }}>
                                                    {eoiUser?.deal_is_ipo ? t('registrationDetails.form.editing.certificateIpo') : t('registrationDetails.form.editing.certificate')}
                                                </Paragraph>

                                                <CheckboxField name="is_sophisticated" validIndicator={false} label="I am a Sophisticated Investor" sx={{ marginBottom: 3 }} />
                                                {values.is_sophisticated && theme.settings.certificate_template ? (
                                                    <Box mb={2} sx={{
                                                        color: 'dark',
                                                        fontSize: '13px',
                                                        '& a': {
                                                            color: 'tealDark'
                                                        }
                                                    }}>
                                                        {t('Need help?')} <Link as="a" href={theme.settings.certificate_template} color="tealDark" target="_blank" variant="light">{t('Download and complete our certificate template')}</Link>
                                                    </Box>
                                                ): null}
                                                {values.is_sophisticated ? (
                                                    <RegistrationDetailsUpload
                                                        uploadProgress={certificateUploadProgress}
                                                        setStatus={setStatus}
                                                        showUpload={showCertificateUpload}
                                                        field={{
                                                            name: 'certificate',
                                                            label: 's708 Certificate',
                                                        }}
                                                        handleUpload={handleUpload}
                                                        setShowUpload={setShowCertificateUpload}
                                                        uploadName="s708 Certificate"
                                                    />
                                                ) : null}

                                            </Box>
                                            
                                        </>
                                    )}


                                    <Submit variant="primary" id="registrationDetailsSubmit" text={t(formText[formState].submit)} sx={{ display: 'block', width: '100%', mt: '40px' }} />
                                </Form>
                            </Box>
                        )}
                    </Formik>
                </Box>
            </Container>
        </Layout>
    )
}

export default connect(mapStateToProps, null)(RegistrationDetailsForm);