import { useEffect, useRef, useState } from 'react'
import { SettingsLayout } from '~/Layouts';
import {
    InviteForm as AdviserInviteForm,
    List as Advisers
}
from '~/Advisers';
import {
    Box,
    Heading,
    Spinner,
    Text
} from '@theme-ui/components';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group'
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const SettingsAdvisers = ({ advisers, advisersLoaded }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('My Advisers'));
    const [loading, setLoading] = useState(true);
    const nodeRef = useRef(null);

    useEffect(() => {
        if(advisersLoaded){
            setLoading(false);
        }
    }, [advisersLoaded]);

    return (
        <SettingsLayout>
            <CSSTransition
                nodeRef={nodeRef}
                in={true}
                timeout={500}
                classNames="fade"
                unmountOnExit
                appear
            >
                <Box ref={nodeRef}>
                    <Heading as="h1" variant="h2" mb={4}>
                        {t('My Advisers')}
                    </Heading>

                    <Box py={[4, 5]} px={[3, 4, 6]} mb={4} sx={{
                        bg: 'light',
                        borderRadius: '16px',
                        textAlign: 'center'
                    }}>
                        <Heading as="h2" variant="h2" mb={4}>{t('Give someone adviser access')}</Heading>
                        <Text as="p" mb={4}>{t('You can now invite an accountant or broker, that we term adviser, which will have access to help manage account information and place bids on your behalf.')}</Text>
                        <AdviserInviteForm setLoading={setLoading} />
                    </Box>

                    {loading ? <Box id="settingsLoading"><Spinner variant="styles.spinner" m={'auto'} /></Box> : (
                        <Advisers advisers={advisers} />
                    )}
                </Box>
            </CSSTransition>
        </SettingsLayout>
    );
};

export default connect((state) => {
    return {
        advisers: state.user?.user?.advisers,
        advisersLoaded: state.user?.user?.advisers_status === undefined || state.user?.user?.advisers_status === 'idle'
    }
}, null)(SettingsAdvisers);
