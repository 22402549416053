import { useCallback, useEffect, useRef, useState } from 'react'
import {
    Box,
    Flex,
    Heading,
    Link,
    NavLink,
    Spinner,
    Text
} from 'theme-ui'
import { Container } from '~/Common/Container'
import { Layout } from '~/Layouts';
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment-timezone'
import { DATE_FORMAT } from 'app/constants'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown'
import { useResponsiveValue } from '@theme-ui/match-media'
import { CSSTransition } from 'react-transition-group'
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { user: state.user?.user, settings: state.theme?.settings }
}

const Policies = ({ user, settings }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Policies'));
    const { hash } = useLocation();
    const nodeRef = useRef(null);
    const loyaltyRef = useRef();
    const privacyRef = useRef();
    const termsofuseRef = useRef();
    const [policy, setPolicy] = useState(null);
    const isFullSize = useResponsiveValue([false, false, false, false, false, true]);
    const isMobile = useResponsiveValue([true, true, false]);
    const [loading, setLoading] = useState(true);
    const [internalLoading, setInternalLoading] = useState(true);
    const history = useHistory();

    const loadPolicy = useCallback((hash) => {
        if (user?.can_see_dashboard){
            switch (hash) {
                case 'loyalty': history.push('/loyalty-terms'); break;
                case 'termsofuse': history.push('/terms'); break;
                default:
                case 'privacy':
                    history.push('/privacy-policy');
                    break;
            }
        }

        switch (hash) {
            case 'loyalty':
            case 'privacy':
            case 'termsofuse':
                setPolicy(hash);
            break;

            default:
                setPolicy('privacy');
            break;
        }
        setTimeout(() => {
            setLoading(false);
            setInternalLoading(false);
        }, 500);
    }, [user, history]);

    const handleNavClick = (e, id) => {
        setInternalLoading(true);
        loadPolicy(id);
    };

    useEffect(() => {
        setTimeout(() => {
            const id = hash.replace('#', '');
            loadPolicy(id);
        }, 750);
    }, [hash, loadPolicy]);

    if (user?.timezone){
        moment.tz.setDefault(user?.timezone);
    }

    return (
        <Layout>
            <Container
                pt={5}
                sx={{
                    borderTop: isMobile ? '1px solid' : null,
                    borderColor: isMobile ? 'light' : null,
                }}
            >
                {loading ? (
                    <Flex sx={{
                        flexDirection: 'column'
                    }}>
                        <Spinner variant="styles.spinner" mx={'auto'} my={6} />
                    </Flex>
                ) : (
                <>
                    <Heading as="h1" mb={4}>{t('Policies')}</Heading>

                    <Box mb={4} sx={{
                        position: !isFullSize ? 'relative' : 'absolute',
                        right: !isFullSize ? 'auto' : '90%',
                        width: !isFullSize ? 'auto' : '230px'
                    }}>
                        <NavLink href="#privacy" variant="policies" className={policy === 'privacy' ? 'active' : null} onClick={(e) => handleNavClick(e, 'privacy')}>
                            {t('Privacy Policy')}
                        </NavLink>
                        <NavLink href="#termsofuse" variant="policies" className={policy === 'termsofuse' ? 'active' : null} onClick={(e) => handleNavClick(e, 'termsofuse')}>
                            {t('Terms Of Use')}
                        </NavLink>
                        <NavLink href="#loyalty" variant="policies" className={policy === 'loyalty' ? 'active' : null} onClick={(e) => handleNavClick(e, 'loyalty')}>
                            {t('Liquidity Loyalty Terms')}
                        </NavLink>
                    </Box>

                    <CSSTransition
                        nodeRef={nodeRef}
                        in={true}
                        timeout={500}
                        classNames="fade"
                        unmountOnExit
                        appear
                    >
                        {internalLoading ? (
                            <Flex sx={{
                                flexDirection: 'column'
                            }}>
                                <Spinner variant="styles.spinner" mx={'auto'} my={6} />
                            </Flex>
                        ) : (
                            <div ref={nodeRef}>
                                {policy === 'privacy' && (
                                    <Box sx={{ variant: 'styles' }} ref={privacyRef}>
                                        <ReactMarkdown>{settings?.privacy_policy}</ReactMarkdown>
                                    </Box>
                                )}

                                {policy === 'termsofuse' && (
                                    <Box sx={{ variant: 'styles' }} ref={termsofuseRef}>
                                        <ReactMarkdown>{settings?.terms_of_use}</ReactMarkdown>
                                    </Box>
                                )}

                                {policy === 'loyalty' && (
                                    <Box sx={{ variant: 'styles' }} ref={loyaltyRef}>
                                        <ReactMarkdown>{settings?.loyalty_terms}</ReactMarkdown>
                                    </Box>
                                )}
                            </div>
                        )}
                    </CSSTransition>

                    {settings?.updated_at && <Text as="p" mt={4} variant="caption">Last updated: {moment(settings?.updated_at).format(DATE_FORMAT.last_updated)}</Text>}

                    <Box mt={4} sx={{
                        display: 'flex',
                        flexDirection: ['column', 'column', 'row']
                    }}>

                        {user?.onboarding ? (
                            <Link href="/onboarding" ml={3}>
                                {t('Back to onboarding')}
                            </Link>
                        ) : (
                            <Link href="/">
                                {t('Back to home')}
                            </Link>
                        )}
                    </Box>
                </>)}
            </Container>
        </Layout>
    );
};


export default connect(mapStateToProps, null)(Policies);
