import { storeAdviserInviteUser } from "features/advisers/advisersAPI";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { Form, Formik } from 'formik';
import { toast } from 'utils/toast';
import * as Yup from 'yup';
import {
    Button,
} from '~/Common';
import {
    Alert,
    Box,
    Close,
    Grid,
    Heading,
    Text
} from '@theme-ui/components';
import { grabFirstError } from "utils/helpers";
import { CSSTransition } from 'react-transition-group'
import { setInvites } from "features/invites/invitesSlice";

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email().required('Email is required'),
});

export const InviteUserForm = ({ setLoading }) => {
    const { t } = useTranslation();
    const [showForm, setShowForm] = useState(false);
    const dispatch = useDispatch();
    const nodeRef = useRef(null);

    const handleSubmit = (values, actions) => {
        setLoading(true);
        storeAdviserInviteUser({
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email
        }).then((response) => {
            toast.success(response?.message || t('advisers.inviteUser.success'), {
                toastId: 'invite-success',
            });
            dispatch(setInvites(response));
            setShowForm(false);
            actions.setStatus('success');
            actions.setSubmitting(false);
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error((grabFirstError(response?.data?.errors) || response?.data?.message) || t('advisers.inviteUser.error'), {
                toastId: 'invite-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        }).finally(() => setLoading(false));
    };

    return (showForm ?
        <>
            <CSSTransition
                nodeRef={nodeRef}
                in={true}
                timeout={500}
                classNames="fade"
                unmountOnExit
                appear
            >
                <Box ref={nodeRef}>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            email: '',
                            first_name: '',
                            last_name: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ status, setStatus, setFieldValue }) => (
                            <Box id="adviserUserInviteForm" py={[4, 5]} px={[3, 4, 6]} mb={4} sx={{
                                bg: 'light',
                                borderRadius: '16px',
                                textAlign: 'center'
                            }}>
                                <Heading as="h2" variant="h2" mb={4}>{t('Invite an investor')}</Heading>
                                <Text as="p" mb={4}>{t('You can invite users to manage their account and bid on their behalf.')}</Text>

                                {status === 'api_error' ?
                                    <Alert role="alert" variant='danger' mb={2}>
                                        {t('advisers.inviteUser.error')}
                                        <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                                    </Alert> : ''}

                                <Form>
                                    <Grid mb={3} sx={{
                                        gridGap: 2,
                                        gridTemplateColumns: ['1fr'],
                                        width: '100%',
                                        maxWidth: '430px',
                                        margin: '0 auto'
                                    }}>
                                        <Field
                                            label="First Name"
                                            name="first_name"
                                        />
                                        <Field
                                            label="Last Name"
                                            name="last_name"
                                        />
                                        <Field
                                            label="Email"
                                            name="email"
                                        />
                                    </Grid>

                                    <Submit variant="primary" id="adviserUserInviteSubmit" text={t('buttons.submit')} mr={2} />

                                    <Button variant="light" onClick={() => setShowForm(false)}>
                                        {t('Cancel')}
                                    </Button>
                                </Form>
                            </Box>
                        )}
                    </Formik>
                </Box>
            </CSSTransition>
        </> : <>
            <Button variant="primary" id="showAdviserUserInviteForm" onClick={() => setShowForm(true)}>
                {t('buttons.invite')}
            </Button>
        </>
    )
};
