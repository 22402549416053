import React from 'react';
import { GuestRoute, TwoFactorRoute, OnboardingRoute, PrivateRoute, SharedPrivateRoute, AdviserRoute, TestingRoute, AcceptanceLetterRoute } from './routeTypes';
import { Route, Switch } from 'react-router-dom';
import {
    AdviserDashboard,
    AdviserDealsView,
    AdviserEmailVerify
} from 'pages/Adviser';
import {
    DealsDashboard,
    DealsView,
    DealAcceptance,
    EoiAcceptance
} from 'pages/Deals';
import {
    CompaniesDashboard,
    CompanyView
} from 'pages/Companies';
import {
    Contact,
    Demo,
    Icons,
    ForgotPassword,
    Login,
    LoginTwoFactorChallenge,
    Logout,
    LoyaltyTerms,
    NotFound,
    OneTimeLogin,
    PasswordReset,
    Policies,
    Privacy,
    Search,
    Terms,
    Trust
} from 'pages/Common';
import { OnboardingLanding } from 'pages/Onboarding';
import { PortfolioDashboard } from 'pages/Portfolio';
import { WatchlistDashboard } from 'pages/Watchlist';
import { ArticleView, GrafaNews } from 'pages/News';
import { CoinsDashboard } from 'pages/Coins';
import {
    SettingsAdvisers,
    SettingsAccount,
    SettingsGetRewards,
    SettingsInviteApproval,
    SettingsNotifications,
    SettingsPreferences,
    SettingsProfiles,
    SettingsProfilesCreate,
    SettingsProfilesConnectedHins,
    SettingsProfileCertificate
} from 'pages/Settings';
import { RegistrationDetailsForm } from 'pages/RegistrationDetails';
import { ScrollToTop } from '~/Common'
import { APP_FEATURES } from './constants';

const Routes = () => {
    return (
        <ScrollToTop>
        <Switch>
            <GuestRoute path="/" component={Login} exact />
            <GuestRoute path="/forgot-password" component={ForgotPassword} />
            <GuestRoute path="/registration-details/:uuid" component={RegistrationDetailsForm} />
            <GuestRoute path="/eoi/acceptance/:uuid" component={EoiAcceptance} />
            <Route path="/reset-password/:token" component={PasswordReset} />
            <Route path="/otl/:token" component={OneTimeLogin} />
            <OnboardingRoute path="/onboarding" component={OnboardingLanding} exact />
            <OnboardingRoute path="/onboarding/:token" component={OnboardingLanding} />
            <TwoFactorRoute path="/two-factor-challenge" component={LoginTwoFactorChallenge} exact />
            <PrivateRoute path="/coins" component={CoinsDashboard} exact />
            <PrivateRoute path="/portfolio" component={PortfolioDashboard} exact />
            {APP_FEATURES.peer_invites ? <PrivateRoute path="/settings/get-rewards" component={SettingsGetRewards} exact />: null}
            {APP_FEATURES.adviser_invites ? <PrivateRoute path="/settings/advisers" component={SettingsAdvisers} exact /> : null}
            <PrivateRoute path="/settings/entities" component={SettingsProfiles} exact />
            <PrivateRoute path="/settings/entities/create" component={SettingsProfilesCreate} exact />
            <PrivateRoute path="/settings/entities/:uuid" component={SettingsProfilesCreate} />
            <PrivateRoute path="/settings/connected-hins/:uuid" component={SettingsProfilesConnectedHins} />
            <PrivateRoute path="/settings/certificate/:uuid" component={SettingsProfileCertificate} />
            <PrivateRoute path="/invite/:uuid" component={SettingsInviteApproval} />
            <AcceptanceLetterRoute path="/acceptance/:token" component={DealAcceptance} />
            <SharedPrivateRoute path="/contact" component={Contact} exact />
            <SharedPrivateRoute path="/companies" component={CompaniesDashboard} exact />
            <SharedPrivateRoute path="/companies/:slug" component={CompanyView} exact />
            <SharedPrivateRoute path="/deals" component={DealsDashboard} exact />
            <SharedPrivateRoute path="/deals/:slug" component={DealsView} />
            <SharedPrivateRoute path="/articles/:slug" component={ArticleView} />
            <SharedPrivateRoute path="/privacy-policy" component={Privacy} exact />
            <SharedPrivateRoute path="/search" component={Search} />
            <SharedPrivateRoute path="/settings/account" component={SettingsAccount} exact />
            <SharedPrivateRoute path="/settings/notifications" component={SettingsNotifications} exact />
            <SharedPrivateRoute path="/settings/preferences" component={SettingsPreferences} exact />
            <SharedPrivateRoute path="/terms" component={Terms} exact />
            <SharedPrivateRoute path="/loyalty-terms" component={LoyaltyTerms} exact />
            <SharedPrivateRoute path="/trust-and-safety" component={Trust} exact />
            <SharedPrivateRoute path="/following" component={WatchlistDashboard} exact />
            {APP_FEATURES.news ? <SharedPrivateRoute path="/news" component={GrafaNews} exact /> : null}
            <AdviserRoute path="/adviser/dashboard" component={AdviserDashboard} />
            <AdviserRoute path="/adviser/email-verify" component={AdviserEmailVerify} />
            <AdviserRoute path="/adviser/deals/:slug" component={AdviserDealsView} />
            <Route path="/policies" component={Policies} />
            <Route path="/logout" component={Logout} exact />
            <TestingRoute exact={true} path="/demo" component={Demo} />
            <TestingRoute path="/demo/icons" component={Icons} />

            <Route
                path="/:url"
                component={({
                    match: {
                        params: { url },
                    },
                }) => <NotFound pageUrl={url} />}
            />
        </Switch>
        </ScrollToTop>
    );
};

export default Routes;
