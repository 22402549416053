import {
    AsxAnnouncements,
    BoardMembers,
    Disclaimer,
    Downloads,
    ImageGallery,
    HighlightCards,
    OfferDetails,
    Quote,
    Table,
    TextAndImage,
    Text,
    LabelAndValues,
    Video
} from './'
import { Box } from '@theme-ui/components'
import _camelCase from 'lodash.camelcase';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { Prospectus } from './Prospectus';

export const ContentBlock = ({ type, data, blockKey, live_at, ...props }) => {
    const id = data?.menu_title ? `${_camelCase(data.menu_title)}${blockKey}`
    : (data?.title ? `${_camelCase(data.title)}${blockKey}` : `section${blockKey}`);
    const user = useSelector((state) => state.user?.user);

    moment.tz.setDefault(user?.timezone);
    const liveAt = moment(live_at).utc();
    if(!moment().isAfter(liveAt)){
        return <></>;
    }

    const renderBlock = (type) => {
        switch (type.toLowerCase()) {
            case 'boardmembers': return <BoardMembers data={data} {...props} />;
            case 'disclaimer': return <Disclaimer data={data} {...props} />;
            case 'downloads': return <Downloads data={data} {...props} />;
            case 'imagegallery': return <ImageGallery data={data} {...props} />;
            case 'highlightcards': return <HighlightCards data={data} {...props} />;
            case 'offerdetails': return <OfferDetails data={data} {...props} />;
            case 'quote': return <Quote data={data} {...props} />;
            case 'table': return <Table data={data} {...props} />;
            case 'textandimage': return <TextAndImage data={data} {...props} />;
            case 'text': return <Text data={data} {...props} />;
            case 'labelandvalues': return <LabelAndValues data={data} {...props} />;
            case 'video': return <Video data={data} {...props} />;
            case 'asxannouncements': return <AsxAnnouncements data={data} {...props} />;
            case 'prospectus': return <Prospectus data={data} {...props} />;
            default: return <></>;
        }
    }

    return (
        <Box className={`content-block content-block--${type.toLowerCase()}`} id={id}>
            {renderBlock(type)}
        </Box>
    );
};
