export const useDisplayName = (asxEntity) => {
    let listingSuffix = '';
    if (asxEntity?.asx) {
        listingSuffix = ` (ASX:${asxEntity?.asx})`;
    } else if (asxEntity?.listed_on_alternate_exchange) {
        if (asxEntity?.alternate_exchange && asxEntity?.alternate_security_code) {
            listingSuffix = ` (${asxEntity?.alternate_exchange}:${asxEntity?.alternate_security_code})`;
        }
    }
    return `${asxEntity?.name}${listingSuffix}`;
}