import {
    Box,
    Grid
} from '@theme-ui/components'
import { Container } from '~/Common';

export const SaveButtons = ({ children, sx, ...props }) => {

    return (
        <Box {...props} sx={{
            position: ['relative', 'relative', 'fixed'],
            bottom: 0,
            left: 0,
            right: 0,
            background: 'white',
            py: '20px',
            textAlign: 'center',
            zIndex: 9999,
            boxShadow: ['none', null, '0px 8px 20px rgba(0, 0, 0, 0.06)'],
            ...sx
        }}>
            <Container sx={{ px: 0 }}>
                <Grid sx={{
                    display: 'inline-grid',
                    width: '100%',
                    gridTemplate: ['auto / 1fr', null, `auto / repeat(${children.length}, 1fr)`],
                    gridGap: 3,
                    maxWidth: ['100%', null, '650px'],
                    '& > *, .tooltip__container button': {
                        width: '100% !important'
                    }
                }}>
                    {children}
                </Grid>
            </Container>
        </Box>
    );
};
