import { storeAdviserInvite } from "features/advisers/advisersAPI";
import { setAdvisers } from "features/auth/authSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { Form, Formik } from 'formik';
import { toast } from 'utils/toast';
import * as Yup from 'yup';
import {
    Button,
} from '~/Common';
import {
    Alert,
    Box,
    Close,
    Grid
} from '@theme-ui/components';
import { grabFirstError } from "utils/helpers";

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email().required('Email is required'),
});

export const InviteForm = ({ setLoading }) => {
    const { t } = useTranslation();
    const [showForm, setShowForm] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = (values, actions) => {
        setLoading(true);
        storeAdviserInvite({
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email
        }).then((response) => {
            toast.success(response?.message || t('advisers.invite.success'), {
                toastId: 'invite-success',
            });
            dispatch(setAdvisers(response));
            setShowForm(false);
            actions.setStatus('success');
            actions.setSubmitting(false);
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error((grabFirstError(response?.data?.errors) || response?.data?.message) || t('advisers.invite.error'), {
                toastId: 'invite-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        }).finally(() => setLoading(false));
    };

    return (showForm ?
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    email: '',
                    first_name: '',
                    last_name: ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ status, setStatus, setFieldValue }) => (
                    <Box id="adviserInviteForm">
                        {status === 'api_error' ?
                            <Alert role="alert" variant='danger' mb={2}>
                                {t('advisers.invite.error')}
                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                            </Alert> : ''}

                        <Form>
                            <Grid mb={3} sx={{
                                gridGap: 2,
                                gridTemplateColumns: ['1fr'],
                                width: '100%',
                                maxWidth: '430px',
                                margin: '0 auto'
                            }}>
                                <Field
                                    label="First Name"
                                    name="first_name"
                                />
                                <Field
                                    label="Last Name"
                                    name="last_name"
                                />
                                <Field
                                    label="Email"
                                    name="email"
                                />
                            </Grid>

                            <Submit variant="primary" id="adviserInviteSubmit" text={t('buttons.submit')} mr={2} />

                            <Button variant="light" onClick={() => setShowForm(false)}>
                                {t('Cancel')}
                            </Button>
                        </Form>
                    </Box>
                )}
            </Formik>
        </> : <>
            <Button variant="primary" id="showAdviserInviteForm" onClick={() => setShowForm(true)}>
                {t('buttons.inviteAdviser')}
            </Button>
        </>
    )
};
