import {
    AspectRatio,
    Box,
    Grid,
} from '@theme-ui/components'
import { Container, PageBanner, PageHeader, Skeleton } from "~/Common";

export const CompanyViewSkeleton = () => {
    return <>
        <PageBanner>
            <PageHeader sx={{ position: 'relative' }}>
                <Container variant="layouts.default" sx={{
                    maxWidth: ['812px', '812px', '812px', '1392px']
                }}>
                    <Grid sx={{
                        position: 'relative',
                        gridGap: [4, 4, 4, 5],
                        gridTemplateColumns: ['1fr', null, null, '2fr 3fr', 'auto 780px'],
                        width: '100%'
                    }}>
                        <Box sx={{ position: 'relative', order: [2, 2, 2, 0] }}>
                            <Skeleton variant="circle" size="70px" mb={3} />

                            <Box mb={4}>
                                <Skeleton height="64px" width="80%" mb={3} />
                                <Skeleton height="14px" width="100%" mb={2} />
                                <Skeleton height="14px" width="100%" mb={2} />
                            </Box>

                            <Box mt={5}>
                                <Grid sx={{
                                    gridTemplate: 'auto / repeat(2, 1fr)'
                                }}>
                                    <Skeleton variant="button" height="60px" mb={3} />
                                    <Skeleton variant="button" height="60px" mb={3} />
                                </Grid>
                            </Box>
                        </Box>
                        <Box sx={{
                            maxWidth: ['none', 'none', 'none', '820px'],
                            position: 'relative',
                            order: [0, 0, 0, 2]
                        }}>
                            <AspectRatio
                                ratio={16 / 9}
                                sx={{
                                    borderRadius: '32px',
                                    overflow: 'hidden',
                                }}
                            >
                                <Skeleton height="100%" />
                            </AspectRatio>
                        </Box>
                    </Grid>
                </Container>
            </PageHeader>
        </PageBanner>

        <Container variant="layouts.default" sx={{
            maxWidth: ['812px', '812px', '812px', '1392px']
        }}>
            <Grid sx={{
                position: 'relative',
                gridGap: [1, 1, 1, 5],
                gridTemplateColumns: ['1fr', '1fr', '1fr', '300px auto', 'auto 780px'],
                width: '100%'
            }}>
                <Box sx={{ position: 'relative' }}>
                    <Skeleton height="48px" width="80%" mb={2} />
                    <Skeleton height="48px" width="80%" mb={2} />
                    <Skeleton height="48px" width="80%" mb={2} />
                </Box>
                <Box>
                    <Box mb={4}>
                        <Skeleton height="48px" width="30%" mb={3} />

                        <Skeleton height="14px" mb={2} />
                        <Skeleton height="14px" mb={2} />
                    </Box>

                    <Box mb={4}>
                        <Skeleton height="48px" width="30%" mb={3} />

                        <Skeleton height="14px" mb={2} />
                        <Skeleton height="14px" mb={2} />
                    </Box>
                </Box>
            </Grid>
        </Container>
    </>;
};
