import { toast as reactToast } from 'react-toastify';
import { Box, Heading, Text } from 'theme-ui';
import { Icon } from 'assets/Icon';
import { Circle } from '~/Common/Circle';

const CloseButton = (props) => (
    <Circle
        type="box"
        size="20px"
        sx={{
            position: 'absolute',
            top: '-10px',
            left: '-5px',
            bg: '#fff',
            boxShadow: '0px 0px 12px rgba(44, 41, 96, 0.08)'
        }}
    >
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7L7 1M1 1L7 7" stroke="#6A7175" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </Circle>
)

const ToastContent = ({
    children,
    options: {
        heading
    }
}) => (
    <Box>
        {heading && <Heading variant="h6" sx={{ fontWeight: 600, fontSize: '14px' }}>{heading}</Heading>}
        <Text>{children}</Text>
    </Box>
)

const success = (text, options) => {
    return reactToast.success(
        <ToastContent options={options}>{text}</ToastContent>,
        {
            icon: () => <Icon icon="check" color="tealDark" size="36px" />,
            closeButton: CloseButton,
            ...options
        }
    )
},
error = (text, options) => {
    return reactToast.error(
        <ToastContent options={options}>{text}</ToastContent>,
        {
            icon: () => <Icon icon="error" color="error" size="36px" />,
            closeButton: CloseButton,
            ...options
        }
    )
},
info = (text, options) => {
    return reactToast.info(
        <ToastContent options={options}>{text}</ToastContent>,
        {
            closeButton: CloseButton,
            ...options
        }
    )
},
warn = (text, options) => {
    return reactToast.warn(
        <ToastContent options={options}>{text}</ToastContent>,
        {
            closeButton: CloseButton,
            ...options
        }
    )
}

export const toast = {
    success,
    error,
    info,
    warn,
    dismiss: reactToast.dismiss,
    isActive: reactToast.isActive,
    update: reactToast.update,
    clearWaitingQueue: reactToast.clearWaitingQueue,
    done: reactToast.done
}
