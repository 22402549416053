import {
    Box,
    Grid,
    Heading,
    Link
} from '@theme-ui/components'
import { Button, Container, Markdown } from '~/Common'
import { Icon } from 'assets/Icon'
import { useTranslation } from 'react-i18next'

export const Downloads = ({ bg = 'white', data, container, ...props }) => {
    const { t } = useTranslation();
    const title = data?.title ?? null;
    const description = data?.description ?? null;
    const downloads = data?.downloads ?? null;

    return (
        <Box bg={bg} {...props}>
            <Container variant="layouts.section" {...container}>
                { title && <Heading as="h2" variant="content_block_heading">{t(title)}</Heading>}
                { description && <Box className="markdown" mb={[4,4,5]}>
                    <Markdown externalLinks={true}>{description}</Markdown>
                </Box>}

                {downloads && (
                    <>
                        <Grid sx={{
                            gridGap: 3,
                            gridTemplateColumns: ['repeat(1fr)'],
                            width: '100%',
                            mb: [4,4,5]
                        }}>
                            {downloads.map((download, key) => (
                                <Box key={`download${key}`}>
                                    { download?.download && (
                                        <Button
                                            as={Link}
                                            href={download.download}
                                            variant="bordered"
                                            className="btn"
                                            sx={{
                                                width: '100%',
                                                display: 'inline-flex',
                                                justifyContent: 'flex-start',
                                                lineHeight: '47px',
                                                borderWidth: '1px',
                                            }}
                                            target="_blank"
                                        >
                                            <Box as='span' sx={{overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '4px'}}>{download?.label ?? 'Download'}</Box> <Icon icon="download" ml={'auto'} sx={{ '& path': { transition: 'none' } }} />
                                        </Button>
                                    ) }
                                </Box>
                            ))}
                        </Grid>
                    </>
                    
                )}
            </Container>
        </Box>
    )
};
