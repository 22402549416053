import { useState, useEffect } from 'react'
import {
    Box,
    Card,
    Embed,
    Heading,
    AspectRatio
} from '@theme-ui/components'
import { Container, Markdown } from '~/Common'
import { useTranslation } from 'react-i18next'
import { getVideoEmbed } from 'utils/helpers'

export const Video = ({ bg = 'white', data, sx, container, ...props }) => {
    const { t } = useTranslation();
    const [embed, setEmbed] = useState(null);
    const title = data?.title ?? null;
    const video = data?.video ?? null;
    const caption = data?.caption ?? null;

    useEffect(() => {
        if(video){
            getVideoEmbed(video).then((src) => setEmbed(src));
        }
    }, [video]);

    return embed !== null && (
        <Box bg={bg} {...props} sx={{
            position: 'relative',
            ...sx
        }}>
            <Container variant="layouts.section" sx={{ position: 'relative', zIndex: 10 }} {...container}>
                { title && <Heading as="h2" variant="content_block_heading">{t(title)}</Heading>}

                <Card variant="video">
                    <AspectRatio ratio={ 16 / 9 }>
                        <Embed src={embed} />
                    </AspectRatio>

                    { caption && <Box className="markdown" mt={4}>
                        <Markdown>{caption}</Markdown>
                    </Box>}
                </Card>
            </Container>
        </Box>
    )
};
