import axios from 'axios'

export const getCompanies = (params) =>
    axios.get('companies', params)
        .then(({ data }) => data)

export const getCompanyView = (slug) =>
    axios.get(`companies/${slug}`)
        .then(({ data }) => data)

export const getCompanyNews = (slug, params) =>
    axios.get(`companies/${slug}/articles`, params)
        .then(({ data }) => data)
