import moment from 'moment';
import { Link } from 'react-router-dom';
import { Box, Flex, Grid, Image } from 'theme-ui';
import { DATE_FORMAT } from 'app/constants';

export const ArticleRow = ({ article, sx, ...props }) => (
    <Grid
        as={Link}
        to={article.is_external ? {pathname: article.external_url} : `/articles/${article.slug}`}
        sx={{
            gridTemplateColumns: 'auto 104px',
            gridGap: '64px',
            py: 3,
            borderBottom: '1px solid #D5D9DA',
            color: '#00000A',
            textDecoration: 'none',
            '&::after': { display: 'none' },
            ...sx
        }}
        target={article.is_external ? '_blank' : null}
        {...props}
    >
        <Flex sx={{ flexDirection: 'column', gridGap: '5px' }}>
            <Box><Box className="link-title">{article.name}</Box></Box>
            <Box sx={{ fontSize: '14px', color: '#6A7175' }}>
                {moment(article.date).format(DATE_FORMAT.date_format_friendly)}
                {article.reading_time_human && ` | ${article.reading_time_human} read time`}
            </Box>
        </Flex>
        {article?.image?.sizes?.thumbnail ? <Image src={article?.image?.sizes?.thumbnail} sx={{ borderRadius: '8px', overflow: 'hidden', marginY: 'auto' }} /> : null}
    </Grid>
)
