import {
    Box,
    Grid,
    Heading,
    Link
} from '@theme-ui/components'
import { Button, Container } from '~/Common'
import { Icon } from 'assets/Icon'
import { useTranslation } from 'react-i18next'

export const Prospectus = ({ bg = 'white', data, container, ...props }) => {
    const { t } = useTranslation();
    const title = data?.title ?? null;
    const download = data?.prospectus?.is_external_link ? data.prospectus?.external_link ?? null : data.prospectus?.download ?? null;

    return (
        <Box bg={bg} {...props}>
            <Container variant="layouts.section" {...container}>
                { title && <Heading as="h2" variant="content_block_heading">{t(title)}</Heading>}

                {download && (
                    <>
                        <Grid sx={{
                            gridGap: 3,
                            gridTemplateColumns: ['repeat(1fr)'],
                            width: '100%',
                            mb: [4,4,5]
                        }}>
                                <Box>
                                    <Button
                                        as={Link}
                                        href={download}
                                        variant="bordered"
                                        className="btn"
                                        sx={{
                                            width: '100%',
                                            display: 'inline-flex',
                                            justifyContent: 'flex-start',
                                            lineHeight: '47px',
                                            borderWidth: '1px',
                                        }}
                                        target="_blank"
                                    >
                                        <Box as='span' sx={{overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '4px'}}>{data.prospectus?.label ?? 'Download'}</Box> <Icon icon="download" ml={'auto'} sx={{ '& path': { transition: 'none' } }} />
                                    </Button>
                                </Box>
                        </Grid>
                    </>
                    
                )}
            </Container>
        </Box>
    )
};
