import {
    Box,
    Grid,
    Heading,
    Image,
    Text,
    Link
} from '@theme-ui/components'
import { Circle, Container, Markdown } from '~/Common'
import { useTranslation } from 'react-i18next'
import { Icon } from 'assets/Icon';

export const BoardMembers = ({ bg = 'white', data, container, ...props }) => {
    const { t } = useTranslation();
    const title = data?.title ? data.title : 'Board Members';
    const subtitle = data?.subtitle ?? null;
    const description = data?.description ?? null;
    const members = data?.members ?? null;

    return (
        <Box bg={bg} {...props}>
            <Container variant="layouts.section" {...container}>
                { title && <Heading as="h2" variant="content_block_heading">{t(title)}</Heading>}

                { subtitle && <Box className="markdown subtitle" mb={3}>
                    <Markdown>{subtitle}</Markdown>
                </Box>}
                { description && <Box className="markdown description" mb={[4,4,5]} sx={{ fontWeight: 'thin' }}>
                    <Markdown>{description}</Markdown>
                </Box>}

                {members &&
                    <Grid sx={{
                        gridGap: 4,
                        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                        width: '100%'
                    }}>
                        {members.map((member, key) => (
                            <Box key={`member${key}`}>
                                <Grid sx={{
                                    gridGap: 3,
                                    gridTemplateColumns: ['84px auto'],
                                    width: '100%',
                                    alignItems: 'center',

                                }}>
                                    <Circle sx={{ bg: 'medium' }} type="box" size="84px">
                                        { member?.image?.sizes?.tiny && <Image src={member.image.sizes.tiny} sx={{ borderRadius: '50%' }} /> }
                                    </Circle>

                                    <Box>
                                        { member?.name && (
                                            <Heading as="h3" variant="h3" sx={{ display: 'flex' }}>
                                                {member.name}
                                                {member.linkedin && (
                                                    <Link variant="icon" href={member.linkedin} target="_blank" ml={2}>
                                                        <Icon size="18px" icon="linkedin-circle" />
                                                    </Link>
                                                )}
                                            </Heading>
                                        )}
                                        { member?.position && <Text as="p" variant="h6" color="dark" sx={{ fontWeight: 500 }}>{member.position}</Text>}
                                    </Box>
                                </Grid>

                                <Box m>
                                    { member?.bio && <Box className="markdown biography" mt={3} mb={3} sx={{ fontWeight: 'thin' }}>
                                        <Markdown externalLinks={true}>{member.bio}</Markdown>
                                    </Box>}
                                </Box>
                            </Box>
                        ))}
                    </Grid>
                }
            </Container>
        </Box>
    )
};
