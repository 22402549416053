import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Grid, Heading, Text } from 'theme-ui';
import { Field } from '~/Forms';
import { Link, Radio, Label } from '@theme-ui/components';
import { useState } from 'react';
import { ProfileMainTypes } from 'app/constants';
import { Accordion } from '~/Common';

const EntityDetailsUncoupled = ({ theme, ...props }) => {
    const { values, setFieldValue } = useFormikContext();
    const { t } = useTranslation();
    const profileSubTypes = {
        individual: 'Individual',
        company: 'Company',
    };
    const [companyType, setCompanyType] = useState(values?.sub_type.includes('company'));
    const profileOptions = theme.profile.options;
    const trustTypes = theme.profile.options.trustTypes;
    const countries = theme.countries.options;

    return (
        <>
            <Box mb={4}>
                <Text mb={4} as="p">{t('fieldsRequired')}</Text>

                <Box mb={4}>
                    <Field label="Name *" name="name" required={true} />
                    {values.type !== 'company' && (
                        <>
                            <Box mb={3}>
                                <Field
                                    label="Accountant's Email Address"
                                    name="accountant_email"
                                />
                                <Text variant="labelSmall" as="p" mb={2}>
                                    {t('settings.profile.create.accountantDetailsReason')}
                                </Text>
                            </Box>
                            <Text mb={2} as="p">
                                {t('Select the type of {{type}} *', { type: ProfileMainTypes[values.type] })}
                            </Text>
                            <Grid
                                mb={0}
                                sx={{
                                    gridGap: 3,
                                    gridTemplateColumns: ['repeat(2, 1fr)'],
                                    width: '100%',
                                    maxWidth: '432px',
                                }}
                            >
                                {Object.keys(profileSubTypes).map((key) => (
                                    <Box key={`profile_sub_type_${key}`}>
                                        <Label
                                            htmlFor={`sub_type_${key}`}
                                            sx={{
                                                textAlign: 'left',
                                                py: 3,
                                                px: 3,
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Radio
                                                checked={(values.sub_type.includes('company') && key.includes('company')) || (!values.sub_type.includes('company') && !key.includes('company'))}
                                                disabled={values.uuid}
                                                name="sub_type"
                                                type="radio"
                                                value={key}
                                                id={`sub_type_${key}`}
                                                onChange={(event) => {
                                                    const { value } = event.target;
                                                    // updateSubTypes(values.type);
                                                    setFieldValue('sub_type', value === 'company' ? values.type.concat('_', value) : values.type);
                                                    setCompanyType(value.includes('company'));
                                                }}
                                            />
                                            <Text pl={2}>{profileSubTypes[key]}</Text>
                                        </Label>
                                    </Box>
                                ))}
                            </Grid>
                            {values.uuid && (
                                <Text variant="labelSmall" as="p" mb={3} color={'red'}>
                                    {t('You will need to delete and start again to change the type of fund')}
                                </Text>
                            )}
                            {profileOptions?.sourceOfWealth.length > 0 ? (
                                <Field
                                    label="Source Of Funds *"
                                    placeholder="Select source of funds"
                                    name="source_of_wealth"
                                    field="searchable-select"
                                    options={profileOptions.sourceOfWealth}
                                />
                            ) : null}
                        </>
                    )}
                    {values.type !== 'company' ? (
                        <>
                            {values.type === 'superannuation' ? (
                                <Field label="Self-managed Super Fund" name="smsf" field="checkbox" value={true} checked={values.smsf} validIndicator={false} />
                            ) : (
                                <>
                                    {countries.length > 0 ? (
                                        <Field label="Country of Establishment *" placeholder="Select country" name="country" field="searchable-select" options={countries} required={true} />
                                    ) : null}
                                    {trustTypes.length > 0 ? (
                                        <Field label="Type of Trust *" placeholder="Select type" name="trust_type" field="searchable-select" options={trustTypes} required={true} />
                                    ) : null}
                                </>
                            )}
                            <Field label="ABN *" name="abn" />
                            <Field label="TFN^" name="tax_number" />
                            <Text variant="labelSmall">^{t('settings.profile.taxFileNumberReason')}</Text>

                            {companyType && (
                                <Accordion title="Company Details" isOpen={true}>
                                    <Field label="Company Name *" name="company_name" required={true} />
                                    <Field label="ACN *" name="company_acn" required={true} />
                                    <Text mb={12} as={Link} variant="labelSmall" target="_blank" href="https://connectonline.asic.gov.au/">
                                        {t('settings.profile.create.details.asicLink')}
                                    </Text>
                                    <Field label="ABN" name="company_abn" />
                                    <Field label="TFN^" name="company_tax_number" />
                                    <Text variant="labelSmall">^{t('settings.profile.taxFileNumberReason')}</Text>

                                    {profileOptions?.companyTypes.length > 0 ? (
                                        <Field label="Company Type *" placeholder="Select company type" name="company_type" field="select" options={profileOptions.companyTypes} />
                                    ) : null}

                                    {profileOptions?.industryCodes.length > 0 ? (
                                        <>
                                            <Field label="Industry *" placeholder="Select industry code" name="industry_code" field="searchable-select" options={profileOptions.industryCodes} required={true} />
                                            <Text variant="labelSmall">{t('settings.profile.industryReason')}</Text>
                                        </>
                                    ) : null}

                                    {countries.length > 0 ? (
                                        <Field label="Country of Incorporation *" placeholder="Select country" name="company_incorporation_country" field="searchable-select" options={countries} required={true} />
                                    ) : null}

                                    <Field
                                        label="Incorporation Date *"
                                        name="company_incorporation_at"
                                        field="date"
                                        required={true}
                                    />
                                </Accordion>
                            )}

                            <Box>
                                <Heading as="h3" variant="settings_heading" mb={3}>
                                    {t('Account Designation (for CHESS purposes)')}
                                </Heading>

                                <Box sx={{ mb: 3, fontSize: '13px', pt: 1 }}>
                                    <Text sx={{ color: 'dark' }}>{t('settings.profile.designationReason')}</Text>
                                </Box>
                                <Box sx={{ position: 'relative' }}>
                                    <Field label="Account Designation *" name="designation" description="eg. Petersen Family A/C" required={true} />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            height: '70px',
                                            top: 0,
                                            right: 3,
                                            pointerEvents: 'none',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text>A/C</Text>
                                    </Box>
                                </Box>
                            </Box>
                            {values.type === 'trust' && (
                                <>
                                    <Field
                                        label="Was the sum of the trust more than $10,000 on establishment?"
                                        name="sum_established_ten_thousand"
                                        field="checkbox"
                                        checked={values?.sum_established_ten_thousand}
                                        validIndicator={false}
                                    />
                                    {values?.sum_established_ten_thousand && <Field label="Name of Settlor *" name="settlor_name" />}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Field label="ACN *" name="acn" required={true} />
                            <Text mb={12} as={Link} variant="labelSmall" target="_blank" href="https://connectonline.asic.gov.au/">
                                {t('settings.profile.create.details.asicLink')}
                            </Text>
                            <Field label="ABN" name="abn" />
                            <Field label="TFN^" name="tax_number" />
                            <Text variant="labelSmall">^{t('settings.profile.taxFileNumberReason')}</Text>

                            {profileOptions?.companyTypes.length > 0 ? (
                                <Field label="Company Type *" placeholder="Select company type" name="company_type" field="select" options={profileOptions.companyTypes} />
                            ) : null}

                            {profileOptions?.industryCodes.length > 0 ? (
                                <>
                                    <Field label="Industry *" placeholder="Select industry code" name="industry_code" field="searchable-select" options={profileOptions.industryCodes} required={true} />
                                    <Text variant="labelSmall">{t('settings.profile.industryReason')}</Text>
                                </>
                            ) : null}

                            {countries.length > 0 ? (
                                <Field label="Country of Incorporation *" placeholder="Select country" name="incorporation_country" field="searchable-select" options={countries} required={true} />
                            ) : null}

                            <Field
                                label="Incorporation Date *"
                                name="incorporation_at"
                                field="date"
                                required={true}
                            />

                            {profileOptions?.sourceOfWealth.length > 0 ? (
                                <Field
                                    label="Source Of Funds *"
                                    placeholder="Select source of funds"
                                    name="source_of_wealth"
                                    field="searchable-select"
                                    options={profileOptions.sourceOfWealth}
                                    required={true}
                                />
                            ) : null}

                            <Field label="Contact Email *" name="contact_email" required={true} />
                            <Field label="Contact Phone *" name="contact_phone" required={true} />
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};

export const EntityDetails = connect(
    (state) => ({
        theme: state.theme,
    }),
    null
)(EntityDetailsUncoupled);
