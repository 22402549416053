import { Flex, Text } from '@theme-ui/components';
import styled from '@emotion/styled';
import { theme } from 'app/theme';
import { Icon } from 'assets/Icon';
import { Tooltip } from '~/Common';
import { useTranslation } from 'react-i18next';

export const ErrorStyled = styled(Text)`
    color: ${theme.colors.error};
    font-weight: 500;
    margin: 0;
    font-size: 10px;
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
    margin-bottom: 8px;
    text-align: left;
`;

export const Error = ({ isVisible, sx, text, ...props }) => {
    const { t } = useTranslation();

    if (!isVisible) {
        return '';
    }

    const errorText = (errors) => {
        const text = Array.isArray(errors) ? errors[0] : errors;

        if (Array.isArray(errors) && errors.length > 1){
            return <Tooltip text={errors.join("\n\n")}>
                <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Text>{t('Hover to view errors')}</Text><Icon icon="error" width="14px" height="auto" ml={1} mt="-4px" />
                </Flex>
            </Tooltip>;
        }

        if (text.length >= 50){
            return <Tooltip text={text}>
                <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Text>{t('Hover to view error')}</Text><Icon icon="error" width="14px" height="auto" ml={1} mt="-4px" />
                </Flex>
            </Tooltip>;
        }
        return text;
    };

    return (
        <ErrorStyled as="div" isVisible={isVisible} sx={{
            zIndex: 10,
            cursor: 'pointer',
            ...sx
        }} className="fieldError">
            {errorText(text)}
        </ErrorStyled>
    );
};
