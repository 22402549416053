import {
    Box
} from '@theme-ui/components'
import './animations.css'

export const Skeleton = ({ children, variant = 'rectangular', width, height, size, animation, sx, ...props }) => {
    let extraSx;

    switch(variant){
        case 'circle':
            extraSx = {
                width: size,
                height: size,
                borderRadius: '50%',
                ...sx
            };
        break;

        case 'button':
            extraSx = {
                borderRadius: '8px',
                ...sx
            };
        break;

        default:
            extraSx = {
                borderRadius: '4px',
                ...sx
            };
        break;
    }

    return (
        <>
            <Box sx={{
                display: 'flex',
                bg: 'light',
                width: width ?? '100%',
                height: height ?? ((t) => t.fontSizes[2]),
                animationName: animation ?? 'skeleton-loading',
                animationDuration: '1s',
                animationIterationCount: 'infinite',
                animationDirection: 'alternate',
                ...extraSx
            }}
            {...props}>{children}</Box>
        </>
    );
};
