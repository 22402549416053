import { useState } from 'react';
import * as Yup from 'yup'
import {
    Alert,
    Box,
    Flex,
    Close,
    Heading,
    Themed,
    Text
} from 'theme-ui'
import { Submit } from '~/Forms/Submit'
import { Form, Formik } from 'formik'
import { Field } from '~/Forms/Field'
import { Layout } from '~/Layouts'
import { Container } from '~/Common'
import { useTranslation } from 'react-i18next'
import { toast } from 'utils/toast'
import { getUserAsync, loginBySingleAccessToken } from 'features/auth/authSlice'
import { Link, useHistory } from "react-router-dom"
import { useSearchParams } from 'hooks/useSearchParams'
import { useResponsiveValue } from '@theme-ui/match-media'
import { grabFirstError } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required()
});

const OneTimeLogin = (props) => {
    const { t } = useTranslation();
    useDocumentTitle(t('One Time Login'));
    const token = props.match.params.token;
    const dispatch = useDispatch();
    const history = useHistory();
    const isMobile = useResponsiveValue([true, true, false]);
    const [errorMessage, setErrorMessage] = useState(null);
    let query = useSearchParams();

    const handleSubmit = (values, actions) => {
        loginBySingleAccessToken({
            email: values.email,
            token: token
        }).then((response) => {
            toast.success(t('loginOneTime.success'), {
                toastId: 'loginOneTime',
            });

            const returnTo = query.get('returnTo') ?? '/portfolio';
            dispatch(getUserAsync());

            localStorage.removeItem('returnTo');
            history.push(returnTo);
        }).catch(({ response }) => {
            localStorage.removeItem('authToken');
            if(response?.data?.errors){
                actions.setErrors(response.data.errors);
                setErrorMessage(() => grabFirstError(response?.data?.errors) || t('loginOneTime.error'));
            }
            toast.error(grabFirstError(response?.data?.errors) || t('loginOneTime.error'), {
                toastId: 'loginOneTime',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    };

    return (
        <Layout>
            <Container>
                <Formik
                    initialValues={{
                        email: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ status, setStatus }) => (
                        <Box pt={5} sx={{
                            borderTop: isMobile ? '1px solid' : null,
                            borderColor: isMobile ? 'light' : null,
                        }}>
                            { status === 'api_error' ?
                            <Alert role="alert" variant='danger' mb={4}>
                                <Box>
                                    <Box mb={2}>
                                        {t(errorMessage)}
                                    </Box>
                                    <Box pl={0} sx={{ fontSize: '13px' }}>
                                        <Text as="span">
                                            Forgot your password?{` `}
                                        </Text>
                                        <Text as="span">
                                            <Themed.a as={Link} to="/forgot-password">Reset</Themed.a>
                                        </Text>
                                    </Box>
                                </Box>
                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                            </Alert> : ''}

                            <Form mb={2}>
                                <Heading as="h1" variant="publicH1" mb={4}>
                                    {t('loginOneTime.title', { site: process.env.REACT_APP_NAME })}
                                </Heading>

                                <Text as="p" mb={3}>
                                    {t('loginOneTime.subtitle')}
                                </Text>

                                <Field
                                    label="Email"
                                    name="email"
                                    type="email"
                                />

                                <Flex sx={{ justifyContent: 'center' }}>
                                    <Submit variant="primary" text={t('buttons.submit')} />

                                    <Flex ml={'auto'} sx={{ justifyContent: 'center', mt: 2 }}>
                                        <Text as="span">
                                            {status === 'api_error' ? <Themed.a as={Link} to="/forgot-password">{t('Forgot password?')}</Themed.a> : <Themed.a as={Link} to="/">{t('Back to login')}</Themed.a>}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Form>
                        </Box>
                    )}
                </Formik>
            </Container>
        </Layout>
    );
};

export default OneTimeLogin;
