export const APP_FEATURES = {
    use_lqd_in_bids: false,
    deal_filters: true,
    peer_invites: true,
    following: true,
    edit_super_trusts: false,
    free_trades: false,
    adviser_invites: true,
    news: process.env.REACT_APP_GRAFA !== undefined ? JSON.parse(process.env.REACT_APP_GRAFA) : false
};

export const REWARD_RATE = 0.2;

// Value of 1 Liquidity dollar in AUD
export const LQD_CONVERSION = 1;

export const MAX_PROFILES_PER_USER = 10;

export const DealStatus = {
    draft: 1,
    active: 2,
    capital_raised: 3,
    closed: 4,
    cancelled: 5,
    money_received: 6,
    fulfilled: 7
};

export const DealInvestmentStatus = {
    pending: 1,
    authorised: 2,
    rejected: 3,
    cancelled: 4,
    agreement_signed: 5,
    money_received: 6,
    fulfilled: 7
};

export const DealBidType = {
    flexible: 0,
    fixed: 1
};

export const AdviserRoleTypeOptions = [
    { label: 'Accountant', value: 'accountant' },
    { label: 'Broker', value: 'broker' },
];

export const ProfileTypes = {
    individual: 'Individual',
    company: 'Company',
    superannuation: 'Super Fund',
    superannuation_company: 'Super Fund (Company)',
    trust: 'Trust',
    trust_company: 'Trust (Company)',
};

export const ProfileMainTypes = {
    individual: 'Individual',
    company: 'Company',
    superannuation: 'Super Fund',
    trust: 'Trust',
};

export const ProfileSubTypes = {
    individual: 'Individual',
    joint: 'Joint',
    company: 'Company'
};

export const ProfileTypeToMainType = {
    company: 'company',
    superannuation: 'superannuation',
    superannuation_company: 'superannuation',
    trust: 'trust',
    trust_company: 'trust'
};

export const POLLING = {
    NOTIFICATIONS: 300000, // 5 mins
    COINS: 300000 // 5 mins
};

export const DATE_FORMAT = {
    last_updated: 'dddd, MMMM Do YYYY, h:mm a',
    date_format_friendly: 'Do MMMM YYYY',
    datetime_format_friendly: 'Do MMMM YYYY, h:mm a'
};

export const DealTypeOptions = [
    { label: 'All Deals', value: '' },
    { label: 'Listed Deals', value: 'listed' },
    { label: 'Unlisted Deals', value: 'unlisted' }
];

export const DealSortByOptions = [
    { label: 'Ending soon', value: 'ending_soon' },
    { label: 'Latest', value: 'latest' },
    { label: 'Earliest', value: 'earliest' },
    { label: 'Offer Price', value: 'offer_price' },
    { label: 'Largest Offer Price', value: 'offer_price_desc' },
    { label: 'Offer Size', value: 'offer_size' },
    { label: 'Largest Offer Size', value: 'offer_size_desc' }
];

export const CompanyTypeOptions = [
    { label: 'All', value: '' },
    { label: 'Listed Companies', value: 'listed' },
    { label: 'Unlisted Companies', value: 'unlisted' }
];

export const CompanySortByOptions = [
    { label: 'Ascending', value: 'asc' },
    { label: 'Descending', value: 'desc' }
];

export const chessEmailOptions = [
    { label: 'None', value: 'NONE' },
    { label: 'Yes, same as applicant', value: 'YES_SAME_AS_APPLICANT' },
    { label: 'Yes, another email', value: 'YES_OTHER' }
];

export const accountManagementTypeOptions = [
    { label: 'Self Managed', value: 0 },
    { label: 'Full Service', value: 1 },
];

export const OnboardingSteps = {
    mobile: 1,
    personal: 2,
    address: 3,
    entity: 4,
    identity: 5,
    drivers_licence: 6,
    passport: 7,
    certificate: 8,
    finished: 9
}

export const DealStageTypes = {
    standard: 0,
    ipo: 1,
    fund: 2,
    term_deposit: 3,
    accus: 4,
    unlisted: 5
}
