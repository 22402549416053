import React, { useRef, useEffect, useCallback } from 'react';
import { Box } from '@theme-ui/components';

import { ReactComponent as LogoSvg } from 'assets/Svg/Logo.svg';
import { logoStates } from './states';

export const Logo = ({ icon, color, sx, size, width, height, center = false, ...props }) => {
    const pixelMe = (val) => (
        val === 'auto' || val.includes('%') || val.includes('px') ? val : `${val}px`
    );
    const svgWidth = width !== undefined ? pixelMe(width) : pixelMe(size);
    const svgHeight = height !== undefined ? pixelMe(height) : pixelMe(size);
    const logoRef = useRef(null);

    const getLetter = (letter, trigger = false) => {
        const l = logoRef.current.querySelectorAll(trigger ? `.logo-${letter}` : `[letter="${letter}"]`);
        return l.length ? l[0] : false;
    }

    const animateState = useCallback(state => {
        Object.entries(logoStates[state]).forEach(item => {
            const letter = item[0], path = item[1];
            const isTrigger = letter.indexOf('trigger') === 0;
            const pathElement = getLetter(letter, isTrigger);

            if (pathElement) {
                if (isTrigger) {
                    pathElement.setAttribute('width', path);
                } else {
                    pathElement.setAttribute('d', path);
                }
            }
        })
    }, []);

    useEffect(() => {
        const letterL = getLetter('triggerL', true);
        const letterQ = getLetter('triggerQ', true);
        const letterD = getLetter('triggerD', true);
        const expandL = () => animateState('expandL');
        const expandQ = () => animateState('expandQ');
        const expandD = () => animateState('expandD');
        const reset = () => animateState('default');

        if (letterL) {
            letterL.addEventListener('mouseenter', expandL);
            letterL.addEventListener('mouseleave', reset);
        }

        if (letterQ) {
            letterQ.addEventListener('mouseenter', expandQ);
            letterQ.addEventListener('mouseleave', reset);
        }

        if (letterD) {
            letterD.addEventListener('mouseenter', expandD);
            letterD.addEventListener('mouseleave', reset);
        }

        return () => {
            if (letterL) {
                letterL.removeEventListener('mouseenter', expandL);
                letterL.removeEventListener('mouseleave', reset);
            }

            if (letterQ) {
                letterQ.removeEventListener('mouseenter', expandQ);
                letterQ.removeEventListener('mouseleave', reset);
            }

            if (letterD) {
                letterD.removeEventListener('mouseenter', expandD);
                letterD.removeEventListener('mouseleave', reset);
            }
        };
    }, [animateState]);

    return (
        <Box
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                '> svg': {
                    // Todo: center to animate left to completely center animation
                    ml: center ? '25px' : 0,
                    width: `${svgWidth}`,
                    height: `${svgHeight}`,
                    '& path': {
                      fill: color,
                      transition: 'd 0.5s ease-in-out, fill 0.3s ease-in-out'
                    },
                    '& rect': {
                        transition: 'width 0.5s ease-in-out'
                    }
                },
                ...sx,
            }}
            {...props}
        >
            <LogoSvg ref={logoRef} />
        </Box>
    );
};

Logo.defaultProps = {
    size: '100%',
};
