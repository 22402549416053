import {
    Box,
    Heading,
    Text,
    Link
} from '@theme-ui/components'
import { Container } from '~/Common'
import { useTranslation } from 'react-i18next'
import { AnnouncementRow } from '~/Common/AnnouncementRow';
import { toast } from 'react-toastify';
import { grabFirstError } from 'utils/helpers';
import { fetchUserOpentrader } from 'features/auth/authAPI';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const AsxAnnouncements = ({ bg = 'white', data, user, container, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const announcements = data?.announcements ?? null;

    const handleOpentraderOnClick = (e) => {
        e.preventDefault();

        if(!user?.user?.can_see_trade_link){
            if(!user?.user?.finished_onboarding_details){
                toast.info(t('login.opentrader_not_ready_finish_onboarding'), {
                    toastId: 'opentrader-warning'
                })
                history.push('/onboarding');
            }
            toast.info(t('login.opentrader_not_ready'), {
                toastId: 'opentrader-warning'
            })
            return;
        }

        fetchUserOpentrader().then((response) => {
            if (response?.redirect_uri && response?.token){
                setTimeout(() => {
                    window.open(`${response.redirect_uri}?token=${response.token}`, '_blank');
                });
            }else{
                toast.error(t('login.opentrader_error'), {
                    toastId: 'opentrader-error'
                })
            }
        }).catch(({ response }) => {
            toast.error((grabFirstError(response.data?.errors) || response.data?.message) ?? t('login.opentrader_error'), {
                toastId: 'opentrader-error'
            })
        });
    };

    const subtitle = user?.user?.can_see_trade_link ? 
        t('Full ASX Announcements can be viewed in your live trade screen by clicking the <trade> link above.', {trade: <Link as="a" onClick={handleOpentraderOnClick}>'Trade'</Link>}) : 
        <>{t('Liquidity users can view live ASX Announcements in their Trade screen. Contact us at <email> to find out more.', {email: <Link href="mailto:members@liquidity.com.au" sx={{ color: 'dark' }}>members@liquidity.com.au</Link>})}</>;

    return (
        <Box id="latest-news">
            <Container variant="layouts.section">
                <Heading as="h2" variant="content_block_heading" mb={0}>{t('Latest Price Sensitive Announcements')}
                    <Text as="p" variant="small" mt={3}>
                        {subtitle}
                    </Text>
                </Heading>
                <Box>
                    {announcements?.map((announcement, index) => (
                        <AnnouncementRow key={index} announcement={announcement} />
                    ))}
                </Box>
            </Container>
        </Box>
    )
};
