import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getNotifications,
    markAllNotificationsAsRead,
    markNotificationAsRead,
    markNotificationAsUnread,
    removeNotification,
    storeNotificationPreferences,
    updateAsxAnnouncementPreference,
    toggleAsxAnnouncementPreferences
} from './notificationsAPI';
import moment from 'moment';

const initialState = {
    notifications: [],
    meta: [],
    unread_count: 0,
    status: 'idle',
    showNotificationMenu: false
};

export const getNotificationsAsync = createAsyncThunk(
    'notifications/getNotifications',
    async (payload) => {
        const response = payload === undefined ? await getNotifications() : payload;
        return response;
    }
);

export const markAllNotificationsAsReadAsync = createAsyncThunk(
    'notifications/markAllNotificationsAsRead',
    async (payload) => {
        const response = payload === undefined ? await markAllNotificationsAsRead() : payload;
        return response;
    }
);

export const markNotificationAsReadAsync = createAsyncThunk(
    'notifications/markNotificationAsRead',
    async (id) => {
        const response = await markNotificationAsRead(id);
        return {
            id: id,
            ...response
        };
    }
);

export const markNotificationAsUnreadAsync = createAsyncThunk(
    'notifications/markNotificationAsUnread',
    async (id) => {
        const response = await markNotificationAsUnread(id);
        return {
            id: id,
            ...response
        };
    }
);

export const removeNotificationAsync = createAsyncThunk(
    'notifications/removeNotification',
    async (id) => {
        const response = await removeNotification(id);
        return {
            id: id,
            ...response
        };
    }
);

const notifications = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setShowNotificationMenu: (state, action) => {
            state.showNotificationMenu = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getNotificationsAsync.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(getNotificationsAsync.rejected, (state) => {
                    state.notifications = [];
                    state.meta = [];
                    state.unread_count = 0;
                    state.status = 'attempted';
                })
                .addCase(getNotificationsAsync.fulfilled, (state, {payload}) => {
                    state.notifications = payload?.data;
                    state.meta = payload?.meta;
                    state.unread_count = payload?.meta?.unread_count ?? 0;
                    state.status = 'attempted';
                })
                .addCase(markAllNotificationsAsReadAsync.fulfilled, (state, {payload}) => {
                    state.notifications = state.notifications.map((notification, key) => {
                        notification.read_at = notification.read_at ?? moment().toISOString();
                        return notification;
                    });
                    state.unread_count = 0;
                })
                .addCase(markNotificationAsReadAsync.fulfilled, (state, {payload}) => {
                    state.notifications = state.notifications.map((notification, key) => {
                        if(notification.id !== payload?.id){
                            return notification;
                        }
                        notification.read_at = notification.read_at ?? moment().toISOString();
                        return notification;
                    });
                    state.unread_count = state.unread_count - 1;
                })
                .addCase(markNotificationAsUnreadAsync.fulfilled, (state, {payload}) => {
                    state.notifications = state.notifications.map((notification, key) => {
                        if(notification.id !== payload?.id){
                            return notification;
                        }
                        notification.read_at = null;
                        return notification;
                    });
                    state.unread_count = state.unread_count + 1;
                })
                .addCase(removeNotificationAsync.fulfilled, (state, {payload}) => {
                    state.notifications = state.notifications.filter(notification => notification.id !== payload?.id);
                });
    },
});

export {
    getNotifications,
    markAllNotificationsAsRead,
    markNotificationAsRead,
    markNotificationAsUnread,
    removeNotification,
    storeNotificationPreferences,
    updateAsxAnnouncementPreference,
    toggleAsxAnnouncementPreferences
}
export const { setShowNotificationMenu } = notifications.actions;
export default notifications.reducer;
