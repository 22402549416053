import { useCallback, useEffect, useState, useRef } from 'react'
import { SettingsLayout } from '~/Layouts';
import {
    Button,
    SaveButtons,
    Circle,
    ConfirmTwoFactorModal,
    Tooltip
} from '~/Common';
import {
    Alert,
    Box,
    Close,
    Grid,
    Heading,
    Link,
    Spinner,
    Text,
    Flex,
    Image,
    Input
} from '@theme-ui/components';
import { NavLink, useHistory } from 'react-router-dom'
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { FieldArray, Form, Formik } from 'formik';
import { Icon } from 'assets/Icon'
import { toast } from 'utils/toast'
import { Yup, addressValidationNullable } from 'utils/validationRules';
import axios from 'axios'
import moment from 'moment-timezone'
import { DATE_FORMAT, accountManagementTypeOptions } from 'app/constants'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group'
import { utilStateByCountry, utilTimezoneByCountry, getProfileOptionsAsync, getCountriesAsync } from 'features/theme/themeSlice'
import {
    storeAccountDetails,
    setUser,
    storeAccountCertificate,
    enableTwoFactor,
    getTwoFactorQrCode,
    getTwoFactorRecoveryCodes,
    refreshTwoFactorRecoveryCodes,
    disableTwoFactor,
    storeProfilePhoto
} from 'features/auth/authSlice'
import { formatBankAccount, getBankDetailsText } from 'utils/helpers';

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    first_name: Yup.string().required('First Name is required'),
    middle_names: Yup.string().nullable(),
    last_name: Yup.string().required('Last Name is required'),
    dob: Yup.string().test(
        'dob',
        'You must be at least 18',
        (value) => {
            return (
                moment().diff(moment(value), 'years') >=
                18
            );
        }
    )
        .required(),
    password: Yup.string().nullable(true).test('len', 'must be at least 8 characters', val => val === undefined || (val !== undefined && val.length >= 8)),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    unit_number: Yup.string().nullable(),
    street_number: Yup.string().required('Street number is required'),
    street_name: Yup.string().required('Street name is required'),
    street_type: Yup.string().nullable(),
    address2: Yup.string().nullable(),
    state: Yup.string().required('State is required'),
    suburb: Yup.string().required('Suburb is required'),
    postcode: Yup.string().required('Postcode is required'),
    timezone: Yup.string().required('Timezone is required'),
    citizenship_country: Yup.string().required('Country of citizenship is required'),
    country_of_birth: Yup.string().required('Country of birth is required'),
    tax_country: Yup.string().required('Tax country is required'),
    tax_number: Yup.string().taxNumber(),
    macquarie_occupation_type: Yup.string().required('Occupation type is required'),
    macquarie_occupation_category: Yup.string().required('Occupation category is required').nullable(),
    source_of_wealth: Yup.string().required('Source of income is required'),
    business_owner_trading_name: Yup.string().when('macquarie_occupation_type', {
        is: 'Business Owner',
        then: Yup.string().required('Business owner trading name is required')
    }),
    business_owner_address: addressValidationNullable,
    chess_addess: addressValidationNullable,
    // additional_direct_debit_fields must be an array of objects where first is an email and second is name
    additional_direct_debit_fields: Yup.array().of(
        Yup.object().shape({
            email: Yup.string().email('Invalid email address').nullable(),
            name: Yup.string().nullable()
        })
    ),
});

export const UserSettings = ({ user, theme }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const nodeRef = useRef(null);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [timezoneOptions, setTimezoneOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [businessStateOptions, setBusinessStateOptions] = useState([]);
    const [chessStateOptions, setChessStateOptions] = useState([]);
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(user?.user.two_factor_enabled);
    const [changePassword, setChangePassword] = useState(false);
    const [showUploadCert, setShowUploadCert] = useState(false);
    const [showTwoFactorExtras, setShowTwoFactorExtras] = useState(false);
    const [twoFactorQrCode, setTwoFactorQrCode] = useState(null);
    const [twoFactorRecoveryCodes, setTwoFactorRecoveryCodes] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [certUploadProgress, setCertUploadProgress] = useState(0);
    const [profileOptions, setProfileOptions] = useState(theme.profile.options);
    const [chessPoBox, setChessPoBox] = useState(user?.user?.individual?.chess_address?.is_po_box ?? false);
    const [countries, setCountries] = useState(theme.countries.options);
    const [occupationCategories, setOccupationCategories] = useState(theme.profile.options?.macquarieOccupations?.[user?.user?.individual?.macquarie_occupation_type] ?? []);
    const avatarInput = useRef(null);
    const isLocked = Boolean(user?.user?.individual?.locked_at !== null);
    const individualProfile = useSelector((state) => Array.isArray(state.user?.user?.profiles) ? state.user?.user?.profiles.find(p => p.type === 'individual') ?? {} : {});
    const hasDirectDebitEmails = true;

    moment.tz.setDefault(user.user?.timezone);

    const getStates = useCallback((country, field) => {
        utilStateByCountry(country ?? 'AU').then((response) => {
            switch (field) {
                case 'business_owner_address':
                    setBusinessStateOptions(response?.data);
                    break;
                case 'chess_address':
                    setChessStateOptions(response?.data);
                    break;
                default:
                    setStateOptions(response?.data);
                    break;
            }
        }).catch(() => {
            switch (field) {
                case 'business_owner_address':
                    setBusinessStateOptions([]);
                    break;
                case 'chess_address':
                    setChessStateOptions([]);
                    break;
                default:
                    setStateOptions([]);
                    break;
            }
        });
    }, [setStateOptions]);

    useEffect(() => {
        axios.all([
            utilStateByCountry('AU'),
            utilTimezoneByCountry('AU')
        ]).then(axios.spread((stateResponse, timezoneResponse) => {
            setStateOptions(stateResponse?.data);
            setBusinessStateOptions(stateResponse?.data)
            setChessStateOptions(stateResponse?.data)
            setTimezoneOptions(timezoneResponse?.data);
        })).catch(({ response }) => {
            setStateOptions(null);
            setBusinessStateOptions(null);
            setChessStateOptions(null);
            setTimezoneOptions([]);
        });

        if (theme.profile.status === 'loading') {
            dispatch(getProfileOptionsAsync());
        }
        if (theme.countries.status === 'loading') {
            dispatch(getCountriesAsync());
        }
        if (theme.profile.status === 'attempted' && theme.countries.status === 'attempted') {
            setLoading(false);
            setProfileOptions(theme.profile.options);
            setOccupationCategories(theme.profile.options.macquarieOccupations?.[user?.user?.individual?.macquarie_occupation_type] ?? []);
            setCountries(theme.countries.options);
        }
    }, [theme, user, dispatch]);

    const handleSubmit = (values, actions) => {
        storeAccountDetails({
            device_name: 'App',
            first_name: values.first_name,
            middle_names: values.middle_names,
            last_name: values.last_name,
            email: values.email,
            timezone: values.timezone,
            dob: values.dob,
            password: values.password,
            password_confirmation: values.password_confirmation,
            title: values.title,
            unit_number: values.unit_number,
            street_number: values.street_number,
            street_name: values.street_name,
            street_type: values.street_type,
            address: values.address,
            address2: values.address2,
            state: values.state,
            suburb: values.suburb,
            postcode: values.postcode,
            country: 'AU',
            citizenship_country: values.citizenship_country,
            country_of_birth: values.country_of_birth,
            tax_country: values.tax_country,
            tax_number: values.tax_number,
            macquarie_occupation_type: values.macquarie_occupation_type,
            macquarie_occupation_category: values.macquarie_occupation_category,
            source_of_wealth: values.source_of_wealth,
            business_owner_trading_name: values.business_owner_trading_name,
            business_owner_address: {
                unit_number: values?.business_owner_address?.unit_number ?? null,
                street_number: values?.business_owner_address?.street_number ?? null,
                street_name: values?.business_owner_address?.street_name ?? null,
                street_type: values?.business_owner_address?.street_type ?? null,
                address2: values?.business_owner_address?.address2 ?? null,
                state: values?.business_owner_address?.state ?? null,
                suburb: values?.business_owner_address?.suburb ?? null,
                postcode: values?.business_owner_address?.postcode ?? null,
                country: values?.business_owner_address?.country ?? null,
            },
            is_chess_address_same_as_applicant: values.is_chess_address_same_as_applicant,
            chess_address: {
                address: values?.chess_address?.address ?? null,
                unit_number: values?.chess_address?.unit_number ?? null,
                street_number: values?.chess_address?.street_number ?? null,
                street_name: values?.chess_address?.street_name ?? null,
                street_type: values?.chess_address?.street_type ?? null,
                address2: values?.chess_address?.address2 ?? null,
                state: values?.chess_address?.state ?? null,
                suburb: values?.chess_address?.suburb ?? null,
                postcode: values?.chess_address?.postcode ?? null,
                country: values?.chess_address?.country ?? null,
                is_po_box: chessPoBox,
            },
            additional_direct_debit_fields: values?.additional_direct_debit_fields ?? [],
        }).then((response) => {
            toast.success(response?.message || t('settings.account.success'), {
                toastId: 'account-success',
            });
            dispatch(setUser(response));
            actions.setSubmitting(false);
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(response?.data?.message || t('settings.account.error'), {
                toastId: 'account-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    };

    const updateOccupationCategories = (e) => {
        setOccupationCategories(theme.profile.options.macquarieOccupations[e.value] ?? []);
    };

    const onUploadProgress = (p) => {
        setCertUploadProgress((p.loaded / p.total) * 100);
    };

    const handleUploadCertificate = ({ name, value, setSubmitting, setStatus }) => {
        let formdata = new FormData();
        formdata.append(name, value);

        setCertUploadProgress(0);

        storeAccountCertificate(formdata, onUploadProgress).then((response) => {
            toast.success(t('settings.certificate.success'), {
                toastId: 'certificate-success',
            });
            dispatch(setUser(response));

            setTimeout(() => setShowUploadCert(false), 750);
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.certificate.error'), {
                toastId: 'certificate-error',
            });
            setStatus('api_error');

            setTimeout(() => setShowUploadCert(false), 750);
        });
    };

    const handleEnableTwoFactor = () => {
        enableTwoFactor().then(() => {
            axios.all([
                getTwoFactorQrCode(),
                getTwoFactorRecoveryCodes()
            ]).then(axios.spread((qrResponse, recoveryResponse) => {
                setTwoFactorQrCode(qrResponse?.svg);
                setTwoFactorRecoveryCodes(recoveryResponse);
                setShowTwoFactorExtras(true);
                setTwoFactorEnabled('pending');
                setShowConfirmModal(true);

                toast.success(t('settings.two_factor.pending.success'), {
                    toastId: 'two_factor-success',
                });
            }))
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.two_factor.pending.error'), {
                toastId: 'two_factor-error',
            });
        });
    };

    const handleRefreshRecoveryCodes = () => {
        refreshTwoFactorRecoveryCodes().then(() => {
            getTwoFactorRecoveryCodes().then((response) => {
                setTwoFactorRecoveryCodes(response);
                setShowTwoFactorExtras(true);

                toast.success(t('settings.two_factor.recoveryCodes.success'), {
                    toastId: 'two_factor-success',
                });
            })
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.two_factor.recoveryCodes.error'), {
                toastId: 'two_factor-error',
            });
        });
    };

    const handleDisableTwoFactor = () => {
        disableTwoFactor().then(() => {
            setShowTwoFactorExtras(false);
            setTwoFactorEnabled(false);

            toast.success(t('settings.two_factor.disabled.success'), {
                toastId: 'two_factor-success',
            });
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.two_factor.disabled.error'), {
                toastId: 'two_factor-error',
            });
        });
    };

    const uploadAvatar = () => avatarInput.current && avatarInput.current.click()
    const handleUploadAvatar = (e) => {
        if (!!e.target?.files?.length) {
            let formdata = new FormData();
            formdata.append('profile_photo', e.target?.files?.[0]);

            storeProfilePhoto(formdata)
                .then((response) => {
                    dispatch(setUser(response));
                    toast.success(t('Your profile photo has been changed'), {
                        toastId: 'avatar-upload-success',
                    });
                })
                .catch((e) => {
                    toast.error(
                        e.response?.data?.errors?.profile_photo?.[0] ?? 'There was an error uploading your profile picture',
                        {
                            toastId: 'avatar-upload-error',
                        }
                    );
                })
        }

        // Reset input
        avatarInput.current.value = ''
    }

    const renderTwoFactorButton = () => {
        switch (twoFactorEnabled) {
            case 'pending':
                return (
                    <Button variant="bordered" onClick={(e) => setShowConfirmModal(true)}>{t('settings.two_factor.pending.buttonText')}</Button>
                );

            case true:
                return (
                    <Box><Button variant="light" onClick={(e) => handleRefreshRecoveryCodes()}>{t('settings.two_factor.enabled.regenerateButtonText')}</Button> <Button variant="bordered" onClick={(e) => handleDisableTwoFactor()}>{t('settings.two_factor.enabled.buttonText')}</Button></Box>
                );

            case false:
            default:
                return (
                    <Button variant="bordered" onClick={(e) => handleEnableTwoFactor()}>{t('settings.two_factor.disabled.buttonText')}</Button>
                );
        }
    };

    // If we toggle either of the PO Box checkboxes, we need to clear the address fields
    const handlePoBoxToggle = (value, values, setValues) => {
        setValues({
            ...values,
            chess_address: {
                address: '',
                unit_number: '',
                street_number: '',
                street_name: '',
                street_type: '',
                address2: '',
                state: '',
                suburb: '',
                postcode: '',
                country: '',
            },
        });
        setChessPoBox(value);
    }

    return (
        <SettingsLayout>
            {loading ? <Box id="settingsLoading"><Spinner variant="styles.spinner" m={'auto'} /></Box> : (
                <>
                    <CSSTransition
                        nodeRef={nodeRef}
                        in={true}
                        timeout={500}
                        classNames="fade"
                        unmountOnExit
                        appear
                    >
                        <Box ref={nodeRef}>
                            <Heading as="h1" variant="h2">{t('Account Details')}</Heading>

                            {isLocked && (
                                <Alert mt={4} mb={4} variant="info" isCloseable={false}>
                                    {t('settings.account.locked')}
                                </Alert>
                            )}

                            <Input
                                ref={avatarInput}
                                type="file"
                                sx={{ width: 0, height: 0, visibility: 'hidden' }}
                                onChange={handleUploadAvatar}
                            />
                            <Formik
                                enableReinitialize
                                validateOnMount={true}
                                initialValues={{
                                    title: user?.user?.individual?.title ?? '',
                                    email: user?.user?.email ?? '',
                                    mobile: user?.user?.mobile ?? '',
                                    first_name: user?.user?.first_name ?? '',
                                    middle_names: user?.user?.middle_names ?? '',
                                    last_name: user?.user?.last_name ?? '',
                                    dob: user?.user?.individual?.dob ?? '',
                                    unit_number: user?.user?.individual?.address?.unit_number ?? '',
                                    street_number: user?.user?.individual?.address?.street_number ?? '',
                                    street_name: user?.user?.individual?.address?.street_name ?? '',
                                    street_type: user?.user?.individual?.address?.street_type ?? '',
                                    address2: user?.user?.individual?.address?.address2 ?? '',
                                    suburb: user?.user?.individual?.address?.suburb ?? '',
                                    state: user?.user?.individual?.address?.state ?? '',
                                    postcode: user?.user?.individual?.address?.postcode ?? '',
                                    country: 'AU',
                                    timezone: user?.user?.timezone ?? '',
                                    citizenship_country: user?.user?.individual?.citizenship_country ?? '',
                                    country_of_birth: user?.user?.individual?.country_of_birth ?? '',
                                    tax_country: user?.user?.individual?.tax_country ?? '',
                                    tax_number: user?.user?.individual?.tax_number ?? '',
                                    macquarie_occupation_type: user?.user?.individual?.macquarie_occupation_type ?? '',
                                    macquarie_occupation_category: user?.user?.individual?.macquarie_occupation_category ?? '',
                                    source_of_wealth: user?.user?.individual?.source_of_wealth ?? '',
                                    business_owner_trading_name: user?.user?.individual?.business_owner_trading_name ?? '',
                                    is_chess_address_same_as_applicant: user?.user?.individual?.is_chess_address_same_as_applicant ?? false,
                                    business_owner_address: {
                                        unit_number: user?.user?.individual?.business_owner_address?.unit_number ?? '',
                                        street_number: user?.user?.individual?.business_owner_address?.street_number ?? '',
                                        street_name: user?.user?.individual?.business_owner_address?.street_name ?? '',
                                        street_type: user?.user?.individual?.business_owner_address?.street_type ?? '',
                                        address2: user?.user?.individual?.business_owner_address?.address2 ?? '',
                                        state: user?.user?.individual?.business_owner_address?.state ?? '',
                                        suburb: user?.user?.individual?.business_owner_address?.suburb ?? '',
                                        postcode: user?.user?.individual?.business_owner_address?.postcode ?? '',
                                        country: user?.user?.individual?.business_owner_address?.country ?? '',
                                    },
                                    chess_address: {
                                        address: user?.user?.individual?.chess_address?.address ?? '',
                                        unit_number: user?.user?.individual?.chess_address?.unit_number ?? '',
                                        street_number: user?.user?.individual?.chess_address?.street_number ?? '',
                                        street_name: user?.user?.individual?.chess_address?.street_name ?? '',
                                        street_type: user?.user?.individual?.chess_address?.street_type ?? '',
                                        address2: user?.user?.individual?.chess_address?.address2 ?? '',
                                        state: user?.user?.individual?.chess_address?.state ?? '',
                                        suburb: user?.user?.individual?.chess_address?.suburb ?? '',
                                        postcode: user?.user?.individual?.chess_address?.postcode ?? '',
                                        country: user?.user?.individual?.chess_address?.country ?? 'AU',
                                        is_po_box: user?.user?.individual?.chess_address?.is_po_box ?? false,
                                    },
                                    account_management_type: user?.user?.individual?.account_management_type ? parseInt(accountManagementTypeOptions.find(option => option.label === user?.user?.individual?.account_management_type)?.value) : '',
                                    additional_direct_debit_fields: user?.user?.individual?.additional_direct_debit_fields ?? [],
                                    password: '',
                                    password_confirmation: '',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ status, setStatus, setFieldValue, values, setValues }) => (
                                    <Box>
                                        {status === 'api_error' ?
                                            <Alert role="alert" variant='danger' mb={2}>
                                                {t('settings.account.error')}
                                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                                            </Alert> : ''}
                                        <Flex sx={{ mb: '33px', alignItems: 'center' }}>
                                            <Box sx={{ width: '110px', height: '110px', position: 'relative' }}>
                                                <Circle type="box" size="100%" sx={{ overflow: 'hidden' }}>
                                                    <Image
                                                        src={user?.user?.profile_photo_url}
                                                        sx={{
                                                            position: 'absolute',
                                                            width: '100%',
                                                            height: '100%',
                                                            left: 0,
                                                            top: 0,
                                                            objectFit: 'cover'
                                                        }}
                                                    />
                                                </Circle>
                                                <Circle
                                                    size="32px"
                                                    bg="#000"
                                                    sx={{
                                                        position: 'absolute',
                                                        right: 0,
                                                        bottom: 0
                                                    }}
                                                    onClick={uploadAvatar}
                                                >
                                                    <Icon icon="camera" />
                                                </Circle>
                                            </Box>
                                            <Box sx={{ pl: '23px' }}>
                                                <Text as="div" sx={{ fontSize: '20px', lineHeight: '24px' }}>
                                                    {`${user?.user?.first_name ?? ''} ${user?.user?.last_name ?? ''}`.trim()}
                                                </Text>
                                                <Text as="div" sx={{ fontSize: '16px', lineHeight: '18.2px' }}>
                                                    {user?.user?.email}
                                                </Text>
                                                <Box sx={{ lineHeight: 1 }}>
                                                    <Tooltip text={t('Liquidity Account Number')} sx={{ cursor: 'default' }}>
                                                        <Text
                                                            as="div"
                                                            sx={{
                                                                fontSize: 11,
                                                                color: 'dark',
                                                            }}
                                                        >
                                                            {user.user?.account_number}
                                                        </Text>
                                                    </Tooltip>
                                                </Box>
                                                {(individualProfile?.bank_account_bsb && individualProfile?.bank_account_number) && (
                                                    <Text as="div" sx={{ mt: 1, lineHeight: '1', fontSize: 11, color: 'dark' }}>
                                                        {getBankDetailsText(individualProfile)}
                                                        <Box sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                                                            {` ${formatBankAccount(individualProfile?.bank_account_bsb, individualProfile?.bank_account_number)}`}
                                                        </Box>
                                                    </Text>
                                                )}
                                                {individualProfile?.hin_number && (
                                                    <Text as="div" sx={{ mt: 1, lineHeight: '1', fontSize: 11, color: 'dark' }}>
                                                        {t('HIN: ')}
                                                        <Box sx={{ display: 'inline', whiteSpace: 'nowrap' }}>
                                                            {individualProfile.hin_number}
                                                        </Box>
                                                    </Text>
                                                )}
                                                {individualProfile?.external_hins && individualProfile?.external_hins.length ? (
                                                    <Text as="div" sx={{ mt: 1, lineHeight: '1', fontSize: 11, color: 'dark' }}>
                                                        {t('{{count}} Connected {{hins}}', { count: individualProfile?.external_hins.length, hins: individualProfile?.external_hins.length === 1 ? 'HIN' : 'HINs' })}
                                                    </Text>
                                                ) : null}
                                            </Box>
                                            <Box ml="auto">
                                                <Button
                                                    variant="light"
                                                    mr={2}
                                                    sx={{
                                                        py: '10px',
                                                        mt: [2, 0]
                                                    }}
                                                    onClick={() => {
                                                        history.push(`/settings/connected-hins/${individualProfile.uuid}`);
                                                    }}
                                                >
                                                    {t('Manage HINs')}
                                                </Button>
                                            </Box>
                                        </Flex>
                                        <Form>
                                            <Heading as="h3" variant="settings_heading" mb={3}>{t('Your Details')}</Heading>
                                            <Grid mb={1} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                <Field
                                                    label="Email"
                                                    name="email"
                                                    required="true"
                                                />
                                                <Box mb={[3]}>
                                                    <Field
                                                        label="Mobile"
                                                        name="mobile"
                                                        disabled={true}
                                                        groupSx={{ mb: 0 }}
                                                    />
                                                    <Text as="div" variant="small" sx={{
                                                        display: 'inline-flex',
                                                        justifyContent: 'center',
                                                        mt: 1,
                                                        color: 'dark',
                                                        fontSize: ['12px', '13px']
                                                    }}><Icon icon="info" size="14" mr={2} /> {t('Contact your account manager to update your mobile')}</Text>
                                                </Box>
                                            </Grid>

                                            <Grid mb={1} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                {profileOptions.titles.length > 0 &&
                                                    <Field
                                                        label="Title"
                                                        placeholder="Select title"
                                                        name="title"
                                                        field="searchable-select"
                                                        options={profileOptions.titles}
                                                        required={true}
                                                    />
                                                }
                                                <Field
                                                    label="First Name"
                                                    name="first_name"
                                                />
                                            </Grid>

                                            <Grid mb={1} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                <Field
                                                    label="Middle Names"
                                                    name="middle_names"
                                                />
                                                <Field
                                                    label="Last Name"
                                                    name="last_name"
                                                />
                                            </Grid>

                                            <Grid mb={1} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                <Field
                                                    label="Date of Birth"
                                                    name="dob"
                                                    field="date"
                                                />

                                                {countries.length > 0 &&
                                                <>
                                                    <Field
                                                        label="Country of citizenship"
                                                        placeholder="Select country"
                                                        name="citizenship_country"
                                                        field="searchable-select"
                                                        options={countries}
                                                        required={true}
                                                    />

                                                    <Field
                                                        label="Country of birth"
                                                        placeholder="Select country"
                                                        name="country_of_birth"
                                                        field="searchable-select"
                                                        options={countries}
                                                        required={true}
                                                    />

                                                    <Field
                                                        label="Tax Country"
                                                        placeholder="Select country"
                                                        name="tax_country"
                                                        field="searchable-select"
                                                        options={countries}
                                                        required={true}
                                                    />
                                                </>
                                                }
                                            </Grid>

                                            <Grid mb={4}>
                                                <Field
                                                    field="select"
                                                    label="Timezone"
                                                    name="timezone"
                                                    options={timezoneOptions}
                                                />
                                            </Grid>

                                            <Heading as="h3" variant="settings_heading" mb={3}>{t('Australian Tax File Number')}</Heading>
                                            <Grid sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                <Field
                                                    label="Australian tax file number (optional)"
                                                    name="tax_number"
                                                />
                                            </Grid>
                                            <Box sx={{ mb: 4, fontSize: '13px', pt: 1 }}>
                                                <Text sx={{ color: 'dark' }}>{t('settings.profile.taxFileNumberReason')}</Text>
                                            </Box>

                                            <Heading as="h3" variant="settings_heading" mb={3}>{t('Occupation Details')}</Heading>

                                            <Grid mb={1} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                {Array.isArray(profileOptions?.macquarieOccupationTypes) && profileOptions?.macquarieOccupationTypes.length > 0 &&
                                                    <Field
                                                        label="Occupation type"
                                                        placeholder="Select occupation type"
                                                        name="macquarie_occupation_type"
                                                        field="searchable-select"
                                                        onChange={(e) => {
                                                            updateOccupationCategories(e);
                                                        }}
                                                        options={profileOptions.macquarieOccupationTypes}
                                                        required={true}
                                                    />
                                                }
                                                {occupationCategories.length > 0 &&
                                                    <Field
                                                        label="Occupation category"
                                                        placeholder="Select occupation category"
                                                        name="macquarie_occupation_category"
                                                        field="searchable-select"
                                                        options={occupationCategories}
                                                        required={true}
                                                    />
                                                }
                                            </Grid>

                                            <Grid mb={4} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                {profileOptions?.sourceOfWealth.length > 0 &&
                                                    <Field
                                                        label="Source of income"
                                                        placeholder="Select source of income"
                                                        name="source_of_wealth"
                                                        field="searchable-select"
                                                        options={profileOptions.sourceOfWealth}
                                                        required={true}
                                                    />
                                                }
                                            </Grid>

                                            <Heading as="h3" variant="settings_heading" mb={3}>{t('Personal Address')}</Heading>

                                            <Grid mb={1} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr'],
                                                width: '100%'
                                            }}>
                                                <Field
                                                    field="google_places"
                                                    label="Search for address..."
                                                    name="address"
                                                    setFieldValue={setFieldValue}
                                                />
                                            </Grid>

                                            <Grid mb={1} sx={{
                                                gridGap: [2],
                                                gridTemplateColumns: ['1fr', '1fr 1fr 2fr 1fr'],
                                                width: '100%'
                                            }}>
                                                <Field
                                                    label="Unit Number"
                                                    name="unit_number"
                                                />
                                                <Field
                                                    label="Street Number"
                                                    name="street_number"
                                                />

                                                <Field
                                                    label="Street Name"
                                                    name="street_name"
                                                />
                                                <Field
                                                    label="Street Type"
                                                    name="street_type"
                                                />
                                            </Grid>

                                            <Grid mb={1} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr'],
                                                width: '100%'
                                            }}>
                                                <Field
                                                    label="Address second line"
                                                    name="address2"
                                                />
                                            </Grid>

                                            <Grid mb={2} sx={{
                                                gridGap: [2],
                                                gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
                                                width: '100%'
                                            }}>
                                                <Field
                                                    label="Suburb"
                                                    name="suburb"
                                                />
                                                <Field
                                                    field={Boolean(Array.isArray(stateOptions) && stateOptions.length > 0) ? 'select' : 'text'}
                                                    label="State"
                                                    name="state"
                                                    options={stateOptions}
                                                />

                                                <Field
                                                    label="Postcode"
                                                    name="postcode"
                                                />
                                            </Grid>

                                            <Grid mb={4} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr'],
                                                width: '100%'
                                            }}>
                                                <Field
                                                    field="checkbox"
                                                    name="is_chess_address_same_as_applicant"
                                                    label={t('CHESS registration address is the same as my personal address')}
                                                    validIndicator={false}
                                                    checked={values?.is_chess_address_same_as_applicant}
                                                />
                                            </Grid>

                                            {!values.is_chess_address_same_as_applicant && (
                                                <>
                                                    <Heading as="h3" variant="settings_heading" mb={3}>{t('CHESS Address')}</Heading>

                                                    <Box sx={{ mb: 3, fontSize: '13px', pt: 1 }}>
                                                        <Text sx={{ color: 'dark' }}>{t('settings.account.chessReason')}</Text>
                                                    </Box>

                                                    <Field
                                                        field="checkbox"
                                                        name="chess_address.is_po_box"
                                                        label={t('Address is PO Box')}
                                                        my={2}
                                                        onChangeOverride={(e) => handlePoBoxToggle(e.target.checked, values, setValues)}
                                                    />

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr'],
                                                        width: '100%'
                                                    }}>
                                                        {(!chessPoBox && values?.chess_address?.country === 'AU') && (
                                                            <Field
                                                                field="google_places"
                                                                label="Search for address..."
                                                                name="chess_address.address"
                                                                setFieldValue={setFieldValue}
                                                                fieldNames={{
                                                                    street_number: 'chess_address.street_number',
                                                                    street_name: 'chess_address.street_name',
                                                                    street_type: 'chess_address.street_type',
                                                                    suburb: 'chess_address.suburb',
                                                                    state: 'chess_address.state',
                                                                    postcode: 'chess_address.postcode',
                                                                    country: 'chess_address.country'
                                                                }}
                                                            />
                                                        )}

                                                        {countries.length > 0 &&
                                                            <Field
                                                                label="Country"
                                                                placeholder="Select country"
                                                                name="chess_address.country"
                                                                field="searchable-select"
                                                                options={countries}
                                                                onChange={(e) => {
                                                                    getStates(e?.value, 'chess_address');
                                                                    setTimeout(() => setFieldValue('chess_address.country', e?.value), 300);
                                                                }}
                                                                required={true}
                                                            />
                                                        }
                                                    </Grid>

                                                    {!chessPoBox && (
                                                        <Grid mb={1} sx={{
                                                            gridGap: [2, 3],
                                                            gridTemplateColumns: ['1fr', '1fr 1fr 2fr 1fr'],
                                                            width: '100%'
                                                        }}>
                                                            <Field
                                                                label="Unit Number"
                                                                name="chess_address.unit_number"
                                                            />
                                                            <Field
                                                                label="Street Number"
                                                                name="chess_address.street_number"
                                                            />
                                                            <Field
                                                                label="Street Name"
                                                                name="chess_address.street_name"
                                                            />
                                                            <Field
                                                                label="Street Type"
                                                                name="chess_address.street_type"
                                                            />
                                                        </Grid>

                                                    )}

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            label={chessPoBox ? "PO Box" : "Address second line"}
                                                            name="chess_address.address2"
                                                        />
                                                    </Grid>

                                                    <Grid mb={4} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            label="Suburb"
                                                            name="chess_address.suburb"
                                                        />

                                                        <Field
                                                            field={Boolean(Array.isArray(chessStateOptions) && chessStateOptions.length > 0) ? 'select' : 'text'}
                                                            label="State"
                                                            placeholder="Select state"
                                                            name="chess_address.state"
                                                            options={chessStateOptions}
                                                        />

                                                        <Field
                                                            label="Postcode"
                                                            name="chess_address.postcode"
                                                        />
                                                    </Grid>
                                                </>
                                            )}

                                            {values.macquarie_occupation_type === 'Business Owner' && (
                                                <>
                                                    <Heading as="h3" variant="settings_heading" mb={3}>{t('Business Details')}</Heading>

                                                    <Box sx={{ mb: 3, fontSize: '13px', pt: 1 }}>
                                                        <Text sx={{ color: 'dark' }}>{t('settings.account.businessOwnerReason')}</Text>
                                                    </Box>

                                                    <Field
                                                        label="Trading name of the business owner"
                                                        name="business_owner_trading_name"
                                                        required={true}
                                                    />

                                                    <Heading as="h4" variant="form_section" sx={{
                                                        fontWeight: 600,
                                                        letterSpacing: '-0.01em',
                                                        mb: 3,
                                                        mt: 4
                                                    }}>
                                                        {t('Business address')}
                                                    </Heading>
                                                    <Box sx={{ mb: 3, fontSize: '13px', pt: 1 }}>
                                                        <Text sx={{ color: 'dark' }}>{t('Please supply the trading address of the business you own. This cannot be a PO Box.')}</Text>
                                                    </Box>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            field="google_places"
                                                            label="Search for address..."
                                                            name="business_owner_address.address"
                                                            setFieldValue={setFieldValue}
                                                            fieldNames={{
                                                                street_number: 'business_owner_address.street_number',
                                                                street_name: 'business_owner_address.street_name',
                                                                street_type: 'business_owner_address.street_type',
                                                                suburb: 'business_owner_address.suburb',
                                                                state: 'business_owner_address.state',
                                                                postcode: 'business_owner_address.postcode',
                                                                country: 'business_owner_address.country'
                                                            }}
                                                        />

                                                        {countries.length > 0 &&
                                                            <Field
                                                                label="Country"
                                                                placeholder="Select country"
                                                                name="business_owner_address.country"
                                                                field="searchable-select"
                                                                options={countries}
                                                                onChange={(e) => {
                                                                    getStates(e?.value, 'business_owner_address');
                                                                    setTimeout(() => setFieldValue('business_owner_address.country', e?.value), 300);
                                                                }}
                                                                required={true}
                                                            />
                                                        }
                                                    </Grid>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', '1fr 1fr 2fr 1fr'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            label="Unit Number"
                                                            name="business_owner_address.unit_number"
                                                        />
                                                        <Field
                                                            label="Street Number"
                                                            name="business_owner_address.street_number"
                                                        />
                                                        <Field
                                                            label="Street Name"
                                                            name="business_owner_address.street_name"
                                                        />
                                                        <Field
                                                            label="Street Type"
                                                            name="business_owner_address.street_type"
                                                        />
                                                        </Grid>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            label="Address second line"
                                                            name="business_owner_address.address2"
                                                        />
                                                    </Grid>

                                                    <Grid mb={4} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            label="Suburb"
                                                            name="business_owner_address.suburb"
                                                        />

                                                        <Field
                                                            field={Boolean(Array.isArray(businessStateOptions) && businessStateOptions.length > 0) ? 'select' : 'text'}
                                                            label="State"
                                                            placeholder="Select state"
                                                            name="business_owner_address.state"
                                                            options={businessStateOptions}
                                                        />

                                                        <Field
                                                            label="Postcode"
                                                            name="business_owner_address.postcode"
                                                        />
                                                    </Grid>
                                                </>
                                            )}

                                            {parseInt(values?.account_management_type) === accountManagementTypeOptions.find(option => option.label === 'Full Service').value ? (
                                                <>
                                                    <Heading as="h3" variant="settings_heading" mb={3}>{t('Direct Debit Request')}</Heading>
                                                    <Box sx={{ mb: 4, fontSize: '13px', pt: 1 }}>
                                                        <Text sx={{ color: 'dark' }}>{t('To open your trading account, we will be sending you a Direct Debit Request Form that will need to be signed by all account holders of your nominated bank account. Please provide the name(s) and email address(es) of any other account holders (not including yourself).')}</Text>
                                                    </Box>

                                                    <FieldArray name="additional_direct_debit_fields">
                                                        {({ remove, push }) => (
                                                            <>
                                                                {hasDirectDebitEmails ? (
                                                                    <Box>
                                                                        {values.additional_direct_debit_fields.map((email, index) => (
                                                                            <Grid
                                                                                key={`direct_debit_emails${index}`}
                                                                                sx={{
                                                                                    gridGap: [1],
                                                                                    gridTemplateColumns: ['4fr 3fr 1fr'],
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                <Field label={`Email ${(index + 1)}`} name={`additional_direct_debit_fields.${index}.email`} updateOnBlur={true} />
                                                                                <Field label="Name" name={`additional_direct_debit_fields.${index}.name`} updateOnBlur={true} />

                                                                                <Button
                                                                                    onClick={() => remove(index)}
                                                                                    size="default"
                                                                                    variant="danger"
                                                                                    mt={2}
                                                                                    mb={3}
                                                                                >
                                                                                    <Icon icon="trash" size="18px" color="#fff" />
                                                                                    <span className="sr-only">{t('Remove')}</span>
                                                                                </Button>
                                                                            </Grid>
                                                                        ))}
                                                                    </Box>
                                                                ) : null}

                                                                <Box mb={4}>
                                                                    <Button
                                                                        sx={{
                                                                            display: ['flex'],
                                                                            alignItems: 'center',
                                                                            width: '50%',
                                                                            mb: 2
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            push("");
                                                                        }}
                                                                    >
                                                                        <Icon icon="plus" size="12" mr={2} />
                                                                        {t(`Add Additional Account Holder`)}
                                                                    </Button>
                                                                </Box>
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </>
                                            ): null}

                                            <Grid sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                <Box>
                                                    <Heading as="h3" variant="settings_heading">{t('Password')}</Heading>
                                                    <Box mb={4}>
                                                        {changePassword ? (
                                                            <>
                                                                <Field
                                                                    label="New Password"
                                                                    name="password"
                                                                    type="password"
                                                                />

                                                                <Box sx={{ mb: 2, fontSize: '13px', pt: 1, color: 'dark' }}>
                                                                    <Text as="p">{t('Passwords must include: ')}</Text>
                                                                    <Text as="p">{t('- At least 8 characters')}</Text>
                                                                    <Text as="p">{t('- At least one uppercase and one lowercase letter')}</Text>
                                                                    <Text as="p">{t('- At least one symbol')}</Text>
                                                                    <Text as="p">{t('- At least one number')}</Text>
                                                                </Box>

                                                                <Field
                                                                    label="Confirm Password"
                                                                    name="password_confirmation"
                                                                    type="password"
                                                                />
                                                                <Box mt={3} mb={4}>
                                                                    <Button variant="bordered" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setChangePassword(false)
                                                                    }}>{t('Cancel Password Change')}</Button>
                                                                </Box>
                                                            </>
                                                        ) : (
                                                            <Button variant="bordered" onClick={() => setChangePassword(true)}>{t('Change My Password')}</Button>
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Heading as="h3" variant="settings_heading">{t(user.user?.certificate ? 'Update s708 Certificate' : 'Upload s708 Certificate')}</Heading>

                                                    {user.user?.certificate_status === 'Expired' ? (
                                                        <Text as="p" variant="body_large" color="slate" mb={4}>
                                                            {t('settings.account.certificateExpired', {
                                                                date: moment(user.user?.certificate_expiry_at).format(DATE_FORMAT.date_format_friendly)
                                                            })}
                                                        </Text>
                                                    ) : (
                                                        <>
                                                            {user.user?.certificate_expiry_at && <Text as="p" variant="body_large" color="slate" mb={4}>
                                                                {t('settings.account.certificateExpiryWarning', {
                                                                    date: moment(user.user?.certificate_expiry_at).format(DATE_FORMAT.date_format_friendly)
                                                                })}
                                                            </Text>}
                                                        </>
                                                    )}



                                                    {theme.settings.certificate_template && (
                                                        <Box mb={3} sx={{
                                                            color: 'dark',
                                                            fontSize: '13px',
                                                            '& a': {
                                                                color: 'tealDark'
                                                            }
                                                        }}>
                                                            {t('Need help?')} <Link href={theme.settings.certificate_template} target="_blank" variant="light">{t('Download Certificate Template')}</Link>
                                                        </Box>
                                                    )}
                                                    <Box mb={4}>
                                                        {showUploadCert ? (
                                                            <>
                                                                <Field
                                                                    field="upload"
                                                                    name="certificate"
                                                                    label="Upload 708 certificate"
                                                                    showLabel={false}
                                                                    uploadProgress={certUploadProgress}
                                                                    onChange={(props) => handleUploadCertificate({ setStatus, ...props })}
                                                                />
                                                                <Box mb={4}>
                                                                    <Link to="#cancel" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setShowUploadCert(false)
                                                                    }}>{t('Cancel certificate upload')}</Link>
                                                                </Box>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Flex sx={{ alignItems: 'center', mb: 2, gridGap: 3 }}>
                                                                    <Button variant="light" onClick={() => setShowUploadCert(true)}>{t(user.user?.certificate ? 'Update Certificate' : 'Supply Certificate')}</Button>
                                                                    {user.user?.certificate &&
                                                                        <Box variant="styles.captionWrapper" sx={{ py: '13px' }}>
                                                                            {user.user?.certificate_status === 'Expired' ? (
                                                                                <Flex sx={{ alignItems: 'center' }}>
                                                                                    <Icon icon="pending" color="warning" width="auto" height="13px" sx={{ mr: 2 }} />
                                                                                    <Text variant="caption">{t('Certificate expired')}</Text>
                                                                                </Flex>
                                                                            ) : (
                                                                                <Flex sx={{ alignItems: 'center' }}>
                                                                                    <Icon icon="tick" color="tealDark" width="auto" height="9px" sx={{ mr: 2 }} />
                                                                                    <Text variant="caption">{t(user.user?.certificate_status === 'Pending' ? 'Awaiting verification' : 'Certificate provided')}</Text>
                                                                                </Flex>
                                                                            )}
                                                                        </Box>
                                                                    }
                                                                </Flex>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Heading as="h3" variant="settings_heading" mb={3}>{t('settings.two_factor.heading')}</Heading>
                                                    <Text as="p" variant="body_large" color="slate" mb={4}>
                                                        {t('settings.two_factor.subheading')}
                                                    </Text>
                                                    <Box mb={4}>
                                                        <Text as="p" sx={{ fontWeight: 500 }} mb={2}>{t(twoFactorEnabled ? 'settings.two_factor.enabled.heading' : 'settings.two_factor.disabled.heading')}</Text>
                                                        <Text as="p" variant="caption" color="dark" mb={4}>
                                                            {t(twoFactorEnabled ? 'settings.two_factor.enabled.subheading' : 'settings.two_factor.disabled.subheading')}
                                                        </Text>

                                                        {showTwoFactorExtras && <Box mb={2}>
                                                            {twoFactorRecoveryCodes.length > 0 && <>
                                                                <Text as="p" variant="captionTitle" mb={1}>{t('Recovery Codes')}</Text>
                                                                <Text as="p" variant="caption" color="dark" mb={3}>{t('settings.two_factor.instructions.recoveryCodes')}</Text>
                                                                <Box mb={4}>
                                                                    {twoFactorRecoveryCodes.map(recoveryCode => (
                                                                        <Text as="div" key={`rc-${recoveryCode}`} variant="caption" color="dark">{recoveryCode}</Text>
                                                                    ))}
                                                                </Box>
                                                            </>}
                                                        </Box>}

                                                        {renderTwoFactorButton()}
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            {(!isLocked || changePassword) && (
                                                <SaveButtons>
                                                    <Submit
                                                        variant="primary"
                                                        text={t('buttons.save')}
                                                        sx={{
                                                            width: '200px'
                                                        }}
                                                        showErrorTooltip={true}
                                                    />
                                                    <Button
                                                        as={NavLink}
                                                        to="/portfolio"
                                                        variant="light"
                                                        sx={{
                                                            width: '200px'
                                                        }}
                                                    >
                                                        {t('buttons.discard')}
                                                    </Button>
                                                </SaveButtons>
                                            )}
                                        </Form>
                                    </Box>
                                )}
                            </Formik>
                        </Box>
                    </CSSTransition>

                    <ConfirmTwoFactorModal
                        isOpen={showConfirmModal}
                        twoFactorQrCode={twoFactorQrCode}
                        user={user}
                        closeModal={() => setShowConfirmModal(false)}
                        onSuccess={() => setTwoFactorEnabled(true)}
                    />
                </>
            )}
        </SettingsLayout>
    );
};
