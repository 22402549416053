import React, {useEffect, useContext, useState, useCallback} from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import {
    Alert,
    Box,
    Close,
    Flex,
    Grid,
    Heading,
    Text,
    Themed,
} from 'theme-ui';
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { OnboardingContext } from 'pages/Onboarding/Landing';
import { getOnboardingStatusAsync, onboardingEntity } from 'features/auth/authSlice';
import {
    getCountriesAsync,
    getProfileOptionsAsync,
    utilStateByCountry,
    utilTimezoneByCountry,
} from 'features/theme/themeSlice';
import { toast } from 'utils/toast';
import { connect, useDispatch } from 'react-redux';
import RegistrationDetailsCard from '~/Common/RegistrationDetailsCard/RegistrationDetailsCard';

const mapStateToProps = (state) => {
    return { user: state.user, theme: state.theme }
}

const validationSchema = Yup.object().shape({
    trustee_1_title: Yup.string().when('subtype', {
        is: (val) => val === 'individual' || val === 'joint',
        then: Yup.string().required('Trustee 1\'s title is required')
    }),
    trustee_1_first_name: Yup.string().when('subtype', {
        is: (val) => val === 'individual' || val === 'joint',
        then: Yup.string().required('Trustee 1\'s first name is required')
    }),
    trustee_1_last_name: Yup.string().when('subtype', {
        is: (val) => val === 'individual' || val === 'joint',
        then: Yup.string().required('Trustee 1\'s last name is required')
    }),
    trustee_2_title: Yup.string().when('subtype', {
        is: 'joint',
        then: Yup.string().required('Trustee 2\'s title is required')
    }),
    trustee_2_first_name: Yup.string().when('subtype', {
        is: 'joint',
        then: Yup.string().required('Trustee 2\'s first name is required')
    }),
    trustee_2_last_name: Yup.string().when('subtype', {
        is: 'joint',
        then: Yup.string().required('Trustee 2\'s last name is required')
    }),
    name: Yup.string().when('subtype', {
        is: 'company',
        then: Yup.string().required()
    }).when('type', {
        is: 'company',
        then: Yup.string().required()
    }),
    designation: Yup.string().when('type', {
       is: (val) => val === 'trust' || val === 'superannuation',
       then: Yup.string().required()
    }),
    unit_number: Yup.string().nullable(),
    street_number: Yup.string().nullable(),
    street_name: Yup.string().nullable(),
    street_type: Yup.string().nullable(),
    address2: Yup.string().nullable(),
    state: Yup.string().nullable(),
    suburb: Yup.string().nullable(),
    postcode: Yup.string().nullable(),
    source_of_wealth: Yup.string().required('Source of wealth is required'),
});

const OnboardingEntity = ({ user, theme }) => {
    const onboarding = useContext(OnboardingContext);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [timezoneOptions, setTimezoneOptions] = useState([]);
    const [profileOptions, setProfileOptions] = useState(theme.profile.options);
    const [stateOptions, setStateOptions] = useState(null);
    const [showAddress, setShowAddress] = useState(false);

    const getOptions = useCallback(() => {
        if (theme.profile.status === 'loading') {
            dispatch(getProfileOptionsAsync());
        }
        if (theme.countries.status === 'loading') {
            dispatch(getCountriesAsync());
        }
    }, [theme, dispatch]);

    useEffect(() => {
        getOptions();

        if (theme.profile.status === 'attempted' && theme.countries.status === 'attempted') {
            setProfileOptions(theme.profile.options);
        }

    }, [theme, getOptions]);

    useEffect(() => {
        axios.all([
            utilStateByCountry('AU'),
            utilTimezoneByCountry('AU')
        ]).then(axios.spread((stateResponse, timezoneResponse) => {
            setStateOptions(stateResponse?.data);
            setTimezoneOptions(timezoneResponse?.data);
            onboarding.setLoading(false);
        })).catch(({ response }) => {
            setStateOptions(null);
            setTimezoneOptions([]);
            onboarding.setLoading(false);
        });
    }, [onboarding]);

    const handleSubmit = (values, actions) => {
        onboarding.setLoading(true);

        onboardingEntity({
            address: values.address,
            unit_number: values.unit_number,
            street_number: values.street_number,
            street_name: values.street_name,
            street_type: values.street_type,
            address2: values.address2,
            state: values.state,
            suburb: values.suburb,
            postcode: values.postcode,
            country: values.country ?? 'AU',
            name: values.name,
            designation: values.designation,
            type: theme.onboarding.type + (theme.onboarding.subtype === 'company' ? `_${theme.onboarding.subtype}` : ''),
            subtype: theme.onboarding.subtype,
            trustee_1_title: values.trustee_1_title,
            trustee_1_first_name: values.trustee_1_first_name,
            trustee_1_middle_names: values.trustee_1_middle_names,
            trustee_1_last_name: values.trustee_1_last_name,
            trustee_2_title: values.trustee_2_title,
            trustee_2_first_name: values.trustee_2_first_name,
            trustee_2_middle_names: values.trustee_2_middle_names,
            trustee_2_last_name: values.trustee_2_last_name,
            source_of_wealth: values.source_of_wealth,
        }).then((response) => {
            toast.success(t('onboarding.landing.entity.success'), {
                toastId: 'entity-success',
            });
            onboarding.setLoading(false);
            onboarding.nextStep();
            dispatch(getOnboardingStatusAsync());
            onboarding.setLoading(false);
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(response?.data?.message || t('onboarding.landing.entity.error'), {
                toastId: 'entity-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
            onboarding.setLoading(false);
        });
    };

    const getStateTimezone = state => {
        let filtered = timezoneOptions.filter(item => item.label.includes(`(${state})`));
        return filtered.length ? filtered[0].value : null;
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    unit_number: user?.user?.individual?.address?.unit_number ?? '',
                    street_number: user?.user?.individual?.address?.street_number ?? '',
                    street_name: user?.user?.individual?.address?.street_name ?? '',
                    street_type: user?.user?.individual?.address?.street_type ?? '',
                    address2: user?.user?.individual?.address?.address2 ?? '',
                    suburb: user?.user?.individual?.address?.suburb ?? '',
                    state: user?.user?.individual?.address?.state ?? '',
                    postcode: user?.user?.individual?.address?.postcode ?? '',
                    country: user?.user?.individual?.address?.country ?? 'AU',
                    name: '',
                    designation: '',
                    type: theme.onboarding.type,
                    subtype: theme.onboarding.subtype,
                    trustee_1_title: user?.user?.individual?.title,
                    trustee_1_first_name: user?.user?.individual?.first_name,
                    trustee_1_middle_names: user?.user?.individual?.middle_names,
                    trustee_1_last_name: user?.user?.individual?.last_name,
                    trustee_2_title: '',
                    trustee_2_first_name: '',
                    trustee_2_middle_names: '',
                    trustee_2_last_name: '',
                    source_of_wealth: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ status, setStatus, setFieldValue, values }) => (
                    <Box id="onboardingEntity">
                        { status === 'api_error' ?
                            <Alert role="alert" variant='danger' mb={2}>
                                {t('onboarding.landing.entity.error')}
                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                            </Alert> : ''}

                        <Form>
                            <Heading as="h1" variant="h3" sx={{ fontWeight: 500 }} mb={4}>
                                {t('onboarding.landing.entity.title')}
                            </Heading>

                            <Heading as="h2" variant="form_section" mb={3} sx={{
                                fontWeight: 700,
                                fontSize: 2
                            }}>
                                {t('onboarding.landing.entity.subtitle')}
                            </Heading>

                            <RegistrationDetailsCard type={theme.onboarding?.type} subtype={theme.onboarding?.subtype} values={values} />

                            { theme.onboarding.type.includes('company') && (
                                <Field
                                    label="Company Name"
                                    name="name"
                                    required={true}
                                />
                            )}

                            { (theme.onboarding.type.includes('trust') || theme.onboarding.type.includes('superannuation')) && (
                                <>
                                    <Box sx={{ position: 'relative' }}>
                                        <Field
                                            label={`Name of ${theme.onboarding.type === 'trust' ? 'Trust' : 'Fund'}`}
                                            name="designation"
                                            description="eg. Petersen Family A/C"
                                        />
                                        <Box sx={{
                                            position: 'absolute',
                                            height: '70px',
                                            top: 0,
                                            right: 3,
                                            pointerEvents: 'none',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <Text>A/C</Text>
                                        </Box>
                                    </Box>
                                </>
                            )}

                            {theme.onboarding.subtype.includes('company') && (
                                <Field
                                    label="Trustee (Company Name)"
                                    name="name"
                                    required={true}
                                />
                            )}

                            {theme.onboarding.subtype.includes('joint') && (
                                <>
                                    <Heading as="h2" variant="form_section" mb={3} sx={{
                                        fontWeight: 700,
                                        fontSize: 2
                                    }}>
                                        {t('Additional Trustee (not yourself)')}
                                    </Heading>
                                    <Grid
                                        sx={{
                                            gridGap: [0, 2],
                                            gridTemplateColumns: ['1fr', '1fr 2fr'],
                                            width: '100%',
                                        }}
                                    >
                                        {profileOptions.titles.length > 0 && <Field label="Title" id="trustee_2_title" classNamePrefix='list' placeholder="Select title" name="trustee_2_title" field="searchable-select" options={profileOptions.titles} />}
                                        <Field label="Trustee 2 First Name" name="trustee_2_first_name" />
                                    </Grid>
                                    <Field label="Trustee 2 Middle Names" name="trustee_2_middle_names" />
                                    <Field label="Trustee 2 Last Name" name="trustee_2_last_name" />
                                </>

                            )}

                            <Field
                                label="Source of Wealth *"
                                placeholder="Select source of wealth"
                                name="source_of_wealth"
                                field="searchable-select"
                                options={theme.profile?.options?.sourceOfWealth ?? []}
                                required={true}
                            />

                            <Heading
                                as="h2"
                                variant="form_section"
                                mb={3}
                                mt={4}
                                sx={{
                                    fontWeight: 700,
                                    fontSize: 2,
                                }}
                            >
                                {t('Entity Address (if different from your personal address)')}
                            </Heading>

                            {theme.onboarding.type === 'company' && (
                                <Field
                                    label="Country *"
                                    placeholder="Select country"
                                    name="country"
                                    field="searchable-select"
                                    options={theme.countries.options ?? []}
                                    required={true}
                                    onChangeOverride={(e) => {
                                        setFieldValue('country', e.value);
                                        setShowAddress(e.value !== 'AU')
                                    }}
                                />
                            )} 

                            {values?.country === 'AU' && (
                                <Field
                                    field="google_places"
                                    label="Search for address..."
                                    name="address"
                                    setFieldValue={setFieldValue}
                                    setShowAddress={setShowAddress}
                                    onStateChange={state => setFieldValue('timezone', getStateTimezone(state))}
                                />
                            )}

                            <Text as="p" variant="small" mb={4} sx={{
                                fontSize: '13px',
                                color: 'dark',
                                '& a': {
                                    color: 'tealDark'
                                }
                            }}>
                                {t('Please note your residential address must reside within Australia.')} {!showAddress && <Themed.a onClick={() => setShowAddress(true)} id="showEntityAddress">Address missing? Enter manually</Themed.a>}
                            </Text>

                            <Box sx={{
                                opacity: showAddress ? 1 : 0,
                                height: showAddress ? 'auto' : 0,
                                minHeight: showAddress ? '300px' : 0,
                                transition: 'min-height 0.3s ease-in-out, opacity 0.3s ease-in-out'
                            }}>
                                <Grid sx={{
                                    gridGap: [0,2],
                                    gridTemplateColumns: ['1fr', '1fr 2fr'],
                                    width: '100%'
                                }}>
                                    <Field
                                        label="Unit Number"
                                        name="unit_number"
                                    />
                                    <Field
                                        label="Street Number"
                                        name="street_number"
                                    />
                                </Grid>

                                <Grid sx={{
                                    gridGap: [0,2],
                                    gridTemplateColumns: ['1fr', '2fr 1fr'],
                                    width: '100%'
                                }}>
                                    <Field
                                        label="Street Name"
                                        name="street_name"
                                    />
                                    <Field
                                        label="Street Type"
                                        name="street_type"
                                    />
                                </Grid>

                                <Field
                                    label="Address second line"
                                    name="address2"
                                />
                                <Field
                                    label="Suburb"
                                    name="suburb"
                                />

                                <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
                                    <Box sx={{ width: ['100%', '100%', '50%'] }} pr={[0, 0, '5px']}>
                                        <Field
                                            field={stateOptions ? 'select' : 'text'}
                                            label="State"
                                            name="state"
                                            options={stateOptions}
                                        />
                                    </Box>

                                    <Box sx={{ width: ['100%', '100%', '50%'] }}>
                                        <Field
                                            label="Postcode"
                                            name="postcode"
                                        />
                                    </Box>
                                </Flex>
                            </Box>

                            <Submit variant="primary" id="entitySubmit" text={t('onboarding.landing.entity.submit')} sx={{ display: 'block', width: '100%' }} />
                        </Form>
                    </Box>
                )}
            </Formik>
        </>
    )
};

export default connect(mapStateToProps, null)(OnboardingEntity);
