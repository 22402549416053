import { useState, useRef, useEffect } from 'react';
import {
    Box,
    Button,
    Text,
    Flex,
    Image
} from '@theme-ui/components';
import { Icon } from 'assets/Icon';
import { useSelector } from 'react-redux';
import { SwitchProfile } from './SwitchProfile';
import { useMenuClose } from 'hooks/useMenuClose';
import { ProfileItem } from './styled';
import { Link } from 'react-router-dom';
import { getFallbackProfileImage } from 'utils/helpers';
import { Circle } from '../Circle';
import { MAX_PROFILES_PER_USER } from 'app/constants';

export const SwitchProfileButton = ({ sx, fullWidth = false, ...props }) => {
    const boxRef = useRef(null);
    const buttonRef = useRef(null);
    const activeProfile = useSelector((state) => state.user?.activeProfile);
    const profileCount = useSelector((state) => Array.isArray(state.user?.user?.profiles) ? state.user?.user?.profiles.length : 1);
    const user = useSelector((state) => state.user.user);
    const [isOpen, setOpen] = useState(false);
    const [profileImage, setProfileImage] = useState(null);

    useMenuClose(boxRef, () => isOpen && setOpen(false));

    useEffect(() => {
        if (user?.profile_photo_url && profileImage === null) {
            setProfileImage(user?.profile_photo_url ?? getFallbackProfileImage(user));
        }
    }, [user, profileImage]);

    return (
        <Box sx={{
            display: 'inline-block',
            height: '58px',
            width: '100%',
            position: 'relative'
        }}>
            <Box ref={boxRef} sx={{
                display: 'inline-block',
                position: 'absolute',
                left: 0,
                top: 0,
                overflow: 'hidden',
                width: fullWidth ? '100%' : 'auto',
                borderRadius: '26px',
                border: '1px solid',
                borderColor: 'white',
                maxHeight: isOpen ? '500px' : '58px',
                transition: 'max-height 0.5s'
            }}>
                <Button
                    ref={buttonRef}
                    variant="switchProfile"
                    onClick={() => setOpen(!isOpen)}
                    sx={{
                        borderRadius: '26px',
                        transition: 'border-radius 0s 0.3s, border-color 0s 0.3s',
                        border: 'none',
                        ...(isOpen ? {
                            borderRadius: '26px 26px 0 0'
                        } : {}),
                        width: fullWidth ? '100%' : 'auto',
                        ...sx
                    }}
                    {...props}
                >
                    {profileImage ? (
                        <Circle size="40px" bg="#f4f4f4" type="box" sx={{ flexShrink: 0, overflow: 'hidden', mr: 2 }}>
                            <Image
                                src={profileImage}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                onError={() => setProfileImage(getFallbackProfileImage(user))}
                            />
                        </Circle>
                    ) : (
                        <Icon icon="user-circle" size="40" mr={2} />
                    )}
                    <Text pr={2}>{activeProfile?.name} / {activeProfile?.type_name}</Text>
                    <Icon icon="chevron-down" size="12" sx={{ ml: 'auto', mr: 2 }} />
                </Button>
                <SwitchProfile
                    isOpen={isOpen}
                    sx={{
                        position: 'relative',
                        transform: 'none',
                        borderRadius: '0 0 26px 26px',
                        bg: 'darker',
                        py: 0,
                        width: buttonRef.current ? buttonRef.current.clientWidth : '200px',
                        transition: 'width 0.3s ease-in-out',
                        minWidth: 'min-content',
                        opacity: 1,
                        visibility: 'visible',
                        mt: '1px',
                        overflow: 'hidden',
                        overflowY: 'auto',
                        maxHeight: '400px',
                    }}
                    itemSx={{
                        color: 'medium',
                        transition: 'color 0.5s ease-in-out',
                        border: 'none',
                        borderTop: '1px solid',
                        borderColor: 'dark',
                        borderRadius: 0,
                        margin: 0,
                        '&:first-of-type': {
                            border: 'none'
                        },
                        '& svg path': {
                            transition: 'none'
                        },
                        '&.active': {
                            color: 'white'
                        },
                        '&.verified:hover': {
                            backgroundColor: 'black',
                            color: 'tealDark'
                        }
                    }}
                >
                    {Boolean(profileCount < MAX_PROFILES_PER_USER) && (
                        <ProfileItem
                            title={`Change to `}
                            as={Link}
                            variant="link"
                            sx={{
                                display: 'block',
                                width: '100%',
                                color: 'white',
                                transition: 'color 0.5s ease-in-out',
                                borderRadius: '0',
                                marginBottom: '20px',
                                padding: '16px 13px 26px 13px',
                                cursor: 'pointer',
                                borderTop: '1px solid',
                                borderColor: 'dark',
                                textDecoration: 'none',
                                '&:hover': {
                                    color: 'teal'
                                }
                            }}
                            to="/settings/entities/create"
                            className={`${true ? 'active' : ''} ${true ? 'verified' : ''}`}
                            {...props}
                        >
                            <Flex sx={{
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column'
                            }}>
                                <Text as="div" variant="h5" mb={0} sx={{
                                    lineHeight: 1,
                                    color: 'inherit',
                                    display: 'flex',
                                    gridGap: 2,
                                    alignItems: 'center'
                                }}>
                                    <Icon icon="add-user" sx={{ top: '3px', position: 'relative', mr: 2 }} /> Add another bidding entity
                                </Text>
                            </Flex>
                        </ProfileItem>
                    )}
                </SwitchProfile>
            </Box>
        </Box>
    )
}
