import { Card, Box, Text} from "theme-ui";

const RegistrationDetailsCard = ({ type, subtype, values }) => {
    return (
        <Card mt={3} mb={3} sx={{borderTop: '3px solid black'}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                p: 3,
            }}>
                <>
                    {type.includes('individual') && (
                        <Text sx={{ fontWeight: 'bold' }}>{`${values.title ?? ''} ${values.first_name ?? ''} ${values.middle_names ?? ''} ${values.last_name ?? ''}`}</Text>
                    )}
                    {type.includes('company') && (
                        <Text sx={{ fontWeight: 'bold' }}>{`${values.name ?? ''}`}</Text>
                    )}
                    {(type.includes('trust') || type.includes('superannuation')) && (
                        <>
                            {subtype.includes('individual') || subtype.includes('joint') ? (
                                <>
                                    <Text sx={{ fontWeight: 'bold' }}>
                                        {`${values.title ?? ''} ${values.first_name ?? ''} ${values.middle_names ?? ''} ${values.last_name ?? ''}`}
                                    </Text>
                                    { subtype.includes('joint') && (
                                        <Text sx={{ fontWeight: 'bold' }}>
                                            {`${values.trustee_2_title ?? ''} ${values.trustee_2_first_name ?? ''} ${values.trustee_2_middle_names ?? ''} ${values.trustee_2_last_name ?? ''}`}
                                        </Text>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Text sx={{ fontWeight: 'bold' }}>{`${values.name ?? ''}`}</Text>
                                </>
                            )}
                            <>
                                {values.designation && <Text sx={{ fontSize: 12 }}>{`<${values.designation ?? 'Blah'} A/C>`}</Text>}
                            </>
                        </>
                    )}
                    <Text sx={{ fontSize: 12 }}>{`${values.unit_number ?? ''} ${values.street_number ?? ''} ${values.street_name ?? ''} ${values.street_type ?? ''}`}</Text>
                    <Text sx={{ fontSize: 12 }}>{`${values.address2 ?? ''}`}</Text>
                    <Text sx={{ fontSize: 12 }}>{`${values.suburb ?? ''} ${values.state ?? ''}`}</Text>
                    {values.postcode && (
                        <Text sx={{ fontSize: 12 }}>{`Australia ${values.postcode ?? ''}`}</Text>
                    )}
                </>
            </Box>
        </Card>
    );
}

export default RegistrationDetailsCard;

