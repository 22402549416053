import axios from 'axios';
import { axiosAdviser } from 'utils/api';
import { grabFirstError } from 'utils/helpers';
import { toast } from 'utils/toast';

export const fetchAdviser = () =>
    axiosAdviser.get('user')
        .then(({ data }) => data);

export const fetchUser = () =>
    axios.get('user').then(({ data }) => {
        if (localStorage.getItem('authTokenScope') !== 'twoFactorRequired') {
            localStorage.setItem('authTokenScope', !data?.data?.finished_onboarding_excluding_certificate ? 'onboarding' : 'full');
        }
        return data;
    });

export const fetchUserAdvisers = () =>
    axios.get('user/advisers')
        .then(({ data }) => data);

export const fetchUserCoins = () =>
    axios.get('user/coins')
        .then(({ data }) => data);

export const fetchUserTransactions = (params) =>
    axios.get('user/transactions', params)
        .then(({ data }) => data);

export const fetchUserOpentrader = () =>
    axios.get('auth/crypt-token')
        .then(({ data }) => data);

export const loginUser = (params) =>
    axios.post('auth/login', params).then(({ data }) => {
        localStorage.setItem('authTokenScope', data?.two_factor ? 'twoFactorRequired' : 'full');
        localStorage.setItem('authToken', data.token);
        return data;
    });

export const loginAsInvestor = (params) =>
    axios.post('auth/login-as-investor', params).then(({ data }) => {
        localStorage.setItem('adviserToken', localStorage.getItem('authToken'));
        localStorage.setItem('authTokenScope', data?.two_factor ? 'twoFactorRequired' : 'full');
        localStorage.setItem('authToken', data.token);
        return data;
    });

export const loginBySingleAccessToken = (params) =>
    axios.post('auth/login-single-access', params).then(({ data }) => {
        localStorage.setItem('authTokenScope', 'full');
        localStorage.setItem('authToken', data.token);
        return data;
    });

export const loginTwoFactorChallengeUser = (params) =>
    axios.post('auth/two-factor-challenge', params).then(({ data }) => {
        localStorage.removeItem('authTokenScope');
        localStorage.setItem('authToken', data.token);
        return data;
    });

export const enableTwoFactor = (params) =>
    axios.post('user/two-factor-authentication', params)
        .then(({ data }) => data);

export const confirmTwoFactor = (params) =>
    axios.post('user/confirmed-two-factor-authentication', params)
        .then(({ data }) => data);

export const getTwoFactorQrCode = () =>
    axios.get('user/two-factor-qr-code')
        .then(({ data }) => data);

export const getTwoFactorRecoveryCodes = () =>
    axios.get('user/two-factor-recovery-codes')
        .then(({ data }) => data);

export const refreshTwoFactorRecoveryCodes = () =>
    axios.post('user/two-factor-recovery-codes')
        .then(({ data }) => data);

export const disableTwoFactor = () =>
    axios.delete('user/two-factor-authentication')
        .then(({ data }) => data);

export const onboardLogin = (params) =>
    axios.post('onboarding', params).then(({ data }) => {
        if (!data?.data?.finished_onboarding_details) {
            localStorage.setItem('authTokenScope', 'onboarding');
        }
        localStorage.setItem('authToken', data.token);
        return data;
    });

export const acceptanceLetterLogin = (params) =>
    axios.post('acceptance-letter', params).then(({ data }) => {
        localStorage.setItem('authTokenScope', 'acceptanceLetter');
        localStorage.setItem('authToken', data.token);
        return data;
    });

export const onboardToken = (params) =>
    axios.post('onboarding/token', params).then(({ data }) => {
        localStorage.setItem('authTokenScope', !data?.data?.finished_onboarding_details ? 'onboarding' : 'full');
        localStorage.setItem('authToken', data.token);
        return data;
    });

export const onboardStatus = () =>
    axios.get('onboarding/status').then(({ data }) => {
        if (!data?.data?.finished_onboarding_excluding_certificate) {
            localStorage.setItem('authTokenScope', 'onboarding');
        }
        return data;
    });

export const logout = (clearTokens = true) =>
    axios.post('auth/logout').then(() => {
        if(clearTokens){
            localStorage.removeItem('authTokenScope');
            localStorage.removeItem('authToken');
            localStorage.removeItem('activeProfile');
            localStorage.removeItem('onboardingFirstLoaded');
        }
    });

export const passwordReset = (params) =>
    axios.post('reset-password', params)
        .then(({ data }) => data);

export const forgotPassword = (params) =>
    axios.post('forgot-password', params)
        .then(({ data }) => data);

export const mobileSendVerification = (params) =>
    axios.post('onboarding/mobile-send-verification', params)
        .then(({ data }) => data);

export const mobileVerify = (params) =>
    axios.post('onboarding/mobile-verify', params)
        .then(({ data }) => data);

export const onboardingAccount = (params) =>
    axios.post('onboarding/account', params)
        .then(({ data }) => data);

export const onboardingAddress = (params) =>
    axios.post('onboarding/address', params)
        .then(({ data }) => data);

export const onboardingEntity = (params) =>
    axios.post('onboarding/entity', params)
        .then(({ data }) => data);

export const onboardingVerifyId = (params) =>
    axios.post('onboarding/verify-id', params)
        .then(({ data }) => data);

export const onboardingCertificate = (params) =>
    axios
        .post('onboarding/certificate', params, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(({ data }) => data);

export const storeProfilePhoto = (params) =>
    axios
        .post('user/profile-photo', params, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(({ data }) => data);

export const introShown = () =>
    axios.get('user/intro-shown')
        .then(({ data }) => data);

export const dismissHoldingsTransfer = () =>
    axios
        .get('user/holdings-transfer-dismissed')
        .then(({ data }) => data)
        .catch(({ response }) => {
            toast.error(grabFirstError(response.data?.errors) ?? response.data?.message, {
                toastId: 'dismiss-error',
            });
        });

export const storeAccountDetails = (params) =>
    axios.post('user/account', params).then(({ data }) => {
        localStorage.setItem('authToken', data.token);
        return data;
    });

export const storeAccountCertificate = (params, onProgress = undefined) =>
    axios
        .post('user/certificate', params, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: onProgress,
        })
        .then(({ data }) => data);

export const createProfile = (params) =>
    axios.post('profiles', params)
        .then(({ data }) => data);

export const updateProfile = (uuid, params) =>
    axios.post(`profiles/${uuid}`, params)
        .then(({ data }) => data);

export const storeProfileUpload = (uuid, field, params, onProgress = undefined) =>
    ['certificate', 'deed', 'application'].includes(field) ?
    axios
        .post(`profiles/${uuid}/${field}`, params, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: onProgress,
        })
        .then(({ data }) => data) : false;

export const storeProfileAccountManagementType = (uuid, params) =>
    axios.post(`profiles/${uuid}/account-management-type`, params)
        .then(({ data }) => data);

export const storeProfileExternalHins = (uuid, params) =>
    axios.post(`profiles/${uuid}/external-hins`, params)
        .then(({ data }) => data);

export const storeProfileSupportingDocuments = (uuid, params, onProgress = undefined) =>
    axios
        .post(`profiles/${uuid}/supporting-documents`, params, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: onProgress,
        })
        .then(({ data }) => data);

export const getProfileSupportingDocuments = (uuid) =>
    axios.get(`profiles/${uuid}/supporting-documents`)
        .then(({ data }) => data);

export const deleteProfileSupportingDocument = (uuid, document) =>
    axios.delete(`profiles/${uuid}/supporting-documents/${document}`)
        .then(({ data }) => data);

export const deleteProfile = (uuid) =>
    axios.delete(`profiles/${uuid}`)
        .then(({ data }) => data);

export const rejectEoi = (dealSlug) =>
    axios.post(`eoi-user/${dealSlug}`)
        .then(({ message }) => message);
