import { useRef } from 'react'
import {
    Box,
    Button,
    Flex,
    Heading,
    Image
} from '@theme-ui/components'
import { Icon } from 'assets/Icon'
import { Container, Markdown } from '~/Common'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const ImageGallery = ({ bg = 'white', data, container, sx, ...props }) => {
    const { t } = useTranslation();
    const title = data?.title ?? null;
    const description = data?.description ?? null;
    const images = data?.images ?? null;
    let slider = useRef(null);

    const settings = {
        dots: false,
        arrows: false,
        infinite: Array.isArray(images) ? images.length > 1 : false,
        accessibility: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true
    };

    return (
        <Box bg={bg} {...props} sx={{
            overflow: 'hidden',
            position: 'relative',
            ...sx
        }}>
            <Container variant="layouts.section" {...container}>
                {(title || description) && (
                    <Flex sx={{ minHeight: '50px' }}>
                        <Box>
                            { title && <Heading as="h2" variant="content_block_heading">{t(title)}</Heading>}
                            { description && <Box className="markdown" mb={3}>
                                <Markdown externalLinks={true}>{description}</Markdown>
                            </Box>}
                        </Box>
                    </Flex>
                )}

                {images &&
                    <Box sx={{
                        position: 'relative',
                        borderRadius: '16px',
                        overflow: 'hidden'
                    }}>
                        {Array.isArray(images) && images.length > 1 && (
                            <>
                                <Button onClick={(e) => slider.slickPrev()} variant="slickNav" sx={{ left: 0 }}>
                                    <Icon icon="chevron-left" size="19px" />
                                </Button>
                                <Button onClick={(e) => slider.slickNext()} variant="slickNav" sx={{ right: 0 }}>
                                    <Icon icon="chevron-right" size="19px" />
                                </Button>
                            </>
                        )}

                        <Slider ref={s => (slider = s)} {...settings}>
                            {images.map((image, key) => (
                                <Box key={key}>
                                    <Box sx={{
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '&:focus': {
                                            outline: '3px solid',
                                            outlineColor: 'tealDark',
                                            outlineOffset: '3px'
                                        }
                                    }}>
                                        <Image
                                            src={image?.image?.sizes?.slider}
                                            alt={image?.alt}
                                            title={image?.title}
                                            sx={{
                                                width: '100%',
                                                height: 'auto',
                                                objectFit: 'contain',
                                                borderRadius: '16px'
                                            }}
                                        />
                                        {image?.caption && (
                                            <Box
                                                className="caption"
                                                sx={{
                                                    mt: 3,
                                                    color: 'dark'
                                                }}
                                            >
                                                {image.caption}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Slider>
                    </Box>
                }
            </Container>
        </Box>
    )
};
