/** @jsxImportSource theme-ui */
import styled from '@emotion/styled';
import { Select } from '@theme-ui/components';
import { theme } from 'app/theme';

export const SelectStyled = styled(Select)`
    border: 1px solid ${(props) => (props.invalid ? theme.colors.error : theme.colors.medium)};
    transition: border-color 0.3s ease-in-out;
    width: 100%;
    font-size: 16px;
    text-overflow: ellipsis;
    padding-left: 14px;
    font-family: 'Favorit';
    border-radius: ${theme.forms.input.borderRadius};
    color: ${theme.colors.darker};

    &.is-empty:not(:focus) {
        color: ${theme.colors.dark};
    }

    &::-ms-clear {
        display: none;
    }

    ::-webkit-input-placeholder {
        color: ${theme.colors.placeholder};
    }

    :disabled {
        color: ${theme.colors.disabled};
        background-color: ${theme.colors.disabledBackground};
    }

    &:focus, &:hover {
        outline: none;
        border-color: ${theme.colors.tealDark};
    }

    &:focus {
        font-weight: 700;
    }
`;
