import { Button } from '~/Common'
import {
    Box,
    Heading,
    Paragraph
} from '@theme-ui/components';
import { Icon } from 'assets/Icon';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onboardToken, setUser } from 'features/auth/authSlice';
import { toast } from 'utils/toast';

const mapStateToProps = (state) => {
    return { user: state.user?.user }
}

const OnboardingFinished = ({ user }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const goToPortfolio = (e) => {
        e.preventDefault();

        onboardToken({
            device_name: 'App'
        }).then((response) => {
            dispatch(setUser(response));
            history.push('/portfolio');
        }).catch(({ response }) => {
            toast.error(t('Sorry we were unable to see that you have completed the required onboading steps. Please contact Liquidity for some assistance.'));
        });
    };

    return (
        <Box
            sx={{
                textAlign: 'center'
            }}
        >
            <Icon size={36} icon="check" color="tealLight" mb={3} />

            <Paragraph mb={[4,5]} sx={{
                fontSize: '22px',
                lineHeight: '29px',
                color: 'darker',
                fontWeight: 500,
                px: 4
            }}>
                {t('onboarding.landing.finished.pretitle')}
            </Paragraph>

            <Heading as="h1" px={2} mb={[4,5]} sx={{
                lineHeight: '43px'
            }}>
                {t('onboarding.landing.finished.title', {
                    verified: <u>verified</u>
                })}
            </Heading>

            <Paragraph mb={5} sx={{
                fontSize: '20px',
                color: 'dark'
            }}>
                {t('onboarding.landing.finished.description')}
            </Paragraph>

            <Button onClick={goToPortfolio} variant="primary" sx={{ width: '100%' }}>
                {t('onboarding.landing.finished.link')}
            </Button>
        </Box>
    )
};

export default connect(mapStateToProps, null)(OnboardingFinished);
