import { useRef, useState, useEffect } from 'react';
import {
    Box,
    Button,
    Flex,
    Spinner,
    Heading,
    Text
} from '@theme-ui/components'
import { Icon } from 'assets/Icon';
import { CSSTransition } from 'react-transition-group';
import { NavLink } from 'react-router-dom';

const TableWrapper = ({ children, sx, fixedWidth, ...props }) => {
    return (
        <Flex sx={{
            flexDirection: 'column',
            position: 'relative',
            p: 4,
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.06)',
            borderRadius: '16px',
            overflow: 'hidden',
            '& .tooltip__box': {
                zIndex: 50
            },
            '&::before': {
                content: '""',
                height: '6px',
                backgroundColor: t => t.colors.darker,
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0
            },
            '&::after': fixedWidth ? {
                content: '""',
                boxShadow: '10px 0px 20px -20px rgba(0, 0, 0, 0.6)',
                position: 'absolute',
                left: 0,
                bottom: 0,
                top: 0,
                width: fixedWidth,
                zIndex: 80,
                pointerEvents: 'none',
                p: '16px'
            } : {},
            ...sx
        }} {...props}>
            {children}
        </Flex>
    );
};

const TableColumn = ({ children, column, sx, ...props }) => (
    <Box className={column.fixed ? 'fixed' : ''} sx={{
        flex: 1,
        flexBasis: '100%',
        flexGrow: column.grow ?? 1,
        flexShrink: column.shrink ?? 1,
        minWidth: column.width ?? 'unset',
        maxWidth: column.width ?? 'unset',
        width: column.width ?? '100%',
        textAlign: column.align ?? 'left',
        justifyContent: column.align ?? 'left',
        position: column.fixed ? 'sticky' : 'initial',
        left: column.fixed ? 0 : 'unset',
        zIndex: column.fixed ? 50 : 'auto',
        transition: 'background 0.3s ease-in-out',
        py: 2,
        px: 2,
        display: 'flex',
        alignItems: 'center',
        ...sx,
        ...column.sx
    }} {...props}>
        {children}
    </Box>
);

const TableRow = ({ children, sx, ...props }) => {
    const rowRef = useRef(null);

    return (
        <CSSTransition
            nodeRef={rowRef}
            in={true}
            timeout={500}
            classNames="fade"
            unmountOnExit
            appear
        >
            <Flex ref={rowRef} sx={{
                borderBottom: '1px solid #D5D9DA',
                '& .fixed': {
                    bg: '#fff'
                },
                '&:last-of-type': {
                    borderBottom: 'none'
                },
                ...sx
            }} {...props}>
                {children}
            </Flex>
        </CSSTransition>
    )
};

export const Table = ({
    children,
    data,
    columns,
    showHeader = true,
    isLoading,
    emptyTemplate = false,
    rowProps,
    rowLink,
    minWidth = '1000px',
    sx,
    mapRowKey,
    prefix = 'table',
     ...props
 }) => {
    const hasRows = Array.isArray(data) && data.length > 0;
    const tableBody = useRef(null);
    const tableContainer = useRef(null);
    const [isOverflow, setOverflow] = useState(false);
    const [fixedWidth, setFixedWidth] = useState(false);

    const getRowKey = typeof mapRowKey === 'function' ? mapRowKey : (row, key) => key;

    const getRowProps = row => {
        var finalRowProps = {
            ...('function' === typeof rowProps ? rowProps(row) : rowProps)
        };

        finalRowProps.sx = {
            ...(finalRowProps?.to || finalRowProps?.href || rowLink ? {
                transition: 'background 0.3s ease-in-out',
                '&:hover': {
                    bg: t => t.colors.secondaryLight,
                    '& .fixed': {
                        bg: t => t.colors.secondaryLight
                    }
                }
            } : {}),
            position: 'relative',
            ...finalRowProps.sx
        };

        return finalRowProps;
    }

    useEffect(() => {
        const onResize = () => {
            const bodyWidth = tableBody.current.clientWidth;
            const contWidth = tableContainer.current.clientWidth;

            setOverflow(contWidth < bodyWidth);
        }

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [])

    useEffect(() => {
        if (!isOverflow) {
            setFixedWidth(null);
            return;
        }

        let hasFixed = false;

        columns.forEach(c => {
            // Currently only supports 1 fixed column
            if (c.fixed) {
                setFixedWidth(c.width);
                hasFixed = true;
            }
        });

        if (!hasFixed) {
            setFixedWidth(null);
        }
    }, [columns, isOverflow]);

    return (
        <TableWrapper fixedWidth={isOverflow && fixedWidth} sx={sx} {...props}>
            <Box ref={tableContainer} sx={{ overflowY: 'hidden', overflowX: 'auto', width: '100%' }}>
                <Box ref={tableBody} sx={{ minWidth: hasRows ? minWidth : 'none' }}>
                    {showHeader && hasRows && (
                        <TableRow sx={{
                            borderBottom: 'none'
                        }}>
                            {columns.map(column => (
                                <TableColumn
                                    key={`${prefix}${column.key}`}
                                    column={{
                                        fixed: column.fixed && isOverflow,
                                        ...column
                                    }}
                                    sx={{
                                        fontSize: '14px',
                                        color: 'dark',
                                        pb: 3
                                    }}
                                >
                                    {column.title}
                                </TableColumn>
                            ))}
                        </TableRow>
                    )}
                    {isLoading &&
                        <Flex sx={{
                            flexDirection: 'column',
                            bg: 'rgba(255, 255, 255, 0.5)'
                        }}>
                            <Spinner variant="styles.spinner" mx={'auto'} mt={4} />
                        </Flex>
                    }
                    {!isLoading && (
                        hasRows ? data.map((row, key) => (
                            <TableRow
                                key={getRowKey(row, key)}
                                {...getRowProps(row)}
                            >
                                {rowLink ? rowLink(row) : null}
                                {columns.map(column => (
                                    <TableColumn
                                        key={`${prefix}${column.key}`}
                                        column={{
                                            fixed: column.fixed && isOverflow,
                                            ...column
                                        }}
                                    >
                                        {'function' === typeof column.render ? column.render(row) ?? '-' : '-'}
                                    </TableColumn>
                                ))}
                            </TableRow>
                        )) : (
                            <Box px={2}>
                                {emptyTemplate && (
                                    <Flex sx={{
                                        py: 5,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}>
                                        {emptyTemplate?.icon && <Icon icon={emptyTemplate.icon} color="tealDark" mb={'28px'} width="84px" />}
                                        {emptyTemplate?.heading && <Heading variant="h2" sx={{ fontSize: ['28px','36px'], textAlign: 'center' }}>{emptyTemplate.heading}</Heading>}
                                        {emptyTemplate?.content && <Text mt={1} variant="subtext">{emptyTemplate.content}</Text>}
                                        {emptyTemplate?.button && (
                                            <Button
                                                as={NavLink}
                                                to={emptyTemplate?.button?.to || '/'}
                                                sx={{
                                                    minWidth: '160px',
                                                    mt: '56px'
                                                }}
                                            >
                                                {emptyTemplate?.button?.title || 'Back to dashboard'}
                                            </Button>
                                        )}
                                    </Flex>
                                )}
                                {children}
                            </Box>
                        )
                    )}
                </Box>
            </Box>
        </TableWrapper>
    );
};
