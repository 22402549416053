import React, { useContext, useEffect } from 'react';
import { Box, Text } from '@theme-ui/components';
import { CheckboxField } from '~/Forms/CheckboxField';
import { CurrencyField } from '~/Forms/CurrencyField';
import { CoinField } from '~/Forms/CoinField';
import { DateField } from '~/Forms/DateField';
import { GooglePlacesField } from '~/Forms/GooglePlacesField';
import { RecaptchaField } from '~/Forms/RecaptchaField';
import { UploadField } from '~/Forms/UploadField';
import { SignatureField } from '~/Forms/SignatureField';
import { SelectField } from '~/Forms/SelectField';
import { CreatableSelectField } from '~/Forms/CreatableSelectField';
import { SearchableSelectField } from '~/Forms/SearchableSelectField';
import { TextField } from '~/Forms/TextField';
import { VerifyField } from '~/Forms/VerifyField';
import { TabContext, TabGroupContext } from '~/Common/Tabs/TabContext';

export const Field = ({ field, groupSx, groupMb = 3, description, ...props }) => {
    const tab = useContext(TabContext);
    const tabGroup = useContext(TabGroupContext);
    const toggleTabField =tab?.toggleTabField

    const renderField = (field) => {
        switch (field) {
            case 'checkbox':
                return <CheckboxField {...props} />;
            case 'google_places':
                return  <GooglePlacesField {...props} />;
            case 'select':
                return <SelectField {...props} />;
            case 'creatable-select':
                return <CreatableSelectField {...props} />;
            case 'searchable-select':
                return <SearchableSelectField {...props} />;
            case 'verify':
                return <VerifyField {...props} />;
            case 'date':
                return <DateField {...props} />;
            case 'upload':
                return <UploadField {...props} />;
            case 'currency':
                return <CurrencyField {...props} />;
            case 'coin':
                return <CoinField {...props} />;
            case 'recaptcha':
                return <RecaptchaField {...props} />;
            case 'phone':
                return <TextField valueRegex={/\D/g} {...props} />;
            case 'signature':
                return <SignatureField {...props} />;
            case 'textarea':
                return <TextField rows={3} {...props} />;
            case 'text':
            default:
                return <TextField {...props} />;
        }
    };

    useEffect(() => {
        if (toggleTabField && tabGroup?.name) {
            toggleTabField(tabGroup?.name, props.name);
        }
    }, [toggleTabField, tabGroup?.name, props.name]);

    return (
        <Box sx={{ pt: 2, ...groupSx }} mb={groupMb}>
            {renderField(field)}
            {description && (
                <Box sx={{ fontSize: '13px', pt: 1 }}>
                    <Text sx={{ color: 'dark' }}>
                        {description}
                    </Text>
                </Box>
            )}
        </Box>
    );
};

Field.defaultProps = {
    groupSx: {
        position: 'relative'
    }
};
