import { useRef } from 'react'
import {
    Box,
    Card as ThemeCard,
    Grid,
    Flex,
    Heading,
    Image,
    Text
} from '@theme-ui/components';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Circle,
    ImagePlaceholder,
    Skeleton
} from '~/Common'
import { StatusTag } from '~/Deals'
import { useSelector } from 'react-redux'
import { CardButtons } from './CardButtons';
import { dealOfferPriceFormatting } from 'utils/helpers';
import { Icon } from 'assets/Icon';
import { useDealTypeDetail } from 'hooks/useDealTypeDetail';
import { useDisplayName } from 'hooks/useDisplayName';

export const Card = ({ deal, hideCardLink, ...props }) => {
    const nodeRef = useRef(null);
    const { t } = useTranslation();
    const user = useSelector((state) => state.user?.user);
    const valuation = deal?.asx_market_cap ? `${deal?.asx_market_cap}` : (deal?.valuation ? deal.valuation : null);
    const valuationLabel = deal?.asx_market_cap ? 'Market Cap' : 'Valuation';
    const dealDetail = useDealTypeDetail(deal);
    const dealDisplayName = useDisplayName(deal);
    const cardLink = user?.can_see_dashboard && (hideCardLink === true ? false : true);

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={true}
            timeout={500}
            classNames="fade"
            unmountOnExit
            appear
        >
            <ThemeCard
                as={!cardLink ? Box : Link}
                to={!cardLink ? undefined : `/deals/${deal?.slug}`}
                variant="deal"
                ref={nodeRef}
            >
                <Flex sx={{ position: 'relative', zIndex: 1 }}>
                    <Flex
                        sx={{
                            position: 'absolute',
                            top: 3,
                            left: 3,
                            zIndex: 10,
                            gap: 2,
                        }}
                    >
                        {deal ? (
                            <>
                                <StatusTag
                                    label={deal?.stage?.name}
                                    status={deal?.status?.id}
                                    stage={deal?.stage?.id}
                                />
                                {!dealDetail.alternateInvestment ? (
                                    <StatusTag
                                        label={deal?.asx || deal?.listed_on_alternate_exchange ? 'Listed' : 'Unlisted'}
                                        sx={{
                                            bg: 'light',
                                            color: 'darker',
                                        }}
                                    />
                                ) : null}
                            </>
                        ) : null}
                    </Flex>

                    {deal ? (
                        <>
                            {deal?.banner?.sizes?.medium ? (
                                <Box sx={{
                                    width: '100%',
                                    height: '168px'
                                }}>
                                    <Image src={deal?.banner?.sizes?.medium} sx={{ objectFit: 'cover', height: '100%', width: '100%' }} />
                                </Box>
                            ) : (
                                <ImagePlaceholder sx={{
                                    width: '100%',
                                    height: '168px'
                                }} />
                            )}
                        </>
                    ) : (
                        <Skeleton height="168px" />
                    )}
                </Flex>
                <Flex px={[3,3,3,4]} py={4} sx={{
                    position: 'relative',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flexGrow: 1
                }}>
                    <Circle size="70px" type="box" sx={{
                        position: 'absolute',
                        top: '-35px',
                        right: '24px',
                        bg: 'white',
                        zIndex: 10,
                        overflow: 'hidden',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)'
                    }}>
                        {deal ? (
                            <>
                                {deal?.company?.logo?.sizes?.tiny ? (
                                    <Image src={deal?.company?.logo?.sizes?.tiny} sx={{ borderRadius: '50%' }} />
                                ) : (
                                    <ImagePlaceholder sx={{
                                        width: '70px',
                                        height: '70px',
                                        borderRadius: '50%'
                                    }} />
                                )}
                            </>
                        ) : (
                            <Skeleton variant="circle" size="70px" />
                        )}
                    </Circle>

                    <Box mb={3} sx={{ flexGrow: 1 }}>
                        {deal ? <Text as="p" variant="tag" color="dark" mb={2} sx={{ fontSize: '14px' }}>{deal?.category?.name}</Text> : <Skeleton height="14px" width="50px" mb={2} />}
                        {deal ? <Heading as="h3" variant="h3">{t(dealDisplayName)}</Heading> : <Skeleton height="24px" width="70%" mb={2} />}
                        {deal ? <Text as="p" variant="small" color="dark" mb={2}>{deal?.code}</Text> : <Skeleton height="14px" width="50px" mb={2} />}
                    </Box>

                    <Box mb={3}>
                        {deal ? <Text as="p" variant="tag" color="dark" mb={2} sx={{ fontSize: '14px' }}>{t(dealDetail.managerText)}</Text> : <Skeleton height="14px" width="50px" mb={2} />}
                        {deal ? (
                            <>
                                {deal?.organisation ? (
                                    <>
                                        {deal?.organisation?.logo?.sizes?.tiny ? (
                                            <Image src={deal?.organisation?.logo?.sizes?.tiny} sx={{ filter: 'grayscale(1)', maxWidth: '100%' }} />
                                        ) : (
                                            <Text as="p" variant="tag" color="dark" mb={2} sx={{ fontSize: '14px' }}>{deal?.organisation?.name}</Text>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Icon icon="lqd" height="24" color="darker" />
                                    </>
                                )}
                            </>
                        ) : (
                            <Skeleton height="70px" width="50%" mb={2} />
                        )}
                    </Box>

                    {deal ? (
                        <Box>
                            {!deal?.preview_mode ? (
                                <>
                                    {(deal?.offer_price && !dealDetail.alternateInvestment) && (
                                        <Grid pb={3} sx={{
                                            borderBottom: t => `1px solid ${t.colors.medium}`,
                                            gridTemplate: 'auto / 131px auto'
                                        }}>
                                            <Box>
                                                <Text as="p" variant="labelCompact">{t('Offer Price')}</Text>
                                                <Text as="p" variant="price" color="darker" sx={{ fontSize: 3 }}>A${dealOfferPriceFormatting(deal?.offer_price)}</Text>
                                            </Box>
                                            <Box>
                                                <Text as="p" variant="labelCompact">{t('Offer Size')}</Text>
                                                <Text as="p" variant="price" color="darker" sx={{ fontSize: 3 }}>A${deal?.offer_size}</Text>
                                            </Box>
                                        </Grid>
                                    )}

                                    {dealDetail.alternateInvestment && (
                                        <Grid pb={3} sx={{
                                            borderBottom: t => `1px solid ${t.colors.medium}`,
                                            gridTemplate: 'auto / 151px auto'
                                        }}>
                                            <Box>
                                                <Text as="p" variant="labelCompact">{t('Minimum Investment')}</Text>
                                                <Text as="p" variant="price" color="darker" sx={{ fontSize: 3 }}>A${dealOfferPriceFormatting(deal?.min_bid_price)}</Text>
                                            </Box>
                                        </Grid>
                                    )}

                                    {dealDetail.showDescription ? (
                                        <Box py={3} mb={2}>
                                            {deal?.alternate_investment_description ? (
                                                <Text as="p" variant="body" color="darker" sx={{ fontSize: 2 }}>{t(deal?.alternate_investment_description)}</Text>
                                            ) : (
                                                <Box sx={{height: '40px'}} ></Box>
                                            )}
                                        </Box>
                                    ) : null}

                                    {(deal?.shares_available && !dealDetail.alternateInvestment) && (
                                        <Grid py={3} mb={2} sx={{
                                            gridTemplate: 'auto / 131px 130px auto',
                                            gridGap: 1
                                        }}>
                                            {valuation &&
                                                <Box>
                                                    <Text as="p" variant="labelCompact">{t(valuationLabel)}</Text>
                                                    <Text as="p" variant="price" color="darker" sx={{ fontSize: 2 }}>{valuation}</Text>
                                                </Box>
                                            }
                                            <Box>
                                                <Text as="p" variant="labelCompact">{t('Shares Available')}</Text>
                                                <Text as="p" variant="price" color="darker" sx={{ fontSize: 2 }}>{deal.shares_available}</Text>
                                            </Box>
                                            {deal?.asx_discount &&
                                                <Box>
                                                    <Text as="p" variant="labelCompact">{t('Discount')}</Text>
                                                    <Text as="p" variant="price" color="darker" sx={{ fontSize: 2 }}>{deal?.asx_discount}%</Text>
                                                </Box>
                                            }
                                        </Grid>
                                    )}
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        minHeight: '150px',
                                        pb: 3,
                                        fontSize: '18px',
                                        fontWeight: 500,
                                        lineHeight: '1.4',
                                        letterSpacing: '0.01em',
                                    }}
                                >
                                    {deal?.preview_text}
                                </Box>
                            )}
                        </Box>
                    ) : null}

                    <CardButtons link={!cardLink} deal={deal} user={user} viewButtonText={dealDetail.viewButtonText} alternateInvestment={dealDetail.alternateInvestment} />
                </Flex>
            </ThemeCard>
        </CSSTransition>
    );
};
