import React, { forwardRef, useEffect, useState } from 'react'
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Box } from 'theme-ui';
import { useField } from 'formik';
import { Label } from '~/Forms/Label';
import { useDebounce } from 'hooks/useDebounce';
import { Icon } from 'assets/Icon';
import { theme } from 'app/theme';

export const CreatableSelectField = forwardRef(
    (
        {
            label = null,
            showLabel,
            name,
            apiName,
            onChange,
            errors = null,
            placeholder,
            options,
            disabled,
            required = false,
            validIndicator,
            validationError,
            onChangeOverride,
            sx,
            nullable = false,
            ...props
        },
        ref
    ) => {
        const [field, meta, { setValue, setTouched }] = useField(name);
        const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);
        const [isFocused, setFocused] = useState(false);
        const [isHovered, setHovered] = useState(false);
        const fieldFocusedOrFilled = true;

        const isInvalid = (required && meta.value === '') || meta.error !== undefined;
        const fieldProps = { ...field, ...props };
        const currentValue = options ? options.find(option => option.value === field.value) ?? null : null;

        useEffect(() => {
            // If options are changed and value is missing, set Formik value to null
            if (field.value !== null && currentValue === null) {
                setValue(null);
            }
        }, [options, currentValue, field.value, setValue]);

        const customStyles = {
            control: (base, state) => ({
                ...base,
                // none of react-select's styles are passed to <Control />
                padding: '8px 6px',
                border: state.isFocused ? `1px solid ${theme.colors.tealDark}` : `1px solid ${theme.colors.medium}`,
                outline: 'none',
                borderRadius: theme.forms.input.borderRadius,
                backgroundColor: theme.forms.input.backgroundColor,
                fontSize: theme.forms.input.fontSize,
                fontWeight: isFocused ? 'bold' : 'normal',
                color: theme.colors.darker,
                fontFamily: theme.fonts.body,
                transition: 'border-color 0.3s ease-in-out',
                '&:hover': {
                    border: `1px solid ${theme.colors.tealDark}`,
                },
                boxShadow: 'none',
                marginBottom: 0
            }),
            input: (base) => ({
                ...base,
                '& input': {
                    fontWeight: isFocused ? 'bold' : 'normal'
                }
            }),
            indicatorSeparator: () => ({
                display: 'none'
            }),
            menu: (base) => ({
                ...base,
                zIndex: 1010,
                borderRadius: '8px',
                padding: '10px'
            }),
            option: (base, state) => ({
                ...base,
                color: theme.colors.darker,
                backgroundColor: state.isFocused ? theme.colors.secondaryLight : (state.isSelected ? theme.colors.secondaryLight : 'white'),
                borderRadius: '8px',
                '&:active': {
                    backgroundColor: theme.colors.secondaryLight
                }
            }),
            singleValue: (base) => ({
                ...base,
                fontSize: theme.forms.input.fontSize,
                fontWeight: isFocused ? 'bold' : 'normal',
                color: theme.colors.darker,
            }),
        }

        return (
            <Box
                sx={{ position: 'relative' }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <Label
                    label={label}
                    showLabel={showLabel}
                    fieldFocusedOrFilled={fieldFocusedOrFilled}
                    field={field}
                    focused={isFocused}
                    placeholder={placeholder}
                    meta={meta}
                    validationError={validationError}
                />

                <CreatableSelect
                    {...fieldProps}
                    {...props}
                    id={name}
                    name={name}
                    value={currentValue}
                    onChange={(item) => {
                        const { value } = item;
                        if (onChangeOverride) {
                            onChangeOverride(item);
                        } else {
                            setValue(value);

                            onChange &&
                                debouncedSave({
                                    name: apiName,
                                    value: value === null ? '' : value,
                                });
                        }
                        return item;
                    }}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                        setFocused(false);
                        setTouched(field.name, true);
                    }}
                    styles={customStyles}
                    options={options}
                    isDisabled={disabled}
                    components={{ DropdownIndicator: props => (
                        <components.DropdownIndicator {...props}>
                            <Icon color={isHovered || isFocused ? 'tealDark' : "dark"} icon="dropdown" size="14px" />
                        </components.DropdownIndicator>
                    ) }}
                    ref={ref}
                />
                {validIndicator === true && isInvalid && (
                    <Icon
                        icon="cross"
                        color="error"
                        sx={{
                            position: 'absolute',
                            right: '40px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 80
                        }}
                    />
                )}
            </Box>
        )
    }
)

CreatableSelectField.defaultProps = {
    validationError: true,
    placeholder: '',
    showLabel: true,
    controlledInput: true,
    validIndicator: true,
    required: false,
    disabled: false
};
