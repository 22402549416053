import axios from 'axios'

export const getPortfolioEquityHoldings = (params) =>
    axios.get('portfolio/equityholdings', params)
        .then(({ data }) => data)

export const getPortfolioDeals = (params) =>
    axios.get('portfolio/deals', params)
        .then(({ data }) => data)

export const getPortfolioStats = (params) =>
    axios.get('portfolio/stats', params)
        .then(({ data }) => data)
