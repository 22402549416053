import styled from '@emotion/styled';
import { theme } from 'app/theme';
import { Box } from '@theme-ui/components';

export const MenuDialog = styled(Box)`
    position: absolute;
    margin-top: 20px;
    background: #fff;
    width: 80%;
    max-width: 280px;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    transform: translate3d(0, -100%, 0);
    transition: transform 0.3s ease-in-out 0.1s,
                opacity 0.2s linear 0s,
                visibility 0s linear 0.3s;
    z-index: 99;
    overflow: visible;
    visibility: hidden;
    opacity: 0;
    padding: 16px;

    &.open{
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
    }

    & a{
        position: relative;
        display: inline-flex;
    }
    & a:focus{
        outline: none;
    }
    & a.active{
        color: ${theme.colors.darker};
    }

    & .header__switchProfile {
      right: 100%;
      top: 0;
      margin-right: 20px;
    }
`;

export const MenuUl = styled('ul')`
    margin: 0;
    padding: 0;

    & li {
        display: flex;
        align-items: center;
        margin: 0;
        margin-top: 8px;
        border-radius: 8px;
        list-style: none;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            background: ${theme.colors.secondaryLight};
        }

        &:first-of-type {
            margin-top: 0;
        }
    }
`;
