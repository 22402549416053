import React, { useEffect, useRef, useState } from 'react';
import { DashboardLayout } from '~/Layouts';
import {
    Container,
    PageHeader,
    PageBanner,
    Pagination,
    SwitchView,
    Tabs
} from '~/Common';
import {
    Box,
    Flex,
    Grid,
    Heading,
    Spinner,
    Text
} from '@theme-ui/components'
import {
    Card,
    CardCompact,
    List
} from '~/Deals';
import { Icon } from 'assets/Icon';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { InviteUserForm, Users, Invites } from '~/Advisers'
import { setItemLayout } from 'features/theme/themeSlice';
import { getOrganisationDeals, getOrganisationDealsAsync, getOrganisationClosedDealsAsync } from 'features/deal/dealSlice';
import { APP_FEATURES, DealStatus } from 'app/constants';
import { getInvitesAsync } from 'features/invites/invitesSlice';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { user: state.user?.user, adviserUsers: state.user?.user?.users, invites: state.invites, deals: state.deals, theme: state.theme }
}

const AdviserDashboard = ({ user, deals, adviserUsers, invites, theme }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Adviser Dashboard'));
    const dispatch = useDispatch();
    const myDeals = useRef(null);
    const [selectedTab, setSelectedTab] = useState('users');
    const [loading, setLoading] = useState(true);
    const smoothScroll = () => {
        window.scrollTo({ behavior: 'smooth', top: myDeals.current.offsetTop });
    };

    const changeItemLayout = (e, type) => {
        e.preventDefault();
        setLoading(true);

        setTimeout(() => {
            dispatch(setItemLayout(type));
            setLoading(false)
        }, 500);
    };

    const tabs = [
        {
            key: 'users',
            name: 'My Investors',
            icon: 'users',
            onClick: () => setSelectedTab('users')
        },
        {
            key: 'deals',
            name: 'My Deals',
            icon: 'deals',
            onClick: () => setSelectedTab('deals')
        }
    ];

    useEffect(() => {
        if (deals?.deals.organisation.status === 'idle') {
            dispatch(getOrganisationDealsAsync());
        }
        if (deals?.deals.organisation_closed.status === 'idle') {
            dispatch(getOrganisationClosedDealsAsync());
        }
        if (invites?.status === 'idle') {
            dispatch(getInvitesAsync());
        }
        if (deals?.deals.organisation.status === 'attempted' && deals?.deals.organisation_closed.status === 'attempted' && invites?.status === 'attempted'){
            setLoading(false);
        }
    }, [deals, invites, setLoading, dispatch]);

    return (
        <DashboardLayout p={0}>
            <PageBanner>
                <Heading as="h1" mt={5} mb={4} sx={{
                    color: t => t.colors.white
                }}>{t('dashboard.welcome', { name: user.first_name })}</Heading>
            </PageBanner>
            <PageHeader></PageHeader>
            <Container variant="layouts.default">
                <Tabs sx={{ mb: '40px' }} tabs={tabs} selected={selectedTab} />

                {selectedTab === 'users' ? <>
                    <Grid sx={{
                        alignItems: 'center',
                        gridTemplate: 'auto / repeat(2, 1fr)',
                        borderBottom: '1px solid',
                        borderColor: 'medium',
                        py: 3,
                        mb: 3
                    }}>
                        <Heading as="h2" variant="h3" id="myInvestors">{t('My Investors')}</Heading>
                    </Grid>
                    <Box mb={'38px'}>
                        {t('Below is the list of users you are authorised to log in on their behalf.')}
                    </Box>

                    <Users adviserUsers={adviserUsers} />

                    {APP_FEATURES.adviser_invites ? (
                        <>
                            <Box py={3}>
                                <InviteUserForm setLoading={setLoading} />
                            </Box>

                            <Invites invites={invites?.invites} />
                        </>
                    ) : null}
                </> : null}
                {selectedTab === 'deals' ? <>
                    <Grid sx={{
                        alignItems: 'center',
                        gridTemplate: 'auto / repeat(2, 1fr)',
                        borderBottom: '1px solid',
                        borderColor: 'medium',
                        py: 3,
                        mb: 3
                    }}>
                        <Heading as="h2" variant="h3" id="myDeals" ref={myDeals}>{t('My Deals')}</Heading>
                        <SwitchView selected={theme.itemLayout} onChange={changeItemLayout} />
                    </Grid>
                    <Box mb={'38px'}>
                        {t('Below is the list of deals your organisation manages.')}
                    </Box>

                    <Flex mb={[4, 5, 6]} sx={{
                        flexDirection: 'column',
                        position: 'relative',
                    }}>
                        {loading &&
                            <Flex sx={{
                                flexDirection: 'column',
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                bg: 'rgba(255, 255, 255, 0.5)',
                                left: 0,
                                top: 0,
                                zIndex: 100
                            }}>
                                <Spinner variant="styles.spinner" mx={'auto'} mt={4} />
                            </Flex>
                        }

                        {deals.deals.organisation.results.length > 0 &&
                            <>
                                <Grid sx={{
                                    gridGap: 4,
                                    gridTemplateColumns: theme.itemLayout === 'grid'
                                        ? ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']
                                        : '1fr',
                                    gridTemplateRows: '1fr',
                                    width: '100%',
                                    overflowX: 'auto',
                                    pt: '10px',
                                    pb: '30px',
                                    px: 3,
                                    mx: -3,
                                    boxSizing: 'content-box',
                                }}>
                                    {deals.deals.organisation.results.map((deal, key) => (
                                        <Box key={`deals-active-managed-result${key}`}>
                                            {theme.itemLayout === 'grid' ? (
                                                <Card hideCardLink={true} deal={deal} />
                                            ) : (
                                                <List hideCardLink={true} deal={deal} />
                                            )}
                                        </Box>
                                    ))}
                                </Grid>

                                <Pagination
                                    activePage={deals.deals?.organisation?.meta?.current_page ?? 0}
                                    pageCount={deals.deals?.organisation?.meta?.last_page ?? 0}
                                    onPageChange={(event) => {
                                        const payload = getOrganisationDeals({ params: {
                                            page: (event.selected + 1)
                                        }});
                                        smoothScroll();
                                        setLoading(true);
                                        dispatch(getOrganisationDealsAsync(payload))
                                    }}
                                />
                            </>
                        }

                        {(!loading && deals.deals.organisation.results.length === 0) &&
                            <Box px={2}>
                                <Flex sx={{
                                    py: 5,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <Icon icon={'lock-thin'} color="tealDark" mb={'28px'} width="84px" />
                                    <Heading variant="h2" sx={{ fontSize: '36px' }}>{t('deals.organisation.notFoundHeading')}</Heading>
                                    <Text mt={1} variant="subtext">{t('deals.organisation.notFound')}</Text>
                                </Flex>
                            </Box>
                        }
                    </Flex>

                    {deals.deals.organisation_closed.results.length > 0 ?
                        <Box>
                            <Flex sx={{
                                borderBottom: '1px solid',
                                borderColor: 'medium',
                                mb: 4,
                                pb: 1
                            }}>
                                <Heading as="h2" variant="h3" id="pastDeals">{t('Past Deals')}</Heading>
                            </Flex>

                            <Grid sx={{
                                gridGap: 4,
                                gridTemplateColumns: theme.itemLayout === 'grid' ? ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)'] : '1fr',
                                gridTemplateRows: '1fr',
                                width: '100%',
                                overflowX: 'auto',
                                pt: '10px',
                                pb: '30px',
                                px: 3,
                                mx: -3,
                                boxSizing: 'content-box',
                            }}>
                                {deals.deals.organisation_closed.results.map((row, key) => (
                                    <Box key={key}>
                                        {theme.itemLayout === 'grid' ? (
                                            <CardCompact deal={row} sx={{ height: '100%' }} />
                                        ) : (
                                            <List deal={row} />
                                        )}
                                    </Box>
                                ))}
                            </Grid>

                            <Pagination
                                activePage={deals.deals?.organisation_closed?.meta?.current_page ?? 0}
                                pageCount={deals.deals?.organisation_closed?.meta?.last_page ?? 0}
                                onPageChange={(event) => {
                                    const payload = getOrganisationDeals({ params: {
                                        status: DealStatus.closed,
                                        page: (event.selected + 1)
                                    }});
                                    setLoading(true);
                                    dispatch(getOrganisationClosedDealsAsync(payload))
                                }}
                            />
                        </Box>
                    : null}
                </> : null}
            </Container>
        </DashboardLayout>
    );
};

export default connect(mapStateToProps, null)(AdviserDashboard);
