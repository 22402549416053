import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardLayout } from '~/Layouts';
import {
    Container,
    PageBanner,
    Button,
} from '~/Common';
import {
    Box,
    Heading,
    Text,
    Divider,
    Link,
    Flex,
    Grid,
    Card,
    Spinner, Badge
} from '@theme-ui/components';
import { getUserTransactionsAsync } from 'features/auth/authSlice';
import { Icon } from 'assets/Icon';
import { useDispatch, connect } from 'react-redux';
import moment from 'moment-timezone';
import { useMounted } from 'hooks/useMounted';
import { CoinBalance } from '~/Common/CoinBalance';
import { ExperienceCard } from '~/Lounge/ExperienceCard';
import { TransactionsModal } from '~/Lounge/TransactionsModal';
import { LoungeModal } from '~/Lounge/LoungeModal';
import { getLoungeExperiencesAsync, getLoungeTradesAsync } from 'features/lounge/loungeSlice';
import { toTitleCase } from 'utils/helpers';
import { APP_FEATURES } from "app/constants";
import { ReactComponent as DollarSvg } from 'assets/Svg/DollarSign.svg';
import {useResponsiveValue} from "@theme-ui/match-media";
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return {
        user: state.user?.user,
        lounge: state.lounge,
    }
}

const CoinsDashboard = ({ user, lounge }) => {
    const modalRef = useRef();
    const [selected, setSelected] = useState(null)
    const [balance, setBalance] = useState(user.coins);
    const [showTransactionsModal, setShowTransactionsModal] = useState(false);
    const [transactionsLoading, setTransactionsLoading] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useDocumentTitle(t('Lounge'));
    const transactions = useRef(null);
    const isMounted = useMounted();
    const isDesktop = useResponsiveValue([false, false, false, false, true, true]);

    moment.tz.setDefault(user?.user?.timezone);

    const refreshTransactions = useCallback(() => {
        setTransactionsLoading(true);
        dispatch(getUserTransactionsAsync()).then(() => {
            if (isMounted) {
                setTransactionsLoading(false)
            }
        });
    }, [dispatch, isMounted]);

    useEffect(refreshTransactions, [refreshTransactions]);

    useEffect(() => {
        if(lounge.experiences?.status === 'idle'){
            setLoading(true);
            dispatch(getLoungeExperiencesAsync());
            dispatch(getLoungeTradesAsync());
        }
        if (lounge.experiences?.status === 'attempted' && lounge.trades?.status === 'attempted') {
            setLoading(false);
        }
    }, [lounge, dispatch]);

    const handleCloseTransactionsModal = () => {
        setShowTransactionsModal(false);
    }

    const transCount = user?.transactions?.meta?.total;

    return (
        <DashboardLayout p={0}>
            <PageBanner>
                <Heading as="h1" mt={5} mb={4} sx={{
                    color: 'white'
                }}>{t('liquidityLounge.title')}</Heading>
                <Box mb={4} sx={{
                    borderRadius: '16px',
                    top: '227px',
                    width: '100%',
                    background: 'white',
                    height: ["auto", "auto", "auto", "130px"]
                }}>
                    <Flex sx={{
                        width: '100%',
                        alignItems: 'flex-start',
                        flexDirection: ['column', 'column', 'column', 'row'],
                    }}>
                        <Flex sx={{
                            borderRadius: ["16px 16px 0 0", "16px 16px 0 0", "16px 16px 0 0", "16px 0 0 16px"],
                            width: ["100%", "100%", "100%", "285px"],
                            height: ["auto", "auto", "auto", "130px"],
                            background: "linear-gradient(0deg, rgba(213, 217, 218, 0.8), rgba(213, 217, 218, 0.8)), url(img/image-placeholder.jpg)",
                            alignSelf: "stretch",
                            flexGrow: 0,
                            position: "relative",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Text color="primary" sx={{
                                position: "absolute",
                                width: "149px",
                                height: "30px",
                                left: "calc(50% - 129px/2)",
                                top: "calc(50% - 30px/2)",
                                fontSize: "14px",
                                lineHeight: "110%",
                                /* or 15px */
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                letterSpacing: "0.6em",
                            }}>
                                REDEEM FOR CASH
                            </Text>
                            <DollarSvg></DollarSvg>
                        </Flex>
                        <Flex sx={{
                            marginY: "auto",
                            justifyContent: ['flex-start', 'flex-start', 'flex-start', 'space-between'],
                            width: "100%",
                            padding: "16px",
                            gap: ["16px", "16px", "16px", "16px", "32px"],
                            flexDirection: ['column', 'column', 'column', 'row'],
                        }}>
                            <Flex sx={{
                                flexDirection: "column",
                                width: ["100%", "100%", "100%", "587px"],
                                height: "auto",
                                alignItems: ["center", "center", "center", "flex-start", "flex-start"],
                                justifyContent: ["center", "center", "center", "flex-start", "flex-start"],
                                textAlign: ["center", "center", "center", "left", "left"],
                            }}>
                                <Text as="h2" sx={{
                                    fontSize: "28px",
                                    fontWeight: "500"
                                }}>Redeem for cash</Text>
                                {user?.can_see_trade_link ? (
                                    <Text as="p" color="primary">Now you can redeem your Liquidity Points (LQD) for cash at any time.</Text>
                                ) : (
                                    <Text as="p" color="red">You must complete your bank details setup before you can redeem your Liquidity Points (LQD) for cash.</Text>
                                )}

                            </Flex>
                            <Flex sx={{
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                {isDesktop && (<Text color="primary">Your liquidity points</Text>)}
                                <Badge variant="coins" className="header__coinsBadge">
                                    <Icon icon="coin" size="25" />
                                    <Text sx={{
                                        minWidth: '40px',
                                        textAlign: 'center',
                                        fontSize: 2,
                                        letterSpacing: '-0.01em',
                                        lineHeight: '25px',
                                        px: 2
                                    }}>{user.coins ?? 0 }</Text>
                                    <Text className="sr-only">{t('liquidityDollars.title')}</Text>
                                </Badge>
                            </Flex>
                            <Flex sx={{
                                alignItems: "center",
                                justifyContent: "center",
                            }} onClick={() => {
                                setSelected({type: 'cash'});
                            }}>
                                <Button
                                    size={isDesktop ? 'large' : 'default'}
                                    {...(user?.finished_bank_setup ? {} : {disabled: true})}
                                >
                                    Redeem Now
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Box>
                <Divider color="white" />
                <Flex>
                    <Icon icon="ticket" size="20px" color="white" mr={1} />
                    <Text
                        as={Link}
                        color="white"
                        sx={{ fontWeight: 'bold' }}
                        onClick={() => {
                            setShowTransactionsModal(true);
                        }}
                    >{transCount} transaction{transCount !== 1 && 's'}</Text>
                </Flex>
            </PageBanner>
            <Container variant="layouts.default">
                <Box py={3}>
                    {APP_FEATURES.free_trades && (
                        <>
                            <Heading variant="h4" mb={3} sx={{
                                color: 'darker'
                            }}>{t('Free Trades')}</Heading>
                            <Divider color="#D5D9DA" mb="40px" />
                            <Grid sx={{
                                gridTemplateColumns: isLoading ? 'repeat(1, 1fr)' : ['repeat(1, 1fr)', null, 'repeat(2, 1fr)', null, 'repeat(4, 1fr)'],
                                pb: 4,
                            }}>
                                {isLoading && Array.isArray(lounge.trades?.results) ? (
                                        <Spinner variant="styles.spinner" m={'auto'} />
                                    ) :
                                    lounge.trades.results.length ? lounge.trades.results.map((trade) => (
                                        <Card
                                            key={trade.name}
                                            as={Link}
                                            variant="link"
                                            sx={{
                                                padding: '24px',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                            onClick={() => {
                                                setSelected({
                                                    type: 'trade',
                                                    trade,
                                                })
                                            }}
                                        >
                                            <Text sx={{ fontSize: '22px', fontWeight: 500 }}>{toTitleCase(trade.name)}</Text>
                                            <CoinBalance balance={trade.price} showInfo={false} small={true} />
                                        </Card>
                                    )) : (
                                        <Box>
                                            {t('liquidityLounge.trades.noResults')}
                                        </Box>
                                    )}
                            </Grid>
                        </>
                    )}

                </Box>
                <Box py={3}>
                    <Heading variant="h4" mb={3} sx={{
                        color: 'darker'
                    }}>Experiences</Heading>
                    <Divider color="#D5D9DA" mb="40px" />
                    <Grid sx={{
                        gridTemplateColumns: isLoading ? 'repeat(1, 1fr)' : ['repeat(1, 1fr)', null, 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
                        gridGap: 4,
                        pb: 4,
                    }}>
                        {isLoading && Array.isArray(lounge.experiences?.results) ? (
                            <Spinner variant="styles.spinner" m={'auto'} />
                        ) :
                        lounge.experiences.results.length ? lounge.experiences.results.map((experience) => (
                            <ExperienceCard
                                key={experience.name}
                                experience={experience}
                                onClick={() => {
                                    setSelected({
                                        type: 'experience',
                                        experience,
                                    });
                                }}
                            />
                        )) : (
                            <Box>
                                {t('liquidityLounge.experiences.noResults')}
                            </Box>
                        )}
                    </Grid>
                </Box>
            </Container>
            <TransactionsModal
                isOpen={showTransactionsModal}
                handleClose={handleCloseTransactionsModal}
                contentRef={node => (modalRef.current = node)}
                loading={transactionsLoading}
                transactions={transactions}
            />
            <LoungeModal
                isOpen={!!selected}
                handleClose={() => {
                    setSelected(null);
                    setBalance(user?.coins);
                }}
                data={selected}
                onPurchase={() => refreshTransactions()}
                balance={balance}
                setBalance={setBalance}
            />
        </DashboardLayout>
    );
};

export default connect(mapStateToProps, null)(CoinsDashboard);
