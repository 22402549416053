import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Link } from 'theme-ui';
import { toast } from 'utils/toast';
import { Field } from '~/Forms';
import { setUser, storeProfileUpload } from 'features/auth/authSlice';
import { Alert } from '~/Common';

const UploadCertificateUncoupled = ({ theme, setUser, currentCertificate, currentCertificateStatus, ...props }) => {
    const { t } = useTranslation();
    const [certUploadProgress, setCertUploadProgress] = useState(0);
    const { values, setStatus, setFieldValue } = useFormikContext();
    const currentCertificateExpired = currentCertificateStatus === 'Expired';
    const uuid = values?.uuid;

    const onUploadProgress = (p) => {
        setCertUploadProgress((p.loaded / p.total) * 100);
    };

    const handleUploadCertificate = ({ name, value, setStatus }) => {
        let formdata = new FormData();
        formdata.append(name, value);

        storeProfileUpload(uuid, 'certificate', formdata, onUploadProgress)
            .then((response) => {
                setFieldValue(name, value);
                toast.success(t('settings.certificate.success'), {
                    toastId: 'certificate-success',
                });
                setUser(response);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || t('settings.certificate.error'), {
                    toastId: 'certificate-error',
                });
                setStatus('api_error');
            });
    };

    return (
        <>
            {theme.settings.certificate_template && (
                <Box
                    mb={3}
                    sx={{
                        color: 'dark',
                        fontSize: '13px',
                        '& a': {
                            color: 'tealDark',
                        },
                    }}
                >
                    {t('Need help?')}{' '}
                    <Link href={theme.settings.certificate_template} target="_blank" variant="light">
                        {t('Download Certificate Template')}
                    </Link>
                </Box>
            )}

            {Boolean((!values.certificate && !currentCertificate) || currentCertificateExpired) ? (
                <>
                    {currentCertificateExpired ? (
                        <Alert mt={2} mb={4} isCloseable={false} variant="warning" icon="certificate-alt" sx={{ display: 'inline-flex' }}>
                            {t('Your certificate has expired.')} <Link href={currentCertificate} target="_blank">View Current File</Link>
                        </Alert>
                    ) : null}

                    <Field
                        field="upload"
                        name="certificate"
                        label="Upload 708 certificate"
                        showLabel={false}
                        uploadProgress={certUploadProgress}
                        onChange={(props) =>
                            handleUploadCertificate({
                                setStatus,
                                setFieldValue,
                                ...props,
                            })
                        }
                    />
                </>
            ) : (
                <Alert mt={2} mb={4} isCloseable={false} variant="success" icon="certificate-alt" sx={{ display: 'inline-flex' }}>
                    {t('Your certificate has been uploaded.')} <Link href={currentCertificate} target="_blank">View File</Link>
                </Alert>
            )}
        </>
    );
};

export const UploadCertificate = connect(
    (state) => ({
        theme: state.theme,
    }),
    { setUser }
)(UploadCertificateUncoupled);
