import { useRef, useState, useEffect } from 'react'
import {
    Box,
    Flex,
    Grid,
    Heading,
    Spinner,
    Text
} from '@theme-ui/components'
import { Button, SaveButtons } from '~/Common'
import { SettingsLayout } from '~/Layouts'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, NavLink } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import * as Yup from 'yup'
import { FieldArray, Form, Formik } from 'formik'
import { toast } from 'utils/toast'
import { Field, Submit } from '~/Forms'
import { Icon } from 'assets/Icon'
import { setUser, storeProfileExternalHins } from 'features/auth/authSlice'
import { getNestedErrors } from 'utils/helpers';
import { useDocumentTitle } from 'hooks/useDocumentTitle'

const mapStateToProps = (state) => {
    return { user: state.user?.user, userLoaded: state.user.status === 'idle' };
};

const validationSchema = Yup.object().shape({
    external_hins: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().nullable(),
            hin_number: Yup.string().required('HIN number required'),
        })
    )
});


const SettingsProfilesConnectedHins = ({ user, userLoaded }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Connected HINs'));
    const { uuid } = useParams();
    const dispatch = useDispatch();
    const nodeRef = useRef(null);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);

    const handleSubmit = ( values, actions ) => {
        let formParams = {
            external_hins: values.external_hins ?? null
        };

        storeProfileExternalHins(uuid, formParams).then((response) => {
            toast.success(t('settings.profile_hins.success'), {
                toastId: 'profile_hins-success',
            });
            dispatch(setUser(response));
            actions.setSubmitting(false);

        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(getNestedErrors(response.data.errors));
            toast.error(response?.data?.message || t('settings.profile_hins.success'), {
                toastId: 'profile_hins-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    };

    const createRow = (values, push) => {
        push({
            name: '',
            hin_number: ''
        })
    };

    useEffect(() => {
        if (userLoaded) {
            const existingProfile = user.profiles.filter((p) => p.uuid === uuid)?.[0] ?? false;

            if (existingProfile) {
                setProfile(() => existingProfile);
            }
            setLoading(false);
        }
    }, [userLoaded, user, uuid]);

    return (
        <SettingsLayout>
            {loading ? (
                <Box id="settingsLoading"><Spinner variant="styles.spinner" m={'auto'} /></Box>
            ) : (
                <CSSTransition nodeRef={nodeRef} in={true} timeout={500} classNames="fade" unmountOnExit appear>
                    <Box ref={nodeRef}>
                        {profile ? (
                            <>
                                <Flex sx={{ alignItems: 'center', justifyContent: 'flex-start' }} mb={4}>
                                    <Box>
                                        <Heading as="h1" variant="h2" mb={2}>
                                            {t('settings.profile_hins.title', {
                                                type: profile?.type_name,
                                                name: profile?.name
                                            })}
                                        </Heading>
                                        <Heading as="p" variant="h4">
                                            {t('settings.profile_hins.subtitle')}
                                        </Heading>
                                    </Box>
                                </Flex>

                                <Box mb={5}>
                                    <Heading as="h2" variant="section_heading" mb={2}>{t('settings.profile_hins.sections.liquidity.title')}</Heading>

                                    <Text as="p">
                                        {profile?.hin_number ? profile?.hin_number : (
                                            profile.type === 'individual' && user.skip_individual_trade_account ? t('settings.profile_hins.sections.liquidity.skip_individual_trade_account') : (
                                                profile?.submitted_at ? t('settings.profile_hins.sections.liquidity.hin_in_progress') : t('settings.profile_hins.sections.liquidity.onboarding_required')
                                            )
                                        )}
                                    </Text>

                                    {profile?.hin_number === null ? (
                                        <Button
                                            variant="light"
                                            sx={{
                                                py: '10px',
                                                mt: 4
                                            }}
                                            onClick={() => {
                                                history.push(profile.type === 'individual' ? '/settings/account' : `/settings/entities/${profile.uuid}`);
                                            }}
                                        >
                                            {t(profile.submitted_at ? 'Manage Entity' : (profile.locked_at ? 'Review Entity' : 'Continue Onboarding'))}
                                        </Button>
                                    ) : null}
                                </Box>

                                <Box mb={5}>
                                    <Heading as="h2" variant="section_heading" mb={3}>{t('settings.profile_hins.sections.alternate.title')}</Heading>
                                    <Text as="p" mb={4}>{t('settings.profile_hins.sections.alternate.subtitle')}</Text>

                                    <Formik
                                        initialValues={{
                                            external_hins: profile?.external_hins ?? [],
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ values, setFieldValue, dirty, errors }) => {
                                            const hasHins = Boolean(Array.isArray(values.external_hins) && values.external_hins.length > 0);

                                            return (
                                                <Form>
                                                    <FieldArray name="external_hins">
                                                        {({ remove, push }) => (
                                                            <>
                                                                {hasHins ? (
                                                                    <>
                                                                        {values.external_hins.map((hin, index) => (
                                                                            <Box key={`profile.${profile?.uuid}.external_hins.${index}`}>
                                                                                <Grid sx={{
                                                                                    gridGap: [2, 3],
                                                                                    gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
                                                                                    width: '100%'
                                                                                }}>
                                                                                    <Field
                                                                                        label="HIN Number *"
                                                                                        name={`external_hins.${index}.hin_number`}
                                                                                        onKeyUp={e => setFieldValue(`external_hins.${index}.hin_number`, e.target.value ? `X${e.target.value.replace(/[^0-9]/g, '')}` : '')}
                                                                                    />
                                                                                    <Field
                                                                                        label="Reference Name"
                                                                                        name={`external_hins.${index}.name`}
                                                                                        value={hin.name ?? ''}
                                                                                    />
                                                                                    <Flex>
                                                                                        <Button
                                                                                            sx={{ display: 'inline-flex', alignItems: 'center'  }}
                                                                                            onClick={() => remove(index)}
                                                                                            size="small"
                                                                                            mt={2}
                                                                                            mb={3}
                                                                                        >
                                                                                            {t('Remove')}
                                                                                        </Button>
                                                                                    </Flex>
                                                                                </Grid>
                                                                            </Box>
                                                                        ))}
                                                                    </>
                                                                ) : null}

                                                                <Button
                                                                    sx={{ display: 'inline-flex', alignItems: 'center'  }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        createRow(values, push);
                                                                    }}
                                                                >
                                                                    <Icon icon="plus" size="12" mr={2} />
                                                                    {t('Add Additional HIN')}
                                                                </Button>
                                                            </>
                                                        )}
                                                    </FieldArray>

                                                    {dirty ?
                                                        <SaveButtons>
                                                            <Submit
                                                                variant="primary"
                                                                text={t('Save')}
                                                            />
                                                            <Button
                                                                as={NavLink}
                                                                to="/settings/entities"
                                                                variant="light"
                                                            >
                                                                {t('buttons.discard')}
                                                            </Button>
                                                        </SaveButtons>
                                                    : null}
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </Box>
                            </>
                        ) : (
                            <Box mb={4}>
                                <Heading as="h1" variant="h2" mb={2}>
                                    {t('settings.profile_hins.notFound.title')}
                                </Heading>
                                <Heading as="p" variant="h4" mb={4}>
                                    {t('settings.profile_hins.notFound.description')}
                                </Heading>

                                <Button
                                    as={NavLink}
                                    to="/settings/entities"
                                    variant="light"
                                >
                                    {t('Back to my entities')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </CSSTransition>
            )}
        </SettingsLayout>
    );
};

export default connect(mapStateToProps, null)(SettingsProfilesConnectedHins);

