import { useCallback, useContext, useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Close,
    Heading,
    Grid,
    Spinner,
    Text,
    Themed,
    Flex,
} from 'theme-ui';
import { Alert as StyledAlert } from '~/Common';
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { OnboardingContext } from 'pages/Onboarding/Landing';
import { onboardingAccount, getOnboardingStatusAsync } from 'features/auth/authSlice';
import { getProfileOptionsAsync, getCountriesAsync, setOnboardingType, setOnboardingSubtype, utilStateByCountry } from 'features/theme/themeSlice'
import { toast } from 'utils/toast';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { Yup, addressValidationNullable } from 'utils/validationRules';
import { Button } from '~/Common';
import { Icon } from 'assets/Icon';
import { ProfileMainTypes, ProfileSubTypes } from 'app/constants';

const mapStateToProps = (state) => {
    return { user: state.user, theme: state.theme }
}

const validationSchema = Yup.object().shape({
    title: Yup.string().required('required field'),
    first_name: Yup.string().required('required field'),
    middle_names: Yup.string().nullable(),
    last_name: Yup.string().required('required field'),
    dob: Yup.string().test(
            'dob',
            'you must be at least 18',
            (value) => {
                return (
                    moment().diff(moment(value), 'years') >=
                    18
                );
            }
        )
        .required('required field'),
    citizenship_country: Yup.string().required('required field'),
    country_of_birth: Yup.string().required('required field'),
    macquarie_occupation_type: Yup.string().required('required field'),
    macquarie_occupation_category: Yup.string().required('required field'),
    source_of_wealth: Yup.string().required('required field'),
    business_owner_trading_name: Yup.string().when('macquarie_occupation_type', {
        is: 'Business Owner',
        then: Yup.string().required('required field')
    }),
    business_owner_address: addressValidationNullable,
    password: Yup.string().required().test('len', 'must be at least 8 characters', val => val !== undefined && val.length >= 8),
    password_confirmation: Yup.string().required('required field').oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const OnboardingPersonal = ({ user, theme }) => {
    const onboarding = useContext(OnboardingContext);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [profileOptions, setProfileOptions] = useState(theme.profile.options);
    const [countries, setCountries] = useState(theme.countries.options);
    const [occupationCategories, setOccupationCategories] = useState(theme.profile.options.macquarieOccupations?.[user?.user?.individual?.macquarie_occupation_type] ?? []);
    const [stateOptions, setStateOptions] = useState([]);
    const [showAddress, setShowAddress] = useState(false);
    const [selected, setSelected] = useState('individual');
    const [subtype, setSubtype] = useState('');

    const getOptions = useCallback(() => {
        if (theme.profile.status === 'loading') {
            dispatch(getProfileOptionsAsync());
        }
        if (theme.countries.status === 'loading') {
            dispatch(getCountriesAsync());
        }
    }, [theme, dispatch]);

    const getStates = useCallback((country) => {
        utilStateByCountry(country ?? 'AU').then((response) => {
            setStateOptions(response?.data);
        }).catch(() => {
            setStateOptions([])
        });
    }, [setStateOptions]);

    useEffect(() => {
        getStates();
    }, [getStates]);

    useEffect(() => {
        getOptions();

        if (theme.profile.status === 'attempted' && theme.countries.status === 'attempted') {
            setProfileOptions(theme.profile.options);
            setCountries(theme.countries.options);
            setLoading(false);
        }

    }, [theme, getOptions]);

    const handleSubmit = (values, actions) => {
        setLoading(true);
        onboardingAccount({
            title: values.title,
            first_name: values.first_name,
            middle_names: values.middle_names,
            last_name: values.last_name,
            dob: values.dob,
            citizenship_country: values.citizenship_country ?? 'AU',
            country_of_birth: values.country_of_birth ?? 'AU',
            tax_country: 'AU',
            macquarie_occupation_type: values.macquarie_occupation_type,
            macquarie_occupation_category: values.macquarie_occupation_category,
            source_of_wealth: values.source_of_wealth,
            business_owner_trading_name: values.business_owner_trading_name,
            business_owner_address: {
                unit_number: values?.business_owner_address?.unit_number ?? null,
                street_number: values?.business_owner_address?.street_number ?? null,
                street_name: values?.business_owner_address?.street_name ?? null,
                street_type: values?.business_owner_address?.street_type ?? null,
                address2: values?.business_owner_address?.address2 ?? null,
                state: values?.business_owner_address?.state ?? null,
                suburb: values?.business_owner_address?.suburb ?? null,
                postcode: values?.business_owner_address?.postcode ?? null,
                country: values?.business_owner_address?.country ?? 'AU'
            },
            password: values.password,
            password_confirmation: values.password_confirmation,
            skip_individual_trade_account: selected.toLowerCase() !== 'individual',
        }).then((response) => {
            toast.success(t('onboarding.landing.personal.success'), {
                toastId: 'personal-success',
            });
            onboarding.nextStep();
            dispatch(getOnboardingStatusAsync());
            setLoading(false);
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(response?.data?.message || t('onboarding.landing.personal.error'), {
                toastId: 'personal-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
            setLoading(false);
        });
    };

    const updateOccupationCategories = (e) => {
        setOccupationCategories(theme.profile.options.macquarieOccupations[e.value] ?? []);
    };

    return (
        <>
            {loading && <Spinner variant="styles.spinner" m={'auto'} />}

            <Box sx={{
                display: loading ? 'none' : 'block'
            }}>
                <Formik
                    enableReinitialize
                    initialValues={{
                        title: user?.user?.individual?.title ?? '',
                        first_name: user?.user?.first_name ?? '',
                        middle_names: user?.user?.middle_names ?? '',
                        last_name: user?.user?.last_name ?? '',
                        email: user?.user?.email ?? '',
                        dob: user?.user?.individual?.dob ?? '',
                        citizenship_country: user?.user?.citizenship_country ?? 'AU',
                        country_of_birth: user?.user?.country_of_birth ?? 'AU',
                        tax_country: 'AU',
                        macquarie_occupation_type: user?.user?.individual?.macquarie_occupation_type ?? '',
                        macquarie_occupation_category: user?.user?.individual?.macquarie_occupation_category ?? '',
                        source_of_wealth: user?.user?.individual?.source_of_wealth ?? '',
                        business_owner_trading_name: user?.user?.individual?.business_owner_trading_name ?? '',
                        business_owner_address: {
                            unit_number: user?.user?.individual?.business_owner_address?.unit_number ?? '',
                            street_number: user?.user?.individual?.business_owner_address?.street_number ?? '',
                            street_name: user?.user?.individual?.business_owner_address?.street_name ?? '',
                            street_type: user?.user?.individual?.business_owner_address?.street_type ?? '',
                            address2: user?.user?.individual?.business_owner_address?.address2 ?? '',
                            state: user?.user?.individual?.business_owner_address?.state ?? '',
                            suburb: user?.user?.individual?.business_owner_address?.suburb ?? '',
                            postcode: user?.user?.individual?.business_owner_address?.postcode ?? '',
                            country: user?.user?.individual?.business_owner_address?.country ?? 'AU'
                        },
                        password: '',
                        password_confirmation: '',
                        type: '',
                        subtype: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ status, setStatus, values, setFieldValue }) => (
                        <Box id="onboardingPersonal">
                            { status === 'api_error' ?
                            <Alert role="alert" variant='danger' mb={2}>
                                {t('onboarding.landing.personal.error')}
                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                            </Alert> : ''}

                            <Form>
                                <Heading as="h1" variant="h3" sx={{ fontWeight: 500 }} mb={4}>
                                    {t('onboarding.landing.personal.title')}
                                </Heading>

                                <Text as="p" mb={3} sx={{
                                    fontSize: 2,
                                    color: 'dark'
                                }}>
                                    {t('onboarding.landing.personal.subtitle')}
                                </Text>

                                <Heading as="h2" variant="form_section" sx={{
                                    fontWeight: 600,
                                    letterSpacing: '-0.01em',
                                    mb: 3,
                                }}>
                                    {t('Your details')}
                                </Heading>

                                <StyledAlert mb={3} isCloseable={false}>{t('onboarding.landing.personal.legal_name')}</StyledAlert>
                                {profileOptions.titles.length > 0 &&
                                    <Field
                                        label="Title"
                                        placeholder="Select title"
                                        name="title"
                                        field="searchable-select"
                                        options={profileOptions.titles}
                                        required={true}
                                    />
                                }
                                <Field
                                    label="First name"
                                    name="first_name"
                                    required={true}
                                />
                                <Field
                                    label="Middle names"
                                    name="middle_names"
                                />
                                <Field
                                    label="Last name"
                                    name="last_name"
                                    required={true}
                                />
                                <Field
                                    label="Email"
                                    name="email"
                                    readOnly="readonly"
                                />

                                <Heading as="h2" variant="form_section" sx={{
                                    fontWeight: 600,
                                    letterSpacing: '-0.01em',
                                    mb: 3,
                                    mt: 4
                                }}>
                                    {t('Date of birth')}
                                </Heading>

                                <Field
                                    field="date"
                                    label="Date of birth"
                                    name="dob"
                                    dateOfBirth={true}
                                />

                                {countries.length > 0 &&
                                    <>
                                        <Field
                                            label="Country of citizenship"
                                            placeholder="Select country"
                                            name="citizenship_country"
                                            field="searchable-select"
                                            options={countries}
                                            required={true}
                                        />

                                        <Field
                                            label="Country of birth"
                                            placeholder="Select country"
                                            name="country_of_birth"
                                            field="searchable-select"
                                            options={countries}
                                            required={true}
                                        />

                                        <Field
                                            label="Tax Country"
                                            placeholder="Select country"
                                            name="tax_country"
                                            field="searchable-select"
                                            options={countries}
                                            required={true}
                                        />
                                    </>
                                }

                                <Heading as="h2" variant="form_section" sx={{
                                    fontWeight: 600,
                                    letterSpacing: '-0.01em',
                                    mb: 3,
                                    mt: 4
                                }}>
                                    {t('Create your password')}
                                </Heading>

                                <Field
                                    label="Password"
                                    name="password"
                                    type="password"
                                    groupMb={0}
                                />
                                <Box sx={{ mb: 3, fontSize: '13px', pt: 3, color: 'dark' }}>
                                    <Text as="p">{t('Passwords must include: ')}</Text>
                                    <Text as="p">{t('- At least 8 characters')}</Text>
                                    <Text as="p">{t('- At least one uppercase and one lowercase letter')}</Text>
                                    <Text as="p">{t('- At least one symbol')}</Text>
                                    <Text as="p">{t('- At least one number')}</Text>
                                </Box>
                                <Field
                                    label="Password Confirmation"
                                    name="password_confirmation"
                                    type="password"
                                />

                                <Heading
                                    as="h2"
                                    variant="form_section"
                                    sx={{
                                        fontWeight: 600,
                                        letterSpacing: '-0.01em',
                                        mt: 4,
                                    }}
                                >
                                    {t('Choose the type of account you would like to open')}
                                </Heading>
                                <Box sx={{ mb: 3, fontSize: '13px', pt: 3, color: 'dark' }}>
                                    <Text as="p">{t('Onboard with your main trading entity. You will be able to add more once inside the platform.')}</Text>
                                </Box>

                                {Object.keys(ProfileMainTypes).map((key) => {
                                    return (
                                        <Box key={`profile${key}`} mb={1}>
                                            <Button
                                                // id should be 'profile' + capitalized key
                                                id={`profile${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                                variant="bordered"
                                                onClick={() => {
                                                    setSelected(key);
                                                    if (key === 'individual' || key === 'company') {
                                                        setSubtype('');
                                                    }
                                                    dispatch(setOnboardingType(key));
                                                }}
                                                sx={{
                                                    width: '100%',
                                                    height: '48px',
                                                    maxWidth: '432px',
                                                    textAlign: 'left',
                                                    py: '12px',
                                                    px: 3,
                                                    borderRadius: '8px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    borderWidth: '1px',
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    gridGap: 3,
                                                    ...(key === selected
                                                        ? {
                                                            backgroundColor: (t) => t.colors.darker,
                                                            color: (t) => t.colors.white,
                                                        }
                                                        : {}),
                                                }}
                                            >
                                                <Icon color={key === selected ? 'tealLight' : 'medium'} icon="ellipse" size="18px" />
                                                <Box sx={{ flex: 1 }}>
                                                    <Text as="p">{ProfileMainTypes[key]}</Text>
                                                </Box>
                                            </Button>
                                        </Box>
                                    );
                                })}

                                {(selected.toLowerCase().includes('super') || selected.toLowerCase().includes('trust')) && (
                                    <>
                                        <Heading
                                            as="h2"
                                            variant="form_section"
                                            sx={{
                                                fontWeight: 600,
                                                letterSpacing: '-0.01em',
                                                mb: 3,
                                                mt: 3,
                                            }}
                                        >
                                            {t('Choose the type of trustee for the account')}
                                        </Heading>
                                        <Grid columns={[1, '1fr 1fr 1fr']} gap={2} mb={2}>
                                            {Object.keys(ProfileSubTypes).map((key) => {
                                                return (
                                                    <Box key={`profile${key}`} mb={1}>
                                                        <Button
                                                            id={`subProfile${key.charAt(0).toUpperCase() + key.slice(1)}`}
                                                            variant="bordered"
                                                            onClick={() => {
                                                                setSubtype(key);
                                                                dispatch(setOnboardingSubtype(key));
                                                            }}
                                                            sx={{
                                                                width: '100%',
                                                                height: '48px',
                                                                maxWidth: '432px',
                                                                textAlign: 'left',
                                                                py: '12px',
                                                                px: 3,
                                                                borderRadius: '8px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                borderWidth: '1px',
                                                                fontWeight: 'bold',
                                                                fontSize: '14px',
                                                                gridGap: 3,
                                                                ...(key === subtype
                                                                    ? {
                                                                        backgroundColor: (t) => t.colors.darker,
                                                                        color: (t) => t.colors.white,
                                                                    }
                                                                    : {}),
                                                            }}
                                                        >
                                                            <Icon color={key === subtype ? 'tealLight' : 'medium'} icon="ellipse" size="18px" />
                                                            <Box sx={{ flex: 1 }}>
                                                                <Text as="p">{ProfileSubTypes[key]}</Text>
                                                            </Box>
                                                        </Button>
                                                    </Box>
                                                );
                                            })}
                                        </Grid>
                                    </>
                                )}

                                <Heading as="h2" variant="form_section" sx={{
                                    fontWeight: 600,
                                    letterSpacing: '-0.01em',
                                    mb: 3,
                                    mt: 4
                                }}>
                                    {t('Occupation')}
                                </Heading>

                                <Box sx={{ mb: 3, fontSize: '13px', pt: 1 }}>
                                    <Text sx={{ color: 'dark' }}>{t('Please select the options that best describes your current situation')}</Text>
                                </Box>

                                {Array.isArray(profileOptions?.macquarieOccupationTypes) && profileOptions?.macquarieOccupationTypes.length > 0 &&
                                    <Field
                                        label="Occupation type"
                                        placeholder="Select occupation type"
                                        name="macquarie_occupation_type"
                                        field="searchable-select"
                                        onChange={(e) => {
                                            updateOccupationCategories(e);
                                        }}
                                        options={profileOptions.macquarieOccupationTypes}
                                        required={true}
                                    />
                                }
                                {occupationCategories.length > 0 &&
                                    <Field
                                        label="Occupation category"
                                        placeholder="Select occupation category"
                                        name="macquarie_occupation_category"
                                        field="searchable-select"
                                        options={occupationCategories}
                                        required={true}
                                    />
                                }
                                {profileOptions?.sourceOfWealth.length > 0 &&
                                    <Field
                                        label="Source of income"
                                        placeholder="Select source of income"
                                        name="source_of_wealth"
                                        field="searchable-select"
                                        options={profileOptions.sourceOfWealth}
                                        required={true}
                                    />
                                }

                                <Box sx={{ mb: 3, fontSize: '13px', pt: 1 }}>
                                    <Text sx={{ color: 'dark' }}>{t('onboarding.landing.personal.occupationReason')}</Text>
                                </Box>

                                {values.macquarie_occupation_type === 'Business Owner' && (
                                <>
                                    <Heading as="h2" variant="form_section" sx={{
                                        fontWeight: 600,
                                        letterSpacing: '-0.01em',
                                        mb: 3,
                                        mt: 4
                                    }}>
                                        {t('Business details')}
                                    </Heading>

                                    <Box sx={{ mb: 3, fontSize: '13px', pt: 1 }}>
                                        <Text sx={{ color: 'dark' }}>{t('onboarding.landing.personal.businessOwnerReason')}</Text>
                                    </Box>

                                    <Field
                                        label="Trading name of the business"
                                        name="business_owner_trading_name"
                                        required={true}
                                    />

                                    <Box sx={{ mb: 3, fontSize: '13px', pt: 1 }}>
                                        <Text mb={4} sx={{
                                            color: 'dark',
                                            '& a': {
                                                color: 'tealDark'
                                            }
                                        }}>
                                            {t('Please supply the trading address of the business you own. This cannot be a PO Box.')} {!showAddress && <Themed.a onClick={() => setShowAddress(true)}>Address missing? Enter manually</Themed.a>}
                                        </Text>
                                    </Box>

                                    <Grid mb={3} sx={{
                                        gridGap: 4,
                                        gridTemplateColumns: ['1fr'],
                                        width: '100%'
                                    }}>
                                        <Field
                                            field="google_places"
                                            label="Search for address..."
                                            name="business_owner_address.address"
                                            setFieldValue={setFieldValue}
                                            setShowAddress={setShowAddress}
                                            fieldNames={{
                                                street_number: 'business_owner_address.street_number',
                                                street_name: 'business_owner_address.street_name',
                                                street_type: 'business_owner_address.street_type',
                                                suburb: 'business_owner_address.suburb',
                                                state: 'business_owner_address.state',
                                                postcode: 'business_owner_address.postcode',
                                                country: 'business_owner_address.country'
                                            }}
                                        />
                                    </Grid>

                                    <Box sx={{
                                        opacity: showAddress ? 1 : 0,
                                        height: showAddress ? 'auto' : 0,
                                        minHeight: showAddress ? '300px' : 0,
                                        transition: 'min-height 0.3s ease-in-out, opacity 0.3s ease-in-out'
                                    }}>
                                        {countries.length > 0 &&
                                            <Field
                                                label="Country"
                                                placeholder="Select country"
                                                name="business_owner_address.country"
                                                field="searchable-select"
                                                options={countries}
                                                onChange={(e) => {
                                                    getStates(e?.value);
                                                    setTimeout(() => setFieldValue('business_owner_address.country', e?.value), 300);
                                                }}
                                                required={true}
                                            />
                                        }

                                        <Grid sx={{
                                            gridGap: 2,
                                            gridTemplateColumns: ['1fr', '1fr 2fr'],
                                            width: '100%'
                                        }}>
                                            <Field
                                                label="Unit Number"
                                                name="business_owner_address.unit_number"
                                            />
                                            <Field
                                                label="Street Number"
                                                name="business_owner_address.street_number"
                                            />
                                        </Grid>

                                        <Grid sx={{
                                            gridGap: 2,
                                            gridTemplateColumns: ['1fr', '2fr 1fr'],
                                            width: '100%'
                                        }}>
                                            <Field
                                                label="Street Name"
                                                name="business_owner_address.street_name"
                                            />
                                            <Field
                                                label="Street Type"
                                                name="business_owner_address.street_type"
                                            />
                                        </Grid>

                                        <Field
                                            label="Address second line"
                                            name="business_owner_address.address2"
                                        />
                                        <Field
                                            label="Suburb"
                                            name="business_owner_address.suburb"
                                        />

                                        <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
                                            <Box sx={{ width: ['100%', '100%', '50%'] }} pr={[0, 0, '5px']}>
                                                <Field
                                                    field={Boolean(Array.isArray(stateOptions) && stateOptions.length > 0) ? 'select' : 'text'}
                                                    label="State"
                                                    placeholder="-- Please select --"
                                                    name="business_owner_address.state"
                                                    options={stateOptions}
                                                />
                                            </Box>

                                            <Box sx={{ width: ['100%', '100%', '50%'] }}>
                                                <Field
                                                    label="Postcode"
                                                    name="business_owner_address.postcode"
                                                />
                                            </Box>
                                        </Flex>
                                    </Box>
                                </>
                                )}

                                <Submit variant="primary" id="personalSubmit" text={t('onboarding.landing.personal.submit')} sx={{ display: 'block', width: '100%', mt: '40px' }} />
                            </Form>
                        </Box>
                    )}
                </Formik>
            </Box>
        </>
    )
};

export default connect(mapStateToProps, null)(OnboardingPersonal);
