import { useEffect, useState } from 'react';
import {
    Button,
    Flex
} from '@theme-ui/components'
import { Icon } from 'assets/Icon';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { DealInvestmentStatus } from 'app/constants';

const checkAuthorised = (list, setAcceptUrl) => {
    if (Array.isArray(list)) {
        const listedDeal = list.find(deal => deal.status.id === DealInvestmentStatus.authorised);
        if (listedDeal) {
            setAcceptUrl(listedDeal.acceptance_url);
            return true;
        }
    }
}

export const DashboardNotice = ({ children, sx, ...props }) => {
    const isAdviser = useSelector((state) => state.user?.user?.is_adviser);
    const portfolio = useSelector((state) => state.portfolio);
    const [acceptUrl, setAcceptUrl] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {

        if (portfolio.deals.listed.status === 'attempted') {
            // Find first deal ready to be signed, starting with unlisted
            if (!checkAuthorised(portfolio.deals?.unlisted?.results, setAcceptUrl)) {
                checkAuthorised(portfolio.deals?.listed?.results, setAcceptUrl);
            }
        }

    }, [portfolio])

    const getContent = () => {
        if ( children ) {
            return children;
        }

        if ( isAdviser ){
            return;
        }

        if (acceptUrl) {
            return (
                <>
                    <Flex sx={{ alignItems: 'center', justifyContent: 'center' }} mr={4} color="dark">
                        <Icon icon="pending" size="15" mr={2} /> {t('You have an offer awaiting sign off')}
                    </Flex>
                    <Button as={'a'} variant="bordered" href={acceptUrl}>{t('Sign Acceptance Document')}</Button>
                </>
            )
        }

        return false;
    };

    const content = getContent();

    return content ? (
        <Flex bg="light" sx={{
            ...sx,
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            p: 3,
            flexDirection: ['column', 'row'],
            gridGap: [3, 0],
        }}>
            {content}
        </Flex>
    ) : null;
};
