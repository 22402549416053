import { useRef, useState, useEffect } from 'react';
import { SettingsLayout } from '~/Layouts';
import { ProfileDelete, Button, Tooltip } from '~/Common';
import { Box, Grid, Heading, Spinner, Text } from '@theme-ui/components';
import { connect } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { Icon } from 'assets/Icon';
import { formatBankAccount, getBankDetailsText } from 'utils/helpers';
import { MAX_PROFILES_PER_USER } from 'app/constants';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { user: state.user?.user, userLoaded: state.user.status === 'idle' };
};

const SettingsProfiles = ({ user, userLoaded }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('My Entities'));
    const [loading, setLoading] = useState(true);
    const nodeRef = useRef(null);
    const profiles = user.profiles.filter((p) => p.type !== 'individual');
    const [selected, setSelected] = useState(false);
    const canAddMore = user?.profiles.length < MAX_PROFILES_PER_USER;
    const history = useHistory();

    useEffect(() => {
        if (userLoaded) {
            setLoading(false);
        }
    }, [userLoaded, user]);

    return (
        <SettingsLayout>
            {loading ? (
                <Box id="settingsLoading"><Spinner variant="styles.spinner" m={'auto'} /></Box>
            ) : (
                <CSSTransition nodeRef={nodeRef} in={true} timeout={500} classNames="fade" unmountOnExit appear>
                    <Box ref={nodeRef}>
                        <Heading as="h1" variant="h2" mb={2}>
                            {t('settings.profiles.heading')}
                        </Heading>

                        <Box mb={4}>
                            {profiles && profiles.length > 0 ? (
                                <>
                                    <Heading as="h2" variant="h4" mb={4}>
                                        {t('settings.profiles.subheading')}
                                    </Heading>

                                    <Grid
                                        mb={4}
                                        sx={{
                                            gridGap: 3,
                                            gridTemplateColumns: ['1fr'],
                                            width: '100%',
                                        }}
                                    >
                                        {profiles.map((profile, key) => {
                                            const isVerified = profile.verified !== false;

                                            // TODO: Primary setting for the profile isn't in play yet; It's assumed to be the first entity in the list if they chose to skip the individual account
                                            const isPrimary = profile.primary || (key === 0 && user?.skip_individual_trade_account);

                                            return (
                                                <Box
                                                    key={`profile${key}`}
                                                >
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                        flexDirection: ['column', 'row']
                                                    }}>
                                                        <Button
                                                            variant="bordered"
                                                            onClick={() => {
                                                                setSelected(key);
                                                            }}
                                                            sx={{
                                                                width: '100%',
                                                                minHeight: '48px',
                                                                height: 'auto',
                                                                maxWidth: '432px',
                                                                textAlign: 'left',
                                                                py: '12px',
                                                                px: 3,
                                                                borderRadius: '8px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                borderWidth: '1px',
                                                                fontWeight: 'bold',
                                                                fontSize: '14px',
                                                                gridGap: 3,
                                                                ...(key === selected
                                                                    ? {
                                                                        backgroundColor: (t) => t.colors.darker,
                                                                        color: (t) => t.colors.white,
                                                                    }
                                                                    : {}),
                                                            }}
                                                        >
                                                            <Icon color={key === selected ? 'tealLight' : 'medium'} icon="ellipse" size="18px" />
                                                            <Box sx={{ flex: 1 }}>
                                                                <Text as="p">
                                                                    {profile.type_name}
                                                                    {` / `}
                                                                    {profile.name}
                                                                </Text>
                                                                {(profile?.bank_account_bsb && profile?.bank_account_number) ? (
                                                                    <Text as="p" variant="caption" sx={{ color: 'dark', fontSize: 11 }}>
                                                                        {`${getBankDetailsText(profile)} ${formatBankAccount(profile?.bank_account_bsb, profile?.bank_account_number)}`}
                                                                    </Text>
                                                                ) : null}
                                                                {profile?.hin_number ? (
                                                                    <Text as="p" variant="caption" sx={{ color: 'dark', fontSize: 11 }}>
                                                                        {`${t('HIN:')} ${profile?.hin_number}`}
                                                                    </Text>
                                                                ) : null}
                                                                {profile?.external_hins && profile?.external_hins.length ? (
                                                                    <Text as="p" variant="caption" sx={{ color: 'dark', fontSize: 11 }}>
                                                                        {t('{{count}} Connected {{hins}}', { count: profile?.external_hins.length, hins: profile?.external_hins.length === 1 ? 'HIN' : 'HINs' })}
                                                                    </Text>
                                                                ) : null}
                                                            </Box>
                                                            {isVerified && (
                                                                <Tooltip text="Verified" sx={{ height: '20px' }}>
                                                                    <Icon icon="verified" size="18" />
                                                                </Tooltip>
                                                            )}
                                                            {!isVerified && (
                                                                <>
                                                                    {profile.certificate_status === 'Expired' ? (
                                                                        <Tooltip text="s708 Certificate has expired" sx={{ height: '20px' }}>
                                                                            <Icon size="18" icon="certificate-alt" color="warning" />
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <Tooltip text="Requires Verification" sx={{ height: '20px' }}>
                                                                            <Icon size="18" icon="pending" color="warning" />
                                                                        </Tooltip>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Button>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}>
                                                            {isPrimary && (
                                                                <Text sx={{ fontSize: '12px', color: '#6A7175', ml: 2 }}>
                                                                    <Icon icon="tick" size="10px" mr={2} color="#1DE9D5" />
                                                                    {t('Primary Entity')}
                                                                </Text>
                                                            )}
                                                        </Box>
                                                    </Box>



                                                    {key === selected && (
                                                        <Box
                                                            mt={2}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                maxWidth: '432px',
                                                            }}>
                                                            <Button
                                                                variant="light"
                                                                mr={2}
                                                                sx={{
                                                                    py: '10px',
                                                                    mt: [2, 0]
                                                                }}
                                                                onClick={() => {
                                                                    history.push(`/settings/entities/${profile.uuid}`);
                                                                }}
                                                            >
                                                                {t((profile.submitted_at || isVerified) ? 'Manage Entity' : (profile.locked_at ? 'Review Entity' : 'Continue Onboarding'))}
                                                            </Button>

                                                            <Button
                                                                variant="light"
                                                                mr={2}
                                                                sx={{
                                                                    py: '10px',
                                                                    mt: [2, 0]
                                                                }}
                                                                onClick={() => {
                                                                    history.push(`/settings/connected-hins/${profile.uuid}`);
                                                                }}
                                                            >
                                                                {t('Manage HINs')}
                                                            </Button>

                                                            {!profile.verified && <ProfileDelete size="default" variant="danger" uuid={profile?.uuid} sx={{
                                                                py: '10px',
                                                                borderRadius: '6px',
                                                                mt: [2, 0],
                                                                mr: 2
                                                            }} />}
                                                        </Box>
                                                    )}
                                                </Box>
                                            );
                                        })}
                                    </Grid>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        {canAddMore && (
                                            <Button variant="light" as={NavLink} to="/settings/entities/create">
                                                <Icon
                                                    icon="add-user"
                                                    sx={{
                                                        top: '3px',
                                                        position: 'relative',
                                                        mr: 2,
                                                    }}
                                                />
                                                {t('Add Entity')}
                                            </Button>
                                        )}
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Text mt={4} as="p">
                                        {t('Want to start investing with a Company, Trust or Super Fund? Get started below.')}
                                    </Text>
                                    <Text mt={4} as="p">
                                        <Button variant="light" as={NavLink} to="/settings/entities/create">
                                            <Icon icon="add-user" sx={{ top: '3px', position: 'relative', mr: 2 }} /> {t('Add Entity')}
                                        </Button>
                                    </Text>
                                </>
                            )}
                        </Box>
                    </Box>
                </CSSTransition>
            )}
        </SettingsLayout>
    );
};

export default connect(mapStateToProps, null)(SettingsProfiles);
