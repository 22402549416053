import axios from 'axios'

export const dealPlaceBid = (params) =>
    axios.post('deals/bid', params)
        .then(({ data }) => data)

export const getDeals = (params) =>
    axios.get('deals', params)
        .then(({ data }) => data)

export const getDealView = (slug) =>
    axios.get(`deals/${slug}`)
        .then(({ data }) => data)

export const getDealNews = (slug, params) =>
    axios.get(`deals/${slug}/articles`, params)
        .then(({ data }) => data)

export const getDealStats = (params) =>
    axios.get('deals/stats', params)
        .then(({ data }) => data)

export const getDealSecurityPerformance = (uuid, params) =>
    axios.get(`deals/${uuid}/performance`, params)
        .then(({ data }) => data)

export const sendVerification = () =>
    axios.post(`deals/send-verification`)
        .then(({ data }) => data)

export const unsophisticatedSendVerification = (uuid, params) =>
    axios.post(`public/eoi-user/${uuid}/send-verification`, params)
        .then(({ data }) => data)

export const verify = (params) =>
    axios.post(`deals/verify`, params)
        .then(({ data }) => data)

export const acceptanceLetterVerify = (params) =>
    axios.post(`acceptance-letter/verify`, params)
        .then(({ data }) => data)

export const unsophisticatedAcceptanceLetterVerify = (params) =>
    axios.post(`public/eoi-user/verify`, params)
        .then(({ data }) => data)

export const getDealAcceptance = (uuid, params) =>
    axios.get(`investments/acceptance/${uuid}`, params)
        .then(({ data }) => data)

export const signInvestment = (uuid, params) =>
    axios.post(`investments/${uuid}/sign`, params)
        .then(({ data }) => data);

export const getOrganisationDeals = (params) =>
    axios.get('organisation/deals', params)
        .then(({ data }) => data)

export const getEoiDealAcceptance = (uuid, params) =>
    axios.get(`public/eoi-user/acceptance/${uuid}`, params)
        .then(({ data }) => data)

export const signEoiInvestment = (uuid, params) =>
    axios.post(`public/eoi-user/${uuid}/sign`, params)
        .then(({ data }) => data);
