import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    getCompanies,
    getCompanyView,
    getCompanyNews
} from './companyAPI'

export const getCompaniesAsync = createAsyncThunk(
    'companies/getCompanies',
    async (payload) => {
        const response = payload === undefined ? await getCompanies() : payload;
        return response;
    }
);

export const getCurrentCompanyAsync = createAsyncThunk(
    'companies/currentCompany',
    async (slug) => {
        try {
            const response = await getCompanyView(slug);
            return response;
        } catch (e) {
            // trigger event for livewire
            var notFound = new CustomEvent('notFound', { detail: { method: 'companies/currentCompany', slug: slug } });
            window.dispatchEvent(notFound);
            throw new Error(e);
        }
    }
);

export const getCurrentCompanyNewsAsync = createAsyncThunk(
    'companies/currentCompanyNews',
    async ({slug, page}) => {
        const response = await getCompanyNews(slug, { params: { page } });
        return response;
    }
);

const initialState = {
    companies: {
        status: 'idle',
        results: [],
    },
    stats: {
        counts: {
            active_companies: 0
        }
    },
    currentCompany: null,
    currentCompanyNews: [],
    currentCompanyNewsStatus: 'idle',
    currentCompanyStatus: 'idle',
};

const companies = createSlice({
    name: 'companies',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getCompaniesAsync.pending, (state, { payload }) => {
            state.companies.status = 'loading';
        })
            .addCase(getCompaniesAsync.rejected, (state) => {
                state.companies.results = [];
                state.companies.meta = [];
                state.companies.status = 'attempted';
            })
            .addCase(getCompaniesAsync.fulfilled, (state, { payload }) => {
                state.companies.results = payload?.data ?? [];
                state.companies.meta = payload?.meta ?? [];
                state.stats.counts = {
                    active_companies: payload?.meta?.total ?? 0
                }
                state.companies.status = 'attempted';
            })
            .addCase(getCurrentCompanyAsync.pending, (state, { payload }) => {
                state.currentCompanyStatus = 'loading';
            })
            .addCase(getCurrentCompanyAsync.rejected, (state, { payload }) => {
                state.currentCompany = null;
                state.currentCompanyStatus = 'attempted';
            })
            .addCase(getCurrentCompanyAsync.fulfilled, (state, { payload }) => {
                state.currentCompany = payload?.data;
                state.currentCompanyStatus = 'attempted';
            })
            .addCase(getCurrentCompanyNewsAsync.pending, (state, { payload }) => {
                state.currentCompanyNewsStatus = 'loading';
            })
            .addCase(getCurrentCompanyNewsAsync.rejected, (state, { payload }) => {
                state.currentCompanyNews = [];
                state.currentCompanyNewsMeta = [];
                state.currentCompanyNewsStatus = 'attempted';
            })
            .addCase(getCurrentCompanyNewsAsync.fulfilled, (state, { payload }) => {
                state.currentCompanyNews = payload?.data;
                state.currentCompanyNewsMeta = payload?.meta;
                state.currentCompanyNewsStatus = 'attempted';
            });
    },
});

export {
    getCompanies,
    getCompanyView,
    getCompanyNews
}
export default companies.reducer;

