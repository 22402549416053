import React from 'react';
import { Box } from '@theme-ui/components';

import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ArrowLong } from './arrow-long.svg';
import { ReactComponent as AddFile } from './add-file.svg';
import { ReactComponent as AddUser } from './add-user.svg';
import { ReactComponent as Bars } from './bars.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as BellThick } from './bell-thick.svg';
import { ReactComponent as BreadcrumbSeperator } from './breadcrumb-seperator.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Camera } from './camera.svg';
import { ReactComponent as Certificate } from './certificate.svg';
import { ReactComponent as CertificateAlt } from './certificate-alt.svg';
import { ReactComponent as Chart } from './chart.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as ClipboardLeft } from './clipboard-left.svg';
import { ReactComponent as ClipboardRight } from './clipboard-right.svg';
import { ReactComponent as ChevronDown } from './chevron-down.svg';
import { ReactComponent as ChevronLeft } from './chevron-left.svg';
import { ReactComponent as ChevronRight } from './chevron-right.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as ClosedDeals } from './closed-deals.svg';
import { ReactComponent as Coin } from './coin.svg';
import { ReactComponent as CoinThick } from './coin-thick.svg';
import { ReactComponent as Company } from './company.svg';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Cog } from './cog.svg';
import { ReactComponent as Deals } from './deals.svg';
import { ReactComponent as Dropdown } from './dropdown.svg';
import { ReactComponent as Dollar } from './dollar.svg';
import { ReactComponent as DollarThin } from './dollar-thin.svg';
import { ReactComponent as Done } from './done.svg';
import { ReactComponent as Dots } from './dots.svg';
import { ReactComponent as DoneWhite } from './done-white.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as ExternalLink } from './external-link.svg';
import { ReactComponent as Ellipse } from './ellipse.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as Gift } from './gift.svg';
import { ReactComponent as Grid } from './grid.svg';
import { ReactComponent as HotDeals } from './hot-deals.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as IdentityCard } from './identity-card.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as List } from './list.svg';
import { ReactComponent as LiveDeals } from './live-deals.svg';
import { ReactComponent as LinkedIn } from './linkedin.svg';
import { ReactComponent as LinkedInCircle } from './linkedin-circle.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as LockThin } from './lock-thin.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as LogoIcon } from './logo-icon.svg';
import { ReactComponent as LQDLogo } from './LQD-logo.svg';
import { ReactComponent as Logout } from './logout.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as MailOpen } from './mail-open.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Minus } from './minus.svg';
import { ReactComponent as Mobile } from './mobile.svg';
import { ReactComponent as OfficeBuilding } from './office-building.svg';
import { ReactComponent as Passport } from './passport.svg';
import { ReactComponent as Pending } from './pending.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Puzzle } from './puzzle.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Portfolio } from './portfolio.svg';
import { ReactComponent as PortfolioThick } from './portfolio-thick.svg';
import { ReactComponent as Quote } from './quote.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as SearchCircle } from './search-circle.svg';
import { ReactComponent as Switch } from './switch.svg';
import { ReactComponent as Stack } from './stack.svg';
import { ReactComponent as Tag } from './tag.svg';
import { ReactComponent as Tick } from './tick.svg';
import { ReactComponent as Ticket } from './ticket.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as TrendingUp } from './trending-up.svg';
import { ReactComponent as TickCircle } from './tick-circle.svg';
import { ReactComponent as TickCircleWhite } from './tick-circle-white.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as UserThin } from './user-thin.svg';
import { ReactComponent as UserCircle } from './user-circle.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as Watchlist } from './watchlist.svg';
import { ReactComponent as WatchlistThick } from './watchlist-thick.svg';
import { ReactComponent as WatchlistThin } from './watchlist-thin.svg';
import { ReactComponent as Reply } from './reply.svg';

import { ReactComponent as Verified } from './verified.svg';

export const allIcons = {
    'reply':        props => <Reply {...props} />,
    'verified':     props => <Verified {...props} />,
    'add-file':     props => <AddFile {...props} />,
    'add-user':     props => <AddUser {...props} />,
    'arrow-up':     props => <ArrowUp {...props} />,
    'arrow-right':  props => <ArrowRight {...props} />,
    'arrow-long':   props => <ArrowLong {...props} />,
    'bars':         props => <Bars {...props} />,
    'breadcrumb-seperator': props => <BreadcrumbSeperator {...props} />,
    'bell':         props => <Bell {...props} />,
    'bell-thick':   props => <BellThick {...props} />,
    'bids':         props => <LiveDeals color={'#006991'} {...props} />,
    'camera':       props => <Camera {...props} />,
    'calendar':     props => <Calendar {...props} />,
    'certificate':  props => <Certificate {...props} />,
    'certificate-alt':  props => <CertificateAlt {...props} />,
    'check':        props => <Check {...props} />,
    'clipboard-left': props => <ClipboardLeft {...props} />,
    'clipboard-right': props => <ClipboardRight {...props} />,
    'chevron-down': props => <ChevronDown {...props} />,
    'chevron-left': props => <ChevronLeft {...props} />,
    'chevron-right': props => <ChevronRight {...props} />,
    'chart':        props => <Chart {...props} />,
    'clock':        props => <Clock {...props} />,
    'closed-deals': props => <ClosedDeals {...props} />,
    'coin':         props => <Coin {...props} />,
    'cog':          props => <Cog {...props} />,
    'coin-thick':    props => <CoinThick {...props} />,
    'company':      props => <Company {...props} />,
    'cross':        props => <Cross {...props} />,
    'dropdown':     props => <Dropdown {...props} />,
    'deals':        props => <Deals {...props} />,
    'dollar':       props => <Dollar {...props} />,
    'dollar-thin':  props => <DollarThin {...props} />,
    'dots':         props => <Dots {...props} />,
    'done':         props => <Done {...props} />,
    'done-white':   props => <DoneWhite {...props} />,
    'download':     props => <Download {...props} />,
    'external-link': props => <ExternalLink {...props} />,
    'ellipse':      props => <Ellipse {...props} />,
    'error':        props => <Error {...props} />,
    'facebook':     props => <Facebook {...props} />,
    'grid':         props => <Grid {...props} />,
    'gift':         props => <Gift {...props} />,
    'hot-deals':    props => <HotDeals {...props} />,
    'instagram':    props => <Instagram {...props} />,
    'identity-card': props => <IdentityCard {...props} />,
    'info':         props => <Info {...props} />,
    'list':         props => <List {...props} />,
    'linkedin':     props => <LinkedIn {...props} />,
    'linkedin-circle': props => <LinkedInCircle {...props} />,
    'live-deals':   props => <LiveDeals {...props} />,
    'lock':         props => <Lock {...props} />,
    'lock-thin':    props => <LockThin {...props} />,
    'logo':         props => <Logo {...props} />,
    'logoIcon':     props => <LogoIcon {...props} />,
    'lqd':          props => <LQDLogo {...props} />,
    'logout':       props => <Logout {...props} />,
    'mail':         props => <Mail {...props} />,
    'mail-open':    props => <MailOpen {...props} />,
    'menu':         props => <Menu {...props} />,
    'minus':        props => <Minus {...props} />,
    'mobile':       props => <Mobile {...props} />,
    'office-building': props => <OfficeBuilding {...props} />,
    'passport':     props => <Passport {...props} />,
    'pending':      props => <Pending {...props} />,
    'phone':        props => <Phone {...props} />,
    'puzzle':       props => <Puzzle {...props} />,
    'play':         props => <Play {...props} />,
    'plus':         props => <Plus {...props} />,
    'portfolio':    props => <Portfolio {...props} />,
    'portfolio-thick':    props => <PortfolioThick {...props} />,
    'quote':        props => <Quote {...props} />,
    'search':       props => <Search {...props} />,
    'search-circle': props => <SearchCircle {...props} />,
    'switch':       props => <Switch {...props} />,
    'stack':        props => <Stack {...props} />,
    'tag':          props => <Tag {...props} />,
    'tick':         props => <Tick {...props} />,
    'tick-circle':  props => <TickCircle {...props} />,
    'tick-circle-white':  props => <TickCircleWhite {...props} />,
    'ticket':       props => <Ticket {...props} />,
    'trash':        props => <Trash {...props} />,
    'trending-up':  props => <TrendingUp {...props} />,
    'twitter':      props => <Twitter {...props} />,
    'upload':       props => <Upload {...props} />,
    'users':        props => <Users {...props} />,
    'user':         props => <User {...props} />,
    'user-thin':         props => <UserThin {...props} />,
    'user-circle':  props => <UserCircle {...props} />,
    'watchlist':    props => <Watchlist {...props} />,
    'watchlist-thin': props => <WatchlistThin {...props} />,
    'watchlist-thick': props => <WatchlistThick {...props} />,
};

export const Icon = ({ icon, sx, size, width, height, strokeWidth, color, ...props }) => {
    const pixelMe = (val) => (
        val === 'auto' || val.includes('%') || val.includes('px') ? val : `${val}px`
    );
    const svgWidth = width !== undefined ? pixelMe(`${width}`) : pixelMe(`${size}`);
    const svgHeight = height !== undefined ? pixelMe(`${height}`) : pixelMe(`${size}`);

    if (color) {
        sx = {
            color: color,
            ...sx
        };
    }

    switch (icon) {
        case 'arrow-down':
            sx = {
                transform: 'rotate(180deg)',
                ...sx
            };
        break;
        default: break;
    }

    const renderIcon = (icon) => {
        if (allIcons.hasOwnProperty(icon)) {
            return allIcons[icon](props);
        }
        return '';
    };

    return (
        <Box
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                '> svg': {
                    width: `${svgWidth}`,
                    height: `${svgHeight}`,
                    maxWidth: '100%',
                    maxHeight: '100%',
                    '& path': {
                        strokeWidth,
                    }
                },
                ...sx,
            }}
            {...props}
        >
            {renderIcon(icon)}
        </Box>
    );
};

Icon.defaultProps = {
    size: 'auto',
};
