import {
    Box,
    Grid,
    Flex,
    Heading
} from '@theme-ui/components'
import { Container, Circle, Markdown } from '~/Common'
import { useTranslation } from 'react-i18next'

export const Table = ({ bg = 'white', data, container, ...props }) => {
    const { t } = useTranslation();
    const title = data?.title ?? null;
    const description = data?.description ?? null;
    const bottomDescription = data?.bottomDescription ?? null;
    const rows = data?.cards ?? null;
    const rowWidth = '600px';

    return (
        <Box bg={bg} {...props}>
            <Container variant="layouts.section" {...container}>
                { title && <Heading as="h2" variant="h1" mb={[4,4,5]} sx={{ fontWeight: 'bold' }}>{t(title)}</Heading>}
                { description && <Box className="markdown" mb={[4,4,5]}>
                    <Markdown>{description}</Markdown>
                </Box>}

                {rows &&
                    <Box sx={{
                        position: 'relative',
                        '&::before': {
                            content: '""',
                            bg: '#D5D9DA',
                            width: '1px',
                            position: 'absolute',
                            top: '40px',
                            bottom: 0

                        }
                    }}>
                        <Box sx={{
                            overflowX: 'auto',
                            pl: '38px'
                        }}>
                            <Grid py={2} sx={{
                                gridGap: 4,
                                gridTemplateColumns: ['repeat(5, 1fr)'],
                                width: '100%',
                                minWidth: rowWidth,
                                fontSize: '14px',
                                color: '#6A7175'
                            }}>
                                <Box px={2}></Box>
                                <Box px={2}>Price/cents</Box>
                                <Box px={2}>Shares</Box>
                                <Box px={2}>Value</Box>
                                <Box px={2}></Box>
                            </Grid>

                            <Box>
                                {rows.map((row, key) => (
                                    <Grid key={key} py={'23px'} sx={{
                                        gridGap: 4,
                                        rowGap: 0,
                                        gridTemplateColumns: ['repeat(5, 1fr)'],
                                        width: '100%',
                                        minWidth: rowWidth,
                                        borderBottom: '1px solid #F7F8F9',
                                        bg: 'transparent'
                                    }}>
                                        <Flex sx={{
                                            alignItems: 'center',
                                            left: '0',
                                            position: 'absolute',
                                            transform: 'translateX(-50%)'
                                        }}>
                                            <Circle
                                                type="box"
                                                size="8px"
                                                pulse={true}
                                                bg="darker"
                                             />
                                        </Flex>
                                        <Box px={2}>{row?.title}</Box>
                                        <Box px={2}>{row?.price}</Box>
                                        <Box px={2}>{row?.shares}</Box>
                                        <Box px={2}>{row?.value}</Box>
                                        <Box px={2}>{row?.percentage}</Box>
                                        {row?.subrows &&
                                            <>
                                            {row.subrows.map((subrow, key) => (
                                                <Grid key={key} py={2} sx={{
                                                    gridColumn: 'span 5',
                                                    gridGap: 4,
                                                    rowGap: 0,
                                                    gridTemplateColumns: ['repeat(5, 1fr)'],
                                                    width: '100%',
                                                    minWidth: rowWidth,
                                                    fontSize: '16px',
                                                    color: 'darker'
                                                }}>
                                                    <Box px={2} pl={3}>{subrow?.title}</Box>
                                                    <Box px={2}>{subrow?.price}</Box>
                                                    <Box px={2}>{subrow?.shares}</Box>
                                                    <Box px={2}>{subrow?.value}</Box>
                                                    <Box px={2}>{subrow?.percentage}</Box>
                                                </Grid>
                                            ))}
                                            </>
                                        }
                                    </Grid>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                }

                { bottomDescription && <Box mt={2} className="markdown">
                    <Markdown externalLinks={true}>{bottomDescription}</Markdown>
                </Box> }
            </Container>
        </Box>
    )
};
