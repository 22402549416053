import styled from '@emotion/styled';
import { theme } from 'app/theme';

export const SearchBar = styled('div')`
    position: fixed;
    top: ${theme.styles.header.height};
    margin-top: 20px;
    left: 0;
    z-index: 90;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    transition: transform 0.3s ease-in-out,
                opacity 0.1s linear,
                visibility 0.1s linear;

    &.open{
        transform: translate3d(0, 0, 0);
        visibility: visible;
        opacity: 1;
        transition: transform 0.3s ease-in-out 0s,
                    opacity 0.1s linear 0.3s,
                    visibility 0.1s linear 0.3s;
    }

    & input {
      box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      padding: 28px 54px;
      font-size: 26px;
      letter-spacing: -0.01em;

      &::placeholder {
        color: ${theme.colors.dark};
      }
    }
`;
