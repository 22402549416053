import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ReactPostprocessor from 'i18next-react-postprocessor';

import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_EN_US } from "./en-us/translations";

i18n
    .use(new ReactPostprocessor())
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN
            },
            "en-US": {
                translation: TRANSLATIONS_EN_US
            }
        },
        postProcess: [ `reactPostprocessor` ],
        debug: false,
        fallbackLng: 'en',
        nsSeparator: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'u'],
        }
    });

export default i18n;
