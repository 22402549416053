import {
    Box,
    Text
} from '@theme-ui/components'
import { Icon } from 'assets/Icon'
import { Circle } from '~/Common';
import { useTranslation } from 'react-i18next';
import { DealStatus } from 'app/constants';

export const StatusTag = ({
    icon = null,
    label = 'Placement',
    background = 'tealLight',
    color = 'darker',
    status,
    sx,
    ...props
}) => {
    const { t } = useTranslation();

    switch(status) {

        case DealStatus.active:
            icon = 'dot';
        break;

        case DealStatus.capital_raised:
        case DealStatus.money_received:
        case DealStatus.fulfilled:
        case DealStatus.closed:
            icon = 'closed-deals';
            label = 'Closed';
            background = 'darker';
            color = 'white';
        break;

        default: break;
    }

    if ('dot' === icon) {
        icon = <Circle type="box" pulse={true} size="6px" mr={2} bg="darker" />
    } else if (icon !== null) {
        icon = <Icon color={color} icon={icon} size="14px" mr={2} />;
    }

    return (
        <Box py={1} px={2} sx={{
            display: 'inline-flex',
            alignItems: 'center',
            backgroundColor: background,
            borderRadius: '26px',
            p: '9px 13px',
            textTransform: 'uppercase',
            lineHeight: '1',
            ...sx
        }}
        {...props}
        >
            {icon} <Text variant="tag" sx={{ pt: '2px', color: sx?.color || color }}>{t(label)}</Text>
        </Box>
    );
}
