import { useState, useEffect, useCallback, useRef } from 'react'
import {
    Button
} from '~/Common'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Filler,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import {
    Box,
    Flex,
    Spinner
} from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getDealSecurityPerformance, setCurrentDealChart } from 'features/deal/dealSlice'
import { toast } from 'utils/toast'
import moment from 'moment'
import { formatCurrency } from 'utils/helpers'

// Register ChartJS bits
ChartJS.register(
    CategoryScale,
    LinearScale,
    Filler,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const PerformanceGraph = ({ deal }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [chartLabels, setChartLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [activeChart, setActiveChart] = useState(null);
    const chartRef = useRef(null);
    const dispatch = useDispatch();
    const currentDealChart = useSelector((state) => state.deals.currentDealChart);
    const chartOptions = {
        responsive: true,
        bezierCurve: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: false
            },
        },
        scales: {
            y: {
                min: 0,
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: (value) => { return formatCurrency(value); },
                }
            }
        }
    };

    const getDateFormat = (date_frequency) => {
        switch(date_frequency){
            case 'Daily': return 'Do MMM';
            case 'Weekly': return 'Do MMM';
            case 'Minutes': return 'h:mma';
            default: return;
        }
    };
    const getChartPerformance = useCallback(
        (date_period, date_frequency) => {
            setActiveChart(`${date_period}_${date_frequency}`);
            getDealSecurityPerformance(deal?.uuid, {
                params: {
                    date_period: date_period,
                    date_frequency: date_frequency
                }
            }).then((response) => {
                setChartLabels(response.data.map((item) => moment(item.time_series_date).utc().clone().format(getDateFormat(date_frequency))));
                setChartData(response.data.map((item) => item.close_price));
                setLoading(false);
            }).catch(({ response }) => {
                toast.error(t('Unable to load performance graph'), {
                    toastId: 'deal-performance-error',
                });
                setChartLabels([]);
                setChartData([]);
                setLoading(false);
            });
        },
        [deal, t]
    );

    useEffect(() => {
        // First load or deal has changed
        if(loading || currentDealChart?.slug !== deal?.slug){
            getChartPerformance('1M', 'Daily');
            dispatch(setCurrentDealChart(deal));
        }
    }, [loading, getChartPerformance, currentDealChart, deal, dispatch]);

    return (
        <Box>
            {loading ? <Spinner variant="styles.spinner" m={'auto'} /> :
            <>
                <Flex mb={4} sx={{
                    flexDirection: ['column','column','column','row']
                }}>
                    <Box>
                        <Button onClick={() => getChartPerformance('1M', 'Daily')} mr={2} variant={activeChart === '1M_Daily' ? 'primary' : 'light'} size="tag">1M</Button>
                        <Button onClick={() => getChartPerformance('6M', 'Weekly')} mr={2} variant={activeChart === '6M_Weekly' ? 'primary' : 'light'} size="tag">6M</Button>
                        <Button onClick={() => getChartPerformance('1Y', 'Weekly')} mr={2} variant={activeChart === '1Y_Weekly' ? 'primary' : 'light'} size="tag">1Y</Button>
                        <Button onClick={() => getChartPerformance('1D', 'Minutes')} variant={activeChart === '1D_Minutes' ? 'primary' : 'light'} size="tag">1D</Button>
                    </Box>
                </Flex>

                {chartData.length > 0 &&
                <Line
                    options={chartOptions}
                    data={{
                        labels: chartLabels,
                        datasets: [{
                            data: chartData,
                            fill: {
                                target: 'origin',
                                above: 'transparent'
                            },
                            borderColor: '#000',
                            tension: 0.35
                        }]
                    }}
                    ref={chartRef}
                />
                }
            </>
            }
        </Box>
    );
};
