import axios from 'axios'
import { DealStatus } from 'app/constants'

export const getWatchlist = (params) =>
    axios.get('watchlist', params)
        .then(({ data }) => data)

export const getWatchlistStats = (params) =>
    axios.get('watchlist/stats', params)
        .then(({ data }) => data)

export const storeCategories = (params) =>
    axios.post('watchlist/categories', params)
        .then(({ data }) => data)

export const storeFollows = (params) =>
    axios.post('watchlist/follows', params)
        .then(({ data }) => data)

export const storeStages = (params) =>
    axios.post('watchlist/stages', params)
        .then(({ data }) => data)

export const getWatchlistCompanies = (params) =>
    axios.post('watchlist/companies', params)
        .then(({ data }) => data)

export const getWatchlistDeals = (params) =>
    axios.post('watchlist/deals', {...params, status: DealStatus.active})
        .then(({ data }) => data)

export const getWatchlistClosedDeals = (params) =>
    axios.post('watchlist/deals', {...params, status: DealStatus.closed})
        .then(({ data }) => data)
