import axios from 'axios';

export const getEoiUser = (uuid) =>
    axios.get(`public/eoi-user/${uuid}`)
        .then(({ data }) => data);

export const storeEoiRegistrationDetails = (params, uuid) =>
    axios.post(`public/eoi-user/${uuid}`, params)
        .then(({ data }) => data);

export const storeEoiContractNote = (params, uuid) =>
    axios.post(`public/eoi-user/${uuid}/contract-note`, params)
        .then(({ data }) => data)

export const storeEoiCertificate = (params, uuid) =>
    axios.post(`public/eoi-user/${uuid}/certificate`, params)
        .then(({ data }) => data)