import { Fragment } from 'react';
import { Footer as FooterStyled, MenuUl } from './styled';
import { Icon } from 'assets/Icon';
import {
    Container
} from '~/Common';
import {
    Box,
    Flex,
    Grid,
    Text,
    NavLink as ThemedNavLink
} from '@theme-ui/components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APP_FEATURES } from 'app/constants';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { fetchUserOpentrader } from 'features/auth/authSlice';
import { grabFirstError } from 'utils/helpers'

export const FooterLink = ({ name, path, label, variant, onClick, ...props }) => {
    const { t } = useTranslation();

    return (
        <Box as="li" {...props}>
            <ThemedNavLink as={NavLink} to={path} id={`FooterMenu_${name}`} variant={variant ?? 'footer'} onClick={onClick}>{t(label)}</ThemedNavLink>
        </Box>
    );
};

export const Footer = ({ ...props }) => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.user?.user);
    const adviser = useSelector((state) => state.user?.adviser);
    const activeProfile = useSelector((state) => state.user?.activeProfile);
    const isAdviser = useSelector((state) => state.user?.user?.is_adviser);
    const history = useHistory();

    const handleOpentraderOnClick = (e) => {
        e.preventDefault();

        if(!user?.can_see_trade_link){
            if(!user?.finished_onboarding_details){
                toast.info(t('login.opentrader_not_ready_finish_onboarding'), {
                    toastId: 'opentrader-warning'
                })
                history.push('/onboarding');
            }
            if (activeProfile?.account_management_type === 'Full Service') {
                toast.info(t('login.opentrader_not_ready_full_service'), {
                    toastId: 'opentrader-warning'
                })
                return; 
            }
            toast.info(t('login.opentrader_not_ready'), {
                toastId: 'opentrader-warning'
            })
            return;
        }

        fetchUserOpentrader().then((response) => {
            if (response?.redirect_uri && response?.token){
                setTimeout(() => {
                    window.open(`${response.redirect_uri}?token=${response.token}`, '_blank');
                });
            }else{
                toast.error(t('login.opentrader_error'), {
                    toastId: 'opentrader-error'
                })
            }
        }).catch(({ response }) => {
            toast.error((grabFirstError(response.data?.errors) || response.data?.message) ?? t('login.opentrader_error'), {
                toastId: 'opentrader-error'
            })
        });
    };

    const menu = [
        { name: 'dashboard', path: '/adviser/dashboard', label: 'Dashboard', hidden: !isAdviser },
        { name: 'portfolio', path: '/portfolio', label: 'Portfolio', hidden: isAdviser },
        // Show the trade link for apps that have it enabled as well as only for user's that are fully verified
        { name: 'trade', path: '/trade', label: 'Trade', hidden: Boolean(adviser || isAdviser || !JSON.parse(process.env.REACT_APP_TRADE)), onClick: handleOpentraderOnClick, disabled: !user?.can_see_trade_link, tooltip: t('login.opentrader_not_ready') },
        { name: 'deals', path: '/deals', label: 'Deals' },
        { name: 'companies', path: '/companies', label: 'Trending' },
        { name: 'watchlist', path: '/following', label: 'Following', hidden: !APP_FEATURES.following },
        { name: 'news', path: '/news', label: 'News', hidden: !APP_FEATURES.news }
    ];
    const secondary = [
        { name: 'account', path: '/settings/account', label: 'My Account' },
        { name: 'contact', path: '/contact', label: 'Contact' }
    ];

    const legal = [
        { name: 'trust', path: '/trust-and-safety', label: 'Trust & Safety' },
        { name: 'terms', path: '/terms', label: 'Terms of Use' },
        { name: 'privacy', path: '/privacy-policy', label: 'Privacy Policy' },
    ];

    const social = [
        { name: 'linkedin', path: 'https://www.linkedin.com/company/liquidityau/', icon: 'linkedin' },
        { name: 'twitter', path: 'https://twitter.com/liquidityau', icon: 'twitter' },
        { name: 'facebook', path: 'https://www.facebook.com/liquidityau', icon: 'facebook' },
        { name: 'instagram', path: 'https://www.instagram.com/liquidityau/', icon: 'instagram' },
    ];

    return (
        <FooterStyled>
            <Container py={5}>
                <Grid sx={{
                    gridTemplate: ['auto', 'auto', 'auto / 546px 1fr']
                }}>
                    <Box>
                        <Box mb={'28px'}>
                            <Icon icon="logo" height="30px" width="auto" color="light" />
                        </Box>
                        <Text as="p" sx={{ fontSize: '18px', lineHeight: '27px' }}>{t('footer.description')}</Text>
                        <Flex mt={3} role="navigation" aria-label="Social Navigation">
                            {social.map((item) => (
                                <ThemedNavLink key={item.name} href={item.path} sx={{
                                    width: '40px',
                                    height: '40px',
                                    mr: 2,
                                    borderRadius: '99px',
                                    '&:hover': {
                                        bg: 'dark'
                                    },
                                    '&:focus': {
                                        outline: '2px solid',
                                        outlineColor: 'tealDark'
                                    }
                                }}>
                                    <Icon color="white" icon={item.icon} />
                                </ThemedNavLink>
                            ))}
                        </Flex>
                    </Box>
                    <Box sx={{ textAlign: ['left', 'left', 'right'] }} role="navigation" aria-label="Footer Navigation">
                        <MenuUl>
                            {menu.map((subItem) => subItem.hidden === true ? null : (
                                <FooterLink
                                    key={subItem.name}
                                    {...subItem}
                                    sx={{
                                        display: ['block', 'block', 'inline-block'],
                                        '> a': {
                                            paddingLeft: [0,0,3]
                                        }
                                    }}
                                />
                            ))}
                        </MenuUl>
                        <MenuUl>
                            {secondary.map((subItem) => subItem.hidden === true ? null : (
                                <FooterLink
                                    key={subItem.name}
                                    {...subItem}
                                    sx={{
                                        display: ['block', 'block', 'inline-block'],
                                        '> a': {
                                            paddingLeft: [0,0,3]
                                        }
                                    }}
                                />
                            ))}
                        </MenuUl>
                    </Box>
                </Grid>
            </Container>
            <Box py={3} sx={{
                backgroundColor: 'darker',
                fontWeight: 'body',
                fontSize: '11px',
                color: 'light'
            }}>
                <Container>
                    <Flex sx={{ justifyContent: 'space-between', flexDirection: ['column', 'column', 'row'] }}>
                        <Box py={[3, 3, 0]} sx={{
                            width: ['100%', '100%', '50%']
                        }}>
                            <Box sx={{
                                display: ['block', 'block', 'flex']
                            }}>
                                <Box py={1} mr={3}>{t('footer.copyright', { year: new Date().getFullYear() })}</Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            width: ['100%', '100%', '50%'],
                            textAlign: ['left', 'left', 'right']
                        }}
                        role="navigation" aria-label="Legal Navigation"
                        >
                            <MenuUl>
                                {legal.map((subItem, i) => (
                                    <Fragment key={subItem.name}>
                                        <FooterLink
                                            {...subItem}
                                            variant="footer_legal"
                                            sx={{
                                                display: ['block', 'block', 'inline-block'],
                                            }}
                                        />
                                        <Box sx={{
                                            display: ['none', 'none', 'inline']
                                        }}>
                                            {i < legal.length - 1 ? ' | ' : ''}
                                        </Box>
                                    </Fragment>
                                ))}
                            </MenuUl>
                        </Box>
                    </Flex>
                </Container>
            </Box>
        </FooterStyled>
    );
};
