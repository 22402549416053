import {
    Box
} from '@theme-ui/components';
import { DashboardNotice } from '~/Common';

export const PageHeader = ({ children, sx, ...props }) => {
    return (
        <>
            <DashboardNotice />
            <Box sx={{ ...sx }} {...props}>
                {children}
            </Box>
        </>
    );
};
