import styled from '@emotion/styled';
import { theme } from 'app/theme';

export const Footer = styled('div')`
    background: ${theme.colors.darker};
    margin-top: auto;
    color: #fff;
`;

export const MenuUl = styled('ul')`
    margin: 0;
    padding: 0;

    & li {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        padding: 0;
        list-style: none;
    }

    & a.active:not(:hover) {
        color: ${theme.colors.light};
    }
`;
