import { EntityAddress } from './EntityAddress';
import { EntityDetails } from './EntityDetails';
import { UploadCertificate } from './UploadCertificate';
import { EntityType } from './EntityType';
import { UploadDeed } from './UploadDeed';
import { AddDirectors } from './AddDirectors';
import { TradingPreferences } from './TradingPreferences';

export { EntityAddress, EntityDetails, UploadCertificate, EntityType, UploadDeed };

export const addEntityStep = {
    title: 'Add Entity',
    description: 'Select which entity account you want to create.',
    hideBack: true,
    validate: ['type'],
    component: EntityType,
};

// Separate the fields that should be submitted at each step
export const StepFields = {
    // The fields that will be submitted each time
    all: ['step','uuid','type','confirm'],

    details: [
        'name',
        'accountant_email',
        'abn',
        'acn',
        'phone',
        'email',
        'tax_number',
        'source_of_wealth',
        'designation',
        'company_type',
        'incorporation_country',
        'incorporation_at',
        'industry_code',
        'contact_email',
        'contact_phone',
        'smsf',
        'company_name',
        'company_tax_number',
        'company_abn',
        'company_acn',
        'company_incorporation_country',
        'company_incorporation_at',
        'settlor_name',
        'trust_type',
        'country',
        'sum_established_ten_thousand'
    ],
    address: [
        'address',
        'company_address',
        'principle_address',
        'chess_address',
        'same_company_address',
        'same_principle_address',
        'same_chess_address',
    ],
    applicants: [
        'applicants',
        'applicant_is_director',
        'applicant_is_beneficial_owner'
    ],
    trade: [
        'affiliated_with_other_asx_or_government',
        'affiliated_with_other_asx_or_government_details',
        'send_chess_email',
        'chess_email',
        'additional_trade_confirmation_emails',
        'additional_direct_debit_fields',
        'account_management_type'
    ]
};

export const Steps = {
    company: [
        {
            title: 'settings.profile.create.details.title',
            description: 'settings.profile.create.details.companyDescription',
            validate: ['name', 'acn'],
            component: EntityDetails,
            updateOnNext: true,
            submittedStep: 'details'
        },
        {
            title: 'Registered Address',
            validate: ['address'],
            component: EntityAddress,
            updateOnNext: true,
            submittedStep: 'address'
        },
        {
            title: 'Upload s708 Certificate',
            description: 'In order to add a Company, you will be required to prove you are an experienced investor by providing a signed s708 certificate form. If accepted, your account will be approved.',
            component: UploadCertificate,
            submittedStep: 'certificate'
        },
        {
            title: 'Add Directors',
            description: 'settings.profile.applicantsReasonCompany',
            component: AddDirectors,
            updateOnNext: true,
            submittedStep: 'applicants',
        },
        {
            title: 'Trading Preferences',
            description: '',
            component: TradingPreferences,
            submitOnNext: true,
            submittedStep: 'trade',
            nextButtonText: 'Submit',
            confirm: true,
        },
        {
            title: 'You are all set',
            description: 'Your company account has been created. You will be notified once it has been verified.',
            hideBack: true,
            hideNext: true,
            hideCancel: true,
            complete: true,
        },
    ],
    trust: [
        {
            title: {
                company: 'settings.profile.create.details.title',
                individual: 'settings.profile.create.details.title'
            },
            description: {
                company: 'settings.profile.create.details.trustDescription',
                individual: 'settings.profile.create.details.trustDescription'
            },
            validate: ['name', 'acn'],
            component: EntityDetails,
            updateOnNext: true,
            submittedStep: 'details'
        },
        {
            title: 'Certified Copy of Trust',
            description: 'A certified copy of the Trust deed extract and any amendments. The sections required are the schedule and execution pages of the deed.',
            descriptionTwo: 'A certified copy must be signed as a true and correct copy by an authorised person, independent from the applicant and application.',
            descriptionThree: 'All documents must have been certified within the last 12 months, at the time of acceptance by us.',
            component: UploadDeed,
            submittedStep: 'deed'
        },
        {
            title: 'Fund Address',
            component: EntityAddress,
            updateOnNext: true,
            submittedStep: 'address'
        },
        {
            title: 'Upload s708 Certificate',
            description: 'In order to add a Trust, you will be required to prove you are an experienced investor by providing a signed s708 certificate form. If accepted, your account will be approved.',
            component: UploadCertificate,
            submittedStep: 'certificate'
        },
        {
            title: {
                company: 'Directors & Beneficial Ownership',
                individual: 'Add Applicants'
            },
            description: {
                company: 'settings.profile.applicantsReasonCompany',
                individual: 'settings.profile.applicantsReasonTrust'
            },
            component: AddDirectors,
            updateOnNext: true,
            submittedStep: 'applicants',
        },
        {
            title: 'Trading Prefereces',
            description: '',
            component: TradingPreferences,
            submitOnNext: true,
            submittedStep: 'trade',
            nextButtonText: 'Submit',
            confirm: true,
        },
        {
            title: 'You are all set',
            description: 'Your Trust account has been created. You will be notified once it has been verified.',
            hideBack: true,
            hideNext: true,
            hideCancel: true,
            complete: true,
        },
    ],
    superannuation: [
        {
            title: {
                company: 'settings.profile.create.details.title',
                individual: 'settings.profile.create.details.title'
            },
            description: {
                company: 'settings.profile.create.details.superannuationDescription',
                individual: 'settings.profile.create.details.superannuationDescription'
            },
            validate: ['name', 'acn'],
            component: EntityDetails,
            updateOnNext: true,
            submittedStep: 'details'
        },
        {
            title: 'Certified Copy of Super Fund',
            description: 'A certified copy of the Super Fund deed extract and any amendments. The sections required are the schedule and execution pages of the deed.',
            descriptionTwo: 'A certified copy must be signed as a true and correct copy by an authorised person, independent from the applicant and application.',
            descriptionThree: 'All documents must have been certified within the last 12 months, at the time of acceptance by us.',
            component: UploadDeed,
            submittedStep: 'deed'
        },
        {
            title: 'Fund Address',
            component: EntityAddress,
            updateOnNext: true,
            submittedStep: 'address'
        },
        {
            title: 'Upload s708 Certificate',
            description: 'In order to add a Super Fund, you will be required to prove you are an experienced investor by providing a signed s708 certificate form. If accepted, your account will be approved.',
            component: UploadCertificate,
            submittedStep: 'certificate'
        },
        {
            title: {
                company: 'Directors & Beneficial Ownership',
                individual: 'Add Applicants'
            },
            description: {
                company: 'settings.profile.applicantsReasonCompany',
                individual: 'settings.profile.applicantsReasonSuper'
            },
            descriptionTwo: {
                company: 'There must be at least 1 Director and 1 Beneficial Owner.',
                individual: ''
            },
            component: AddDirectors,
            updateOnNext: true,
            submittedStep: 'applicants',
        },
        {
            title: 'Trading Prefereces',
            description: '',
            component: TradingPreferences,
            submitOnNext: true,
            submittedStep: 'trade',
            nextButtonText: 'Submit',
            confirm: true,
        },
        {
            title: 'You are all set',
            description: 'Your Super Fund account has been created. You will be notified once it has been verified.',
            hideBack: true,
            hideNext: true,
            hideCancel: true,
            complete: true,
        },
    ],
};
