import { useContext, useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Close,
    Heading,
    Spinner,
    Text,
    Flex
} from '@theme-ui/components';
import { SkipIdentity, Alert as StyledAlert } from '~/Common'
import { Icon } from 'assets/Icon';
import * as Yup from 'yup';
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { OnboardingContext } from 'pages/Onboarding/Landing';
import { useDispatch, connect } from 'react-redux';
import { onboardingVerifyId, getOnboardingStatusAsync } from 'features/auth/authSlice';
import { toast } from 'utils/toast';
import moment from 'moment';
import ReactMarkdown from 'react-markdown'

const mapStateToProps = (state) => {
    return { user: state.user?.user, settings: state.theme?.settings }
}

const validationSchema = Yup.object().shape({
    passportdvs_givenname: Yup.string().matches(/^[a-zA-Z]*$/, 'must not contain numbers or symbols').required('required field'),
    passportdvs_middlename: Yup.string().nullable().matches(/^[a-zA-Z]*$/, 'must not contain numbers or symbols'),
    passportdvs_surname: Yup.string().matches(/^[a-zA-Z]*$/, 'must not contain numbers or symbols').required('required field'),
    passportdvs_dob: Yup.string().test(
            'passportdvs_dob',
            'you must be at least 18',
            (value) => moment().diff(moment(value), 'years') >= 18
        )
        .required('required field'),
    passportdvs_number: Yup.string().required('required field').trim()
        .matches(/^[a-zA-Z]{1,2}[0-9]{7}$/, 'Please enter a valid Australian Passport Number'),
    passportdvs_gender: Yup.string().nullable(),
    passportdvs_tandc: Yup.boolean().default(false).oneOf([true], 'You must accept the terms and conditions before we can send your data')
});

const OnboardingPassport = ({ user, settings }) => {
    const onboarding = useContext(OnboardingContext);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user?.tasks?.tests?.has_provided_passport) {
            onboarding.nextStep();
        }
        setLoading(false);
    }, [user, onboarding]);

    const handleSubmit = (values, actions) => {
        setLoading(true);

        onboardingVerifyId({
            source: 'passportdvs',
            passportdvs_givenname: values.passportdvs_givenname,
            passportdvs_middlename: values.passportdvs_middlename,
            passportdvs_surname: values.passportdvs_surname,
            passportdvs_dob: values.passportdvs_dob,
            passportdvs_number: values.passportdvs_number,
            passportdvs_gender: values.passportdvs_gender !== "" ? values.passportdvs_gender : null,
            passportdvs_tandc: values.passportdvs_tandc ? 'on' : null
        }).then((response) => {
            toast.success(t('onboarding.landing.passport.success'), {
                toastId: 'passport-success',
            });
            onboarding.nextStep();
            dispatch(getOnboardingStatusAsync());
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(response?.data?.message || t('onboarding.landing.passport.error'), {
                toastId: 'passport-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        }).finally(() => setLoading(false));
    };

    return (
        <>
            {loading && <Spinner variant="styles.spinner" m={'auto'} />}

            <Box sx={{
                display: loading ? 'none' : 'block'
            }}>
                <Formik
                    enableReinitialize
                    initialValues={{
                        passportdvs_number: '',
                        passportdvs_givenname: user?.first_name ?? '',
                        passportdvs_middlename: user?.middle_names ?? '',
                        passportdvs_surname: user?.last_name ?? '',
                        passportdvs_dob: user?.individual?.dob ?? '',
                        passportdvs_gender: user?.gender ?? '',
                        passportdvs_tandc: false
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ status, setStatus }) => (
                        <Box>
                            { status === 'api_error' ?
                            <Alert role="alert" variant='danger' mb={2}>
                                {t('onboarding.landing.passport.error')}
                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                            </Alert> : ''}

                            <Form>
                                <Flex sx={{
                                    mb: 4,
                                    alignItems: 'center'
                                }}>
                                    <Icon icon="passport" size="48px" mr={2} color="tealLight" />
                                    <Heading as="h1" variant="h3" sx={{ fontWeight: 500, lineHeight: 1 }}>
                                        {t('onboarding.landing.passport.title')}
                                    </Heading>
                                </Flex>

                                <Box mb={4}>
                                    <SkipIdentity sx={{ textAlign: 'left' }} />
                                </Box>

                                <Text as="p" mb={4} sx={{
                                    fontSize: 2,
                                    color: 'dark'
                                }}>
                                    {t('onboarding.landing.passport.subtitle')}
                                </Text>

                                <StyledAlert mb={4} variant="info" isCloseable={false}>{t('onboarding.landing.passport.legal_name')}</StyledAlert>

                                <Field
                                    label="First name"
                                    name="passportdvs_givenname"
                                />
                                <Field
                                    label="Middle names"
                                    name="passportdvs_middlename"
                                />
                                <Field
                                    label="Last name"
                                    name="passportdvs_surname"
                                />

                                <Field
                                    field="date"
                                    label="Date of birth"
                                    name="passportdvs_dob"
                                />
                                <Field
                                    field="select"
                                    label="Gender"
                                    placeholder="-- Gender --"
                                    options={[
                                        { label: 'Male', value: 'M'},
                                        { label: 'Female', value: 'F'}
                                    ]}
                                    name="passportdvs_gender"
                                />

                                <Field
                                    label="Passport number"
                                    name="passportdvs_number"
                                />

                                <Box sx={{
                                    overflow: 'auto',
                                    maxHeight: '300px',
                                    bg: 'light',
                                    border: '1px solid',
                                    borderColor: 'medium',
                                    borderRadius: '4px',
                                    p: 3,
                                    mb: 4,
                                    fontSize: '0.8rem'
                                }}>
                                    <Box>
                                        <ReactMarkdown>{settings.identity_terms_conditions}</ReactMarkdown>
                                    </Box>
                                </Box>

                                <Field
                                    field="checkbox"
                                    label="I agree to the above terms and conditions"
                                    name="passportdvs_tandc"
                                />

                                <Submit variant="primary" text={t('onboarding.landing.passport.submit')} sx={{ display: 'block', width: '100%' }} />
                            </Form>
                        </Box>
                    )}
                </Formik>
            </Box>
        </>
    )
};

export default connect(mapStateToProps, null)(OnboardingPassport);
