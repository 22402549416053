import styled from '@emotion/styled';
import { theme } from 'app/theme';

export const ProfileMenu = styled('div')`
    position: fixed;
    top: ${theme.styles.header.height};
    right: 40px;
    margin-top: 5px;
    background: #fff;
    width: 80%;
    max-width: 280px;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.3s ease-in-out 0.1s,
                opacity 0s linear 0.3s,
                visibility 0s linear 0.3s;
    z-index: 999;
    overflow: visible;
    visibility: hidden;
    opacity: 0;

    &.open{
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
    }

    & a{
        position: relative;
        display: inline-flex;
    }
    & a:focus{
        outline: none;
    }
    & a.active{
        color: ${theme.colors.darker};
    }

    & .header__switchProfile {
      right: 100%;
      top: 0;
      margin-right: 20px;
    }

    @media(max-width: 991px) {
        margin: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: unset;
        border-radius: 0;
        width: 100%;
        box-shadow: none;

        & .header__switchProfile {
            right: 0;
            margin: 0;
            height: 100%;
        }
    }
`;

export const ProfileMenuBox = styled('div')`
    padding: 8px 16px;
    border-bottom: 1px solid ${theme.colors.light};

    &:last-child {
      border-bottom: 0;
    }

    & a{
        display: inline-flex;
        align-content: center;
        justify-content: flex-start;
        text-align: left;
        color: ${theme.colors.darker};
    }
`;

export const MenuUl = styled('ul')`
    margin: 0;
    padding: 0;

    & li {
        display: flex;
        align-items: center;
        margin: 0;
        margin-top: 7px;
        list-style: none;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px;
            border-radius: 8px;
            transition: background-color 0.3s ease-in-out;

            &:hover, &:focus {
                background: ${theme.colors.secondaryLight};
            }
        }
    }
`;
