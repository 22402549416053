import { Box } from '@theme-ui/components'

const progressStyles = {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: '4px solid #19C5B4',
    top: '0',
    left: '0',
    borderRadius: '50%',
}

export const ProgressCircle = ({ progress = 45, total = 100, size = 40, sx, ...props }) => {
    const progressPercent = (progress / total) * 100;

    return (
        <Box sx={{
            position: 'relative',
            width: `${size}px`,
            height: `${size}px`,
            '&::after': {
                ...progressStyles,
                borderColor: '#D5D9DA',
                zIndex: 0,
                boxSizing: 'border-box',
            },
            ...sx
        }}>
            <Box sx={{
                position: 'absolute',
                borderRadius: '50%',
                width: '100%',
                height: '100%',
                inset: 0,
                clip: progressPercent > 50 ? 'rect(auto, auto, auto, auto)' : `rect(0, ${size}px, ${size}px, ${size / 2}px)`,
                zIndex: 1
            }} {...props}>
                <Box sx={{
                    ...progressStyles,
                    clip: `rect(0, ${size / 2}px, ${size}px, 0)`,
                    transform: `rotate(${progressPercent * 3.6}deg)`,
                }} />
                <Box sx={{
                    ...progressStyles,
                    clip: progressPercent > 50 ? `rect(0, ${size / 2}px, ${size}px, 0)` : `rect(0, ${size}px, ${size}px, ${size / 2}px)`,
                    visibility: progressPercent > 50 ? 'visible' : 'hidden',
                    transform: progressPercent > 50 ? 'rotate(180deg)' : 'rotate(0)',
                }} />
            </Box>
        </Box>
    );
};
