import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    getLoungeExperiences, getLoungeTrades,
} from './loungeAPI'

export const getLoungeExperiencesAsync = createAsyncThunk(
    'lounge/getLoungeExperiences',
    async (payload) => {
        const response = payload === undefined ? await getLoungeExperiences() : payload;
        return response;
    }
);

export const getLoungeTradesAsync = createAsyncThunk(
    'lounge/getLoungeTrades',
    async (payload) => {
        const response = payload === undefined ? await getLoungeTrades() : payload;
        return response;
    }
);

const initialState = {
    experiences: {
        status: 'idle',
        results: [],
    },
    trades: {
        status: 'idle',
        results: [],
    },
};

const lounge = createSlice({
    name: 'lounge',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getLoungeExperiencesAsync.pending, (state) => {
                state.experiences.status = 'loading';
            })
            .addCase(getLoungeExperiencesAsync.rejected, (state) => {
                state.experiences.results = [];
                state.experiences.status = 'attempted';
            })
            .addCase(getLoungeExperiencesAsync.fulfilled, (state, {payload}) => {
                state.experiences.results = payload?.data;
                state.experiences.status = 'attempted';
            })
            .addCase(getLoungeTradesAsync.pending, (state) => {
                state.trades.status = 'loading';
            })
            .addCase(getLoungeTradesAsync.rejected, (state) => {
                state.trades.results = [];
                state.trades.status = 'attempted';
            })
            .addCase(getLoungeTradesAsync.fulfilled, (state, {payload}) => {
                state.trades.results = payload?.data;
                state.trades.status = 'attempted';
            });
    },
});

export default lounge.reducer;
