import { Icon } from 'assets/Icon';
import { FieldArray, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Grid, Heading, Text, Themed } from 'theme-ui';
import { toTitleCase } from 'utils/helpers';
import { Accordion, Button } from '~/Common';
import { Field } from '~/Forms';

export const AddDirectorsUncoupled = ({ theme, countries, profileOptions, stateOptions, isLocked, ...props }) => {
    const { values, setFieldValue } = useFormikContext();
    const { t } = useTranslation();
    const [occupationCategories, setOccupationCategories] = useState([]);
    const hasApplicants = Boolean(Array.isArray(values.applicants) && values.applicants.length > 0);

    const updateOccupationCategories = (e, index) => {
        const temp = occupationCategories;
        temp[index] = theme.profile.options.macquarieOccupations[e?.value] ?? [];
        setOccupationCategories(temp);
    };

    const createApplicant = (role = 'DIRECTOR', values, push) => {
        const count = values.applicants.length;
        updateOccupationCategories(null, count);
        push({
            role: role,
            title: '',
            first_name: '',
            middle_names: '',
            last_name: '',
            mobile: '',
            email: '',
            dob: '',
            tax_number: '',
            tax_country: 'AU',
            citizenship_country: 'AU',
            country_of_birth: 'AU',
            macquarie_occupation_type: '',
            address: {
                unit_number: '',
                street_number: '',
                street_name: '',
                street_type: '',
                address2: '',
                state: '',
                suburb: '',
                postcode: '',
            },
            driver_licence: {
                state: '',
                number: '',
                cardnumber: '',
            },
        });
    };

    const ProfileTypeToRoleType = {
        company: 'roleTypesCompany',
        superannuation: 'roleTypesSuper',
        superannuation_company: 'roleTypesCompany',
        trust: 'roleTypesTrust',
        trust_company: 'roleTypesCompany'
    };
    const roleTypes = profileOptions[ProfileTypeToRoleType[values.sub_type]] ?? profileOptions[ProfileTypeToRoleType[values.type]];
    const macquarieOccupationTypes = profileOptions?.macquarieOccupationTypes;

    useEffect(() => {
        if (theme.profile.status === 'attempted' && theme.countries.status === 'attempted'){
            if (Array.isArray(values.applicants) && values.applicants.length) {
                const temp = [];
                values.applicants.forEach((applicant, index) => {
                    temp.push(profileOptions?.macquarieOccupations[applicant.macquarie_occupation_type] ?? []);
                });
                setOccupationCategories(temp);
            }
        }
    }, [theme, values, profileOptions]);

    return (
        <Box mb={5}>
            <Text mb={4} as="p">{t('fieldsRequired')}</Text>

            {(values.type === 'company' || values.sub_type?.includes('company')) && (
                <Grid
                    mb={0}
                    sx={{
                        gridGap: [0,0,0,3],
                        gridTemplateColumns: ['1fr', '1fr', '1fr', 'repeat(2, 1fr)'],
                        width: '100%',
                        maxWidth: '432px',
                    }}
                >
                    <Field label="I am a Director" name="applicant_is_director" field="checkbox" value={true} checked={values.applicant_is_director} validIndicator={false} />
                    <Field label="I am a Beneficial Owner" name="applicant_is_beneficial_owner" field="checkbox" value={true} checked={values.applicant_is_beneficial_owner} validIndicator={false} />
                </Grid>
            )}

            <FieldArray name="applicants">
                {({ remove, push }) => (
                    <>
                        {hasApplicants ? (
                            values.applicants.map((applicant, index) => (
                                <Accordion
                                    title={toTitleCase(`${applicant.first_name ?? ''} ${applicant.last_name ?? ''}`.trim())}
                                    isOpen={true}
                                    emptyTitle={t('New Applicant')}
                                    key={`profile.applicants.${index}`}
                                >
                                    {Array.isArray(roleTypes) && roleTypes.length > 0 ? (
                                        <Field label="Role *" placeholder="Select role" name={`applicants.${index}.role`} field="searchable-select" options={roleTypes} />
                                    ) : null}

                                    <Field label="Title *" placeholder="Select title" name={`applicants.${index}.title`} field="searchable-select" options={profileOptions.titles} required={true} />
                                    <Field label="First Name *" name={`applicants.${index}.first_name`} updateOnBlur={true} />

                                    <Field label="Middle Names" name={`applicants.${index}.middle_names`} updateOnBlur={true} />
                                    <Field label="Last Name *" name={`applicants.${index}.last_name`} updateOnBlur={true} />

                                    <Field label="Mobile *" name={`applicants.${index}.mobile`} field="phone" updateOnBlur={true} />
                                    <Field label="Email *" name={`applicants.${index}.email`} type="email" updateOnBlur={true} />

                                    <Box sx={{ mb: 4, fontSize: '13px', pt: 1 }}>
                                        <Text sx={{ color: 'dark' }}>{t('settings.profile.phoneEmailReason')}</Text>
                                    </Box>

                                    <Field label="Date of Birth *" name={`applicants.${index}.dob`} field="date" />

                                    {countries.length > 0 && (
                                        <>
                                            <Field label="Country of citizenship *" placeholder="Select country" name={`applicants.${index}.citizenship_country`} field="searchable-select" options={countries} />
                                            <Field label="Country of birth *" placeholder="Select country" name={`applicants.${index}.country_of_birth`} field="searchable-select" options={countries} />
                                        </>
                                    )}

                                    <Heading as="h3" variant="settings_heading" mb={3}>
                                        {t('Australian Tax File Number')}
                                    </Heading>
                                    <Field label="TFN^" name={`applicants.${index}.tax_number`} updateOnBlur={true} />

                                    <Box sx={{ mb: 4, fontSize: '13px', pt: 1 }}>
                                        <Text sx={{ color: 'dark' }}>^{t('settings.profile.taxFileNumberReason')}</Text>
                                    </Box>

                                    <Heading as="h3" variant="settings_heading" mb={3}>
                                        {t('Occupation Details')}
                                    </Heading>

                                    {Boolean(Array.isArray(macquarieOccupationTypes) && macquarieOccupationTypes.length > 0) && (
                                        <Field
                                            label="Occupation type *"
                                            placeholder="Select occupation type"
                                            name={`applicants.${index}.macquarie_occupation_type`}
                                            field="searchable-select"
                                            onChange={(e) => {
                                                updateOccupationCategories(e, index);
                                            }}
                                            options={macquarieOccupationTypes}
                                        />
                                    )}
                                    {occupationCategories[index] !== undefined && occupationCategories[index].length > 0 && (
                                        <Field label="Occupation category *" placeholder="Select occupation category" name={`applicants.${index}.macquarie_occupation_category`} field="searchable-select" options={occupationCategories[index]} />
                                    )}

                                    <Heading
                                        as="h2"
                                        variant="form_section"
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: 2,
                                            my: 3,
                                        }}
                                    >
                                        {t('Address Details')}
                                    </Heading>

                                    <Field field="google_places" label="Search for address..." name={`applicants.${index}.address.address`} fieldNamePrefix={`applicants.${index}.address.`} setFieldValue={setFieldValue} />

                                    <Box>
                                        <Grid
                                            mb={1}
                                            sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%',
                                            }}
                                        >
                                            <Field label="Unit Number" name={`applicants.${index}.address.unit_number`} updateOnBlur={true} />
                                            <Field label="Street Number *" name={`applicants.${index}.address.street_number`} updateOnBlur={true} />
                                        </Grid>

                                        <Grid
                                            mb={1}
                                            sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%',
                                            }}
                                        >
                                            <Field label="Street Name *" name={`applicants.${index}.address.street_name`} updateOnBlur={true} />
                                            <Field label="Street Type *" name={`applicants.${index}.address.street_type`} updateOnBlur={true} />
                                        </Grid>

                                        <Field label="Address second line" name={`applicants.${index}.address.address2`} updateOnBlur={true} />

                                        <Grid
                                            sx={{
                                                gridGap: [0, 2],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%',
                                            }}
                                        >
                                            <Field label="Suburb *" name={`applicants.${index}.address.suburb`} updateOnBlur={true} />
                                            <Field label="Postcode *" name={`applicants.${index}.address.postcode`} updateOnBlur={true} />
                                        </Grid>
                                        <Field field={stateOptions ? 'select' : 'text'} label="State *" placeholder={stateOptions ? 'Select State' : ''} name={`applicants.${index}.address.state`} options={stateOptions} />

                                        <Heading
                                            as="h2"
                                            variant="form_section"
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: 2,
                                                my: 3,
                                            }}
                                        >
                                            {t('Drivers Licence')}
                                        </Heading>

                                        {applicant.drivers_licence_provided_at ? (
                                            <Box variant="styles.captionWrapper">
                                                <Icon icon="tick" color="tealDark" width="auto" height="9px" sx={{ mr: 2 }} />
                                                <Text variant="caption" sx={{ fontSize: '13px' }}>
                                                    {t('Drivers licence provided')}
                                                </Text>
                                            </Box>
                                        ) : (
                                            <>
                                                <Field field={stateOptions ? 'select' : 'text'} label="State *" placeholder={stateOptions ? 'Select State' : ''} name={`applicants.${index}.driver_licence.state`} options={stateOptions} />

                                                <Grid
                                                    sx={{
                                                        gridGap: [0, 2],
                                                        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Field label="Licence number *" name={`applicants.${index}.driver_licence.number`} />

                                                    <Field label="Card number *" name={`applicants.${index}.driver_licence.cardnumber`} />
                                                </Grid>

                                                <Box
                                                    sx={{
                                                        fontSize: '13px',
                                                        color: 'dark',
                                                        '& a': {
                                                            color: 'tealDark',
                                                        },
                                                    }}
                                                >
                                                    {t('The unique 8-10 character card number.')}{' '}
                                                    <Themed.a href="https://www.opy.com/au/how-to-find-your-licence-card-number/" target="_blank">
                                                        See where this is for your state.
                                                    </Themed.a>
                                                </Box>
                                            </>
                                        )}
                                    </Box>

                                    {!isLocked ? (
                                        <Button
                                            mt={4}
                                            onClick={() => remove(index)}
                                            size="small"
                                            sx={{ py: 1, px: 2, fontSize: 0 }}
                                        >
                                            {t('Remove')}
                                        </Button>
                                    ) : null}
                                </Accordion>
                            ))
                        ) : (
                            <Box mb={3}></Box>
                        )}

                        {!isLocked ? (
                            <Box>
                                {Array.isArray(roleTypes) && roleTypes.length > 0 ? (
                                    <>
                                        {roleTypes.map((roleType, key) => (
                                            <Button
                                                key={`roleTypeButton${key}`}
                                                sx={{
                                                    display: ['flex'],
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    mb: 2
                                                }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    createApplicant(roleType.value, values, push);
                                                }}
                                            >
                                                <Icon icon="plus" size="12" mr={2} />
                                                {t(`Add Additional ${roleType.label}`)}
                                            </Button>
                                        ))}
                                    </>
                                ) : null}
                            </Box>
                        ) : null}
                    </>
                )}
            </FieldArray>
        </Box>
    );
};

export const AddDirectors = connect(
    (state) => ({
        theme: state.theme,
    }),
    null
)(AddDirectorsUncoupled);
