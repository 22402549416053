import Modal from "react-modal";
import {Box, Close, Flex, Heading, Image, Link, Spinner, Text} from "theme-ui";
import {theme as appTheme} from 'app/theme';
import {useTranslation} from "react-i18next";
import {Button, Circle, ImagePlaceholder, Markdown} from "~/Common";
import {formatCurrency, formatNumber, grabFirstError, toTitleCase} from "utils/helpers";
import {useCallback, useEffect, useState} from "react";
import {purchaseExperience, purchaseTrades, purchaseCashback} from "features/lounge/loungeAPI";
import {Icon} from "assets/Icon";
import LqdLogoSvg from 'assets/Svg/LqdLogo.svg';
import {connect} from "react-redux";
import {toast} from "utils/toast";
import {Field} from "~/Forms";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {CoinBalance} from "~/Common/CoinBalance";
import { ReactComponent as DollarSvg } from 'assets/Svg/DollarSign.svg';
import { LQD_CONVERSION } from "app/constants";
import {useResponsiveValue} from "@theme-ui/match-media";

const mapStateToProps = (state) => {
    return {user: state.user?.user}
}

export const LoungeModal = connect(mapStateToProps)(
    ({user, isOpen, handleClose, data, onPurchase, balance, setBalance, ...props}) => {
        const {t} = useTranslation();
        const [isPurchasing, setPurchasing] = useState(false);
        const [state, setState] = useState({});
        const [amount, setAmount] = useState(data?.type === 'cash' ? 5 : 0);
        const isVertMobile = useResponsiveValue([true, true, true, false, false, false]);

        const inputSx = {
            maxWidth: '215px',
            ml: 'auto',
            mr: 'auto',
        }

        const validationSchema = Yup.object().shape({
            coins: Yup.number().transform((_value, orig) => formatNumber(orig)).min(5).max(user?.coins).required(),
        });

        const modalClose = () => {
            setAmount(0);
            setPurchasing(false);
            handleClose();
        };

        useEffect(() => {
            let stateData;
            switch (data?.type) {
                case 'experience':
                    stateData = {
                        logo: data.experience?.company?.logo?.sizes?.tiny,
                        companyName: data.experience.company.name,
                        name: toTitleCase(data.experience.name),
                        description: data.experience.description,
                        price: data.experience.price,
                        disabled: Boolean(user?.coins < data.experience.price),
                        modalImage: data.experience?.image?.sizes?.modal
                    }
                    break;
                case 'trade':
                    stateData = {
                        logo: LqdLogoSvg,
                        companyName: 'Liquidity',
                        name: toTitleCase(data.trade.name),
                        description: t('liquidityLounge.tradeModal.description'),
                        price: data.trade.price,
                        disabled: Boolean(!user?.can_see_trade_link || user?.coins < data.trade.price),
                        modalImage: 'url(img/image-placeholder.jpg)'
                    }
                    break;
                case 'cash':
                    stateData = {
                        logo: LqdLogoSvg,
                        companyName: 'Liquidity',
                        name: 'Redeem for Cash',
                        description: t('liquidityLounge.cashModal.description'),
                        price: 5,
                        disabled: false,
                        modalImage: 'url(img/image-placeholder.jpg)'
                    }
                    break;
                default:
                    break;
            }

            if (!data) {
                // Timeout to avoid loading screen on fade-out
                const timeout = setTimeout(() => setState(null), 500);
                return () => clearTimeout(timeout);
            }

            setState(stateData);
        }, [data, user, t]);

        const setCurrentCoins = useCallback((value) => {
            // Constrain the value between 0 and the user's coin balance
            const newValue = parseInt(value < 0 || value === '' ? 0 : value > user?.coins ? user?.coins : value);
            setBalance(user?.coins - newValue);
            setAmount(newValue);
            return newValue;
        }, [setBalance, user?.coins]);

        const handlePurchase = useCallback(() => {
            const type = data?.type;
            setPurchasing(true);

            switch (type) {
                case 'experience':
                    purchaseExperience(data.experience.uuid)
                        .then(() => {
                            setPurchasing('complete');
                            onPurchase && onPurchase();
                        })
                        .catch(({response}) => {
                            setPurchasing(false);
                            toast.error(grabFirstError(response?.data?.errors) ?? t('liquidityLounge.failedPurchase'), {
                                toastId: 'purchase-error-experience',
                            })
                        })
                    break;
                case 'trade':
                    purchaseTrades(data.trade.free_trades)
                        .then(() => {
                            setPurchasing('complete');
                            onPurchase && onPurchase();
                        })
                        .catch(({response}) => {
                            setPurchasing(false);
                            toast.error(grabFirstError(response?.data?.errors) ?? t('liquidityLounge.failedPurchase'), {
                                toastId: 'purchase-error-trade',
                            })
                        })
                    break;
                case 'cash':
                    purchaseCashback(parseInt(amount))
                        .then(() => {
                            setPurchasing('complete');
                            onPurchase && onPurchase();
                        })
                        .catch(({response}) => {
                            setPurchasing(false);
                            toast.error(grabFirstError(response?.data?.errors) ?? t('liquidityLounge.failedPurchase'), {
                                toastId: 'purchase-error-cash',
                            })
                        })
                    break;
                default:
                    setPurchasing(false);
                    break;
            }
        }, [data, onPurchase, t, amount]);

        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={modalClose}
                style={{
                    ...appTheme.modals.default,
                    content: {
                        ...appTheme.modals.default.content,
                        padding: '0px 20px 0px 0px',
                        textAlign: 'left',
                        maxWidth: '950px',
                    },
                }}
                shouldCloseOnOverlayClick={false}
                closeTimeoutMS={300}
                contentLabel="Identity verification"
                aria={{
                    labelledby: "verifyHeading"
                }}
                {...props}
            >
                <Close ml="auto" mr={-2} onClick={modalClose} sx={{
                    position: 'absolute',
                    right: '30px',
                    top: '20px',
                    zIndex: 101
                }}/>
                {!state ? (
                    <Spinner variant="styles.spinner" m={'auto'}/>
                ) : (
                    <Box sx={{height: '100%'}}>
                        <Flex sx={{
                            flexDirection: 'row',
                            gap: '20px',
                            height: '100%',
                        }}>
                            {!isVertMobile && (
                                <Flex sx={{flex: 7}}>
                                    {data?.type === 'experience' ? (
                                        <Image src={state.modalImage} sx={{objectFit: 'none'}}></Image>
                                    ) : (
                                        <Flex sx={{
                                            background: "linear-gradient(0deg, rgba(213, 217, 218, 0.8), rgba(213, 217, 218, 0.8)), url(img/intro-banner.jpg)",
                                            alignSelf: "stretch",
                                            flexGrow: 0,
                                            position: "relative",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                        }}>
                                            {data?.type === 'cash' && (
                                                <>
                                                    <Text color="primary" sx={{
                                                        position: "absolute",
                                                        left: "calc(50% - 259px/2)",
                                                        top: "calc(50% - 30px/2)",
                                                        fontSize: "14px",
                                                        lineHeight: "110%",
                                                        /* or 15px */
                                                        display: "flex",
                                                        alignItems: "center",
                                                        textAlign: "center",
                                                        letterSpacing: "0.6em",
                                                    }}>
                                                        REDEEM FOR CASH
                                                    </Text>
                                                    <DollarSvg></DollarSvg>
                                                </>
                                            )}
                                        </Flex>
                                    )}
                                </Flex>
                            )}
                            <Flex sx={{
                                position: 'relative',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                flexGrow: 1,
                                flex: 12,
                                paddingY: '20px'
                            }} pl={isVertMobile ? 3 : 0}>
                                {data?.type !== 'cash' && (
                                        <Circle size="70px" type="box" sx={{
                                            bg: 'white',
                                            zIndex: 10,
                                            overflow: 'hidden',
                                            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)'
                                        }}>
                                            {state.logo ? (
                                                <Image src={state.logo} sx={{borderRadius: '50%'}}/>
                                            ) : (
                                                <ImagePlaceholder sx={{
                                                    width: '70px',
                                                    height: '70px',
                                                    borderRadius: '50%'
                                                }}/>
                                            )}
                                        </Circle>
                                )}

                                <Box mt={data?.type === 'cash' ? 4 : 3}
                                     mb={3}
                                     sx={{
                                        flexGrow: !data?.type === 'cash',
                                        textAlign: data?.type === 'cash' ? 'center' : 'left',
                                }}>
                                    {data?.type !== 'cash' && (<Text as="p" color="dark" mb={1} sx={{fontSize: '14px'}}>{state.companyName}</Text>)}
                                    <Heading as="h2" variant="h2">
                                        {state.name}
                                    </Heading>
                                </Box>

                                {data?.type === 'cash' && (
                                    <Box my={2} sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        background: '#F7F8F9',
                                        borderRadius: '100px',
                                        width: 'fit-content',
                                        marginX: 'auto',
                                    }}>
                                        <Text mx={3} mt={2}>LQD points</Text>
                                        <CoinBalance balance={balance} showInfo={false} bg="white" />
                                    </Box>
                                )}

                                {data?.type === 'cash' ? (
                                    <Box my={4} sx={{
                                        display: 'flex',
                                        marginX: 'auto',
                                        textAlign: 'center',
                                        width: '75%',
                                        maxWidth: '340px'
                                    }}>
                                        <Text as="h3" variant="h4" color="darker">{state.description}</Text>
                                    </Box>
                                ) : (
                                    <Markdown sx={{
                                        overflowY: 'auto',
                                        maxHeight: ['40vh', '80vh'],
                                        '& p': {
                                            lineHeight: '24px',
                                            letterSpacing: '-0.01em',
                                            textAlign: 'left'
                                        },
                                    }}>
                                        {state.description}
                                    </Markdown>
                                )}


                                {data?.type === 'cash' && (
                                    <Formik
                                        initialValues={{
                                            coins: 5
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={()=>{}}
                                    >
                                        {({values, setFieldValue, errors, touched, isValid}) => (
                                            <Form>
                                                <Flex sx={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} mb={2}>
                                                    <Field
                                                        name="coins"
                                                        field="number"
                                                        label="Set your amount"
                                                        variant="forms.input.investment"
                                                        sx={{
                                                            transition: 'font-size 0.5s ease-in-out',
                                                            fontSize: isVertMobile ? '14px' : '32px'
                                                        }}
                                                        showLabel={false}
                                                        groupSx={inputSx}
                                                        groupMb={0}
                                                        onKeyUp={(e) => {
                                                            const coins = e.target?.value === '' ? '' : parseInt(e.target?.value);
                                                            setCurrentCoins(coins);
                                                            setFieldValue('coins', coins);
                                                        }}
                                                    />
                                                    <Text as='p' mt={3} sx={{ fontSize: '13px' }} color='dark'>{`1LQD = A${formatCurrency(LQD_CONVERSION)}`}</Text>
                                                </Flex>
                                            </Form>
                                        )}
                                    </Formik>
                                )}

                                {data?.type === 'cash' && (
                                    <Box mt={4} sx={{border: 'solid 1px black', height: '0px', marginX: '-20px'}}></Box>
                                )}

                                {isPurchasing !== true ? (
                                    <Box mt={4}>
                                        {isPurchasing === 'complete' ? (
                                            <>
                                                <Flex sx={{alignItems: 'center', mb: 3, gridGap: 2}}>
                                                    <Icon icon="done" size="24" color="tealDark"/>
                                                    {t('Purchase complete')}
                                                </Flex>
                                                <Button sx={{width: ['100%', '247px'], mr: 3, mb: [2, 0]}}
                                                        onClick={modalClose}>{t('Done')}</Button>
                                            </>
                                        ) : (
                                            <Flex mb={data?.type === 'cash' ? 2 : 0} sx={{flexDirection: ['column', 'row'], justifyContent: 'center'}}>
                                                {data?.type === 'cash' && (
                                                    <Flex pl={3} sx={{flex: 3}}>
                                                        <Box>
                                                            <Text as='p' sx={{fontSize: '13px'}} color='dark'>You will get</Text>
                                                            <Text as='h2'>{`A${formatCurrency(amount * LQD_CONVERSION)}`}</Text>
                                                        </Box>
                                                    </Flex>

                                                )}
                                                <Flex sx={{flex: 3}}>
                                                    <Button
                                                        sx={{
                                                            width: ['100%', '247px'],
                                                            mr: data?.type === 'cash' ? 0 : 3,
                                                            ml: data?.type === 'cash' ? [0, 0, 6, 3] : 0,
                                                            mb: [2, 0],
                                                            display: 'inline-flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                        onClick={handlePurchase}
                                                        disabled={state?.disabled}
                                                    >
                                                        {state?.disabled ? (
                                                            <>
                                                                <Icon icon="closed-deals" width="15px"
                                                                      sx={{top: '-1px', position: 'relative', mr: 2}}/>
                                                                {t(Boolean(data?.type === 'experience' || user?.can_see_trade_link) ? 'Not enough coins' : 'Trade account required')}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {t('Confirm')}
                                                            </>
                                                        )}
                                                    </Button>
                                                    {data?.type !== 'cash' && (
                                                        <Link sx={{
                                                            lineHeight: 2.5,
                                                            fontWeight: 400,
                                                            display: ['block', 'inline'],
                                                            textAlign: ['center', 'left']
                                                        }} onClick={modalClose}>{t('Not now')}</Link>
                                                    )}
                                                </Flex>
                                            </Flex>
                                        )}
                                    </Box>
                                ) : (
                                    <Spinner variant="styles.spinner"/>
                                )}
                            </Flex>
                        </Flex>
                    </Box>
                )}
            </Modal>
        )
    }
);
