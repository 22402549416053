import { useState } from 'react'
import { Layout } from '~/Layouts'
import {
    Button,
    Container
} from '~/Common'
import {
    Heading,
    Text
} from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { toast } from 'utils/toast'
import { forgotPassword } from 'features/auth/authSlice'
import { grabFirstError } from 'utils/helpers'
import { useDocumentTitle } from 'hooks/useDocumentTitle'

const mapStateToProps = (state) => {
    return { user: state.user?.user }
}

const AdviserEmailVerify = ({ user }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Adviser Email Verify'));
    const [submitting, setSubmitting] = useState(false);

    const handleClick = () => {
        setSubmitting(true);
        forgotPassword({
            email: user?.email
        }).then((response) => {
            toast.success(response?.data?.message || t('forgotPassword.success'), {
                toastId: 'forgotPassword',
            });
            setSubmitting(false);
        }).catch(({ response }) => {
            localStorage.removeItem('authToken');
            toast.error(grabFirstError(response?.data?.errors) || t('forgotPassword.error'), {
                toastId: 'forgotPassword',
            });
            setSubmitting(false);
        });
    };

    return (
        <Layout>
            <Container>
                <Heading as="h1" mb={4} sx={{
                    fontSize: '26px'
                }}>
                    {t('Please verify your email before you can gain access')}
                </Heading>

                <Text as="p" mb={4}>{t('You will receive an email and upon your return will be asked to reset your password to get started')}</Text>
                <Button variant="primary" size="large" onClick={() => handleClick()} disabled={submitting ? true : undefined}>{t('Send email verification')}</Button>
            </Container>
        </Layout>
    );
};

export default connect(mapStateToProps, null)(AdviserEmailVerify);
