import {
    Box,
    Grid,
    Heading,
    Link,
    Text
} from '@theme-ui/components'

import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { DATE_FORMAT } from 'app/constants'
import { useSelector, useDispatch } from 'react-redux'
import { resendAdviserInviteUser } from 'features/advisers/advisersAPI'
import { setInvites } from 'features/invites/invitesSlice'
import { toast } from 'utils/toast'
import { grabFirstError } from "utils/helpers"
import { useState } from 'react'
import { Icon } from 'assets/Icon'

export const Invites = ({ invites }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [working, setWorking] = useState(false);
    const user = useSelector(state => state.user);
    moment.tz.setDefault(user?.user?.timezone);

    const handleResendInvite = (e, uuid) => {
        e.preventDefault();

        setWorking(true);
        resendAdviserInviteUser(uuid).then((response) => {
            toast.success(response?.message || t('advisers.resendInviteUser.success'), {
                toastId: 'resend-invite-success',
            });
            dispatch(setInvites(response));
        }).catch(({ response }) => {
            toast.error((grabFirstError(response?.data?.errors) || response?.data?.message) || t('advisers.resendInviteUser.error'), {
                toastId: 'resend-invite-error',
            });
        }).finally(() => setTimeout(() => setWorking(false), 1000));
    };

    return (
        <>
            {(invites && invites.length) > 0 ? (
                <Box my={4}>
                    <Grid sx={{
                        alignItems: 'center',
                        gridTemplate: 'auto / repeat(2, 1fr)',
                        borderBottom: '1px solid',
                        borderColor: 'medium',
                        py: 3,
                        mb: 3
                    }}>
                        <Heading as="h2" variant="h3" id="myInvites">{t('My Invites')}</Heading>
                    </Grid>

                    <Grid mb={4} sx={{
                        gridGap: 3,
                        gridTemplateColumns: ['1fr'],
                        width: '100%'
                    }}>
                        <Grid mb={2} sx={{
                            gridGap: 3,
                            gridTemplateColumns: ['1fr 2fr 1fr 1fr 1fr'],
                            width: '100%',
                            display: ['none','none','grid']
                        }}>
                            <Box sx={{ fontWeight: 'bold' }}>{t('Name')}</Box>
                            <Box sx={{ fontWeight: 'bold' }}>{t('Email')}</Box>
                            <Box sx={{ fontWeight: 'bold' }}>{t('Invited On')}</Box>
                            <Box sx={{ fontWeight: 'bold' }}>{t('Expiry')}</Box>
                            <Box sx={{ fontWeight: 'bold' }}></Box>
                        </Grid>
                        {invites.map((invite, key) => (
                            <Grid key={`invites${key}`} mb={1} sx={{
                                gridGap: [1,1,3],
                                gridTemplateColumns: ['auto', 'auto','1fr 2fr 1fr 1fr 1fr'],
                                width: '100%',
                                paddingBottom: [3,3,0],
                                borderBottom: ['1px solid #eaeaea', '1px solid #eaeaea', 0]
                            }}>
                                <Box>{invite?.first_name} {invite?.last_name}</Box>
                                <Box>{invite?.email}</Box>
                                <Box>
                                    <Text sx={{ fontWeight: 'bold', display: ['inline', 'inline', 'none'] }}>{t('Invited On')}</Text> {moment(invite?.invited_at).format(DATE_FORMAT.date_format_friendly)}
                                    {invite?.last_resend_at ? (
                                        <Text as="p" sx={{
                                            fontSize: 0
                                        }}>
                                            Last resent: {moment(invite?.last_resend_at).format(DATE_FORMAT.date_format_friendly)}
                                        </Text>
                                    ) : null}
                                </Box>
                                <Box><Text sx={{ fontWeight: 'bold', display: ['inline', 'inline', 'none'] }}>{t('Expiry')}</Text> {invite?.expiry_at && moment(invite?.expiry_at).format(DATE_FORMAT.date_format_friendly)}</Box>
                                <Box>
                                    {invite?.can_resend ? (
                                        <>
                                            <Link sx={{
                                                pointerEvents: working ? 'none' : 'all',
                                                color: working ? 'dark' : 'primary'
                                            }} onClick={(e) => handleResendInvite(e, invite.uuid)}>
                                                <Icon icon="mail" size="12" color="dark" /> {t('Resend invite')}
                                            </Link>
                                        </>
                                    ) : (
                                        <Text as="span" color="dark">{t('Max retries reached')}</Text>
                                    )}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ) : null}
        </>

    );
};
