import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getWatchlist,
    getWatchlistStats,
    getWatchlistDeals,
    getWatchlistCompanies,
    getWatchlistClosedDeals,
    storeCategories,
    storeFollows,
    storeStages
} from './watchlistAPI';

const initialState = {
    watchlist: {
        categories: [],
        follows: [],
        stages: [],
    },
    deals: {
        status: 'idle',
        live: {
            results: [],
            meta: []
        },
        closed: {
            results: [],
            meta: []
        }
    },
    companies: {
        status: 'idle',
        results: [],
        meta: []
    },
    stats: {
        status: 'idle',
        counts: {
            watchlist_deals_in_portfolio: 0
        }
    },
    status: 'idle',
};

export const getWatchlistAsync = createAsyncThunk(
    'watchlist/getWatchlist',
    async (payload) => {
        const response = payload === undefined ? await getWatchlist() : payload;
        return response;
    }
);

export const getWatchlistStatsAsync = createAsyncThunk(
    'watchlist/getWatchlistStats',
    async (payload) => {
        const response = payload === undefined ? await getWatchlistStats() : payload;
        return response;
    }
);

export const storeFollowsAsync = createAsyncThunk(
    'watchlist/storeFollows',
    async ({ method, follows }) => {
        const response = await storeFollows({ [method]: follows });
        return response;
    }
);

export const storeCategoriesAsync = createAsyncThunk(
    'watchlist/storeCategories',
    async ({ method = 'update', categories }) => {
        const response = await storeCategories(
            method === 'update'
                ? { categories }
                : {[method]: categories }
        );
        return response;
    }
);

export const storeStagesAsync = createAsyncThunk(
    'watchlist/storeStages',
    async ({ method = 'update', stages }) => {
        const response = await storeStages(
            method === 'update'
                ? { stages }
                : { [method]: stages }
            );
        return response;
    }
);

export const getWatchlistCompaniesAsync = createAsyncThunk(
    'watchlist/getWatchlistCompanies',
    async (payload) => {
        const response = payload === undefined ? await getWatchlistCompanies() : payload;
        return response;
    }
);

export const getWatchlistDealsAsync = createAsyncThunk(
    'watchlist/getWatchlistDeals',
    async (payload) => {
        const response = payload === undefined ? await getWatchlistDeals() : payload;
        return response;
    }
);

export const getWatchlistClosedDealsAsync = createAsyncThunk(
    'watchlist/getWatchlistClosedDeals',
    async (payload) => {
        const response = payload === undefined ? await getWatchlistClosedDeals() : payload;
        return response;
    }
);

const watchlist = createSlice({
    name: 'watchlist',
    initialState,
    reducers: {
        setWatchlistStatus: (state, { payload }) => {
            state.status = payload;
        },
    },
    extraReducers: builder => builder
        .addCase(getWatchlistAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getWatchlistAsync.rejected, (state) => {
            state.watchlist = {...initialState.watchlist};
            state.status = 'attempted';
        })
        .addCase(getWatchlistAsync.fulfilled, (state, {payload}) => {
            state.watchlist = payload?.data?.watchlist;
            state.status = 'attempted';
        })
        .addCase(storeFollowsAsync.pending, (state) => {
            state.status = 'saving';
        })
        .addCase(storeFollowsAsync.rejected, (state) => {
            state.watchlist = {...initialState.watchlist};
            state.status = 'attempted';
        })
        .addCase(storeFollowsAsync.fulfilled, (state, {payload}) => {
            state.watchlist = payload?.data?.watchlist;
            state.status = payload?.data?.status ?? 'attempted';
            state.deals.status = 'idle';
            state.companies.status = 'idle';
        })
        .addCase(storeCategoriesAsync.pending, (state) => {
            state.status = 'saving';
        })
        .addCase(storeCategoriesAsync.rejected, (state) => {
            state.watchlist = {...initialState.watchlist};
            state.status = 'attempted';
        })
        .addCase(storeCategoriesAsync.fulfilled, (state, {payload}) => {
            state.watchlist = payload?.data.watchlist;
            state.status = payload?.data?.status ?? 'attempted';
        })
        .addCase(storeStagesAsync.pending, (state) => {
            state.status = 'saving';
        })
        .addCase(storeStagesAsync.rejected, (state) => {
            state.watchlist = {...initialState.watchlist};
            state.status = 'attempted';
        })
        .addCase(storeStagesAsync.fulfilled, (state, {payload}) => {
            state.watchlist = payload?.data.watchlist;
            state.status = payload?.data?.status ?? 'attempted';
        })
        .addCase(getWatchlistCompaniesAsync.pending, (state) => {
            state.companies.status = 'loading';
        })
        .addCase(getWatchlistCompaniesAsync.rejected, (state) => {
            state.companies.results = {...initialState.companies.results};
            state.companies.status = 'attempted';
        })
        .addCase(getWatchlistCompaniesAsync.fulfilled, (state, {payload}) => {
            state.companies.results = payload?.data ?? [];
            state.companies.meta = payload?.meta ?? [];
            state.companies.status = 'attempted';
        })
        .addCase(getWatchlistDealsAsync.pending, (state) => {
            state.deals.status = 'loading';
        })
        .addCase(getWatchlistDealsAsync.rejected, (state) => {
            state.deals.live = {...initialState.deals.live};
            state.deals.status = 'attempted';
        })
        .addCase(getWatchlistDealsAsync.fulfilled, (state, {payload}) => {
            state.deals.live.results = payload?.data ?? [];
            state.deals.live.meta = payload?.meta ?? [];
            state.deals.status = 'attempted';
        })
        .addCase(getWatchlistClosedDealsAsync.pending, (state) => {
            state.deals.status = 'loading';
        })
        .addCase(getWatchlistClosedDealsAsync.rejected, (state) => {
            state.deals.closed = {...initialState.deals.closed};
            state.deals.status = 'attempted';
        })
        .addCase(getWatchlistClosedDealsAsync.fulfilled, (state, {payload}) => {
            state.deals.closed.results = payload?.data ?? [];
            state.deals.closed.meta = payload?.meta ?? [];
            state.deals.status = 'attempted';
        })
        .addCase(getWatchlistStatsAsync.pending, (state) => {
            state.stats.status = 'loading';
        })
        .addCase(getWatchlistStatsAsync.rejected, (state) => {
            state.stats.counts = {...state.stats.counts};
            state.stats.status = 'attempted';
        })
        .addCase(getWatchlistStatsAsync.fulfilled, (state, {payload}) => {
            state.stats.counts = payload?.data ?? [];
            state.stats.status = 'attempted';
        })
});

export const { setWatchlistStatus } = watchlist.actions;

export {
    getWatchlistDeals,
    getWatchlistStats,
    getWatchlistClosedDeals,
    storeCategories,
    storeFollows,
    storeStages
};
export default watchlist.reducer;
