import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useField } from 'formik';
import { Box } from '@theme-ui/components';
import { Icon } from 'assets/Icon';
import { Label } from '~/Forms';
import { useDebounce } from 'hooks/useDebounce';
import { theme } from 'app/theme';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { extractStreetTypeFromName } from 'utils/helpers';

const getCustomStyles = (isFocused) => ({
    control: (base, state) => ({
        ...base,
        // none of react-select's styles are passed to <Control />
        padding: '8px 6px',
        border: isFocused ? `1px solid ${theme.colors.tealDark}` : `1px solid ${theme.colors.medium}`,
        transition: 'border-color 0.3s ease-in-out',
        outline: 'none',
        borderRadius: theme.forms.input.borderRadius,
        backgroundColor: theme.forms.input.backgroundColor,
        fontSize: theme.forms.input.fontSize,
        fontWeight: 'bold',
        color: theme.colors.darker,
        fontFamily: theme.fonts.body,
        '&:hover': {
            border: `1px solid ${theme.colors.tealDark}`
        },
        boxShadow: 'none',
        marginBottom: 0
    }),
    dropdownIndicator: (base, state) => ({
        display: 'none'
    }),
    indicatorSeparator: (base, state) => ({
        display: 'none'
    }),
    menu: (base, state) => ({
        ...base,
        zIndex: 110,
        borderRadius: '8px',
        padding: '10px'
    }),
    option: (base, state) => ({
        ...base,
        color: theme.colors.darker,
        backgroundColor: state.isFocused ? theme.colors.secondaryLight : (state.isSelected ? theme.colors.secondaryLight : 'white'),
        borderRadius: '8px',
        '&:active': {
            backgroundColor: theme.colors.secondaryLight
        }
    }),
    singleValue: (base, state) => ({
        ...base,
        fontSize: theme.forms.input.fontSize,
        fontWeight: isFocused ? 700 : 400,
        color: theme.colors.darker
    })
})

export const GooglePlacesField = React.forwardRef(
    (
        {
            label,
            showLabel = true,
            autocompletionRequest = {
                componentRestrictions: {
                    country: ['au'],
                    strictBounds: false
                }
            },
            fieldNames = {
                street_number: 'street_number',
                street_name: 'street_name',
                street_type: 'street_type',
                suburb: 'suburb',
                state: 'state',
                postcode: 'postcode',
                country: 'country'
            },
            fieldNamePrefix = '',
            apiOptions,
            name: _name,
            apiName,
            setFieldValue,
            defaultValue,
            validIndicator,
            validationError,
            disabled,
            required = true,
            placeholder,
            onChange,
            onChangeOverride,
            onStateChange,
            setShowAddress,
            ...props
        },
        ref
    ) => {
        const name = `${_name}.address`
        const [focused, setFocused] = useState(false);
        const [isHovered, setHovered] = useState(false);
        const [field, meta, { setValue }] = useField(name);
        const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);
        const fieldFocusedOrFilled = focused || (meta.value !== undefined && meta.value !== '');
        const fieldProps = { ...field, ...props };

        const isInvalid = (required && meta.value === '') || meta.error === undefined;
        const getFieldName = (field_name) => `${fieldNamePrefix}${fieldNames[field_name]}`;

        return (
            <Box
                sx={{ position: 'relative' }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <Label
                    label={label}
                    showLabel={showLabel}
                    field={field}
                    fieldFocusedOrFilled={fieldFocusedOrFilled}
                    focused={focused || isHovered}
                    meta={meta}
                    validationError={validationError}
                />

                <GooglePlacesAutocomplete
                    {...fieldProps}
                    ref={ref}
                    apiOptions={apiOptions}
                    autocompletionRequest={autocompletionRequest}
                    debounce={750}
                    selectProps={{
                        id: name,
                        name: name,
                        placeholder: '',
                        disabled: disabled,
                        styles: getCustomStyles(focused),
                        onFocus: (event) => {
                            event.target.setAttribute('autocomplete', 'off'); // try force autocomplete off
                            setFocused(true)
                        },
                        onBlur: () => setFocused(false),
                        onChange: (event) => {
                            const { label } = event;

                            if (onChangeOverride) {
                                onChangeOverride(event);
                            }else{

                                let postcode = '';
                                let state = '';
                                let street_name = '';

                                if(event?.value?.place_id){
                                    geocodeByPlaceId(event?.value?.place_id)
                                        .then(results => {
                                            for(const component of results[0].address_components){
                                                const componentType = component.types[0];

                                                switch (componentType) {
                                                    case "street_number":
                                                        setFieldValue(getFieldName('street_number'), component.long_name);
                                                        break;

                                                    case "route":
                                                        street_name = component.long_name;
                                                        break;

                                                    case "postal_code":
                                                        postcode = `${component.long_name}${postcode}`;
                                                        break;

                                                    case "postal_code_suffix":
                                                        postcode = `${postcode}-${component.long_name}`;
                                                        break;

                                                    case "locality":
                                                        setFieldValue(getFieldName('suburb'), component.long_name);
                                                    break;

                                                    case "administrative_area_level_1":
                                                        state = component.short_name;
                                                        break;

                                                    case "country":
                                                        setFieldValue(getFieldName('country'), component.short_name);
                                                    break;

                                                    default: break;
                                                }
                                            }
                                            extractStreetTypeFromName(street_name).then((result) => {
                                                setFieldValue(getFieldName('street_name'), result?.street_name);
                                                setFieldValue(getFieldName('street_type'), result?.street_type);
                                            });
                                            setFieldValue(getFieldName('postcode'), postcode);
                                            setFieldValue(getFieldName('state'), state);

                                            if ('function' === typeof onStateChange) {
                                                onStateChange(state);
                                            }

                                            if(setShowAddress !== undefined){
                                                setShowAddress(true);
                                            }
                                        })
                                        .catch(error => console.error(error));
                                }
                                setValue(label);
                                onChange &&
                                    debouncedSave({
                                        name: apiName,
                                        value: label === null ? '' : label,
                                    })
                            }
                        }
                    }}
                />
                {validIndicator === true && isInvalid && (
                    <Icon
                        icon="cross"
                        color="error"
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 80
                        }}
                    />
                )}
            </Box>
        );
    }
);
