import { Text, Box, Grid, Themed, Flex } from "theme-ui";
import { Field } from "..";
import axios from "axios";
import { utilStateByCountry, utilTimezoneByCountry } from "features/theme/themeAPI";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const defaultFields = {
    address: 'address',
    unit_number: 'unit_number',
    street_number: 'street_number',
    street_name: 'street_name',
    street_type: 'street_type',
    address2: 'address2',
    suburb: 'suburb',
    state: 'state',
    postcode: 'postcode',
    timezone: 'timezone',
};

const AddressFields = ({ setFieldValue, fields=defaultFields, ...props }) => {
    const [showAddress, setShowAddress] = useState(false);
    const [stateOptions, setStateOptions] = useState(null);
    const [timezoneOptions, setTimezoneOptions] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        axios.all([
            utilStateByCountry('AU'),
            utilTimezoneByCountry('AU')
        ]).then(axios.spread((stateResponse, timezoneResponse) => {
            setStateOptions(stateResponse.data);
            setTimezoneOptions(timezoneResponse.data);
        })).catch(() => {
            setStateOptions(null);
            setTimezoneOptions([]);
            if (props.context) {
                props.context.setLoading(false);
            }
        });
    }, [props.context]);

    const getStateTimezone = state => {
        let filtered = timezoneOptions.filter(item => item.label.includes(`(${state})`));
        return filtered.length ? filtered[0].value : null;
    }

    return (
        <>
            <Field
                field="google_places"
                label="Search for address..."
                name={fields.address}
                setFieldValue={setFieldValue}
                setShowAddress={setShowAddress}
                onStateChange={state => setFieldValue(fields.timezone, getStateTimezone(state))}
            />

            <Text as="p" variant="small" mb={4} sx={{
                fontSize: '13px',
                color: 'dark',
                '& a': {
                    color: 'tealDark'
                }
            }}>
                {t('Please note your residential address must reside within Australia.')} {!showAddress && <Themed.a onClick={() => setShowAddress(true)} id="showEntityAddress">Address missing? Enter manually</Themed.a>}
            </Text>

            <Box sx={{
                opacity: showAddress ? 1 : 0,
                height: showAddress ? 'auto' : 0,
                minHeight: showAddress ? '300px' : 0,
                transition: 'min-height 0.3s ease-in-out, opacity 0.3s ease-in-out'
            }}>
                <Grid sx={{
                    gridGap: [0,2],
                    gridTemplateColumns: ['1fr', '1fr 2fr'],
                    width: '100%'
                }}>
                    <Field
                        label="Unit Number"
                        name={fields.unit_number}
                    />
                    <Field
                        label="Street Number"
                        name={fields.street_number}
                    />
                </Grid>

                <Grid sx={{
                    gridGap: [0,2],
                    gridTemplateColumns: ['1fr', '2fr 1fr'],
                    width: '100%'
                }}>
                    <Field
                        label="Street Name"
                        name={fields.street_name}
                    />
                    <Field
                        label="Street Type"
                        name={fields.street_type}
                    />
                </Grid>

                <Field
                    label="Address second line"
                    name={fields.address2}
                />
                <Field
                    label="Suburb"
                    name={fields.suburb}
                />

                <Flex sx={{ flexDirection: ['column', 'column', 'row'] }}>
                    <Box sx={{ width: ['100%', '100%', '50%'] }} pr={[0, 0, '5px']}>
                        <Field
                            field={stateOptions ? 'select' : 'text'}
                            label="State"
                            name={fields.state}
                            options={stateOptions}
                        />
                    </Box>

                    <Box sx={{ width: ['100%', '100%', '50%'] }}>
                        <Field
                            label="Postcode"
                            name={fields.postcode}
                        />
                    </Box>
                </Flex>
            </Box>
        </>

    )
}

export default AddressFields;