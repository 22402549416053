import React, { useState, forwardRef } from 'react';
import { useField } from 'formik';
import { Box } from 'theme-ui';
import { Label } from '~/Forms/Label';
import { useDebounce } from 'hooks/useDebounce';
import { SelectStyled } from './styled';
import { Icon } from 'assets/Icon';

export const SelectField = forwardRef(
    (
        {
            label = null,
            showLabel,
            name,
            apiName,
            value,
            onChange,
            errors = null,
            placeholder,
            options,
            disabled,
            required = false,
            validIndicator,
            validationError,
            onChangeOverride,
            useValueOnBlur = false,
            sx,
            nullable = false,
            ...props
        },
        ref
    ) => {
        const [field, meta, { setValue }] = useField(name);
        const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);
        const [isHovered, setHovered] = useState(false);
        const [focused, setFocused] = useState(false);
        const fieldFocusedOrFilled = true;

        const isInvalid = (required && meta.value === '') || meta.error !== undefined;

        const fieldProps = { ...field, ...props };

        return (
            <Box sx={{ position: 'relative' }}>
                <Label
                    label={label}
                    showLabel={showLabel}
                    fieldFocusedOrFilled={fieldFocusedOrFilled}
                    focused={focused || isHovered}
                    field={field}
                    placeholder={placeholder}
                    meta={meta}
                    validationError={validationError}
                />
                <SelectStyled
                    {...fieldProps}
                    {...props}
                    id={name}
                    name={name}
                    invalid={meta.touched && meta.error}
                    className={field.value === "" ? 'is-empty' : ''}
                    onChange={(event) => {
                        const { value } = event.target;
                        if (onChangeOverride) {
                            onChangeOverride(event);
                        } else {
                            setValue(value);

                            onChange &&
                                debouncedSave({
                                    name: apiName,
                                    value: value === null ? '' : value,
                                });
                        }
                    }}
                    arrow={<Icon
                        color={isHovered || focused ? 'tealDark' : "dark"}
                        icon="dropdown"
                        size="14px"
                        sx={{
                            position: 'absolute',
                            right: '16px',
                            top: '50%',
                            marginTop: '-7px',
                            pointerEvents: 'none'
                        }}
                    />}
                    sx={{
                        borderRadius: '2px',
                        padding: '13px 14px',
                        bg: 'white',
                        ...sx
                    }}
                    px={15}
                    py={10}
                    ref={ref}
                    disabled={disabled}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    <option value='' disabled={!nullable}>
                        {' '}
                        {placeholder || `-- Select ${name || 'an option'} --`}{' '}
                    </option>
                    {options.map(({ value, label }, index) => (
                        <option key={`${index}-${value}`} value={value}>
                            {useValueOnBlur && !focused ? value : label}
                        </option>
                    ))}
                </SelectStyled>

                {validIndicator === true && isInvalid && (
                    <Icon
                        icon="cross"
                        color="error"
                        sx={{
                            position: 'absolute',
                            right: '40px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 80
                        }}
                    />
                )}
            </Box>
        )
    }
);

SelectField.defaultProps = {
    validationError: true,
    placeholder: '',
    showLabel: true,
    controlledInput: true,
    type: 'text',
    validIndicator: true,
    required: false,
    disabled: false
};
