import { forwardRef } from 'react';
import { useField } from 'formik';
import { Icon } from 'assets/Icon';
import { useDebounce } from 'hooks/useDebounce';
import { Box, Flex, Switch, Checkbox, Label, Text } from 'theme-ui'
import { Error } from '~/Forms/Error';

export const CheckboxField = forwardRef(
    (
        {
            label,
            name,
            apiName,
            type = 'switch',
            className,
            disabled,
            validIndicator,
            validationError,
            showLabel,
            onChangeOverride,
            onChange,
            validation,
            variant,
            sx,
            pb = 2,
            ...props
        },
        ref
    ) => {
        const [field, meta, { setValue, setTouched }] = useField(name);
        const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);
        const fieldProps = { ...field, ...props };
        const showIcon = validIndicator === true && meta.value !== false && meta.error === undefined;

        return (
            <Box pb={pb} sx={sx}>
                <Flex sx={{ position: 'relative', alignItems: 'center' }} id={`checkbox_${name}`}>
                    {type === 'checkbox' ? (
                        <Label sx={{ textAlign: 'left' }}>
                            <Checkbox
                                {...fieldProps}
                                {...props}
                                name={name}
                                id={name}
                                disabled={disabled}
                                variant={variant}
                                ref={ref}
                                invalid={meta.touched && meta.error}
                                checked={meta.value}
                                sx={{ '~ span': { pr: validIndicator ? 25 : 0, textAlign: 'left' } }}
                                onChange={(event) => {
                                    const value = event.target.checked;

                                    if (onChangeOverride) {
                                        onChangeOverride(event);
                                    } else {
                                        setValue(value);

                                        onChange &&
                                            debouncedSave({
                                                name: apiName,
                                                value: value === null ? '' : value,
                                            });
                                    }
                                }}
                                onBlur={() => {
                                    setTouched(field.name, true);
                                }}
                            />

                            <Text as="span">
                                {label}
                            </Text>
                        </Label>
                    ) : (
                        <Switch
                            {...fieldProps}
                            {...props}
                            label={label}
                            name={name}
                            id={name}
                            disabled={disabled}
                            variant={variant}
                            ref={ref}
                            invalid={meta.touched && meta.error}
                            checked={meta.value}
                            sx={{ '~ span': { pr: validIndicator ? 25 : 0, textAlign: 'left' } }}
                            onChange={(event) => {
                                const value = event.target.checked;

                                if (onChangeOverride) {
                                    onChangeOverride(event);
                                } else {
                                    setValue(value);

                                    onChange &&
                                        debouncedSave({
                                            name: apiName,
                                            value: value === null ? '' : value,
                                        });
                                }
                            }}
                            onBlur={() => {
                                setTouched(field.name, true);
                            }}
                        />
                    )}
                    {validationError && meta.touched && (
                        <Error
                            text={meta?.error}
                            isVisible={meta?.error}
                            sx={{
                                position: 'absolute',
                                left: 0,
                                top: '95%',
                                zIndex: 80
                            }}
                        />
                    )}

                    {showIcon && (
                        <Icon
                            icon="tick-circle-white"
                            color="tealLight"
                            sx={{
                                position: 'absolute',
                                right: '-0',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                zIndex: 80
                            }}
                        />
                    )}
                </Flex>
            </Box>
        );
    }
);

CheckboxField.defaultProps = {
    validationError: true,
    placeholder: '',
    showLabel: true,
    validIndicator: true,
    required: false,
    disabled: false
};
