import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DealStatus } from 'app/constants';

import {
    dealPlaceBid,
    getDeals,
    getDealView,
    getDealNews,
    getDealStats,
    getDealSecurityPerformance,
    getDealAcceptance,
    signInvestment,
    sendVerification,
    unsophisticatedSendVerification,
    verify,
    getOrganisationDeals,
    getEoiDealAcceptance,
    signEoiInvestment
} from './dealAPI'

export const getDealsAsync = createAsyncThunk(
    'deals/getDeals',
    async (payload) => {
        const response = payload === undefined ? await getDeals() : payload;
        return response;
    }
);

export const getFeaturedDealsAsync = createAsyncThunk(
    'deals/getFeaturedDeals',
    async (payload) => {
        const response = payload === undefined ? await getDeals({
            params: {
                featured: 1,
                limit: 1
            }
        }) : payload;
        return response;
    }
);

export const getClosedDealsAsync = createAsyncThunk(
    'deals/getClosedDeals',
    async (payload) => {
        const response = payload === undefined ? await getDeals({
            params: {
                status: DealStatus.closed,
                limit: 12
            }
        }) : payload;
        return response;
    }
);

export const getLaunchedDealsAsync = createAsyncThunk(
    'deals/getLaunchedDeals',
    async (payload) => {
        const response = payload === undefined ? await getDeals({
            params: {
                status: DealStatus.fulfilled,
                limit: 2
            }
        }) : payload;
        return response;
    }
);

export const getOrganisationDealsAsync = createAsyncThunk(
    'deals/getOrganisationDeals',
    async (payload) => {
        const response = payload === undefined ? await getOrganisationDeals() : payload;
        return response;
    }
);

export const getOrganisationClosedDealsAsync = createAsyncThunk(
    'deals/getOrganisationClosedDeals',
    async (payload) => {
        const response = payload === undefined ? await getOrganisationDeals({
            params: {
                status: DealStatus.closed
            }
        }) : payload;
        return response;
    }
);

export const getCurrentDealAsync = createAsyncThunk(
    'deals/currentDeal',
    async (slug) => {
        try{
            const response = await getDealView(slug);
            return response;
        }catch(e){
            // trigger event for livewire
            var notFound = new CustomEvent('notFound', { detail: { method: 'deals/currentDeal', slug: slug } });
            window.dispatchEvent(notFound);
            throw new Error(e);
        }
    }
);

export const getCurrentDealNewsAsync = createAsyncThunk(
    'deals/currentDealNews',
    async ({slug, page}) => {
        const response = await getDealNews(slug, { params: { page } });
        return response;
    }
);

export const getDealStatsAsync = createAsyncThunk(
    'deals/getDealStats',
    async (payload) => {
        const response = payload === undefined ? await getDealStats() : payload;
        return response;
    }
);

const initialState = {
    deals: {
        featured: {
            status: 'idle',
            results: [],
        },
        live: {
            status: 'idle',
            results: [],
            meta: []
        },
        closed: {
            status: 'idle',
            results: [],
        },
        launched: {
            status: 'idle',
            results: [],
        },
        organisation: {
            status: 'idle',
            results: [],
            meta: []
        },
        organisation_closed: {
            status: 'idle',
            results: [],
            meta: []
        },
    },
    stats: {
        status: 'idle',
        counts: {
            live_deals: 0,
            bids_last_7_days: 0,
            deals_closed_last_3_days: 0
        }
    },
    currentFeaturedDeal: null,
    currentDealChart: null,
    currentDeal: null,
    currentDealNews: [],
    currentDealStatus: 'idle',
    currentDealNewsMeta: null,
};


const deals = createSlice({
    name: 'deals',
    initialState,
    reducers: {
        setCurrentDealChart: (state, action) => {
            state.currentDealChart = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDealsAsync.pending, (state, {payload}) => {
                    state.deals.live.status = 'loading';
                })
                .addCase(getDealsAsync.rejected, (state) => {
                    state.deals.live.results = [];
                    state.deals.live.meta = [];
                    state.deals.live.status = 'attempted';
                })
                .addCase(getDealsAsync.fulfilled, (state, {payload}) => {
                    state.deals.live.results = payload?.data ?? [];
                    state.deals.live.meta = payload?.meta ?? [];
                    state.deals.live.status = 'attempted';
                })
                .addCase(getClosedDealsAsync.pending, (state, {payload}) => {
                    state.deals.closed.status = 'loading';
                })
                .addCase(getClosedDealsAsync.rejected, (state) => {
                    state.deals.closed.results = [];
                    state.deals.closed.status = 'attempted';
                })
                .addCase(getClosedDealsAsync.fulfilled, (state, {payload}) => {
                    state.deals.closed.results = payload?.data ?? [];
                    state.deals.closed.status = 'attempted';
                })
                .addCase(getFeaturedDealsAsync.pending, (state, {payload}) => {
                    state.deals.featured.status = 'loading';
                })
                .addCase(getFeaturedDealsAsync.rejected, (state) => {
                    state.deals.featured.results = [];
                    state.deals.featured.status = 'attempted';
                    state.currentFeaturedDeal = null;
                })
                .addCase(getFeaturedDealsAsync.fulfilled, (state, {payload}) => {
                    state.deals.featured.results = payload?.data ?? [];
                    state.deals.featured.status = 'attempted';
                    state.currentFeaturedDeal = (payload?.data ?? []).map(d => d.uuid)?.[0];
                })
                .addCase(getLaunchedDealsAsync.pending, (state, {payload}) => {
                    state.deals.launched.status = 'loading';
                })
                .addCase(getLaunchedDealsAsync.rejected, (state) => {
                    state.deals.launched.results = [];
                    state.deals.launched.status = 'attempted';
                })
                .addCase(getLaunchedDealsAsync.fulfilled, (state, {payload}) => {
                    state.deals.launched.results = payload?.data ?? [];
                    state.deals.launched.status = 'attempted';
                })
                .addCase(getOrganisationDealsAsync.pending, (state, { payload }) => {
                    state.deals.organisation.status = 'loading';
                })
                .addCase(getOrganisationDealsAsync.rejected, (state) => {
                    state.deals.organisation.results = [];
                    state.deals.organisation.meta = [];
                    state.deals.organisation.status = 'attempted';
                    })
                .addCase(getOrganisationDealsAsync.fulfilled, (state, { payload }) => {
                    state.deals.organisation.results = payload?.data ?? [];
                    state.deals.organisation.meta = payload?.meta ?? [];
                    state.deals.organisation.status = 'attempted';
                })
                .addCase(getOrganisationClosedDealsAsync.pending, (state, { payload }) => {
                    state.deals.organisation_closed.status = 'loading';
                })
                .addCase(getOrganisationClosedDealsAsync.rejected, (state) => {
                    state.deals.organisation_closed.results = [];
                    state.deals.organisation_closed.meta = [];
                    state.deals.organisation_closed.status = 'attempted';
                })
                .addCase(getOrganisationClosedDealsAsync.fulfilled, (state, { payload }) => {
                    state.deals.organisation_closed.results = payload?.data ?? [];
                    state.deals.organisation_closed.meta = payload?.meta ?? [];
                    state.deals.organisation_closed.status = 'attempted';
                })
                .addCase(getCurrentDealAsync.pending, (state, {payload}) => {
                    state.currentDealStatus = 'loading';
                })
                .addCase(getCurrentDealAsync.rejected, (state, {payload}) => {
                    state.currentDeal = null;
                    state.currentDealStatus = 'attempted';
                })
                .addCase(getCurrentDealAsync.fulfilled, (state, {payload}) => {
                    state.currentDeal = payload?.data;
                    state.currentDealStatus = 'attempted';
                })
                .addCase(getCurrentDealNewsAsync.rejected, (state, {payload}) => {
                    state.currentDealNews = [];
                    state.currentDealNewsMeta = null;
                })
                .addCase(getCurrentDealNewsAsync.fulfilled, (state, {payload}) => {
                    state.currentDealNews = payload?.data;
                    state.currentDealNewsMeta = payload?.meta;
                })
                .addCase(getDealStatsAsync.pending, (state, {payload}) => {
                    state.stats.status = 'loading';
                })
                .addCase(getDealStatsAsync.rejected, (state, {payload}) => {
                    state.stats.counts = {...initialState.stats.counts};
                    state.stats.status = 'attempted';
                })
                .addCase(getDealStatsAsync.fulfilled, (state, {payload}) => {
                    state.stats.counts = payload?.data;
                    state.stats.status = 'attempted';
                });
    },
});

export {
    dealPlaceBid,
    getDeals,
    getDealView,
    getDealNews,
    getDealSecurityPerformance,
    getDealAcceptance,
    signInvestment,
    sendVerification,
    unsophisticatedSendVerification,
    verify,
    getOrganisationDeals,
    getEoiDealAcceptance,
    signEoiInvestment
}
export const { setCurrentDealChart } = deals.actions;
export default deals.reducer;
