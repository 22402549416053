import React from 'react';
import * as Yup from 'yup';
import {
    Alert,
    Box,
    Close,
    Heading,
    Label,
    Link,
    Spinner
} from 'theme-ui';
import { Form, Formik } from 'formik';
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { Container } from '~/Common';
import { Layout } from '~/Layouts';
import { loginTwoFactorChallengeUser, getUserAsync } from 'features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useResponsiveValue } from '@theme-ui/match-media';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const validationSchema = Yup.object().shape({
    mode: Yup.string(),
    code: Yup.string().when('mode', {
        is: (mode) => mode === 'code',
        then: Yup.string().required('please enter a code').test('len', 'must be exactly 6 characters', val => val !== undefined && val.length === 6)
    }),
    recovery_code: Yup.string().when('mode', {
        is: (mode) => mode === 'recovery_code',
        then: Yup.string().required('recovery code is a required field')
    })
});

const LoginTwoFactorChallenge = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    useDocumentTitle(t('Login 2FA'));
    const isMobile = useResponsiveValue([true, true, false]);

    const handleSubmit = (values, actions) => {
        loginTwoFactorChallengeUser({
            code: values.code,
            recovery_code: values.recovery_code,
            device_name: 'App'
        })
        .then(() => {
            const returnTo = localStorage.getItem('returnTo') ?? '/portfolio';

            localStorage.removeItem('authTokenScope');
            dispatch(getUserAsync());

            localStorage.removeItem('returnTo');
            history.push(returnTo);
        })
        .catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            actions.resetForm();
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    };

    return (
        <Layout>
            <Container>
                <Formik
                    initialValues={{
                        code: '',
                        recovery_code: '',
                        mode: 'code'
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ status, setStatus, setFieldValue, values, validateField, submitForm, isSubmitting }) => (
                        <Box pt={5} sx={{
                            borderTop: isMobile ? '1px solid' : null,
                            borderColor: isMobile ? 'light' : null,
                        }}>
                            { status === 'api_error' ?
                            <Alert role="alert" variant='danger' mb={4}>
                                {t('login.two_factor_error')}
                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                            </Alert> : ''}

                            <Form>
                                <Heading as="h1" variant="publicH1" mb={4}>
                                    {t('login.two_factor_title', { site: process.env.REACT_APP_NAME })}
                                </Heading>

                                {isSubmitting ? (
                                    <Box my={2}>
                                        <Spinner variant="styles.spinner" />
                                    </Box>
                                ) : (
                                    <>
                                    {values.mode === 'code' ? (
                                        <>
                                            <Label mb={3}>
                                                {t('Please enter the 6-digit security code provided in your authenticator app:')}
                                            </Label>

                                            <Field
                                                field="verify"
                                                label={t('Please supply your code')}
                                                showLabel={false}
                                                name="code"
                                                validateField={validateField}
                                                onChange={(field) => {
                                                    if (field.value.length >= 6) {
                                                        submitForm();
                                                    }
                                                }}
                                            />
                                            <Link as="a" href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setFieldValue('mode', 'recovery_code')
                                            }}>
                                                {t('Supply a recovery code instead')}
                                            </Link>
                                        </>
                                    ) : (
                                        <>
                                            <Label mb={4}>
                                                {t('Please enter your recovery code below:')}
                                            </Label>
                                            <Field
                                                label={t('Recovery code')}
                                                name="recovery_code"
                                            />
                                            <Submit variant="primary" text={t('buttons.submit')} />
                                            <Link as="a" href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setFieldValue('mode', 'code')
                                            }} ml={2}>
                                                {t('Supply a code instead')}
                                            </Link>
                                        </>
                                    )}
                                    </>
                                )}
                            </Form>
                        </Box>
                    )}
                </Formik>
            </Container>
        </Layout>
    );
};

export default LoginTwoFactorChallenge;
