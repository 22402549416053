import {
    Themed
} from 'theme-ui'
import {
    Button
} from '~/Common'
import {
    Box
} from '@theme-ui/components'
import { onboardToken, setProfileByUUID, setUser } from 'features/auth/authSlice'
import { useTranslation } from 'react-i18next'
import { toast } from 'utils/toast'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

export const SkipIdentity = ({ type = 'text', linkText, sx }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const goToPortfolio = (e) => {
        e.preventDefault();

        onboardToken({
            device_name: 'App'
        }).then((response) => {
            dispatch(setUser(response));
            if (response?.data?.profiles?.length > 0) {
                dispatch(setProfileByUUID(response?.data?.profiles[0].uuid));
            }
            history.push('/portfolio');
        }).catch(({ response }) => {
            toast.error(t('Sorry we were unable to see that you have completed the required onboarding steps. Please contact Liquidity for some assistance.'));
        });
    };

    return (
        <>
            {type === 'button' ? (
                <Button type="button" variant="bordered" onClick={goToPortfolio} sx={{ ...sx, minWidth: '150px' }}>
                    {t(linkText ?? 'Skip For Now')}
                </Button>
            ) : (
                <Box mt={4} sx={{
                    color: 'dark',
                    fontSize: '13px',
                    textAlign: 'center',
                    '& a': {
                        color: 'tealDark'
                    },
                    ...sx
                }}>
                    {t('Don\'t have these documents with you?')} <Themed.a onClick={goToPortfolio}>{t(linkText ?? 'Skip for now')}</Themed.a>
                </Box>
            )}
        </>
    );
};
