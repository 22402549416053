import { useState, useEffect, useCallback, useRef } from 'react';
import { Form, Formik } from 'formik';
import { LoadingScreen, VerifyModal } from '~/Common';
import { AcceptanceLayout } from '~/Layouts';
import { Field, Submit } from '~/Forms';
import * as Yup from 'yup';
import { formatCurrency, numberWithCommas } from 'utils/helpers'
import {
    Heading,
    Text,
    Link,
    Flex,
    Box,
    Grid
} from '@theme-ui/components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getEoiDealAcceptance, signEoiInvestment } from 'features/deal/dealSlice'
import { setPortfolioStatus } from 'features/portfolio/portfolioSlice';
import { toast } from 'utils/toast'
import moment from 'moment-timezone';
import { DATE_FORMAT } from 'app/constants';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const validationSchema = Yup.object().shape({
    signature: Yup.string().nullable().required('Please sign within the box'),
    termsAgree: Yup.bool().oneOf([true], 'You must agree to the terms')
});

const EoiDealAcceptance = ({ match }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('EOI Acceptance'));
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const [data, setData] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    const fetchAcceptanceData = useCallback((uuid) => {
        getEoiDealAcceptance(uuid)
            .then((response) => {
                setData(response.data);
                setLoading(false);
            })
            .catch(({ response }) => {
                toast.error(response.data?.errors?.uuid?.[0] ?? t('Error loading investment data'), {
                    toastId: 'deal-acceptance-signature-error',
                });
                setData('error');
                setLoading(false);
                setShowVerifyModal(true);
                setSuccess(true);
            });
    }, [t]);

    useEffect(() => {
        if (!data && !isLoading) {
            setLoading(true);
            fetchAcceptanceData(match.params?.uuid);
        }
    }, [data, fetchAcceptanceData, match.params, isLoading])

    const resubmitForm = () => formRef.current && formRef.current.submitForm();

    const onVerifySuccess = () => {
        resubmitForm();
    }

    const handleSubmit = (values, actions) => {
        if (data?.deal) {
            signEoiInvestment(data.uuid, {
                signature: values.signature,
                uuid: data.uuid,
            })
                .then(() => {
                    setSuccess(true);
                    setShowVerifyModal(true);
                    dispatch(setPortfolioStatus('idle'));
                })
                .catch(({ response }) => {
                    if (response?.status === 403) {
                        setShowVerifyModal(true);
                    } else {
                        toast.error(response.data?.message ?? t('Unable to submit signature'), {
                            toastId: 'deal-acceptance-signature-error',
                        });
                    }
                    setLoading(false);
                })
                .finally(() => actions.setSubmitting(false))
        }
    }


    if (isLoading || !data) {
        return <LoadingScreen />
    }

    const acceptanceDate = moment(data.acceptance_by).format(DATE_FORMAT.date_format_friendly);

    const address = data.address?.street_name ? [
        data.address?.unit_number,
        data.address?.street_number,
        data.address?.street_name,
        data.address?.street_type
    ].filter(i => !!i).join(' ') : (data.address?.address ?? false);

    const location = [
        data.address?.suburb,
        data.address?.state,
        data.address?.postcode
    ].filter(i => !!i).join(' ');

    moment.tz.setDefault(data.timezone);

    return (
        <>
            <VerifyModal
                isOpen={showVerifyModal}
                onSuccess={onVerifySuccess}
                closeModal={() => setShowVerifyModal(false)}
                hideCloseOnSuccess={true}
                successTemplate={{
                    heading: data === 'error' ? t('acceptance.error.heading') : t('acceptance.success.heading'),
                    content: data === 'error' ? t('acceptance.error.content') : t('acceptance.success.content'),
                    button: {
                        label: t('acceptance.success.unsophisticatedButton'),
                        to: 'https://www.liquidity.com.au'
                    }
                }}
                forceSuccess={isSuccess}
                unsophisticated={true}
                userUuid={match.params?.uuid}
            />
            {data !== 'error' && (
                <AcceptanceLayout>
                    <Heading mb={5}>{t('acceptance.heading')}</Heading>
                    <Heading variant="h3" sx={{ fontWeight: 500 }}>{t(`${data.deal.company.name} (or the "Company")`)}</Heading>
                    <Text as="p" variant="small" color="dark" mb={4} sx={{ fontSize: ['14px', '14px'] }}>{t('Deal:')} {data.deal?.code}</Text>

                    <Text as="p">{data.letterhead_name ?? data.name}</Text>
                    {data.designation && <Text as="p">{`<${data.designation} A/C>`}</Text>}
                    {address && <Text as="p">{address}</Text>}
                    {data.address?.address2 && <Text as="p">{data.address?.address2}</Text>}
                    {location && <Text as="p">{location}</Text>}
                    <br />
                    <Text as="p">{t('By Email:')} {data.email}</Text>
                    <br />
                    <Text as="p" mb={4}>
                        {t('Dear')} {data.first_name},<br />
                        <br />
                        {t('acceptance.content')}
                    </Text>

                    <Grid sx={{
                        gridTemplate: Boolean(data.has_attaching_options) ? ['auto', 'auto / repeat(4, 1fr)'] : ['auto', 'auto / repeat(3, 1fr)'],
                        gridGap: '20px',
                        py: [3, '40px'],
                        px: '20px',
                        border: '1px solid #00000A',
                        borderRadius: '16px',
                        mb: 4,
                        textAlign: 'center'
                    }}>
                        <Box>
                            <Text variant="smallLight" sx={{ fontSize: '13px' }}>{t('Offer Shares')}</Text>
                            <Text sx={{ display: 'block', fontSize: '16px' }}>{numberWithCommas(data.expected_shares)}</Text>
                        </Box>
                        <Box>
                            <Text variant="smallLight" sx={{ fontSize: '13px' }}>{t('Subscription Price')}</Text>
                            <Text sx={{ display: 'block', fontSize: '16px' }}>AUD{formatCurrency(data.avg_price, 3)}</Text>
                        </Box>
                        <Box>
                            <Text variant="smallLight" sx={{ fontSize: '13px' }}>{t('Total Amount')}</Text>
                            <Text sx={{ display: 'block', fontSize: '16px' }}>AUD{formatCurrency(data.capital)}</Text>
                        </Box>

                        {Boolean(data.has_attaching_options) && (
                            <Box>
                                <Text variant="smallLight" sx={{ fontSize: '13px' }}>{t('Attaching Options')}</Text>
                                <Text sx={{ display: 'block', fontSize: '16px' }}>{data.attaching_options}</Text>
                            </Box>
                        )}
                    </Grid>

                    <Text as="p" mb={5}>
                        {t('acceptance.urgentAttention', { acceptanceDate })}<br /><br />
                        {t('acceptance.readTermSheet')}<br /><br />
                        {t('acceptance.clickAccept')}
                    </Text>


                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            signature: null,
                            termsAgree: false
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ status, setStatus, setFieldValue, values, validateField, submitForm, isSubmitting }) => (
                            <Form>
                                <Heading variant="h3" mb={4} sx={{ fontWeight: 500 }}>{t('acceptance.drawSignature')}</Heading>
                                <Text size="20px">{t('acceptance.signatureLabel')}</Text>
                                <Field field="signature" name="signature" disabled={isSubmitting} sx={{ mt: 3 }} />
                                <Flex sx={{ mb: 3 }}>
                                    <Field
                                        field="checkbox"
                                        name="termsAgree"
                                        disabled={isSubmitting}
                                        label={t('acceptance.agreeLabel', { companyName: data.deal.company.name })}
                                        validIndicator={false}
                                        pb={0}
                                        groupSx={{ pt: 0 }}
                                        groupMb={0}
                                    />
                                    <Link
                                        href={data.acceptance_document}
                                        target="_blank"
                                        sx={{
                                            fontSize: '14px',
                                            color: 'tealDark',
                                            ml: 1
                                        }}>{t('acceptance.downloadLetter')}</Link>
                                </Flex>
                                <Submit text="Accept Offer" disabled={!values.termsAgree || !values.signature || isSubmitting} sx={{ width: '100%' }} />
                            </Form>
                        )}
                    </Formik>

                    <Text as="p" mt={5}>
                        {t('acceptance.contact')} <Link href="mailto:members@liquidity.com.au">members@liquidity.com.au</Link>.<br /><br />
                        {t('acceptance.thankyou')}
                    </Text>

                </AcceptanceLayout>
            )}
        </>
    );
};

export default EoiDealAcceptance;
