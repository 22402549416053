import React, { useEffect, useRef, useState } from 'react'
import {Box, Text} from 'theme-ui';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { formatCurrency } from "utils/helpers";
import {useResponsiveValue} from "@theme-ui/match-media";
import moment from "moment";
import { Icon } from 'assets/Icon';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return { theme: state.theme }
}

const Carousel = ({ theme }) => {
    const nodeRef = useRef(null);
    const slickRef = useRef(null);
    const [initialized, setInitialized] = useState(false);
    const { t } = useTranslation();
    const isTablet = useResponsiveValue([false, true, false, false, false]);
    const isMobile = useResponsiveValue([true, false]);
    const data = theme.marketData?.data || [];

    let slidesToShow;
    if (isMobile) {
        slidesToShow = data.length > 3 ? 3 : data.length;
    }else if(isTablet) {
        slidesToShow = data.length > 6 ? 6 : data.length;
    } else {
        slidesToShow = data.length > 11 ? 11 : data.length;
    }

    const getGainColor = (value) => {
        return value > 0 ? 'tealDark' : (
            value < 0 ? 'loss' : 'darker'
        );
    };

    const settings = {
        infinite: true,
        speed: 10000,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: Boolean(isMobile || isTablet),
        autoplaySpeed: 1000,
        arrows: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        variableWidth: true,
        cssEase: 'linear',
        initialSlide: 0,
        swipe: false
    };

    // Wait until the slick reference is initialised, then play the auto scroll
    useEffect(() => {
        const interval = setInterval(() => {
            if (!initialized && slickRef?.current !== null) {
                setTimeout(() => slickRef?.current?.slickPlay(), 3000);
                setInitialized(true);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [initialized]);

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={true}
            timeout={500}
            classNames="fade"
            unmountOnExit
            appear
        >
            <Box>
                {data.length > 0 ? (
                    <Box sx={{
                        py: 2,
                        mb: theme.showVerifySteps ? 0 : 2,
                        height: '48px',
                        borderBottom: '1px solid',
                        borderColor: 'light',
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'hidden',
                        '> .slick-slider': {
                            opacity: 0,
                            transition: 'opacity 0.3s ease-in-out'
                        },
                        '> .slick-slider.slick-initialized': {
                            opacity: 1
                        }
                    }}
                    onMouseEnter={() => slickRef?.current.slickPause()}
                    onMouseLeave={() => slickRef?.current.slickPlay()}
                    >
                        <Slider ref={slider => slickRef.current = slider} {...settings}>
                            <Box px={4} sx={{
                                background: 'none',
                                whiteSpace: 'nowrap',
                                height: '24px'
                            }}>
                                <Text as="p" variant="market_data_value">
                                    As market close on {moment().subtract(1, 'days').format('Do MMM Y')}
                                </Text>
                            </Box>
                            {data.map((item, key) => (
                                <Box key={key}>
                                    <Box px={4} sx={{
                                        display: 'flex',
                                        whiteSpace: 'nowrap',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        background: 'none',
                                        height: '24px'
                                    }}
                                    >
                                        <Text as="p" mr={2} variant="market_data">
                                            {t(item.short_name)}
                                        </Text>
                                        <Text as="div" variant="market_data_value" sx={{ display: 'inline-block' }}>
                                            {item.type === 'dollars' ? formatCurrency(item.raw_value) : item.formatted_value}
                                            {item.percent_change !== false && (
                                                <Text
                                                    as="div"
                                                    variant="gain"
                                                    ml={1}
                                                    sx={{ display: 'inline-block', color: getGainColor(item.percent_change) }}
                                                >
                                                    {item.percent_change >= 0 ? <Icon icon="arrow-up" size="10px" mr={'4px'} /> : <Icon icon="arrow-up" size="10px" mr={'4px'} sx={{ transform: 'rotate(180deg)' }} />}
                                                    {Math.abs(item.percent_change)}%
                                                </Text>
                                            )}
                                        </Text>
                                    </Box>
                                </Box>
                            ))}
                        </Slider>
                    </Box>
                ) : null}
            </Box>
        </CSSTransition>
    );
};

export default connect(mapStateToProps, null)(Carousel);
