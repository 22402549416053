import axios from 'axios'

export const fetchInvites = (params) =>
    axios.get('peer-invites', params)
        .then(({ data }) => data)

export const storeInvite = (params) =>
    axios.post('peer-invites', params)
        .then(({ data }) => data)

export const fetchInvitedBy = (params) =>
        axios.get(`user/invites`, params)
            .then(({ data }) => data)

export const respondToInvitedBy = (uuid, params) =>
    axios.post(`user/invite/${uuid}`, params)
        .then(({ data }) => data)
