import { useTranslation } from 'react-i18next';
import { Box, Grid, Heading } from 'theme-ui';
import { ArticleTile } from '~/News/ArticleTile';


export const RelatedArticles = ({ article, deal, ...props }) => {
    const { t } = useTranslation();
    
    if (!Array.isArray(article?.related) || !article.related.length) {
        return null;
    }

    return (
        <Box {...props}>
            <Heading as="h2" variant="h3">{t('news.related.title', { deal: article?.company?.name })}</Heading>
            <Grid sx={{ mt: '40px', gridGap: '32px', gridTemplateColumns: ['1fr', null, null, 'repeat(4, 1fr)'] }}>
                {article.related.map((item, index) => (
                    <ArticleTile key={index} article={item} />
                ))}
            </Grid>
        </Box>
    )
}
