import { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import {
    Box,
    Flex,
    Link as ThemeLink,
    Text,
    Heading,
    Image
} from '@theme-ui/components'
import {
    Circle
} from '~/Common'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Icon } from 'assets/Icon'
import { NotificationMenu as NotificationMenuStyled, NotificationMenuItem, NotificationMenuScroll } from './styled'
import { setShowNotificationMenu, markAllNotificationsAsReadAsync, getNotificationsAsync } from 'features/notifications/notificationsSlice'
import { useMenuClose } from 'hooks/useMenuClose'
import { useInterval } from 'hooks/useInterval'
import { POLLING } from 'app/constants'
import { getUserAsync } from 'features/auth/authSlice'
import { isBroadcastingEnabled } from 'utils/helpers'
import { getInvitedByAsync, getInvitesAsync } from 'features/invites/invitesSlice'

export const NotificationMenu = ({ buttonRef, inviteRef, ...props }) => {
    const notifications = useSelector((state) => state.notifications);
    const invited_by = useSelector((state) => state.invites?.invited_by);
    const user = useSelector((state) => state.user?.user);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const menuRef = useRef(null);
    const unreadNotifications = notifications.notifications.filter(n => !n.read_at);
    const readNotifications = notifications.notifications.filter(n => !!n.read_at);

    const closeMenu = () => {
        dispatch(markAllNotificationsAsReadAsync());
        dispatch(setShowNotificationMenu(false));
    };

    // Close menu if esc is pressed, or if click outside of reference
    useMenuClose([buttonRef, menuRef, inviteRef], () => {
        // Only trigger if showing
        if (notifications.showNotificationMenu) {
            closeMenu();
        }
    });

    useEffect(() => {
        if (isBroadcastingEnabled()) {
            window.Echo.private(`user.${user?.uuid}`).listenToAll((event, data) => {
                dispatch(getNotificationsAsync());
                dispatch(getInvitedByAsync());

                switch(event){
                    case '.openmarket.profile-ready':
                    case '.profile.authorised':
                    case '.profile.approved':
                    case '.user.authorised':
                    case '.user.lift-rejection':
                    case '.user.adviser-unlinked-user':
                        dispatch(getUserAsync());
                        break;

                    case '.user.user-unlinked-adviser':
                    case '.user.adviser-invite':
                    case '.user.approved-invite':
                    case '.user.denied-invite':
                        if(user.is_adviser){
                            dispatch(getUserAsync());
                            dispatch(getInvitesAsync());
                        }
                        break;

                    case '.user.rejected':
                        window.location.href = '/logout';
                        break;

                    default: break;
                }
            });
        }
    }, [dispatch, user]);

    // Still keeping polling as a backup
    useInterval(async () => {
        dispatch(getNotificationsAsync());
    }, POLLING.NOTIFICATIONS);

    const renderNotificationContent = (notification) => (
        <>
            <Circle size="48px" bg="#f4f4f4" type="box" sx={{ flexShrink: 0, overflow: 'hidden' }}>
                {notification.data.avatar_url && (
                    <Image
                        alt="Notification image"
                        src={notification.data.avatar_url}
                        sx={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                )}
            </Circle>
            <Box sx={{ px: '16px' }}>
                <Text as="p" variant="small">
                    {notification.data?.content}
                </Text>
                <Text as="p" variant="small" className="notifications__date" title={moment(notification.created_at).format('Do MMM Y')}>
                    {notification.created_at && moment(notification.created_at).fromNow()}
                </Text>
            </Box>
            {!notification.read_at && <Circle size="10px" bg="tealLight" type="box" ml={'auto'} sx={{ minWidth: '10px', alignSelf: 'center' }} />}
        </>
    )

    const renderNotification = (notification, key) => (
      <NotificationMenuItem key={`notification-menu${key}`} className={notification.data?.frontend_url ? 'has-link' : ''}>
        <Flex
            {...(notification.data?.frontend_url && {
                as: Link,
                to: notification.data?.frontend_url,
                sx: { textDecoration: 'none', color: 'text' },
                onClick: closeMenu
            })}
        >
          {renderNotificationContent(notification)}
        </Flex>
      </NotificationMenuItem>
    );

    const renderInvitedByItem = (invite, key) => (
        <NotificationMenuItem key={`notification-menu${invite.uuid}`} className="has-link">
          <Flex
            as={Link}
            to={`/invite/${invite.uuid}`}
            sx={{
                textDecoration: 'none', color: 'text'
            }}
            onClick={closeMenu}
          >
             <>
                <Circle size="48px" bg="#f4f4f4" type="box" sx={{ flexShrink: 0, overflow: 'hidden' }}>
                    {invite.avatar_url && (
                        <Image
                            alt="Notification image"
                            src={invite.avatar_url}
                            sx={{
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    )}
                </Circle>
                <Box sx={{ px: '16px' }}>
                    <Text as="p" variant="small">
                        {t('notifications.invites.notification', {
                            invited_by: `${invite.first_name} ${invite.last_name}`,
                            organisation: invite?.organisation
                        })}
                    </Text>
                    <Text as="p" variant="small" className="notifications__date" title={moment(invite.created_at).format('Do MMM Y')}>
                        {invite.created_at && moment(invite.created_at).fromNow()}
                    </Text>
                </Box>
                <Circle size="10px" bg="tealLight" type="box" ml={'auto'} sx={{ minWidth: '10px', alignSelf: 'center' }} />
            </>
          </Flex>
        </NotificationMenuItem>
      );

    return (
        <NotificationMenuStyled ref={menuRef} role="dialog" tabIndex="-1" className={notifications.showNotificationMenu ? 'open' : ''} >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 3
            }}>
              <Heading as="h4" variant="menu_heading">{t('Notifications')}</Heading>
              {notifications.meta?.total >= 6 && <ThemeLink as={Link} ml={3} to="/settings/notifications#latestNotifications" sx={{
                  display: 'inline-flex',
                  fontSize: 1,
              }}>
                  View more <Icon icon="chevron-right" ml={2} sx={{ color: t => t.colors.tealLight }} />
              </ThemeLink>}
            </Box>
            <NotificationMenuScroll>
                {notifications.notifications.length === 0 && invited_by && invited_by.length === 0 ? (
                    <NotificationMenuItem><Text as="p" variant="small" mb={3}>{t('No notifications as this time...')}</Text></NotificationMenuItem>
                ) : null}

                {invited_by && invited_by.length !== 0 ?
                    <Box>
                        <Heading as="h5" variant="h5" mb={2}>{t('Invites')}</Heading>
                        {invited_by.map(renderInvitedByItem)}

                        {readNotifications.length !== 0 && unreadNotifications.length === 0 ?
                            <Heading as="h5" variant="h5" mt={2} mb={2}>{t('Notifications')}</Heading>
                        : null}
                    </Box>
                : null}

                {unreadNotifications.length !== 0 ?
                    <Heading as="h5" variant="h5" mb={2}>{t('New')}</Heading>
                : null}
                {unreadNotifications.map(renderNotification)}

                {readNotifications.length !== 0 && unreadNotifications.length !== 0 ?
                    <Heading as="h5" variant="h5" mt={3} mb={2}>{t('Earlier')}</Heading>
                : null}
                {readNotifications.map(renderNotification)}
            </NotificationMenuScroll>
        </NotificationMenuStyled>
    )
};
