import styled from '@emotion/styled';
import { theme } from 'app/theme';
import { Box } from '@theme-ui/components';

export const SwitchProfile = styled(Box)`
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);

    &.open{
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
    }

`;

export const ProfileItem = styled(Box)`
  letter-spacing: -0.01em;
  background: transparent;

  &.active {
    .profile__icon {
      color: ${theme.colors.tealDark}
    }
  }

  &:focus {
      background: transparent;
  }

  &:last-child {
    margin: 0;
  }
`;
