import { useState, useEffect } from 'react';
import { DashboardLayout } from '~/Layouts';
import {
    Container,
    IntroSteps,
    PageHeader,
    PageBanner,
    Tabs,
    Tooltip,
} from '~/Common';
import {
    Related
} from '~/Deals'
import {
    List
} from '~/Investments';
import {
    Box,
    Flex,
    Grid,
    Heading,
    Link,
    Text,
    Badge,
    NavLink as ThemedNavLink
} from '@theme-ui/components';
import { Icon } from 'assets/Icon'
import { formatCurrency, getBankDetailsText } from 'utils/helpers'
import { useDispatch, connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPortfolioStatsAsync, setPortfolioStatus } from 'features/portfolio/portfolioSlice';
import { getWatchlistDealsAsync } from 'features/watchlist/watchlistSlice';
import { getMarketDataAsync } from "features/theme/themeSlice";
import { useResponsiveValue } from '@theme-ui/match-media'
import { APP_FEATURES } from 'app/constants';
import { VerifySteps } from '~/Common/VerifySteps';
import { Carousel } from "~/MarketData";
import { CarouselWrapper } from '~/Grafa';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { setProfileByUUID } from 'features/auth/authSlice';
import { CSSTransition } from 'react-transition-group';
import { NavLink } from 'react-router-dom';


const PortfolioDashboard = ({ user, portfolio, watchlist, settings, theme }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useDocumentTitle(t('Portfolio Dashboard'));
    const [ selectedTab, setSelectedTab ] = useState('listed');
    const isMobile = useResponsiveValue([true, true, true, false]);
    const activeProfile = useSelector((state) => state.user?.activeProfile);
    const profileCount = useSelector((state) => state.user?.user?.profiles ? state.user.user.profiles.length : 1);
    const fullService = activeProfile?.account_management_type === 'Full Service';
    const individual = activeProfile?.type === 'individual';

    const tabs = [
        {
            key: 'listed',
            name: 'Listed Shareholdings',
            icon: 'clipboard-left',
            onClick: () => setSelectedTab('listed')
        },
        {
            key: 'unlisted',
            name: 'Unlisted Shareholdings',
            icon: 'clipboard-right',
            onClick: () => setSelectedTab('unlisted')
        },
    ];

    const profileTabNames = {
        individual: 'Individual',
        company: 'Company',
        superannuation: 'Super Fund',
        superannuation_company: 'Super Company',
        trust: 'Trust',
        trust_company: 'Trust Company',
    }

    const renderTradeAccountStatus = () => {
        let tooltipText = t('Set up your trading account');
        let icon = "pending";
        let iconColor = "warning";
        let linkTo = individual ? '/settings/account' : `/settings/entities/${activeProfile?.uuid}`;
    
        if (activeProfile?.account_management_type !== 'Incomplete') {
            if (activeProfile?.user_submitted_at === null && !activeProfile?.trade_account_complete) {
                tooltipText = t('Requires details');
            } else if (!activeProfile?.trade_account_complete) {
                tooltipText = t('Verifying');
                linkTo = null;
            } else {
                icon = "done";
                iconColor = "tealLight";
                tooltipText = null;
                linkTo = null;
            }
        }
    
        const content = (
            <Flex>
                {activeProfile?.type === 'individual' && user?.skip_individual_trade_account ? (
                    null
                ) : (
                    tooltipText ? (
                        <Tooltip text={tooltipText}>
                            <Icon icon={icon} color={iconColor} size="14" mr={1} pt="3px" />
                        </Tooltip>
                    ) : (
                        <Icon icon={icon} color={iconColor} size="14" mr={1} strokeWidth={2} />
                    )
                )}
                <Text as="p" variant="smallLight" color='darker' sx={{ fontWeight: 600 }}>
                    {t(`${activeProfile?.account_management_type}`)}
                </Text>
            </Flex>
        );
    
        return linkTo ? (
            <Link as={NavLink} to={linkTo} sx={{ fontSize: '14px' }}>
                {content}
            </Link>
        ) : content;
    };

    const render708Status = () => {
        let statusText = t('Required');
        let iconColor = "warning";
        let icon = "pending";
        let tooltipText = t('Please upload your 708 certificate');
        let linkTo = individual ? "/onboarding" : `/settings/certificate/${activeProfile?.uuid}`;
    
        const hasCertificate = activeProfile?.certificate !== null;
        const isExpired = activeProfile?.certificate_status === 'Expired';
    
        if (hasCertificate && isExpired) {
            statusText = t('Expired');
            linkTo = individual ? "/settings/account" : `/settings/certificate/${activeProfile?.uuid}`;
        } else if (user?.finished_onboarding_details && activeProfile?.certificate_status === 'Verified') {
            statusText = t('Complete');
            iconColor = "tealLight";
            icon = "done";
            tooltipText = null;
            linkTo = null;
        } else if (hasCertificate) {
            statusText = t('Verifying');
            tooltipText = t('Awaiting admin verification');
            linkTo = null;
        }
    
        const content = (
            <Flex>
                {tooltipText ? (
                    <Tooltip text={tooltipText}>
                        <Icon icon={icon} color={iconColor} size="14" mr={1} pt="3px" />
                    </Tooltip>
                ) : (
                    <Icon icon={icon} color={iconColor} size="14" mr={1} strokeWidth={2} />
                )}
                <Text as="p" variant="smallLight" color='darker' sx={{ fontWeight: 600 }}>
                    {statusText}
                </Text>
            </Flex>
        );
    
        return linkTo ? (
            <Link as={NavLink} to={linkTo} sx={{ fontSize: '14px' }}>
                {content}
            </Link>
        ) : content;
    };

    useEffect(() => {
        if (portfolio.stats.status === 'idle') {
            dispatch(getPortfolioStatsAsync());
        }
        if(watchlist.deals.status === 'idle'){
            dispatch(getWatchlistDealsAsync());
        }
    }, [dispatch, watchlist, portfolio]);

    useEffect(() => {
        if (theme.status === 'idle') {
            dispatch(getMarketDataAsync());
        }
    }, [theme, dispatch]);

    const handleProfileTabChange = (uuid) => {
        dispatch(setProfileByUUID(uuid));
        dispatch(setPortfolioStatus('idle'));
    }

    return (
        <DashboardLayout p={0}>
            <PageBanner variant="layouts.portfolio">
                <Heading as="h1" mb="48px" sx={{
                    color: t => t.colors.white
                }}>{t((profileCount > 1 ? 'portfolio.title' : 'My Portfolio'), { type: activeProfile?.type_name })}</Heading>

                <Box bg="light" sx={{
                    boxShadow: '3px 3px 20px rgba(29, 43, 43, 0.1)',
                    borderRadius: '20px'
                }}>
                    <Box bg="white" p={4} sx={{
                        boxShadow: '3px 3px 20px rgba(29, 43, 43, 0.1)',
                        borderRadius: '20px'
                    }}>
                        <Flex sx={{
                            flexDirection: 'column',
                            gap: [2, null, null, 5],
                        }}>
                            <Flex sx={{
                                justifyContent: ['left', 'left', 'left', 'space-between'],
                            }}>
                                <Box>
                                    <Badge variant='profile'>{user?.first_name} {user?.last_name}</Badge>
                                    <Text as={isMobile ? "h3" : "h2"}>
                                        {activeProfile?.name} <Text as="span" variant='smallLight' pl={2}>{user?.account_number}</Text>
                                    </Text>
                                </Box>
                                {!isMobile && (
                                    <Flex as="nav" sx={{
                                        backgroundColor: 'tealLighter',
                                        borderRadius: '9999px',
                                        height: 'fit-content',
                                        width: 'fit-content',
                                    }}>
                                        {user.profiles.map((profile, index) => (
                                            <ThemedNavLink 
                                                sx={{
                                                    height: '22px',
                                                    backgroundColor: activeProfile?.uuid === profile?.uuid ? 'white' : 'tealLighter',
                                                    borderRadius: '9999px',
                                                    margin: '2px',
                                                    cursor: 'pointer',
                                                    color: 'tealDark'
                                                }}
                                                key={index}
                                                onClick={() => handleProfileTabChange(profile.uuid)}
                                            >
                                                {profileTabNames[profile.type]}
                                            </ThemedNavLink>
                                        ))}
                                    </Flex>
                                )}
                            </Flex>
                            <Flex sx={{
                                width: '100%',
                                justifyContent: ['left', 'left', 'left', 'space-between'],
                                flexDirection: ['column', 'column', 'column', 'row'],
                            }}>
                                <Grid mb={[3, null, null, 0]} sx={{
                                    display: 'grid',
                                    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr 1fr', '1fr 1fr 1fr'],
                                    gap: [3, null, null, '40px'],
                                }}>
                                    <Box>
                                        <Text as="p" variant="smallLight">{t('Equity')}</Text>
                                        <Text as="p" variant="portfolio_item">{formatCurrency(portfolio?.stats?.counts?.total_portfolio_value)}</Text>
                                    </Box>
                                    {!fullService ? (
                                        <Box>
                                            <Text as="p" variant="smallLight">{t('Cash Balance')}</Text>
                                            <Text as="p" variant="portfolio_item">A${activeProfile?.cash_balance ?? '0.00'}</Text>
                                        </Box>
                                    ) : null}
                                    <Box>
                                        <Text as="p" variant="smallLight">{t('Liquidity Dollars')}</Text>
                                        <Link as={NavLink} to="/coins" sx={{
                                            color: 'text',
                                            padding: '0 !important',
                                        }}>
                                            <Flex sx={{
                                                alignContent: 'center',
                                                justifyContent: ['left', 'left', 'left', 'left'],
                                            }}>
                                                <Icon icon="coin" size="26" mr={2} /><Text as="p" variant="portfolio_item">{user?.coins}</Text>
                                            </Flex>
                                        </Link>
                                    </Box>
                                </Grid>
                                <Grid sx={{
                                    display: 'grid',
                                    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr 1fr', '1fr 1fr 1fr'],
                                    gap: [2, null, null, '24px'],
                                }}>
                                    <Box>
                                        <Text as="p" variant="smallLight" mb={[1, null, null, 0]}>{t('708 Certificate')}</Text>
                                        <Flex sx={{justifyContent: ['left', null, null, 'center']}}>
                                            {render708Status()}
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Text as="p" variant="smallLight" mb={[1, null, null, 0]}>{t('Bidding account')}</Text>
                                        <Flex sx={{ justifyContent: ['left', null, null, 'center'] }}>
                                            <Icon icon="done" color="tealLight" size="14" mr={1} strokeWidth={2} />
                                            <Text as="p" variant="smallLight" color='darker' sx={{ fontWeight: 600 }}>
                                                {user?.can_place_bids? t('Active') : t('Incomplete')}
                                            </Text>
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Text as="p" variant="smallLight" mb={[1, null, null, 0]}>{t('Trading account')}</Text>
                                        <Flex sx={{ justifyContent: ['left', null, null, 'center'] }}>
                                            {renderTradeAccountStatus()}
                                        </Flex>
                                    </Box>
                                </Grid>
                            </Flex>
                        </Flex>
                    </Box>
                    
                    <CSSTransition
                        in={activeProfile?.openmarket_completed}
                        timeout={500}
                        classNames="slide"
                        unmountOnExit
                        onEnter={(node) => {
                            node.style.height = '0px';
                            setTimeout(() => {
                                node.style.height = '48px';
                            }, 0);
                        }}
                        onExiting={(node) => {
                            node.style.height = '0px';
                        }}
                    >
                        {(state) => (
                            <Flex px={4} sx={{ height: '48px', alignItems: 'center' }}>
                                {state === 'entered' ? (
                                    <>
                                        <Box pr="12px" sx={{ borderRight: 'solid 1px #CBD2D4' }}>
                                        {activeProfile?.bank_account_bsb && activeProfile?.bank_account_number ? (
                                            <>
                                                <Text as="p" variant="caption" sx={{ color: 'dark', display: 'inline-flex' }}>{getBankDetailsText(activeProfile)}</Text>
                                                <Text ml={1} as="h5" sx={{ display: 'inline-flex' }}>{activeProfile?.bank_account_bsb} {activeProfile?.bank_account_number}</Text>
                                            </>
                                        ): (
                                            <Text as="p" variant="caption" sx={{ color: 'dark', display: 'inline-flex' }}>{t('Awaiting bank account setup')}</Text>
                                        )}
                                        </Box>
                                        <Box pl="12px">
                                            <Text as="p" variant="caption" sx={{ color: 'dark', display: 'inline-flex' }}>{t('Reference Nº')}</Text>
                                            <Text ml={1} as="h5" sx={{ display: 'inline-flex' }}>{activeProfile?.omg_account_number}</Text>
                                        </Box>
                                    </>
                                ) : null}
                            </Flex>
                        )}
                    </CSSTransition>
                </Box>
            </PageBanner>
            <PageHeader />
            <VerifySteps />
            <Carousel />
            <Container variant="layouts.default">
                <IntroSteps />
                <Tabs sx={{ mb: '40px' }} tabs={tabs} selected={selectedTab} />
                <List portfolio={portfolio} type={selectedTab} />

                {!!APP_FEATURES.following && watchlist?.deals?.live?.results.length > 0 && (
                    <Related heading="Following" showDiscoverMore={true} to="/following" deals={watchlist?.deals?.live?.results} sx={{ mt: 5, mb: 4 }} />
                )}
            </Container>
            {APP_FEATURES.news ? <CarouselWrapper /> : null}
        </DashboardLayout>
    );
};

const mapStateToProps = (state) => {
    return { user: state.user?.user, portfolio: state.portfolio, watchlist: state.watchlist, settings: state.theme?.settings, theme: state.theme }
}

export default connect(mapStateToProps, null)(PortfolioDashboard);
