import { Box } from '@theme-ui/components'
import React, { useRef, useEffect, useState } from 'react';
import { Gradient } from './helpers';

const gradient = new Gradient();

export const AnimatedTextureCanvas = ({ sx, ...props }) => {
    const canvasRef = useRef(null);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        let opacityTimeout = null;

        if (canvasRef.current) {
            gradient.initGradient(canvasRef.current);
            opacityTimeout = setTimeout(() => setOpacity(1), 200);
        }

        return () => clearTimeout(opacityTimeout);
    }, []);

    return (
        <Box sx={{
            transition: 'opacity 2s',
            opacity: opacity,
            overflow: 'hidden',
            '& canvas': {
                '--gradient-color-1': '#7FAB9F',
                '--gradient-color-2': '#0D2125',
                '--gradient-color-3': '#4C8278',
                '--gradient-color-4': '#3F6D6A', //Lighter: #95BCB7
                width: '100%',
                height: '100%'
            },
            ...sx
        }}>
            <canvas ref={canvasRef}></canvas>
        </Box>
    );
};
