import { useContext, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Flex,
    Heading,
    Paragraph
} from '@theme-ui/components';
import { BackButton, Button, Circle, SkipIdentity } from '~/Common';
import { Icon } from 'assets/Icon';
import { Trans, useTranslation } from 'react-i18next';
import { OnboardingContext } from 'pages/Onboarding/Landing';
import { onboardToken, getOnboardingStatusAsync, setUser } from 'features/auth/authSlice';
import { connect, useDispatch } from 'react-redux';
import ReactSwipe from 'react-swipe';
import { useHistory } from 'react-router-dom';
import {OnboardingSteps} from "../../../app/constants";

const mapStateToProps = (state) => {
    return { user: state.user }
}

const OnboardingIdentity = ({ user }) => {
    const onboarding = useContext(OnboardingContext);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [showBackButton, setShowBackButton] = useState(false);
    let reactSwipeEl;
    const slides = useMemo(() => {
        return user?.user?.finished_onboarding_excluding_certificate ? [
            {
                icon: 'certificate',
            }    
        ] : [
            {
                icon: 'identity-card',
            },
            {
                icon: 'passport',
            },
            {
                icon: 'certificate',
            }
        ];
    }, [user]);

    const goBack = (e) => {
        e.preventDefault();

        if (reactSwipeEl.getPos() === 0) {
            onboarding.setReverseSlides(false);
        } else {
            reactSwipeEl.prev()
        }
        setShowBackButton(Boolean(reactSwipeEl.getPos() > 0));
    };

    const goNext = () => {
        if (reactSwipeEl.getPos() === slides.length - 1) {
            skipToIdentityStep();
            onboarding.setReverseSlides(true);
        } else {
            reactSwipeEl.next()
        }
        setShowBackButton(Boolean(reactSwipeEl.getPos() > 0));
    }

    const skipToIdentityStep = () => {
        onboarding.setSeenIdentityCards(true);

        if(user?.user?.tasks?.tests?.certificate_uploaded === false){
            onboarding.setCurrentStep(OnboardingSteps.certificate);
        }
        if (user?.user?.tasks?.tests?.has_provided_passport === false) {
            onboarding.setCurrentStep(OnboardingSteps.passport);
        }
        if(user?.user?.tasks?.tests?.has_provided_drivers_licence === false){
            onboarding.setCurrentStep(OnboardingSteps.drivers_licence);
        }
        dispatch(getOnboardingStatusAsync());
    };

    useEffect(() => {
        if(user?.user?.finished_onboarding_details === true){
            onboardToken({
                device_name: 'App'
            }).then((response) => {
                dispatch(setUser(response));
                history.push('/portfolio');
            });
        }
    }, [user, dispatch, history]);

    return (
        <Box id="onboardingIdentity">
            {showBackButton && (
                <BackButton onClick={goBack} sx={{
                    position: 'absolute',
                    top: [4, 5],
                    left: [2, 0],
                    mt: '-10px'
                }} />
            )}
            <ReactSwipe
                className="carousel"
                swipeOptions={{
                    startSlide: onboarding.reverseSlides ? slides.length - 1 : 0,
                    continuous: false
                }}
                ref={el => (reactSwipeEl = el)}
            >
                {slides.map((slide, i) => {
                    return (
                        <Box key={i}>
                            <Flex sx={{
                                flexDirection: 'column',
                                textAlign: 'center',
                                minHeight: '60vh',
                                width: '100%',
                                pt: 5
                            }}>
                                {slide.icon && <Icon icon={slide.icon} color="tealLight" mb={4} size={'70px'} />}
                                <Heading as="h3" mb={'24px'} sx={{ fontSize: '26px' }}>
                                    {t(`onboarding.landing.identity.slides.${slide.icon}.title`)}
                                </Heading>
                                <Paragraph mb={4} sx={{
                                    fontSize: '16px',
                                    color: 'dark',
                                    lineHeight: '24px'
                                }}>
                                    <Trans
                                        i18nKey={`onboarding.landing.identity.slides.${slide.icon}.description`}
                                        components={{ strong: <strong /> }}
                                    />
                                </Paragraph>

                                <Box mt={'auto'}>
                                    <Box mb={4}>
                                    {Array(slides.length).fill(null).map((value, key) =>
                                        <Circle
                                            onClick={() => reactSwipeEl.slide(key, 300)}
                                            sx={{ bg: i === key ? 'tealLight' : 'light' }}
                                            size="8px"
                                            mr={key === 2 ? '0' : '8px'}
                                            key={key}
                                        >{' '}</Circle>
                                    )}
                                    </Box>

                                    <Box>
                                        <Button variant="primary" type="button" onClick={goNext} sx={{ display: 'block', width: '100%' }} mb={2}>
                                            {t(`onboarding.landing.identity.slides.${slide.icon}.next`)}
                                        </Button>

                                        {i === 2 ? <SkipIdentity type="button" sx={{ display: 'block', width: '100%' }} /> : <Box sx={{ height: '40px' }}>&nbsp;</Box>}
                                    </Box>

                                </Box>
                            </Flex>
                        </Box>
                    )
                })}
            </ReactSwipe>

            <SkipIdentity />
        </Box>
    )
};

export default connect(mapStateToProps, null)(OnboardingIdentity);
