import { useRef } from 'react'
import {
    Box,
    Card as ThemeCard,
    Flex,
    Grid,
    Heading,
    Image,
    Text,
} from '@theme-ui/components';
import { Card as DealCard } from '~/Deals'
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Circle,
    ImagePlaceholder,
    Skeleton
} from '~/Common'
import { StatusTag } from '~/Deals'
import { CardButtons } from 'components/Deals/Card';

import { useSelector } from 'react-redux'
import { useBreakpointIndex } from '@theme-ui/match-media';
import { dealOfferPriceFormatting } from 'utils/helpers';
import { Icon } from 'assets/Icon'
import { useDealTypeDetail } from 'hooks/useDealTypeDetail';
import { useDisplayName } from 'hooks/useDisplayName';

export const Featured = ({ deal, hideCardLink, ...props }) => {
    const { t } = useTranslation();
    const nodeRef = useRef(null);
    const user = useSelector((state) => state.user?.user);
    const breakpoint = useBreakpointIndex();
    const valuation = deal?.asx_market_cap ? `${deal.asx_market_cap}` : (deal?.valuation ? deal.valuation : null);
    const valuationLabel = deal?.asx_market_cap ? 'Market Cap' : 'Valuation';
    const dealDetail = useDealTypeDetail(deal);
    const dealDisplayName = useDisplayName(deal);
    const cardLink = user.can_see_dashboard && (hideCardLink === true ? false : true);

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={true}
            timeout={500}
            classNames="fade"
            unmountOnExit
            appear
        >
            {breakpoint <= 2 ? (
                <DealCard deal={deal} />
            ) : (
                <ThemeCard
                    ref={nodeRef}
                    variant="featured"
                    as={!cardLink ? Box : Link}
                    to={!cardLink ? undefined : `/deals/${deal?.slug}`}
                >
                    <Box sx={{
                        position: 'relative',
                        width: ['100%'],
                        minHeight: ['40vw', '40vw', '40vw', '450px'],
                        maxHeight: ['450px', '450px', '450px', 'none']
                    }}>
                        {deal ? (
                            <>
                                {deal?.banner?.sizes?.featured ? (
                                    <Box sx={{
                                        width: '100%',
                                        height: '100%'
                                    }}>
                                        <Image src={deal?.banner?.sizes?.featured} sx={{
                                            objectFit: 'cover',
                                            position: 'absolute',
                                            left: 0,
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            width: '100%',
                                            height: '100%'
                                        }} />
                                    </Box>
                                ) : (
                                    <ImagePlaceholder sx={{
                                        width: '100%',
                                        height: '100%'
                                    }} />
                                )}
                            </>
                        ) : (
                            <Skeleton width="100%" height="100%" />
                        )}

                        <Circle size="70px" type="box" ml={'auto'} sx={{
                            bg: 'white',
                            position: 'absolute',
                            top: '88px',
                            right: '-24px',
                            zIndex: 50,
                            overflow: 'hidden',
                            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)'
                        }}>
                            {deal ? (
                                <>
                                {deal?.company?.logo?.sizes?.tiny ? (
                                    <Image src={deal?.company?.logo?.sizes?.tiny} sx={{ borderRadius: '50%' }} />
                                ) : (
                                    <ImagePlaceholder sx={{
                                        width: '70px',
                                        height: '70px',
                                        borderRadius: '50%'
                                    }} />
                                )}
                                </>
                            ) : (
                                <Skeleton variant="circle" size="70px" />
                            )}

                        </Circle>
                    </Box>

                    <Box px={[3,3,3,'48px']} py={4} sx={{
                        position: 'relative',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}>
                        <Flex sx={{
                            flexDirection: 'row',
                            flexWrap: 'nowrap'
                        }}>
                            <Box mb={3} pr={2} sx={{ width: '100%' }}>
                                {deal ? <StatusTag label={deal?.stage?.name} status={deal?.status?.id} stage={deal?.stage?.id} mb={3} /> : <Skeleton width="100px" height="20px" mb={3} />}
                                {deal ? <Text as="p" color="dark" mb={2}>{deal?.category?.name}</Text> : <Skeleton height="14px" width="50px" mb={2} />}
                                {deal ? <Heading as="h3" variant="h3" sx={{ fontSize: '30px' }}>{t(dealDisplayName)}</Heading> : <Skeleton height="48px" width="70%" mb={2} />}
                                {deal ? <Text as="p" variant="small" color="dark" mb={2}>{deal?.code}</Text> : <Skeleton height="14px" width="30px" mb={2} />}
                            </Box>
                        </Flex>

                        {deal ? (
                            <>
                                {!deal?.preview_mode ? (
                                    <>
                                        {(deal?.offer_price && !dealDetail.alternateInvestment) && (
                                            <Grid pb={2} mb={2}  sx={{
                                                gridTemplate: ['auto', 'auto', 'auto / 240px auto'],
                                                borderBottom: ['none', 'none', '1px solid'],
                                                borderColor: t => t.colors.medium
                                            }}>
                                                <Box sx={{
                                                    width: [null, null, null, '38%']
                                                }}>
                                                    <Text as="p" variant="small" color="dark">{t('Offer Price')}</Text>
                                                    <Text as="p" variant="price" color="darker">A${dealOfferPriceFormatting(deal?.offer_price)}</Text>
                                                </Box>
                                                <Box sx={{
                                                    width: [null, null, null, '62%']
                                                }}>
                                                    <Text as="p" variant="small" color="dark">{t('Offer Size')}</Text>
                                                    <Text as="p" variant="price" color="darker">A${deal?.offer_size}</Text>
                                                </Box>
                                            </Grid>
                                        )}

                                        {dealDetail.alternateInvestment && (
                                            <Grid pb={2} mb={2} sx={{
                                                borderBottom: t => `1px solid ${t.colors.medium}`,
                                                gridTemplate: 'auto / 151px auto'
                                            }}>
                                                <Box>
                                                    <Text as="p" variant="labelCompact">{t('Minimum Investment')}</Text>
                                                    <Text as="p" variant="price" color="darker" sx={{ fontSize: 3 }}>A${dealOfferPriceFormatting(deal?.min_bid_price)}</Text>
                                                </Box>
                                            </Grid>
                                        )}

                                        {dealDetail.showDescription && deal?.alternate_investment_description ? (
                                            <Box py={3} mb={2}>
                                                {deal?.alternate_investment_description ? (
                                                    <Text as="p" variant="body" color="darker" sx={{ fontSize: 2 }}>{t(deal?.alternate_investment_description)}</Text>
                                                ) : (
                                                    <Box sx={{height: '40px'}} ></Box>
                                                )}
                                            </Box>
                                        ) : null}

                                        {(deal?.shares_available && !dealDetail.alternateInvestment) && (
                                            <Grid pb={3} sx={{
                                                gridTemplate: ['auto', 'auto', 'auto / 240px auto auto']
                                            }}>
                                                {valuation &&
                                                    <Box>
                                                            <Text as="p" variant="small" color="dark">{t(valuationLabel)}</Text>
                                                        <Text as="p" variant="price" color="darker" sx={{ fontSize: '22px' }}>{valuation}</Text>
                                                    </Box>
                                                }
                                                <Box>
                                                    <Text as="p" variant="small" color="dark">{t('Shares Available')}</Text>
                                                    <Text as="p" variant="price" color="darker" sx={{ fontSize: '22px' }}>{deal.shares_available}</Text>
                                                </Box>
                                                {deal?.asx_discount &&
                                                    <Box>
                                                        <Text as="p" variant="small" color="dark">{t('Discount')}</Text>
                                                        <Text as="p" variant="price" color="darker" sx={{ fontSize: '22px' }}>{deal?.asx_discount}%</Text>
                                                    </Box>
                                                }
                                            </Grid>
                                        )}
                                    </>
                                ) : (
                                    <Box
                                        sx={{
                                            minHeight: '110px',
                                            pb: 3,
                                            fontSize: '18px',
                                            fontWeight: 500,
                                            lineHeight: '1.4',
                                            letterSpacing: '0.01em',
                                            color: 'black',
                                        }}
                                    >
                                        {deal?.preview_text}
                                    </Box>
                                )}
                            </>
                        ) : null}

                        <Box mb={3} mt={1}>
                            {deal ? <Text as="p" variant="tag" color="dark" mb={2} sx={{ fontSize: '14px' }}>{t(dealDetail.managerText)}</Text> : <Skeleton height="14px" width="70px" mb={2} />}
                            {deal ? (
                                <>
                                    {deal?.organisation ? (
                                        <>
                                            {deal?.organisation?.logo?.sizes?.tiny ? (
                                                <Image src={deal?.organisation?.logo?.sizes?.tiny} sx={{ filter: 'grayscale(1)', maxWidth: '100%' }} />
                                            ) : (
                                                <Text as="p" variant="tag" color="dark" mb={2} sx={{ fontSize: '14px' }}>{deal?.organisation?.name}</Text>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Icon icon="lqd" height="24" color="darker" />
                                        </>
                                    )}
                                </>
                            ) : (
                                <Skeleton width="50%" height="70px" />
                            )}
                        </Box>

                        <CardButtons link={!cardLink} user={user} deal={deal} buttonHeights={['32px', '48px']} viewButtonText={dealDetail.viewButtonText} alternateInvestment={dealDetail.alternateInvestment} />
                    </Box>
                </ThemeCard>
            )}
        </CSSTransition>
    );
};
