import { useEffect, useState } from 'react'
import {
    DiscoverMore
} from '~/Common'
import {
    Box,
    Flex,
    Grid,
    Heading
} from '@theme-ui/components'
import { CardCompact, List } from '~/Deals'
import { getDeals } from 'features/deal/dealSlice'
import { DealStatus } from 'app/constants'
import { useTranslation } from 'react-i18next';
import { useMounted } from 'hooks/useMounted';

export const Related = ({
    deal,
    id = 'related',
    heading = 'Missed opportunities',
    layout = "grid",
    status = 'closed',
    deals,
    limit = 4,
    to,
    showDiscoverMore = false,
    ...props
}) => {
    const { t } = useTranslation();
    const [results, setResults] = useState([]);
    const isMounted = useMounted();

    useEffect(() => {

        // Provided deals
        if (deals !== undefined) {

            // Ensure we only have the limit
            setResults(deals.slice(0, limit));

        // Only provided deal, get others that relate
        } else if (deal !== undefined) {
            getDeals({ params: {
                category: deal?.category?.uuid,
                not_deal: deal?.uuid,
                limit: limit
            }}).then((response) => {
                if (isMounted) {
                    setResults(response?.data ?? []);
                }
            }).catch(() => {
                if (isMounted) {
                    setResults([]);
                }
            });
        } else {
            getDeals({ params: {
                status: DealStatus[status],
                limit: limit
            }}).then((response) => {
                if (isMounted) {
                    setResults(response?.data ?? []);
                }
            }).catch(() => {
                if (isMounted) {
                    setResults([]);
                }
            });
        }
    }, [deals, deal, limit, status, isMounted]);

    return (
        <>
            {results.length > 0 ?
            <Box {...props}>
                <Flex sx={{
                    borderBottom: '1px solid',
                    borderColor: 'medium',
                    mb: 4,
                    pb: 1
                }}>
                    <Heading as="h2" variant="h3" id={id}>{t(heading)}</Heading>

                    { showDiscoverMore && (
                        <Box ml={'auto'}>
                            <DiscoverMore to={to ? to : '/deals' } />
                        </Box>
                    )}
                </Flex>

                <Grid sx={{
                    gridGap: 4,
                    gridTemplateColumns: layout === 'grid' ? ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)'] : '1fr',
                    gridTemplateRows: '1fr',
                    width: '100%',
                    overflowX: 'auto',
                    pt: '10px',
                    pb: '30px',
                    px: 3,
                    mx: -3,
                    boxSizing: 'content-box',
                }}>
                {results.map((row, key) => (
                    <Box key={key}>
                        {layout === 'grid' ? (
                            <CardCompact deal={row} sx={{ height: '100%' }} />
                        ) : (
                            <List deal={row} />
                        )}
                    </Box>
                ))}
                </Grid>
            </Box>
            : null}
        </>
    )
};
