import { useRef, useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import {
    Button,
    Alert,
    SwitchProfileButton
} from '~/Common';
import { StatusTag } from '~/Deals'
import {
    Badge,
    Box,
    Checkbox,
    Close,
    Flex,
    Grid,
    Heading,
    Label,
    Link,
    Text,
    Spinner
} from '@theme-ui/components'
import { useResponsiveValue } from '@theme-ui/match-media'
import { Form, Formik } from 'formik';
import { Field } from '~/Forms/Field';
import { Icon } from 'assets/Icon'
import Modal from 'react-modal'
import { Link as RouterLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dealPlaceBid, sendVerification, verify } from 'features/deal/dealSlice';
import { setShowPlaceBid } from 'features/theme/themeSlice';
import { setPortfolioStatus } from 'features/portfolio/portfolioSlice';
import { rejectEoi } from "features/auth/authAPI";
import { theme as appTheme } from 'app/theme'
import { formatCurrency, formatNumber, numberWithCommas, grabFirstError, dealOfferPriceFormatting } from 'utils/helpers'
import { toast } from 'utils/toast';
import { APP_FEATURES, LQD_CONVERSION, DealInvestmentStatus } from 'app/constants'
import { useMenuClose } from 'hooks/useMenuClose';
import { CoinBalance } from '~/Common/CoinBalance';
import { CheckboxField } from '~/Forms/CheckboxField';
import ReactMarkdown from 'react-markdown';
import {confirmAlert} from "react-confirm-alert";
import {ThemeProvider} from "theme-ui";
import { getUserAsync } from 'features/auth/authSlice';
import { useDealTypeDetail } from 'hooks/useDealTypeDetail';
import { useDisplayName } from 'hooks/useDisplayName';

export const DealModal = ({ deal, user, theme, onBidPlaced }) => {
    const convertFormattedStringToNumber = (formattedString) => {
        if (formattedString === null || formattedString === undefined) {
            return 0;
        }
        return parseFloat(formattedString?.replace(/,/g, ''));
    };
    const offer_price = convertFormattedStringToNumber(deal?.offer_price);    
    const eoi = user.user?.eoi?.deal_investment_status_id === DealInvestmentStatus.pending ? user.user?.eoi : null;
    const [showRejectModal, setShowRejectModal] = useState(eoi !== null);
    const [amounts, setAmounts] = useState({
        minAmount: 0,
        maxAmount: 0,
        step: 0,
        currentInvestment: formatNumber(eoi?.deal_info?.uuid === deal?.uuid && eoi?.capital > deal?.min_bid_price ? eoi?.capital : Math.ceil(deal?.min_bid_price / offer_price) * offer_price),
        lqdPortion: 0,
        bidStatus: 'open'
    });
    const profileSwitchStatus = useSelector(state => state.user.profile_switch_status);
    const [useLqd, setUseLqd] = useState(false);
    const userCoins = user?.user?.coins ?? 0;
    const [maxLqd, setMaxLqd] = useState(0);
    const modalRef = useRef(null);
    const [savedFormData, setFormData] = useState(false);
    const isMobile = useResponsiveValue([true, true, true, false]);
    const formRef = useRef(null);
    const [policy, setPolicy] = useState(null);
    const activeProfile = user?.activeProfile;
    const [hins, setHins] = useState([]);
    const [newHin, setNewHin] = useState(false);
    const [adjustingBid, setAdjustingBid] = useState(false);
    const dealDetail = useDealTypeDetail(deal);
    const dealDisplayName = useDisplayName(deal);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const capitalPortion = amounts.currentInvestment - (useLqd ? amounts.lqdPortion * LQD_CONVERSION : 0);
    const rewardCoins = Math.floor(deal?.total_lqd_pool * (formatNumber(capitalPortion) / formatNumber(deal?.offer_size)));
    const expectedShares = Math.round(formatNumber(deal?.shares_available) * (formatNumber(capitalPortion) / formatNumber(deal?.offer_size)));

    const bidType = deal?.bid_type;
    const currentInvestment = amounts?.currentInvestment;
    const fixedBidPrice = formatNumber(deal?.fixed_bid_price);
    const minBidPrice = formatNumber(deal?.min_bid_price);
    const maxBidPrice = formatNumber(deal?.max_bid_price);
    const dealStepBidPrice = deal?.step_bid_price ? formatNumber(deal?.step_bid_price) : 10000;
    const dealMinBidPrice = formatNumber(deal?.min_bid_price);

    const validationSchema = Yup.object().shape({
        investment: Yup.number().transform((_value, orig) => formatNumber(orig)).min(amounts.minAmount).max(amounts.maxAmount).required(),
        coins: Yup.number().transform((_value, orig) => formatNumber(orig)).min(0).max(maxLqd),
        agree_terms: dealDetail?.alternateInvestment ? Yup.bool().nullable() : Yup.bool().required(),
        hin_number: Yup.string().nullable().when('hin_number_existing', {
            is: (value) => value === null || value === '',
            then: Yup.string().trim().matches(/X[0-9]{10,}/, 'Is not in correct HIN format').required()
        }),
    });

    const validationVerifySchema = Yup.object().shape({
        code: Yup.string().required().test('len', 'must be exactly 6 characters', val => val !== undefined && val.length === 6)
    });

    const inputSx = {
        maxWidth: '430px',
        ml: 'auto',
        mr: 'auto'
    }

    const openTermsModal = () => {
        setPolicy({
            title: 'Terms and Conditions',
            content: theme.settings.deal_bid_terms,
        });
    }

    const closePolicyModal = () => {
        setPolicy(null);
    }

    const maxAllowedLqd = useCallback(value => {
        const maxAllowed = Math.floor(value / LQD_CONVERSION);
        return maxAllowed > userCoins ? userCoins : maxAllowed;
    }, [userCoins]);

    const closeModal = useCallback(e => {
        const rejectEoiConfirmed = (slug) => {
            rejectEoi(slug).then(() => {
                dispatch(getUserAsync());
            });
        };
        const rejectEoiOnClose = (slug) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <ThemeProvider theme={appTheme}>
                            <Box bg="white" px={5} py={4} sx={{
                                maxWidth: '600px',
                                width: '100%',
                                textAlign: 'center',
                                boxShadow: '0 20px 75px rgb(0 0 0 / 23%)',
                                position: 'relative'
                            }}>
                                <Close onClick={onClose} sx={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px'
                                }}></Close>

                                <Heading as="h2" variant="h2" mb={3}>{t('Are you sure?')}</Heading>
                                <Text as="p" mb={3}>{t('Are you sure you want to cancel this bid?')}</Text>
                                <Text as="p" mb={3}>{t('You may bid again but will risk missing out.')}</Text>
                                <Button variant="primary" mr={3} onClick={() => {
                                    rejectEoiConfirmed(slug);
                                    onClose();
                                }}
                                >
                                    {t('Yes, please cancel it!')}
                                </Button>
                                <Button variant="link" onClick={() => {
                                    onClose();
                                    dispatch(setShowPlaceBid(true));
                                }}>
                                    {t('Cancel')}
                                </Button>
                            </Box>
                        </ThemeProvider>
                    );
                }
            });
        };

        if (e) {
            e.preventDefault();
        }

        if (!!policy) {
            return;
        }

        if (eoi && eoi?.deal_info?.uuid === deal?.uuid && showRejectModal) {
            rejectEoiOnClose(deal?.slug);
        }

        setUseLqd(false);

        switch (deal?.bid_type) {
            case 'fixed':
                setAmounts({
                    minAmount: formatNumber(deal?.fixed_bid_price),
                    maxAmount: formatNumber(deal?.fixed_bid_price),
                    step: 0,
                    currentInvestment: formatNumber(deal?.fixed_bid_price),
                    lqdPortion: maxLqd,
                    bidStatus: 'open'
                });
                break;

            case 'flexible':
            default:
                setAmounts({
                    minAmount: formatNumber(deal?.min_bid_price),
                    maxAmount: formatNumber(deal?.max_bid_price),
                    step: deal?.step_bid_price ? formatNumber(deal?.step_bid_price) : 10000,
                    currentInvestment: formatNumber(deal?.min_bid_price),
                    lqdPortion: maxLqd,
                    bidStatus: 'open'
                });
                break;
        }

        dispatch(setShowPlaceBid(false));
    }, [deal, dispatch, maxLqd, policy, eoi, t, showRejectModal]);

    useMenuClose(modalRef, () => {
        closeModal();
    });

    useEffect(() => {
        switch (bidType) {
            case 'fixed':
                setAmounts((amounts) => ({
                    ...amounts,
                    minAmount: fixedBidPrice,
                    maxAmount: fixedBidPrice,
                    step: 0,
                    currentInvestment: fixedBidPrice,
                    bidStatus: 'open'
                }));
                break;

            case 'flexible':
            default:
                setAmounts((amounts) => ({
                    ...amounts,
                    minAmount: minBidPrice,
                    maxAmount: maxBidPrice,
                    step: dealStepBidPrice,
                    bidStatus: 'open'
                }));
                break;
        }
    }, [
        maxBidPrice,
        minBidPrice,
        dealStepBidPrice,
        dealMinBidPrice,
        bidType,
        fixedBidPrice,
        activeProfile,
        maxAllowedLqd,
    ]);

    useEffect(() => {
        if(profileSwitchStatus === 'idle'){
            setNewHin(false);
            setHins(() => {
                let options = [];

                if(activeProfile?.hin_number){
                    options.push({
                        label: `Default | ${activeProfile.hin_number}`,
                        value: activeProfile.hin_number
                    });
                }
                if(activeProfile?.external_hins && activeProfile?.external_hins.length){
                    activeProfile?.external_hins.map(h => {
                        options.push({
                            label: h.name ? `${h.name} | ${h.hin_number}` : h.hin_number,
                            value: h.hin_number
                        });
                        return h;
                    });
                }

                if(options.length === 0){
                    setNewHin(() => false);
                }
                return options;
            });
        }
    }, [activeProfile, profileSwitchStatus]);

    useEffect(() => {
        setMaxLqd(maxAllowedLqd(currentInvestment));
        let newValue = Math.floor(currentInvestment / offer_price) * offer_price;

        if (currentInvestment !== newValue) {
            setAdjustingBid(true);
            const timeoutId = setTimeout(() => {   
                setAmounts({
                ...amounts,
                currentInvestment: newValue
                });
                // if form is not null, setFieldValue
                if (formRef?.current !== null) {
                    formRef?.current?.setFieldValue('investment', newValue);
                }
    
                setAdjustingBid(false);
            }, 1500);

            return () => clearTimeout(timeoutId);
        }
        setAdjustingBid(false);
      }, [amounts, currentInvestment, maxAllowedLqd, offer_price]);

    const handlePlaceBid = (data) => new Promise((resolve, reject) => {
        dealPlaceBid(data)
            .then((response) => {
                toast.success(t('deals.bid.success'), {
                    toastId: 'deal-bid-success',
                });

                setAmounts({
                    ...amounts,
                    currentInvestment: response?.data?.capital,
                    lqdPortion: response?.data?.coins,
                    bidStatus: 'success'
                });

                dispatch(setPortfolioStatus('idle'));
                setShowRejectModal(false);

                onBidPlaced(true);

                resolve(response);
            })
            .catch(({ response }) => {
                if (response?.status === 403) {
                    setFormData(data);
                    setAmounts({
                        ...amounts,
                        bidStatus: 'verify'
                    });
                } else {
                    toast.error(grabFirstError(response.data?.errors) ?? t('deals.bid.error'), {
                        toastId: 'deal-bid-error',
                    });
                    setAmounts({
                        ...amounts,
                        bidStatus: 'open'
                    });
                }

                reject(response);
            });
    });

    const handleSubmit = (values, actions) => {
        if (!values.agree_terms && !dealDetail?.alternateInvestment) {
            toast.error(t('deals.bid.agreeTermsError'), {
                toastId: 'deal-terms-error',
            });
            setAmounts({
                ...amounts,
                bidStatus: 'open'
            });
            return;
        }

        let formdata = new FormData();
        formdata.append('deal', deal?.uuid);
        formdata.append('profile', activeProfile?.uuid);
        formdata.append('investment', values.investment);
        formdata.append('coins', useLqd ? parseInt(values.coins) : 0);
        formdata.append('agree_terms', values.agree_terms);
        formdata.append('follow_company', values.follow_company);
        formdata.append('hin_number', newHin ? values?.hin_number : values.hin_number_existing);
        formdata.append('hin_number_name', newHin ? values?.hin_number_name : null);
        formdata.append('hin_number_save', newHin ? values?.hin_number_save : false);

        handlePlaceBid(formdata)
            .catch((response) => {
                if (response?.status !== 403) {
                    actions.setStatus('api_error');
                }
                response?.data?.errors && actions.setErrors(response.data.errors);
            })
            .finally(() => actions.setSubmitting(false));
    };

    const resendVerification = e => {
        e.preventDefault();

        sendVerification()
            .then((response) => {
                toast.success(t(response.message), {
                    toastId: 'twofa-sent',
                });
            })
            .catch((response) => {
                toast.error(t(response.message), {
                    toastId: 'twofa-not-sent',
                });
            });
    }

    const handleVerifySubmit = (values, actions) => {
        let formdata = new FormData();
        formdata.append('code', values.code);

        verify(formdata)
            .then((response) => {
                setAmounts({
                    ...amounts,
                    bidStatus: 'loading'
                });

                actions.resetForm();

                handlePlaceBid(savedFormData)
                    .then((response) => {
                        setAmounts({
                            ...amounts,
                            bidStatus: 'success'
                        });
                    })
                    .catch((response) => {
                        setAmounts({
                            ...amounts,
                            bidStatus: 'open'
                        });
                    });
            })
            .catch((response) => {
                actions.resetForm();

                toast.error(t(response.message), {
                    toastId: 'identity-not-verified',
                });
            })
            .finally(() => actions.setSubmitting(false));
    }

    const transitionSx = condition => ({
        transition: 'all 0.3s ease-in-out',
        maxHeight: condition ? '999px' : 0,
        opacity: condition ? 1 : 0,
        overflow: 'hidden'
    });

    const handleSubmitClick = () => {
        if (formRef.current) {
            formRef.current.submitForm();
            setAmounts({
                ...amounts,
                bidStatus: 'loading'
            });
        }
    }

    const setCurrentInvestment = useCallback((value) => {
        let newValue = value < amounts.minAmount
            ? amounts.minAmount
            : value > amounts.maxAmount
            ? amounts.maxAmount
            : value;
        setAmounts({ ...amounts, currentInvestment: newValue })
        return newValue;
    }, [setAmounts, amounts]);

    const renderButtons = (isInvalid) => {
        switch (amounts.bidStatus) {
            case 'open':
                return (
                    <>
                        <Grid
                            mt={2}
                            sx={{
                                gridGap: 2,
                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                gridTemplateRows: '1fr',
                                width: '100%'
                            }}
                            mb={3}
                        >
                            <Button onClick={closeModal} variant={isMobile ? 'primary' : 'borderedMedium'} sx={{ width: '100%', height: '60px', borderRadius: '8px', order: isMobile ? 2 : 1 }} >
                                {t('Cancel')}
                            </Button>

                            <Button disabled={isInvalid || adjustingBid} onClick={handleSubmitClick} variant={isMobile ? 'tealLight' : 'primary'} sx={{ width: '100%', height: '60px', order: isMobile ? 1 : 2 }}>
                                {adjustingBid ? <Spinner variant="styles.spinner" sx={{ display: 'inline' }} /> : t(dealDetail.alternateInvestment ? dealDetail.bidButtonText : 'Confirm bid')}
                            </Button>

                        </Grid>
                    </>
                );
            case 'verify':
                return null;
            case 'success':
                return null;
            default:
                return null;
        }
    };

    const useSmallText = amounts?.currentInvestment.toString().length > 6;

    const content = () => {
        switch (amounts.bidStatus) {
            case 'loading':
                return (
                    <Flex sx={{
                        flexDirection: 'column',
                        height: '100%',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Spinner variant="styles.spinner" mb={2} />
                        <Heading variant="h2" sx={{ fontSize: '36px', lineHeight: '43px' }}>Please wait while<br />we process your bid</Heading>
                        <Text as="p" mt={'27px'}>It may take a few seconds</Text>
                    </Flex>
                )
            case 'open':
                if (dealDetail.alternateInvestment) {
                    return (
                        <Formik
                            innerRef={formRef}
                            initialValues={{
                                investment: amounts.currentInvestment,
                                coins: maxLqd,
                                agree_terms: false,
                                follow_company: true,
                                hin_number_existing: activeProfile?.hin_number ?? '',
                                hin_number: '',
                                hin_number_name: '',
                                hin_number_save: false,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, setFieldValue, errors, touched, isValid }) => (
                                <Form>
                                    <Flex sx={{
                                        flexDirection: 'column',
                                        height: '100%'
                                    }}>
                                        <Heading as="h2" variant="h2" color={['white', null, null, 'primary']} id="confirmBidTitle">{t('Confirm amount')}</Heading>
        
                                        <Box mb={4}>
                                            <Box mt={3}>
                                                <Field
                                                    name="investment"
                                                    field="currency"
                                                    label="Set your amount"
                                                    variant="forms.input.investment"
                                                    sx={{
                                                        transition: 'font-size 0.5s ease-in-out',
                                                        fontSize: [
                                                            useSmallText ? '28px' : '42px',
                                                            useSmallText ? '34px' : '48px'
                                                        ]
                                                    }}
                                                    showLabel={false}
                                                    readonly={deal?.bid_type === 'fixed'}
                                                    groupSx={inputSx}
                                                    groupMb={0}
                                                    onKeyUp={e => setAmounts({ ...amounts,
                                                        currentInvestment: e.target.value ? formatNumber(e.target.value.replaceAll(',', '').replace('$', '')) : 0
                                                    })}
                                                />
                                            </Box>
                                            {(eoi && eoi?.deal_info?.uuid === deal?.uuid && eoi?.capital < minBidPrice) && <Text as="p" variant="caption" color="red" mb={1} mt={1}>{t('Your bid has been adjusted up from {{bidAmount}} to the minimum bid amount for this deal', { bidAmount: formatCurrency(eoi?.capital) })}</Text> }
        
                                            {deal?.bid_type === 'flexible' && amounts.step > 0 && (
                                                <Box mt={3}>
                                                    <Button type="button" variant="increment" mr={3} onClick={(e) => {
                                                        const newValue = setCurrentInvestment(formatNumber(values.investment) - amounts.step);
                                                        setFieldValue('investment', newValue);
                                                        touched.investment = true;
                                                    }}>
                                                        <Icon icon="minus" size="14" />
                                                        <Text className="sr-only">Lower investment by {formatCurrency(amounts.step)}</Text>
                                                    </Button>
                                                    <Button type="button" variant="increment" onClick={(e) => {
                                                        const newValue = setCurrentInvestment(formatNumber(values.investment) + amounts.step);
                                                        setFieldValue('investment', newValue);
                                                        touched.investment = true;
                                                    }}>
                                                        <Icon icon="plus" size="14" />
                                                        <Text className="sr-only">Raise investment by {formatCurrency(amounts.step)}</Text>
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
        
                                        <Box mt="auto">
                                            <CheckboxField name="follow_company" type="checkbox" variant={'checkbox.small'} validIndicator={false} sx={{ color: isMobile ? 'white' : 'primary' }} label={t('deals.modal.followCompany', { company: deal?.company?.name })} />
                                        </Box>
        
                                        {!isMobile && renderButtons(!isValid)}
                                    </Flex>
                                </Form>
                            )}
                        </Formik>
                    )
                }

                return (
                    <Formik
                        innerRef={formRef}
                        initialValues={{
                            investment: amounts.currentInvestment,
                            coins: maxLqd,
                            agree_terms: false,
                            follow_company: true,
                            hin_number_existing: activeProfile?.hin_number ?? '',
                            hin_number: '',
                            hin_number_name: '',
                            hin_number_save: false,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue, errors, touched, isValid }) => (
                            <Form>
                                <Flex sx={{
                                    flexDirection: 'column',
                                    height: '100%'
                                }}>
                                    {deal?.bid_placed &&
                                        <Alert mb={4} variant="bid" isCloseable={false}>
                                            <Text variant="caption">{t('deals.modal.bidBefore')}</Text>
                                        </Alert>
                                    }
                                    <Heading as="h2" variant="h2" color={['white', null, null, 'primary']} id="confirmBidTitle">{t('Confirm bid amount')}</Heading>

                                    <Box mb={4}>
                                        <Box mt={3}>
                                            <Field
                                                name="investment"
                                                field="currency"
                                                label="Set your amount"
                                                variant="forms.input.investment"
                                                sx={{
                                                    transition: 'font-size 0.5s ease-in-out',
                                                    fontSize: [
                                                        useSmallText ? '28px' : '42px',
                                                        useSmallText ? '34px' : '48px'
                                                    ]
                                                }}
                                                showLabel={false}
                                                readonly={deal?.bid_type === 'fixed'}
                                                groupSx={inputSx}
                                                groupMb={0}
                                                onKeyUp={e => setAmounts({ ...amounts,
                                                    currentInvestment: e.target.value ? formatNumber(e.target.value.replaceAll(',', '').replace('$', '')) : 0
                                                })}
                                            />
                                        </Box>
                                        {(eoi && eoi?.deal_info?.uuid === deal?.uuid && eoi?.capital < minBidPrice) && <Text as="p" variant="caption" color="red" mb={1} mt={1}>{t('Your bid has been adjusted up from {{bidAmount}} to the minimum bid amount for this deal', { bidAmount: formatCurrency(eoi?.capital) })}</Text> }
                                        {expectedShares && deal?.shares_available ? <Text as="p" variant="caption" color="dark" mb={0} mt={1}>{t('Should your bid be successful you will receive {{shares}} shares', { shares: numberWithCommas(expectedShares) })}</Text> : null}

                                        {deal?.bid_type === 'flexible' && amounts.step > 0 && (
                                            <Box mt={3}>
                                                <Button type="button" variant="increment" mr={3} onClick={(e) => {
                                                    const newValue = setCurrentInvestment(formatNumber(values.investment) - amounts.step);
                                                    setFieldValue('investment', newValue);
                                                    touched.investment = true;
                                                }}>
                                                    <Icon icon="minus" size="14" />
                                                    <Text className="sr-only">Lower investment by {formatCurrency(amounts.step)}</Text>
                                                </Button>
                                                <Button type="button" variant="increment" onClick={(e) => {
                                                    const newValue = setCurrentInvestment(formatNumber(values.investment) + amounts.step);
                                                    setFieldValue('investment', newValue);
                                                    touched.investment = true;
                                                }}>
                                                    <Icon icon="plus" size="14" />
                                                    <Text className="sr-only">Raise investment by {formatCurrency(amounts.step)}</Text>
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>

                                    {APP_FEATURES.use_lqd_in_bids && userCoins && userCoins > 0 && (
                                        <Box mb={4}>
                                            <Label sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                mb: 0
                                            }}>
                                                <Checkbox name="remember" onChange={e => setUseLqd(e.target.checked)} checked={useLqd} />
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Text as="p">Use Liquidity dollars (</Text>
                                                    <Icon icon="coin-thick" size="16" />
                                                    <Box>&nbsp;{userCoins}</Box>
                                                    <Text as="p">&nbsp;available)</Text>
                                                </Box>
                                            </Label>
                                            <Box sx={{
                                                pt: useLqd ? 3 : 0,
                                                ...transitionSx(useLqd)
                                            }}>
                                                <Field
                                                    name="coins"
                                                    field="coin"
                                                    label={t('liquidityDollars.add')}
                                                    groupSx={{ mb: 0, ...inputSx }}
                                                    maxValue={maxLqd}
                                                    disabled={maxLqd <= 0}
                                                    onKeyUp={e => setAmounts({ ...amounts, lqdPortion: e.target.value ? parseInt(e.target.value.replaceAll(',', '')) : 0 })}
                                                />
                                            </Box>
                                            {amounts.currentInvestment > 0 && <Box sx={{
                                                ...transitionSx(useLqd && amounts.lqdPortion),
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                mx: -2,
                                                mt: 1
                                            }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', px: 2, alignItems: 'start' }}>
                                                    <Text variant="caption" as="p">Capital</Text>
                                                    <Text variant="small" as="p">{formatCurrency(capitalPortion)}</Text>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', px: 2, alignItems: 'start' }}>
                                                    <Text variant="caption" as="p">Liquidity dollars</Text>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Icon icon="coin-thick" />
                                                        <Text variant="small" as="p">&nbsp;{numberWithCommas(amounts.lqdPortion)}</Text>
                                                    </Box>
                                                </Box>
                                            </Box>}
                                        </Box>
                                    )}

                                    <Box mb={4}>
                                        {!isMobile && (
                                            <Box mb={'11px'}>
                                                <CoinBalance balance={rewardCoins} expectedShares={expectedShares} totalShares={deal?.shares_available} />
                                            </Box>
                                        )}
                                    </Box>

                                    <Box mb={4} sx={{
                                        bg: 'light',
                                        borderRadius: '12px',
                                        textAlign: 'left'
                                    }}>
                                        <Box p={3} sx={{
                                            borderBottom: '1px solid',
                                            borderColor: 'utilityGray200'
                                        }}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                <Text as="label" sx={{ fontSize: 2, fontWeight: 'heading' }} mr={2}>{t('Holder identification number (HIN)')}</Text>
                                                <Badge variant="new">{t('New')}</Badge>
                                            </Box>
                                            <Box>
                                                <Text as="p" sx={{ fontSize: 1, fontWeight: 'body' }}>Now you can add or manage external HINs to bid.</Text>
                                            </Box>
                                        </Box>
                                        <Box p={3}>
                                            <Field
                                                name="hin_number_existing"
                                                field="creatable-select"
                                                label="Select below or type new external HIN"
                                                placeholder="Select below or type new external HIN"
                                                createOptionPosition="first"
                                                formatCreateLabel={(inputValue) => `Create "X${inputValue.replace(/[^0-9]/g, '')}"`}
                                                onCreateOption={(inputValue) => {
                                                    setFieldValue('hin_number_existing', '');
                                                    setFieldValue('hin_number', `X${inputValue.replace(/[^0-9]/g, '')}`);
                                                    setNewHin(true)
                                                }}
                                                options={hins}
                                                onChangeOverride={(item) => {
                                                    const { value } = item;
                                                    setFieldValue('hin_number_existing', value);
                                                    setFieldValue('hin_number', '');
                                                    setNewHin(false)
                                                }}
                                            />

                                            {newHin ? (
                                                <Box>
                                                    <Field name="hin_number" label="HIN Number" onKeyUp={e => setFieldValue('hin_number', e.target.value ? `X${e.target.value.replace(/[^0-9]/g, '')}` : '')} />
                                                    <CheckboxField name="hin_number_save" type="checkbox" validIndicator={false} variant={'checkbox.small'} label="Save HIN for another time." />
                                                    {values.hin_number_save ? <Field name="hin_number_name" label="Reference Name" /> : null}
                                                </Box>
                                            ) : null}
                                        </Box>
                                    </Box>

                                    <Box mt="auto">
                                        <CheckboxField name="follow_company" type="checkbox" variant={'checkbox.small'} validIndicator={false} sx={{ color: isMobile ? 'white' : 'primary' }} label={t('deals.modal.followCompany', { company: deal?.company?.name })} />
                                        <CheckboxField name="agree_terms" type="checkbox" variant={'checkbox.small'} validIndicator={false}
                                            sx={{
                                                color: isMobile ? 'white' : 'primary',
                                                '& a' : {
                                                    color: isMobile ? 'white' : 'primary'
                                                }
                                            }}
                                            label={
                                                <>
                                                    {`${t('deals.modal.acceptTerms')} `}
                                                    <Link as="a" href="#terms" onClick={openTermsModal}>{t('deals.modal.termsLink')}</Link>
                                                    {` ${t('deals.modal.acceptTermsAfter')}`}
                                                </>
                                            }
                                        />
                                    </Box>

                                    {!isMobile && renderButtons(!isValid)}
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                )
            case 'verify':
                return (
                    <Formik
                        initialValues={{
                            code: ''
                        }}
                        validationSchema={validationVerifySchema}
                        onSubmit={handleVerifySubmit}
                    >
                        {({ validateField, isSubmitting, submitForm }) => (
                            <Form>
                                <Box mb={4}>
                                    <Heading as="h2" variant="h2" color="primary">{t('Please verify your identity with the code sent to your registered mobile number')}</Heading>
                                </Box>

                                {isSubmitting ? (
                                    <Box my={2}>
                                        <Spinner variant="styles.spinner" />
                                    </Box>
                                ) : (
                                    <>
                                        <Field
                                            field="verify"
                                            label={t('Please supply your code')}
                                            showLabel={true}
                                            name="code"
                                            validateField={validateField}
                                            onChange={(field) => {
                                                if (field.value.length >= 6) {
                                                    submitForm();
                                                }
                                            }}
                                        />
                                        <Box>
                                            <Link as="a" onClick={resendVerification}>
                                                {t('Resend verification code')}
                                            </Link>
                                        </Box>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                )
            case 'success':
                return (
                    <Flex sx={{
                        flexDirection: 'column',
                        height: '100%',
                        flex: 1
                    }}>
                        <Box mb={4}>
                            <Icon icon="done" mb={3} size="50" color="tealDark" />
                            <Heading as="h2" variant="h2" id="confirmBidTitle" sx={{ color: ['white', null, null, 'primary'], fontSize: '36px', letterSpacing: '-0.03em' }}>{t(`Your ${dealDetail.alternateInvestment ? 'expression of interest' : 'bid'} has been placed`)}</Heading>
                        </Box>

                        <Box mb={4}>
                            <Box my={3}>
                                <Text as="p" variant="h1" color="tealDark" sx={{ fontFamily: 'serif', fontSize: '60px' }}>{formatCurrency(amounts.currentInvestment, 0)}</Text>
                            </Box>
                            <Text as="p" variant="h5" mb={4} sx={{ color: ['medium', null, null, 'darker'] }}>{t(`We will ${dealDetail.alternateInvestment ? 'be in touch shortly with further details' : 'notify you once your bid is approved'}.`)}</Text>
                        </Box>

                        <Box mt={'auto'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                variant="primary"
                                as={RouterLink}
                                to="/portfolio"
                                sx={{ width: '100%', height: '60px', lineHeight: '44px' }}
                                mb={3}
                                onClick={() => closeModal()}
                            >{t('Go to Portfolio')}</Button>

                            <Link as="a" href="#cancel" onClick={closeModal}>
                                {t('Back to deal')}
                            </Link>
                        </Box>
                    </Flex>
                )
            default:
                return <></>
        }
    }

    if (!deal) {
        return null;
    }

    return (
        <>
        <Modal
            isOpen={!!policy}
            onRequestClose={closePolicyModal}
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={300}
            contentLabel={policy?.title}
            style={appTheme.modals.terms}
            aria={{
                labelledby: "modalTitle"
            }}
        >
            <Box>
                <Heading as="p" variant="h1" id="modalTitle" mb={4}>{policy?.title}</Heading>
                <Box sx={{
                    variant: 'styles',
                    maxHeight: '50vh',
                    bg: 'lighter',
                    p: 3,
                    overflow: 'auto'
                }}>
                    <ReactMarkdown>{policy?.content}</ReactMarkdown>
                </Box>

                <Button variant="primary" sx={{
                    mt: 4,
                    width: '100%',
                    maxWidth: '280px'
                }} type="button" onClick={closePolicyModal}>{t('Close')}</Button>
            </Box>
        </Modal>
        <Modal
            contentRef={node => (modalRef.current = node)}
            isOpen={theme.showPlaceBid}
            onRequestClose={closeModal}
            style={{
                overlay: appTheme.modals.deal_bid.overlay,
                content: {
                    ...appTheme.modals.deal_bid.content,
                    overflow: isMobile ? 'hidden' : 'auto',
                    maxHeight: isMobile ? null : '80vh',
                    borderRadius: isMobile ? '0' : '16px'
                }
            }}
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={300}
            contentLabel="Please confirm your bid"
            aria={{
                labelledby: "confirmBidTitle"
            }}
        >
            <>
                <Flex sx={{
                    flexDirection: ['column', null, null, 'row'],
                    overflow: 'auto',
                    width: '100%',
                    height: ['100vh', null, null, 'auto']
                }}>
                    <Box bg="darker" color="light" px={4} py={'40px'} sx={{
                        width: ['100%', null, null, '400px'],
                        textAlign: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        order: [2, null, null, 1]
                    }}>
                        {Array.isArray(user?.user?.profiles) && user?.user?.profiles.length > 0 && (
                            <Box mb={4} sx={{ position: 'relative' }}>
                                {activeProfile && (
                                    <Box>
                                        <Heading variant="caption" mb={2} color="light">{t('You are bidding with')}</Heading>
                                        <SwitchProfileButton fullWidth={true} />
                                    </Box>
                                )}
                            </Box>
                        )}

                        <Box sx={{
                            pt: 4,
                            display: ['flex', null, null, 'block'],
                            flexWrap: 'wrap'
                        }}>
                            <Box sx={{ flex: '100%' }}>
                                <StatusTag
                                    label={deal?.stage?.name}
                                    status={deal?.status?.id}
                                    stage={deal?.stage?.id}
                                    sx={{
                                        mb: 3
                                    }}
                                />
                                <Box mb={'54px'}>
                                    <Heading as="h1" variant="h3" color="white" sx={{ fontWeight: 500 }}>{t(dealDisplayName)}</Heading>
                                    <Text as="p" variant="small" color="light">{deal?.code}</Text>
                                    {deal?.valuation ? (
                                        <>
                                            <Text as="p" variant="small" color="lighter" mb={2}>{t('Pre-Money Valuation')}</Text>
                                            <Text as="p" variant="price" color="white" sx={{ fontSize: [3, 5] }}>A${deal?.valuation}</Text>
                                        </>
                                    ) : null}
                                </Box>
                            </Box>

                            {(deal?.offer_price && !dealDetail.alternateInvestment) && (
                                <>
                                    <Box mb={[0, 0, 0, 4]} sx={{ flex: '50%' }}>
                                        <Text as="p" variant="small" color="medium" sx={{ fontWeight: 500 }}>{t('Offer Price')}</Text>
                                        <Text as="p" variant="price" color="white" sx={{ fontSize: [3, 5] }}>A${deal?.offer_price}</Text>
                                        <Text as="p" variant="small" color="medium" sx={{ fontSize: '15px' }}>{deal?.stage?.name === 'Fund' ? t('per unit') : t('per share')}</Text>
                                    </Box>
                                    <Box sx={{ flex: '50%' }}>
                                        <Text as="p" variant="small" color="medium" sx={{ fontWeight: 500 }}>{t('Offer Size')}</Text>
                                        <Text as="p" variant="price" color="white" sx={{ fontSize: [3, 5] }}>A${deal?.offer_size}</Text>
                                    </Box>
                                </>
                            )}

                            {dealDetail.alternateInvestment && (
                                <Box sx={{ flex: '50%' }}>
                                    <Text as="p" variant="small" color="medium" sx={{ fontWeight: 500 }}>{t('Minimum Investment')}</Text>
                                    <Text as="p" variant="price" color="white" sx={{ fontSize: [3, 5] }}>A${dealOfferPriceFormatting(deal?.min_bid_price)}</Text>
                                </Box>
                            )}
                        </Box>

                        {isMobile && (<Box mt={4}>{renderButtons()}</Box>)}
                    </Box>
                    <Box
                        sx={{
                            bg: ['black', null, null, 'white'],
                            width: ['100%', null, null, 'calc(100% - 400px)'],
                            position: 'relative',
                            px: 4,
                            pb: [3, null, null, 4],
                            pt: ['100px', null, null, 5],
                            order: [1, null, null, 2],
                            display: ['flex', null, null, 'block'],
                            alignItems: 'center',
                            flex: 1
                        }}
                    >
                        <Close onClick={closeModal} sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            zIndex: 10,
                            color: 'tealDark',
                            '&:hover': {
                                color: 'tealLight'
                            }
                        }} />

                        <Flex sx={{
                            width: '100%',
                            height: ['auto', null, null, '100%'],
                            justifyContent: 'center',
                            position: 'relative'
                        }}>
                            {content()}
                        </Flex>
                    </Box>
                </Flex>
            </>
        </Modal>
        </>
    );
};
