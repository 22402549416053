import { DashboardLayout } from "~/Layouts";
import { Flex, Spinner } from "theme-ui";
import { connect } from "react-redux";
import { Container, PageBanner } from "~/Common";
import { BlogsGridWrapper } from "~/Grafa";
import { Heading } from "@theme-ui/components";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "hooks/useDocumentTitle";

const GrafaNews = ({ status }) => {
    const isLoading = status === 'loading';
    const { t } = useTranslation();
    useDocumentTitle(t('News'));

    return (
        <DashboardLayout p={0}>
            <PageBanner>
                <Heading as="h2" variant="page_title" sx={{
                    mt: '128px'
                }}>{`News`}</Heading>
            </PageBanner>
            {isLoading ? (
                <Flex sx={{
                    flexDirection: 'column'
                }}>
                    <Spinner variant="styles.spinner" mx={'auto'} my={6} />
                </Flex>
            ) : (
                <Container variant="layouts.default">
                    <BlogsGridWrapper />
                </Container>

            )}
        </DashboardLayout>
    );
}

const mapStateToProps = (state) => {
    return {
        status: state.articles.current?.status
    }
}

export default connect(mapStateToProps)(GrafaNews);