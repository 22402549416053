import styled from '@emotion/styled';
import { theme } from 'app/theme';

export const PageBanner = styled('div')`
    margin-top: -${theme.styles.header.height};
    padding-top: ${theme.styles.header.height};
    padding-bottom: 45px;
    overflow: hidden;
    min-height: 220px;
    position: relative;
    background: #3F6D6A;
    color: #fff;
`;
