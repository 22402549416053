import { configureStore } from '@reduxjs/toolkit';
import companyReducer from 'features/company/companySlice';
import dealReducer from 'features/deal/dealSlice';
import invitesReducer from 'features/invites/invitesSlice';
import notificationsReducer from 'features/notifications/notificationsSlice';
import portfolioReducer from 'features/portfolio/portfolioSlice';
import themeReducer from 'features/theme/themeSlice';
import userReducer from 'features/auth/authSlice';
import watchlistReducer from 'features/watchlist/watchlistSlice';
import articleReducer from 'features/article/articleSlice';
import loungeReducer from 'features/lounge/loungeSlice';

export const store = configureStore({
    reducer: {
        companies: companyReducer,
        deals: dealReducer,
        invites: invitesReducer,
        notifications: notificationsReducer,
        portfolio: portfolioReducer,
        theme: themeReducer,
        user: userReducer,
        watchlist: watchlistReducer,
        articles: articleReducer,
        lounge: loungeReducer,
    },
});
