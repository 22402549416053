import React, { useState, forwardRef } from 'react';
import { useField } from 'formik';
import { Box } from 'theme-ui';
import { Icon } from 'assets/Icon';
import { InputStyled } from './styled';
import { Label } from '~/Forms/Label';
import { useDebounce } from 'hooks/useDebounce';
import { theme } from 'app/theme';
import NumberFormat from 'react-number-format';

// forward ref so we can focus with parent components
export const CurrencyField = forwardRef(
    (
        {
            label,
            name,
            apiName,
            type,
            className,
            placeholder,
            disabled,
            required = true,
            readonly,
            validIndicator,
            validationError,
            showLabel,
            onChangeOverride,
            onChange,
            textType,
            variant,
            size = 'default',
            sx,
            decimalScale = 2,
            prefix = '$',
            icon,
            maxValue,
            ...props
        },
        ref
    ) => {
        const [focused, setFocused] = useState(false);
        const [field, meta, { setValue, setTouched }] = useField(name);
        const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);
        const fieldFocusedOrFilled = focused || meta.value !== '' || placeholder !== '';

        const fieldProps = { ...field, ...props };
        const sizes = theme?.forms?.input?.sizes ?? [];

        const isInvalid = (required && meta.value === '') || meta.error !== undefined;

        const withValueLimit = ({ value }) => maxValue ? value <= maxValue : true;

        return (
            <Box sx={{ position: 'relative' }}>
                <Label
                    label={label}
                    showLabel={showLabel}
                    fieldFocusedOrFilled={fieldFocusedOrFilled}
                    focused={focused}
                    field={field}
                    placeholder={placeholder}
                    meta={meta}
                    validationError={validationError}
                />

                <NumberFormat
                    prefix={prefix}
                    customInput={InputStyled}
                    thousandSeparator={true}
                    {...fieldProps}
                    {...props}
                    type={textType || type}
                    placeholder={placeholder}
                    disabled={readonly || disabled}
                    ref={ref}
                    variant={variant}
                    invalid={meta.touched && meta.error}
                    showLabel={showLabel}
                    onChange={(event) => {
                        const { value } = event.target;
                        if (onChangeOverride) {
                            onChangeOverride(event);
                        } else {
                            setValue(value);

                            onChange &&
                                debouncedSave({
                                    name: apiName,
                                    value: value === null ? '' : value,
                                });
                        }
                    }}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                        setTouched(field.name, true);
                        setFocused(false);
                    }}
                    sx={{
                        ...sizes[size],
                        ...sx
                    }}
                    decimalScale={decimalScale}
                    isAllowed={withValueLimit}
                />

                {validIndicator === true && isInvalid && !focused && (
                    <Icon
                        icon="cross"
                        color="error"
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 80
                        }}
                    />
                )}

                {icon && <Icon icon={icon} color={focused ? 'tealDark' : 'medium'} sx={{
                    opacity: fieldFocusedOrFilled ? 1 : 0,
                    position: 'absolute',
                    top: '50%',
                    left: '10px',
                    pointerEvents: 'none',
                    width: '1.8rem',
                    transform: 'translateY(-50%)'
                }} />}
            </Box>
        );
    }
);

CurrencyField.defaultProps = {
    validationError: true,
    placeholder: '',
    showLabel: true,
    type: 'text',
    validIndicator: true,
    required: false,
    disabled: false
};
