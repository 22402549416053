import { Box, Link } from '@theme-ui/components';
import {
    CTABanner,
    Header,
    KeyboardNavigation,
    Footer
} from '~/Common';
import { useDispatch } from 'react-redux';
import { setShowSearch } from 'features/theme/themeSlice';

export const DashboardLayout = ({ children, sx, ...props }) => {
    const dispatch = useDispatch();

    return (
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            mt: '88px'
        }}>
            <KeyboardNavigation>
                <Link href="#main-nav">Skip to navigation</Link>
                <Link href="#content">Skip to content</Link>
                <Link href="#search" onClick={() => dispatch(setShowSearch(true))}>Open search</Link>
                <Link href="#user-actions">Skip to user actions</Link>
            </KeyboardNavigation>

            <Header />

            <Box
                p={5}
                {...props}
                id="content"
                sx={{
                    flex: 1,
                    ...sx
                }}
            >
                {children}
            </Box>

            <CTABanner />

            <Footer />
        </Box>
    );
};
