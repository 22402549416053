import {
    Box,
    Close,
    Heading,
    Text
} from '@theme-ui/components'
import {
    Button
} from '~/Common'
import { useDispatch } from 'react-redux';
import { deleteProfile, setUser } from 'features/auth/authSlice'
import { confirmAlert } from 'react-confirm-alert'
import { useHistory } from 'react-router-dom'
import { ThemeProvider } from 'theme-ui'
import { theme } from 'app/theme'
import { useTranslation } from 'react-i18next';
import { Icon } from 'assets/Icon';
import { toast } from 'utils/toast'

export const ProfileDelete = ({ uuid, size, label, sx, ...props }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const removeProfileConfirmed = () => {
        deleteProfile(uuid).then((response) => {
            toast.success(t('settings.profile.delete.success'), {
                toastId: 'profile-success',
            });
            dispatch(setUser(response));
            history.push('/settings/entities');

        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.profile.delete.error'), {
                toastId: 'profile-error',
            })
        });
    };

    const removeProfile = (e, uuid) => {
        e.preventDefault();

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ThemeProvider theme={theme}>
                        <Box bg="white" px={5} py={4} sx={{
                            maxWidth: '600px',
                            width: '100%',
                            textAlign: 'center',
                            boxShadow: '0 20px 75px rgb(0 0 0 / 23%)',
                            position: 'relative'
                        }}>
                            <Close onClick={onClose} sx={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px'
                            }}></Close>

                            <Heading as="h2" variant="h2" mb={3}>{t('Are you sure?')}</Heading>
                            <Text as="p" mb={3}>{t('Are you sure to want to remove this entity?')}</Text>
                            <Text as="p" mb={3}>{t('This can\'t be undone')}</Text>
                            <Button variant="primary" mr={3} onClick={() => {
                                removeProfileConfirmed();
                                onClose();
                            }}
                            >
                                {t('Yes, please delete it!')}
                            </Button>
                            <Button variant="link" onClick={onClose}>
                                {t('Cancel')}
                            </Button>
                        </Box>
                    </ThemeProvider>
                );
            }
        });
    };

    return <Button
            onClick={(e) => removeProfile(e, uuid)}
            size={size ?? 'small'}
            sx={sx}
            title={t(label ?? 'Delete Entity')}
            {...props}>
                <Icon icon="trash" size="18px" color="#fff" />
                <span className="sr-only">{t(label ?? 'Delete Entity')}</span>
            </Button>
};
