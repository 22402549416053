import { useRef, useState, useEffect } from 'react'
import { SettingsLayout } from '~/Layouts';
import {
    ProfileDelete,
    SaveButtons,
    Tooltip,
    Accordion,
    Alert,
    Tabs,
} from '~/Common';
import {
    Box,
    Flex,
    Grid,
    Heading,
    Link,
    Spinner,
    Text,
    Button
} from '@theme-ui/components';
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group'
import { Field, Submit } from '~/Forms'
import { FieldArray, Form, Formik } from 'formik'
import { toast } from 'utils/toast'
import moment from 'moment-timezone'
import { DATE_FORMAT } from 'app/constants'
import { updateProfile, setUser, storeProfileUpload } from 'features/auth/authSlice'
import { getProfileOptionsAsync, utilStateByCountry, getCountriesAsync } from 'features/theme/themeSlice'
import { Yup, addressValidationNullable } from 'utils/validationRules'
import { Icon } from 'assets/Icon';
import { formatBankAccount, getBankDetailsText, getNestedErrors, toTitleCase } from 'utils/helpers';
import { Themed } from 'theme-ui';
import { TabGroup } from '~/Common/Tabs';

const mapStateToProps = (state) => {
    return { user: state.user?.user, userLoaded: state.user.status === 'idle', theme: state.theme }
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    abn: Yup.string().abnNumber().nullable(),
    acn: Yup.string().acnNumber().nullable(),
    source_of_wealth: Yup.string().nullable(),
    company_type: Yup.string().nullable(),
    incorporation_country: Yup.string().nullable(),
    incorporation_at: Yup.date().transform((val, origin) => new Date(moment(origin).format('YYYY-MM-DD'))).nullable(),
    industry_code: Yup.string().nullable(),
    tax_number: Yup.string().taxNumber().nullable(),
    contact_email: Yup.string().email().nullable(),
    contact_phone: Yup.string().nullable(),
    address: addressValidationNullable,
    principle_address: addressValidationNullable,
    applicants: Yup.array().of(
        Yup.object().shape({
            role: Yup.string().nullable(),
            title: Yup.string().nullable(),
            first_name: Yup.string().nullable(),
            middle_names: Yup.string().nullable(),
            last_name: Yup.string().nullable(),
            mobile: Yup.string().nullable(),
            email: Yup.string().email('must be a valid email address').nullable(),
            dob: Yup.string().test(
                'dob',
                'applicant must be at least 18',
                (value) => {
                    return (
                        value === undefined || moment().diff(moment(value), 'years') >= 18
                    );
                }
            ).nullable(),
            tax_number: Yup.string().taxNumber().nullable(),
            tax_country: Yup.string().nullable(),
            citizenship_country: Yup.string().nullable(),
            county_of_birth: Yup.string().nullable(),
            macquarie_occupation_type: Yup.string().nullable(),
            address: addressValidationNullable,
            driver_licence: Yup.object().shape({
                state: Yup.string().nullable(),
                number: Yup.string().nullable().trim().matches(/^[a-zA-Z0-9]{1,10}$/, 'Please enter a valid Australian Driver\'s Licence Number'),
                cardnumber: Yup.string().nullable().trim()
            })
        })
    )
});

const SettingsProfilesUpdateCompany = ({ user, userLoaded, theme }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const nodeRef = useRef(null);
    const { uuid } = useParams();
    const history = useHistory();
    const [profile, setProfile] = useState(null);
    const [profileOptions, setProfileOptions] = useState(theme.profile.options ?? []);
    const [countries, setCountries] = useState(theme.countries.options ?? []);
    const [occupationCategories, setOccupationCategories] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [showUploadCert, setShowUploadCert] = useState(false);
    const [certUploadProgress, setCertUploadProgress] = useState(0);
    const [isLocked, setIsLocked] = useState(false);
    const isVerified = profile?.verified !== false;
    const [selectedTab, setSelectedTab] = useState('profile')

    const tabs = [
        {
            key: 'profile',
            name: 'Company Info',
            onClick: () => setSelectedTab('profile'),
        },
        {
            key: 'certificate',
            name: 's708 Certificate',
            onClick: () => setSelectedTab('certificate'),
        },
        {
            key: 'ownership',
            name: 'Directors & Beneficial Ownership',
            onClick: () => setSelectedTab('ownership'),
        },
    ]

    moment.tz.setDefault(user?.timezone);

    const handleSubmit = ( values, actions ) => {
        let formParams = {
            name: values.name,
            abn: values?.abn ?? null,
            acn: values?.acn ?? null,
            source_of_wealth: values?.source_of_wealth ?? null,
            company_type: values?.company_type ?? null,
            incorporation_country: values?.incorporation_country ?? null,
            incorporation_at: values?.incorporation_at ?? null,
            industry_code: values?.industry_code ?? null,
            tax_number: values?.tax_number ?? null,
            contact_email: values?.contact_email ?? null,
            contact_phone: values?.contact_phone ?? null,
            confirm: values?.confirm ?? undefined,
            applicant_is_director: Boolean(values?.applicant_is_director),
            applicant_is_beneficial_owner: Boolean(values?.applicant_is_beneficial_owner),
            address: {
                unit_number: values?.address?.unit_number ?? null,
                street_number: values?.address?.street_number ?? null,
                street_name: values?.address?.street_name ?? null,
                street_type: values?.address?.street_type ?? null,
                address2: values?.address?.address2 ?? null,
                state: values?.address?.state ?? null,
                suburb: values?.address?.suburb ?? null,
                postcode: values?.address?.postcode ?? null,
                country: 'AU'
            },
            principle_address: {
                unit_number: values?.principle_address?.unit_number ?? null,
                street_number: values?.principle_address?.street_number ?? null,
                street_name: values?.principle_address?.street_name ?? null,
                street_type: values?.principle_address?.street_type ?? null,
                address2: values?.principle_address?.address2 ?? null,
                state: values?.principle_address?.state ?? null,
                suburb: values?.principle_address?.suburb ?? null,
                postcode: values?.principle_address?.postcode ?? null,
                country: 'AU'
            },
            applicants: values.applicants ?? null
        };

        updateProfile(uuid, formParams).then((response) => {
            toast.success(t(values?.confirm ? 'settings.profile.submitted.success' : 'settings.profile.update.success'), {
                toastId: 'profile-success',
            });
            dispatch(setUser(response));
            actions.setSubmitting(false);
            setIsLocked(values?.confirm ? true : false);

        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(getNestedErrors(response.data.errors));
            toast.error(response?.data?.message || t('settings.profile.update.error'), {
                toastId: 'profile-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);

            // Failed? Let's save where they were up to
            if(values?.confirm){
                formParams.confirm = false;
                updateProfile(uuid, formParams).then((response) => {
                    toast.success(t('settings.profile.submitted.progress'), {
                        toastId: 'profile-progress',
                    });
                    dispatch(setUser(response));
                    actions.setSubmitting(false);
                });
            }
        });
    };

    useEffect(() => {
        utilStateByCountry('AU').then((response) => {
            setStateOptions(response?.data);
        }).catch(({ response }) => {
            setStateOptions(null);
        });
    }, []);

    useEffect(() => {
        if(theme.profile.status === 'loading'){
            dispatch(getProfileOptionsAsync());
        }
        if(theme.countries.status === 'loading'){
            dispatch(getCountriesAsync());
        }

        if(userLoaded && theme.profile.status === 'attempted' && theme.countries.status === 'attempted'){
            const newProfile = user.profiles.filter((p) => p.uuid === uuid)?.[0] ?? false;

            if (newProfile) {
                setLoading(false);
                setProfile(newProfile);
                setProfileOptions(theme.profile.options);
                setCountries(theme.countries.options);
                setIsLocked(Boolean(newProfile?.locked_at !== null));

                if (Array.isArray(newProfile.beneficials) && newProfile.beneficials.length) {
                    const temp = [];
                    newProfile.beneficials.forEach((applicant, index) => {
                        temp.push(theme.profile.options.macquarieOccupations[applicant.macquarie_occupation_type] ?? []);
                    });
                    setOccupationCategories(temp);
                }
            } else {
                history.push('/settings/entities');
            }
        }
    }, [userLoaded, theme, dispatch, user, history, uuid]);

    const createApplicant = (role = 'DIRECTOR', values, push) => {
        const count = values.applicants.length;
        updateOccupationCategories(null, count);
        push({
            role: role,
            title: '',
            first_name: '',
            middle_names: '',
            last_name: '',
            mobile: '',
            email: '',
            dob: '',
            tax_number: '',
            tax_country: 'AU',
            citizenship_country: 'AU',
            county_of_birth: 'AU',
            macquarie_occupation_type: '',
            address: {
                unit_number: '',
                street_number: '',
                street_name: '',
                street_type: '',
                address2: '',
                state: '',
                suburb: '',
                postcode: ''
            },
            driver_licence: {
                state: '',
                number: '',
                cardnumber: ''
            }
        });
    };

    const updateOccupationCategories = (e, index) => {
        const temp = occupationCategories;
        temp[index] = theme.profile.options.macquarieOccupations[e?.value] ?? [];
        setOccupationCategories(temp);
    };

    const onUploadProgress = (p) => {
        setCertUploadProgress((p.loaded / p.total) * 100);
    };

    const handleUploadCertificate = ({ name, value, setSubmitting, setStatus }) => {
        let formdata = new FormData();
        formdata.append(name, value);

        setCertUploadProgress(0);

        storeProfileUpload(profile?.uuid, 'certificate', formdata, onUploadProgress).then((response) => {
            toast.success(t('settings.certificate.success'), {
                toastId: 'certificate-success',
            });
            dispatch(setUser(response));

            setTimeout(() => setShowUploadCert(false), 750);
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.certificate.error'), {
                toastId: 'certificate-error',
            });
            setStatus('api_error');

            setTimeout(() => setShowUploadCert(false), 750);
        });
    };

    return (
        <SettingsLayout>
            {loading ? <Spinner variant="styles.spinner" m={'auto'} /> : (
                <CSSTransition
                    nodeRef={nodeRef}
                    in={true}
                    timeout={500}
                    classNames="fade"
                    unmountOnExit
                    appear
                >
                    <Box ref={nodeRef}>
                        <Flex sx={{ alignItems: 'center', justifyContent: 'flex-start' }} mb={4}>
                            <Box>
                                <Heading as="h2" variant="h2" mb={0}>
                                    {t('Company Entity')}

                                    {isVerified ? (
                                        <Tooltip text="Verified" sx={{ height: '20px' }} ml={2}>
                                            <Icon icon="verified" size="18" />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip text="Requires Verification" sx={{ height: '20px' }} ml={2}>
                                            <Icon size="18" icon="pending" color="warning" />
                                        </Tooltip>
                                    )}
                                </Heading>
                                {(profile?.bank_account_bsb && profile?.bank_account_number) && (
                                    <Text as="p" variant="caption" sx={{ color: 'dark', fontSize: 11 }}>
                                        {`${getBankDetailsText(profile)} ${formatBankAccount(profile?.bank_account_bsb, profile?.bank_account_number)}`}
                                    </Text>
                                )}
                                {profile?.hin_number && (
                                    <Text as="p" variant="caption" sx={{ color: 'dark', fontSize: 11 }}>
                                        {`${t('HIN:')} ${profile?.hin_number}`}
                                    </Text>
                                )}
                            </Box>
                            {!profile.verified && <ProfileDelete uuid={profile?.uuid} ml={'auto'} />}
                        </Flex>

                        {isLocked ? (
                            <Alert mb={4} variant="info" isCloseable={false}>
                                {t('settings.profile.locked')}
                            </Alert>
                        ) : (
                            <>
                            {!profile.submitted_at &&
                                <>
                                    <Heading as="h3" variant="h4" mb={5}>
                                        {t('settings.profile.entityIntroduction')}
                                    </Heading>
                                </>
                            }
                            </>
                        )}

                        <Box mb={4} sx={{
                            width: '100%',
                            maxWidth: '760px'
                        }}>
                            <Text mb={4} as="p">{t('fieldsRequired')}</Text>

                            <Formik
                                initialValues={{
                                    name: profile?.name ?? '',
                                    abn: profile?.abn ?? '',
                                    acn: profile?.acn ?? '',
                                    source_of_wealth: profile?.source_of_wealth ?? (user?.individual?.source_of_wealth ?? ''),
                                    company_type: profile?.company_type ?? '',
                                    incorporation_country: profile?.incorporation_country ?? 'AU',
                                    incorporation_at: profile?.incorporation_at ?? '',
                                    industry_code: profile?.industry_code ?? '',
                                    tax_number: profile?.tax_number ?? '',
                                    contact_email: profile?.contact_email ?? '',
                                    contact_phone: profile?.contact_phone ?? '',
                                    applicant_is_director: Boolean(profile?.applicant_is_director),
                                    applicant_is_beneficial_owner: Boolean(profile?.applicant_is_beneficial_owner),
                                    address: {
                                        unit_number: profile?.address?.unit_number ?? '',
                                        street_number: profile?.address?.street_number ?? '',
                                        street_name: profile?.address?.street_name ?? '',
                                        street_type: profile?.address?.street_type ?? '',
                                        address2: profile?.address?.address2 ?? '',
                                        state: profile?.address?.state ?? '',
                                        suburb: profile?.address?.suburb ?? '',
                                        postcode: profile?.address?.postcode ?? ''
                                    },
                                    principle_address: {
                                        unit_number: profile?.principle_address?.unit_number ?? '',
                                        street_number: profile?.principle_address?.street_number ?? '',
                                        street_name: profile?.principle_address?.street_name ?? '',
                                        street_type: profile?.principle_address?.street_type ?? '',
                                        address2: profile?.principle_address?.address2 ?? '',
                                        state: profile?.principle_address?.state ?? '',
                                        suburb: profile?.principle_address?.suburb ?? '',
                                        postcode: profile?.principle_address?.postcode ?? '',
                                    },
                                    applicants: profile?.beneficials ?? [],
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, setStatus, setFieldValue, submitForm, errors }) => {
                                    const hasApplicants = Boolean(Array.isArray(values.applicants) && values.applicants.length > 0);

                                    return (
                                        <Form>
                                            <Tabs
                                                variant="underline"
                                                wrap={false}
                                                tabs={tabs}
                                                selected={selectedTab}
                                                errors={errors}
                                            >
                                                <TabGroup name="profile">
                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr'],
                                                    }}>
                                                        <Field
                                                            label="Company Name *"
                                                            name="name"
                                                            autocomplete="organization"
                                                        />
                                                    </Grid>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
                                                    }}>
                                                        <Field
                                                            label="ABN"
                                                            name="abn"
                                                        />

                                                        <Field
                                                            label="ACN *"
                                                            name="acn"
                                                        />

                                                        <Field
                                                            label="Tax Number"
                                                            name="tax_number"
                                                        />
                                                    </Grid>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                        width: '100%'
                                                    }}>
                                                        {profileOptions?.sourceOfWealth.length > 0 &&
                                                            <Field
                                                                label="Source Of Funds *"
                                                                placeholder="Select source of funds"
                                                                name="source_of_wealth"
                                                                field="searchable-select"
                                                                options={profileOptions.sourceOfWealth}
                                                            />
                                                        }
                                                    </Grid>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                    }}>
                                                        <Field
                                                            label="Industry *"
                                                            placeholder="Select industry code"
                                                            name="industry_code"
                                                            field="searchable-select"
                                                            options={profileOptions.industryCodes}
                                                        />

                                                        <Field
                                                            label="Company Type *"
                                                            placeholder="Select company type"
                                                            name="company_type"
                                                            field="select"
                                                            options={profileOptions.companyTypes}
                                                        />
                                                    </Grid>

                                                    <Box sx={{ mb: 4, fontSize: '13px', pt: 1 }}>
                                                        <Text sx={{ color: 'dark' }}>{t('settings.profile.industryReason')}</Text>
                                                    </Box>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                    }}>
                                                        <Field
                                                            label="Country of Incorporation *"
                                                            placeholder="Select country"
                                                            name="incorporation_country"
                                                            field="searchable-select"
                                                            options={countries}
                                                            disabled
                                                            required={true}
                                                        />

                                                        <Field
                                                            label="Incorporation Date"
                                                            name="incorporation_at"
                                                            field="date"
                                                            required={true}
                                                        />
                                                    </Grid>


                                                    <Grid mb={4} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                    }}>
                                                        <Field
                                                            label="Contact Email *"
                                                            name="contact_email"
                                                        />

                                                        <Field
                                                            label="Contact Phone *"
                                                            name="contact_phone"
                                                        />
                                                    </Grid>

                                                    <Heading as="h3" variant="settings_heading" mb={3}>{t('Registered Address')}</Heading>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            field="google_places"
                                                            label="Search for address..."
                                                            name="address.address"
                                                            setFieldValue={setFieldValue}
                                                            fieldNames={{
                                                                street_number: 'address.street_number',
                                                                street_name: 'address.street_name',
                                                                street_type: 'address.street_type',
                                                                suburb: 'address.suburb',
                                                                state: 'address.state',
                                                                postcode: 'address.postcode',
                                                                country: 'address.country'
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', '1fr 1fr 2fr 1fr'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            label="Unit Number"
                                                            name="address.unit_number"
                                                        />
                                                        <Field
                                                            label="Street Number"
                                                            name="address.street_number"
                                                        />
                                                        <Field
                                                            label="Street Name *"
                                                            name="address.street_name"
                                                        />
                                                        <Field
                                                            label="Street Type"
                                                            name="address.street_type"
                                                        />
                                                    </Grid>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            label="Address second line"
                                                            name="address.address2"
                                                        />
                                                    </Grid>

                                                    <Grid mb={4} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            label="Suburb *"
                                                            name="address.suburb"
                                                        />

                                                        <Field
                                                            field={stateOptions ? 'select' : 'text'}
                                                            label="State *"
                                                            placeholder="Select state"
                                                            name="address.state"
                                                            options={stateOptions}
                                                        />

                                                        <Field
                                                            label="Postcode *"
                                                            name="address.postcode"
                                                        />
                                                    </Grid>

                                                    <Heading as="h3" variant="settings_heading" mb={3}>{t('Principal Place of Business')}</Heading>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            field="google_places"
                                                            label="Search for address..."
                                                            name="principle_address.address"
                                                            setFieldValue={setFieldValue}
                                                            fieldNames={{
                                                                street_number: 'principle_address.street_number',
                                                                street_name: 'principle_address.street_name',
                                                                street_type: 'principle_address.street_type',
                                                                suburb: 'principle_address.suburb',
                                                                state: 'principle_address.state',
                                                                postcode: 'principle_address.postcode',
                                                                country: 'principle_address.country'
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', '1fr 1fr 2fr 1fr'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            label="Unit Number"
                                                            name="principle_address.unit_number"
                                                        />
                                                        <Field
                                                            label="Street Number"
                                                            name="principle_address.street_number"
                                                        />
                                                        <Field
                                                            label="Street Name *"
                                                            name="principle_address.street_name"
                                                        />
                                                        <Field
                                                            label="Street Type"
                                                            name="principle_address.street_type"
                                                        />
                                                    </Grid>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            label="Address second line"
                                                            name="principle_address.address2"
                                                        />
                                                    </Grid>

                                                    <Grid mb={4} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
                                                        width: '100%'
                                                    }}>
                                                        <Field
                                                            label="Suburb *"
                                                            name="principle_address.suburb"
                                                        />

                                                        <Field
                                                            field={stateOptions ? 'select' : 'text'}
                                                            label="State *"
                                                            placeholder="Select state"
                                                            name="principle_address.state"
                                                            options={stateOptions}
                                                        />

                                                        <Field
                                                            label="Postcode *"
                                                            name="principle_address.postcode"
                                                        />
                                                    </Grid>
                                                </TabGroup>
                                                <TabGroup name="certificate">
                                                    <Heading as="h3" variant="settings_heading" mb={3}>{t(profile?.certificate ? 'Update s708 Certificate' : 'Upload s708 Certificate')}</Heading>
                                                    {profile?.certificate_expiry_at && <Text as="p" variant="body_large" color="slate" mb={4}>
                                                        {t('settings.account.certificateExpiryWarning', {
                                                            date: moment(profile?.certificate_expiry_at).format(DATE_FORMAT.date_format_friendly)
                                                        })}
                                                    </Text>}

                                                    {theme.settings.certificate_template && (
                                                        <Box mb={3} sx={{
                                                            color: 'dark',
                                                            fontSize: '13px',
                                                            '& a': {
                                                                color: 'tealDark'
                                                            }
                                                        }}>
                                                            {t('Need help?')} <Link href={theme.settings.certificate_template} target="_blank" variant="light">{t('Download Certificate Template')}</Link>
                                                        </Box>
                                                    )}

                                                    <Box mb={4}>
                                                        {showUploadCert ? (
                                                            <>
                                                                <Field
                                                                    field="upload"
                                                                    name="certificate"
                                                                    label="Upload 708 certificate"
                                                                    showLabel={false}
                                                                    uploadProgress={certUploadProgress}
                                                                    onChange={(props) => handleUploadCertificate({ setStatus, ...props })}
                                                                />
                                                                <Box mb={4}>
                                                                    <Link to="#cancel" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setShowUploadCert(false)
                                                                    }}>{t('Cancel certificate upload')}</Link>
                                                                </Box>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Flex sx={{ alignItems: 'center', mb: 2, gridGap: 3 }}>
                                                                    <Button variant="light" onClick={() => setShowUploadCert(true)}>{t(profile.certificate ? 'Update Certificate' : 'Supply Certificate')}</Button>
                                                                    {profile?.certificate &&
                                                                        <Box variant="styles.captionWrapper">
                                                                            <Flex sx={{ alignItems: 'center' }}>
                                                                                <Icon icon="tick" color="tealDark" width="auto" height="9px" sx={{ mr: 2 }} />
                                                                                <Text variant="caption">{t('Certificate provided')}</Text>
                                                                            </Flex>
                                                                        </Box>
                                                                    }
                                                                </Flex>
                                                            </>
                                                        )}
                                                    </Box>
                                                </TabGroup>
                                                <TabGroup name="ownership">
                                                    <Heading as="h3" variant="settings_heading" mb={3}>{t('Directors & Beneficial Ownership')}</Heading>
                                                    <Text mb={2} as="p">There must be at least 1 Director and 1 Beneficial Owner.</Text>

                                                    <Grid mb={1} sx={{
                                                        gridGap: [2, 3],
                                                        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                    }}>
                                                        <Field
                                                            label="I am a Director"
                                                            name="applicant_is_director"
                                                            field="checkbox"
                                                            value={true}
                                                            validIndicator={false}
                                                            defaultChecked={profile?.applicant_is_director}
                                                        />
                                                        <Field
                                                            label="I am a Beneficial Owner"
                                                            name="applicant_is_beneficial_owner"
                                                            field="checkbox"
                                                            value={true}
                                                            validIndicator={false}
                                                            defaultChecked={profile?.applicant_is_beneficial_owner}
                                                        />
                                                    </Grid>

                                                    <Text mb={2} as="p">{t('settings.profile.applicantsReasonCompany')}</Text>

                                                    {hasApplicants && <Alert mb={4} variant="info" isCloseable={false}>{t('settings.profile.applicantsLegalName')}</Alert>}
                                                    <FieldArray name="applicants">
                                                        {({ remove, push }) => (
                                                            <>
                                                            {hasApplicants
                                                                ? values.applicants.map((applicant, index) => (
                                                                    <Accordion
                                                                        title={toTitleCase(`${applicant.first_name ?? ''} ${applicant.last_name ?? ''}`.trim())}
                                                                        isOpen={true}
                                                                        emptyTitle={t('New Applicant')}
                                                                        key={`profile.${profile?.uuid}.applicants.${index}`}
                                                                    >
                                                                        {profileOptions?.roleTypesCompany.length > 0 &&
                                                                            <Field
                                                                                label="Role *"
                                                                                placeholder="Select role"
                                                                                name={`applicants.${index}.role`}
                                                                                field="searchable-select"
                                                                                options={profileOptions.roleTypesCompany}
                                                                            />
                                                                        }

                                                                        <Grid sx={{
                                                                            gridGap: [2, 3],
                                                                            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                                            width: '100%'
                                                                        }}>
                                                                            <Field
                                                                                label="Title *"
                                                                                placeholder="Select title"
                                                                                name={`applicants.${index}.title`}
                                                                                field="searchable-select"
                                                                                options={profileOptions.titles}
                                                                                required={true}
                                                                            />
                                                                            <Field
                                                                                label="First Name *"
                                                                                name={`applicants.${index}.first_name`}
                                                                                updateOnBlur={true}
                                                                            />
                                                                        </Grid>

                                                                        <Grid sx={{
                                                                            gridGap: [2, 3],
                                                                            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                                            width: '100%'
                                                                        }}>
                                                                            <Field
                                                                                label="Middle Names"
                                                                                name={`applicants.${index}.middle_names`}
                                                                                updateOnBlur={true}
                                                                            />
                                                                            <Field
                                                                                label="Last Name *"
                                                                                name={`applicants.${index}.last_name`}
                                                                                updateOnBlur={true}
                                                                            />
                                                                        </Grid>

                                                                        <Grid sx={{
                                                                            gridGap: [2, 3],
                                                                            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                                            width: '100%'
                                                                        }}>
                                                                            <Field
                                                                                label="Mobile *"
                                                                                name={`applicants.${index}.mobile`}
                                                                                field="phone"
                                                                                updateOnBlur={true}
                                                                            />
                                                                            <Field
                                                                                label="Email *"
                                                                                name={`applicants.${index}.email`}
                                                                                type="email"
                                                                                updateOnBlur={true}
                                                                            />
                                                                        </Grid>

                                                                        <Box sx={{ mb: 4, fontSize: '13px', pt: 1 }}>
                                                                            <Text sx={{ color: 'dark' }}>{t('settings.profile.phoneEmailReason')}</Text>
                                                                        </Box>

                                                                        <Grid mb={1} sx={{
                                                                            gridGap: [2, 3],
                                                                            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                                            width: '100%'
                                                                        }}>
                                                                            <Field
                                                                                label="Date of Birth *"
                                                                                name={`applicants.${index}.dob`}
                                                                                field="date"
                                                                            />

                                                                            {countries.length > 0 &&
                                                                            <>
                                                                                <Field
                                                                                    label="Country of citizenship *"
                                                                                    placeholder="Select country"
                                                                                    name={`applicants.${index}.citizenship_country`}
                                                                                    field="searchable-select"
                                                                                    options={countries}
                                                                                />

                                                                                <Field
                                                                                    label="Country of birth *"
                                                                                    placeholder="Select country"
                                                                                    name={`applicants.${index}.county_of_birth`}
                                                                                    field="searchable-select"
                                                                                    options={countries}
                                                                                />
                                                                            </>
                                                                            }
                                                                        </Grid>

                                                                        <Heading as="h3" variant="settings_heading" mb={3}>{t('Australian Tax File Number')}</Heading>
                                                                        <Grid sx={{
                                                                            gridGap: [2, 3],
                                                                            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                                            width: '100%'
                                                                        }}>
                                                                            <Field
                                                                                label="Australian tax file number"
                                                                                name={`applicants.${index}.tax_number`}
                                                                                updateOnBlur={true}
                                                                            />
                                                                        </Grid>

                                                                        <Box sx={{ mb: 4, fontSize: '13px', pt: 1 }}>
                                                                            <Text sx={{ color: 'dark' }}>{t('settings.profile.taxFileNumberReason')}</Text>
                                                                        </Box>

                                                                        <Heading as="h3" variant="settings_heading" mb={3}>{t('Occupation Details')}</Heading>

                                                                        <Grid mb={1} sx={{
                                                                            gridGap: [2, 3],
                                                                            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                                            width: '100%'
                                                                        }}>
                                                                            {Boolean(Array.isArray(profileOptions?.macquarieOccupationTypes) && profileOptions?.macquarieOccupationTypes.length > 0) &&
                                                                                <Field
                                                                                    label="Occupation type *"
                                                                                    placeholder="Select occupation type"
                                                                                    name={`applicants.${index}.macquarie_occupation_type`}
                                                                                    field="searchable-select"
                                                                                    onChange={(e) => {
                                                                                        updateOccupationCategories(e, index);
                                                                                    }}
                                                                                    options={profileOptions.macquarieOccupationTypes}
                                                                                />
                                                                            }
                                                                            {occupationCategories[index].length > 0 &&
                                                                                <Field
                                                                                    label="Occupation category *"
                                                                                    placeholder="Select occupation category"
                                                                                    name={`applicants.${index}.macquarie_occupation_category`}
                                                                                    field="searchable-select"
                                                                                    options={occupationCategories[index]}
                                                                                />
                                                                            }
                                                                        </Grid>

                                                                        <Heading as="h2" variant="form_section" sx={{
                                                                            fontWeight: 700,
                                                                            fontSize: 2,
                                                                            my: 3,
                                                                        }}>
                                                                            {t('Address Details')}
                                                                        </Heading>

                                                                        <Field
                                                                            field="google_places"
                                                                            label="Search for address..."
                                                                            name={`applicants.${index}.address.address`}
                                                                            fieldNamePrefix={`applicants.${index}.address.`}
                                                                            setFieldValue={setFieldValue}
                                                                        />

                                                                        <Box>
                                                                            <Grid sx={{
                                                                                gridGap: [0, 2],
                                                                                gridTemplateColumns: ['1fr', '1fr 1fr 2fr 1fr'],
                                                                                width: '100%'
                                                                            }}>
                                                                                <Field
                                                                                    label="Unit Number"
                                                                                    name={`applicants.${index}.address.unit_number`}
                                                                                    updateOnBlur={true}
                                                                                />
                                                                                <Field
                                                                                    label="Street Number"
                                                                                    name={`applicants.${index}.address.street_number`}
                                                                                    updateOnBlur={true}
                                                                                />

                                                                                <Field
                                                                                    label="Street Name *"
                                                                                    name={`applicants.${index}.address.street_name`}
                                                                                    updateOnBlur={true}
                                                                                />
                                                                                <Field
                                                                                    label="Street Type"
                                                                                    name={`applicants.${index}.address.street_type`}
                                                                                    updateOnBlur={true}
                                                                                />
                                                                            </Grid>

                                                                            <Field
                                                                                label="Address second line"
                                                                                name={`applicants.${index}.address.address2`}
                                                                                updateOnBlur={true}
                                                                            />

                                                                            <Grid sx={{
                                                                                gridGap: [0, 2],
                                                                                gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
                                                                                width: '100%'
                                                                            }}>
                                                                                <Field
                                                                                    label="Suburb *"
                                                                                    name={`applicants.${index}.address.suburb`}
                                                                                    updateOnBlur={true}
                                                                                />
                                                                                <Field
                                                                                    field={stateOptions ? 'select' : 'text'}
                                                                                    label="State *"
                                                                                    placeholder={stateOptions ? 'Select State' : ''}
                                                                                    name={`applicants.${index}.address.state`}
                                                                                    options={stateOptions}
                                                                                />
                                                                                <Field
                                                                                    label="Postcode *"
                                                                                    name={`applicants.${index}.address.postcode`}
                                                                                    updateOnBlur={true}
                                                                                />
                                                                            </Grid>

                                                                            <Heading as="h2" variant="form_section" sx={{
                                                                                fontWeight: 700,
                                                                                fontSize: 2,
                                                                                my: 3,
                                                                            }}>
                                                                                {t('Drivers Licence')}
                                                                            </Heading>

                                                                            {applicant.drivers_licence_provided_at ? (
                                                                                <Box variant="styles.captionWrapper" sx={{
                                                                                    mb: 4,
                                                                                }}>
                                                                                    <Icon icon="tick" color="tealDark" width="auto" height="9px" sx={{ mr: 2 }} />
                                                                                    <Text variant="caption" sx={{ fontSize: '13px', }}>{t('Drivers licence provided')}</Text>
                                                                                </Box>
                                                                            ) : (
                                                                                <>
                                                                                    <Grid sx={{
                                                                                        gridGap: [0, 2],
                                                                                        gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
                                                                                        width: '100%'
                                                                                    }}>
                                                                                        <Field
                                                                                            field={stateOptions ? 'select' : 'text'}
                                                                                            label="State *"
                                                                                            placeholder={stateOptions ? 'Select State' : ''}
                                                                                            name={`applicants.${index}.driver_licence.state`}
                                                                                            options={stateOptions}
                                                                                        />

                                                                                        <Field
                                                                                            label="Licence number *"
                                                                                            name={`applicants.${index}.driver_licence.number`}
                                                                                        />

                                                                                        <Field
                                                                                            label="Card number *"
                                                                                            name={`applicants.${index}.driver_licence.cardnumber`}
                                                                                        />
                                                                                    </Grid>

                                                                                    <Box sx={{
                                                                                        mb: 4,
                                                                                        fontSize: '13px',
                                                                                        color: 'dark',
                                                                                        '& a': {
                                                                                            color: 'tealDark'
                                                                                        }
                                                                                    }}>
                                                                                        {t('The unique 8-10 character card number.')} <Themed.a href="https://www.opy.com/au/how-to-find-your-licence-card-number/" target="_blank">See where this is for your state.</Themed.a>
                                                                                    </Box>
                                                                                </>
                                                                            )}
                                                                        </Box>
                                                                        {!isLocked && (
                                                                            <Button
                                                                                onClick={() => remove(index)}
                                                                                size="small"
                                                                                sx={{ py: 1, px: 2, fontSize: 0 }}
                                                                            >
                                                                                {t('Remove Applicant')}
                                                                            </Button>
                                                                        )}
                                                                    </Accordion>
                                                                ))
                                                                : (
                                                                    <Box mb={3}></Box>
                                                                )
                                                            }
                                                            <>
                                                            {!isLocked && (
                                                                <Box>
                                                                    <Button
                                                                        sx={{ display: 'inline-flex', alignItems: 'center', mr: 2 }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            createApplicant('DIRECTOR', values, push);
                                                                        }}
                                                                    >
                                                                        <Icon icon="plus" size="12" mr={2} />
                                                                        {t('Add Additional Director')}
                                                                    </Button>

                                                                    <Button
                                                                        sx={{ display: 'inline-flex', alignItems: 'center' }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            createApplicant('BENEFICIAL_OWNER', values, push);
                                                                        }}
                                                                    >
                                                                        <Icon icon="plus" size="12" mr={2} />
                                                                        {t('Add Additional Beneficial Owner')}
                                                                    </Button>
                                                                </Box>

                                                            )}
                                                            </>

                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </TabGroup>
                                            </Tabs>

                                            {!isLocked &&
                                                <SaveButtons>
                                                    <Submit
                                                        variant="primary"
                                                        text={t(!profile.submitted_at ? 'Save Submission' : 'Save Changes')}
                                                        tooltipDefaultText={t(!profile.submitted_at ? 'settings.profile.tooltips.save' : null)}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setFieldValue('confirm', false).then(() => submitForm());
                                                        }}
                                                    />

                                                    {!profile.submitted_at && (
                                                        <Submit
                                                            variant="primary"
                                                            type="button"
                                                            tooltipDefaultText={t('settings.profile.tooltips.submit')}
                                                            showErrorTooltip={true}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setFieldValue('confirm', true).then(() => submitForm());
                                                            }}
                                                        >
                                                            {t('Submit Application')}
                                                        </Submit>
                                                    )}
                                                    <Button
                                                        as={NavLink}
                                                        to="/portfolio"
                                                        variant="light"
                                                    >
                                                        {t('buttons.discard')}
                                                    </Button>
                                                </SaveButtons>
                                            }
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </Box>
                    </Box>
                </CSSTransition>
            )}
        </SettingsLayout>
    );
};

export default connect(mapStateToProps, null)(SettingsProfilesUpdateCompany);
