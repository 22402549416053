import { Box, Flex, Heading, Link as ThemeLink } from '@theme-ui/components';
import {
    AccountMenu,
    Container,
    Footer,
    Header,
    KeyboardNavigation,
    PageHeader
} from '~/Common';
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setShowSearch } from 'features/theme/themeSlice'
import { useResponsiveValue } from '@theme-ui/match-media';

export const SettingsLayout = ({ children, sx, ...props }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useResponsiveValue([true, true, true, false]);

    return (
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '60vh',
            marginTop: '90px'
        }}>
            <KeyboardNavigation>
                <ThemeLink href="#main-nav">Skip to navigation</ThemeLink>
                <ThemeLink href="#content">Skip to content</ThemeLink>
                <ThemeLink href="#search" onClick={() => dispatch(setShowSearch(true))}>Open search</ThemeLink>
                <ThemeLink href="#user-actions">Skip to user actions</ThemeLink>
            </KeyboardNavigation>

            <Header />

            <PageHeader />

            <Flex sx={{ flexDirection: ['column', null, null, null, 'row'], minHeight: [0, null, null, null, 'calc(100vh - 400px)'] }}>
                <Box px={[2,null,null,null,4]} sx={{
                    width: ['100%', null, null, null, '332px'],
                    minHeight: [0, null, null, null, '60vh'],
                    bg: 'white',
                    borderRight: '1px solid',
                    borderColor: ['transparent', null, null, null, 'lighter'],
                    paddingTop: [2, null, null, null, 5],
                }}>
                    <Container variant="layouts.default" px={[1,1,1,1,2]} py={[0, 0, 2]}>
                        {!isMobile && <Heading as="h1" variant="h3" mb={4}>{t('Settings')}</Heading>}
                        <AccountMenu nested={isMobile} />
                    </Container>
                </Box>
                <Box sx={{
                    width: ['100%', null, null, null, 'calc(100% - 332px)']
                }}>
                    <Container variant="layouts.default" sx={{
                        paddingTop: [2, null, null, null, 6],
                        marginLeft: [0, null, null, null, '45px'],
                        maxWidth: ['100%', null, null, null, '820px'],
                        width: '100%'
                    }}
                    {...props}
                    id="content"
                    >
                        {children}
                    </Container>
                </Box>
            </Flex>

            <Footer />
        </Box>
    )
};
