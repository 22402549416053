import { Icon } from "assets/Icon"
import { useTranslation } from "react-i18next"
import { Box, Text } from "theme-ui"
import { numberWithCommas } from "utils/helpers"
import { Tooltip } from "~/Common/Tooltip"

export const CoinBalance = ({ balance, expectedShares, totalShares, showInfo = true, small = false, ...props }) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignContent: 'center',
                p: '6px',
                border: '1px solid',
                borderColor: 'darker',
                borderRadius: '213px',
                minWidth: small ? null : '90px',
                textAlign: 'center',
                alignItems: 'center'
            }}
            {...props}
        >
            <Icon icon="coin" mr={2} size={small ? '20px' : '25px'} color="darker" />
            <Text
                as="p"
                color="darker"
                sx={{
                    flex: 1,
                    fontSize: small ? '16px' : '20px',
                    fontWeight: 400,
                    lineHeight: '1.29',
                    mr: balance >= 0 ? 2 : -1
                }}
            >
                {balance >= 0 ? numberWithCommas(balance) : ''}
            </Text>
            {showInfo && (
                <Tooltip text={(
                    <>
                        {totalShares ? <Text as="p" mb={2}>{t('Number of shares: {{shares}}', { shares: numberWithCommas(totalShares) })}</Text> : null}
                        <Box mb={2}>{t('liquidityDollars.tooltip')}</Box>

                        <Text as="p">{t('Should your bid be successful you will')}</Text>
                        <Text as="p">{t('receive {{shares}} shares and {{coins}} LQD', { shares: numberWithCommas(expectedShares), coins: numberWithCommas(balance) })}</Text>
                    </>
                 )} sx={{ display: 'inline-flex', alignItems: 'center' }} groupSx={{ height: '18px' }}>
                    <Icon icon="info" size="18" color="darker" sx={{ height: '18px' }} />
                </Tooltip>
            )}
        </Box>
    )
}
