import { useRef, useState, useEffect } from 'react'
import { SettingsLayout } from '~/Layouts';
import {
    Box,
    Flex,
    Grid,
    Heading,
    Spinner,
    Text
} from '@theme-ui/components';
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'utils/toast'
import { Icon } from 'assets/Icon'

import moment from 'moment-timezone'
import { Button } from '~/Common';
import { respondToInvitedBy, setInvitedBy } from 'features/invites/invitesSlice';
import { getUserAdvisersAsync } from 'features/auth/authSlice';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { user: state.user, invitesLoaded: state.invites?.invited_by_status === 'attempted', invites: state.invites }
}

const SettingsInviteApproval = ({ user, invitesLoaded, invites }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Account Invites'));
    const { uuid } = useParams();
    const [loading, setLoading] = useState(true);
    const [working, setWorking] = useState(false);
    const [invite, setInvite] = useState(null);
    const nodeRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    moment.tz.setDefault(user?.user?.timezone);

    const handleApproval = (e, answer) => {
        e.preventDefault();
        setWorking(true);
        setLoading(true);

        respondToInvitedBy(uuid, { action: answer }).then((response) => {
            toast.success(t(response.data?.message ?? 'notifications.invites.success', {
                answer: answer === 'deny' ? 'Denial' : 'Approval'
            }), {
                toastId: 'notifications-invites-success',
            });

            dispatch(setInvitedBy(response));
            dispatch(getUserAdvisersAsync());
            history.push('/settings/advisers');
        }).catch(({response}) => {
            toast.error(t('notifications.invites.error'), {
                toastId: 'notifications-invites-error',
            });
        })
    };

    useEffect(() => {
        if(invitesLoaded){
            const inviteRow = invites?.invited_by.filter((i) => i.uuid === uuid)?.[0] ?? false;

            if (inviteRow) {
                setLoading(false);
                setInvite(inviteRow);
            } else if(!working) {
                toast.error(t('Unable to find the invite for the provided link'), {
                    toastId: 'invite-error'
                });
                history.push('/portfolio');
            }
        }
    }, [invitesLoaded, t, invites, history, uuid, working]);

    return (
        <SettingsLayout>
            {loading ? <Box id="settingsLoading"><Spinner variant="styles.spinner" m={'auto'} /></Box> : (
                <CSSTransition
                    nodeRef={nodeRef}
                    in={true}
                    timeout={500}
                    classNames="fade"
                    unmountOnExit
                    appear
                >
                    <Box ref={nodeRef}>
                        <Box py={[4,5]} px={[3,4,6]} mb={4} sx={{
                            bg: 'light',
                            borderRadius: '16px',
                            textAlign: 'center'
                        }}>
                            <Flex
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                mb={4}
                            >
                                <Icon icon="user-thin" width="24px" height="auto" mr={2} />
                                <Heading as="h2" variant="h2">
                                    {t('Account management request')}
                                </Heading>
                            </Flex>

                            <Text as="p" variant="lead" mb={4}>
                                {t('notifications.invites.lead', {
                                    invited_by: `${invite.first_name} ${invite.last_name}`,
                                    organisation: invite?.organisation
                                })}
                            </Text>

                            <Grid gap={2} columns={['auto', 'repeat(2, 1fr)']}>
                                <Button onClick={(e) => handleApproval(e, 'approve')} disabled={working}>
                                    Approve
                                </Button>
                                <Button onClick={(e) => handleApproval(e, 'deny')} variant="bordered" disabled={working}>
                                    Decline
                                </Button>
                            </Grid>
                        </Box>
                    </Box>
                </CSSTransition>
            )}
        </SettingsLayout>
    );
};

export default connect(mapStateToProps, null)(SettingsInviteApproval);
