import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { OPEN_UP } from 'react-dates/constants';
import moment from 'moment';
import { useField } from 'formik';
import { Box, Flex, Select } from '@theme-ui/components';

import { DateWrap } from './styled';
import { ReactComponent as Calendar } from 'assets/Icon/calendar.svg';
import { Icon } from 'assets/Icon';
import { Label } from '~/Forms/Label';
import { useDebounce } from 'hooks/useDebounce';

export const DateField = React.forwardRef(
    (
        {
            label,
            showLabel = true,
            name,
            apiName,
            defaultValue,
            disablePastDates,
            isOutsideRange,
            validIndicator,
            validationError,
            disabled,
            required = false,
            placeholder,
            onChange,
            onChangeOverride,
            dateOfBirth = false,
            ...props
        },
        ref
    ) => {
        const [focused, setFocused] = useState(false);
        const [isHovered, setHovered] = useState(false);
        const [field, meta, { setValue, setTouched }] = useField(name);
        const debouncedSave = useDebounce((nextValue) => onChange(nextValue), 750);
        const [dateValue, setDateValue] = useState(meta.value ? moment(meta.value) : null);
        const fieldFocusedOrFilled = focused || (dateValue !== null && dateValue !== '');

        const isInvalid = (required && meta.value === '') || meta.error !== undefined;

        const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
            let i;
            const years = [];
            const startYear = dateOfBirth ? moment().subtract(18, 'years').year() : moment().year();

            for (i = startYear; i >= startYear - 100; i--) {
                years.push(
                    <option value={i} key={`year-${i}`}>
                        {i}
                    </option>
                );
            }
            return (
                <Flex
                    sx={{
                        justifyContent: 'center',
                        fontSize: 1,
                        px: 45,
                        top: '-4px',
                        position: 'relative',
                    }}
                >
                    <Box sx={{ flexGrow: 1, mr: '5px' }}>
                        <Select
                            sx={{ p: '4px 7px', border: '1px solid #e4e7e7' }}
                            value={month.month()}
                            onChange={(e) => onMonthSelect(month, e.target.value)}
                        >
                            {moment.months().map((label, value) => (
                                <option value={value} key={value}>
                                    {label}
                                </option>
                            ))}
                        </Select>
                    </Box>

                    <Box sx={{ flexGrow: 1 }}>
                        <Select
                            sx={{ p: '4px 7px', border: '1px solid #e4e7e7' }}
                            value={month.year()}
                            onChange={(e) => onYearSelect(month, e.target.value)}
                        >
                            {years}
                        </Select>
                    </Box>
                </Flex>
            );
        };

        return (
            <Box>
                <DateWrap
                    disabled={disabled}
                    focused={focused}
                    isHovered={isHovered}
                    invalid={meta?.touched && meta?.error}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >

                    <Label
                        label={label}
                        showLabel={showLabel}
                        fieldFocusedOrFilled={fieldFocusedOrFilled}
                        focused={focused || isHovered}
                        field={field}
                        placeholder={placeholder}
                        meta={meta}
                        validationError={validationError}
                    />

                    <SingleDatePicker
                        date={dateValue} // momentPropTypes.momentObj or null
                        onDateChange={(date) => {
                            if (date !== null) {
                                const value = date.format('YYYY-MM-DD');
                                setDateValue(date);

                                if (onChangeOverride) {
                                    onChangeOverride(date);
                                }else{
                                    setValue(value);

                                    onChange &&
                                        debouncedSave({
                                            name: apiName,
                                            value: value === null ? '' : value,
                                        })
                                }
                            }else{
                                setDateValue(null);
                            }

                        }}
                        onFocusChange={({ focused }) => {
                            setFocused(focused);
                            setTouched(field.name, false);
                        }}
                        focused={focused}
                        renderMonthElement={renderMonthElement}
                        id={name}
                        readOnly
                        hideKeyboardShortcutsPanel
                        displayFormat={'DD/MM/YYYY'}
                        disabled={disabled}
                        customInputIcon={ <Calendar />}
                        inputIconPosition="after"
                        numberOfMonths={1}
                        openDirection={OPEN_UP}
                        isOutsideRange={() => false}
                        initialVisibleMonth={() => dateValue ? dateValue : (dateOfBirth ? moment().subtract(50, 'years').month(0).date(1) : moment().month(0))}
                        placeholder=""
                        ref={ref}
                    />

                    {validIndicator === true && isInvalid && (
                        <Icon
                            icon="cross"
                            color="error"
                            sx={{
                                position: 'absolute',
                                right: '50px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                zIndex: 80
                            }}
                        />
                    )}
                </DateWrap>
            </Box>
        );
    }
);

DateField.defaultProps = {
    validationError: true,
    placeholder: '',
    validIndicator: true,
    required: false,
};
