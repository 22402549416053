import React, { useEffect, createContext, useState } from 'react';
import {
    OnboardingAddress,
    OnboardingCertificate,
    OnboardingDriversLicence,
    OnboardingFinished,
    OnboardingIdentity,
    OnboardingMobile,
    OnboardingPassport,
    OnboardingPersonal,
    OnboardingEntity
} from 'pages/Onboarding'
import {
    Box,
    Heading,
    Progress,
    Text,
    Flex,
    Spinner
} from 'theme-ui';
import { Icon } from 'assets/Icon';
import { Layout } from '~/Layouts';
import { LoadingScreen, Logo, BackButton, Button, Container, AnimatedTexture } from '~/Common';
import { toast } from 'utils/toast';
import { onboardToken, onboardLogin, setUser } from 'features/auth/authSlice';
import { useDispatch, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getCsrfCookie } from 'utils/api';
import {OnboardingSteps} from "../../../app/constants";
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const OnboardingContext = createContext();

const mapStateToProps = (state) => {
    return { user: state.user?.user, userStatus: state.user?.status, appTheme: state.theme }
}

const OnboardingLanding = ({ user, userStatus, appTheme, ...props }) => {
    const token = props.match.params.token;
    const [loginAttempted, setLoginAttempted] = useState(false);
    const [onboardingStepChecked, setOnboardingStepChecked] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    useDocumentTitle(t('Onboarding'));
    const [showInvite, setShowInvite] = useState(false);
    const [currentStep, setCurrentStep] = useState(OnboardingSteps.mobile);
    const [isLoading, setLoading] = useState(true);
    const [isReady, setReady] = useState(false);
    const [seenIdentityCards, setSeenIdentityCards] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isSetup, setSetup] = useState(false);
    const [reverseSlides, setReverseSlides] = useState(false);

    const triggerError = (error, data = {}) => {
        setErrors([
            ...errors,
            {
                message: error,
                ...data
            }
        ]);
    };

    useEffect(() => {
        // If we have a logged in user, we don't need to login via token
        if(!loginAttempted && token){
            setLoading(true);
            setOnboardingStepChecked(false);

            // Get login via CSRF and token, have to wait for csrf to run first
            getCsrfCookie().then(() => {
                onboardLogin({
                    onboarding_token: token,
                    device_name: 'App'
                }).then((response) => {
                    dispatch(setUser(response));

                    if (history.location.pathname !== '/onboarding') {
                        history.push('/onboarding');
                    }

                    setLoading(false);
                }).catch(({ response }) => {
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('authTokenScope');
                    toast.error(response?.data?.message || t('login.error'), {
                        toastId: 'login-error',
                    });
                    history.push('/');
                });
                setLoginAttempted(true);
            })
        } else {
            setLoading(false);
        }
    }, [token, dispatch, t, history, loginAttempted]);

    useEffect(() => {
        if(!onboardingStepChecked && userStatus === 'idle'){
            setOnboardingStepChecked(true);
        }

        if (user?.onboarding) {
            if(JSON.parse(localStorage.getItem('onboardingFirstLoaded')) !== true){
                setShowInvite(user?.tasks?.tests?.mobile_verified === false);
            }

            setReady(true);
        }
    }, [token, user, setShowInvite, showInvite, userStatus, onboardingStepChecked]);

    useEffect(() => {
        if (user?.finished_onboarding_details === true && currentStep !== OnboardingSteps.certificate) {
            setLoading(true);
            onboardToken({
                device_name: 'App'
            }).then((response) => {
                dispatch(setUser(response));
                history.push('/portfolio');
            });
        }
    }, [user, dispatch, history, currentStep]);

    const closeModal = (e) => {
        e.preventDefault();
        localStorage.setItem('onboardingFirstLoaded', true);
        setShowInvite(false);
    };

    const canGoBack = step => {
        switch (currentStep) {
            case 3:
            case 6:
            case 7:
                return true;
            default:
                return false;
        }
    }

    const goBack = (e, force) => {
        if (e) {
            e.preventDefault();
        }

        const allow = canGoBack(currentStep) || force;
        var nextIndex = allow ? currentStep - 1 : currentStep;

        if (allow) {
            // Skip steps that cannot be submitted more than once
            if (currentStep === OnboardingSteps.passport) {
                nextIndex = OnboardingSteps.identity;
            }
        }

        setCurrentStep(nextIndex);
    };

    const nextStep = (e) => {
        if (e) {
            e.preventDefault();
        }

        // Skip the Entity Onboarding step if user chose individual account
        if (appTheme.onboarding.type === 'individual' && currentStep === OnboardingSteps.address) {
            setCurrentStep(currentStep + 2);
        } else {
            setCurrentStep(currentStep + 1);
        }
    }

    useEffect(() => {
        if (!isSetup && user?.onboarding) {
            setSetup(true);

            let passed = true;

            if(user?.tasks?.tests?.certificate_uploaded === false){
                setCurrentStep( !seenIdentityCards ? OnboardingSteps.identity : OnboardingSteps.certificate );
                passed = false;
            }
            if (user?.tasks?.tests?.has_provided_passport === false) {
                setCurrentStep(!seenIdentityCards ? OnboardingSteps.identity : OnboardingSteps.passport );
                passed = false;
            }
            if(user?.tasks?.tests?.has_provided_drivers_licence === false){
                setCurrentStep(!seenIdentityCards ? OnboardingSteps.identity : OnboardingSteps.drivers_licence );
                passed = false;
            }
            if(user?.tasks?.tests?.has_address_details === false){
                setCurrentStep( OnboardingSteps.address );
                passed = false;
            }
            if(user?.tasks?.tests?.has_personal_details === false){
                setCurrentStep( OnboardingSteps.personal );
                passed = false;
            }
            if(user?.tasks?.tests?.mobile_verified === false){
                setCurrentStep( OnboardingSteps.mobile );
                passed = false;
            }
            if(passed && isLoading) {
                setCurrentStep( OnboardingSteps.finished );
            }
        }

    }, [user, seenIdentityCards, isLoading, isSetup, setSetup]);

    if (!isReady) {
        return <LoadingScreen />;
    }

    const renderStep = () => {
        if (isLoading) {
            return (
                <Flex sx={{ justifyContent: 'center' }}>
                    <Spinner variant="styles.spinner" />
                </Flex>
            );
        }

        switch(currentStep){
            case OnboardingSteps.mobile: return <OnboardingMobile />;
            case OnboardingSteps.personal: return <OnboardingPersonal />;
            case OnboardingSteps.address: return <OnboardingAddress />;
            case OnboardingSteps.entity: return <OnboardingEntity />;
            case OnboardingSteps.identity: return <OnboardingIdentity />;
            case OnboardingSteps.drivers_licence: return <OnboardingDriversLicence />;
            case OnboardingSteps.passport: return <OnboardingPassport />;
            case OnboardingSteps.certificate: return <OnboardingCertificate />;
            case OnboardingSteps.finished: return <OnboardingFinished />;

            default:
                return <Text as="p">Unable to find step</Text>;
        }
    };

    if (showInvite) {
        return (
            <Flex sx={{
                background: '#3F6D6A',
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
                minHeight: '100vh',
                alignItems: 'center',
                textAlign: 'center'
            }}>
                <AnimatedTexture sx={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 1
                }} />
                <Container size="612px" sx={{ zIndex: 10 }}>
                    <Box sx={{
                        overflow: 'auto',
                        minHeight: ['100vh', 0],
                        pt: 5,
                        position: 'relative',
                        zIndex: 3
                    }}>
                        <Icon icon="mail-open" size="53px" color="white" sx={{ mb: '20px' }} />
                        <Heading
                            as="h2"
                            mb={3}
                            id="onboardingTitle"
                            color="white"
                            sx={{
                                fontSize: ['48px', '48px', '64px'],
                                fontWeight: 400
                            }}
                        >{t('onboarding.landing.invite.title')}</Heading>
                        <Heading
                            as="h3"
                            variant="h4"
                            color="white"
                            sx={{
                                fontSize: '26px',
                                maxWidth: '516px',
                                fontWeight: 500,
                                textAlign: 'center',
                                margin: '0 auto',
                                mt: 5
                            }}
                        >{t('onboarding.landing.invite.subtitle')}</Heading>

                        <Button variant="primary" id="acceptInvite" sx={{
                            width: '100%',
                            maxWidth: '280px',
                            mt: [4,'53px']
                        }} type="button" onClick={closeModal}>{t('onboarding.landing.invite.accept')}</Button>
                    </Box>

                    <Box sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 2,
                        px: 2,
                    }}>
                        <Logo p={4} px="40px" width="180" color="white" center={true} />
                        <Text as="p" mb={4} color="white" sx={{
                            fontSize: '14px'
                        }}>{t('onboarding.landing.invite.description')}</Text>
                    </Box>
                </Container>
            </Flex>
        )
    }

    return (
        <Layout>
            <OnboardingContext.Provider value={{
                currentStep,
                setCurrentStep,
                isLoading,
                setLoading,
                seenIdentityCards,
                setSeenIdentityCards,
                errors,
                triggerError,
                nextStep,
                goBack,
                reverseSlides,
                setReverseSlides
            }}>
                {canGoBack(currentStep) && (
                    <BackButton onClick={goBack} sx={{
                        position: 'absolute',
                        top: [4,5],
                        left: [2,0],
                        mt: '-10px'
                    }} />
                )}
                <Container>
                    <Progress max={1} value={currentStep / 9} mb={5} >
                       {parseFloat(currentStep / 9)}%
                    </Progress>

                    {renderStep()}
                </Container>
            </OnboardingContext.Provider>
        </Layout>
    );
};

export { OnboardingContext };
export default connect(mapStateToProps, null)(OnboardingLanding);
