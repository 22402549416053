import { useRef } from 'react'
import {
    Box,
    Card as ThemeCard,
    Flex,
    Heading,
    Image,
    Text
} from '@theme-ui/components';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Circle,
    ImagePlaceholder,
    Skeleton,
    WatchlistButton
} from '~/Common'
import { useSelector } from 'react-redux'
import { StatusTag } from '~/Deals';
import { useDisplayName } from 'hooks/useDisplayName';

export const Card = ({ company, ...props }) => {
    const nodeRef = useRef(null);
    const { t } = useTranslation();
    const user = useSelector((state) => state.user?.user);
    const companyDisplayName = useDisplayName(company);

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={true}
            timeout={500}
            classNames="fade"
            unmountOnExit
            appear
        >
            <ThemeCard
                as={!user.finished_onboarding_details ? Box : Link}
                to={!user.finished_onboarding_details ? undefined : `/companies/${company?.slug}`}
                variant="company"
                ref={nodeRef}
            >
                {company ? (
                    <Box py={1} px={2} sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        color: 'light',
                        position: 'absolute',
                        top: 2,
                        right: 1,
                        zIndex: 10
                    }}>
                        <WatchlistButton key={`watchlist-company-${company?.uuid}`} company={company?.uuid} variant="card" />
                    </Box>
                ) : null}

                <Flex sx={{ position: 'relative', zIndex: 1 }}>
                    {company ? (
                        <>
                            {company?.banner?.sizes?.medium ? (
                                <Box sx={{
                                    width: '100%',
                                    height: '168px'
                                }}>
                                    <Image src={company?.banner?.sizes?.medium} sx={{ objectFit: 'cover', height: '100%', width: '100%' }} />
                                </Box>
                            ) : (
                                <ImagePlaceholder sx={{
                                    width: '100%',
                                    height: '168px'
                                }} />
                            )}
                        </>
                    ) : <Skeleton height="168px" />}
                </Flex>
                <Flex px={[3,3,3,'26px']} py={4} sx={{
                    position: 'relative',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flexGrow: 1
                }}>
                    <Circle size="70px" type="box" sx={{
                        position: 'absolute',
                        top: '-35px',
                        right: '24px',
                        bg: 'white',
                        zIndex: 10,
                        overflow: 'hidden',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)'
                    }}>
                        {company ? (
                            <>
                                {company?.logo?.sizes?.tiny ? (
                                    <Image src={company?.logo?.sizes?.tiny} sx={{ borderRadius: '50%' }} />
                                ) : (
                                    <ImagePlaceholder sx={{
                                        width: '70px',
                                        height: '70px',
                                        borderRadius: '50%'
                                    }} />
                                )}
                            </>
                        ) : <Skeleton variant="circle" size="70px" />}
                    </Circle>

                    <Box sx={{ flexGrow: 1 }}>
                        {company ? <Text as="p" variant="tag" color="dark" mb={2} sx={{ fontSize: '14px' }}>{company?.category?.name}</Text> : <Skeleton height="14px" width="50px" mb={2} />}
                        {company ? <Heading as="h3" variant="h3">{t(companyDisplayName)}</Heading> : <Skeleton height="28px" width="70%" />}

                        {company ? (
                            <>
                                {Boolean(company?.current_deal || company?.past_deals) && (
                                    <Flex sx={{
                                        mt: 3,
                                        flexDirection: ['column', 'column', 'column', 'column', 'row']
                                    }}>
                                        {company?.current_deal ? (
                                            <Box sx={{
                                                minWidth: [null, null, null, null, '35%']
                                            }}>
                                                <Text as="p" variant="tag" color="dark" mb={2} sx={{ fontSize: '14px' }}>{t('Current Deal')}</Text>

                                                <Box>
                                                    <StatusTag
                                                        label={company?.current_deal?.stage?.name}
                                                        status={company?.current_deal?.status?.id}
                                                        stage={company?.current_deal?.stage?.id}
                                                        sx={{
                                                            mr: 4,
                                                            whiteSpace: 'nowrap'
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        ) : null}

                                        {company?.past_deals ? (
                                            <Box sx={{
                                                ml: company?.current_deal ? [null, null, null, null, 'auto'] : null,
                                                width: [null, null, null, null, '65%']
                                            }}>
                                                <Text as="p" variant="tag" color="dark" mb={2} sx={{ fontSize: '14px' }}>{t('Past Deals')}</Text>

                                                <Box>
                                                    {company.past_deals.map((deal, i) => (
                                                        <StatusTag
                                                            key={`past-deal-${deal.uuid}`}
                                                            label={deal?.stage?.name}
                                                            sx={{
                                                                bg: 'medium',
                                                                mr: i === 1 ? 0 : 2,
                                                                mb: 2,
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                        />
                                                    ))}
                                                </Box>
                                            </Box>
                                        ) : null}
                                    </Flex>
                                )}
                            </>
                        ) : (
                            <Box mt={3}>
                                <Skeleton height="14px" width="30px" mb={2} />
                                <Skeleton height="40px" width="80px" />
                            </Box>
                        )}
                    </Box>
                </Flex>
            </ThemeCard>
        </CSSTransition>
    );
};
