import { useRef } from 'react'
import {
    Box,
    Card as ThemeCard,
    Grid,
    Flex,
    Heading,
    Image,
    Text,
} from '@theme-ui/components';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Circle,
    ImagePlaceholder,
    Skeleton
} from '~/Common'
import { StatusTag } from '~/Deals'
import { useSelector } from 'react-redux'
import { CardButtons } from 'components/Deals/Card';
import { dealOfferPriceFormatting } from 'utils/helpers';
import { Icon } from 'assets/Icon';
import { useDisplayName } from 'hooks/useDisplayName';
import { useDealTypeDetail } from 'hooks/useDealTypeDetail';

export const List = ({ deal, hideCardLink, ...props }) => {
    const { t } = useTranslation();
    const nodeRef = useRef(null);
    const user = useSelector((state) => state.user?.user);
    const dealDisplayName = useDisplayName(deal);
    const cardLink = user?.can_see_dashboard && (hideCardLink === true ? false : true);
    const dealDetail = useDealTypeDetail(deal);

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={true}
            timeout={500}
            classNames="fade"
            unmountOnExit
            appear
        >
            <ThemeCard
                ref={nodeRef}
                variant="list"
                as={!cardLink ? Box : Link}
                to={!cardLink ? undefined : `/deals/${deal?.slug}`}
                sx={{
                    minWidth: '1300px',
                }}
            >
                <Grid px={3} py={3} sx={{
                    position: 'relative',
                    alignItems: 'center',
                    gridTemplate: 'auto / 70px auto 140px 230px 400px',
                    gridGap: '24px',
                }}>
                    <Flex sx={{
                        width: '80px',
                        alignItems: 'center'
                    }}>
                        <Circle size="70px" type="box" sx={{
                            boxShadow: ['none', 'none', '3px 3px 20px rgba(29, 43, 43, 0.1)'],
                            bg: 'transparent'
                        }}>
                            {deal ? (
                                <>
                                    {deal?.company?.logo?.sizes?.tiny ? (
                                        <Image src={deal?.company?.logo?.sizes?.tiny} sx={{ borderRadius: '50%' }} />
                                    ) : (
                                        <ImagePlaceholder imageSx={{ borderRadius: '50%' }} sx={{
                                            width: '70px',
                                            height: '70px',
                                            borderRadius: '50%'
                                        }} />
                                    )}
                                </>
                            ) : <Skeleton variant="circle" size="70px" />}
                        </Circle>
                    </Flex>

                    <Box pb={[0]} sx={{
                        alignItems: 'center',
                        width: ['auto'],
                        maxWidth: '400px'
                    }}>
                        <Grid sx={{
                            gridTemplateColumns: ['1fr 150px'],
                            alignItems: 'center',
                            gridGap: ['12px'],
                        }}>
                            <Flex sx={{
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start'
                            }}
                            >
                                {deal ? <Text as="p" variant="tag" color="dark">{deal?.category?.name}</Text> : <Skeleton height="14px" width="50px" mb={1} />}
                                {deal ? <Heading as="h3" variant="deal_heading_list" mb={0}>{t(dealDisplayName)}</Heading> : <Skeleton height="24px" width="70%" mb={1} />}
                                {deal ? <Text as="p" variant="smaller" color="dark">{deal?.code}</Text> : <Skeleton height="14px" width="30px" />}
                            </Flex>
                            <Box>
                                {deal ?
                                    <StatusTag label={deal?.stage?.name} status={deal?.status?.id} stage={deal?.stage?.id} sx={{
                                        p: '6px 13px',
                                        fontSize: '11px'
                                    }} />
                                : <Skeleton height="28px" width="100px" />}
                            </Box>
                        </Grid>
                    </Box>

                    <Flex sx={{
                        position: 'relative',
                        width: ['auto'],
                        alignItems: 'center',
                        justifyContent: ['flex-start']
                    }}>
                        <Box>
                            {deal ? <Text as="p" variant="labelCompact">{t(dealDetail.managerText)}</Text> : <Skeleton height="14px" width="50px" mb={1} />}
                            {deal ? (
                                <>
                                    {deal?.organisation ? (
                                        <>
                                            {deal?.organisation?.logo?.sizes?.tiny ? (
                                                <Image src={deal?.organisation?.logo?.sizes?.tiny} sx={{ filter: 'grayscale(1)', maxWidth: '100%' }} />
                                            ) : (
                                                <Text as="p" variant="price" color="darker" sx={{ fontSize: '14px' }}>{deal?.organisation?.name}</Text>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <Icon icon="lqd" height="24" color="darker" />
                                        </>
                                    )}
                                </>
                            ) : (
                                <Skeleton height="40px" width="120px" />
                            )}

                        </Box>
                    </Flex>

                    <Flex sx={{
                        alignItems: ['center'],
                        flexDirection: ['row'],
                        width: ['auto'],
                    }}>
                    {!deal?.preview_mode && (
                        <>
                            {(deal?.offer_price && !dealDetail.alternateInvestment) && (
                                <>
                                    <Box mr={[3,3,3,3,4]} mb={[0]} sx={{
                                        width: [null, null, null, null, '38%']
                                    }}>
                                        <Text as="p" variant="labelCompact">{t('Offer Price')}</Text>
                                        <Text as="p" variant="price" color="darker" sx={{ fontSize: '18px' }}>A${dealOfferPriceFormatting(deal?.offer_price)}</Text>
                                    </Box>
                                    <Box sx={{
                                        width: [null, null, null, null, '62%']
                                    }}>
                                        <Text as="p" variant="labelCompact">{t('Offer Size')}</Text>
                                        <Text as="p" variant="price" color="darker" sx={{ fontSize: '18px' }}>A${deal?.offer_size}</Text>
                                    </Box>
                                </>
                            )}

                            {(deal?.min_bid_price && dealDetail.alternateInvestment) && (
                                <>
                                    <Box mr={[3,3,3,3,4]} mb={[0]} sx={{
                                        width: [null, null, null, null, '38%']
                                    }}>
                                    </Box>
                                    <Box sx={{
                                        width: [null, null, null, null, '62%']
                                    }}>
                                        <Text as="p" variant="labelCompact">{t('Minimum Investment')}</Text>
                                        <Text as="p" variant="price" color="darker" sx={{ fontSize: '18px' }}>A${dealOfferPriceFormatting(deal?.min_bid_price)}</Text>
                                    </Box>
                                </>
                            )}
                        </>
                    )}
                    </Flex>

                    <Flex p={[null, null, 2]} sx={{
                        width: '100%',
                        flexDirection: 'column',
                        justifyContent: 'flex-start'
                    }}>
                        <CardButtons link={!cardLink} deal={deal} user={user} size="list" buttonHeights={['64px','100%']} viewButtonText={dealDetail.viewButtonText} alternateInvestment={dealDetail.alternateInvestment}/>
                    </Flex>
                </Grid>
            </ThemeCard>
        </CSSTransition>
    );
};
