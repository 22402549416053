import { useRef, useState, useEffect } from 'react'
import { SettingsLayout } from '~/Layouts';
import { PeerInviteForm } from '~/Common';
import {
    Box,
    Grid,
    Heading,
    Spinner,
    Text
} from '@theme-ui/components';
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group'
import {
    getInvitesAsync
} from 'features/invites/invitesSlice'

import moment from 'moment-timezone'
import { DATE_FORMAT } from 'app/constants'
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { user: state.user?.user, invites: state.invites, settings: state.theme?.settings }
}

const SettingsGetRewards = ({ user, invites, settings }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Get Rewards'));
    const [loading, setLoading] = useState(true);
    const nodeRef = useRef(null);
    const dispatch = useDispatch();
    const canAddMore = invites?.invites && invites?.invites.length < settings?.peer_invites_per_user_limit;
    moment.tz.setDefault(user?.user?.timezone);

    useEffect(() => {
        if(invites.status === 'idle'){
            dispatch(getInvitesAsync());
        }

        if(invites.status === 'attempted'){
            setLoading(false);
        }
    }, [invites, dispatch]);

    return (
        <SettingsLayout>
            {loading ? <Box id="settingsLoading"><Spinner variant="styles.spinner" m={'auto'} /></Box> : (
                <CSSTransition
                    nodeRef={nodeRef}
                    in={true}
                    timeout={500}
                    classNames="fade"
                    unmountOnExit
                    appear
                >
                    <Box ref={nodeRef}>
                        <Heading as="h1" variant="h2" mb={4}>{t('Get Rewards')}</Heading>

                        <Box py={[4,5]} px={[3,4,6]} mb={4} sx={{
                            bg: 'light',
                            borderRadius: '16px',
                            textAlign: 'center'
                        }}>
                            {canAddMore ? <>
                                <Heading as="h2" variant="h2" mb={4}>{t('Give someone\n exclusive access')}</Heading>
                                <Text as="p" mb={4}>{t('You can now invite a fellow investor, but you only have {{inviteLimit}}.\n Who will you ask to join?', { inviteLimit: (settings?.peer_invites_per_user_limit === 1 ? 'one invite' : `a total of ${settings?.peer_invites_per_user_limit} invites`) })}</Text>
                                <PeerInviteForm />
                            </> : <>
                                <Heading as="h2" variant="h2" mb={4}>{t('Sharing is caring')}</Heading>
                                <Text as="p" mb={4}>{t('You\'ve reached the maximum allowed invites. Watch this space until we release more spots.')}</Text>
                            </>}
                        </Box>

                        {(invites?.invites && invites?.invites.length > 0) &&
                            <Box my={4}>
                                <Heading as="h2" variant="h2" mb={3}>{t('Invites')}</Heading>
                                <Grid mb={4} sx={{
                                    gridGap: 3,
                                    gridTemplateColumns: ['1fr'],
                                    width: '100%',
                                    maxWidth: '760px'
                                }}>
                                    <Grid mb={2} sx={{
                                        gridGap: 3,
                                        gridTemplateColumns: ['1fr 2fr 1fr 1fr'],
                                        width: '100%',
                                        display: ['none','none','grid']
                                    }}>
                                        <Box sx={{ fontWeight: 'bold' }}>{t('Name')}</Box>
                                        <Box sx={{ fontWeight: 'bold' }}>{t('Email')}</Box>
                                        <Box sx={{ fontWeight: 'bold' }}>{t('Invited On')}</Box>
                                        <Box sx={{ fontWeight: 'bold' }}>{t('Expiry')}</Box>
                                    </Grid>
                                    {invites.invites.map((invite, key) => (
                                        <Grid key={`invites${key}`} mb={1} sx={{
                                            gridGap: [1,1,3],
                                            gridTemplateColumns: ['auto', 'auto','1fr 2fr 1fr 1fr'],
                                            width: '100%',
                                            paddingBottom: [3,3,0],
                                            borderBottom: ['1px solid #eaeaea', '1px solid #eaeaea', 0]
                                        }}>
                                            <Box>{invite?.first_name} {invite?.last_name}</Box>
                                            <Box><Text sx={{ fontSize: 1 }}>{invite?.email}</Text></Box>
                                            <Box><Text sx={{ fontWeight: 'bold', display: ['inline', 'inline', 'none'] }}>{t('Invited On')}</Text> <Text sx={{ fontSize: 1 }}>{moment(invite?.invited_at).format(DATE_FORMAT.date_format_friendly)}</Text></Box>
                                            <Box><Text sx={{ fontWeight: 'bold', display: ['inline', 'inline', 'none'] }}>{t('Expiry')}</Text> <Text sx={{ fontSize: 1 }}>{invite?.expiry_at && moment(invite?.expiry_at).format(DATE_FORMAT.date_format_friendly)}</Text></Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        }

                    </Box>
                </CSSTransition>
            )}
        </SettingsLayout>
    );
};

export default connect(mapStateToProps, null)(SettingsGetRewards);
