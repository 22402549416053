import styled from '@emotion/styled';
import { theme } from 'app/theme';

export const Menu = styled('div')`
    & a{
        position: relative;
    }

    & a span{
      position: relative;
    }

    & a span::after{
        content: '';
        height: 1px;
        background: ${theme.colors.darker};
        width: 0;
        position: absolute;
        bottom: -5px;
        left: 0;
        transition: all 0.3s ease-in-out;
    }
    & a.active span::after{
        width: 100%;
    }
    & a.active:hover span::after {
      background: ${theme.colors.tealDark}
    }
`;

export const MenuUl = styled('ul')`
    margin: 0;
    padding: 0;

    & li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
`;
