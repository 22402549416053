import { useEffect, useCallback, useState } from 'react'
import {
    Box,
    Divider,
    Text,
    NavLink as ThemedNavLink
} from '@theme-ui/components'
import { NavLink } from 'react-router-dom'
import { Icon } from 'assets/Icon'
import { MenuUl } from './styled'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { APP_FEATURES, MAX_PROFILES_PER_USER } from 'app/constants'

export const MenuItem = ({ label, name, path, url, icon, iconColor, textColor, items, ...props }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const escFunction = useCallback((event) => {
        if(event.keyCode === 27) {
            setOpen(false);
        }
    }, []);
    const hasItems = Array.isArray(items) && items.length;

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    if (name === 'divider') {
        return <li><Divider color="light" my={3} /></li>;
    }

    return (
        <li className={open ? 'active' : ''}>
            <ThemedNavLink
                as={path ? NavLink : url ? 'a' : Box}
                id={`AccountMenu_${name}`}
                to={path}
                href={url}
                target={url ? '_blank' : undefined}
                variant="menuitem"
                onClick={() => setOpen(!open)}
            >
                {icon && <Icon icon={icon} color={iconColor ?? "tealDark"} size="24px" className="header__navIcon" mr={3} />}
                <Text className="header__navText" sx={{
                    color: textColor
                }}>{t(label)}</Text>
                {hasItems && (
                    <Icon icon="dropdown" sx={{
                        flex: 1,
                        justifyContent: 'flex-end',
                        '& svg': {
                            transition: 'transform 0.2s ease-in-out',
                            transform: `rotate(${open ? -180 : 0}deg) translateY(${open ? 2 : 0}px)`,
                        }
                    }} />
                )}
            </ThemedNavLink>
            {hasItems ? (
                <MenuUl style={{
                    maxHeight: open ? '1000px' : '0px',
                    transition: open
                        ? 'opacity 0.3s 0.3s ease-in-out, max-height 0.3s ease-in-out, padding-top 0.3s ease-in-out'
                        : 'opacity 0.3s ease-in-out, max-height 0.3s 0.3s ease-in-out, padding-top 0.3s ease-in-out',
                    pointerEvents: open ? 'all' : 'none',
                    opacity: open ? 1 : 0,
                    paddingTop: open ? '15px' : 0,
                }}>
                    {items.map((subItem) => (
                    <MenuItem
                        key={subItem.name}
                        {...subItem}
                        />
                    ))}
                </MenuUl>
            ) : null}
        </li>
    );
};

export const AccountMenu = ({ children, nested, ...props }) => {
    const settings = useSelector(store => store.theme?.settings);
    const adviser = useSelector((state) => state.user?.adviser);
    const isAdviser = useSelector((state) => state.user?.user?.is_adviser);
    const canAddMoreProfiles = useSelector(state => state.user?.user?.profiles && !state.user?.user?.is_adviser ? state.user?.user?.profiles.length < MAX_PROFILES_PER_USER : false)
    const menuItems = [
        { name: 'details', path: '/settings/account', label: 'Account Details', icon: 'user' },
        { name: 'entities', path: '/settings/entities', label: 'My Entities', icon: 'users', hidden: isAdviser },
        { name: 'my-advisers', path: '/settings/advisers', label: 'My Advisers', icon: 'users', hidden: Boolean(!APP_FEATURES.adviser_invites || isAdviser || adviser) },
        { name: 'preferences', path: '/settings/preferences', label: 'Deal Preferences', icon: 'cog' },
        { name: 'transfer-in', url: settings?.broker_to_broker_download, label: 'Transfer In Holdings', icon: 'reply', hidden: isAdviser },
        { name: 'notifications', path: '/settings/notifications', label: 'Notifications', icon: 'bell-thick' },
        { name: 'get-rewards', path: '/settings/get-rewards', label: 'Get Rewards', icon: 'gift', hidden: Boolean(!APP_FEATURES.peer_invites || isAdviser) },
        ...(canAddMoreProfiles
            ? [
                { name: 'divider' },
                {
                    name: 'add-entity',
                    path: '/settings/entities/create',
                    label: 'Add Entity',
                    icon: 'add-user',
                    iconColor: 'medium',
                    textColor: 'dark'
                }
            ]
            : []
        )
    ];

    return (
        <Box {...props} role="navigation" aria-label="Settings Navigation">
            <MenuUl>
                {(nested ? [
                    {
                        name: 'settings',
                        label: 'Settings',
                        items: menuItems,
                        icon: 'cog',
                    }
                ] : menuItems).map((subItem) => subItem.hidden === true ? null : (
                    <MenuItem
                        key={subItem.name}
                        {...subItem}
                    />
                ))}
            </MenuUl>
        </Box>
    );
};
