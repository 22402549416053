import { Formik, Form } from 'formik';
import { toast } from 'utils/toast';
import { Box, Flex, Heading, Link, Spinner, Text } from '@theme-ui/components';
import { Field } from '~/Forms';
import { Button } from '~/Common';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { deleteProfileSupportingDocument, getProfileSupportingDocuments, storeProfileSupportingDocuments } from 'features/auth/authSlice';
import { Icon } from 'assets/Icon';
import { grabFirstError } from 'utils/helpers';

export const UploadSupportingDocuments = ({ profile, maxFiles = 5, sx, showForm = true, setStatus, ...props }) => {
    return (
        showForm ? (
            <Formik
                initialValues={{
                    supporting_documents: [],
                }}
            >
                {({ setStatus }) => {
                    return (
                        <Form>
                            <UploadSupportingDocumentsContent
                                profile={profile}
                                maxFiles={maxFiles}
                                setStatus={setStatus}
                                sx={sx}
                            />
                        </Form>
                    );
                }}
            </Formik>
        ) : (
            <UploadSupportingDocumentsContent
                profile={profile}
                maxFiles={maxFiles}
                setStatus={setStatus}
                sx={sx}
            />
        )
    );
};

export const UploadSupportingDocumentsContent = ({ profile, maxFiles = 5, sx, setStatus, ...props }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(true);
    const [showUpload, setShowUpload] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const handleUploadSupportingDocuments = ({ name, value, setSubmitting, setStatus }) => {
        setProcessing(true);

        let formdata = new FormData();

        value.map((file) => {
            return formdata.append(`${name}[]`, file);
        });

        setUploadProgress(0);

        storeProfileSupportingDocuments(profile, formdata, uploadProgress)
            .then((response) => {
                toast.success(t('settings.supporting_documents.success'), {
                    toastId: 'supporting_documents-success',
                });
                setUploadedFiles(response.data);

                setTimeout(() => setShowUpload(false), 750);
            })
            .catch(({ response }) => {
                toast.error(grabFirstError(response?.data?.errors) || response?.data?.message || t('settings.supporting_documents.error'), {
                    toastId: 'supporting_documents-error',
                });
                setStatus('api_error');

                setTimeout(() => setShowUpload(false), 750);
            })
            .finally(() => setProcessing(false));
    };

    const handleRemoveSupportingDocument = (document) => {
        setProcessing(true);

        deleteProfileSupportingDocument(profile, document)
            .then((response) => {
                toast.success(t('settings.supporting_documents.delete_success'), {
                    toastId: 'supporting_documents-success',
                });
                setUploadedFiles(response.data);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || t('settings.supporting_documents.delete_error'), {
                    toastId: 'supporting_documents-error',
                });
            })
            .finally(() => setProcessing(false));
    };

    useEffect(() => {
        if (loading) {
            getProfileSupportingDocuments(profile)
                .then((response) => {
                    setUploadedFiles(response.data);
                })
                .finally(() => {
                    setLoading(false);
                    setProcessing(false);
                });
        }
    }, [loading, uploadedFiles, profile]);

    return (
        <Box
            mb={4}
            sx={{
                sx,
            }}
        >
            <Heading as="h3" variant="settings_heading" mb={2}>
                {t('Optional Supporting Documentation')}
            </Heading>
            <Text as="p" mb={3}>
                {t('ie. contract note or holding statement')}
            </Text>

            {showUpload ? (
                <>
                    <Field
                        field="upload"
                        name="supporting_documents"
                        maxFiles={maxFiles}
                        label="Supporting documentation"
                        showLabel={false}
                        uploadProgress={uploadProgress}
                        onChange={(props) => handleUploadSupportingDocuments({ setStatus, ...props })}
                    />
                    <Box mb={4}>
                        <Link
                            to="#cancel"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowUpload(false);
                            }}
                        >
                            {t('Cancel upload')}
                        </Link>
                    </Box>
                </>
            ) : (
                <>
                    <Flex sx={{ alignItems: 'center', mb: 2, gridGap: 3 }}>
                        <Button variant="light" onClick={() => setShowUpload(true)}>
                            {t('Supply supporting documentation')}
                        </Button>
                    </Flex>
                </>
            )}

            {Boolean(uploadedFiles.length && !processing) && (
                <Box mt={4}>
                    <Text as="p" variant="caption" mb={2}>
                        {t('Current supporting documents')}
                    </Text>

                    <Box>
                        {uploadedFiles.map((file) => (
                            <Box
                                key={`file${file.uuid}`}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    border: '1px solid',
                                    borderRadius: '6px',
                                    borderColor: 'primary',
                                    py: 2,
                                    px: 3,
                                    mb: 2,
                                    overflow: 'hidden',
                                }}
                            >
                                <Link href={file.file} target="_blank">
                                    {t(file?.description ? file.description : 'View file')}
                                </Link>
                                <Icon icon="external-link" ml={2} />
                                <Link
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveSupportingDocument(file.uuid);
                                    }}
                                    ml={'auto'}
                                >
                                    {t('Remove')}
                                </Link>
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}

            {processing && <Spinner variant="styles.spinner" m={'auto'} />}
        </Box>
    );
};
