import { useRef, useState, useEffect } from 'react'
import {
    Box,
    Flex,
    Link,
    Heading,
    Spinner,
    Text
} from '@theme-ui/components'
import { Button } from '~/Common'
import { SettingsLayout } from '~/Layouts'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, NavLink } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import * as Yup from 'yup'
import {  Form, Formik } from 'formik'
import { toast } from 'utils/toast'
import { Field } from '~/Forms'
import { Icon } from 'assets/Icon'
import { setUser, storeProfileExternalHins, storeProfileUpload } from 'features/auth/authSlice'
import { getNestedErrors } from 'utils/helpers';
import { useDocumentTitle } from 'hooks/useDocumentTitle'
import moment from 'moment'
import { DATE_FORMAT } from 'app/constants'

const mapStateToProps = (state) => {
    return { user: state.user?.user, userLoaded: state.user.status === 'idle', theme: state.theme };
};

const validationSchema = Yup.object().shape({
    external_hins: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().nullable(),
            hin_number: Yup.string().required('HIN number required'),
        })
    )
});


const SettingsProfilesCertificate = ({ user, userLoaded, theme }) => {
    const { t } = useTranslation();
    const history = useHistory();
    useDocumentTitle(t('Connected HINs'));
    const { uuid } = useParams();
    const dispatch = useDispatch();
    const nodeRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const [showUploadCert, setShowUploadCert] = useState(false);
    const [certUploadProgress, setCertUploadProgress] = useState(0);

    const onUploadProgress = (p) => {
        setCertUploadProgress((p.loaded / p.total) * 100);
    };

    const handleUploadCertificate = ({ name, value, setSubmitting, setStatus }) => {
        let formdata = new FormData();
        formdata.append(name, value);

        setCertUploadProgress(0);

        storeProfileUpload(uuid, 'certificate', formdata, onUploadProgress).then((response) => {
            toast.success(t('settings.certificate.success'), {
                toastId: 'certificate-success',
            });
            dispatch(setUser(response));

            setTimeout(() => setShowUploadCert(false), 750);
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.certificate.error'), {
                toastId: 'certificate-error',
            });
            setStatus('api_error');

            setTimeout(() => setShowUploadCert(false), 750);
        });
    };

    const handleSubmit = ( values, actions ) => {
        let formParams = {
            external_hins: values.external_hins ?? null
        };

        storeProfileExternalHins(uuid, formParams).then((response) => {
            toast.success(t('settings.profile_certificate.success'), {
                toastId: 'profile_certificate-success',
            });
            dispatch(setUser(response));
            actions.setSubmitting(false);

        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(getNestedErrors(response.data.errors));
            toast.error(response?.data?.message || t('settings.profile_certificate.success'), {
                toastId: 'profile_certificate-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    };

    useEffect(() => {
        if (userLoaded) {
            const existingProfile = user.profiles.filter((p) => p.uuid === uuid)?.[0] ?? false;

            if (existingProfile) {
                setProfile(() => existingProfile);
            }
            setLoading(false);
        }
    }, [userLoaded, user, uuid]);

    return (
        <SettingsLayout>
            {loading ? (
                <Box id="settingsLoading"><Spinner variant="styles.spinner" m={'auto'} /></Box>
            ) : (
                <CSSTransition nodeRef={nodeRef} in={true} timeout={500} classNames="fade" unmountOnExit appear>
                    <Box ref={nodeRef}>
                        {profile ? (
                            <>
                                <Flex sx={{ alignItems: 'center', justifyContent: 'flex-start' }} mb={4}>
                                    <Box>
                                        <Heading as="h1" variant="h2" mb={2}>
                                            {t('settings.profile_certificate.title', {
                                                type: profile?.type_name,
                                                name: profile?.name
                                            })}
                                        </Heading>
                                        <Heading as="p" variant="h4">
                                            {t('settings.profile_certificate.subtitle')}
                                        </Heading>
                                    </Box>
                                </Flex>

                                <Box mb={5}>
                                    <Formik
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ setStatus, setFieldValue }) => {
                                            return (
                                                <Form>
                                                    <Box>
                                                        <Heading as="h3" variant="settings_heading">{t(profile?.certificate ? 'Update s708 Certificate' : 'Upload s708 Certificate')}</Heading>

                                                        {profile?.certificate_status === 'Expired' ? (
                                                            <Text as="p" variant="body_large" color="slate" mb={4}>
                                                                {t('settings.account.certificateExpired', {
                                                                    date: moment(profile?.certificate_expiry_at).format(DATE_FORMAT.date_format_friendly)
                                                                })}
                                                            </Text>
                                                        ) : (
                                                            <>
                                                                {profile?.certificate_expiry_at && <Text as="p" variant="body_large" color="slate" mb={4}>
                                                                    {t('settings.account.certificateExpiryWarning', {
                                                                        date: moment(profile?.certificate_expiry_at).format(DATE_FORMAT.date_format_friendly)
                                                                    })}
                                                                </Text>}
                                                            </>
                                                        )}

                                                        {theme.settings.certificate_template && (
                                                            <Box mb={3} sx={{
                                                                color: 'dark',
                                                                fontSize: '13px',
                                                                '& a': {
                                                                    color: 'tealDark'
                                                                }
                                                            }}>
                                                                {t('Need help?')} <Link href={theme.settings.certificate_template} target="_blank" variant="light">{t('Download Certificate Template')}</Link>
                                                            </Box>
                                                        )}
                                                        <Box mb={4}>
                                                            {showUploadCert ? (
                                                                <>
                                                                    <Field
                                                                        field="upload"
                                                                        name="certificate"
                                                                        label="Upload 708 certificate"
                                                                        showLabel={false}
                                                                        uploadProgress={certUploadProgress}
                                                                        onChange={(props) => handleUploadCertificate({ setStatus, setFieldValue, ...props })}
                                                                    />
                                                                    <Box mb={4}>
                                                                        <Link to="#cancel" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setShowUploadCert(false)
                                                                        }}>{t('Cancel certificate upload')}</Link>
                                                                    </Box>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Flex sx={{ alignItems: 'center', mb: 2, gridGap: 3 }}>
                                                                        <Button variant="light" onClick={() => setShowUploadCert(true)}>{t(profile?.certificate ? 'Update Certificate' : 'Supply Certificate')}</Button>
                                                                        {profile?.certificate &&
                                                                            <Box variant="styles.captionWrapper" sx={{ py: '13px' }}>
                                                                                {profile?.certificate_status === 'Expired' ? (
                                                                                    <Flex sx={{ alignItems: 'center' }}>
                                                                                        <Icon icon="pending" color="warning" width="auto" height="13px" sx={{ mr: 2 }} />
                                                                                        <Text variant="caption">{t('Certificate expired')}</Text>
                                                                                    </Flex>
                                                                                ) : (
                                                                                    <Flex sx={{ alignItems: 'center' }}>
                                                                                        <Icon icon="tick" color="tealDark" width="auto" height="9px" sx={{ mr: 2 }} />
                                                                                        <Text variant="caption">{t(profile?.certificate_status === 'Pending' ? 'Awaiting verification' : 'Certificate provided')}</Text>
                                                                                    </Flex>
                                                                                )}
                                                                            </Box>
                                                                        }
                                                                        
                                                                    </Flex>
                                                                    <Button
                                                                        mt={4}
                                                                        sx={{ display: 'inline-flex', alignItems: 'center'  }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            history.push('/portfolio');
                                                                        }}
                                                                    >
                                                                        {t('Back to Portfolio')}
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </Box>
                            </>
                        ) : (
                            <Box mb={4}>
                                <Heading as="h1" variant="h2" mb={2}>
                                    {t('settings.profile_certificate.notFound.title')}
                                </Heading>
                                <Heading as="p" variant="h4" mb={4}>
                                    {t('settings.profile_certificate.notFound.description')}
                                </Heading>

                                <Button
                                    as={NavLink}
                                    to="/settings/entities"
                                    variant="light"
                                >
                                    {t('Back to my entities')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </CSSTransition>
            )}
        </SettingsLayout>
    );
};

export default connect(mapStateToProps, null)(SettingsProfilesCertificate);

