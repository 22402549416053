import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { logoutAsync } from "features/auth/authSlice";
import { LoadingScreen } from '~/Common/LoadingScreen';

const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logoutAsync());
    })

    return (
        <LoadingScreen hasToken={false} />
    );
}

export default Logout;
