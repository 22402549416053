import {
    Box,
    Button
} from '@theme-ui/components'
import { Icon } from 'assets/Icon'
import { xor } from 'lodash'
import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toTitleCase, getNestedErrorsByFields } from 'utils/helpers'
import { Circle } from '../Circle'
import { TabContext, TabGroupProvider, TabProvider } from './TabContext'

export const TabGroup = ({
    name,
    children,
    sx,
    ...props
}) => {
    const { selected } = useContext(TabContext);

    return (
        <TabGroupProvider value={{ name }}>
            <Box
                sx={{
                    display: selected === name ? 'block' : 'none',
                }}
                {...props}
            >
                {children}
            </Box>
        </TabGroupProvider>
    )
}

export const Tabs = ({
    children,
    sx,
    tabs,
    selected,
    translate = true,
    variant = '',
    wrap = true,
    errors,
    ...props
}) => {
    const { t } = useTranslation()
    const [tabFields, setTabFields] = useState({});

    const toggleTabField = useCallback((tab, field) => {
        setTabFields((fields) => {
            if (Array.isArray(fields[tab])) {
                fields[tab] = xor(fields[tab], [field])
            } else {
                fields[tab] = [field];
            }
            return fields;
        })
    }, [setTabFields]);

    const getTabErrors = (tab) => {
        if (errors && tabFields) {
            const fields = tabFields[tab];
            if (Array.isArray(fields)) {
                const nested = getNestedErrorsByFields(errors, fields);
                return nested;
            }
        }
        return [];
    }

    if (!Array.isArray(tabs)) {
        return null;
    }

    return (
        <TabProvider value={{ selected, tabFields, toggleTabField }}>
            <Box sx={{
                display: 'flex',
                flexWrap: wrap ? 'wrap' : undefined,
                mb: '40px',
                gridGap: variant === '' ? 2 : 4,
                overflowX: !wrap ? 'auto' : undefined,
            }} {...props}>
                {tabs.map((tab, key) => {
                    const errors = getTabErrors(tab.key);
                    const hasError = !!errors.length;

                    return (
                        <Button
                            key={key}
                            variant={`tab${toTitleCase(variant)}`}
                            className={`${selected === tab.key ? 'active' : ''} ${hasError ? 'invalid' : ''}`}
                            onClick={(e) => e.preventDefault() || (tab.onClick && tab.onClick())}
                            sx={{ position: 'relative', flexShrink: 0 }}
                        >
                            {tab.icon && <Icon icon={tab.icon} color={tab.iconColor ?? '#00000A'} sx={{ mr: 2 }} />}
                            {translate ? t(tab.name) : tab.name}
                            {hasError && <Circle bg="error" sx={{ position: 'absolute', top: 0, right: '-14px', fontSize: '10px', ml: 2 }} size="14px">
                                {errors.length}
                            </Circle>}
                        </Button>
                    )
                })}
            </Box>
            {children}
        </TabProvider>
    );
};
