import { connect } from 'react-redux';
import { UserSettings } from './UserSettings';
import { AdviserSettings } from './AdviserSettings';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { user: state.user, theme: state.theme }
}

const SettingsAccount = ({ user, theme }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Account Details'));

    return ( user?.user?.is_adviser ?
        <AdviserSettings user={user} theme={theme} /> : <UserSettings user={user} theme={theme} />
    );
};

export default connect(mapStateToProps, null)(SettingsAccount);
