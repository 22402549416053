import { Box, Link } from '@theme-ui/components'
import { Icon } from 'assets/Icon';
import ReactMarkdown from 'react-markdown'
import { ReadMore } from '~/Common';

const getTextParts = (text, pCount = 3) => {
    if (typeof text !== 'string') {
        return [text, false];
    }

    const paragraphs = text.split('\n\n');
    const before = [], after = [];

    paragraphs.forEach((p, i) => i < pCount ? before.push(p) : after.push(p));

    return [
        before.join("\n\n"),
        after.length ? after.join("\n\n") : false
    ];
};

const ExternalLink = ({ children, sx, ...props }) => (
    <Link
        sx={{
            display: 'inline-flex',
            gridGap: '5px',
            ...sx
        }}
        {...props}
    >
        {children}
        <Icon as="span" icon="external-link" sx={{ display: 'flex' }} />
    </Link>
)

const MarkdownComponent = ({ externalLinks, components, ...props }) => (
    <ReactMarkdown
        components={{
            a: externalLinks ? ExternalLink : Link,
            ...components
        }}
        {...props}
    />
)

export const Markdown = ({
    children,
    className,
    components,
    sx,
    showReadMore = false,
    readMoreParagraphs = 3,
    readMoreLabel = 'Show %more%',
    externalLinks = false,
    ...props
}) => {
    const content = showReadMore ? getTextParts(children, readMoreParagraphs) : [children];

    return (
        <Box
            sx={{
                ...sx,
                '& img': {
                    maxWidth: '100%',
                    height: 'auto'
                }
            }}
            className={`markdown ${className ?? ''}`}
            {...props}
        >
            <MarkdownComponent
                components={components}
                externalLinks={externalLinks}
            >
                {content[0]}
            </MarkdownComponent>
            {showReadMore && (
                <ReadMore
                    label={readMoreLabel}
                    wrapper={MarkdownComponent}
                    wrapProps={{
                        externalLinks,
                        components
                    }}
                >
                    {content[1]}
                </ReadMore>
            )}
        </Box>
    )
};
