import { useState } from 'react'
import {
    Box,
    Heading,
    Input,
    Link,
    Text
} from '@theme-ui/components';
import {
    Button
} from '~/Common'
import { Icon } from 'assets/Icon';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { theme as appTheme } from 'app/theme';

export const ShareButton = ({ user, deal, size, buttonStyles, ...props }) => {
    const { t } = useTranslation();
    const [copied, setCopied] = useState(false);
    const [modalOpen, setModalOpen] = useState(false)
    const isAdviser = user?.is_adviser === true;

    const handleButtonClick = (e) => {
        e.preventDefault();
        setModalOpen(true);
    };

    const handleShareClick = (e) => {
        e.preventDefault();

        navigator.clipboard.writeText(deal?.share_link);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 750);
    };

    return (
        <>
            {deal?.share_link && isAdviser ? (
                <>
                    <Modal
                        isOpen={modalOpen}
                        onRequestClose={() => setModalOpen(false)}
                        shouldCloseOnOverlayClick={true}
                        closeTimeoutMS={300}
                        contentLabel={"Copy website link"}
                        style={appTheme.modals.default}
                        aria={{
                            labelledby: "modalTitle"
                        }}
                    >
                        <Heading as="h2" variant="h2" id="modalTitle" mb={4}>{t('Get bidders')}</Heading>
                        <Text as="p" mb={3}>{t('Share the following link with your book to gain expression of interest bidders via the public deal page.')}</Text>
                        <Box
                            sx={{
                                position: 'relative',
                                zIndex: 1
                            }}
                            mb={4}
                        >
                            <Box color="white" sx={{
                                position: 'absolute',
                                textAlign: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                bottom: '120%',
                                zIndex: 0,
                                opacity: copied ? 1 : 0,
                                transition: 'all 0.3s ease-in-out',
                                fontSize: '9px',
                                fontWeight: 'bold',
                                display: 'flex'
                            }}>
                                <Box bg={copied ? "darker" : 'transparent'} sx={{
                                    maxWidth: '100px',
                                    transition: 'background 0.3s ease-in-out',
                                    borderRadius: '4px',
                                    padding: '4px 6px',
                                }}>
                                    {t(copied ? 'Copied!' : null)}
                                </Box>
                            </Box>
                            <Input readonly="readonly" onClick={handleShareClick} bg="light" value={deal?.share_link} sx={{
                                cursor: 'copy',
                            }} />
                        </Box>
                        <Button
                            variant="primary"
                            size="large"
                            sx={{
                                cursor: 'copy',
                            }}
                            onClick={handleShareClick}>
                            Copy link
                        </Button>
                    </Modal>
                    <Box sx={{
                        mt: 'list' === size ? 0 : 3,
                        ml: 'list' === size ? 3 : 0,
                        order: 'list' === size ? 3 : 0
                    }}>
                        <Button variant="bordered" sx={{
                            ...buttonStyles,
                        }} as={Link} title={t('buttons.shareDeal')} href={deal?.share_link} onClick={handleButtonClick}>
                            <Icon size="16" icon="external-link" mr={'list' === size ? 0 : 2} />
                            <Text as="span" sx={{
                                display: 'list' === size ? 'none' : 'inline'
                            }}>{t('buttons.shareDeal')}</Text>
                        </Button>
                    </Box>
                </>
            ) : null}
        </>
    );

};
