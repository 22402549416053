import axios from 'axios'

export const getLoungeExperiences = (params) =>
    axios.get('experiences', params)
        .then(({ data }) => data)

export const getLoungeTrades = (params) =>
    axios.get('trades', params)
        .then(({ data }) => data)

export const purchaseTrades = (free_trades) =>
    axios.post(`trades/purchase`, { free_trades })
        .then(({ data }) => data)

export const purchaseExperience = (uuid) =>
    axios.post(`experiences/purchase`, { experience: uuid })
        .then(({ data }) => data)

export const purchaseCashback = (coins) =>
    axios.post(`cashback/purchase`, { coins: coins })
        .then(({ data }) => data)
