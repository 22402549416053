import {
    Box,
    Grid,
    Heading,
    Image
} from '@theme-ui/components'
import { Container, Markdown } from '~/Common'
import { useTranslation } from 'react-i18next'

export const HighlightCards = ({ bg = 'white', data, container, ...props }) => {
    const { t } = useTranslation();
    const title = data?.title ?? null;
    const description = data?.description ?? null;
    const cards = data?.cards ?? null;

    return (
        <Box bg={bg} {...props}>
            <Container variant="layouts.section" {...container}>
                { title && <Heading as="h2" variant="content_block_heading">{t(title)}</Heading>}
                { description && <Box className="markdown" mb={[4,4,5]}>
                    <Markdown>{description}</Markdown>
                </Box>}

                {cards &&
                    <Grid sx={{
                        gridGap: 4,
                        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(2, 1fr)'],
                        width: '100%'
                    }}>
                        {cards.map((card, key) => (
                            <Box key={key}>
                                { card?.image?.sizes?.icons && <Box mb={2} sx={{
                                    height: '70px',
                                    width: '82px',
                                    mb: 4,
                                    display: 'flex',
                                    alignItems: 'bottom',
                                    justifyContent: 'center'
                                }}>
                                    <Image src={card?.image?.sizes?.icons} sx={{ objectFit: 'contain' }} />
                                </Box>}
                                { card?.title && <Heading as="h3" variant="h3" mb={3} sx={{
                                    fontSize: '22px',
                                    fontWeight: 500
                                }}>{card.title}</Heading> }
                                { card?.description && <Box className="markdown" sx={{ lineHeight: '1.5' }}>
                                    <Markdown externalLinks={true}>{card.description}</Markdown>
                                </Box>}
                            </Box>
                        ))}
                    </Grid>
                }
            </Container>
        </Box>
    )
};
