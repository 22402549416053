import { useState, useEffect, useRef } from 'react';
import {
    Box,
    Flex,
    Grid,
    Heading,
    Text
} from '@theme-ui/components';
import { DashboardLayout } from '~/Layouts';
import {
    Alert,
    Container,
    PageBanner,
    Pagination,
    SwitchView
} from '~/Common';
import {
    Card,
    CardCompact,
    List
} from '~/Deals';
import {
    Card as CompanyCard
} from '~/Companies';
import { Icon } from 'assets/Icon';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setItemLayout } from 'features/theme/themeSlice';
import { getWatchlistDealsAsync, getWatchlistClosedDealsAsync, getWatchlistDeals, getWatchlistClosedDeals, getWatchlistAsync, getWatchlistStatsAsync, getWatchlistCompaniesAsync } from 'features/watchlist/watchlistSlice';
import { smoothScroll } from 'utils/helpers';
import { getWatchlistCompanies } from 'features/watchlist/watchlistAPI';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { theme: state.theme, watchlist: state.watchlist }
}

const WatchlistDashboard = ({ theme, watchlist }) => {
    const defaultResults = new Array(6).fill(null);
    const { t } = useTranslation();
    useDocumentTitle(t('Following'));
    const dispatch = useDispatch();
    const companies = useRef(null);
    const liveDeals = useRef(null);
    const closedDeals = useRef(null);
    const [liveResults, setLiveResults] = useState(defaultResults);
    const [closedResults, setClosedResults] = useState(defaultResults);
    const [companyResults, setCompanyResults] = useState(defaultResults);

    const changeItemLayout = (e, type) => {
        e.preventDefault();
        dispatch(setItemLayout(type));

        const copyResults = [...liveResults];
        setLiveResults(() => {
            return defaultResults;
        });

        setTimeout(() => {
            setLiveResults(() => copyResults);
        }, 750);
    };

    useEffect(() => {
        if(['resync','idle'].includes(watchlist.status)) {
            dispatch(getWatchlistAsync());
        }
        if(['resync','idle'].includes(watchlist.stats.status)) {
            dispatch(getWatchlistStatsAsync());
        }
        if(['resync','idle'].includes(watchlist.deals.status)) {
            dispatch(getWatchlistDealsAsync());
            dispatch(getWatchlistClosedDealsAsync());
        }
        if(['resync','idle'].includes(watchlist.companies.status)) {
            dispatch(getWatchlistCompaniesAsync());
        }

        if(watchlist.deals.status === 'attempted' && watchlist.companies.status === 'attempted'){
            setLiveResults(watchlist.deals.live.results);
            setClosedResults(watchlist.deals.closed.results);
            setCompanyResults(watchlist.companies.results);
        }
    }, [watchlist, dispatch]);

    const closingCount = watchlist?.stats?.counts?.deals_closing_next_24hrs || 0;

    return (
        <DashboardLayout p={0}>
            <PageBanner>
                <Heading as="h1" mt={5} sx={{
                    color: 'white',
                    mb: 4
                }}>{t('Following')}</Heading>
                <Box sx={{
                    borderTop: '1px solid #fff',
                    borderColor: 'light',
                    pt: 3
                }}
                >
                    <Flex sx={{
                        alignItems: 'center',
                        color: 'white',
                        fontWeight: 500
                    }}>
                        <Box sx={{ width: 'calc(100% - 100px)', display: ['block', 'block', 'inline-flex']}}>
                            <Flex sx={{ alignItems: 'center' }} mr={4}><Icon color="white" icon="live-deals" size="15px" mr={2} /> {watchlist.deals?.live?.meta?.total ?? 0} live {watchlist.deals?.live?.meta?.total === 1 ? 'deal' : 'deals'}</Flex>
                            <Flex sx={{ alignItems: 'center' }} mr={4}><Icon color="white" icon="closed-deals" size="15px" mr={2} /> {watchlist.deals?.closed?.meta?.total ?? 0} closed {watchlist.deals?.closed?.meta?.total === 1 ? 'deal' : 'deals'}</Flex>
                        </Box>
                    </Flex>
                </Box>
            </PageBanner>
            <Container variant="layouts.default">
                {closingCount ? <Alert role="alert" variant="primary" mb={3}>You have {closingCount} deal{closingCount !== 1 && 's'} closing in the next 24hrs.</Alert> : ''}

                <Flex sx={{
                    borderBottom: '1px solid',
                    borderColor: 'medium',
                    mb: 4,
                    pb: 2
                }}>
                    <Heading as="h2" variant="h3" id="companies" ref={companies}>{t('Companies following')}</Heading>
                </Flex>
                <Flex mb={[4,5,6]} sx={{
                    flexDirection: 'column',
                    position: 'relative',
                }}>
                {companyResults.length > 0 ?
                    <>
                        <Grid sx={{
                            gridGap: 4,
                            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
                            gridTemplateRows: '1fr',
                            width: '100%',
                            overflowX: 'auto',
                            pt: '10px',
                            pb: '30px',
                            px: 3,
                            mx: -3,
                            boxSizing: 'content-box',
                        }}>
                            {companyResults.map((company, key) => (
                                <Box key={`company-result${key}`}>
                                    <CompanyCard company={company} />
                                </Box>
                            ))}
                        </Grid>

                        <Pagination
                            activePage={watchlist.companies?.meta?.current_page ?? 0}
                            pageCount={watchlist.companies?.meta?.last_page ?? 0}
                            onPageChange={(event) => {
                                const payload = getWatchlistCompanies({ page: (event.selected + 1) });
                                setCompanyResults(defaultResults);
                                setTimeout(() => smoothScroll(companies), 1000);
                                dispatch(getWatchlistCompaniesAsync(payload))
                            }}
                        />
                    </>
                : null}
                </Flex>

                {liveResults.length > 0 && (
                    <Flex sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: 3,
                        mb: '38px',
                        borderBottom: '1px solid #D5D9DA'
                    }}>
                        <Heading as="h2" variant="h3" id="liveDeals" ref={liveDeals}>{t('Live deals for followed companies')}</Heading>
                        <Box>
                            <SwitchView selected={theme.itemLayout} onChange={changeItemLayout} />
                        </Box>
                    </Flex>
                )}

                <Flex mb={[4,5,6]} sx={{
                    flexDirection: 'column',
                    position: 'relative',
                }}>
                    {liveResults.length > 0 ? (
                        <>
                            <Grid sx={{
                                gridGap: 4,
                                gridTemplateColumns: theme.itemLayout === 'grid'
                                    ? ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']
                                    : '1fr',
                                gridTemplateRows: '1fr',
                                width: '100%',
                                overflowX: 'auto',
                                pt: '10px',
                                pb: '30px',
                                px: 3,
                                mx: -3,
                                boxSizing: 'content-box',
                            }}>
                            {liveResults.map((deal, key) => (
                                <Box key={`watchlist-live-deals-${key}`}>
                                    {theme.itemLayout === 'grid' ? (
                                        <Card deal={deal} />
                                    ) : (
                                        <List deal={deal} />
                                    )}
                                </Box>
                            ))}
                            </Grid>

                            <Pagination
                                activePage={watchlist.deals?.live?.meta?.current_page ?? 0}
                                pageCount={watchlist.deals?.live?.meta?.last_page ?? 0}
                                onPageChange={(event) => {
                                    const payload = getWatchlistDeals({
                                        page: (event.selected + 1)
                                    });
                                    setLiveResults(() => defaultResults);
                                    setTimeout(() => smoothScroll(liveDeals), 1000);
                                    dispatch(getWatchlistDealsAsync(payload))
                                }}
                            />
                        </>
                    ) : (
                        <Flex sx={{
                            pt: 6,
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            mx: 'auto',
                            textAlign: 'center'
                        }}>
                            <Icon icon="watchlist-thin" color="tealDark" mb={3} width="65px" />
                            <Heading variant="h2" sx={{ fontSize: '36px' }}>{t('Start following companies')}</Heading>
                            <Text mt={1} variant="subtext">{t('Start following the companies that interest you')}</Text>
                        </Flex>
                    )}
                </Flex>

                <Flex sx={{
                    borderBottom: '1px solid',
                    borderColor: 'medium',
                    mb: 4,
                    pb: 2
                }}>
                    <Heading as="h2" variant="h3" id="closedDeals" ref={closedDeals}>{t('Missed Opportunities from followed companies')}</Heading>
                </Flex>
                <Flex mb={[4,5,6]} sx={{
                    flexDirection: 'column',
                    position: 'relative',
                }}>
                    {closedResults.length > 0 ? (
                        <>
                            <Grid sx={{
                                gridGap: 4,
                                gridTemplateColumns: theme.itemLayout === 'grid'
                                    ? ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']
                                    : '1fr',
                                gridTemplateRows: '1fr',
                                width: '100%',
                                overflowX: 'auto',
                                pt: '10px',
                                pb: '30px',
                                px: 3,
                                mx: -3,
                                boxSizing: 'content-box',
                            }}>
                            {closedResults.map((deal, key) => (
                                <Box key={`watchlist-closed-deals-${key}`}>
                                    {theme.itemLayout === 'grid' ? (
                                        <CardCompact deal={deal} />
                                    ) : (
                                        <List deal={deal} />
                                    )}
                                </Box>
                            ))}
                            </Grid>

                            <Pagination
                                activePage={watchlist.deals?.closed?.meta?.current_page ?? 0}
                                pageCount={watchlist.deals?.closed?.meta?.last_page ?? 0}
                                onPageChange={(event) => {
                                    const payload = getWatchlistClosedDeals({
                                        page: (event.selected + 1)
                                    });
                                    setClosedResults(() => defaultResults);
                                    setTimeout(() => smoothScroll(closedDeals), 1000);
                                    dispatch(getWatchlistClosedDealsAsync(payload))
                                }}
                                sx={{ mt: 5 }}
                            />
                        </>
                    ) : (
                        <Text as="p">{t('You\'re not following any closed deals at this time.')}</Text>
                    )}
                </Flex>

            </Container>
        </DashboardLayout>
    );
};

export default connect(mapStateToProps, null)(WatchlistDashboard);
