import { Icon } from "assets/Icon";
import Modal from "react-modal";
import { Box, Close, Flex, Heading, Spinner, Text } from "theme-ui";
import { Pagination, Table } from "~/Common";
import { theme as appTheme } from 'app/theme';
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { DATE_FORMAT } from "app/constants";
import { fetchUserTransactions } from "features/auth/authAPI";
import { getUserTransactionsAsync } from "features/auth/authSlice";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state) => {
    return { user: state.user?.user }
}

const columns = [
    {
        key: 'details',
        title: 'Details',
        width: 'auto',
        render: transaction => transaction?.details?.message
    },
    {
        key: 'created_at',
        title: 'Date',
        width: '220px',
        render: transaction => moment(transaction.created_at).format(DATE_FORMAT.datetime_format_friendly)
    },
    {
        key: 'value',
        title: 'LQD Earned',
        width: '130px',
        align: 'right',
        render: (transaction) => {
            return <Flex sx={{
                alignContent: 'center',
                justifyContent: 'right'
            }}>
                <Icon icon="coin" size="16" mr={2} color={transaction.value > 0 ? "tealDark" : "darker"} /><Text sx={{ fontWeight: 700, color: (transaction.value > 0 ? "tealDark" : "darker") }}>{transaction.value.toFixed(0)}</Text>
            </Flex>;
        }
    }
]

export const TransactionsModal = connect(mapStateToProps)(
    ({ user, isOpen, handleClose, loading, transactions, ...props }) => {
        const dispatch = useDispatch();
        const smoothScroll = () => {
            window.scrollTo({ behavior: 'smooth', top: transactions.current.offsetTop });
        };
        const { t } = useTranslation();

        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={appTheme.modals.transactions}
                shouldCloseOnOverlayClick={false}
                closeTimeoutMS={300}
                contentLabel={t("Recent Transactions")}
                aria={{
                    labelledby: "recentTransactions"
                }}
                {...props}
            >
                <Close ml="auto" mr={-2} onClick={handleClose} sx={{
                    position: 'absolute',
                    right: '30px',
                    top: '20px',
                    zIndex: 101
                }} />
                {loading ? <Box sx={{
                    justifyContent: 'center',
                    display: 'flex',
                    minHeight: '320px',
                    alignItems: 'center'
                }}>
                    <Spinner variant="styles.spinner" />
                </Box> :
                user?.transactions?.data?.length ? (
                    <Box pb={3}>
                        <Box ref={transactions} py={3} sx={{ maxHeight: '70vh', overflowY: 'auto', paddingTop: '35px' }}>
                            <Box sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: '60px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 99,
                                backgroundColor: 'darker',
                            }}>
                                <Heading id="recentTransactions" variant="h4" sx={{
                                    color: 'lighter'
                                }}>{t('Recent Transactions')}</Heading>
                            </Box>

                            <Table
                                data={user.transactions.data}
                                columns={columns}
                                isLoading={!Array.isArray(user.transactions.data)}
                                my={3}
                                minWidth={['700px', '700px', '700px', "100%"]}
                                sx={{ borderRadius: 0, boxShadow: 'none' }}
                                mapRowKey={(row) => row.created_at}
                                emptyTemplate={{
                                    icon: 'portfolio',
                                    heading: 'Your Portfolio is empty',
                                    content: 'Start investing',
                                    button: {
                                        title: 'See deals',
                                        to: '/deals'
                                    }
                                }}
                            />
                        </Box>

                        <Pagination
                            activePage={user?.transactions?.meta?.current_page ?? 0}
                            pageCount={user?.transactions?.meta?.last_page ?? 0}
                            onPageChange={(event) => {
                                const payload = fetchUserTransactions({
                                    params: {
                                        page: (event.selected + 1)
                                    }
                                });
                                smoothScroll();
                                dispatch(getUserTransactionsAsync(payload));
                            }}
                        />
                    </Box>
                ) : (
                    <Flex sx={{
                        py: 6,
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        maxWidth: '355px',
                        mx: 'auto',
                        textAlign: 'center'
                    }}>
                        <Icon icon="coin" color="tealDark" mb={2} width="84px" />
                        <Heading variant="h2" sx={{ fontSize: '36px' }}>{t('liquidityDollars.title')}</Heading>
                        <Text mt={1} variant="subtext">{t('liquidityDollars.description')}</Text>
                    </Flex>
                )}
            </Modal>
        )
    }
);
