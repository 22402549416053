import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Heading,
    Text,
    NavLink as ThemedNavLink
} from '@theme-ui/components';
import { Icon } from 'assets/Icon';
import { NavLink } from 'react-router-dom';
import { ProfileMenu as ProfileMenuStyled, ProfileMenuBox, MenuUl } from './styled';
import { useTranslation } from 'react-i18next';
import { setShowProfileMenu } from 'features/theme/themeSlice';
import { theme } from 'app/theme';
import { useMenuClose } from 'hooks/useMenuClose';
import { Skeleton, SwitchProfile } from '~/Common';
import { MAX_PROFILES_PER_USER, APP_FEATURES } from 'app/constants';

export const MenuItem = ({ label, name, path, icon, items, icon_color, title, ...props }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    return (
        <li className={open ? 'focused' : ''}>
            <ThemedNavLink
                as={NavLink}
                id={`ProfileMenu_${name}`}
                to={path}
                variant="link"
                title={title ?? label}
                onClick={() => {
                    dispatch(setShowProfileMenu(false));
                }}
                {...props}
            >
                {icon && <Icon sx={{ color: icon_color ?? 'tealDark' }} icon={icon} size="24px" className="header__navIcon" mr={3} />}
                <Text className="header__navText">{t(label)}</Text>
                {Array.isArray(items) ? (
                    <Button variant="link" className="header__dropdownIcon" ml={2} onClick={() => setOpen(!open)}><Icon icon="chevron-down" size="24" /></Button>
                ) : null}
            </ThemedNavLink>
            {Array.isArray(items) ? (
                <MenuUl>
                    {items.map((subItem) => (
                    <MenuItem
                        key={subItem.name}
                        {...subItem}
                        />
                    ))}
                </MenuUl>
            ) : null}
        </li>
    );
};

export const ProfileMenu = ({ isOpen, buttonRef, ...props }) => {
    const user = useSelector((state) => state.user?.user);
    const adviser = useSelector((state) => state.user?.adviser);
    const isAdviser = useSelector((state) => state.user?.user?.is_adviser);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const menuRef = useRef(null);
    const [ showSwitchProfile, setShowSwitchProfile ] = useState(false);
    const profileCount = useSelector((state) => state.user?.user?.profiles ? state.user.user.profiles.length : 1);
    const activeProfile = useSelector((state) => state.user?.activeProfile);
    const certificateExpired = user?.certificate_status === 'Expired';
    const settings = [
        { name: 'account', path: '/settings/account', label: 'Account Details', title: certificateExpired ? 'Account Details: s708 certificate expired' : 'Account Details', icon: certificateExpired ? 'certificate-alt' : 'user', icon_color: certificateExpired ? 'warning' : 'tealDark' },
        { name: 'entities', path: '/settings/entities', label: 'My Entities', icon: 'users', hidden: isAdviser },
        { name: 'my-advisers', path: '/settings/advisers', label: 'My Advisers', icon: 'users', hidden: Boolean(!APP_FEATURES.adviser_invites || isAdviser || adviser) },
        { name: 'preferences', path: '/settings/preferences', label: 'Deal Preferences', icon: 'cog' },
        { name: 'notifications', path: '/settings/notifications', label: 'Notifications', icon: 'bell-thick' },
        { name: 'get-rewards', path: '/settings/get-rewards', label: 'Get Rewards', icon: 'gift', hidden: Boolean(!APP_FEATURES.peer_invites || isAdviser) },
    ];

    const options = [
        { name: 'contact', path: '/contact', label: 'Contact Us', icon: 'users' },
        { name: 'terms', path: '/terms', label: 'Terms & Privacy', icon: 'cog' },
    ];

    // Close menu if esc is pressed, or if click outside of reference
    useMenuClose([menuRef, buttonRef], () => {
        if (isOpen) {
            dispatch(setShowProfileMenu(false));
            setShowSwitchProfile(false);
        }
    });

    return (
        <ProfileMenuStyled ref={menuRef} role="dialog" tabIndex="-1" className={isOpen ? 'open' : ''} {...props}>
            <Box sx={{ overflowY: 'auto', px: 2, height: '100%', paddingBottom: ['20px', null, null, 0], maxHeight: 'calc(100vh - 200px)' }} role="navigation" aria-label="Profile Navigation">
                {user ? (
                    <>
                        {!isAdviser ? (
                            <>
                                <ProfileMenuBox>
                                    <MenuUl>
                                        {profileCount > 1 &&
                                            <MenuItem
                                                path="#switch-entity"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setShowSwitchProfile(true);
                                                }}
                                                icon="switch"
                                                label={t('header.switchProfile')}
                                            />
                                        }
                                        {profileCount < MAX_PROFILES_PER_USER &&
                                            <MenuItem
                                                path="/settings/entities/create"
                                                variant="menuitem"
                                                label={t('header.addProfile')}
                                                icon="add-user"
                                            />
                                        }
                                    </MenuUl>
                                </ProfileMenuBox>
                                <ProfileMenuBox>
                                    <Heading variant="profile_section" mb={2}>{t('profileMenu.balances.title')}</Heading>
                                    <Box variant="styles.menuitem" title={`${t('cashBalance.title')}\nLast Updated: ${activeProfile?.cash_balance_updated_at ?? 'N/A'}`}>
                                        <Icon sx={{ color: 'tealDark' }} icon="dollar" size="24px" mr={3} />
                                        <Text sx={{ color: theme.colors.darker, fontWeight: 500 }}>${activeProfile?.cash_balance ?? "0.00"}</Text>
                                    </Box>
                                    <ThemedNavLink
                                        as={NavLink}
                                        to="/coins"
                                        id={`ProfileMenu_coins`}
                                        variant="menuitem"
                                        onClick={() => {
                                            dispatch(setShowProfileMenu(false));
                                        }}
                                        title={`${t('liquidityDollars.title')}`}
                                    >
                                        <Icon sx={{ color: 'tealDark' }} icon="coin-thick" size="24px" mr={3} />
                                        <Text sx={{ color: theme.colors.darker, fontWeight: 500 }}>{t('header.lqd')} {user?.coins ?? 0}</Text>
                                    </ThemedNavLink>
                                </ProfileMenuBox>
                            </>
                        ) : null}

                        <ProfileMenuBox>
                            <Heading variant="profile_section" mb={2}>{t('Settings')}</Heading>
                            <MenuUl>
                                {settings.map((subItem) => subItem.hidden === true ? null : (
                                    <MenuItem
                                        key={subItem.name}
                                        {...subItem}
                                    />
                                ))}
                            </MenuUl>
                        </ProfileMenuBox>
                        <ProfileMenuBox>
                            <Heading variant="profile_section" mb={2}>{t('More options')}</Heading>
                            <MenuUl>
                                {options.map((subItem) => subItem.hidden === true ? null : (
                                    <MenuItem
                                        key={subItem.name}
                                        {...subItem}
                                    />
                                ))}
                            </MenuUl>
                        </ProfileMenuBox>
                        <ProfileMenuBox>
                            <ThemedNavLink
                                as={NavLink}
                                id={`ProfileMenu_logout`}
                                to="/logout"
                                variant="menuitem"
                            >
                                <Icon sx={{ color: 'tealDark' }} icon="logout" size="24px" mr={3} />
                                <Text className="header__navText">{t('Logout')}</Text>
                            </ThemedNavLink>
                        </ProfileMenuBox>
                    </>
                ) : (
                    <>
                        <Skeleton height="24px" mb={2} />
                        <Skeleton height="24px" mb={2} />
                        <Skeleton height="24px" mb={2} />
                        <Skeleton height="24px" mb={2} />
                        <Skeleton height="24px" mb={2} />
                    </>
                )}
            </Box>
            <SwitchProfile
                isOpen={showSwitchProfile}
                className="header__switchProfile"
                onChange={() => {
                    dispatch(setShowProfileMenu(false));
                    setShowSwitchProfile(false);
                }}
            />
        </ProfileMenuStyled>
    );
};
