import { Box } from '@theme-ui/components'

export const PageOverlay = ({ children, show = false, ...props }) => {

    return (
        <Box sx={{
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: 'rgba(0,0,0,0.4)',
            zIndex: 80,
            transition: 'opacity 0.5s ease-in-out',
            opacity: show ? 1 : 0,
            pointerEvents: show ? 'all' : 'none'
        }} {...props} />
    );
};
