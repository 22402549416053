import styled from '@emotion/styled';
import { theme } from 'app/theme';

export const DateWrap = styled.div`
    position: relative;
    font-family: 'Favorit';

    .SingleDatePicker {
        width: 100%;
        display: block;

        &_picker {
            z-index: 101;
        }

        .SingleDatePickerInput {
            width: 100%;
            display: inline-flex;
            border-radius: ${theme.forms.input.borderRadius};
            border: 1px solid ${(props) => (props.invalid ? theme.colors.error : (props.focused || props.isHovered ? theme.colors.tealDark : theme.colors.medium))};
            transition: border-color 0.3s ease-in-out;
        }

        .SingleDatePickerInput_calendarIcon path,
        .SingleDatePickerInput_calendarIcon rect {
            stroke: ${(props) => (props.invalid ? theme.colors.error : (props.focused || props.isHovered ? theme.colors.tealDark : theme.colors.dark))};
        }

        .DateInput {
            width: 100%;
            background: transparent;

            &_input {
                font-size: 16px;
                font-family: 'Favorit';
                color: ${theme.colors.darker};
                padding: 13px 11px 13px 14px;
                border: none;
                background: transparent;

                &__focused {
                    border: none;
                    font-weight: 700;
                }
            }
        }

        .CalendarDay__selected,
        .CalendarDay__selected:active,
        .CalendarDay__selected:hover {
            background: ${theme.colors.primary};
            border: 1px double ${theme.colors.primary};
            color: white;
        }
    }
`;
