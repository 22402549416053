import React, { useState, useContext } from 'react';
import * as Yup from 'yup';
import {
    Alert,
    Box,
    Close,
    Heading,
    Flex,
    Paragraph,
    Label,
    Themed,
    Spinner
} from 'theme-ui';
import { Button } from '~/Common'
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OnboardingContext } from 'pages/Onboarding/Landing';
import { mobileSendVerification, mobileVerify, getOnboardingStatusAsync } from 'features/auth/authSlice';
import { toast } from 'utils/toast';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import ReactMarkdown from 'react-markdown'
import { theme } from 'app/theme';

const validationSchema = Yup.object().shape({
    mobile: Yup.string().required(),
    extension: Yup.string().required()
});

const validationVerifySchema = Yup.object().shape({
    code: Yup.string().required().test('len', 'must be exactly 6 characters', val => val !== undefined && val.length === 6)
});

const OnboardingMobile = () => {
    const onboarding = useContext(OnboardingContext);
    const dispatch = useDispatch();
    const settings = useSelector(state => state.theme?.settings);
    const [fullMobile, setFullMobile] = useState('');
    const [status, setStatus] = useState('mobile');
    const [resending, setResending] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const { t } = useTranslation();
    const extensions = [
        {
            value: '+61',
            label: '+61'
        }
    ];

    const handleSubmit = (values, actions) => {
        setFullMobile(values.extension + values.mobile);
        mobileSendVerification({
            mobile: values.extension + values.mobile
        }).then((response) => {
            toast.success(response?.message, {
                toastId: 'mobile-success',
            });
            setStatus('code');
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(response?.message || t('onboarding.landing.mobileVerify.error'), {
                toastId: 'mobile-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    };

    const handleVerifySubmit = (values, actions) => {
        mobileVerify({
            mobile: fullMobile,
            code: values.code
        }).then((response) => {
            toast.success(response?.message, {
                toastId: 'mobile-success',
            });
            onboarding.nextStep();
            dispatch(getOnboardingStatusAsync());
            setStatus('done');
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(response?.data?.message || t('onboarding.landing.mobile.error'), {
                toastId: 'mobile-error',
            });
            actions.resetForm();
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    };

    const resendVerification = (e) => {
        e.preventDefault();
        setResending(true);

        mobileSendVerification({
            mobile: fullMobile
        }).then((response) => {
            toast.success(response?.message, {
                toastId: 'mobile-success',
            });
            setStatus('code');
            setResending(false);
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('onboarding.landing.mobileVerify.error'), {
                toastId: 'mobile-error',
            });
            setResending(false);
        });
    };

    const showPrivacy = (e) => {
        e.preventDefault();
        setModalTitle('Privacy Policy');
        setModalContent(settings?.privacy_policy);
        setModalOpen(true);
    };
    const showTerms = (e) => {
        e.preventDefault();
        setModalTitle('Terms of Use');
        setModalContent(settings?.terms_of_use);
        setModalOpen(true);
    };
    const closeModal = (e) => {
        e.preventDefault();
        setModalOpen(false);
    };

    return (
        <>
        <Modal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            style={theme.modals.terms}
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={300}
            contentLabel={t(modalTitle)}
            aria={{
                labelledby: "modalTitle"
            }}
        >
            <>
                <Box>
                    <Heading as="p" variant="h1" id="modalTitle" mb={4}>{modalTitle}</Heading>
                    <Box sx={{
                        variant: 'styles',
                        maxHeight: '50vh',
                        bg: 'lighter',
                        p: 3,
                        overflow: 'auto'
                    }}>
                        <ReactMarkdown>{modalContent}</ReactMarkdown>
                    </Box>

                    <Button variant="primary" sx={{
                        mt: 4,
                        width: '100%',
                        maxWidth: '280px'
                    }} type="button" onClick={closeModal}>{t('Close')}</Button>
                </Box>
            </>
        </Modal>

        {status === 'code' ? <>
            <Formik
                enableReinitialize
                initialValues={{
                    code: ''
                }}
                validationSchema={validationVerifySchema}
                onSubmit={handleVerifySubmit}
            >
                {({ status, setStatus, validateField, submitForm, isSubmitting }) => (
                    <Box>
                        <Form>
                            { status === 'api_error' ? (
                                <Alert role="alert" variant='danger' mb={3}>
                                    {t('onboarding.landing.mobileVerify.error')}
                                    <Close variant="closeWhite" ml="auto" mr={-2} onClick={() => setStatus(null)} />
                                </Alert>
                            ) : ''}

                            <Heading as="h1" variant="h3" sx={{ fontWeight: 500 }} mb={4}>
                                {t('onboarding.landing.mobileVerify.title')}
                            </Heading>

                            <Paragraph mb={4} sx={{
                                fontSize: 2,
                                color: 'dark'
                            }}>
                                {t('onboarding.landing.mobileVerify.subtitle', { mobile: fullMobile })}
                            </Paragraph>

                            <Label htmlFor="mobile" sx={{ fontSize: '16px', color: 'dark' }} mb={2}>
                                {t('onboarding.landing.mobileVerify.label')}
                            </Label>

                            {isSubmitting ? (
                                <Box my={2}>
                                    <Spinner variant="styles.spinner" />
                                </Box>
                            ) : (
                                <Box id="verifyCodeForm">
                                    <Field
                                        field="verify"
                                        label="Code"
                                        name="code"
                                        type="number"
                                        validateField={validateField}
                                        sx={{ mt: 3 }}
                                        onChange={(field) => {
                                            if (field.value.length >= 6) {
                                                submitForm();
                                            }
                                        }}
                                    />
                                    <Box
                                        mb={5}
                                        pl={0}
                                        sx={{
                                            fontSize: '13px',
                                            color: 'dark',
                                            '& a': {
                                                color: 'tealDark'
                                            }
                                        }}
                                    >
                                        {t('Didn\'t receive your code?')} {resending ? <>Resending...</> : <Themed.a as={Link} to="#resend" onClick={resendVerification}>Resend</Themed.a>}
                                    </Box>
                                </Box>
                            )}
                        </Form>
                    </Box>
                )}
            </Formik>
        </> : <>
            <Formik
                enableReinitialize
                initialValues={{
                    mobile: '',
                    extension: '+61'
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ status, setStatus }) => (
                    <Box>
                        { status === 'api_error' ?
                        <Alert role="alert" variant='danger' mb={2}>
                            {t('onboarding.landing.mobile.error')}
                            <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                        </Alert> : ''}

                        <Form>
                            <Heading as="h1" mb={'24px'} variant="h3" sx={{ fontWeight: 500 }}>
                                {t('onboarding.landing.mobile.title')}
                            </Heading>

                            <Label htmlFor="mobile" mb={'64px'} sx={{ fontSize: '14px', color: 'dark' }}>
                                {t('onboarding.landing.mobile.subtitle')}
                            </Label>

                            <Flex sx={{
                                justifyContent: "flex-start",
                                alignContent: "center"
                            }}>
                                <Box mr={2} sx={{
                                    width: ['40%', '30%']
                                }}>
                                    <Field
                                        field="select"
                                        label="Ext"
                                        name="extension"
                                        options={extensions}
                                        sx={{ bg: 'secondary' }}
                                        showLabel={false}
                                    />
                                </Box>

                                <Box sx={{ flex: 1 }}>
                                    <Field
                                        label="Phone Number"
                                        name="mobile"
                                        field="phone"
                                    />
                                </Box>
                            </Flex>

                            <Box mb={5}>
                                <Paragraph sx={{
                                    '&': { color: 'dark', fontSize: '14px' },
                                    '& a': { color: 'tealDark', fontSize: '14px' }
                                }}>
                                    By continuing, you accept our{' '}<Themed.a as={Link} to="#terms" onClick={showTerms}>Terms of use</Themed.a>{' '}and{' '}<Themed.a as={Link} to="#privacy" onClick={showPrivacy}>Privacy policy</Themed.a>
                                </Paragraph>
                            </Box>

                            <Submit variant="primary" id="mobileSubmit" text={t('onboarding.landing.mobile.submit')} sx={{ display: 'block', width: '100%' }} />
                        </Form>
                    </Box>
                )}
            </Formik>
        </>}
        </>
    )
};

export default OnboardingMobile;
