import { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Link,
    Spinner,
    Text
} from '@theme-ui/components'
import { connect, useDispatch } from 'react-redux';
import { storeStagesAsync, setWatchlistStatus } from 'features/watchlist/watchlistSlice';
import { Icon } from 'assets/Icon';
import { Button } from '../Button';
import { toast } from 'utils/toast';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return { user: state.user?.user, theme: state.theme, watchlist: state.watchlist }
}

const PreferenceStage = ({ user, theme, watchlist, showToast = true, showSave = true, preSelect = false, children, ...props }) => {
    const dispatch = useDispatch();
    const [isLoaded, setLoaded] = useState(false);
    const [selectedStages, setStages] = useState([]);
    const [isTouched, setTouched] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (!isLoaded && watchlist.status === 'attempted') {
            setStages( watchlist.watchlist.stages );
            setLoaded(true);
        }
    }, [watchlist, isLoaded]);

    useEffect(() => {
        if (preSelect && theme.status === 'attempted') {
            setStages( theme.stages.map(s => s.uuid) );
        }
    }, [preSelect, theme]);

    const toggleAll = () => {
        if(Array.isArray(selectedStages) && Array.isArray(theme.stages)){
            setStages(
                theme.stages.length === selectedStages.length
                    ? []
                    : theme.stages.map(c => c.uuid)
            )
            setTouched(true);
        }
    }

    const handleSave = () => {
        setTouched(false);
        return dispatch(storeStagesAsync({
            stages: selectedStages
        }))
            .then(() => {
                if (showToast) {
                    toast.success(t('settings.preferences.update_stage.success'), {
                        toastId: 'preferences-update-stage',
                    });
                }
                dispatch(setWatchlistStatus('resync'));
            });
    }

    const selectStage = (uuid) => {
        if (!Array.isArray(selectedStages)) {
            return;
        }

        setTouched(true);

        if (selectedStages.includes(uuid)) {
            setStages(s => s.filter(item => item !== uuid));
        } else {
            setStages([
                ...selectedStages,
                uuid
            ]);
        }
    };

    const renderChildren = () => {
        if (typeof children === 'function') {
            return children(handleSave);
        } else {
            return children;
        }
    }

    const isSelected = uuid => Array.isArray(selectedStages) && selectedStages.includes(uuid)
    const isAllSelected = Array.isArray(selectedStages) && selectedStages.length === theme.stages.length;

    return Array.isArray(theme.stages) && theme.stages.length ? (
        <>
            <Grid mb={4} sx={{
                gridGap: 4,
                gridTemplateColumns: ['1fr', 'repeat(3, 1fr)', 'repeat(4, 1fr)'],
                width: '100%',
                maxHeight: ['none', 'calc(100vh - 410px)'],
                overflowY: ['visible', 'auto']
            }}>
                {theme.stages.length > 0 && (
                    <>
                    {theme.stages.map((stage, key) => (
                        <Box className="btn" as={Link} to="#selectStage" key={key} sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            position: 'relative',
                            textDecoration: 'none',
                            border: '1px solid',
                            borderRadius: '8px',
                            padding: '22px 15px',
                            borderColor: isSelected(stage.uuid) ? 'light' : 'medium',
                            bg: isSelected(stage.uuid) ? 'light' : 'white',
                            '&:hover': {
                                background: '#F7F8F9'
                            }
                        }} onClick={(e) => {
                            e.preventDefault();
                            selectStage(stage.uuid);
                        }}>
                            <Text color={isSelected(stage.uuid) ? "darker" : "dark"} sx={{
                                fontSize: '14px',
                                fontWeight: 700
                            }}>{stage.name}</Text>
                            <Box ml={'auto'} sx={{ display: 'flex', alignContent: 'center' }}>
                                {isSelected(stage.uuid) ? <Icon icon="done-white" size="11" /> : <Icon icon="plus" color="dark" size="11" />}
                            </Box>
                        </Box>
                    ))}
                    </>
                )}
            </Grid>
            {showSave && <Button className="saveChanges" disabled={!isTouched || !showSave} onClick={handleSave} mr={2}>Save Changes</Button>}
            <Button ml={2} mr={2} className="toggleAll" variant="secondary" onClick={toggleAll}>{isAllSelected ? 'Unselect All' : 'Select All'}</Button>
            {renderChildren()}
        </>
    ) : (
        <Spinner variant="styles.spinner" mx="0" mt={1} />
    )
};

export default connect(mapStateToProps, null)(PreferenceStage);
