import { useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';
import {
    Box,
    Grid,
    Flex,
    Text
} from '@theme-ui/components';
import { Field } from '~/Forms';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getFiltersAsync } from 'features/theme/themeSlice';
import { DealTypeOptions, DealSortByOptions } from 'app/constants';

const fieldWrapStyles = {
    minWidth: '191px',
    width: ['100%','100%','100%','100%','auto'],
    mx: 3
};

const selectStyles = {
    py: '12px'
}

export const SubmitListener = ({ values, initialValues, submitForm, ...props }) => {
    const [lastValues, setLastValues] = useState(values);

    useEffect(() => {
        const valuesEqualLastValues = isEqual(lastValues, values);
        const valuesEqualInitialValues = values === initialValues;

        if (!valuesEqualLastValues) {
            setLastValues(values);
        }

        if (!valuesEqualLastValues && !valuesEqualInitialValues) {
            submitForm();
        }
    }, [
        lastValues,
        values,
        initialValues,
        submitForm
    ]);

    return null;
};

export const Filters = ({ handleSubmit, formRef, ...props }) => {
    const dispatch = useDispatch();
    const theme = useSelector(s => s.theme);
    const [stageOptions, setStageOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);

    useEffect(() => {
        if (theme.status === 'idle') {
            dispatch(getFiltersAsync());
        }
    }, [dispatch, theme]);

    useEffect(() => {
        if (theme.status === 'attempted') {
            let options = [];
            theme.stages.map(stage => options.push({ label: stage.name, value: stage.uuid }));
            setStageOptions(options);

            options = [];
            theme.categories.map(category => options.push({ label: category.name, value: category.uuid }));
            setCategoryOptions(options);
        }
    }, [theme]);

    return (
        <Formik
            enableReinitialize
            innerRef={formRef}
            initialValues={{
                type: '',
                stage: '',
                category: '',
                sortBy: ''
            }}
            onSubmit={handleSubmit}
        >
            {({ values, initialValues, submitForm }) => (
                <Form>
                    <Grid sx={{
                        gridGap: [3,2],
                        gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)', 'repeat(4, 1fr)'],
                        width: '100%'
                    }}>
                        <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Text as="p" variant="filter_label">Show me</Text>
                            <Box sx={fieldWrapStyles}>
                                <Field name="type" label="Type" field="select" options={DealTypeOptions} groupMb={0} sx={selectStyles} placeholder="Filter by type" />
                            </Box>
                        </Flex>

                        {stageOptions.length > 0 && (
                            <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Text as="p" variant="filter_label">filtered by</Text>
                                <Box sx={fieldWrapStyles}>
                                    <Field name="stage" label="Stage" field="select" options={stageOptions} groupMb={0} sx={selectStyles} placeholder="Filter by stage" />
                                </Box>
                            </Flex>
                        )}
                        {categoryOptions.length > 0 && (
                            <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Text as="p" variant="filter_label">in</Text>
                                <Box sx={fieldWrapStyles}>
                                    <Field name="category" label="Theme" field="select" options={categoryOptions} groupMb={0} sx={selectStyles} placeholder="Filter by theme" />
                                </Box>
                            </Flex>
                        )}
                        <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Text as="p" variant="filter_label">sorted by</Text>
                            <Box sx={{
                                ...fieldWrapStyles
                            }}>
                                <Field name="sortBy" label="Sorting By" field="select" options={DealSortByOptions} groupMb={0} sx={selectStyles} placeholder="Sorting by" />
                            </Box>
                        </Flex>
                    </Grid>
                    <SubmitListener values={values} initialValues={initialValues} submitForm={submitForm} />
                </Form>
            )}
        </Formik>
    );
};
