import axios from 'axios';

export const storeAdviserInvite = (params) =>
    axios.post('advisers/invite', params)
        .then(({ data }) => data);

export const storeAdviserInviteUser = (params) =>
    axios.post('advisers/invite-user', params)
        .then(({ data }) => data);

export const unlinkAdviser = (uuid) =>
    axios.get(`advisers/unlink/${uuid}`)
        .then(({ data }) => data);

export const unlinkUser = (uuid) =>
    axios.get(`advisers/unlink/${uuid}`)
        .then(({ data }) => data);

export const resendAdviserInviteUser = (uuid) =>
    axios.get(`advisers/resend-invite-user/${uuid}`)
        .then(({ data }) => data);
