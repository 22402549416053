import { Box, Flex, NavLink as ThemedNavLink } from '@theme-ui/components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { switchBackToAdviser, getUserAsync } from 'features/auth/authSlice'

export const Navigation = () => {
    const adviser = useSelector((state) => state.user?.adviser);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSwitchBackToAdviser = (e) => {
        e.preventDefault();
        dispatch(switchBackToAdviser());

        setTimeout(() => {
            dispatch(getUserAsync());
        }, 500);
    };

    return adviser ?
    (
        <Flex id="adviserNavigation" role="navigation" aria-label="Adviser Navigation" sx={{ background: 'black', color: 'white', py: 1, px: 3, width: '100%', justifyContent: 'center', alignItems: 'center' }} >
            <Box>
                <ThemedNavLink to="/adviser/dashboard" as={NavLink} color="white" id="switchBackToAdviser" onClick={(e) => handleSwitchBackToAdviser(e)}>
                    {t('Switch back to {{name}}', { name: `${adviser.first_name} ${adviser.last_name}` })}
                </ThemedNavLink>
            </Box>

            <Box sx={{ ml: 'auto' }}>
                <ThemedNavLink to="/logout" as={NavLink} color="white">
                    {t('Logout')}
                </ThemedNavLink>
            </Box>
        </Flex>
    ) : null;
};
