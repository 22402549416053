import { Box, Close, Heading, Text } from '@theme-ui/components';
import { Button } from '~/Common';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'theme-ui';
import { theme } from 'app/theme';
import { useTranslation } from 'react-i18next';

export const ProfileCreateCancel = ({ uuid = '', isLocked, size, label, sx, ...props }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const cancelProfileCreate = (e, uuid) => {
        e.preventDefault();

        if (isLocked){
            history.push('/settings/entities');
            return;
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ThemeProvider theme={theme}>
                        <Box
                            bg="white"
                            px={5}
                            py={4}
                            sx={{
                                maxWidth: '600px',
                                width: '100%',
                                textAlign: 'center',
                                boxShadow: '0 20px 75px rgb(0 0 0 / 23%)',
                                position: 'relative',
                            }}
                        >
                            <Close
                                onClick={onClose}
                                sx={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                }}
                            ></Close>

                            <Heading as="h2" variant="h2" mb={3}>
                                {t('Are you sure?')}
                            </Heading>
                            <Text as="p" mb={3}>
                                {t('Are you sure to want to cancel creating this entity?')}
                            </Text>
                            {uuid ? (
                                <Text as="p" mb={3}>
                                    {t("We will save your progress so you can continue later.")}
                                </Text>
                            ) : null}
                            <Button
                                variant="primary"
                                mr={3}
                                onClick={() => {
                                    history.push('/settings/entities');
                                    onClose();
                                }}
                            >
                                {t('Yes')}
                            </Button>
                            <Button variant="link" onClick={onClose}>
                                {t('Cancel')}
                            </Button>
                        </Box>
                    </ThemeProvider>
                );
            },
        });
    };

    return (
        <Button variant="light" onClick={(e) => cancelProfileCreate(e, uuid)} size={size ?? 'large'} sx={{ ...sx }} {...props}>
            {t(label ?? 'Cancel')}
        </Button>
    );
};
