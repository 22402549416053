import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { Box, Flex, Heading, Link, Spinner, Text } from "theme-ui"
import { dismissHoldingsTransferAsync, setUser, storeProfileAccountManagementType } from 'features/auth/authSlice';
import { Container } from "~/Common/Container"
import { ProgressCircle } from "~/Common/ProgressCircle";
import { Button } from "../Button";
import { Steps } from "./Steps";
import { setShowVerifySteps } from "features/theme/themeSlice";
import { toast } from "utils/toast";
import { useHistory } from "react-router-dom";

export const VerifySteps = connect(
    (state) => ({ user: state.user?.user, settings: state.theme?.settings, showVerifySteps: state.theme?.showVerifySteps, activeProfile: state.user?.activeProfile }),
    { dismissHoldingsTransfer: dismissHoldingsTransferAsync }
)(
    ({ user, settings, showVerifySteps, activeProfile, dismissHoldingsTransfer, ...props }) => {
        const [currentStep, setCurrentStep] = useState(false);
        const [steps, setSteps] = useState(false);
        const { t } = useTranslation();
        const [isLoading, setLoading] = useState(false);
        const dispatch = useDispatch();
        const history = useHistory();
        const individualProfile = activeProfile?.type === 'individual';
        const finaliseEntityLocation = user?.finished_onboarding_details ? !individualProfile ? `/settings/entities/${activeProfile?.uuid}` : '/settings/account' : '/onboarding';

        useEffect(() => {
            const newSteps =
             [
                {
                    label: t('verifySteps.canBid.label'),
                    heading: t('verifySteps.canBid.heading'),
                    description: t('verifySteps.canBid.description'),
                    complete: user.can_place_bids
                },
                {
                    label: t('verifySteps.finaliseEntity.label'),
                    heading: t('verifySteps.finaliseEntity.heading'),
                    description: t('verifySteps.finaliseEntity.description', {
                        finalise: <Link href={finaliseEntityLocation} sx={{ color: 'dark' }}>finalise</Link>,
                    }),
                    showFinaliseEntity: true,
                    complete: user?.finished_onboarding_details && activeProfile?.can_bid,
                },
                {
                    label: t('verifySteps.openTrading.label'),
                    heading: activeProfile?.user_submitted_at ? t('verifySteps.openTrading.heading_submitted') : t('verifySteps.openTrading.heading'),
                    description: t(activeProfile?.account_management_type === 'Incomplete' ? 'verifySteps.openTrading.description' : activeProfile?.user_submitted_at ? 'verifySteps.openTrading.description_submitted' : 'verifySteps.openTrading.description_account_management_type_selected', {
                        call_to_action: activeProfile?.account_management_type === 'Incomplete' ? 'Choose your preferred account type:' : activeProfile?.user_submitted_at ? 'We are in the process of verifying your account.' :  'Complete your account setup:'
                    }),
                    complete: activeProfile?.trade_account_complete,
                    showChooseAccountType: activeProfile?.account_management_type === 'Incomplete',
                    showCompleteAccountSetup: activeProfile?.account_management_type !== 'Incomplete' && activeProfile?.user_submitted_at === null,
                },
                {
                    label: t('verifySteps.transferHoldings.label'),
                    heading: t('verifySteps.transferHoldings.heading'),
                    description: t('verifySteps.transferHoldings.description', {
                        transferForm: <Link href={settings?.broker_to_broker_download} target="_blank" sx={{ color: 'dark' }}>transfer form</Link>,
                        email: <Link href="mailto:members@liquidity.com.au" sx={{ color: 'dark' }}>members@liquidity.com.au</Link>
                    }),
                    showBrokerDownload: true,
                    complete: user?.holdings_transfer_dismissed,
                }
            ];

            // If the active profile is an individual and they have skipped the trade account step, remove the last two steps
            if (individualProfile && user.skip_individual_trade_account) {
                newSteps.pop();
                newSteps.pop();
            };

            setSteps(newSteps);
            setCurrentStep(newSteps.find(s => !s.complete) ?? false);
        }, [t, user, settings?.broker_to_broker_download, activeProfile, finaliseEntityLocation, individualProfile])

        const dismissFinalStep = () => {
            setLoading(true);
            dismissHoldingsTransfer()
                .then(() => setLoading(false));
        };

        const handleAccountManagementTypeClick = (type) => {
            storeProfileAccountManagementType(activeProfile?.uuid, { account_management_type: type }).then((response) => {
                toast.success(t('settings.profile.accountManagementType.success'), {
                    toastId: 'account_management_type-success',
                });
                dispatch(setUser(response));
                !individualProfile ? history.push(`/settings/entities/${activeProfile?.uuid}`) : history.push('/settings/account')
            }).catch(({ response }) => {
                toast.error(response?.data?.message || t('settings.profile.accountManagementType.error'), {
                    toastId: 'account_management_type-error',
                });
            });
        };

        useEffect(() => {
            // Hide if all steps are complete
            if (!currentStep && Array.isArray(steps)) {
                dispatch(setShowVerifySteps(false));
            }
        }, [currentStep, dispatch, steps]);

        return !showVerifySteps ? null : (
            <Box sx={{
                bg: 'light',
                py: '90px',
            }} {...props}>
                <Container>
                    {Array.isArray(steps) && !isLoading ? (
                        <Flex sx={{
                            flexWrap: 'wrap',
                            justifyContent: 'right',
                            alignItems: 'center',
                            gridGap: '55px',
                        }}>
                            <Box sx={{ maxWidth: ['100%', null, null, '408px'], order: [2, null, null, 0] }}>
                                <ProgressCircle
                                    sx={{ mb: 4 }}
                                    total={steps.length}
                                    progress={steps.indexOf(currentStep)}
                                />
                                <Heading sx={{
                                    fontSize: '28px',
                                    marginBottom: '32px',
                                }}>
                                    {currentStep.heading}
                                </Heading>
                                <Text
                                    as="p"
                                    sx={{
                                        fontSize: '20px',
                                        color: 'dark'
                                    }}
                                >
                                    {currentStep.description}
                                </Text>
                                {currentStep.showBrokerDownload && (
                                    <>
                                    {settings?.broker_to_broker_download && <Button as="a" mr={2} href={settings.broker_to_broker_download} target="_blank" sx={{ fontWeight: 'bold' }}>Download Transfer Form</Button>}
                                        <Button mt={3} onClick={dismissFinalStep} variant={settings?.broker_to_broker_download ? 'secondary' : 'primary'}>Dismiss</Button>
                                    </>
                                )}
                                {currentStep.showFinaliseEntity && (
                                    <>
                                        <Button as={Link} mr={2} mt={3} href={finaliseEntityLocation} sx={{ fontWeight: 'bold' }}>Secure Priority Allocations</Button>
                                    </>
                                )}
                                {currentStep.showChooseAccountType && (
                                    <Flex>
                                        <Button onClick={() => handleAccountManagementTypeClick(0)} mr={2} mt={3} sx={{ fontWeight: 'bold', width: '50%' }}>{t('Self Managed')}</Button>
                                        <Button onClick={() => handleAccountManagementTypeClick(1)} mt={3} sx={{ fontWeight: 'bold', width: '50%' }}>{t('Full Service')}</Button>
                                    </Flex>
                                )}
                                {currentStep.showCompleteAccountSetup && (
                                    <Button as={Link} mt={3} href={!individualProfile ? `/settings/entities/${activeProfile?.uuid}` : '/settings/account'} sx={{ fontWeight: 'bold' }}>{t('Complete Trade Account Setup')}</Button>
                                )}
                            </Box>
                            <Box sx={{ flexBasis: ['100%', null, null, '50%'] }}>
                                <Steps
                                    items={steps}
                                    sx={{
                                        maxWidth: ['100%', null, null, '405px'],
                                    }}
                                />
                            </Box>
                        </Flex>
                    ) : (
                        <Spinner variant="styles.spinner" />
                    )}
                </Container>
            </Box>
        )
    }
);
