import { useEffect, useCallback } from 'react'
import {
    Box,
    Flex,
    Heading,
    Image,
    Link as ThemeLink,
    Text,
    Grid
} from '@theme-ui/components'
import {
    Button,
    Circle,
    ImagePlaceholder,
    TimeRemaining,
    Tooltip
} from '~/Common'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { DealStatus } from 'app/constants'
import { useTranslation } from 'react-i18next';
import { Icon } from 'assets/Icon'
import { Link } from 'react-router-dom';
import { setShowingDealSummaryHeader } from 'features/theme/themeSlice';
import { dealOfferPriceFormatting } from 'utils/helpers'
import { useDealTypeDetail } from 'hooks/useDealTypeDetail'
import { useDisplayName } from 'hooks/useDisplayName'

export const SummaryHeader = ({ deal, bidButtonAction = false, ...props }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const showing = useSelector((state) => state.theme?.showingDealSummaryHeader);
    const user = useSelector((state) => state.user?.user);
    const dealDetail = useDealTypeDetail(deal);
    const dealDisplayName = useDisplayName(deal);

    const listenScroll = useCallback(() => {
        if(window.pageYOffset > 450 && !showing){
            dispatch(setShowingDealSummaryHeader(true));

        } else if (window.pageYOffset <= 450 && showing){
            dispatch(setShowingDealSummaryHeader(false));
        }
    }, [ dispatch, showing ]);


    useEffect(() => {
        window.addEventListener('scroll', listenScroll)
        return () => window.removeEventListener("scroll", listenScroll)
    }, [ listenScroll ]);

    if ( ! deal ) {
        return null;
    }

    return (
        <>
            <Box sx={{
                top: t => t.styles.header.heightSmall,
                transform: showing ? 'translate3d(0,0,0)' : 'translate3d(0,-200%,0)',
                position: 'fixed',
                width: '100%',
                zIndex: 99,
                transition: 'transform 0.3s ease-in-out',
                bg: '#fff',
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.06)'
            }}>
                <Box sx={{
                    maxWidth: '1440px',
                    m: '0 auto'
                }}>
                    <Box sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Grid sx={{
                            gridTemplateColumns: ['1fr', null, null, '2fr 3fr', 'auto 780px'],
                            p: '12px 32px 24px',
                            position: 'relative',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flexGrow: 1
                        }}>
                            <Flex>
                                <Flex mr={[null, null, 3]} sx={{
                                    width: '40px',
                                    alignItems: 'center',
                                    order: [1,0]
                                }}>
                                    <Circle size="40px" type="box" sx={{
                                        boxShadow: ['none', 'none', '3px 3px 20px rgba(29, 43, 43, 0.1)'],
                                        bg: 'transparent'
                                    }}>
                                        {deal?.company?.logo?.sizes?.tiny ? (
                                            <Image src={deal?.company?.logo?.sizes?.tiny} sx={{ borderRadius: '50%' }} />
                                        ) : (
                                            <ImagePlaceholder sx={{
                                                width: '40px',
                                                height: '40px',
                                                borderRadius: '50%'
                                            }} />
                                        )}
                                    </Circle>
                                </Flex>

                                <Box pb={[3,3,0]} mr={[null, null, 3]} sx={{
                                    alignItems: 'center',
                                    width: ['calc(100% - 80px)', 'auto'],
                                    maxWidth: '470px',
                                    order: [2,2,1],
                                    flex: 1
                                }}>
                                    <Heading as="h3" variant="h3" sx={{ fontSize: '20px', lineHeight: '40px' }}>
                                        {!user.finished_onboarding_details ? dealDisplayName : <ThemeLink as={Link} to={`/deals/${deal?.slug}`} sx={{ whiteSpace: 'normal' }}>{dealDisplayName}</ThemeLink>}
                                    </Heading>
                                </Box>
                            </Flex>

                            <Flex>
                                {deal?.offer_price && <Flex mb={[3,3,0]} ml={[null, null, 3]} sx={{
                                    alignItems: ['center', 'center', 'center', 'flex-start', 'center'],
                                    order: [3,3,4],
                                    flexDirection: ['row', 'row', 'row', 'column', 'row'],
                                    width: ['100%', 'auto'],
                                }}>
                                    {!dealDetail?.alternateInvestment ? (
                                        <>
                                            <Box mr={[3,3,3,0,4]} sx={{
                                                position: 'relative',
                                                width: [null, null, null, null, '38%']
                                            }}>
                                                <Text as="p" variant="smaller" color="dark">{t('Offer Price')}</Text>
                                                <Text as="p" variant="price" color="darker" sx={{ fontSize: '20px', lineHeight: 1 }}>A${dealOfferPriceFormatting(deal?.offer_price)}</Text>
                                                <Text as="p" color="dark" sx={{ fontSize: '12px', lineHeight: 1, position: 'absolute', top: '100%', left: 0 }}>{deal?.stage?.name === 'Fund' ? 'per unit' : 'per share'}</Text>
                                            </Box>
                                            <Box sx={{
                                                width: [null, null, null, null, '62%']
                                            }}>
                                                <Text as="p" variant="smaller" color="dark">{t('Offer Size')}</Text>
                                                <Text as="p" variant="price" color="darker" sx={{ fontSize: '20px', lineHeight: 1 }}>A${deal?.offer_size}</Text>
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Box mr={[3,3,3,0,4]} sx={{
                                                position: 'relative',
                                                width: [null, null, null, null, '80%']
                                            }}>
                                                <Text as="p" variant="smaller" color="dark">{t('Minimum Investment')}</Text>
                                                <Text as="p" variant="price" color="darker" sx={{ fontSize: '20px', lineHeight: 1 }}>A${dealOfferPriceFormatting(deal?.min_bid_price)}</Text>
                                            </Box>
                                        </>
                                    )}
                                    
                                </Flex>}

                                <Flex ml={[null, 'auto', 'auto', 4]} sx={{
                                    height: '42px',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    alignItems: 'stretch',
                                    order: 5,
                                    flex: 1,
                                }}>
                                    <Box sx={{
                                        width: '343px',
                                        display: 'grid',
                                        gridTemplate: 'auto / repeat(2, 1fr)',
                                        gridGap: 3,
                                        height: '42px'
                                    }}>
                                        <Box sx={{ flex: 1, order: !user.finished_onboarding_details || bidButtonAction ? 0 : 1 }}>
                                            {(deal?.status?.id === DealStatus.active && deal?.available_to) && (
                                                <TimeRemaining
                                                    sx={{ height: '100%', fontSize: '14px' }}
                                                    to={moment(deal?.available_to).utc().clone().local()}
                                                    from={moment(deal?.available_from).utc().clone().local()}
                                                    dealPreviewMode={deal.preview_mode}
                                                    alternateInvestment={dealDetail.alternateInvestment}
                                                />
                                            )}
                                        </Box>
                                        <Flex sx={{ flex: 1 }}>
                                            {!user.finished_onboarding_details ? (
                                                <Tooltip text={t('deals.notFinishedOnboarding.tooltip')}>
                                                    <Button variant="primary" sx={{ width: '100%', display: 'inline-flex' }} mr={3}><Icon icon="closed-deals" size="15px" sx={{ top: '-1px', position: 'relative', mr: 2 }} /> <small>{t('deals.notFinishedOnboarding.label')}</small></Button>
                                                </Tooltip>
                                            ) : (
                                                bidButtonAction && <Button variant="tealLight" sx={{ width: '100%', fontSize: dealDetail.alternateInvestment ? '15px' : '16px' }} mr={3} onClick={bidButtonAction}>{t(dealDetail.bidButtonText)}</Button>
                                            )}
                                        </Flex>
                                    </Box>
                                </Flex>
                            </Flex>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
