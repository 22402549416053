import { useRef, useState, useEffect } from 'react'
import { SettingsLayout } from '~/Layouts';
import {
    PreferenceCategory,
    PreferenceStage
} from '~/Common';
import {
    Box,
    Heading,
    Spinner
} from '@theme-ui/components';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getFiltersAsync } from 'features/theme/themeSlice'
import { CSSTransition } from 'react-transition-group'
import { getWatchlistAsync } from 'features/watchlist/watchlistSlice';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { user: state.user?.user, theme: state.theme, watchlist: state.watchlist }
}

const SettingsPreferences = ({ user, theme, watchlist }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Deal Preferences'));
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const nodeRef = useRef(null);

    useEffect(() => {
        if(theme.status === 'idle'){
            dispatch(getFiltersAsync());
        }
        if(watchlist.status === 'idle'){
            dispatch(getWatchlistAsync());
        }
        setLoading(false);
    }, [dispatch, theme, watchlist]);

    return (
        <SettingsLayout>
            {loading ? <Box id="settingsLoading"><Spinner variant="styles.spinner" m={'auto'} /></Box> : (
                <CSSTransition
                    nodeRef={nodeRef}
                    in={true}
                    timeout={500}
                    classNames="fade"
                    unmountOnExit
                    appear
                >
                    <Box ref={nodeRef}>
                        <Heading as="h1" variant="h2" mb={4}>{t('Preferences')}</Heading>

                        <Box mb={5}>
                            <Heading as="h2" variant="section_heading" mb={4}>{t('Select investment categories that appeal to you:')}</Heading>

                            <PreferenceCategory />
                        </Box>
                        <Box>
                            <Heading as="h2" variant="section_heading" mb={4}>{t('Select deal stages that appeal to you:')}</Heading>

                            <PreferenceStage />
                        </Box>
                    </Box>
                </CSSTransition>
            )}
        </SettingsLayout>
    );
};

export default connect(mapStateToProps, null)(SettingsPreferences);
