import {
    Button,
    Flex,
    Text
} from '@theme-ui/components'
import { Icon } from 'assets/Icon'

export const SwitchView = ({ selected, onChange, sx, ...props }) => {
    return (
        <Flex sx={{ justifyContent: 'right' }}>
            <Button className={selected === 'list' ? 'active' : ''} variant="icon" onClick={(e) => onChange(e, 'list')} mr={3}>
                <Icon icon="list" size="19px" />
                <Text className="sr-only">Switch to list layout</Text>
            </Button>
            <Button className={selected === 'grid' ? 'active' : ''} variant="icon" onClick={(e) => onChange(e, 'grid')}>
                <Icon icon="grid" size="19px" />
                <Text className="sr-only">Switch to grid layout</Text>
            </Button>
        </Flex>
    );
}
