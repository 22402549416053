import { KeyboardNavigation as KeyboardNavigationStyled } from './styled';

export const KeyboardNavigation = ({ children, ...props }) => {
    return (
        <KeyboardNavigationStyled
            ariaLabel="Skip to navigation"
            {...props}
        >
            {children}
        </KeyboardNavigationStyled>
    );
};
