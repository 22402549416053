import { useFormikContext } from 'formik';
import { Grid, Heading, Text } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import { Field } from '~/Forms';
import { useEffect, useState } from 'react';
import { utilStateByCountry } from 'features/theme/themeAPI';

export const EntityAddress = ({ stateOptions, setStateOptions, countries, type, ...props }) => {
    const { t } = useTranslation();
    const { values, setFieldValue, setValues } = useFormikContext();
    const [chessStateOptions, setChessStateOptions] = useState(null);
    const [ principleAddressStateOptions, setPrincipleAddressStateOptions ] = useState(null);

    useEffect(() => {
        utilStateByCountry(values?.address?.country)
            .then((response) => {
                setStateOptions(response?.data);
            })
            .catch(() => {
                setStateOptions(null);
            });
        }, [values?.address?.country, setStateOptions]
    );

    useEffect(() => {
        utilStateByCountry(values?.chess_address?.country)
            .then((response) => {
                setChessStateOptions(response?.data);
            })
            .catch(() => {
                setChessStateOptions(null);
            });
    }, [values?.chess_address?.country, setChessStateOptions]);

    useEffect(() => {
        utilStateByCountry(values?.principle_address?.country)
            .then((response) => {
                setPrincipleAddressStateOptions(response?.data);
            })
            .catch(() => {
                setPrincipleAddressStateOptions(null);
            });
    }, [values?.principle_address?.country, setPrincipleAddressStateOptions]);

    // If we toggle the PO Box checkbox, we need to clear the address fields
    const handlePoBoxToggle = (values, value, setValues) => {
        setValues({
            ...values,
            chess_address: {
                address: '',
                unit_number: '',
                street_number: '',
                street_name: '',
                street_type: '',
                address2: '',
                state: '',
                suburb: '',
                postcode: '',
                country: 'AU', 
            },
        });
        setFieldValue('chess_address.is_po_box', value);
    };

    return (
        <>
            <Text mb={4} as="p">{t('fieldsRequired')}</Text>

            <Grid
                mb={1}
                sx={{
                    gridGap: [2, 3],
                    gridTemplateColumns: ['1fr'],
                    width: '100%',
                }}
            >
                {type === 'company' && (
                    <>
                        <Field
                            label="Country *"
                            placeholder="Select country"
                            name="address.country"
                            field="searchable-select"
                            options={countries}
                            required={true}
                        />
                        {values?.address?.country !== 'AU' && (
                            <Text as="p" variant="caption" color="red" mb={3}>{t('Companies must have Australian addresses to open a trading account. However, you can still bid on deals with foreign addresses')}</Text>
                        )}
                    </>
                    
                )} 
                
                {values.address.country === 'AU' && (
                    <Field
                        field="google_places"
                        label="Search for address..."
                        name="address.address"
                        setFieldValue={setFieldValue}
                        fieldNames={{
                            street_number: 'address.street_number',
                            street_name: 'address.street_name',
                            street_type: 'address.street_type',
                            suburb: 'address.suburb',
                            state: 'address.state',
                            postcode: 'address.postcode',
                            country: 'address.country',
                        }}
                    />
                )}     
            </Grid>

            <Grid
                mb={1}
                sx={{
                    gridGap: [2, 3],
                    gridTemplateColumns: ['1fr', '1fr 1fr'],
                    width: '100%',
                }}
            >
                <Field label="Unit Number" name="address.unit_number" />
                <Field label="Street Number" name="address.street_number" />
            </Grid>

            <Field label="Street Name *" name="address.street_name" required={true} />
            <Field label="Street Type *" name="address.street_type" required={true} />

            <Grid
                mb={1}
                sx={{
                    gridGap: 2,
                    gridTemplateColumns: ['1fr'],
                    width: '100%',
                }}
            >
                <Field label="Address second line" name="address.address2" />
            </Grid>

            <Grid
                mb={4}
                sx={{
                    gridGap: 2,
                    gridTemplateColumns: ['1fr', '5fr 4fr 4fr'],
                    width: '100%',
                }}
            >
                <Field label="Suburb *" name="address.suburb" required={true} />

                <Field field={Boolean(Array.isArray(stateOptions)) && stateOptions.length > 0 ? 'select' : 'text'} label="State *" placeholder="State" name="address.state" options={stateOptions} useValueOnBlur={true} required={true} />

                <Field label="Postcode *" name="address.postcode" required={true} />
            </Grid>
            {values.sub_type.includes('_company') && (
                <>
                    <Heading variant="h5">{t('Registered Company Address')}</Heading>
                    <Field field="checkbox" label="Use same address as above" validIndicator={false} name="same_company_address" value={true} checked={values?.same_company_address} sx={{ my: 3 }} />
                    {values.same_company_address !== true && (
                        <>
                            <Grid
                                mb={1}
                                sx={{
                                    gridGap: [2, 3],
                                    gridTemplateColumns: ['1fr'],
                                    width: '100%',
                                }}
                            >
                                <Field
                                    field="google_places"
                                    label="Search for address..."
                                    name="company_address.address"
                                    setFieldValue={setFieldValue}
                                    fieldNames={{
                                        street_number: 'company_address.street_number',
                                        street_name: 'company_address.street_name',
                                        street_type: 'company_address.street_type',
                                        suburb: 'company_address.suburb',
                                        state: 'company_address.state',
                                        postcode: 'company_address.postcode',
                                        country: 'company_address.country',
                                    }}
                                />
                            </Grid>

                            <Grid
                                mb={1}
                                sx={{
                                    gridGap: [2, 3],
                                    gridTemplateColumns: ['1fr', '1fr 1fr'],
                                    width: '100%',
                                }}
                            >
                                <Field label="Unit Number" name="company_address.unit_number" />
                                <Field label="Street Number" name="company_address.street_number" />
                            </Grid>

                            <Field label="Street Name *" name="company_address.street_name" />
                            <Field label="Street Type *" name="company_address.street_type" />

                            <Grid
                                mb={1}
                                sx={{
                                    gridGap: 2,
                                    gridTemplateColumns: ['1fr'],
                                    width: '100%',
                                }}
                            >
                                <Field label="Address second line" name="company_address.address2" />
                            </Grid>

                            <Grid
                                mb={4}
                                sx={{
                                    gridGap: 2,
                                    gridTemplateColumns: ['1fr', '5fr 4fr 4fr'],
                                    width: '100%',
                                }}
                            >
                                <Field label="Suburb *" name="company_address.suburb" />

                                <Field field={stateOptions ? 'select' : 'text'} label="State *" placeholder="State" name="company_address.state" options={stateOptions} useValueOnBlur={true} />

                                <Field label="Postcode *" name="company_address.postcode" />
                            </Grid>
                        </>
                    )}
                </>
            )}

            {(values.type === 'company' || values.sub_type.includes('_company')) && (
                <>
                    <Heading variant="h5">{t('Principal Place of Business')}</Heading>
                    <Field field="checkbox" label="Use same address as above" validIndicator={false} name="same_principle_address" value={true} checked={values?.same_principle_address} sx={{ my: 3 }} />
                    {values.same_principle_address !== true && (
                        <>
                            <Grid
                                mb={1}
                                sx={{
                                    gridGap: [2, 3],
                                    gridTemplateColumns: ['1fr'],
                                    width: '100%',
                                }}
                            >
                                {type === 'company' && (
                                    <>
                                        <Field
                                            label="Country *"
                                            placeholder="Select country"
                                            name="principle_address.country"
                                            field="searchable-select"
                                            options={countries}
                                            required={true}
                                        />
                                        {values?.address?.country !== 'AU' && (
                                            <Text as="p" variant="caption" color="red" mb={3}>{t('Companies must have Australian addresses to open a trading account. However, you can still bid on deals with foreign addresses')}</Text>
                                        )}
                                    </>
                                    
                                )} 
                                {values.principle_address.country === 'AU' && (
                                    <Field
                                        field="google_places"
                                        label="Search for address..."
                                        name="principle_address.address"
                                        setFieldValue={setFieldValue}
                                        fieldNames={{
                                            street_number: 'principle_address.street_number',
                                            street_name: 'principle_address.street_name',
                                            street_type: 'principle_address.street_type',
                                            suburb: 'principle_address.suburb',
                                            state: 'principle_address.state',
                                            postcode: 'principle_address.postcode',
                                            country: 'principle_address.country',
                                        }}
                                    />
                                )}
                            </Grid>

                            <Grid
                                mb={1}
                                sx={{
                                    gridGap: [2, 3],
                                    gridTemplateColumns: ['1fr', '1fr 1fr'],
                                    width: '100%',
                                }}
                            >
                                <Field label="Unit Number" name="principle_address.unit_number" />
                                <Field label="Street Number" name="principle_address.street_number" />
                            </Grid>

                            <Field label="Street Name *" name="principle_address.street_name" />
                            <Field label="Street Type *" name="principle_address.street_type" />

                            <Grid
                                mb={1}
                                sx={{
                                    gridGap: 2,
                                    gridTemplateColumns: ['1fr'],
                                    width: '100%',
                                }}
                            >
                                <Field label="Address second line" name="principle_address.address2" />
                            </Grid>

                            <Grid
                                mb={4}
                                sx={{
                                    gridGap: 2,
                                    gridTemplateColumns: ['1fr', '5fr 4fr 4fr'],
                                    width: '100%',
                                }}
                            >
                                <Field label="Suburb *" name="principle_address.suburb" />

                                <Field field={Boolean(Array.isArray(principleAddressStateOptions)) && principleAddressStateOptions.length > 0 ? 'select' : 'text'} label="State *" placeholder="State" name="principle_address.state" options={principleAddressStateOptions} useValueOnBlur={true} required={true} />

                                <Field label="Postcode *" name="principle_address.postcode" />
                            </Grid>
                        </>
                    )}
                </>
            )}

            <Heading variant="h5">{t('CHESS Registration Address')}</Heading>
            <Field field="checkbox" label="Use same address as above" validIndicator={false} name="same_chess_address" value={true} checked={values?.same_chess_address} sx={{ my: 3 }} />
            {values.same_chess_address !== true && (
                <>
                    <Grid
                        mb={1}
                        sx={{
                            gridGap: [2, 3],
                            gridTemplateColumns: ['1fr'],
                            width: '100%',
                        }}
                    >
                        <Field
                            field="checkbox"
                            name="chess_address.is_po_box"
                            label={t('Address is PO Box')}
                            my={2}
                            onChangeOverride={(e) => handlePoBoxToggle(values, e.target.checked, setValues)}
                            validIndicator={false}
                            checked={values?.chess_address?.is_po_box}
                        />

                        {type === 'company' && (
                            <Field
                                label="Country *"
                                placeholder="Select country"
                                name="chess_address.country"
                                field="searchable-select"
                                options={countries}
                                required={true}
                            />
                        )} 

                        {!values?.chess_address?.is_po_box && (
                            <Field
                                field="google_places"
                                label="Search for address..."
                                name="chess_address.address"
                                setFieldValue={setFieldValue}
                                fieldNames={{
                                    street_number: 'chess_address.street_number',
                                    street_name: 'chess_address.street_name',
                                    street_type: 'chess_address.street_type',
                                    suburb: 'chess_address.suburb',
                                    state: 'chess_address.state',
                                    postcode: 'chess_address.postcode',
                                    country: 'chess_address.country',
                                }}
                            />
                        )}
                    </Grid>

                    {!values?.chess_address?.is_po_box && (
                        <>
                            <Grid
                                mb={1}
                                sx={{
                                    gridGap: [2, 3],
                                    gridTemplateColumns: ['1fr', '1fr 1fr'],
                                    width: '100%',
                                }}
                            >
                                <Field label="Unit Number" name="chess_address.unit_number" />
                                <Field label="Street Number" name="chess_address.street_number" />
                            </Grid>

                            <Field label="Street Name *" name="chess_address.street_name" />
                            <Field label="Street Type *" name="chess_address.street_type" />
                        </>
                    )}

                    <Grid
                        mb={1}
                        sx={{
                            gridGap: 2,
                            gridTemplateColumns: ['1fr'],
                            width: '100%',
                        }}
                    >
                        <Field label={values?.chess_address?.is_po_box ? "PO Box" : "Address second line"} name="chess_address.address2" />
                    </Grid>

                    <Grid
                        mb={4}
                        sx={{
                            gridGap: 2,
                            gridTemplateColumns: ['1fr', '5fr 4fr 4fr'],
                            width: '100%',
                        }}
                    >
                        <Field label="Suburb *" name="chess_address.suburb" />

                        <Field field={Boolean(Array.isArray(chessStateOptions)) && chessStateOptions.length > 0 ? 'select' : 'text'} label="State *" placeholder="State" name="chess_address.state" options={chessStateOptions} useValueOnBlur={true} />

                        <Field label="Postcode *" name="chess_address.postcode" />
                    </Grid>
                </>
            )}
        </>
    );
};
