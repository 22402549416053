import { useState, useEffect } from 'react';
import {
    BackButton,
    Button,
    PreferenceCategory,
    PreferenceStage
} from '~/Common';
import {
    Box,
    Flex,
    Heading,
    Image,
    Spinner,
    Text
} from '@theme-ui/components';
import { useHistory } from 'react-router-dom'
import { Icon } from 'assets/Icon';
import Modal from 'react-modal';
import { useDispatch, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { theme as appTheme } from 'app/theme';
import { introShownAsync } from 'features/auth/authSlice';
import { getFiltersAsync, setBlurDashboard } from 'features/theme/themeSlice';
import { getWatchlistAsync, getWatchlistClosedDealsAsync, getWatchlistDealsAsync, getWatchlistStatsAsync } from 'features/watchlist/watchlistSlice';
import { DealInvestmentStatus } from 'app/constants';

const mapStateToProps = (state) => {
    return { user: state.user?.user, theme: state.theme, watchlist: state.watchlist }
}

const IntroSteps = ({ user, theme, watchlist }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [modelOpen, setModalOpen] = useState(false);
    const [goToEntities, setGoToEntities] = useState(false);
    const [finaliseEntity, setFinaliseEntity] = useState(false);
    const goToEoiDeal = user.eoi !== null && user.eoi?.deal_info?.uuid && user.eoi?.deal_investment_status_id === DealInvestmentStatus.pending;
    const history = useHistory();
    const maxSteps = 5;

    const hasNonIndividualProfile = user.profiles.find((profile) => {
        const type = profile.type;
        return ['superannuation', 'superannuation_company', 'trust', 'trust_company', 'company'].includes(type);
    });

    const previousStep = (e) => {
        if (e) e.preventDefault();
        setStep((currentStep) => {
            if (currentStep === 5 && goToEoiDeal) {
                return currentStep - 2;
            }
            return currentStep > 1 ? currentStep - 1 : currentStep;
        });
    };
    const nextStep = (e) => {
        if (e) e.preventDefault();
        setStep((currentStep) => {
            if (currentStep === 3 && goToEoiDeal) {
                return currentStep + 2;
            }
            return currentStep < maxSteps ? currentStep + 1 : currentStep;
        });
    };
    const closeModal = (e) => {
        if (e) e.preventDefault();
        dispatch(introShownAsync());
        setModalOpen(false);
    };

    useEffect(() => {
        setModalOpen(!user.intro_shown);
    }, [user]);

    useEffect(() => {
        if(watchlist.status === 'resync' || watchlist.status === 'idle'){
            dispatch(getWatchlistAsync());
            dispatch(getWatchlistStatsAsync());
            dispatch(getWatchlistDealsAsync());
            dispatch(getWatchlistClosedDealsAsync());
        }
    }, [dispatch, watchlist]);

    useEffect(() => {
        if(theme.status === 'idle'){
            dispatch(getFiltersAsync());
        }

        if (step === maxSteps) {
            setTimeout(() => {
                dispatch(introShownAsync());
            }, 3500);

            setTimeout(() => {
                if (goToEoiDeal && user.eoi?.deal_info?.path && user.eoi?.deal_info?.status?.name === 'Active') {
                    history.push(user.eoi.deal_info.path);
                } else if (goToEntities) {
                    finaliseEntity ?
                        user?.skip_individual_trade_account ? history.push(`/settings/entities/${user?.profiles[0]?.uuid}`) : history.push('/settings/account')
                        : history.push('/settings/entities/create');
                } else {
                    history.push('/deals');
                }
            }, 4000);
        }
    }, [dispatch, user, theme, history, step, goToEntities, goToEoiDeal, finaliseEntity]);

    useEffect(() => {
        dispatch(setBlurDashboard(modelOpen));

        return () => {
            dispatch(setBlurDashboard(false));
        }
    }, [modelOpen, dispatch]);

    return (
        <Modal
            isOpen={modelOpen}
            onRequestClose={closeModal}
            style={appTheme.modals.intro}
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={300}
            contentLabel={t('intro.landing.modal.title')}
            aria={{
                labelledby: "introTitle"
            }}
        >
            <>
                {(step !== 1 && step !== maxSteps) && (
                    <Box px={3} py={3} sx={{
                        textAlign: 'left'
                    }}>
                        <BackButton onClick={previousStep} />
                    </Box>
                )}

                {step === 1 &&
                    <>
                        <Box sx={{ overflow: 'auto' }} id="introStep1">
                            <Box sx={{
                                marginTop: '-1px',
                                marginBottom: '50px',
                                backgroundImage: 'url(/img/intro-banner.jpg)',
                                backgroundSize: 'cover',
                                height: '266px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Heading as="h1" sx={{
                                    fontSize: ['48px','64px'],
                                    px: 2,
                                    fontWeight: 400,
                                    color: 'white'
                                }}>{t('intro.modal.step1.title', { name: user.first_name })}</Heading>
                            </Box>

                            <Box px={4} sx={{
                                maxWidth: '460px',
                                margin: '0 auto'
                            }}>
                                <Heading as="h3" variant="h4" mb={'24px'} sx={{
                                    color: 'darker',
                                    fontWeight: 500
                                }}>{t('intro.modal.step1.subtitle')}</Heading>

                                <Text as="p" mb={5} sx={{
                                    fontSize: '18px'
                                }}>{t('intro.modal.step1.description')}</Text>

                                <Button className="nextStep" variant="primary" sx={{
                                    width: '100%',
                                    maxWidth: '280px'
                                }} type="button" onClick={nextStep}>{t('intro.modal.step1.continue')}</Button>
                            </Box>
                        </Box>
                    </>
                }
                {step === 2 &&
                    <>
                        <Box sx={{ overflow: 'auto' }} id="introStep2">
                            <Box sx={{ maxWidth: '664px', margin: '0 auto 0 auto' }}>
                                <Icon icon="stack" mb={3} size="38px" color="tealLight" />
                                <Heading as="h2" mb={3} sx={{ fontSize: '26px' }}>{t('intro.modal.step2.title')}</Heading>
                                <Text sx={{
                                    display: 'block',
                                    color: '#6A7175',
                                    fontSize: '13px',
                                    mt: '24px',
                                    mb: '40px',
                                    px: '30px'
                                }}>{t('intro.modal.step2.description')}</Text>
                            </Box>


                            <Box sx={{ maxWidth: '664px', margin: '48px auto 0 auto', px: [3,0] }}>
                                <PreferenceCategory showToast={false} showSave={false} preSelect={false}>
                                    {(save) => (
                                        <Button
                                            mt={'24px'}
                                            variant="primary"
                                            sx={{
                                                width: '100%',
                                                maxWidth: '280px'
                                            }}
                                            type="button"
                                            className="nextStep"
                                            onClick={() => save().then(nextStep)}
                                        >
                                            {t('intro.modal.step2.continue')}
                                        </Button>
                                    )}
                                </PreferenceCategory>
                            </Box>
                        </Box>
                    </>
                }
                {step === 3 &&
                    <>
                        <Box sx={{ overflow: 'auto' }} id="introStep3">
                            <Box sx={{ maxWidth: '664px', margin: '0 auto 0 auto', px: [3, 0] }}>
                                <Icon icon="puzzle" mb={3} size="38px" color="tealLight" />
                                <Heading as="h2" sx={{ fontSize: '26px' }}>{t('intro.modal.step3.title')}</Heading>
                                <Text sx={{
                                    display: 'block',
                                    color: '#6A7175',
                                    fontSize: '13px',
                                    mt: '24px',
                                    mb: '40px',
                                    px: '30px'
                                }}>{t('intro.modal.step3.description')}</Text>
                                <PreferenceStage showToast={false} showSave={false} preSelect={false}>
                                    {(save) => (
                                        <Button
                                            mt={'24px'}
                                            variant="primary"
                                            sx={{
                                                width: '100%',
                                                maxWidth: '280px'
                                            }}
                                            type="button"
                                            className="nextStep"
                                            onClick={() => save().then(nextStep)}
                                        >
                                            {t('intro.modal.step3.continue')}
                                        </Button>
                                    )}
                                </PreferenceStage>
                            </Box>
                        </Box>
                    </>
                }
                {step === 4 && user?.skip_individual_trade_account && hasNonIndividualProfile ? (
                    <>
                        <Box sx={{ overflow: 'auto' }} id="introStep4">
                            <Box sx={{ maxWidth: '664px', margin: '0 auto 0 auto', px: [3, 0] }}>
                                <Icon icon="users" mb={3} size="38px" color="tealLight" />
                                <Heading as="h2" sx={{ fontSize: '26px' }}>{t('intro.modal.step4.finaliseEntity.title')}</Heading>
                                <Heading as="h3" sx={{
                                    fontSize: '18px',
                                    lineHeight: '27px',
                                    color: 'darker',
                                    fontWeight: 400,
                                    mb: '40px',
                                    mt: '24px',
                                }} mb={3}>{t('intro.modal.step4.finaliseEntity.subtitle')}</Heading>
                                <Button mr={2} className="nextStep" variant="secondary" onClick={nextStep}>{t('intro.modal.step4.skipForNow')}</Button>
                                <Button className="toEntities" variant="primary" sx={{
                                    width: '100%',
                                    maxWidth: '280px'
                                }} type="button" onClick={() => {
                                    setGoToEntities(true);
                                    setFinaliseEntity(true);
                                    nextStep();
                                }}>{t('intro.modal.step4.finaliseEntity.toEntity')}</Button>
                            </Box>
                        </Box>
                    </>
                ) : (
                    step === 4 && (
                        <>
                            <Box sx={{ overflow: 'auto' }} id="introStep4">
                                <Box sx={{ maxWidth: '664px', margin: '0 auto 0 auto', px: [3, 0] }}>
                                    <Icon icon="users" mb={3} size="38px" color="tealLight" />
                                    <Heading as="h2" sx={{ fontSize: '26px' }}>{t(user?.skip_individual_trade_account && !hasNonIndividualProfile ? 'intro.modal.step4.required.title' : 'intro.modal.step4.title')}</Heading>
                                    <Heading as="h3" sx={{
                                        fontSize: '18px',
                                        lineHeight: '27px',
                                        color: 'darker',
                                        fontWeight: 400,
                                        mb: '40px',
                                        mt: '24px',
                                    }} mb={3}>{t(user?.skip_individual_trade_account && !hasNonIndividualProfile ? 'intro.modal.step4.required.subtitle' : 'intro.modal.step4.subtitle')}</Heading>
                                    <Button mr={2} className="nextStep" variant="secondary" onClick={nextStep}>{t('intro.modal.step4.skipForNow')}</Button>
                                    <Button className="toEntities" variant="primary" sx={{
                                        width: '100%',
                                        maxWidth: '280px'
                                    }} type="button" onClick={() => {
                                        setGoToEntities(true);
                                        nextStep();
                                    }}>{t('intro.modal.step4.toEntities')}</Button>
                                </Box>
                            </Box>
                        </>
                    )
                )}
                {step === 5 &&
                    <>
                        <Box sx={{ overflow: 'auto' }} id="introStep5">
                            <Box sx={{
                                background: '#ffffff',
                                borderRadius: '16px',
                                pb: 2,
                                mb: '44px'
                            }}>
                                <Image src="/img/intro-banner-top.png" alt="" />

                                <Box sx={{
                                    margin: '0 auto',
                                    mt: goToEntities || goToEoiDeal ? '-80px' : '-140px',
                                    maxWidth: goToEntities || goToEoiDeal ? '420px' : '240px'
                                }}>
                                    {goToEntities || goToEoiDeal ? (
                                        <>
                                            <Heading as="h2" mb={3} id="introTitle" sx={{
                                                fontSize: '31px'
                                            }}>{t(goToEntities ? 'intro.modal.step5.toEntities.title' : 'intro.modal.step5.toEoiDeal.title', { name: user.first_name })}</Heading>
                                            <Heading as="h3" sx={{
                                                fontSize: '18px',
                                                lineHeight: '27px',
                                                color: 'darker',
                                                fontWeight: 400
                                            }} mb={3}>{t(goToEntities ? 'intro.modal.step5.toEntities.subtitle' : 'intro.modal.step5.toEoiDeal.subtitle')}</Heading>
                                        </>
                                    ) : (
                                        <>
                                            <Heading as="h2" mb={3} id="introTitle">{t('intro.modal.step5.title', { name: user.first_name })}</Heading>
                                            <Heading as="h3" sx={{
                                                fontSize: '18px',
                                                lineHeight: '27px',
                                                color: 'darker',
                                                fontWeight: 400
                                            }} mb={3}>{t('intro.modal.step5.subtitle')}</Heading>
                                        </>
                                    )}
                                </Box>
                            </Box>

                            <Flex sx={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%'
                            }}>
                                <Spinner variant="styles.spinner" m={'auto'} />
                            </Flex>
                        </Box>
                    </>
                }
            </>
        </Modal>
    );
};

export default connect(mapStateToProps, null)(IntroSteps);
