import styled from '@emotion/styled';
import { theme } from 'app/theme';

export const Header = styled('div')`
    background: ${theme.colors.white};
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    transition: background 0.3s ease-in-out;

    .header__left {
        height: 100%;
    }

    .header__userimage {
        height: 39px;
        transition: height 0.5s ease-in-out;
    }

    &.header__transparent:not(.menu-open) {
        background: transparent;
        color: ${theme.colors.white};

        .header__coinsBadge {
            background: transparent;

            &:not(:hover) {
                color: ${theme.colors.white};
                border-color: ${theme.colors.white};
            }
        }

        .header__utility:not(:hover) {
            color: ${theme.colors.white};
        }

        .header__mainMenu {
            ul {
                background: transparent;
            }

            a {
                &:not(:hover) {
                    color: ${theme.colors.white};
                }

                &.active {
                    background: transparent;

                    &:not(:hover) span::after {
                        background: ${theme.colors.white};
                    }
                }
            }
        }
    }

    & .header__right{
        transition: height 0.5s ease-in-out;
        height: ${theme.styles.header.height};
    }
    & .header__mainMenu{
        z-index: 100;
        visibility: hidden;
        opacity: 0;
        height: 100%;
        transition: all 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
    & .header__mainMenu ul{
        position: relative;
        width: 100%;
        flex-direction: column;
        display: flex;
        overflow-y: auto;
        height: 100%;
    }

    @media(min-width: 992px) {
        & .header__mainMenu ul{
            overflow: visible;
        }
    }
    & .header__mainMenu ul ul{
        background: ${theme.colors.dark};
        visibility: hidden;
        height: 0;
        opacity: 0;
        top: 0;
    }
    & .header__mainMenu li li{
        position: relative;
        display: list-item;
        width: 100%;
    }
    & .header__mainMenu li li a:focus,
    & .header__mainMenu li li a:hover{
        background: ${theme.colors.darker}
    }
    & .header__mainMenu li.focused ul{
        visibility: visible;
        opacity: 1;
        height: auto;
    }
    & .header__mainMenu a{
        text-align: left;
        justify-content: flex-start;
        display: flex;
    }
    & .header__mainMenu--open{
        visibility: visible;
        opacity: 1;
    }
    & .header__menuButton{
        svg path {
            transition: none;
        }
    }
    & .header__dropdownIcon{
        padding: 4px;
        display: inline-flex;
        justify-content: center;
        align-content: center;
        margin-left: auto;
    }
    & .header__dropdownIcon:focus{
        outline: 1px solid ${theme.colors.light};
        outline-offset: 3px;
    }
    & .header__dropdownIcon:focus svg path{
        stroke: ${theme.colors.light};
    }

    @media(max-width: 991px) {

        .header__mainMenu {
            left: 0;
            top: ${theme.styles.header.height};
            right: 0;
            bottom: 0;
            position: fixed;
            background: #fff;

            a {
                padding: 20px;
                border-bottom: 1px solid #F7F8F9;

                span::after {
                    display: none;
                }
            }
        }

        .header__profileName {
            display: none;
        }

        .header__navText {
            font-size: 18px;
            margin-left: 10px;
        }

        .header__coins,
        .header__search {
            display: none;
        }

        .profileButton {
            padding: 4px;
        }

        .header__mobileSearch {
            padding: 20px;
            border-bottom: 1px solid #F7F8F9;

            input {
                border: 1px solid #D5D9DA;
            }
        }

    }

    @media(min-width: 992px){
        .header__mobileSearch,
        .header__navIcon {
            display: none;
        }

        & .header__left{
            transition: height 0.5s ease-in-out;
            height: ${theme.styles.header.height};
        }
        & .header__right .header__navText{
            display: inline-block;
        }
        & .header__logoWrap {
          padding: 8px 45px;
        }
        & .header__mainMenu{
            position: relative;
            visibility: visible !important;
            opacity: 1 !important;
        }
        & .header__mainMenu ul{
            position: relative;
            width: auto;
            flex-direction: row;
        }
        & .header__mainMenu li{
            position: relative;
            display: flex;
        }
        & .header__mainMenu ul ul{
            position: absolute;
            top: 100%;
            display: block;
            flex-direction: column;
            width: 100%;
        }
        & .header__mainMenu li li{
            position: relative;
            display: list-item;
            width: 100%;
        }
        & .header__mainMenu li li a{
            position: relative;
            display: block;
        }
        & .header__mainMenu li:hover > ul,
        & .header__mainMenu li.focused ul{
            visibility: visible;
            opacity: 1;
            height: auto;
        }
        & .header__mainMenu a{
            text-align: center;
            justify-content: center;
        }
        & .header__menuButton{
            display: none;
        }
        & .header__navText{
            display: inline-block;
        }

        .profileButton {
            padding: 8px;
        }

        &.header__small {
            .profileButton {
                padding: 4px;
            }

            .header__right {
                height: ${theme.styles.header.heightSmall};
            }

            .header__userimage {
                height: 31px;
            }

            @media(min-width: 992px) {
                .header__left {
                    height: ${theme.styles.header.heightSmall};
                }
            }
        }
    }

    @media(min-width: 1290px){
        & .header__left .header__navText{
            display: inline-block;
        }
    }
`;
