import { Flex, Spinner } from '@theme-ui/components';
import { DashboardLayout, Layout } from '~/Layouts';
import { Container } from '~/Common'
import { useLocation } from 'react-router-dom';

export const LoadingScreen = ({ hasToken = false, children, sx, ...props }) => {
    const location = useLocation();
    return (
        <>
            {hasToken && location !== '/logout' ? (
                <DashboardLayout p={0} className="loading-screen">
                    <Container variant="layouts.default">
                        <Flex mb={4} sx={{
                            minHeight: '90vh',
                            flexDirection: 'column',
                            position: 'relative',
                        }}>
                            <Spinner variant="styles.spinner" m={'auto'} />
                        </Flex>
                    </Container>
                </DashboardLayout>
            ) : (
                <Layout className="loading-screen">
                    <Container>
                        <Spinner variant="styles.spinner" m={'auto'} />
                    </Container>
                </Layout>
            )}
        </>
    );
};
