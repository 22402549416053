import { useRef } from 'react'
import {
    Box,
    Card as ThemeCard,
    Flex,
    Heading,
    Image,
    Text,
    Link
} from '@theme-ui/components';
import { CSSTransition } from 'react-transition-group';
import {
    Button,
    Circle,
    ImagePlaceholder
} from '~/Common'
import { CoinBalance } from '~/Common/CoinBalance';
import { useTranslation } from 'react-i18next';

export const ExperienceCard = ({ experience, onClick, ...props }) => {
    const { t } = useTranslation();
    const nodeRef = useRef(null);

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={true}
            timeout={500}
            classNames="fade"
            unmountOnExit
            appear
        >
            <ThemeCard
                as={Link}
                variant="deal"
                ref={nodeRef}
                onClick={(e) => {
                    e.preventDefault();
                    onClick && onClick();
                }}
                {...props}
            >
                <Flex sx={{ position: 'relative', zIndex: 1 }}>
                    {experience?.image?.sizes?.thumbnail ? (
                        <Box sx={{
                            width: '100%',
                            height: '272px'
                        }}>
                            <Image src={experience.image.sizes.thumbnail} sx={{ objectFit: 'cover', height: '100%', width: '100%' }} />
                        </Box>
                    ) : (
                        <ImagePlaceholder sx={{
                            width: '100%',
                            height: '272px'
                        }} />
                    )}
                </Flex>
                <Flex px={[2,2,2,3]} py={4} sx={{
                    position: 'relative',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flexGrow: 1
                }}>
                    <Circle size="70px" type="box" sx={{
                        position: 'absolute',
                        top: '-35px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bg: 'white',
                        zIndex: 10,
                        overflow: 'hidden',
                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)'
                    }}>
                        {experience?.company?.logo?.sizes?.tiny ? (
                            <Image src={experience?.company?.logo?.sizes?.tiny} sx={{ borderRadius: '50%' }} />
                        ) : (
                            <ImagePlaceholder sx={{
                                width: '70px',
                                height: '70px',
                                borderRadius: '50%'
                            }} />
                        )}
                    </Circle>

                    <Box mb={3} sx={{ flexGrow: 1, textAlign: 'center', }}>
                        <Text as="p" color="dark" mt={3} mb={1} sx={{ fontSize: '14px' }}>{experience?.company?.name}</Text>
                        <Heading as="h3" variant="h3">
                            {experience?.name}
                        </Heading>
                    </Box>

                    <Flex sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        gridGap: '28px',
                        fontSize: '14px'
                    }}>
                        {experience.short_description && <Box sx={{ color: '#6A7175' }}>{experience.short_description}</Box>}
                        <CoinBalance balance={experience.price} small={true} showInfo={false} />
                    </Flex>
                    <Button mt={3}>{t('View more')}</Button>
                </Flex>
            </ThemeCard>
        </CSSTransition>
    );
};
