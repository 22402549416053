import styled from '@emotion/styled';

import { theme } from 'app/theme';
import { Button } from 'theme-ui';
import { useFormikContext } from 'formik';
import { Tooltip } from '~/Common';
import { useTranslation } from 'react-i18next';
import { getNestedErrorsByFields } from 'utils/helpers';

const ButtonStyled = styled(Button)`
    position: relative;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    ${(props) =>
        props.disabled &&
        `
        &:hover {
            background-color: ${theme.colors.dark};
            border-color: ${theme.colors.dark};
        }
    `}
`;

export const Submit = ({ type, text, size = null, forceSubmit = false, sx, showErrorTooltip = false, tooltipDefaultText = null, children, ...props }) => {
    const { isSubmitting, isValid, errors } = useFormikContext();
    const sizes = theme?.buttons?.sizes ?? [];
    const { t } = useTranslation();

    const getErrorTooltip = (errors) => {
        return getNestedErrorsByFields(errors).reduce((tooltip, error) => {
            return Array.isArray(error[1]) && error[1][0] !== undefined ?
                `${tooltip}\n- ${error[1][0]}` :
                ( typeof error[1] === 'string' ? `${tooltip}\n- ${error[1]}` : `${tooltip}\n\nView form for more errors`);
        }, t('buttons.submitErrorTooltip'));
    }

    return (
        <Tooltip text={isValid || !showErrorTooltip ? tooltipDefaultText : getErrorTooltip(errors)} innerSx={{ textAlign: 'left' }}>
            <ButtonStyled
                type={type || 'submit'}
                disabled={isSubmitting || (!isValid && !forceSubmit)}
                {...props}
                sx={{ ...sizes[size], ...sx }}
            >
                {isSubmitting
                    ? props.submittingText || 'Submitting...'
                    : text
                    ? text
                    : children
                    ? children
                    : 'Submit'}
            </ButtonStyled>
        </Tooltip>
    );
};
