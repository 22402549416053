import { DealStageTypes } from 'app/constants';

export const useDealTypeDetail = (deal) => {
    // Variables to handle alternate investments
    let managerText, alternateInvestment, showDescription, viewButtonText, bidButtonText;
    switch (deal?.stage?.type) {
        case DealStageTypes.fund:
            managerText = 'Fund Manager';
            alternateInvestment = true;
            showDescription = true;
            viewButtonText = deal.has_external_onboarding_link ? 'View & Invest Now' : 'View & Express Interest';
            bidButtonText = deal.has_external_onboarding_link ? 'Invest Now' : 'Express Interest';
            break;
        case DealStageTypes.term_deposit:
            managerText = 'Product Manager';
            alternateInvestment = true;
            showDescription = true;
            viewButtonText = deal.has_external_onboarding_link ? 'View & Invest Now' : 'View & Express Interest';
            bidButtonText = deal.has_external_onboarding_link ? 'Invest Now' : 'Express Interest';
            break;
        case DealStageTypes.accus:
            managerText = 'Manager';
            alternateInvestment = true;
            showDescription = true;
            viewButtonText = 'Buy / Sell';
            bidButtonText = 'Buy / Sell';
            break;
        case DealStageTypes.unlisted:
            managerText = 'Manager';
            alternateInvestment = true;
            showDescription = true;
            viewButtonText = 'View & Express Interest';
            bidButtonText = 'Express Interest';
            break;
        default:
            managerText = 'Lead Manager';
            alternateInvestment = false;
            showDescription = false;
            bidButtonText = 'Place a Bid';
            break;
    }

    return {
        managerText,
        alternateInvestment,
        showDescription,
        viewButtonText,
        bidButtonText
    }
}