import { useState, useEffect, useRef } from 'react';
import { DashboardLayout } from '~/Layouts';
import {
    ArticleRow,
    Breadcrumbs,
    Button as ThemeButton,
    Circle,
    Container,
    ImagePlaceholder,
    PageBanner,
    PageHeader,
    Media,
    Markdown,
    Pagination,
    WatchlistButton
} from '~/Common';
import { ContentBlock, ContentBlockMenu, AsxAnnouncements } from '~/ContentBlocks'
import {
    Box,
    Button,
    Grid,
    Heading,
    Image,
    Link,
    Text
} from '@theme-ui/components'
import { Icon } from 'assets/Icon'
import { NavLink } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment-timezone'
import { getCurrentCompanyAsync, getCurrentCompanyNewsAsync } from 'features/company/companySlice';
import { useResponsiveValue } from '@theme-ui/match-media'
import { CompanyViewSkeleton } from './skeleton';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useDisplayName } from 'hooks/useDisplayName';

const mapStateToProps = (state) => {
    return { user: state.user, theme: state.theme, companies: state.companies }
}

const CompanyView = ({ user, theme, companies, match }) => {
    const { t } = useTranslation();
    useDocumentTitle(t(`Company - ${companies?.currentCompany?.name || ''}`));
    const dispatch = useDispatch();
    const nodeRef = useRef(null);
    const [notFound, setNotFound] = useState(false);
    const [companySlug, setCompanySlug] = useState('');
    const isMobile = useResponsiveValue([true, true, true, false]);

    const isLoading = companies.currentCompanyStatus === 'loading';
    const company = companies?.currentCompany;
    const articles = companies?.currentCompanyNews;
    const showArticles = Boolean(Array.isArray(articles) && articles.length > 0);
    const companyDisplayName = useDisplayName(company);
    const [announcementsData, setAnnouncementsData] = useState(null);

    useEffect(() => {
        setAnnouncementsData(company?.asx_announcements ? {
            title: 'Recent Announcement Summaries',
            announcements: company?.asx_announcements.slice(0, 4).map((announcement) => ({
                announcement: announcement?.url,
                label: announcement?.title,
                priceSensitive: announcement?.price_sensitive,
                summary: announcement?.summary,
                releaseDate: announcement?.released_at
            }))
        } : null);
    }, [company?.asx_announcements, user?.can_see_trade_screen, company?.asx]);

    useEffect(() => {
        // Get the current company (if the slug is empty or has changed)
        if (companies.currentCompanyStatus === 'idle' || (match?.params?.slug !== companySlug)) {
            const newSlug = match?.params?.slug;

            setCompanySlug(newSlug);
            dispatch(getCurrentCompanyAsync(newSlug));
            dispatch(getCurrentCompanyNewsAsync({slug: newSlug, page: 1 }))

            window.addEventListener('notFound', event => {
                setNotFound(true);
            });
        }
    }, [company, companies, companySlug, match, dispatch]);

    moment.tz.setDefault(user?.user?.timezone);

    return (
        <DashboardLayout p={0}>
            <CSSTransition
                nodeRef={nodeRef}
                in={true}
                timeout={500}
                classNames="fade"
                unmountOnExit
                appear
            >
                <div ref={nodeRef}>
                    {isLoading ?
                        <CompanyViewSkeleton />
                    : <>
                        <PageBanner>
                            <PageHeader sx={{ position: 'relative' }}>
                                <Container variant="layouts.default">
                                    <Grid sx={{
                                        position: 'relative',
                                        gridGap: [4, 4, 4, 5],
                                        gridTemplateColumns: ['1fr', null, null, '2fr 3fr', 'auto 780px'],
                                        width: '100%'
                                    }}>
                                        <Box sx={{ position: 'relative', order: [2, 2, 2, 0] }}>

                                            {notFound ? (
                                                <Box mb={4}>
                                                    <Heading as="h1" variant="display" color="white" mb={2}>{t('companies.notFound.title')}</Heading>
                                                    <Text as="p" color="white">{t('companies.notFound.description')}</Text>
                                                </Box>
                                            ) : (
                                                <>
                                                    <Box mb={4}>
                                                        <Circle size="70px" type="box" sx={{
                                                            bg: 'white',
                                                            zIndex: 10,
                                                            overflow: 'hidden',
                                                            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                                                            mb: 3
                                                        }}>
                                                            {company?.logo?.sizes?.tiny ? (
                                                                <Image src={company?.logo?.sizes?.tiny} sx={{ borderRadius: '50%' }} />
                                                            ) : (
                                                                <ImagePlaceholder sx={{
                                                                    width: '70px',
                                                                    height: '70px',
                                                                    borderRadius: '50%'
                                                                }} />
                                                            )}
                                                        </Circle>
                                                        <Heading as="h1" variant="display" color="white" sx={{ lineHeight: '110%', fontWeight: 500 }} mb={3}>{t(companyDisplayName)}</Heading>
                                                        {company?.highlight ? <Text as="p" variant="h4" color="white" mb={3}>{t(company.highlight)}</Text> : null}
                                                    </Box>
                                                    <Box mt={5}>
                                                        <Grid sx={{
                                                            gridTemplate: ['auto / 1fr', 'auto / 1fr', 'auto / 1fr', 'auto / 1fr', 'auto / repeat(2, 1fr)'],
                                                            gridGap: 2
                                                        }}>
                                                            {company?.current_deal ? (
                                                                <ThemeButton as={NavLink} type={null} to={`/deals/${company?.current_deal?.slug}`} variant="borderedWhite" sx={{
                                                                    display: 'inline-flex',
                                                                    height: '60px',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    mb: 3
                                                                }}>
                                                                    <Text>{t('View current deal')}</Text>
                                                                    <Icon icon="tag" color="white" ml={2} />
                                                                </ThemeButton>
                                                            ) : null}
                                                            {company ? (
                                                                <WatchlistButton sx={{ height: '62px', width: '100%' }} company={company.uuid} withText={true} />
                                                            ) : null}
                                                            {company?.website ? (
                                                                <Button as={Link} variant="link" href={company.website} target="_blank" color="white" sx={{
                                                                    display: 'inline-flex',
                                                                    height: '60px',
                                                                    justifyContent: company?.current_deal ? 'center' : 'flex-start',
                                                                    alignItems: 'center',
                                                                    px: 0,
                                                                    mb: 3
                                                                }}>
                                                                    <Text>{t('View website')}</Text>
                                                                    <Icon icon="external-link" color="white" ml={2} />
                                                                </Button>
                                                            ) : null}
                                                        </Grid>
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                        <Box sx={{
                                            maxWidth: ['none', 'none', 'none', '820px'],
                                            position: 'relative',
                                            order: [0, 0, 0, 2]
                                        }}>
                                            <Media
                                                video={company?.video}
                                                image={company?.banner?.sizes?.large}
                                            />
                                        </Box>
                                    </Grid>
                                </Container>
                            </PageHeader>
                        </PageBanner>
                        {!notFound && (
                            <Container variant="layouts.default">
                                {!isMobile && (
                                    <Breadcrumbs title={company?.name} items={[
                                        {
                                            title: 'Company Insights',
                                            to: '/companies'
                                        }
                                    ]} />
                                )}

                                <Grid sx={{
                                    position: 'relative',
                                    gridGap: [1, 1, 1, 5],
                                    gridTemplateColumns: ['1fr', '1fr', '1fr', '300px auto', 'auto 780px'],
                                    width: '100%'
                                }}>
                                    <Box sx={{ position: 'relative' }}>
                                        <ContentBlockMenu
                                            contentBlocks={company?.content_blocks}
                                            showAsx={false}
                                            showArticles={showArticles}
                                            prependMenu={company?.summary ? {
                                                title: 'About Us',
                                                href: '#about-us'
                                            } : null}
                                        />
                                    </Box>
                                    <Box>
                                        {company?.summary ? (
                                            <Box id="about-us">
                                                <Container variant="layouts.section">
                                                    <Heading as="h2" variant="content_block_heading">{t('About Us')}</Heading>

                                                    <Markdown className="leftContent">{company.summary}</Markdown>
                                                </Container>
                                            </Box>
                                        ) : null}

                                        {company?.asx_announcements && company?.asx_announcements.length > 0 ? (
                                            <AsxAnnouncements user={user} data={announcementsData}></AsxAnnouncements>
                                        ) : null}

                                        {showArticles && (
                                            <Box id="latest-news">
                                                <Container variant="layouts.section">
                                                    <Heading as="h2" variant="content_block_heading" mb={0}>{t('Latest News')}</Heading>
                                                    <Box>
                                                        {articles.map((article, index) => (
                                                            <ArticleRow key={index} article={article} />
                                                        ))}
                                                    </Box>
                                                    <Pagination
                                                        activePage={companies.currentCompanyNewsMeta?.current_page ?? 0}
                                                        pageCount={companies.currentCompanyNewsMeta?.last_page ?? 0}
                                                        onPageChange={(event) => {
                                                            dispatch(getCurrentCompanyNewsAsync({slug: companySlug, page: event.selected + 1 }))
                                                        }}
                                                    />
                                                </Container>
                                            </Box>
                                        )}

                                        {company?.content_blocks && company?.content_blocks.map((block, key) => (
                                            <ContentBlock
                                                type={block?.type}
                                                data={block?.data}
                                                container={block?.settings?.container}
                                                bg={block?.settings?.bg}
                                                blockKey={key + 1}
                                                live_at={block?.live_at}
                                                key={`block${key}`}
                                            />
                                        ))}
                                    </Box>
                                </Grid>
                            </Container>
                        )}
                    </>}
                </div>
            </CSSTransition>
        </DashboardLayout>
    );
};

export default connect(mapStateToProps, null)(CompanyView);
