
import { useEffect, useState, useRef } from 'react'
import { SettingsLayout } from '~/Layouts';
import {
    Button,
    SaveButtons,
    Circle,
    ConfirmTwoFactorModal,
    Tooltip
} from '~/Common';
import {
    Alert,
    Box,
    Close,
    Grid,
    Heading,
    Link,
    Spinner,
    Text,
    Flex,
    Image,
    Input
} from '@theme-ui/components';
import { NavLink } from 'react-router-dom'
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { Form, Formik } from 'formik';
import { Icon } from 'assets/Icon'
import { toast } from 'utils/toast'
import { Yup } from 'utils/validationRules';
import axios from 'axios'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group'
import { utilTimezoneByCountry, getProfileOptionsAsync } from 'features/theme/themeSlice'
import {
    storeAccountDetails,
    setUser,
    enableTwoFactor,
    getTwoFactorQrCode,
    getTwoFactorRecoveryCodes,
    refreshTwoFactorRecoveryCodes,
    disableTwoFactor,
    storeProfilePhoto
} from 'features/auth/authSlice'
import { AdviserRoleTypeOptions } from 'app/constants';

const validationSchema = Yup.object().shape({
    title: Yup.string().nullable().required('Title is required'),
    first_name: Yup.string().nullable().required('First Name is required'),
    middle_names: Yup.string().nullable(),
    last_name: Yup.string().nullable().required('Last Name is required'),
    password: Yup.string().nullable(true).test('len', 'must be at least 8 characters', val => val === undefined || (val !== undefined && val.length >= 8)),
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    role_type: Yup.string().nullable(),
    role_number: Yup.string().nullable(),
    timezone: Yup.string().nullable().required('Timezone is required')
});

export const AdviserSettings = ({ user, theme }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const nodeRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [timezoneOptions, setTimezoneOptions] = useState([]);
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(user?.user.two_factor_enabled);
    const [changePassword, setChangePassword] = useState(false);
    const [showTwoFactorExtras, setShowTwoFactorExtras] = useState(false);
    const [twoFactorQrCode, setTwoFactorQrCode] = useState(null);
    const [twoFactorRecoveryCodes, setTwoFactorRecoveryCodes] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [profileOptions, setProfileOptions] = useState(theme.profile.options);
    const avatarInput = useRef(null);
    const isLocked = Boolean(user?.user?.individual?.locked_at !== null);

    moment.tz.setDefault(user.user?.timezone);

    useEffect(() => {
        utilTimezoneByCountry('AU').then((response) => {
            setTimezoneOptions(response?.data);
        }).catch(({ response }) => {
            setTimezoneOptions([]);
        })

        if (theme.profile.status === 'loading') {
            dispatch(getProfileOptionsAsync());
        }
        if (theme.profile.status === 'attempted') {
            setLoading(false);
            setProfileOptions(theme.profile.options);
        }
    }, [theme, user, dispatch]);

    const handleSubmit = (values, actions) => {
        storeAccountDetails({
            device_name: 'App',
            first_name: values.first_name,
            middle_names: values.middle_names,
            last_name: values.last_name,
            email: values.email,
            timezone: values.timezone,
            password: values.password,
            password_confirmation: values.password_confirmation,
            title: values.title,
            role_type: values.role_type,
            role_number: values.role_number,
        }).then((response) => {
            toast.success(response?.message || t('settings.account.success'), {
                toastId: 'account-success',
            });
            dispatch(setUser(response));
            actions.setSubmitting(false);
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(response?.data?.message || t('settings.account.error'), {
                toastId: 'account-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
        });
    };

    const handleEnableTwoFactor = () => {
        enableTwoFactor().then(() => {
            axios.all([
                getTwoFactorQrCode(),
                getTwoFactorRecoveryCodes()
            ]).then(axios.spread((qrResponse, recoveryResponse) => {
                setTwoFactorQrCode(qrResponse?.svg);
                setTwoFactorRecoveryCodes(recoveryResponse);
                setShowTwoFactorExtras(true);
                setTwoFactorEnabled('pending');
                setShowConfirmModal(true);

                toast.success(t('settings.two_factor.pending.success'), {
                    toastId: 'two_factor-success',
                });
            }))
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.two_factor.pending.error'), {
                toastId: 'two_factor-error',
            });
        });
    };

    const handleRefreshRecoveryCodes = () => {
        refreshTwoFactorRecoveryCodes().then(() => {
            getTwoFactorRecoveryCodes().then((response) => {
                setTwoFactorRecoveryCodes(response);
                setShowTwoFactorExtras(true);

                toast.success(t('settings.two_factor.recoveryCodes.success'), {
                    toastId: 'two_factor-success',
                });
            })
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.two_factor.recoveryCodes.error'), {
                toastId: 'two_factor-error',
            });
        });
    };

    const handleDisableTwoFactor = () => {
        disableTwoFactor().then(() => {
            setShowTwoFactorExtras(false);
            setTwoFactorEnabled(false);

            toast.success(t('settings.two_factor.disabled.success'), {
                toastId: 'two_factor-success',
            });
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.two_factor.disabled.error'), {
                toastId: 'two_factor-error',
            });
        });
    };

    const uploadAvatar = () => avatarInput.current && avatarInput.current.click()
    const handleUploadAvatar = (e) => {
        if (!!e.target?.files?.length) {
            let formdata = new FormData();
            formdata.append('profile_photo', e.target?.files?.[0]);

            storeProfilePhoto(formdata)
                .then((response) => {
                    dispatch(setUser(response));
                    toast.success(t('Your profile photo has been changed'), {
                        toastId: 'avatar-upload-success',
                    });
                })
                .catch((e) => {
                    toast.error(
                        e.response?.data?.errors?.profile_photo?.[0] ?? 'There was an error uploading your profile picture',
                        {
                            toastId: 'avatar-upload-error',
                        }
                    );
                })
        }

        // Reset input
        avatarInput.current.value = ''
    }

    const renderTwoFactorButton = () => {
        switch (twoFactorEnabled) {
            case 'pending':
                return (
                    <Button variant="bordered" onClick={(e) => setShowConfirmModal(true)}>{t('settings.two_factor.pending.buttonText')}</Button>
                );

            case true:
                return (
                    <Box><Button variant="light" onClick={(e) => handleRefreshRecoveryCodes()}>{t('settings.two_factor.enabled.regenerateButtonText')}</Button> <Button variant="bordered" onClick={(e) => handleDisableTwoFactor()}>{t('settings.two_factor.enabled.buttonText')}</Button></Box>
                );

            case false:
            default:
                return (
                    <Button variant="bordered" onClick={(e) => handleEnableTwoFactor()}>{t('settings.two_factor.disabled.buttonText')}</Button>
                );
        }
    };

    const getRoleNumberLabel = (roleType) => {
        switch(roleType){
            case 'accountant': return 'CPA/CA/IPA Member Number';
            case 'broker': return 'Financial Adviser Number';
            default: break;
        }
        return 'Identification Number';
    };

    const getRoleNumberInstructions = (roleType) => {
        switch (roleType) {
            case 'accountant':
                return <Link href="https://asic.gov.au/regulatory-resources/financial-services/financial-product-disclosure/certificates-issued-by-a-qualified-accountant/" target="_blank">
                    See ASIC about CPA/CA/IPA Member Number <Icon icon="external-link" sx={{ bottom: '-4px', position: 'relative' }} />
                </Link>;
            case 'broker':
                return <Link href="https://moneysmart.gov.au/financial-advice/financial-advisers-register" target="_blank">
                    See Money Smart to find your Financial Adviser Number <Icon icon="external-link" sx={{ bottom: '-4px', position: 'relative' }} />
                </Link>;
            default: break;
        }
        return null;
    };

    return (
        <SettingsLayout>
            {loading ? <Box id="settingsLoading"><Spinner variant="styles.spinner" m={'auto'} /></Box> : (
                <>
                    <CSSTransition
                        nodeRef={nodeRef}
                        in={true}
                        timeout={500}
                        classNames="fade"
                        unmountOnExit
                        appear
                    >
                        <Box ref={nodeRef}>
                            <Heading as="h1" variant="h2">{t('Account Details')}</Heading>

                            {isLocked && (
                                <Alert mt={4} mb={4} variant="info" isCloseable={false}>
                                    {t('settings.account.locked')}
                                </Alert>
                            )}

                            <Input
                                ref={avatarInput}
                                type="file"
                                sx={{ width: 0, height: 0, visibility: 'hidden' }}
                                onChange={handleUploadAvatar}
                            />
                            <Formik
                                enableReinitialize
                                validateOnMount={true}
                                initialValues={{
                                    title: user?.user?.individual?.title ?? '',
                                    email: user?.user?.email ?? '',
                                    first_name: user?.user?.first_name ?? '',
                                    middle_names: user?.user?.middle_names ?? '',
                                    last_name: user?.user?.last_name ?? '',
                                    timezone: user?.user?.timezone ?? '',
                                    role_type: user?.user?.adviser_data?.role_type ?? '',
                                    role_number: user?.user?.adviser_data?.role_number ?? '',
                                    password: '',
                                    password_confirmation: '',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ status, setStatus, setFieldValue, values }) => (
                                    <Box>
                                        {status === 'api_error' ?
                                            <Alert role="alert" variant='danger' mb={2}>
                                                {t('settings.account.error')}
                                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                                            </Alert> : ''}
                                        <Flex sx={{ mb: '33px', alignItems: 'center' }}>
                                            <Box sx={{ width: '110px', height: '110px', position: 'relative' }}>
                                                <Circle type="box" size="100%" sx={{ overflow: 'hidden' }}>
                                                    <Image
                                                        src={user?.user?.profile_photo_url}
                                                        sx={{
                                                            position: 'absolute',
                                                            width: '100%',
                                                            height: '100%',
                                                            left: 0,
                                                            top: 0,
                                                            objectFit: 'cover'
                                                        }}
                                                    />
                                                </Circle>
                                                <Circle
                                                    size="32px"
                                                    bg="#000"
                                                    sx={{
                                                        position: 'absolute',
                                                        right: 0,
                                                        bottom: 0
                                                    }}
                                                    onClick={uploadAvatar}
                                                >
                                                    <Icon icon="camera" />
                                                </Circle>
                                            </Box>
                                            <Box sx={{ pl: '23px' }}>
                                                <Text as="div" sx={{ fontSize: '20px', lineHeight: '24px' }}>
                                                    {`${user?.user?.first_name ?? ''} ${user?.user?.last_name ?? ''}`.trim()}
                                                </Text>
                                                <Text as="div" sx={{ fontSize: '16px', lineHeight: '18.2px' }}>
                                                    {user?.user?.email}
                                                </Text>
                                                <Box sx={{ lineHeight: 1 }}>
                                                    <Tooltip text={t('Liquidity Account Number')} sx={{ cursor: 'default' }}>
                                                        <Text
                                                            as="div"
                                                            sx={{
                                                                fontSize: 11,
                                                                color: 'dark',
                                                            }}
                                                        >
                                                            {user.user?.account_number}
                                                        </Text>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                        </Flex>
                                        <Form>
                                            <Heading as="h3" variant="settings_heading" mb={3}>{t('Your Details')}</Heading>
                                            <Grid mb={1} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                <Field
                                                    label="Email"
                                                    name="email"
                                                    required="true"
                                                />
                                                <Field
                                                    field="select"
                                                    label="Timezone"
                                                    name="timezone"
                                                    options={timezoneOptions}
                                                />
                                            </Grid>

                                            <Grid mb={1} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                {profileOptions.titles.length > 0 &&
                                                    <Field
                                                        label="Title"
                                                        placeholder="Select title"
                                                        name="title"
                                                        field="searchable-select"
                                                        options={profileOptions.titles}
                                                        required={true}
                                                    />
                                                }
                                                <Field
                                                    label="First Name"
                                                    name="first_name"
                                                />
                                            </Grid>

                                            <Grid mb={1} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                <Field
                                                    label="Middle Names"
                                                    name="middle_names"
                                                />
                                                <Field
                                                    label="Last Name"
                                                    name="last_name"
                                                />
                                            </Grid>

                                            <Grid mb={1} sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                <Field
                                                    field="select"
                                                    placeholder="Select profession"
                                                    label="Profession"
                                                    name="role_type"
                                                    options={AdviserRoleTypeOptions}
                                                />
                                                <Box>
                                                    <Field
                                                        label={getRoleNumberLabel(values.role_type)}
                                                        name="role_number"
                                                    />
                                                    <Text as="p" variant="caption" color="dark" mb={3}>
                                                        {getRoleNumberInstructions(values.role_type)}
                                                    </Text>
                                                </Box>
                                            </Grid>

                                            <Grid sx={{
                                                gridGap: [2, 3],
                                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
                                                width: '100%'
                                            }}>
                                                <Box>
                                                    <Heading as="h3" variant="settings_heading">{t('Password')}</Heading>
                                                    <Box mb={4}>
                                                        {changePassword ? (
                                                            <>
                                                                <Field
                                                                    label="New Password"
                                                                    name="password"
                                                                    type="password"
                                                                />

                                                                <Box sx={{ mb: 2, fontSize: '13px', pt: 1, color: 'dark' }}>
                                                                    <Text as="p">{t('Passwords must include: ')}</Text>
                                                                    <Text as="p">{t('- At least 8 characters')}</Text>
                                                                    <Text as="p">{t('- At least one uppercase and one lowercase letter')}</Text>
                                                                    <Text as="p">{t('- At least one symbol')}</Text>
                                                                    <Text as="p">{t('- At least one number')}</Text>
                                                                </Box>

                                                                <Field
                                                                    label="Confirm Password"
                                                                    name="password_confirmation"
                                                                    type="password"
                                                                />
                                                                <Box mt={3} mb={4}>
                                                                    <Button variant="bordered" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setChangePassword(false)
                                                                    }}>{t('Cancel Password Change')}</Button>
                                                                </Box>
                                                            </>
                                                        ) : (
                                                            <Button variant="bordered" onClick={() => setChangePassword(true)}>{t('Change My Password')}</Button>
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Heading as="h3" variant="settings_heading" mb={3}>{t('settings.two_factor.heading')}</Heading>
                                                    <Text as="p" variant="body_large" color="slate" mb={4}>
                                                        {t('settings.two_factor.subheading')}
                                                    </Text>
                                                    <Box mb={4}>
                                                        <Text as="p" sx={{ fontWeight: 500 }} mb={2}>{t(twoFactorEnabled ? 'settings.two_factor.enabled.heading' : 'settings.two_factor.disabled.heading')}</Text>
                                                        <Text as="p" variant="caption" color="dark" mb={4}>
                                                            {t(twoFactorEnabled ? 'settings.two_factor.enabled.subheading' : 'settings.two_factor.disabled.subheading')}
                                                        </Text>

                                                        {showTwoFactorExtras && <Box mb={2}>
                                                            {twoFactorRecoveryCodes.length > 0 && <>
                                                                <Text as="p" variant="captionTitle" mb={1}>{t('Recovery Codes')}</Text>
                                                                <Text as="p" variant="caption" color="dark" mb={3}>{t('settings.two_factor.instructions.recoveryCodes')}</Text>
                                                                <Box mb={4}>
                                                                    {twoFactorRecoveryCodes.map(recoveryCode => (
                                                                        <Text as="div" key={`rc-${recoveryCode}`} variant="caption" color="dark">{recoveryCode}</Text>
                                                                    ))}
                                                                </Box>
                                                            </>}
                                                        </Box>}

                                                        {renderTwoFactorButton()}
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            {(!isLocked || changePassword) && (
                                                <SaveButtons>
                                                    <Submit
                                                        variant="primary"
                                                        text={t('buttons.save')}
                                                        sx={{
                                                            width: '200px'
                                                        }}
                                                        showErrorTooltip={true}
                                                    />
                                                    <Button
                                                        as={NavLink}
                                                        to="/portfolio"
                                                        variant="light"
                                                        sx={{
                                                            width: '200px'
                                                        }}
                                                    >
                                                        {t('buttons.discard')}
                                                    </Button>
                                                </SaveButtons>
                                            )}
                                        </Form>
                                    </Box>
                                )}
                            </Formik>
                        </Box>
                    </CSSTransition>

                    <ConfirmTwoFactorModal
                        isOpen={showConfirmModal}
                        twoFactorQrCode={twoFactorQrCode}
                        user={user}
                        closeModal={() => setShowConfirmModal(false)}
                        onSuccess={() => setTwoFactorEnabled(true)}
                    />
                </>
            )}
        </SettingsLayout>
    );
};
