import React, { forwardRef } from 'react';
import { CurrencyField } from '../CurrencyField';

export const CoinField = forwardRef(
    ({ ...props }, ref) => {
        return (
            <CurrencyField
                prefix=''
                decimalScale={0}
                sx={{ paddingLeft: '2.8rem !important' }}
                icon='coin-thick'
                {...props}
                ref={ref}
            />
        );
    }
);
