import { useContext, useState } from 'react';
import * as Yup from 'yup';
import {
    Alert,
    Box,
    Close,
    Heading,
    Link,
    Paragraph
} from '@theme-ui/components';
import { SkipIdentity } from '~/Common'
import { Field } from '~/Forms/Field';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { OnboardingContext } from 'pages/Onboarding/Landing';
import { onboardingCertificate, getOnboardingStatusAsync } from 'features/auth/authSlice';
import { toast } from 'utils/toast';
import { connect, useDispatch } from 'react-redux';
import {OnboardingSteps} from "../../../app/constants";

const mapStateToProps = (state) => {
    return { theme: state.theme }
}

const validationSchema = Yup.object().shape({
    certificate: Yup.string().required()
});

const OnboardingCertificate = ({ theme }) => {
    const onboarding = useContext(OnboardingContext);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [reloadUpload, setReloadUpload] = useState(false);

    // Reload the upload input because it will be otherwise stuck in loading mode
    const refresh = () => {
        setReloadUpload(true);

        setTimeout(() => {
            setReloadUpload(false);
        }, 750);
    };

    const handleUpload = ({ name, value, setFieldValue, submitForm, ...rest }) => {
        setFieldValue(name, value)
            .then(() => submitForm());
    };

    const handleSubmit = (values, actions) => {
        let formdata = new FormData();
        formdata.append('certificate', values.certificate);

        onboardingCertificate(formdata).then((response) => {
            toast.success(t('onboarding.landing.certificate.success'), {
                toastId: 'certificate-success',
            });
            onboarding.nextStep();
            dispatch(getOnboardingStatusAsync());
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(response?.data?.message || t('onboarding.landing.certificate.error'), {
                toastId: 'certificate-error',
            });
            actions.resetForm();
            actions.setStatus('api_error');
            actions.setSubmitting(false);

            refresh();
        });
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    certificate: ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ status, setStatus, setFieldValue, submitForm }) => (
                    <>
                        <Box>
                            { status === 'api_error' ?
                            <Alert role="alert" variant='danger' mb={2}>
                                {t('onboarding.landing.certificate.error')}
                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                            </Alert> : ''}

                            <Form>
                                <Heading as="h1" variant="h3" sx={{ fontWeight: 500 }} mb={3}>
                                    {t('onboarding.landing.certificate.title')}
                                </Heading>

                                <Box mb={4}>
                                    <SkipIdentity sx={{ textAlign: 'left' }} />
                                </Box>

                                <Paragraph mb={4} sx={{
                                    fontSize: 2,
                                    color: 'dark'
                                }}>
                                    {t('onboarding.landing.certificate.description')}
                                </Paragraph>

                                {theme.settings.certificate_template && (
                                    <Box mb={4} sx={{
                                        color: 'dark',
                                        fontSize: '13px',
                                        '& a': {
                                            color: 'tealDark'
                                        }
                                    }}>
                                        {t('Need help?')} <Link as="a" href={theme.settings.certificate_template} color="tealDark" target="_blank" variant="light">{t('Download and complete our certificate template')}</Link>
                                    </Box>
                                )}

                                <Field
                                    field="upload"
                                    name="certificate"
                                    label="Upload 708 certificate"
                                    allowMimes={[
                                        'image/png',
                                        'image/gif',
                                        'image/jpeg',
                                        'application/pdf'
                                    ]}
                                    reloadUpload={reloadUpload}
                                    onChange={(props) => handleUpload({ submitForm, setFieldValue, ...props })}
                                    showLabel={false}
                                />

                                <Box sx={{
                                    textAlign: 'center'
                                }}>
                                    <Link as="a" variant="light" color="tealDark" href="#" onClick={(e) => {
                                        e.preventDefault();
                                        onboarding.setReverseSlides(false);
                                        onboarding.setCurrentStep(OnboardingSteps.identity);
                                        onboarding.setSeenIdentityCards(false);
                                        dispatch(getOnboardingStatusAsync());
                                    }}>
                                        {t('Review slides again')}
                                    </Link>
                                </Box>
                            </Form>
                        </Box>
                    </>
                )}
            </Formik>
        </>
    )
};

export default connect(mapStateToProps, null)(OnboardingCertificate);
