import { useState, useEffect, useRef } from 'react';
import {
    Button,
    Container,
    PageBanner,
    PageHeader,
    Pagination
} from '~/Common';
import {
    Box,
    Divider,
    Flex,
    Grid,
    Heading,
    NavLink,
    Text
} from '@theme-ui/components';
import {
    Card, Filters
} from '~/Companies';
import { Icon } from 'assets/Icon';
import { DashboardLayout } from '~/Layouts';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCompanies, getCompaniesAsync } from 'features/company/companySlice';
import { CarouselWrapper } from '~/Grafa';
import { APP_FEATURES } from 'app/constants';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { theme: state.theme, user: state.user?.user, companies: state.companies }
}

const CompaniesDashboard = ({ companies, user, theme }) => {
    const defaultResults = new Array(9).fill(null);
    const { t } = useTranslation();
    useDocumentTitle(t('Companies'));
    const dispatch = useDispatch();
    const activeCompanies = useRef(null);
    const [results, setResults] = useState(defaultResults);
    const [isFiltering, setIsFiltering] = useState(false);
    const [loading, setLoading] = useState(true);
    const [liveFilters, setLiveFilters] = useState({});
    const filterRef = useRef();

    const smoothScroll = () => {
        window.scrollTo({ behavior: 'smooth', top: activeCompanies.current.offsetTop });
    };

    const handleSubmit = (values) => {
        setIsFiltering(true);
        const payload = getCompanies({
            params: {
                page: 0,
                type: values?.type ?? null,
                stage: values?.stage ?? null,
                category: values?.category ?? null,
                sortBy: values?.sortBy ?? null,
                search: values?.search ?? null
            }
        });
        setLiveFilters({
            page: 0,
            type: values?.type ?? null,
            stage: values?.stage ?? null,
            category: values?.category ?? null,
            sortBy: values?.sortBy ?? null,
            search: values?.search ?? null
        });
        setLoading(true);
        dispatch(getCompaniesAsync(payload));
        return false;
    };

    const resetFilters = (e) => {
        e.preventDefault();
        filterRef.current.resetForm();
        setIsFiltering(false);
        const payload = getCompanies();
        setResults(defaultResults);
        dispatch(getCompaniesAsync(payload));
    };

    useEffect(() => {
        if (companies?.companies.status === 'idle') {
            dispatch(getCompaniesAsync());
        }
        if (companies?.companies.status === 'attempted') {
            setResults(companies?.companies?.results);
            setLoading(false);
        }
    }, [companies, setResults, dispatch]);

    return (
        <DashboardLayout p={0}>
            <PageBanner>
                <Heading as="h1" mt={5} mb={2} id="activeCompanies" ref={activeCompanies}  sx={{
                    color: 'white'
                }}>{t('companiesDashboard.title')}</Heading>

                <Box>
                    {t('companiesDashboard.description')}
                </Box>
                <Divider color="white" />
                <Flex mt={1}>
                    <Icon icon="office-building" size="20px" color="white" mr={1} />
                    <Text>{companies?.stats?.counts?.active_companies} trending compan{companies?.stats?.counts?.active_companies === 1 ? 'y' : 'ies'}</Text>
                </Flex>
            </PageBanner>
            <PageHeader>
                <Container>
                    <Box variant="layouts.default" sx={{ mb: [2,'50px'] }}>
                        <Filters handleSubmit={handleSubmit} formRef={filterRef} />
                    </Box>
                </Container>
            </PageHeader>
            <Container variant="layouts.default">
                <Flex mb={4} sx={{
                    flexDirection: 'column',
                    position: 'relative',
                }}>
                    {results.length > 0 ?
                        <>
                            <Grid sx={{
                                gridGap: 4,
                                gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
                                gridTemplateRows: '1fr',
                                width: '100%',
                                overflowX: 'auto',
                                pt: '10px',
                                pb: '30px',
                                px: 3,
                                mx: -3,
                                boxSizing: 'content-box',
                            }}>
                                {results.map((company, key) => (
                                    <Box key={`company-result${key}`}>
                                        <Card company={company} />
                                    </Box>
                                ))}
                            </Grid>

                            <Pagination
                                activePage={companies.companies?.meta?.current_page ?? 0}
                                pageCount={companies.companies?.meta?.last_page ?? 0}
                                onPageChange={(event) => {
                                    setLiveFilters((prev) => ({
                                        ...prev,
                                        page: (event.selected + 1)
                                    }));
                                    const newFilters = { ...liveFilters, page: (event.selected + 1) };
                                    const payload = getCompanies({ params: newFilters });
                                    setResults(() => defaultResults);
                                    smoothScroll();
                                    setLoading(true);
                                    dispatch(getCompaniesAsync(payload))
                                }}
                            />
                        </>
                    : null}

                    {(!loading && companies.companies.results.length === 0) &&
                        <Box px={2}>
                            <Flex sx={{
                                py: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}>
                                <Icon icon={isFiltering ? 'error' : 'lock-thin'} color="tealDark" mb={'28px'} width="84px" />
                                <Heading variant="h2" sx={{ fontSize: '36px' }}>{t('companiesDashboard.notFoundHeading')}</Heading>
                                <Text mt={1} variant="subtext">{isFiltering ? t('companiesDashboard.filteringNotFound') : t('companiesDashboard.notFound')}</Text>
                                {isFiltering &&
                                    <Button
                                        as={NavLink}
                                        onClick={resetFilters}
                                        sx={{
                                            minWidth: '160px',
                                            mt: '56px'
                                        }}
                                    >
                                        Reset filters
                                    </Button>
                                }
                            </Flex>
                        </Box>
                    }
                </Flex>
            </Container>
            {APP_FEATURES.news ? <CarouselWrapper /> : null}
        </DashboardLayout>
    );
};

export default connect(mapStateToProps, null)(CompaniesDashboard);
