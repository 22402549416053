import {
    Box
} from '@theme-ui/components'
import {
    Tooltip
} from '~/Common'
import { Icon } from 'assets/Icon'
import { useTranslation } from 'react-i18next';
import { DealInvestmentStatus } from 'app/constants';

export const StatusIcon = ({ icon = '', color = 'light', label = 'Placement', status, sx, size = 16, ...props }) => {
    const { t } = useTranslation();

    switch(status){
        case DealInvestmentStatus.pending:
            icon = 'pending';
            color = 'warning';
            label = label === 'Pending' ? 'Pending Approval' : label;
            break;

        case DealInvestmentStatus.authorised:
            icon = 'pending';
            color = 'tealDark';
            break;

        case DealInvestmentStatus.agreement_signed:
            icon = 'info';
            color = 'tealDark';
            break;

        case DealInvestmentStatus.money_received:
            icon = 'info';
            color = 'tealDark';
            break;

        case DealInvestmentStatus.fulfilled:
            icon = 'done';
            color = 'tealDark';
            break;

        case DealInvestmentStatus.rejected:
            icon = 'pending';
            color = 'error';
            break;

        case DealInvestmentStatus.cancelled:
            icon = 'pending';
            color = 'medium';
            break;

        default:
            break;
    }

    return (
        <Tooltip text={t(label)} sx={sx}>
            <Box sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: color,
                height: size
            }}
            {...props}
            >
                {icon && <Icon icon={icon} size={size} />}
            </Box>
        </Tooltip>
    );
}
