import { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Link,
    Spinner,
} from '@theme-ui/components'
import { connect, useDispatch } from 'react-redux';
import { storeCategoriesAsync, setWatchlistStatus } from 'features/watchlist/watchlistSlice';
import { Icon } from 'assets/Icon';
import { Button } from '../Button';
import { toast } from 'utils/toast';
import { useTranslation } from 'react-i18next';

const mapStateToProps = (state) => {
    return { user: state.user?.user, theme: state.theme, watchlist: state.watchlist }
}

const PreferenceCategory = ({ user, theme, watchlist, showSave = true, showToast = true, preSelect = false, children, ...props }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [ isLoaded, setLoaded ] = useState(false);
    const [ selectedCategories, setCategories ] = useState([]);
    const [ isTouched, setTouched ] = useState(false);

    useEffect(() => {
        if (!isLoaded && watchlist.status === 'attempted') {
            setCategories( watchlist.watchlist.categories );
            setLoaded(true);
        }
    }, [watchlist, isLoaded]);

    useEffect(() => {
        if (preSelect && theme.status === 'attempted') {
            setCategories( theme.categories.map(c => c.uuid) );
        }
    }, [preSelect, theme]);

    const toggleAll = () => {
        if(Array.isArray(selectedCategories) && Array.isArray(theme.categories)) {
            setCategories(
                theme.categories?.length === selectedCategories.length
                    ? []
                    : theme.categories.map(c => c.uuid)
            )
            setTouched(true);
        }
    }

    const handleSave = () => {
        setTouched(false);
        return dispatch(storeCategoriesAsync({ categories: selectedCategories }))
            .then(() => {
                if (showToast) {
                    toast.success(t('settings.preferences.update_category.success'), {
                        toastId: 'preferences-update-category',
                    });
                }
                dispatch(setWatchlistStatus('resync'));
            });
    }

    const selectCategory = (uuid) => {
        if (!Array.isArray(selectedCategories)) {
            return;
        }

        setTouched(true);

        if (selectedCategories.includes(uuid)) {
            setCategories(s => s.filter(item => item !== uuid));
        } else {
            setCategories([
                ...selectedCategories,
                uuid
            ]);
        }
    };

    const renderChildren = () => {
        if (typeof children === 'function') {
            return children(handleSave);
        } else {
            return children;
        }
    }

    const isAllSelected = Array.isArray(selectedCategories) && selectedCategories.length === theme.categories.length;

    return Array.isArray(theme.categories) && theme.categories.length > 0 ? (
        <>
            <Grid mb={4} sx={{
                gridGap: 4,
                gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)'],
                width: '100%',
                maxHeight: ['none', 'calc(100vh - 410px)'],
                overflowY: ['visible', 'auto']
            }}>
                {theme.categories.length > 0 && (
                    <>
                    {theme.categories.map((category, key) => (
                        <Link to="#selectCategory" className="btn" key={key} sx={{
                            backgroundImage: `url('${category?.image?.sizes?.thumbnail}')`,
                            backgroundSize: 'cover',
                            minHeight: '109px',
                            display: 'flex',
                            borderRadius: '6px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            textDecoration: 'none',
                            overflow: 'hidden',
                            whiteSpace: 'normal',
                            p: '10px',
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                right: 0,
                                bottom: 0,
                                background: 'rgba(0,0,0,0.2)',
                                opacity: 0,
                                transition: 'opacity 0.5s'
                            },
                            '&:hover::after': {
                                opacity: 1
                            }
                        }} onClick={(e) => {
                            e.preventDefault();
                            selectCategory(category.uuid);
                        }}>
                            {Array.isArray(selectedCategories) && selectedCategories.includes(category.uuid) &&
                                <Box sx={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    zIndex: 10
                                }}>
                                    <Icon icon="tick-circle-white" color="black" size="18" />
                                </Box>
                            }
                            <Box sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                background: 'rgba(0,0,0,0.2)',
                                zIndex: 1
                            }}></Box>
                            <Box color="white" sx={{
                                zIndex: 10,
                                fontSize: '18px',
                                fontWeight: 500
                            }}>{category.name}</Box>
                        </Link>
                    ))}
                    </>
                )}
            </Grid>
            {showSave && <Button className="saveChanges" disabled={!isTouched || !showSave} onClick={handleSave} mr={2}>Save Changes</Button>}
            <Button ml={2} mr={2} className="toggleAll" variant="secondary" onClick={toggleAll}>{isAllSelected ? 'Unselect All' : 'Select All'}</Button>
            {renderChildren()}
        </>
    ) : (
        <Spinner variant="styles.spinner" mx="0" mt={1} />
    )
};

export default connect(mapStateToProps, null)(PreferenceCategory);
