import { useState, useEffect, useRef } from 'react';
import { DashboardLayout } from '~/Layouts';
import {
    Button,
    Container,
    PageHeader,
    Pagination,
    PageBanner,
    SwitchView
} from '~/Common';
import {
    Box,
    Flex,
    Grid,
    Heading,
    NavLink,
    Text
} from '@theme-ui/components';
import {
    Card,
    Featured,
    List,
    Related,
    Filters
} from '~/Deals';
import { Icon } from 'assets/Icon';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setItemLayout } from 'features/theme/themeSlice';
import { APP_FEATURES } from 'app/constants';
import { getDeals, getDealsAsync, getFeaturedDealsAsync, getLaunchedDealsAsync, getClosedDealsAsync, getDealStatsAsync } from 'features/deal/dealSlice';
import { isBroadcastingEnabled } from 'utils/helpers';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const mapStateToProps = (state) => {
    return { theme: state.theme, user: state.user?.user, deals: state.deals }
}

const DealsDashboard = ({ deals, user, theme }) => {
    const defaultResults = new Array(9).fill(null);
    const { t } = useTranslation();
    useDocumentTitle(t('Deals'));
    const dispatch = useDispatch();
    const liveDeals = useRef(null);
    const filterRef = useRef();
    const [isFiltering, setIsFiltering] = useState(false);
    const [results, setResults] = useState(defaultResults);
    const [loading, setLoading] = useState(true);
    const [liveFilters, setLiveFilters] = useState({});
    const smoothScroll = () => {
        window.scrollTo({ behavior: 'smooth', top: liveDeals.current.offsetTop });
    };

    const changeItemLayout = (e, type) => {
        e.preventDefault();
        setLoading(true);
        dispatch(setItemLayout(type));

        const copyResults = [...results];
        setResults(() => {
            return defaultResults;
        });

        setTimeout(() => {
            setResults(() => copyResults);
            setLoading(false)
        }, 750);
    };

    const handleSubmit = (values) => {
        setIsFiltering(true);
        const payload = getDeals({
            params: {
                page: 0,
                type: values?.type ?? null,
                stage: values?.stage ?? null,
                category: values?.category ?? null,
                sortBy: values?.sortBy ?? null
            }
        });
        setLiveFilters({
            page: 0,
            type: values?.type ?? null,
            stage: values?.stage ?? null,
            category: values?.category ?? null,
            sortBy: values?.sortBy ?? null
        });
        setLoading(true);
        dispatch(getDealsAsync(payload));
        return false;
    };

    const resetFilters = (e) => {
        e.preventDefault();
        filterRef.current.resetForm();
        setLiveFilters({});
        setIsFiltering(false);
        const payload = getDeals();
        setLoading(true);
        dispatch(getDealsAsync(payload));
    };

    useEffect(() => {
        if (deals?.deals.featured.status === 'idle') {
            dispatch(getFeaturedDealsAsync());
        }
        if(deals.deals.featured.status === 'attempted' && deals?.deals.live.status === 'idle'){
            const payload = getDeals({
                params: {
                    page: 0,
                    not_deal: deals?.currentFeaturedDeal
                }
            });
            dispatch(getDealsAsync(payload));
        }
        if(deals?.deals.launched.status === 'idle'){
            dispatch(getLaunchedDealsAsync());
        }
        if(deals?.deals.closed.status === 'idle'){
            dispatch(getClosedDealsAsync());
        }
        if(deals?.stats.status === 'idle'){
            dispatch(getDealStatsAsync());
        }
        if(deals?.deals.live.status === 'attempted'){
            setResults(deals?.deals?.live?.results);
            setLoading(false);
        }
    }, [deals, setLoading, setResults, dispatch]);

    useEffect(() => {
        if (isBroadcastingEnabled()) {
            window.Echo.private(`deals`).listenToAll(() => {
                const payload = getDeals({ params: liveFilters });
                setLoading(true);
                dispatch(getDealsAsync(payload));
                dispatch(getFeaturedDealsAsync());
                dispatch(getLaunchedDealsAsync());
                dispatch(getClosedDealsAsync());
                dispatch(getDealStatsAsync());
            });
        }
    }, [dispatch, liveFilters]);

    return (
        <DashboardLayout p={0}>
            <PageBanner>
                <Container variant="layouts.pageBanner">
                    <Heading as="h2" variant="page_title">{`Exclusive Deals`}</Heading>
                </Container>
            </PageBanner>
            <PageHeader>
                <Container>
                    <Box sx={{
                        borderBottom: '1px solid #fff',
                        borderColor: 'medium'
                    }}>
                        <Box pt="82px" pb="20px">
                            <Flex sx={{
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                flexDirection: ['column', 'column', 'row'],
                                width: '100%'
                            }}>
                                <Box sx={{ width: ['100%', null] }}>
                                    <Heading as="h1" variant="h3">{t('Explore Deals')}</Heading>
                                </Box>

                                <Flex ml={[null, 'auto']} sx={{
                                    width: '100%',
                                    textAlign: 'left',
                                    color: '#6A7175',
                                    fontWeight: 'bold',
                                    justifyContent: ['left', 'left', 'right'],
                                    letterSpacing: '-0.01em',
                                    mt: [3, 3, 0],
                                    flexDirection: ['column', 'row'],
                                }}>
                                    <Flex sx={{ alignItems: 'center' }}>
                                        <Icon icon="live-deals" size="20px" mr={2} color="tealDark" /> {t('deals.stats.liveBidding', {
                                            count: deals.stats?.counts?.live_deals ?? 0,
                                            singularPlural: deals.stats?.counts?.live_deals !== 1 ? 'deals' : 'deal'
                                        })}
                                    </Flex>
                                    {deals?.stats?.counts?.bids_last_7_days > 0 ? <Flex sx={{ alignItems: 'center' }} ml={[0,3]}>
                                        <Icon icon="dollar" color="tealDark" size="20px" mr={2} /> {t('deals.stats.bidsLast7Days', {
                                            count: deals.stats?.counts?.bids_last_7_days ?? 0,
                                            singularPlural: deals.stats?.counts?.bids_last_7_days !== 1 ? 'bids' : 'bid'
                                        })}
                                    </Flex> : null}
                                    {deals?.stats?.counts?.deals_closed_last_3_days > 0 ? <Flex sx={{ alignItems: 'center' }} ml={[0, 3]}>
                                        <Icon icon="lock-thin" size="20px" mr={2} color="tealDark" />  {t('deals.stats.dealsClosedLast3Days', {
                                            count: deals.stats?.counts?.deals_closed_last_3_days ?? 0,
                                            singularPlural: deals.stats?.counts?.deals_closed_last_3_days !== 1 ? 'offers' : 'offer'
                                        })}
                                    </Flex> : null}
                                </Flex>
                            </Flex>
                        </Box>
                    </Box>

                    <Box variant="layouts.default">
                        {deals?.deals.featured.status !== 'attempted' ?
                            <Featured />
                        : null}
                        {deals?.deals?.featured?.results.length > 0 ? (
                            <>
                            {deals.deals.featured.results.map((deal, key) => (
                                <Featured deal={deal} key={key} />
                            ))}
                            </>
                        ) : null}
                    </Box>

                    {APP_FEATURES.deal_filters && (
                        <Box variant="layouts.default" sx={{ mb: [2,'50px'] }}>
                            <Filters handleSubmit={handleSubmit} formRef={filterRef} />
                        </Box>
                    )}
                </Container>
            </PageHeader>
            <Container variant="layouts.default">
                <Grid sx={{
                    alignItems: 'center',
                    gridTemplate: 'auto / repeat(2, 1fr)',
                    borderBottom: '1px solid',
                    borderColor: 'medium',
                    py: 3,
                    mb: '38px'
                }}>
                    <Heading as="h2" variant="h3" id="liveDeals" ref={liveDeals}>{t('Live Deals')}</Heading>
                    <SwitchView selected={theme.itemLayout} onChange={changeItemLayout} />
                </Grid>

                <Flex mb={[4,5,6]} sx={{
                    flexDirection: 'column',
                    position: 'relative',
                }}>
                    {results.length > 0 ?
                        <>
                            <Grid sx={{
                                gridGap: 4,
                                gridTemplateColumns: theme.itemLayout === 'grid'
                                    ? ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']
                                    : '1fr',
                                gridTemplateRows: '1fr',
                                width: '100%',
                                overflowX: 'auto',
                                pt: '10px',
                                pb: '30px',
                                px: 3,
                                mx: -3,
                                boxSizing: 'content-box',
                            }}>
                                {results.map((deal, key) => (
                                    <Box key={`deals-live-result${key}`}>
                                        {theme.itemLayout === 'grid' ? (
                                            <Card deal={deal} />
                                        ) : (
                                            <List deal={deal} />
                                        )}
                                    </Box>
                                ))}
                            </Grid>

                            <Pagination
                                activePage={deals.deals?.live?.meta?.current_page ?? 0}
                                pageCount={deals.deals?.live?.meta?.last_page ?? 0}
                                onPageChange={(event) => {
                                    setLiveFilters((prev) => ({
                                        ...prev,
                                        page: (event.selected + 1)
                                    }));
                                    const newFilters = { ...liveFilters, page: (event.selected + 1) };
                                    const payload = getDeals({ params: newFilters });
                                    setResults(() => defaultResults);
                                    smoothScroll();
                                    setLoading(true);
                                    dispatch(getDealsAsync(payload))
                                }}
                            />
                        </>
                    : null}

                    {(!loading && deals.deals.live.results.length === 0) &&
                        <Box px={2}>
                            <Flex sx={{
                                py: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}>
                                <Icon icon={isFiltering ? 'error' : 'lock-thin'} color="tealDark" mb={'28px'} width="84px" />
                                <Heading variant="h2" sx={{ fontSize: '36px' }}>{t('deals.live.notFoundHeading')}</Heading>
                                <Text mt={1} variant="subtext">{isFiltering ? t('deals.live.filteringNotFound') : t('deals.live.notFound')}</Text>
                                {isFiltering &&
                                    <Button
                                        as={NavLink}
                                        onClick={resetFilters}
                                        sx={{
                                            minWidth: '160px',
                                            mt: '56px'
                                        }}
                                    >
                                        Reset filters
                                    </Button>
                                }
                            </Flex>
                        </Box>
                    }
                </Flex>

                <Related id="closedDeals" layout={theme.itemLayout} heading="Missed Opportunities" deals={deals?.deals.closed?.results} limit={12} sx={{ mb: 4 }} />

            </Container>
        </DashboardLayout>
    );
};

export default connect(mapStateToProps, null)(DealsDashboard);
