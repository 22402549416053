import { InPortal } from 'react-reverse-portal';
import { AnimatedTextureCanvas } from '~/Common';
import { useSelector } from 'react-redux';

/* Use a reverse portal for animations to avoid redraw of the canvas on route change */

export const AnimatedTexturePortal = ({ node, ...props }) => {
    const showAnimation = useSelector(state => state.theme.showAnimation);

    return (
        <InPortal node={node}>
            {showAnimation && <AnimatedTextureCanvas {...props} />}
        </InPortal>
    )
}
