import { useRef } from 'react'
import {
    Box,
    Card as ThemeCard,
    Flex,
    Grid,
    Heading,
    Image,
    Text,
} from '@theme-ui/components';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ImagePlaceholder, Skeleton, WatchlistButton } from '~/Common'
import { StatusTag } from '~/Deals'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { dealOfferPriceFormatting } from 'utils/helpers';
import { Icon } from 'assets/Icon'
import { useDisplayName } from 'hooks/useDisplayName';

export const CardCompact = ({ deal, sx, hideCardLink, ...props }) => {
    const { t } = useTranslation();
    const nodeRef = useRef(null);
    const user = useSelector((state) => state.user?.user);
    const dealDisplayName = useDisplayName(deal);
    const cardLink = user?.can_see_dashboard && (hideCardLink === true ? false : true);

    return (
        <CSSTransition
            nodeRef={nodeRef}
            in={true}
            timeout={500}
            classNames="fade"
            unmountOnExit
            appear
        >
            <ThemeCard
                variant="compact"
                as={!cardLink ? Box : Link}
                to={!cardLink ? undefined : `/deals/${deal?.slug}`}
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    ...sx
                }}
                ref={nodeRef}
            >
                <Flex sx={{ position: 'relative' }}>
                    {deal ? (
                        <Flex
                            sx={{
                                position: 'absolute',
                                top: 3,
                                left: 3,
                                zIndex: 10,
                                gap: 2,
                            }}
                        >
                            <StatusTag
                                label={deal?.stage?.name}
                                status={deal?.status?.id}
                                stage={deal?.stage?.id}
                                title={deal?.closed_at ? `Close Date: ${moment(deal.closed_at).utc().clone().local().format('D MMM YYYY')}` : deal?.status?.name}
                            />
                            <StatusTag
                                label={deal?.asx || deal?.listed_on_alternate_exchange ? 'Listed' : 'Unlisted'}
                                sx={{
                                    bg: 'light',
                                    color: 'darker',
                                }}
                            />
                        </Flex>
                    ) : null}

                    {deal?.company?.uuid ? (
                        <Box py={1} px={2} sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            color: 'light',
                            position: 'absolute',
                            top: 2,
                            right: 1,
                            zIndex: 10
                        }}>
                            <WatchlistButton key={`watchlist-deal-compact-company-${deal?.company?.uuid}`} company={deal?.company?.uuid} variant="card" />
                        </Box>
                    ) : null}

                    {deal ? (
                        <>
                            {deal?.banner?.sizes?.thumbnail ? (
                                <Box sx={{
                                    width: '100%',
                                    height: '150px'
                                }}>
                                    <Image src={deal?.banner?.sizes?.thumbnail} sx={{ objectFit: 'cover', height: '100%', width: '100%' }} />
                                </Box>
                            ) : (
                                <ImagePlaceholder sx={{
                                    width: '100%',
                                    height: '150px'
                                }} />
                            )}
                        </>
                    ) : <Skeleton height="150px" />}
                </Flex>
                <Flex px={3} py={3} sx={{
                    position: 'relative',
                    flexDirection: 'column',
                    height: '100%'
                }}>
                    <Box mb={2} sx={{ flex: '1' }}>
                        {deal ? <Text as="p" variant="tag" color="dark" mb={1} sx={{ fontSize: '14px' }}>{deal?.category?.name}</Text> : <Skeleton height="14px"width="50px" mb={1}/>}
                        {deal ? <Heading as="h4" variant="deal_heading_compact">{t(dealDisplayName)}</Heading> : <Skeleton height="24px"width="70%"  mb={1} />}
                        {deal ? <Text as="p" variant="small" color="dark" mb={2} sx={{ fontSize: ['14px','14px'] }}>{deal?.code}</Text> : <Skeleton height="14px"width="30px" mb={2}/>}
                    </Box>

                    {!deal?.preview_mode ? (
                        <>
                            {(deal?.offer_price && deal?.offer_size) && (
                                <Grid mt={'auto'} sx={{
                                    gridTemplate: 'auto / 1fr 1fr 1fr',
                                    gridGap: 1
                                }}>
                                    {deal?.offer_price && (
                                        <Box>
                                            <Text as="p" variant="labelCompact">{t('Offer Price')}</Text>
                                            <Text as="p" variant="price" color="darker" sx={{ fontSize: '14px', fontWeight: 'bold' }}>A${dealOfferPriceFormatting(deal?.offer_price)}</Text>
                                        </Box>
                                    )}
                                    {Boolean(deal?.asx_current_price > 0) && (
                                        <Box>
                                            <Text as="p" variant="labelCompact">{t('Last Price')}</Text>
                                            <Text as="p" variant="price" color="darker" sx={{ fontSize: '14px', fontWeight: 'bold' }}>A${deal?.asx_current_price}</Text>
                                        </Box>
                                    )}
                                    {deal?.asx_change && (
                                        <Box>
                                            <Text as="p" variant="labelCompact">{t('% Change')}</Text>
                                            <Text as="p" variant="price" color="darker" sx={{ fontSize: '14px', fontWeight: 'bold' }}>{deal?.asx_change}%</Text>
                                        </Box>
                                    )}
                                </Grid>
                            )}
                            {(deal?.offer_price && deal?.all_time_low_price) && (
                                <Grid mt={3} sx={{
                                    gridTemplate: 'auto / 1fr 1fr 1fr',
                                    gridGap: 1
                                }}>
                                    {deal?.all_time_low_price && (
                                        <Box>
                                            <Text as="p" variant="labelCompact">{t('All Time Low')}</Text>
                                            <Text as="p" variant="price" color="darker" sx={{ fontSize: '14px', fontWeight: 'bold' }}>A${dealOfferPriceFormatting(deal?.all_time_low_price)}</Text>
                                        </Box>
                                    )}
                                    {Boolean(deal?.all_time_high_price > 0) && (
                                        <Box>
                                            <Text as="p" variant="labelCompact">{t('All Time High')}</Text>
                                            <Text as="p" variant="price" color="darker" sx={{ fontSize: '14px', fontWeight: 'bold' }}>A${deal?.all_time_high_price}</Text>
                                        </Box>
                                    )}
                                    {deal?.asx_all_time_high_change && (
                                        <Box>
                                            <Text as="p" variant="labelCompact">{t('% Change')}</Text>
                                            <Text as="p" variant="price" color="darker" sx={{ fontSize: '14px', fontWeight: 'bold' }}>{deal?.asx_all_time_high_change}%</Text>
                                        </Box>
                                    )}
                                </Grid>
                            )}
                        </>
                    ) : (
                        <Box sx={{ height: '70px' }}></Box>
                    )}

                    <Box mt={2}>
                        {deal ? <Text as="p" variant="labelCompact">{t('Lead Manager')}</Text> : <Skeleton height="14px" width="50px" mb={2}/>}
                        {deal ? (
                            <>
                                {deal?.organisation ? (
                                    <>
                                        {deal?.organisation?.logo?.sizes?.tiny ? (
                                            <Image src={deal?.organisation?.logo?.sizes?.tiny} sx={{ filter: 'grayscale(1)', maxWidth: '100%' }} />
                                        ) : (
                                            <Text as="p" variant="price" color="darker" mb={2} sx={{ fontSize: '14px', fontWeight: 'bold' }}>{deal?.organisation?.name}</Text>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Icon icon="lqd" height="24" color="darker" />
                                    </>
                                )}
                            </>
                        ) : <Skeleton height="32px" width="100px" />}
                    </Box>
                </Flex>
            </ThemeCard>
        </CSSTransition>
    );
};
