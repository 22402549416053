import { Layout } from '~/Layouts';
import { Container, Tooltip } from '~/Common';
import { Box, Grid } from '@theme-ui/components';
import { Icon, allIcons } from 'assets/Icon';

const Icons = ({ ...props }) => {

    return (
        <Layout>
            <Container>
                <Grid sx={{
                    gridTemplate: 'auto / repeat(6, 1fr)'
                }}>
                    {Object.keys(allIcons).map((icon, key) => (
                        <Box key={key} sx={{ p: 3 }}>
                            <Tooltip text={icon}>
                                <Icon icon={icon} size="100%" />
                            </Tooltip>
                        </Box>
                    ))}
                </Grid>
            </Container>
        </Layout>
    );
};

export default Icons;
