import {
    Box,
    Grid,
    Heading
} from '@theme-ui/components'
import { Container, Markdown } from '~/Common'
import { useTranslation } from 'react-i18next'

export const Text = ({ bg = 'white', data, container, ...props }) => {
    const { t } = useTranslation();
    const title = data?.title ?? null;

    return (
        <Box bg={bg} {...props}>
            <Container variant="layouts.section" {...container}>
                { title && <Heading as="h2" variant="content_block_heading">{t(title)}</Heading>}

                <Grid sx={{
                    gridGap: 4,
                    gridTemplateColumns: ['1fr', data?.rightContent ? 'repeat(2, 1fr)' : '1fr'],
                    width: '100%'
                }}>
                    { data?.leftContent && (
                        <Markdown className="leftContent" showReadMore={!!data?.showReadMore}>{data?.leftContent}</Markdown>
                    )}
                    { data?.rightContent && (
                        <Markdown className="rightContent" showReadMore={!!data?.showReadMore}>{data?.rightContent}</Markdown>
                    )}
                </Grid>
            </Container>
        </Box>
    )
};
