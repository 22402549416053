import { useLocation } from 'react-router-dom';
import axios from 'axios'
import { merge } from 'lodash';

export const isBroadcastingEnabled = () => process.env.REACT_APP_BROADCASTING !== undefined && JSON.parse(process.env.REACT_APP_BROADCASTING)

export const useQuery = () => (
    new URLSearchParams(useLocation().search)
);

export const numberWithCommas = (n) => {
    const parts = n !== undefined ? n.toString().split('.') : [];
    const decimals = parts.map((d, i) => i > 0 ? `.${d}` : '');
    return `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${decimals.join('')}`;
}

export const formatCurrency = (n, dec = 2) => {
    const prefix = Number(n) >= 0 ? '$' : '-$';
    return n !== undefined ? `${prefix}${Number(Math.abs(n)).toLocaleString('en-US', { maximumFractionDigits: dec, minimumFractionDigits: dec })}` : '';
};

export const formatNumber = (n) => {
    return n !== undefined ? Number(typeof n === 'string' ? n.replace(/\$|,/g, '') : n) : '';
};

export const formatPercentage = (n) => (
    n !== undefined ? `${n}%` : ''
);

export const dealOfferPriceFormatting = (n) => {
    const num = formatNumber(n);
    return num >= 100 ? numberWithCommas(num.toFixed(0)) : n;
};

export const toTitleCase = (s) => typeof s === 'string' ? s.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : s;

export const replacementValue = (str, data) => {
    let matches = str.match(/{[a-zA-Z0-9_-]+?}/g);
    if(matches){
        matches.forEach(function(match) {
            // Strip the special characters to dynamically get the indices of the object
            let templateVar = match.replace(/#|{|}/g, "");
            str = str.replace(match, data[templateVar]);
        });
    }
    return str;
};

export const getVideoEmbed = async (url) => {
    const domainMatches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
    const domain = domainMatches && domainMatches[1];
    let videoId = null;
    let videoType = null;
    let videoMeta = null;

    switch (domain) {
        case 'youtube.com':
        case 'youtu.be':
        case 'www.youtube.com':
            videoType = 'youtube';
            const ytMatch = url.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/);
            videoId = (ytMatch && ytMatch[2].length === 11) ? ytMatch[2] : null;
        break;

        case 'player.vimeo.com':
        case 'vimeo.com':
            videoType = 'vimeo';
            videoMeta = await fetch('https://vimeo.com/api/oembed.json?url=' + encodeURIComponent(url)).then(response => response.json());
            videoId = videoMeta && videoMeta.video_id !== undefined ? videoMeta.video_id : null;
        break;

        default: break;
    }

    if (videoId !== null) {
        switch (videoType) {
            case 'youtube': return '//www.youtube.com/embed/' + videoId;
            case 'vimeo': return '//player.vimeo.com/video/' + videoId;
            default: return null;
        }
    }
    return null;
};

export const extractStreetTypeFromName = (value) => {
    const response = axios.get('extract-street-type', {
        params: {
            street_name: value
        }
    }).then(({ data }) => {
        return {
            street_name: data?.data?.street_name,
            street_type: data?.data?.street_type
        }
    });
    return response;
};

export const grabFirstError = (errors) => {
    if (typeof errors === 'object' && errors !== null) {
        for (let error in errors) {
            if (errors.hasOwnProperty(error)) {
                if (errors[error][0] !== '') {
                    return errors[error][0];
                }
            }
        }
    }
    return '';
}

export const getNestedErrors = (errors) => Object
    .entries(errors)
    .reduce((errors, error) => merge(
        errors,
        error[0].split('.').reduceRight((res, key) => ({[key]: res}), error[1])
    ), {});

export const getNestedErrorsByFields = (errors, fields) => {
    const shouldAdd = (name) => !Array.isArray(fields) || fields.includes(name);
    return Object.entries(errors).reduce((errors, error) => {
        const isObject = typeof error[1] === 'object' && !Array.isArray(error[1])
        if (isObject) {
            // If error is object with numeric keys, it's probably a nested array
            if (Object.keys(error[1]).reduce((v, key) => v && !isNaN(key), true)) {
                Object.values(error[1]).forEach((row, index) => {
                    Object.entries(row).forEach((e) => {
                        errors = [
                            ...errors,
                            ...getNestedErrorsByFields({
                                [`${error[0]}.${index}.${e[0]}`]: e[1]
                            }, fields)
                        ];
                    })
                })
                // Otherwise if it's an object that isn't an array, it's probably a nested field (e.g. "address.postcode" field)
            } else {
                Object.entries(error[1]).forEach(
                    (e) => shouldAdd(`${error[0]}.${e[0]}`) && errors.push([`${error[0]}.${e[0]}`, e[1]])
                );
            }
            // Otherwise just push the error as a single error
        } else if (shouldAdd(error[0])) {
            errors.push(error);
        }
        return errors;
    }, []);
}

export const getFallbackProfileImage = (user) => {
    const name = `${user.first_name.substring(0, 1)}+${user.last_name.substring(0, 1)}`;
    return `https://ui-avatars.com/api/?name=${name}&color=7F9CF5&background=EBF4FF`;
}

export const formatBankAccount = (bsb, accNo) => {
    // if either BSB or account number is empty, return empty string
    if (!bsb || !accNo) return '';

    // Strip everything except digits
    bsb = bsb.replace(/\D/g, '');
    accNo = accNo.replace(/\D/g, '');

    // Add dash to BSB
    bsb = `${bsb.substring(0, 3)}-${bsb.substring(3)}`;

    return `${bsb} ${accNo}`;
}

export const smoothScroll = (ref) => {
    window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop });
};

// Turn off console logs for production
if(process.env.NODE_ENV === 'production'){
    console.log = () => {};
}

export const getBankDetailsText = (profile) => {
    if (profile?.account_management_type === 'Full Service') {
        return 'Bank Details:'
    }
    return 'Macquarie CMA:'
}
