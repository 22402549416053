
export const logoStates = {
    default: {
        L: 'M4.86076 0.303833H0V21.9747H21.7215V17.6203H4.86076V0.303833Z',
        I: 'M29.62 0.303833H24.7593V21.9747H29.62V0.303833Z',
        Q: 'M61.48 11.143C61.526 8.438 60.561 6.007 58.681 4.108 56.121 1.52 51.94.025 47.235 0 47.159 0 47.57 0 47.499 0 42.643-.02 38.395 1.479 35.794 4.103 33.92 6.002 32.954 8.438 33 11.138 33 15.915 35.992 19.597 40.92 21.274 42.033 21.653 44.157 22.089 45.223 22.17 47.753 22.459 44.823 21.857 44.812 22.17V24.692C44.812 26.643 46.179 28.005 48.134 28.005H57.101V23.968H50.309C49.572 23.968 49.405 23.801 49.405 23.067V22.165C56.862 21.37 61.486 17.166 61.486 11.148L61.48 11.143ZM47.317 17.941H47.195C42.597 17.941 37.493 15.839 37.493 11.138 37.493 6.438 42.597 4.336 47.195 4.336H47.317C51.899 4.336 56.527 6.438 56.527 11.138 56.527 15.839 51.899 17.941 47.317 17.941Z',
        U: 'M82.6329 11.8481C82.6329 15.8988 80.5064 17.9241 76.3038 17.9241H75.6962C71.4937 17.9241 69.3671 15.8988 69.3671 11.8481V.3038H64.5063V12.1519C64.5063 18.481 68.557 22.2279 75.6456 22.2785 75.8481 22.2785 76.2026 22.2785 76.3038 22.2785 83.2912 22.1773 87.4937 18.3798 87.4937 12.1519V.3038H82.6329V11.8481Z',
        I2: 'M95.3925 0.303833H90.5317V21.9747H95.3925V0.303833Z',
        D: 'M111.848 0.303833H98.4302V21.9747H111.848C119.342 21.9747 124.202 17.7216 124.202 11.1393C124.202 4.557 119.392 0.303833 111.848 0.303833ZM111.19 17.6203H103.291V4.65826H111.139C114.835 4.65826 119.24 5.77219 119.24 11.1393C119.24 16.5064 114.886 17.6203 111.19 17.6203Z',
        I3: 'M132.101 0.303833H127.24V21.9747H132.101V0.303833Z',
        T: 'M135.139 4.65826H145.164V21.9747H150.025V4.65826H160.051V0.303833H135.139V4.65826Z',
        Y: 'M180.253.3038 173.367 8.2532 166.532.3038H160.051L170.886 12.6583V21.9747H175.747V12.6583L186.532.3038H180.253Z',

        triggerL: '21.891',
        triggerQ: '29.2',
        triggerD: '26.179'
    },
    expandL: {
        L: 'M4.86076 0.303833H0V21.9747H45.924V17.6203H4.86076V0.303833Z',
        I: 'M53.8227 0.303833H48.9619V21.9747H53.8227V0.303833Z',
        Q: 'M85.779 11.143C85.825 8.438 84.86 6.007 82.98 4.108 80.42 1.52 76.239.025 71.534 0 71.458 0 71.869 0 71.798 0 66.942-.02 62.694 1.479 60.093 4.103 58.219 6.002 57.253 8.438 57.299 11.138 57.299 15.915 60.291 19.597 65.219 21.274 66.332 21.653 68.456 22.089 69.522 22.17 72.052 22.459 69.122 21.857 69.111 22.17V24.692C69.111 26.643 70.478 28.005 72.433 28.005H81.4V23.968H74.608C73.871 23.968 73.704 23.801 73.704 23.067V22.165C81.161 21.37 85.785 17.166 85.785 11.148L85.779 11.143ZM71.616 17.941H71.494C66.896 17.941 61.792 15.839 61.792 11.138 61.792 6.438 66.896 4.336 71.494 4.336H71.616C76.198 4.336 80.826 6.438 80.826 11.138 80.826 15.839 76.198 17.941 71.616 17.941Z',
        U: 'M107.24 11.7975C107.24 15.8481 105.114 17.8734 100.911 17.8734H100.304C96.1011 17.8734 93.9745 15.8481 93.9745 11.7975V0.253174H89.1138V12.1013C89.1138 18.4304 93.1644 22.1772 100.253 22.2279C100.456 22.2279 100.81 22.2279 100.911 22.2279C107.899 22.1266 112.101 18.3291 112.101 12.1013V0.253174H107.24V11.7975Z',
        I2: 'M119.595 0.303833H114.734V21.9747H119.595V0.303833Z',
        D: 'M136.051 0.303833H122.633V21.9747H136.051C143.544 21.9747 148.405 17.7216 148.405 11.1393C148.405 4.557 143.595 0.303833 136.051 0.303833ZM135.392 17.6203H127.544V4.65826H135.392C139.089 4.65826 143.494 5.77219 143.494 11.1393C143.494 16.5064 139.089 17.6203 135.392 17.6203Z',
        I3: 'M156.304 0.303833H151.443V21.9747H156.304V0.303833Z',
        T: 'M159.342 4.65826H169.367V21.9747H174.228V4.65826H184.253V0.303833H159.342V4.65826Z',
        Y: 'M204.455 0.303833L197.569 8.2532L190.734 0.303833H184.253L195.088 12.6583V21.9747H199.949V12.6583L210.734 0.303833H204.455Z',

        triggerL: '45.987'
    },
    expandQ: {
        Q: 'M85.5001 11.1432C85.5458 8.43845 84.5805 6.00719 82.7008 4.10777C80.1404 1.5195 75.9592 0.0252842 71.2549 -4.14186e-05C71.1787 -4.14186e-05 47.5704 -4.14186e-05 47.4993 -4.14186e-05C42.6425 -0.0203019 38.3954 1.47897 35.7943 4.10271C33.9196 6.00213 32.9544 8.43845 33.0001 11.1382C33.0001 15.9146 35.9924 19.5969 40.9203 21.2735C42.0329 21.6534 44.1565 22.089 45.2233 22.17C47.7533 22.4587 61.5718 22.17 68.8316 22.17V24.6924C68.8316 26.6425 70.1982 28.005 72.1541 28.005H81.1209V23.9681H74.3285C73.5918 23.9681 73.4242 23.801 73.4242 23.0665V22.1649C80.8821 21.3697 85.5052 17.1657 85.5052 11.1483L85.5001 11.1432ZM71.3362 17.9406H47.1945C42.5968 17.9406 37.9534 15.8386 37.9534 11.1382C37.9534 6.43773 42.5968 4.3357 47.1945 4.3357H71.3362C75.9186 4.3357 80.5468 6.43773 80.5468 11.1382C80.5468 15.8386 75.9186 17.9406 71.3362 17.9406Z',
        U: 'M106.455 11.8625C106.455 15.8994 104.321 17.9457 100.115 17.9457H99.5105C95.3043 17.9457 93.1708 15.8994 93.1708 11.8625V.2988H88.2788V12.1664C88.2788 18.4928 92.3478 22.2308 99.4648 22.2815 99.6899 22.2815 99.9149 22.2815 100.14 22.2815 107.166 22.17 111.347 18.4016 111.347 12.1664V.2988H106.455V11.8625Z',
        I2: 'M119.266 0.298828H114.374V21.9776H119.266V0.298828Z',
        D: 'M135.761 0.298828H122.294V21.9776H135.761C143.299 21.9776 148.171 17.7229 148.171 11.1382C148.171 4.55353 143.299 0.298828 135.761 0.298828ZM135.065 17.6418H127.186V4.63457H135.065C138.783 4.63457 143.218 5.7641 143.218 11.1382C143.218 16.5123 138.783 17.6418 135.065 17.6418Z',
        I3: 'M156.096 0.298828H151.204V21.9776H156.096V0.298828Z',
        T: 'M159.123 4.63457H169.176V21.9776H174.068V4.63457H184.121V0.298828H159.123V4.63457Z',
        Y: 'M204.37 0.298828L197.472 8.26627L190.634 0.298828H184.122L194.993 12.6679V21.9776H199.885V12.6679L210.73 0.298828H204.37Z',

        triggerQ: '53'
    },
    expandD: {
        D: 'M135.342 0.303833H98.481V21.9747H135.342C142.835 21.9747 147.696 17.7216 147.696 11.1393C147.696 4.55699 142.835 0.303833 135.342 0.303833ZM134.633 17.6203H103.291V4.60763H134.582C138.278 4.60763 142.683 5.72156 142.683 11.0887C142.683 16.4557 138.329 17.6203 134.633 17.6203Z',
        I3: 'M155.544 0.303833H150.684V21.9747H155.544V0.303833Z',
        T: 'M158.583 4.60763H168.608V21.9241H173.519V4.60763H183.545V0.303833H158.583V4.60763Z',
        Y: 'M203.696 0.303833L196.81 8.25321L189.975 0.303833H183.494L194.329 12.6583V21.9747H199.19V12.6583L209.975 0.303833H203.696Z',

        triggerD: '49.623'
    }
}
