import { useState, useEffect, useRef } from 'react'
import { Icon } from  'assets/Icon'
import { Box, Text, Flex, Spinner } from '@theme-ui/components'
import { CSSTransition } from 'react-transition-group'
import moment from 'moment'
import countdown from 'countdown'
import 'moment-countdown'
import { useTranslation } from 'react-i18next'

export const TimeRemaining = ({
    styled = true,
    sx,
    from,
    to,
    showClosed = false,
    closedLabel = 'Bidding closed',
    dealPreviewMode= false,
    alternateInvestment = false,
    ...props
}) => {
    const { t } = useTranslation();
    const nodeRef = useRef(null);

    // Setting the labels, see http://countdownjs.org/readme.html
    countdown.setLabels(
       'ms|s|m|h|d|w|mth|yr| decade| century| millennium',
       'ms|s|m|h|d|w|mth|yr| decades| centuries| millennia',
       '',
       '',
       '');
    const [remaining, setRemaining] = useState(null);
    const [dealStatus, setDealStatus] = useState(false);
    const [className, setClassName] = useState('darker');

    useEffect(() => {
        if (typeof to === 'object' && to.isValid() && typeof from === 'object' && from.isValid()) {
            const interval = setInterval(() => {
                const now = moment().utc();
                const then = moment(to).utc();
                const open = moment(from).utc();

                if (moment().isAfter(then)) {
                    setDealStatus('closed');
                    setRemaining(null)
                } else if (moment().isBefore(open)) {
                    setDealStatus('upcoming')
                    setRemaining(moment().countdown(from, countdown.DEFAULT, 2).toString())
                } else {
                    setDealStatus('active')
                    setRemaining(moment().countdown(to, countdown.DEFAULT, 2).toString())
                }

                const diff = then.diff(now, 'hours');

                if (diff < 5 || moment().isAfter(then)) {
                    setClassName('error');
                } else if (diff < 24) {
                    setClassName('warning');
                }

            }, 1000);
            return () => clearInterval(interval);
        } else {
            setRemaining(null);
            setDealStatus('open');
        }
    }, [to, from]);

    const fontSize = sx?.fontSize,
    styles = styled ? {
        bg: 'light',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        p: 2,
        ...sx
    } : sx;

    // If the deal is in preview mode show opening soon
    if (dealPreviewMode){
        return <Flex sx={styles}>
            <Box sx={{
                display: 'inline-flex',
                alignItems: 'center'
            }}>
                <Icon icon="clock" size="14px" color="darker" mr={2} />
                <Text as="p" variant="small" color="darker" sx={{ fontSize }} {...props}>
                    {t('Opening soon')}
                </Text>
            </Box>
        </Flex>;
    }

    // If the deal status is closed, and we don't want to show; don't show
    if ((dealStatus === 'closed' && !showClosed) || alternateInvestment) {
        return null;
    }

    return (
        <Flex sx={styles}>
            {remaining ?
                <CSSTransition
                    nodeRef={nodeRef}
                    in={true}
                    timeout={500}
                    classNames="fade"
                    unmountOnExit
                    appear
                >
                    <Box ref={nodeRef} sx={{
                        display: 'inline-flex',
                        alignItems: 'center'
                    }}>
                        <Icon icon="clock" size="16px" color={className} mr={2} />
                        <Text as="p" variant="small" color={className} sx={{ flexShrink: 0, fontSize }} {...props}>
                            <strong>{remaining}</strong>
                        </Text>
                        <Text as="p" ml={'4px'} variant="small" color={className} sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            fontSize
                        }} {...props}>
                            {dealStatus === 'active' ? t('remaining') : t('bids open')}
                        </Text>
                    </Box>
                </CSSTransition>
            : (
                    ['closed', 'upcoming', 'open'].includes(dealStatus) !== false ? (
                    <Box sx={{
                        display: 'inline-flex',
                        alignItems: 'center'
                    }}>
                        <Icon icon="clock" size="14px" color={className} mr={2} />
                        <Text as="p" variant="small" color={className} sx={{ fontSize }} {...props}>
                            {t(dealStatus === 'closed' ? closedLabel : (dealStatus === 'open' ? 'Open now!' : 'Opening soon'))}
                        </Text>
                    </Box>
                ) : <Spinner variant="styles.spinner" size="24" />
            )}
        </Flex>
    );
};
