import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    fetchAdviser,
    fetchUserAdvisers,
    fetchUser,
    fetchUserCoins,
    fetchUserTransactions,
    fetchUserOpentrader,
    loginUser,
    loginAsInvestor,
    loginBySingleAccessToken,
    loginTwoFactorChallengeUser,
    enableTwoFactor,
    confirmTwoFactor,
    getTwoFactorQrCode,
    getTwoFactorRecoveryCodes,
    refreshTwoFactorRecoveryCodes,
    disableTwoFactor,
    logout,
    onboardLogin,
    onboardStatus,
    onboardToken,
    onboardingAccount,
    onboardingAddress,
    onboardingEntity,
    onboardingCertificate,
    onboardingVerifyId,
    passwordReset,
    storeProfileUpload,
    storeProfilePhoto,
    storeAccountCertificate,
    storeAccountDetails,
    storeProfileExternalHins,
    storeProfileAccountManagementType,
    storeProfileSupportingDocuments,
    getProfileSupportingDocuments,
    deleteProfileSupportingDocument,
    forgotPassword,
    mobileSendVerification,
    mobileVerify,
    introShown,
    createProfile,
    updateProfile,
    deleteProfile,
    rejectEoi,
    dismissHoldingsTransfer
} from './authAPI';
import LogRocket from 'logrocket';

const initialState = {
    user: null,
    adviser: null,
    activeProfile: null,
    status: 'loading',
    adviser_status: 'loading',
    login_status: 'required',
    profile_switch_status: 'idle'
};

export const getUserAsync = createAsyncThunk(
    'user/getUser',
    async (payload) => {
        const response = payload === undefined ? await fetchUser() : payload;

        if (LogRocket._identifiedUserId !== response.data.uuid) {
            LogRocket.identify(response.data.uuid, {
                name: response.data?.individual?.name,
                email: response.data?.individual?.email,
            });
            LogRocket._identifiedUserId = response.data.uuid;
        }

        return response;
    }
);

export const getAdviserAsync = createAsyncThunk(
    'user/getAdviser',
    async (payload) => {
        const response = payload === undefined ? await fetchAdviser() : payload;
        return response;
    }
);

export const getOnboardingStatusAsync = createAsyncThunk(
    'user/getOnboardingStatus',
    async (payload) => {
        const response = payload === undefined ? await onboardStatus() : payload;
        return response;
    }
);

export const introShownAsync = createAsyncThunk(
    'user/introShown',
    async () => await introShown()
);

export const dismissHoldingsTransferAsync = createAsyncThunk(
    'user/dismissHoldingsTransfer',
    async () => await dismissHoldingsTransfer()
);

export const logoutAsync = createAsyncThunk(
    'user/logout',
    async () => {
        const response = await logout(false);
        return response;
    }
);

export const redirectAuthenticated = (user) => {
    const isAuthenticated = user?.user;
    if (isAuthenticated && user?.login_status === 'success' && localStorage.getItem('authTokenScope') === 'full') {
        return '/portfolio';
    }

    if (isAuthenticated && user?.login_status === 'onboarding') {
        return '/onboarding';
    }
};

export const getUserCoinsAsync = createAsyncThunk(
    'user/getUserCoins',
    async () => {
        const response = await fetchUserCoins();
        return response;
    }
);

export const getUserTransactionsAsync = createAsyncThunk(
    'user/getUserTransactions',
    async (payload) => {
        const response = payload === undefined ? await fetchUserTransactions() : payload;
        return response;
    }
);

export const getUserAdvisersAsync = createAsyncThunk(
    'user/getAdvisers',
    async (payload) => {
        const response = payload === undefined ? await fetchUserAdvisers() : payload;
        return response;
    }
);

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            state.status = 'idle';
            state.user = payload?.data;
            state.login_status = !state.user?.finished_onboarding_excluding_certificate ? 'onboarding' : 'success';
            state.activeProfile = null;

            if (Array.isArray(state.user?.profiles) && state.user?.profiles.length > 0) {
                // Ensure all profiles have valid UUID
                state.user.profiles = state.user.profiles.filter((profile) => !!profile?.uuid);

                if(localStorage.getItem('activeProfile')){
                    // Set current profile in local storage to active profile
                    const [profile] = state.user.profiles.filter((profile) => profile?.uuid === localStorage.getItem('activeProfile'));
                    state.activeProfile = profile;
                } else {
                    // Set first profile in user's profiles to active
                    const profile = state.user.profiles.find((profile) => profile.can_bid !== false)
                    if (profile) {
                        state.activeProfile = profile;
                        localStorage.setItem('activeProfile', profile?.uuid);
                    }
                }
            }
        },
        setUserStatus: (state, { payload }) => {
            state.status = payload;
        },
        setLoginStatus: (state, { payload }) => {
            state.login_status = payload;
        },
        setProfileByUUID: (state, action) => {
            if (state.user?.profiles) {
                state.profile_switch_status = 'switching';
                const [profile] = state.user.profiles.filter((profile) => profile.uuid === action.payload);
                state.activeProfile = profile;
                localStorage.setItem('activeProfile', action.payload);
                state.profile_switch_status = 'idle';
            }
        },
        setAdviserStatus: (state, { payload }) => {
            state.adviser_status = payload;
        },
        switchBackToAdviser: (state, { payload }) => {
            localStorage.setItem('authToken', localStorage.getItem('adviserToken'));
            localStorage.removeItem('adviserToken');
            state.adviser = null;
        },
        setAdvisers: (state, { payload }) => {
            if(state.user !== null){
                state.user.advisers = payload?.data;
            }
        },
        setAdviserUsers: (state, { payload }) => {
            if (state.user !== null) {
                state.user.users = payload?.data;
            }
        },
        setInviteBy: (state, { payload }) => {
            if (state.user !== null) {
                state.user.invited_by = payload?.data;
            }
        },
        setAsxAnnouncementPreferences: (state, { payload }) => {
            if (state.user !== null) {
                state.user.asx_announcement_preferences = payload;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserAsync.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(getUserAsync.rejected, (state) => {
                    state.status = 'idle';
                    state.user = null;
                    state.login_status = localStorage.getItem('authTokenScope') === 'twoFactorRequired' ? 'two_factor_required' : 'required';
                })
                .addCase(getUserAsync.fulfilled, (state, {payload}) => {
                    state.status = 'idle';
                    state.user = payload?.data;
                    state.login_status = !state.user?.finished_onboarding_excluding_certificate ? 'onboarding' : 'success';
                    state.activeProfile = null;

                    // Set current profile in local storage to active profile
                    if (localStorage.getItem('activeProfile') && state.user?.profiles) {
                        const [ profile ] = state.user.profiles.filter((profile) => profile?.uuid === localStorage.getItem('activeProfile'));
                        if (profile) {
                            state.activeProfile = profile;
                        }
                    }

                    if (Array.isArray(state.user?.profiles) && state.user?.profiles.length > 0) {
                        // Ensure all profiles have valid UUID
                        state.user.profiles = state.user.profiles.filter((profile) => !!profile?.uuid);

                        // Set first profile in user's profiles to active
                        if (state.activeProfile === null) {
                            const profile = state.user.profiles.find((profile) => profile.can_bid !== false)
                            if (profile) {
                                state.activeProfile = profile;
                                localStorage.setItem('activeProfile', profile?.uuid);
                            }
                        }
                    }
                })
                .addCase(logoutAsync.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(logoutAsync.rejected, (state) => {
                    state.status = 'idle';
                    state.user = null;
                    state.login_status = 'required';
                    localStorage.removeItem('authTokenScope');
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('adviserToken');
                    localStorage.removeItem('onboardingFirstLoaded');
                    window.location.href = '/';
                })
                .addCase(logoutAsync.fulfilled, (state, {payload}) => {
                    state.status = 'idle';
                    state.user = null;
                    state.login_status = 'required';
                    localStorage.removeItem('authTokenScope');
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('adviserToken');
                    localStorage.removeItem('onboardingFirstLoaded');
                    window.location.href = '/';
                })
                .addCase(getOnboardingStatusAsync.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(getOnboardingStatusAsync.rejected, (state, {payload}) => {
                    state.status = 'idle';
                    state.user = null;
                    state.login_status = 'required';
                })
                .addCase(getOnboardingStatusAsync.fulfilled, (state, {payload}) => {
                    state.status = 'idle';
                    state.user = payload?.data;
                    state.login_status = !state.user?.finished_onboarding_excluding_certificate ? 'onboarding' : 'success';
                    state.activeProfile = null;
                    if(localStorage.getItem('activeProfile')){
                        if(state.user?.profiles){
                            const [profile] = state.user.profiles.filter((profile) => profile.uuid === localStorage.getItem('activeProfile'));
                            state.activeProfile = profile;
                        }
                    }
                })
                .addCase(introShownAsync.pending, (state) => {
                    state.status = 'loading';
                })
                .addCase(introShownAsync.rejected, (state) => {
                    state.status = 'idle';
                })
                .addCase(introShownAsync.fulfilled, (state, {payload}) => {
                    state.status = 'idle';
                    state.user.intro_shown = payload?.data?.intro_shown;
                })
                .addCase(dismissHoldingsTransferAsync.fulfilled, (state, {payload}) => {
                    state.user.holdings_transfer_dismissed = payload?.data?.holdings_transfer_dismissed;
                })
                .addCase(getUserCoinsAsync.fulfilled, (state, {payload}) => {
                    state.status = 'idle';
                    state.user.coins = payload?.data?.coins;
                })
                .addCase(getUserTransactionsAsync.fulfilled, (state, {payload}) => {
                    state.status = 'idle';
                    state.user.transactions = payload;
                })
                .addCase(getAdviserAsync.pending, (state) => {
                    state.adviser_status = 'loading';
                })
                .addCase(getAdviserAsync.rejected, (state) => {
                    state.adviser_status = 'idle';
                    state.adviser = null;
                })
                .addCase(getAdviserAsync.fulfilled, (state, { payload }) => {
                    state.adviser_status = 'idle';
                    state.adviser = payload?.data;
                })
                .addCase(getUserAdvisersAsync.pending, (state) => {
                    if (state.user !== null) {
                        state.user.advisers_status = 'loading';
                    }
                })
                .addCase(getUserAdvisersAsync.rejected, (state) => {
                    if (state.user !== null) {
                        state.user.advisers_status = 'idle';
                        state.user.advisers = null;
                    }
                })
                .addCase(getUserAdvisersAsync.fulfilled, (state, { payload }) => {
                    if (state.user !== null) {
                        state.user.advisers_status = 'idle';
                        state.user.advisers = payload?.data;
                    }
                });
    },
});

export const { setUser, resetUser, setProfileByUUID, setLoginStatus, setUserStatus, setAdviserStatus, switchBackToAdviser, setAdvisers, setAdviserUsers, setInviteBy, setAsxAnnouncementPreferences } = user.actions;
export {
    fetchAdviser,
    fetchUser,
    fetchUserTransactions,
    fetchUserOpentrader,
    loginUser,
    loginAsInvestor,
    loginBySingleAccessToken,
    loginTwoFactorChallengeUser,
    enableTwoFactor,
    confirmTwoFactor,
    getTwoFactorQrCode,
    getTwoFactorRecoveryCodes,
    refreshTwoFactorRecoveryCodes,
    disableTwoFactor,
    logout,
    onboardLogin,
    onboardStatus,
    onboardToken,
    onboardingAccount,
    onboardingAddress,
    onboardingEntity,
    onboardingCertificate,
    onboardingVerifyId,
    passwordReset,
    storeProfileUpload,
    storeProfilePhoto,
    storeAccountCertificate,
    storeAccountDetails,
    storeProfileExternalHins,
    storeProfileAccountManagementType,
    storeProfileSupportingDocuments,
    getProfileSupportingDocuments,
    deleteProfileSupportingDocument,
    forgotPassword,
    mobileSendVerification,
    mobileVerify,
    createProfile,
    rejectEoi,
    updateProfile,
    deleteProfile
};

export default user.reducer;
