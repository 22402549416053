import axios from 'axios';
import { toast } from 'utils/toast'

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {
    const authToken = localStorage.getItem('authToken');
    const adviserToken = localStorage.getItem('adviserToken');
    if (authToken) {
        config.headers.Authorization = 'Bearer ' + authToken;
    }
    if (adviserToken) {
        config.headers.common['X-Adviser-Authorization'] = 'Bearer ' + adviserToken;
    }
    return config
})

axios.interceptors.response.use(
    response => response,
    error => {
        const unauthorized = [401,419];
        if (unauthorized.includes(error.response?.status)) {
            if ( !localStorage.getItem('twoFactorRequired') || !localStorage.getItem('authToken') ) {
                toast.error('Please try and sign in again.', {
                    toastId: 'session_expired',
                    heading: 'Sorry, your session has expired.'
                });

                localStorage.removeItem('adviserToken');
                localStorage.removeItem('authToken');
                localStorage.removeItem('authTokenScope');

                if(window.location.pathname !== '/'){
                    if (window.location.pathname !== '/logout') {
                        localStorage.setItem('returnTo', window.location.pathname);
                    }
                    window.location.href = '/';
                }
            }
        } else if(error.response?.status === 500) {
            toast.error('Sorry, there was an error processing your request', {
                toastId: 'error'
            });
            return;
        } else {
            return Promise.reject(error)
        }
    }
)

export const getCsrfCookie = async () => {
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`);
};

export const axiosAdviser = axios.create();
axiosAdviser.defaults.withCredentials = true;
axiosAdviser.defaults.baseURL = process.env.REACT_APP_API_URL;
axiosAdviser.interceptors.request.use(function (config) {
    const adviserToken = localStorage.getItem('adviserToken');
    if (adviserToken) {
        config.headers.Authorization = 'Bearer ' + adviserToken;
    }
    return config
});
axiosAdviser.interceptors.response.use(
    response => response,
    error => {
        const unauthorized = [401, 419];
        if (unauthorized.includes(error.response?.status)) {
            if (!localStorage.getItem('twoFactorRequired') || !localStorage.getItem('adviserToken')) {
                toast.error('Please try and sign in again.', {
                    toastId: 'session_expired',
                    heading: 'Sorry, your session has expired.'
                });

                localStorage.removeItem('adviserToken');
                localStorage.removeItem('authToken');
                localStorage.removeItem('authTokenScope');

                if (window.location.pathname !== '/') {
                    if (window.location.pathname !== '/logout') {
                        localStorage.setItem('returnTo', window.location.pathname);
                    }
                    window.location.href = '/';
                }
            }
        } else if (error.response?.status === 500) {
            toast.error('Sorry, there was an error processing your request', {
                toastId: 'error'
            });
            return;
        } else {
            return Promise.reject(error)
        }
    }
)
