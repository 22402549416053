import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Box
} from '@theme-ui/components'

export const Tooltip = ({ children, text, sx, innerSx, groupSx, position, ...props }) => {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    return (
        <Box className="tooltip__container" sx={{
            position: 'relative',
            display: 'inline-block',
            ...sx
        }}>
            {text && (
                <Box
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}
                    className={show ? `tooltip__box tooltip__box--${(position ?? 'top')} tooltip__box--visible` : 'tooltip__box'}
                    sx={{
                        position: 'absolute',
                        minWidth: '250px',
                        top: position === 'bottom' ? 'calc(100% + 5px)' : null,
                        bottom: position === 'bottom' ? null : 'calc(100% + 5px)',
                        left: '50%',
                        transform: 'translate3d(-50%, 0, 0)',
                        transition: '0.3s all ease-in-out',
                        visibility: show ? 'visible' : 'hidden',
                        opacity: show ? '1' : '0',
                        textAlign: 'center',
                        zIndex: show ? 1000 : 0,
                        ...innerSx
                    }}
                >
                    <Box sx={{
                        position: 'relative',
                        background: 'rgba(0, 0, 0, 0.9)',
                        padding: 2,
                        color: 'white',
                        borderRadius: '5px',
                        fontSize: 0,
                        display: 'inline-flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        lineHeight: '1.5',
                        fontWeight: 'thin',
                        width: '100%',
                    }}>
                        {"string" === typeof text ? t(text) : text}
                    </Box>
                    <Box className="tooltip__arrow" sx={{
                        position: 'absolute',
                        top: position === 'bottom' ? '-10px' : null,
                        bottom: position === 'bottom' ? null : '-10px',
                        left: 'calc(50% - 5px)',
                        border: '5px solid',
                        borderColor: position === 'bottom' ? 'transparent transparent rgba(0, 0, 0, 0.9) transparent' : 'rgba(0, 0, 0, 0.9) transparent transparent transparent'
                    }}>
                    </Box>
                </Box>
            )}
            <Box
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                {...props}
                sx={{
                    display: 'inline',
                    ...groupSx
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
