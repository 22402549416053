import { useFormikContext } from 'formik';
import { Box, Link } from 'theme-ui';
import { Field } from '~/Forms';
import { useState } from 'react';
import { toast } from 'utils/toast';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setUser, storeProfileUpload } from 'features/auth/authSlice';
import { UploadSupportingDocuments } from 'components/Common/UploadSupportingDocuments';
import { Alert } from '~/Common';

const UploadDeedDecoupled = ({ setUser, currentDeed, ...props }) => {
    const { t } = useTranslation();
    const [deedUploadProgress, setDeedUploadProgress] = useState(0);
    const { values, setStatus, setFieldValue } = useFormikContext();
    const uuid = values?.uuid;
    const uploadName = values.type === 'superannuation' ? 'Super Fund Deed' : 'Certified Copy of Trust';

    const onDeedUploadProgress = (p) => {
        setDeedUploadProgress((p.loaded / p.total) * 100);
    };

    const handleUploadDeed = ({ name, value, setFieldValue }) => {
        let formdata = new FormData();
        formdata.append(name, value);

        storeProfileUpload(uuid, 'deed', formdata, onDeedUploadProgress)
            .then((response) => {
                setFieldValue(name, value);
                toast.success(t('settings.deed.success'), {
                    toastId: 'deed-success',
                });
                setUser(response);
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || t('settings.deed.error'), {
                    toastId: 'deed-error',
                });
                setStatus('api_error');
            });
    };

    return (
        <>
            <Box mb={4}>
                {Boolean(!values.deed && !currentDeed) ? (
                    <>
                        <Field
                            field="upload"
                            name="deed"
                            label={`Upload ${uploadName}`}
                            showLabel={false}
                            uploadProgress={deedUploadProgress}
                            onChange={(props) =>
                                handleUploadDeed({
                                    setStatus,
                                    setFieldValue,
                                    ...props,
                                })
                            }
                        />
                    </>
                ) : (
                    <Alert mt={2} mb={4} isCloseable={false} variant="success" icon="certificate-alt" sx={{ display: 'inline-flex' }}>
                        {t(`Your ${uploadName} has been uploaded.`)} <Link href={currentDeed} target="_blank">View File</Link>
                    </Alert>
                )}
            </Box>
            <Box>
                <UploadSupportingDocuments profile={uuid} showForm={false} setStatus={setStatus} />
            </Box>
        </>
    );
};

export const UploadDeed = connect(null, { setUser })(UploadDeedDecoupled);
