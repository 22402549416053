import axios from 'axios'

export const utilStateByCountry = (country) =>
    axios.get(`states/${country}`)
        .then(({ data }) => data)

export const utilTimezoneByCountry = (country) =>
    axios.get(`timezones/${country}`)
        .then(({ data }) => data)

export const utilGetFilters = () =>
    axios.get(`filters`)
        .then(({ data }) => data)

export const utilGetSettings = () =>
    axios.get(`site-settings`)
        .then(({ data }) => data)

export const utilGetCountries = () =>
    axios.get(`countries`)
        .then(({ data }) => data)

export const utilGetProfileOptions = () =>
    axios.get(`profile-options`)
        .then(({ data }) => data)

export const utilGetMarketData = () =>
    axios.get(`market-data`)
        .then(({ data }) => data)
