import { createRef, useState } from 'react';
import * as Yup from 'yup';
import {
    Alert,
    Close,
    Heading,
    Box,
    Themed,
    Text
} from 'theme-ui';
import { Submit } from '~/Forms/Submit';
import { Form, Formik } from 'formik';
import { Field } from '~/Forms/Field';
import { Container } from '~/Common/Container';
import { Layout } from '~/Layouts';
import { toast } from 'utils/toast';
import { loginUser, getUserAsync } from 'features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { useResponsiveValue } from '@theme-ui/match-media';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required()
});

const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    useDocumentTitle(t('Login'));
    const recaptchaRef = createRef();
    const [formError, setFormError] = useState(null);
    const recaptchaEnabled = process.env.REACT_APP_GOOGLE_RECAPTCHA === 'true';
    const referrer = history.location.state?.referrer?.pathname ?? localStorage.getItem('returnTo') ?? '/';
    const isMobile = useResponsiveValue([true, true, false]);

    if (referrer !== '/two-factor-challenge' && referrer !== '/logout') {
        localStorage.setItem('returnTo', referrer);
    }

    const handleSubmit = (
        values,
        actions,
        setFieldError
    ) => {
        (async () => {
            actions.setSubmitting(true);
            let formData = {
                email: values.email,
                password: values.password,
                device_name: 'App'
            };
            let token = null;

            if(recaptchaEnabled){
                token = recaptchaRef.current.getValue();
                if(!token){
                    token = await recaptchaRef.current.executeAsync();
                }
            }

            if(token){
                formData['recaptcha'] = token;
            }

            // if reCaptcha validates then proceed to try and validate code
            if(token || !recaptchaEnabled){
                loginUser(formData).then((response) => {
                    const returnTo = localStorage.getItem('returnTo') ?? '/portfolio';

                    if(response?.two_factor){
                        localStorage.setItem('authTokenScope', 'twoFactorRequired');
                        history.push('/two-factor-challenge');
                        return;
                    }
                    dispatch(getUserAsync());

                    localStorage.removeItem('returnTo');
                    history.push(returnTo);
                }).catch(({ response }) => {
                    const connectionError = response?.status >= 500 || response?.status === undefined;
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('authTokenScope');
                    response?.data?.errors && actions.setErrors(response.data.errors);

                    // Show connection error if status in the 500s
                    const errorMessage = connectionError ? t('login.connect_error') : (
                        // Otherwise show more specific error when we've reached too many requests
                        response?.status === 429 && response?.data?.message
                    ) || t('login.error');

                    setFormError(errorMessage);
                    toast.error(errorMessage, {
                        toastId: 'login-error',
                    });
                    actions.setStatus('api_error');
                    actions.setSubmitting(false);

                    // Reset recaptcha on error
                    if(recaptchaEnabled){
                        window.grecaptcha.reset();
                    }
                });
            } else {
                toast.error(t('recaptcha.error'), {
                    toastId: 'login-error',
                });
                setFieldError('recaptcha', t('recaptcha.error'));
                setFormError(t('recaptcha.error'));
                actions.setSubmitting(false);
            }
        })();
    };

    return (
        <Layout>
            <Container>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        recaptcha: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ status, setStatus, errors }) => (
                        <Box pt={5} sx={{
                            borderTop: isMobile ? '1px solid' : null,
                            borderColor: isMobile ? 'light' : null,
                        }}>
                            { (status === 'api_error' && formError) ?
                            <Alert role="alert" variant='danger' mb={2}>
                                {t(formError)}
                                <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                            </Alert> : ''}

                            <Form>
                                <Heading as="h1" variant="publicH1" mb={4}>
                                    {t('login.title', { site: process.env.REACT_APP_NAME })}
                                </Heading>

                                <Field
                                    label="Email"
                                    name="email"
                                    type="email"
                                />

                                <Field
                                    label="Password"
                                    name="password"
                                    type="password"
                                />

                                <Box mb={4} pl={0} sx={{ fontSize: '13px' }}>
                                  <Text as="span">
                                    Forgot your password?{` `}
                                  </Text>
                                  <Text as="span">
                                    <Themed.a as={Link} to="/forgot-password">Reset</Themed.a>
                                  </Text>
                                </Box>

                                {recaptchaEnabled &&
                                    <Field
                                        field="recaptcha"
                                        name="recaptcha"
                                        inputRef={recaptchaRef}
                                    />
                                }

                                <Submit variant="primary" text={t('buttons.login')} sx={{ display: 'block', width: '100%' }} />
                            </Form>
                        </Box>
                    )}
                </Formik>
            </Container>
        </Layout>
    );
};

export default Login;
