import {
    Box,
    Heading,
    Text
} from '@theme-ui/components'
import { Container, Markdown } from '~/Common'
import { useTranslation } from 'react-i18next'
import { Icon } from 'assets/Icon';

export const Quote = ({ bg = 'white', data, container, ...props }) => {
    const { t } = useTranslation();
    const title = data?.title ?? null;
    const content = data?.content ?? null;
    const author = data?.author ?? null;

    return (
        <Box bg={bg} {...props}>
            <Container sx={{ py: 1 }} {...container}>
                { title && <Heading as="h2" variant="h1" mb={[4,4,5]} sx={{ fontWeight: 'bold' }}>{t(title)}</Heading>}
                <Box>
                    { content && (
                        <Box className="markdown caption" mt={4} sx={{
                            fontSize: 5,
                            textAlign: 'left',
                            display: 'grid',
                            gridTemplate: 'auto / 24px auto',
                            gridGap: '30px',
                            alignItems: 'start'
                        }}>
                            <Icon color="medium" icon="quote" width="24px" mt="11px" />
                            <Markdown>{content}</Markdown>
                        </Box>
                    )}
                    { author && <Text as="p" variant="tag" color="primary" mb={1}>{author}</Text>}
                </Box>

            </Container>
        </Box>
    )
};
