import { useRef, useState, useEffect } from 'react';
import { Layout } from '~/Layouts';
import { Box, Heading, Paragraph, Spinner } from '@theme-ui/components';
import { Link, useHistory, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { MAX_PROFILES_PER_USER, ProfileTypeToMainType, ProfileMainTypes } from 'app/constants';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { toast } from 'utils/toast';
import { createProfile, setUser } from 'features/auth/authSlice';
import { getNestedErrors } from 'utils/helpers';
import { Alert, BackButton, Button } from '~/Common';
import { addEntityStep, Steps, StepFields } from './Steps';
import { getCountriesAsync, getProfileOptionsAsync, utilStateByCountry } from 'features/theme/themeSlice';
import { Submit } from '~/Forms';
import { updateProfile } from 'features/auth/authSlice';
import moment from 'moment';
import { addressValidationNullable } from 'utils/validationRules';
import { ProfileCreateCancel } from '~/Common/ProfileCreateCancel';
import { useResponsiveValue } from "@theme-ui/match-media";
import { useDocumentTitle } from 'hooks/useDocumentTitle';

const validationSchema = Yup.object().shape({
    type: Yup.string().oneOf(['company', 'superannuation', 'trust']),
    sub_type: Yup.string().when('type', {
        is: (value) => value !== 'company',
        then: Yup.string().required(),
    }),
    name: Yup.string().required('Name is required'),
    acn: Yup.string().when('type', {
        is: 'company',
        then: Yup.string().acnNumber().required('ACN is required'),
    }),
    company_name: Yup.string().when('sub_type', {
        is: (value) => value === 'superannuation_company' || value === 'trust_company',
        then: Yup.string().required('Company name is required'),
    }),
    company_acn: Yup.string().when('sub_type', {
        is: (value) => value === 'superannuation_company' || value === 'trust_company',
        then: Yup.string().acnNumber().required('Company ACN is required'),
    }),
    deed: Yup.mixed().when('type', {
        is: (value) => value !== 'company',
        then: Yup.mixed().required('Document is required'),
    }),
    abn: Yup.string().abnNumber().nullable(),
    source_of_wealth: Yup.string().nullable(),
    company_type: Yup.string().nullable(),
    incorporation_country: Yup.string().nullable(),
    incorporation_at: Yup.date()
        .transform((val, origin) => new Date(moment(origin).format('YYYY-MM-DD')))
        .nullable(),
    company_incorporation_at: Yup.date()
        .transform((val, origin) => new Date(moment(origin).format('YYYY-MM-DD')))
        .nullable(),
    industry_code: Yup.string().nullable().when('type', {
        is: 'company',
        then: Yup.string().nullable().required('Industry is required'),
    }),
    tax_number: Yup.string().taxNumber().nullable(),
    contact_email: Yup.string().email().nullable(),
    contact_phone: Yup.string().nullable(),
    address: addressValidationNullable,
    company_address: addressValidationNullable,
    principle_address: addressValidationNullable,
    chess_address: addressValidationNullable,
    applicants: Yup.array().of(
        Yup.object().shape({
            role: Yup.string().nullable(),
            title: Yup.string().nullable(),
            first_name: Yup.string().nullable(),
            middle_names: Yup.string().nullable(),
            last_name: Yup.string().nullable(),
            mobile: Yup.string().nullable(),
            email: Yup.string().email('must be a valid email address').nullable(),
            dob: Yup.string()
                .test('dob', 'applicant must be at least 18', (value) => {
                    return value === undefined || moment().diff(moment(value), 'years') >= 18;
                })
                .nullable(),
            tax_number: Yup.string().taxNumber().nullable(),
            tax_country: Yup.string().nullable(),
            citizenship_country: Yup.string().nullable(),
            country_of_birth: Yup.string().nullable(),
            macquarie_occupation_type: Yup.string().nullable(),
            address: addressValidationNullable,
            driver_licence: Yup.object().shape({
                state: Yup.string().nullable(),
                number: Yup.string()
                    .nullable()
                    .trim()
                    .matches(/^[a-zA-Z0-9]{1,10}$/, "Please enter a valid Australian Driver's Licence Number"),
                cardnumber: Yup.string().nullable().trim(),
            }),
        })
    ),
    additional_direct_debit_fields: Yup.array().of(
        Yup.object().shape({
            email: Yup.string().email('Invalid email address').nullable(),
            name: Yup.string().nullable(),
        })
    ),
});

const SettingsProfilesCreate = ({ user, userLoaded, theme, setUser }) => {
    const { t } = useTranslation();
    useDocumentTitle(t('Create Entity'));
    const { uuid } = useParams();
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const [isLocked, setIsLocked] = useState(false);
    const [triggeredDispatch, setTriggeredDispatch] = useState(false);
    const dispatch = useDispatch();
    const nodeRef = useRef(null);
    const formRef = useRef(null);
    const [currentStep, setStep] = useState(uuid ? 2 : 1);
    const canAddMore = user?.profiles.length < MAX_PROFILES_PER_USER;
    const [stateOptions, setStateOptions] = useState(null);
    const [countries, setCountries] = useState(theme.countries.options ?? []);
    const [profileOptions, setProfileOptions] = useState(theme.profile.options ?? []);
    const history = useHistory();
    const isMobile = useResponsiveValue([true, true, false]);

    useEffect(() => {
        utilStateByCountry('AU')
            .then((response) => {
                setStateOptions(response?.data);
            })
            .catch(() => {
                setStateOptions(null);
            });

        if (!triggeredDispatch) {
            dispatch(getProfileOptionsAsync());
            dispatch(getCountriesAsync());
            setTriggeredDispatch(true);
        }
    }, [dispatch, triggeredDispatch]);

    useEffect(() => {
        if (theme.profile.status === 'attempted' && theme.countries.status === 'attempted') {
            setProfileOptions(theme.profile.options);
            setCountries(theme.countries.options);
        }

        if (userLoaded) {
            const existingProfile = user.profiles.filter((p) => p.uuid === uuid)?.[0] ?? false;

            if (existingProfile) {
                setProfile(() => existingProfile);
                setIsLocked(Boolean(existingProfile?.locked_at !== null));
            }
            setLoading(false);
        }
    }, [theme, userLoaded, user, uuid]);

    const setErrorsTouched = (errors, setFieldTouched) => {
        if (errors) {
            Object.keys(errors).forEach((field) => setFieldTouched(field, true, false));
        }
    };

    const updateEntity = (uuid, values, { setStatus, setErrors, setFieldTouched }) =>
        new Promise((resolve, reject) =>
            updateProfile(uuid, values)
                .then((response) => {
                    setUser(response);
                    return response;
                })
                .then(resolve)
                .catch(({ response }) => {
                    reject(response);
                    if (response?.data?.errors) {
                        toast.error(t('settings.profile.create.step_error'), {
                            toastId: 'profile-error',
                        });
                        setErrors(getNestedErrors(response.data.errors));
                        setErrorsTouched(response?.data?.errors, setFieldTouched);
                    }
                    setStatus('api_error');
                })
        );

    const createOrUpdateEntity = (values, { setStatus, setErrors, setFieldValue, setFieldTouched }, step) => {
        if (isLocked){
            setStep((step) => step + 1);
            return;
        }
        let submitValues;
        if (step?.confirm) {
            submitValues = {...values, confirm: true};
        } else {
            submitValues = { ...values, step: step?.submittedStep };

            // Filter out values not valid to send in this step
            submitValues = Object.fromEntries(StepFields['all'].concat(StepFields[step?.submittedStep]).map(col => [col, submitValues[col]]));
        }
        submitValues['type'] = values.sub_type ? values.sub_type : values.type;

        if (!values.uuid) {
            createProfile(submitValues)
                .then((response) => {
                    setFieldValue('uuid', response.uuid);
                    setUser(response);
                    history.push(`/settings/entities/${response.uuid}`);
                    setStep((step) => step + 1);
                })
                .catch(({ response }) => {
                    toast.error(t('settings.profile.create.step_error'), {
                        toastId: 'profile-error',
                    });
                    response?.data?.errors && setErrors(getNestedErrors(response.data.errors));
                    setErrorsTouched(response?.data?.errors, setFieldTouched);
                    setStatus('api_error');
                });
        } else {
            updateEntity(values.uuid, submitValues, {
                setStatus,
                setErrors,
                setFieldValue,
                setFieldTouched,
            }).then((response) => {
                setStep((step) => step + 1);

                if (step?.confirm) {
                    setUser(response);
                }
            })
        }
    };

    const goBack = () => {
        setStep((step) => {
            if (step > 0) {
                return step - 1;
            }
        });
    };

    const handleEnter = (e, step, formProps) => {
        if (e.key === 'Enter') {
            if (step.hideNext !== true && !step.showSubmit) {
                nextStep(formProps, step);
            } else if (step.complete) {
                history.push('/settings/entities');
            }
        }
    }

    const nextStep = ({ values, setErrors, setStatus, setFieldValue, setFieldTouched, validateField }, step) => {
        const next = () => {
            if (step.updateOnNext || step.submitOnNext) {
                createOrUpdateEntity(values, {
                    setErrors,
                    setStatus,
                    setFieldValue,
                    setFieldTouched,
                }, step);
            } else if (step.submittedStep === 'deed') {
                if(profile?.deed){
                    setStep((step) => step + 1);
                } else {
                    const uploadName = values.type === 'superannuation' ? 'Super Fund Deed' : 'Certified Copy of Trust';
                    toast.error(t(`Please supply the ${uploadName}`), {
                        toastId: 'profile-error',
                    });
                    return false;
                }
            } else {
                setStep((step) => step + 1);
            }
        };

        if (Array.isArray(step.validate)) {
            const allowNext = step.validate.reduce((allow, field) => {
                if (!validateField(field)) {
                    setFieldTouched(field, true, false);
                    return false;
                }
                return allow;
            }, true);

            if (allowNext) {
                next();
            }
        } else {
            next();
        }
    };

    return (
        <Layout>
            {loading ? (
                <Box id="settingsLoading"><Spinner variant="styles.spinner" m={'auto'} /></Box>
            ) : (
                <CSSTransition nodeRef={nodeRef} in={true} timeout={500} classNames="fade" unmountOnExit appear>
                    <Box ref={nodeRef} id="addEntity" px={isMobile ? 3 : 0}>
                        <>
                            <Box mb={4}>
                                {isLocked ? (
                                    <Alert mb={4} variant="info" isCloseable={false}>
                                        {t('settings.profile.locked')}
                                    </Alert>
                                ) : null}

                                <Formik
                                    innerRef={formRef}
                                    initialValues={{
                                        confirm: undefined,
                                        step: currentStep ?? '',
                                        uuid: uuid ?? null,
                                        type: ProfileTypeToMainType[profile?.type] ?? '',
                                        name: profile?.name ?? '',
                                        accountant_email: profile?.accountant_email ?? '',
                                        abn: profile?.abn ?? '',
                                        acn: profile?.acn ?? '',
                                        sub_type: profile?.type ?? '',
                                        phone: profile?.phone ?? '',
                                        email: profile?.email ?? '',
                                        tax_number: profile?.tax_number ?? '',
                                        address: {
                                            unit_number: profile?.address?.unit_number ?? '',
                                            street_number: profile?.address?.street_number ?? '',
                                            street_name: profile?.address?.street_name ?? '',
                                            street_type: profile?.address?.street_type ?? '',
                                            address2: profile?.address?.address2 ?? '',
                                            state: profile?.address?.state ?? '',
                                            suburb: profile?.address?.suburb ?? '',
                                            postcode: profile?.address?.postcode ?? '',
                                            country: profile?.address?.country ?? 'AU',
                                        },
                                        same_company_address: profile?.address?.street_number === profile?.company_address?.street_number
                                            && profile?.address?.street_name === profile?.company_address?.street_name
                                            && profile?.address?.suburb === profile?.company_address?.suburb
                                            && profile?.address?.postcode === profile?.company_address?.postcode,
                                        same_principle_address: profile?.address?.street_number === profile?.principle_address?.street_number
                                            && profile?.address?.street_name === profile?.principle_address?.street_name
                                            && profile?.address?.suburb === profile?.principle_address?.suburb
                                            && profile?.address?.postcode === profile?.principle_address?.postcode,
                                        same_chess_address: profile?.is_chess_address_same_as_applicant || (
                                            profile?.address?.street_number === profile?.chess_address?.street_number
                                            && profile?.address?.street_name === profile?.chess_address?.street_name
                                            && profile?.address?.suburb === profile?.chess_address?.suburb
                                            && profile?.address?.postcode === profile?.chess_address?.postcode
                                        ),
                                        source_of_wealth: profile?.source_of_wealth ?? '',
                                        affiliated_with_other_asx_or_government: Boolean(profile?.affiliated_with_other_asx_or_government ?? false) ?? false,
                                        affiliated_with_other_asx_or_government_details: profile?.affiliated_with_other_asx_or_government_details ?? '',
                                        send_chess_email: profile?.send_chess_email ?? '',
                                        chess_email: profile?.chess_email ?? '',
                                        designation: profile?.designation ?? '',
                                        applicants: profile?.beneficials ?? [],
                                        additional_trade_confirmation_emails: profile?.additional_trade_confirmation_emails ?? [],
                                        account_management_type: profile?.account_management_type === 'Full Service' ? 1 : 0,
                                        additional_direct_debit_fields: profile?.additional_direct_debit_fields ?? [],

                                        chess_address: {
                                            unit_number: profile?.chess_address?.unit_number ?? '',
                                            street_number: profile?.chess_address?.street_number ?? '',
                                            street_name: profile?.chess_address?.street_name ?? '',
                                            street_type: profile?.chess_address?.street_type ?? '',
                                            address2: profile?.chess_address?.address2 ?? '',
                                            state: profile?.chess_address?.state ?? '',
                                            suburb: profile?.chess_address?.suburb ?? '',
                                            postcode: profile?.chess_address?.postcode ?? '',
                                            country: profile?.chess_address?.country ?? 'AU',
                                            is_po_box: profile?.chess_address?.is_po_box ?? false
                                        },

                                        // Company fields
                                        company_type: profile?.company_type ?? '',
                                        incorporation_country: profile?.incorporation_country ?? 'AU',
                                        incorporation_at: profile?.incorporation_at ?? '',
                                        industry_code: profile?.industry_code ?? '',
                                        contact_email: profile?.contact_email ?? '',
                                        contact_phone: profile?.contact_phone ?? '',
                                        principle_address: {
                                            unit_number: profile?.principle_address?.unit_number ?? '',
                                            street_number: profile?.principle_address?.street_number ?? '',
                                            street_name: profile?.principle_address?.street_name ?? '',
                                            street_type: profile?.principle_address?.street_type ?? '',
                                            address2: profile?.principle_address?.address2 ?? '',
                                            state: profile?.principle_address?.state ?? '',
                                            suburb: profile?.principle_address?.suburb ?? '',
                                            postcode: profile?.principle_address?.postcode ?? '',
                                            country: profile?.principle_address?.country ?? 'AU',
                                        },
                                        applicant_is_director: Boolean(profile?.applicant_is_director ?? true) ?? true,
                                        applicant_is_beneficial_owner: Boolean(profile?.applicant_is_beneficial_owner ?? true) ?? true,

                                        // Super fields
                                        smsf: Boolean(profile?.smsf ?? true) ?? true,

                                        // Super/Trust Company fields
                                        company_name: profile?.company_name ?? '',
                                        company_tax_number: profile?.company_tax_number ?? '',
                                        company_abn: profile?.company_abn ?? '',
                                        company_acn: profile?.company_acn ?? '',
                                        company_incorporation_country: profile?.company_incorporation_country ?? '',
                                        company_incorporation_at: profile?.company_incorporation_at ?? '',

                                        company_address: {
                                            unit_number: profile?.company_address?.unit_number ?? '',
                                            street_number: profile?.company_address?.street_number ?? '',
                                            street_name: profile?.company_address?.street_name ?? '',
                                            street_type: profile?.company_address?.street_type ?? '',
                                            address2: profile?.company_address?.address2 ?? '',
                                            state: profile?.company_address?.state ?? '',
                                            suburb: profile?.company_address?.suburb ?? '',
                                            postcode: profile?.company_address?.postcode ?? '',
                                            country: 'AU'
                                        },

                                        // Trust fields
                                        settlor_name: profile?.settlor_name ?? '',
                                        trust_type: profile?.trust_type ?? '',
                                        country: profile?.country ?? '',
                                        sum_established_ten_thousand: Boolean(profile?.sum_established_ten_thousand ?? false) ?? false,
                                        supporting_documents: []
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={() => {}}
                                >
                                    {(formProps) => {
                                        const entityType = ProfileTypeToMainType[formProps.values.type] ?? null;
                                        const entityTypeTitle = ProfileMainTypes[entityType] ?? null;
                                        const step = (currentStep <= 1 && !uuid) || !entityType  ? addEntityStep : Steps[entityType][currentStep - 2];
                                        const isCompanyType = formProps.values.sub_type.includes('company');
                                        const handleEntityLang = (lang) => isCompanyType ? (lang?.company !== undefined ? lang.company : lang) : (lang?.individual !== undefined ? lang.individual : lang);

                                        // Allow user to go back until uuid is set
                                        const showBack = (currentStep > 2 || !formProps.values.uuid) && step?.hideBack !== true;

                                        if (currentStep <= 1 && !canAddMore && !uuid) {
                                            return (
                                                <>
                                                    <Heading as="h3" variant="settings_heading" mb={3}>
                                                        {t('You have reached the maximum of {{max}} entities.', {
                                                            max: MAX_PROFILES_PER_USER,
                                                        })}
                                                    </Heading>
                                                    <Button variant="primary" to="/settings/entities" as={Link}>
                                                        {t('Manage entities')}
                                                    </Button>
                                                </>
                                            );
                                        }

                                        if (!step) {
                                            return null;
                                        }

                                        const Component = step?.component;

                                        return (
                                            <Form onKeyDown={(e) => handleEnter(e, step, formProps)}>
                                                {showBack && (
                                                    <BackButton
                                                        onClick={() => goBack()}
                                                        sx={{
                                                            mb: 4,
                                                            ml: isMobile ? '' : '-50px',
                                                        }}
                                                    />
                                                )}
                                                <Heading as="h2" variant="h2" mb={4}>
                                                    {t(handleEntityLang(step.title), { type: entityTypeTitle })}
                                                </Heading>
                                                <Paragraph mb={formProps.values.type === 'company' ? 4 : 2}>{t(handleEntityLang(step.description), { type: entityTypeTitle })}</Paragraph>
                                                {formProps.values.type !== 'company' && (
                                                    <>
                                                        <Paragraph mb={2}>{t(handleEntityLang(step.descriptionTwo), { type: entityTypeTitle })}</Paragraph>
                                                        <Paragraph mb={4} sx={{ fontWeight: 'bold' }}>
                                                            {t(handleEntityLang(step.descriptionThree), { type: entityTypeTitle })}
                                                        </Paragraph>
                                                    </>
                                                )}
                                                {Component && <Component
                                                    isLocked={isLocked}
                                                    countries={countries}
                                                    currentDeed={profile?.deed}
                                                    currentCertificate={profile?.certificate}
                                                    currentCertificateStatus={profile?.certificate_status}
                                                    profileOptions={profileOptions}
                                                    stateOptions={stateOptions}
                                                    setStateOptions={setStateOptions}
                                                    type={formProps.values.type}
                                                />}
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: ['column', 'row'],
                                                    justifyContent: 'space-between'
                                                }}>
                                                    {step.hideNext !== true && !step.showSubmit && (!isLocked || (isLocked && !step.submitOnNext)) ? (
                                                        <Button variant="primary" size="large" onClick={() => nextStep(formProps, step, currentStep)} mb={2}>
                                                            {t(step?.nextButtonText ?? 'Next')}
                                                        </Button>
                                                    ) : null}
                                                    {step.showSubmit && <Submit variant="primary" size="large" text={t('Create new entity')} mb={2} />}
                                                    {!step.hideCancel && (
                                                        <ProfileCreateCancel
                                                            sx={{
                                                                float: 'right',
                                                                mb: 2,
                                                                px: isLocked ? 4 : 5
                                                            }}
                                                            label={isLocked ? 'Back to My Entities' : 'Cancel'}
                                                            isLocked={isLocked}
                                                            uuid={formProps.values.uuid}
                                                        />
                                                    )}
                                                    {step.complete && (
                                                        <Button
                                                            sx={{
                                                                width: '100%',
                                                                mb: 2
                                                            }}
                                                            variant="primary"
                                                            size="medium"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                history.push('/settings/entities');
                                                            }}
                                                        >
                                                            {t('Go back to my entities')}
                                                        </Button>
                                                    )}
                                                </Box>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </Box>
                        </>
                    </Box>
                </CSSTransition>
            )}
        </Layout>
    );
};

export default connect(
    (state) => {
        return {
            user: state.user?.user,
            userLoaded: state.user.status === 'idle',
            theme: state.theme,
        };
    },
    { setUser }
)(SettingsProfilesCreate);
