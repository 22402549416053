import {
    Button,
    Box
} from '@theme-ui/components';

export const Circle = ({
    children,
    size = '20px',
    type = 'button',
    bg = 'light',
    sx,
    pulse = false,
    ...props
}) => {
    const PulseEffect = () => pulse && (
        <Box sx={{
            border: '3px solid',
            borderColor: bg,
            borderRadius: '50%',
            position: 'absolute',
            animation: 'pulsate 5s ease-out',
            animationIterationCount: 'infinite',
            opacity: 0,
            top: '-50%',
            left: '-50%',
            width: '200%',
            height: '200%'
        }} />
    )

    switch(type){
        case 'div':
        case 'box':
            return (
                <Box { ...props } sx={{
                    position: 'relative',
                    borderRadius: '50%',
                    width: size,
                    height: size,
                    display: 'inline-block',
                    textAlign: 'center',
                    bg: bg,
                    padding: 0,
                    lineHeight: size,
                    ...sx
                }}>
                    {children}
                    <PulseEffect />
                </Box>
            );

        case 'button':
        default:
            return (
                <Button { ...props } sx={{
                    position: 'relative',
                    borderRadius: '50%',
                    width: size,
                    height: size,
                    display: 'inline-block',
                    textAlign: 'center',
                    padding: 0,
                    lineHeight: size,
                    overflow: 'visible',
                    border: 0,
                    bg: bg,
                    ...sx
                }}>
                    {children}
                    <PulseEffect />
                </Button>
            );

    }
};
