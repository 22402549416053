import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js';
import "@grafa/grafa-web-component/dist";
import { useRef } from 'react';

export const BlogsGridWrapper = () => {
  const blogsGridRef = useRef();

  return (
    <div>
      <grid-component data-feed-key="liqudity-au-0-v1" ref={blogsGridRef} standalone="true"></grid-component>
    </div>
  );
};
