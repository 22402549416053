import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js';
import "@grafa/grafa-web-component/dist";
import { useRef } from 'react';
import { Box } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';

export const CarouselWrapper = () => {
  const carouselRef = useRef();
  const notPC = useResponsiveValue([true, true, true, true, false]);

  return (
    <Box sx={{mt: 2, mb: 4, mx: notPC ? 2 : 7}}>
      <carousel-component 
        data-feed-key="liqudity-au-0-v1"
        ref={carouselRef}
        data-item-small="2"
        data-item-medium="3"
        data-item-large="6"
        data-loop="false"
        standalone="true"
        view-more="false"></carousel-component>
    </Box>
  )
};
