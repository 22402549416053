import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { storeFollowsAsync } from 'features/watchlist/watchlistSlice';
import {
    Box,
    Button,
    Text,
    Flex
} from '@theme-ui/components';
import { Icon } from 'assets/Icon';
import { APP_FEATURES } from 'app/constants';

export const WatchlistButton = ({ company, withText, sx, variant, fund = false, ...props}) => {
    const dispatch = useDispatch();
    const follows = useSelector((state) => state?.watchlist?.watchlist?.follows);
    const [isActive, setIsActive] = useState(follows.length && follows.some((item) => item === company));
    const [isAnimating, setAnimate] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();

        if (isAnimating) {
            return;
        }

        dispatch(storeFollowsAsync({
            method: (!isActive ? 'add' : 'remove'),
            follows: company
        }));

        if (isActive) {
            setIsActive(false);
        } else if (!withText) {
            setIsActive(true);
        } else {
            setAnimate(true);
            setTimeout(() => {
                setIsActive(true);
                setAnimate(false);
            }, 1500)
        }
    };

    // Hide watchlist button if following is disabled
    if (!APP_FEATURES.following || company === undefined) {
        return null;
    }

    return (
        <>
            {company ? (
                <Button
                    {...props}
                    variant="watchlist"
                    className={isActive ? 'active' : ''}
                    title={isActive ? 'Stop following' : `Follow the ${fund ? 'fund' : 'company'}`}
                    onClick={handleClick}
                    sx={{
                        position: 'relative',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: withText ? '240px' : 'auto',
                        px: withText ? 3 : '10px',
                        borderRadius: '8px',
                        ...sx
                    }}
                >
                    {isAnimating && (
                        <Box className="btn-progress">
                            <svg><rect width="100%" height="100%" fill="none" rx="8px" /></svg>
                        </Box>
                    )}
                    <Flex sx={{
                        alignItems: 'center',
                        opacity: isAnimating ? 0 : 1,
                        transition: 'opacity 0.5s 0.5s ease-in-out'
                    }}>
                        <Icon
                            className={isActive ? `animate-tick ${withText ? '' : 'instant'}` : ''}
                            icon={isActive ? 'tick' : 'plus'}
                            size={withText || variant === 'card' ? '12px' : '18px'}
                            mr={withText ? 2 : 0}
                            sx={{
                                transition: 'none',
                                '& path': {
                                    transition: 'none'
                                }
                            }}
                        />
                        <Text
                            className={withText ? '' : 'sr-only'}
                        >
                            {isActive ? 'Following' : `Follow ${fund ? 'fund' : 'company'}`}
                        </Text>
                    </Flex>
                </Button>
            ) : null}
        </>
    );
};
