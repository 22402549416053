/** @jsxImportSource theme-ui */
import styled from '@emotion/styled';
import { Box } from 'theme-ui';
import { theme } from 'app/theme';

export const VerifyStyled = styled(Box)`
    max-width: 408px;
    margin: 0 auto;

    --ReactInputVerificationCode-itemWidth: calc(16.667% - 18px);
    --ReactInputVerificationCode-itemHeight: 4.5rem;
    --ReactInputVerificationCode-itemSpacing: 18px;

    /* Chrome, Safari, Edge, Opera */
    & input::-webkit-outer-spin-button,
    & input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    & input[type=number] {
      -moz-appearance: textfield;
    }

    .ReactInputVerificationCode__container {
        width: 100%;
    }

    & .ReactInputVerificationCode__item {
        border-radius: 2px;
    }

    & .ReactInputVerificationCode__item.is-active {
        box-shadow: inset 0 0 0 2px ${theme.colors.tealLight};
    }
`;
