import { Box, Link as ThemeLink } from '@theme-ui/components'
import { Icon } from 'assets/Icon'
import { Link } from 'react-router-dom'

export const BackButton = ({ component, to, label = 'Back', sx, ...props }) => {
    return (
        <Box>
            <ThemeLink as={component ? component : (to ? Link : null)} to={to} sx={{
                display: 'inline-flex',
                fontSize: 1,
                position: 'relative',
                pl: '22px',
                overflow: 'visible',
                '& svg': {
                    transition: 'transform 0.3s ease-in-out',
                    transform: 'translateX(6px)'
                },
                '&:hover svg': {
                    transform: 'translateX(0)'
                },
                ...sx
            }}{...props}>
                <Icon icon="chevron-left" sx={{
                    position: 'absolute',
                    left: 0,
                    top: '3px'
                }} /> {label}
            </ThemeLink>
        </Box>
    );
}
