import { useEffect } from 'react';
import { setShowAnimation } from 'features/theme/themeSlice';
import { OutPortal } from 'react-reverse-portal';
import { usePortals } from 'app/PortalContext';
import { useDispatch } from 'react-redux';

export const AnimatedTexture = ({ ...props }) => {
    const portals = usePortals();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setShowAnimation(true));

        return () => {
            dispatch(setShowAnimation(false));
        }
    }, [dispatch]);

    return (
        <OutPortal node={portals.canvas} {...props} />
    )
}
