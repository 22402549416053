import { useState } from 'react';
import { Box, Link, Text } from 'theme-ui'
import { Icon } from 'assets/Icon';

export const ReadMore = ({
    children,
    wrapper = Box,
    sx,
    contentProps,
    contentSx,
    wrapProps,
    label = 'Show %more%',
    ...props
}) => {
    const [ show, setShow ] = useState(false);
    const Wrap = wrapper;
    const labelDisplay = label.replaceAll('%more%', show ? 'less' : 'more');

    if (!children) {
        return null;
    }

    return (
        <Box sx={sx} {...props}>
            <Box>
                <Link sx={{ color: 'dark', }} onClick={() => setShow(!show)}>
                    {labelDisplay}
                    <Icon
                        sx={{
                            ml: 2,
                            transform: show ? 'rotate(-180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s 0.1s ease-in-out'
                        }}
                        icon="dropdown"
                        size="10px"
                    />
                </Link>
            </Box>
            <Text variant="body_large" color="darker" {...contentProps} sx={{
                maxHeight: show ? '1000px' : 0,
                overflow: 'hidden',
                display: 'block',
                transition: 'max-height 0.5s ease-in-out',
                pt: 2,
                ...contentSx
            }}>
                <Wrap {...wrapProps}>{children}</Wrap>
            </Text>
        </Box>
    );
};
