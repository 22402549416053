import React, { useEffect, useContext, useState } from 'react';
import * as Yup from 'yup';
import {
    Alert,
    Box,
    Close,
    Heading,
    Text,
} from 'theme-ui';
import { Submit } from '~/Forms/Submit';
import { Field } from '~/Forms/Field';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { OnboardingContext } from 'pages/Onboarding/Landing';
import { onboardingAddress, getOnboardingStatusAsync } from 'features/auth/authSlice';
import { utilTimezoneByCountry } from 'features/theme/themeSlice';
import { toast } from 'utils/toast';
import { connect, useDispatch } from 'react-redux';
import AddressFields from '~/Forms/AddressFields/AddressFields';

const mapStateToProps = (state) => {
    return { user: state.user }
}

const validationSchema = Yup.object().shape({
    unit_number: Yup.string().nullable(),
    street_number: Yup.string().required('Street number is required'),
    street_name: Yup.string().required('Street name is required'),
    street_type: Yup.string().nullable(),
    address2: Yup.string().nullable(),
    state: Yup.string().required(),
    suburb: Yup.string().required(),
    postcode: Yup.string().required(),
    timezone: Yup.string().required()
});

const OnboardingAddress = ({ user }) => {
    const onboarding = useContext(OnboardingContext);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [timezoneOptions, setTimezoneOptions] = useState([]);

    useEffect(() => {
        utilTimezoneByCountry('AU')
            .then((response) => {
                setTimezoneOptions(response?.data);
                onboarding.setLoading(false);
            })
            .catch(({ response }) => {
                setTimezoneOptions([]);
                onboarding.setLoading(false);
            });
    }, [onboarding]);

    const handleSubmit = (values, actions) => {
        onboarding.setLoading(true);

        onboardingAddress({
            address: values.address,
            unit_number: values.unit_number,
            street_number: values.street_number,
            street_name: values.street_name,
            street_type: values.street_type,
            address2: values.address2,
            state: values.state,
            suburb: values.suburb,
            postcode: values.postcode,
            timezone: values.timezone,
            country: 'AU'
        }).then((response) => {
            toast.success(t('onboarding.landing.address.success'), {
                toastId: 'address-success',
            });
            onboarding.nextStep();
            dispatch(getOnboardingStatusAsync());
            onboarding.setLoading(false);
        }).catch(({ response }) => {
            response?.data?.errors && actions.setErrors(response.data.errors);
            toast.error(response?.data?.message || t('onboarding.landing.address.error'), {
                toastId: 'address-error',
            });
            actions.setStatus('api_error');
            actions.setSubmitting(false);
            onboarding.setLoading(false);
        });
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    unit_number: user?.user?.individual?.address?.unit_number ?? '',
                    street_number: user?.user?.individual?.address?.street_number ?? '',
                    street_name: user?.user?.individual?.address?.street_name ?? '',
                    street_type: user?.user?.individual?.address?.street_type ?? '',
                    address2: user?.user?.individual?.address?.address2 ?? '',
                    suburb: user?.user?.individual?.address?.suburb ?? '',
                    state: user?.user?.individual?.address?.state ?? '',
                    postcode: user?.user?.individual?.address?.postcode ?? '',
                    timezone: user?.user?.individual?.address?.timezone ?? ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ status, setStatus, setFieldValue }) => (
                    <Box id="onboardingAddress">
                        { status === 'api_error' ?
                        <Alert role="alert" variant='danger' mb={2}>
                            {t('onboarding.landing.address.error')}
                            <Close ml="auto" mr={-2} onClick={() => setStatus(null)} />
                        </Alert> : ''}

                        <Form>
                            <Heading as="h1" variant="h3" sx={{ fontWeight: 500 }} mb={4}>
                                {t('onboarding.landing.address.title')}
                            </Heading>

                            <Heading as="h2" variant="form_section" mb={3} sx={{
                                fontWeight: 700,
                                fontSize: 2
                            }}>
                                {t('onboarding.landing.address.subtitle')}
                            </Heading>

                            <AddressFields setFieldValue={setFieldValue} context={onboarding} />

                            <Heading as="h2" variant="form_section" mb={3} mt={4} sx={{
                                fontWeight: 700,
                                fontSize: 2
                            }}>
                                {t('Time zone')}
                            </Heading>

                            <Field
                                field="select"
                                label="Timezone"
                                name="timezone"
                                options={timezoneOptions}
                                showLabel={false}
                            />

                            <Text as="p" variant="small" mb={4} sx={{
                                fontSize: '13px',
                                color: 'dark'
                            }}>
                                {t('Please select the time zone you will be bidding from.')}
                            </Text>

                            <Submit variant="primary" id="addressSubmit" text={t('onboarding.landing.address.submit')} sx={{ display: 'block', width: '100%' }} />
                        </Form>
                    </Box>
                )}
            </Formik>
        </>
    )
};

export default connect(mapStateToProps, null)(OnboardingAddress);
